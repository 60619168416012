import React from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { LinearProgress } from "@material-ui/core";
import Footer from "./views/Footer";
import AppSnackbar from "./components/AppSnackbar";
import Dashboard from "./views/Dashboard";
import Profile from "./views/Profile";
import CompanyInformation from "./views/CompanyInformation";
import BarCodeDisplay from "./components/InventoryMaster/BarCodeDisplay";
import Login from "./views/Login";
import routes from "./utils/routes";
import SalesPrint from "./views/SalesPrint";
import Header from "./views/Header";

function AppWithCSS(props) {
  const returnConditionalRoute = (value, companyInfoSet, path) => {
    if (value) {
      if (companyInfoSet) {
        return (
          <Route path={path}>
            <Redirect to="/dashboard"></Redirect>
          </Route>
        );
      } else {
        return (
          <Route path={path}>
            <Redirect to="/companyInfo"></Redirect>
          </Route>
        );
      }
    } else {
      return (
        <Route path={path}>
          <Login></Login>
        </Route>
      );
    }
  };

  const returnExactRoute = (value, companyInfoSet, path) => {
    if (value) {
      if (companyInfoSet) {
        return (
          <Route exact path={path}>
            <Dashboard></Dashboard>
          </Route>
        );
      } else {
        return (
          <Route path={path}>
            <CompanyInformation></CompanyInformation>
          </Route>
        );
      }
    } else {
      return (
        <Route path={path}>
          <Redirect to="/login"></Redirect>
        </Route>
      );
    }
  };

  const switchRoutes = (value, companyInfoSet, roles) => (
    <Switch>
      {returnConditionalRoute(value, companyInfoSet, "/login")}
      {returnExactRoute(value, companyInfoSet, "/")}

      <Route path="/profile">
        {value ? <Profile></Profile> : <Redirect to="/login"></Redirect>}
      </Route>

      <Route path="/companyInfo">
        {value ? (
          <CompanyInformation></CompanyInformation>
        ) : (
          <Redirect to="/login"></Redirect>
        )}
      </Route>

      <Route path="/barCodeDisplay">
        <BarCodeDisplay />
      </Route>

      <Route path="/salesPrint">
        <SalesPrint />
      </Route>

      {routes.map(function (item, i) {
        if (value) {
          if (companyInfoSet) {
            let exists = false;

            try {
              roles.forEach((element) => {
                element.roleTasks.map((val) =>
                  val.type === item.name ? (exists = true) : null
                );
              });
            } catch (error) {
              console.log(error);
            }

            if (exists) {
              return (
                <Route
                  path={item.path}
                  component={item.component}
                  key={i}
                ></Route>
              );
            }

            return null;
          } else {
            return (
              <Route path={item.path} key={i}>
                <CompanyInformation></CompanyInformation>
              </Route>
            );
          }
        } else {
          return <Redirect to="/login" key={i}></Redirect>;
        }
      })}

      <Route path="*">
        {value ? <Dashboard></Dashboard> : <Redirect to="/login"></Redirect>}
      </Route>
    </Switch>
  );

  return (
    <BrowserRouter>
      <div style={{ backgroundColor: "#EAEDEF", height: "100vh" }}>
        {props.value.loadingRoles ? (
          <LinearProgress variant="query" />
        ) : (
          <>
            <Header />
            {/* <DashboardView /> */}
            {switchRoutes(
              props.value.isLoggedIn,
              props.value.companyInfoSet,
              props.value.roles
            )}
            <Footer />
            <AppSnackbar />
          </>
        )}
      </div>
    </BrowserRouter>
  );
}

export default AppWithCSS;
