import React, { useState, useEffect, useRef } from "react";
import Axios from "axios";
import {
  Grid,
  Card,
  CardContent,
  TextField,
  MenuItem,
  TableRow,
  Table,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  withStyles,
  TableContainer,
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  Divider,
  Hidden,
  CircularProgress,
} from "@material-ui/core";
import dateFormat from "dateformat";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import emptyDataIcon from '../assets/img/icons8-empty-box-16.png'

import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ReactExport from "react-export-excel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExcel,
  faFilePdf,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import adbs from "ad-bs-converter";
import UserContext from "../utils/UserContext";
import ReactToPrint from "react-to-print";
// import CreditorReportPrint from "../components/PrintableComponents/CreditorReport";
import jsPDF from "jspdf";
import "jspdf-autotable";
import defaultRoutes from "../utils/routes";
import { withRouter } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const getFinalData = (data) => {

  const uniqueData = {};
  const finalData = [];

  for (let i = 0; i < data.length; i++) {
    if (!uniqueData[data[i].customer.customerIdNumber]) {
      uniqueData[data[i].customer.customerIdNumber] = data[i].customer.customerIdNumber
    }
  }
  // console.log("The uniqData",uniqueData)
  for (let key in uniqueData) {

    const filterDataByKeys = data.filter(item => item.customer.customerIdNumber == key);
    let totalAmount = 0;
    let customerChild = []
    for (let amountItem of filterDataByKeys) {
      //   console.log("myAmount",amount)
      let myChildObject = {
        fiscalYear: amountItem.fiscalYear,
        period: amountItem.period,
        saleDate: amountItem.saleDate,
        customerName: amountItem.customer.fullName,
        customerId: amountItem.customer.customerIdNumber,
        salesType: "Sales",
        totalAmount: amountItem.totalAmount,

      }
      customerChild.push(myChildObject)
      totalAmount += amountItem.totalAmount
    }

    //   console.log("customerChild",customerChild)

    if (filterDataByKeys) {
      finalData.push({ ...filterDataByKeys[0], totalAmount: totalAmount, customerChild: customerChild })

    }

  }

  console.log("The final Data", finalData)
  return finalData
}

function getFinalDataWithRunningBalance(data) {
  var newArray = [];
  newArray.push({
    ...data[0],
    runningBalance: data[0].totalAmount
  })
  for (let i = 1; i < data.length; i++) {
    var newData = {
      ...data[i],

      runningBalance: data[i].totalAmount + newArray[i - 1].runningBalance
    }
    newArray.push(newData);
  }

  return newArray
}

function getStartDate (year,month) {

  let monthIndex = {
    "Opening" : 4,
    "Baisakh": 1,
    "Jestha": 2,
    "Ashadh": 3,
    "Shrawan": 4,
    "Bhadra": 5,
    "Ashwin": 6,
    "Kartik": 7,
    "Mangsir": 8,
    "Poush": 9,
    "Magh": 10,
    "Falgun": 11,
    "Chaitra": 12,
    "Reporting": 3,
  }
  const fsYear78 = {
    "Baisakh": 31,
    "Jestha": 31,
    "Ashadh": 31,
    "Shrawan": 32,
    "Bhadra": 31,
    "Ashwin": 31,
    "Kartik": 30,
    "Mangsir": 29,
    "Poush": 30,
    "Magh": 29,
    "Falgun": 30,
    "Chaitra": 30,
  };
  const fsYear79 = {
    "Baisakh": 31,
    "Jestha": 31,
    "Ashadh": 32,
    "Shrawan": 31,
    "Bhadra": 31,
    "Ashwin": 31,
    "Kartik": 30,
    "Mangsir": 29,
    "Poush": 30,
    "Magh": 29,
    "Falgun": 30,
    "Chaitra": 30,
  };
  const fsYear80 = {
    "Baisakh": 31,
    "Jestha": 32,
    "Ashadh": 31,
    "Shrawan": 32,
    "Bhadra": 31,
    "Ashwin": 30,
    "Kartik": 30,
    "Mangsir": 30,
    "Poush": 29,
    "Magh": 29,
    "Falgun": 30,
    "Chaitra": 30,
  };
  let myEndDate = 0
  if(year === "2078/2079") {
    myEndDate = fsYear78[month]
  } else if(year === "2079/2080") {
    myEndDate = fsYear79[month]
  } else if(year == "2080/2081") {
    myEndDate = fsYear80[month]
  } else {
    alert("Invalid fiscal year. Keep end date of each month of this year in code .")
  }
  if(monthIndex[month] >= 10) {
    let startDate = `${year.slice(0,4)}-${monthIndex[month]}-01`

    return startDate
  } else {
    let startDate = `${year.slice(0,4)}-0${monthIndex[month]}-01`

    return startDate
  }

}
function getEndDate (year,month) {
  let monthIndex = {
    "Opening" : 4,
    "Baisakh": 1,
    "Jestha": 2,
    "Ashadh": 3,
    "Shrawan": 4,
    "Bhadra": 5,
    "Ashwin": 6,
    "Kartik": 7,
    "Mangsir": 8,
    "Poush": 9,
    "Magh": 10,
    "Falgun": 11,
    "Chaitra": 12,
    "Reporting": 3,
  }
  const fsYear78 = {
    "Baishakh": 31,
    "Jestha": 31,
    "Ashadh": 31,
    "Shrawan": 32,
    "Bhadra": 31,
    "Ashwin": 31,
    "Kartik": 30,
    "Mangsir": 29,
    "Poush": 30,
    "Magh": 29,
    "Falgun": 30,
    "Chaitra": 30,
  };
  const fsYear79 = {
    "Baisakh": 31,
    "Jestha": 31,
    "Ashadh": 32,
    "Shrawan": 31,
    "Bhadra": 31,
    "Ashwin": 31,
    "Kartik": 30,
    "Mangsir": 29,
    "Poush": 30,
    "Magh": 29,
    "Falgun": 30,
    "Chaitra": 30,
  };
  const fsYear80 = {
    "Baisakh": 31,
    "Jestha": 32,
    "Ashadh": 31,
    "Shrawan": 32,
    "Bhadra": 31,
    "Ashwin": 30,
    "Kartik": 30,
    "Mangsir": 30,
    "Poush": 29,
    "Magh": 29,
    "Falgun": 30,
    "Chaitra": 30,
  };
  let myEndDate = ""
  if(year === "2078/2079") {
    if(month=== "Opening") {
      myEndDate = fsYear78["Shrawan"]
    } else if(month === "Reporting") {
      myEndDate = fsYear78["Ashadh"]
    } else {
      myEndDate = fsYear79[month]
    }
  } else if(year === "2079/2080") {
    if(month=== "Opening") {
      myEndDate = fsYear78["Shrawan"]
    } else if(month === "Reporting") {
      myEndDate = fsYear78["Ashadh"]
    } else {
      myEndDate = fsYear79[month]
    }
  } else if(year == "2080/2081") {
    if(month=== "Opening") {
      myEndDate = fsYear78["Shrawan"]
    } else if(month === "Reporting") {
      myEndDate = fsYear78["Ashadh"]
    } else {
      myEndDate = fsYear79[month]
    }
  } else {
    alert("Invalid fiscal year. Keep end date of each month of this year in code .")
  }
  if(monthIndex[month] >=10) {
    let endDate = `${year.slice(0,4)}-${monthIndex[month]}-${myEndDate}`
    return endDate
  } else {
    let endDate = `${year.slice(0,4)}-0${monthIndex[month]}-${myEndDate}`
    return endDate
  }

}
const styles = (theme) => ({
  tabMargin: {
    height: "auto",
    width: "100%",
    maxWidth: "1300px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "100px",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "65px",
    },
  },
  root: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "150px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
    },
  },
});

function BookSales(props) {
  let initialDetails = {
    fiscalYear: "",
    period: "",
    transactionDate: "",
    openingDateNepali: "",

    bookSalesData: [],
    totalAmount: 0,
    runningBalance: 0,
  };
  const [startDate,setStartDate] = useState("")
  const [endDate,setEndDate] = useState("")

  const [fiscalYear, setFiscalYear] = useState("");
  // const [currentPeriod, setCurrentPeriod] = useState("");
  //const [accountList, setAccountList] = useState([]);
  const [subAccountList, setSubAccountList] = useState([]);
  const [accountCode, setAccountCode] = useState(200);
  const [subAccountCode, setSubAccountCode] = useState("");
  const [data, setData] = useState(initialDetails);
  // const [orgData, setOrgData] = useState(initialDetails);
  const [distinctFyList, setDistinctFyList] = useState([]);
  const [distinctPeriodList, setDistinctPeriodList] = useState([]);
  const { classes } = props;
  const [excel, setExcel] = useState([]);
  const [excelSummary, setExcelSummary] = useState([]);
  // const [todayDate, setTodayDate] = useState([]);
  const [panelExpanded, setPanelExpanded] = useState(true);
  const [filterTerm, setFilterTerm] = useState();
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [periodFrom, setPeriodFrom] = useState("");
  const [periodTo, setPeriodTo] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyContact, setCompanyContact] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [dropdownList, setDropdownList] = useState([]);
  const [subAccountDropdownList, setSubAccountDropdownList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [myTotalAmount, setMyAmount] = useState(0)

  const [uiType, setUiType] = React.useState('Full');

  const handleUiTypeChange = (event) => {
    setUiType(event.target.value);
  };

  const token = useContext(UserContext);
  let componentRef = useRef();

  const getNepaliDate = (date) => {
    let parsedDate = new Date(Date.parse(date));
    let day = parsedDate.getDate();
    let month = parsedDate.getMonth() + 1;
    let year = parsedDate.getFullYear();

    let miti = adbs.ad2bs(year + "/" + month + "/" + day);
    let nepaliDate = miti.en.year + "-" + miti.en.month + "-" + miti.en.day;

    return nepaliDate;
  };

  const redirectVoucher = (voucherType, voucherNo) => {
    let routes = defaultRoutes;

    let obj = {};

    if (fiscalYear !== "") {
      obj = {
        fiscalYear: fiscalYear,
        periodFrom: periodFrom,
        periodTo: periodTo,
      };
    } else {
      obj = {
        dateFrom: dateFrom,
        dateTo: dateTo,
      };
    }

    if (voucherType === "Debit Note" || voucherType === "Credit Note")
      voucherType += " Voucher";

    token.setVoucherNo({ value: voucherNo });
    token.setBoaFrom({ value: "voucher" });
    token.setVoucherType({ value: voucherType });
    //token.setAccountCode({ value: "200" });
    token.setSubAccountCode({ value: subAccountCode });
    token.setFilterFields({ value: obj });

    routes.map((val) =>
      voucherType === val.name ? props.history.push(val.path) : null
    );
  };

  const getSubAccountName = (subAccountCode) => {
    let subAccountName = "";

    subAccountList.map((val) =>
      val.code === subAccountCode ? (subAccountName = val.name) : null
    );

    return subAccountName;
  };

  const getCurrentPeriod = () => {
    Axios.get("/fy/getCurrentPeriod")
      .then(async (res) => {
        setFiscalYear(res.data.fiscalYear);
        setPeriodFrom(res.data.name);
        let myStartDate = getStartDate(res.data.fiscalYear,res.data.name)
        setStartDate(myStartDate)
        let myEndDate = getEndDate(res.data.fiscalYear,res.data.name)
        setEndDate(myEndDate)
        setPeriodTo(res.data.name);
      
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCurrentPeriod();
    getSubAccountList(112);

    let viewParam = props.match.params.id;

    const accountCode = token.accountCode;
    const subAccountCode = token.subAccountCode;
    const voucherNo = token.voucherNo;
    const filterFields = token.filterFields;
    const boaFrom = token.boaFrom;

    Axios.get("/fy/findTillCurrentPeriod")
      .then((res) => {
        var data = [];
        var nonDistinctFyList = [];
        var nonDistinctPeriodList = [];

        res.data.forEach((element) => {
          var item = {
            id: element.id,
            code: element.code,
            name: element.name,
            status: element.currentStatus,
            fiscalYear: element.fiscalYear,
          };

          data.push(item);
          nonDistinctFyList.push(item.fiscalYear);
          nonDistinctPeriodList.push(item.name);
        });

        setDistinctFyList([...new Set(nonDistinctFyList)]);
        setDistinctPeriodList([...new Set(nonDistinctPeriodList)]);

        // let currentPeriod = "";

        data.forEach(async (element) => {
          if (element.status === true) {
            // currentPeriod = element.name;
            // setCurrentPeriod(currentPeriod);
          }
        })

        //get table data if accountCode is not empty
        if (viewParam === "view" || voucherNo !== "") {
          let newFilterFields = filterFields;

          setShowDetails(true);
          setPanelExpanded(false);

          if (newFilterFields.fiscalYear === "") {
            newFilterFields = {
              dateFrom: newFilterFields.dateFrom,
              dateTo: newFilterFields.dateTo,
            };

            setDateFrom(filterFields.dateFrom);
            setDateTo(filterFields.dateTo);
            setFilterTerm("date");
          } else {
            setFiscalYear(filterFields.fiscalYear);
            setPeriodFrom(filterFields.periodFrom);
            setPeriodTo(filterFields.periodTo);

            setFilterTerm("fiscalYear");
          }

        } else {
          getTodaysDate();
        }
      })
      .catch((err) => {
        console.log(err);
      });

    getCompanyName();
  }, [
    token.accountCode,
    token.subAccountCode,
    token.filterFields,
    props.match.params.id,
    token.voucherNo,
    token.boaFrom,
  ]);

  const getTodaysDate = () => {
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    var todayDate = dateFormat(date, "yyyy-mm-dd");

    setDateFrom(todayDate);
    setDateTo(todayDate);
  };

  const handleFromDate = (event) => {
    var date = event.target.value;
    setDateFrom(date);
  };

  const handleToDate = (event) => {
    var date = event.target.value;
    setDateTo(date);
  };

  const getTableData = (obj) => {
    setLoading(true);
    let bookSalesGetUrl = ""
    if (subAccountCode !== "") {
      bookSalesGetUrl = `pos/bookOfSales/findAllBookOfSales?fiscalYear=${fiscalYear}&periodFrom=${startDate}&periodTo=${endDate}&customerName=${subAccountCode}`
    } else {
      bookSalesGetUrl = `pos/bookOfSales/findAllBookOfSales?fiscalYear=${fiscalYear}&periodFrom=${startDate}&periodTo=${endDate}`
    }


    setLoading(true);

    setShowDetails(true);
    let initialBalance = 0


    Axios.get(bookSalesGetUrl)
      .then((res) => {
        setLoading(false);
        let sortedDate = []
        let myFinalData = getFinalData(res.data);

        let newData = res.data;
        console.log("🚀 ~ file: BookSales.js:336 ~ .then ~ newData", newData)

        let runningBalance = initialBalance;

        let totalDebitAmount = res.data.openingDebitAmount;
        let totalCreditAmount = res.data.closingCreditAmount;

        var format = new Intl.NumberFormat("ne-NP", {
          minimumFractionDigits: 2,
        });

        //newData.accountCode = accountCode;
        //newData.accountName = getAccountName(accountCode);
        newData.subAccountCode = subAccountCode;
        newData.subAccountName = getSubAccountName(subAccountCode);



        newData.totalDebitAmount = totalDebitAmount;
        newData.totalCreditAmount = totalCreditAmount;

        newData.openingDateNepali = getNepaliDate(newData.openingDate);
        newData.closingDateNepali = getNepaliDate(newData.closingDate);



        let ss = []

        if (uiType == "Full") {
          ss = res.data
        } else {
          ss = myFinalData
        }

        ss.sort(function (a, b) {
          if (a.customer.fullName < b.customer.fullName) {
            return -1;
          }
          if (a.customer.fullName > b.customer.fullName) {
            return 1;
          }
          return 0;
        });

        // const myNewData = getFinalDataWithRunningBalance(ss)
        // console.log("🚀 ~ file: BookSales.js:454 ~ .then ~ myNewData", myNewData)


        // const sumArray = [];


        // for (let i = 0; i < ss.length; i++) {
        //   let sum = ss[i];
        //   let myRunningBalance = 0

        //   console.log("the sum sdfsd",sum["totalAmount"])
        //   if(i==0) {
        //     sum.runningBalance = sum["totalAmount"]

        //   }

        //   if (i > 0) {
        //     let prevSum = ss[i - 1];
        //     console.log("🚀 ~ file: BookSales.js:443 ~ .then ~ prevSum", prevSum)

        //     for (const totalAmount in ss[i]) {
        //       sum[runningBalance] = prevSum[runningBalance] + ss[i][totalAmount];
        //     }
        //   }

        //   // if (i < ss.length - 1) {
        //   //   for (const totalAmount in ss[i + 1]) {
        //   //     sum[totalAmount] = sum[totalAmount] + ss[i + 1][totalAmount];
        //   //   }
        //   // }

        //   sumArray.push(sum);
        // }

        // console.log("sumArray",sumArray)

        const myTotal = ss.reduce((acc, item) => {
          acc += item.totalAmount
          return acc
        }, 0)
        setMyAmount(myTotal)




        let tempState = {
          fiscalYear: "",
          period: "",
          transactionDate: "",
          openingDateNepali: "",

          bookSalesData: ss,
          totalAmount: 0,
          runningBalance: 0,
        };





        setData(tempState);

        //------EXCEL DATA--------
        var accountNamePdf = "300-Sales";
        var subAccountNamePdf = "";

        // accountList.forEach((element) => {
        //   if (element.code === accountCode) {
        //     accountNamePdf = element.code + "-" + element.name;
        //   } else {
        //     return null;
        //   }
        // });

        subAccountDropdownList.forEach((item) => {
          if (item.value === subAccountCode) {
            subAccountNamePdf = item.text;
          } else {
            return null;
          }
        });

        let excelData = [];
        let excelDataSummary = [];

        if (filterTerm === "fiscalYear") {
          excelData.push({
            accountCode: "",
            fiscalYear: "Fiscal Year",
            period: fiscalYear,
            transactionDate: "",
            voucherType: "",
            voucherNumber: "",
            particulars: "",
            amount: "",
            runningBalance: "",
          });

          excelData.push({
            accountCode: "",
            fiscalYear: "Period From",
            period: periodFrom,
            transactionDate: "",
            voucherType: "",
            voucherNumber: "",
            particulars: "",
            amount: "",
            runningBalance: "",
          });

          excelData.push({
            accountCode: "",
            fiscalYear: "Period To",
            period: periodTo,
            transactionDate: "",
            voucherType: "",
            voucherNumber: "",
            particulars: "",
            amount: "",
            runningBalance: "",
          });
        } else if (filterTerm === "date") {
          excelData.push({
            accountCode: "",
            fiscalYear: "Date Range",
            period: "",
            transactionDate: "",
            voucherType: "",
            voucherNumber: "",
            particulars: "",
            amount: "",
            runningBalance: "",
          });
          excelData.push({
            accountCode: "",
            fiscalYear: "Date From",
            period: dateFrom,
            transactionDate: "",
            voucherType: "",
            voucherNumber: "",
            particulars: "",
            amount: "",
            runningBalance: "",
          });

          excelData.push({
            accountCode: "",
            fiscalYear: "Date To",
            period: dateTo,
            transactionDate: "",
            voucherType: "",
            voucherNumber: "",
            particulars: "",
            amount: "",
            runningBalance: "",
          });
        } else {
          return null;
        }

        excelData.push({
          accountCode: "",
          fiscalYear: "Accounts",
          period: accountNamePdf,
          transactionDate: "",
          voucherType: "",
          voucherNumber: "",
          particulars: "",
          amount: "",
          runningBalance: "",
        });

        excelData.push({
          accountCode: "",
          fiscalYear: "Sub Accounts",
          period: subAccountNamePdf,
          transactionDate: "",
          voucherType: "",
          voucherNumber: "",
          particulars: "",
          amount: "",
          runningBalance: "",
        });

        excelData.push({
          accountCode: "",
          fiscalYear: "",
          period: "",
          transactionDate: "",
          voucherType: "",
          voucherNumber: "",
          particulars: "",
          amount: "",
          runningBalance: "",
        });

        excelData.push({

          fiscalYear: "Fiscal Year",
          period: "Period",
          saleDate: "Transaction Date",
          customerName: "Customer Name",
          salesType: "Transaction Type",
          salesNo: "Sales No.",
          totalAmount: "Total Amount",
          // runningBalance: "Running Balance",
        });

        excelDataSummary.push({

          sn: "S.No.",

          customerName: "Customer Name",
     
          totalAmount: "Total Amount",
          // runningBalance: "Running Balance",
        });
        ss.map((val) =>
          excelData.push({
            fiscalYear: val.fiscalYear,
            period: val.period,
            saleDate: val.saleDate,
            customerName: val.customer.fullName,
            salesType: "Sales",
            salesNo: val.invoiceNumber,
            totalAmount: format.format(val.totalAmount),
            // runningBalance: format.format(val.totalAmount),

          })
        );
        ss.map((val,index) =>
          excelDataSummary.push({
            sn: index + 1,
            customerName: val.customer.fullName,

            totalAmount: format.format(val.totalAmount),
            // runningBalance: format.format(val.totalAmount),

          })
        );
        excelData.push({
          fiscalYear: "",
          period: "",
          saleDate: "",
          customerName: "Grand Total",
          salesType: "",
          salesNo: "",
          totalAmount: format.format(myTotal),
        })
        excelDataSummary.push({
          sn: "",
          customerName: "Grand Total",

          totalAmount: format.format(myTotal),
        })
        

        setExcel(excelData);
        setExcelSummary(excelDataSummary);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const customSearchSubAccount = (_options, query) => {
    let queryText = query.toLowerCase();

    let arr = subAccountDropdownList;

    let newSet = arr.filter(
      (val) => val.text.toLowerCase().indexOf(queryText.toLowerCase()) !== -1
    );

    if (isNaN(queryText)) {
      newSet = newSet.sort((x, y) =>
        x.text.split("-")[1][0].toLowerCase() === queryText
          ? -1
          : y.text.split("-")[1][0].toLowerCase() === queryText
            ? 1
            : 0
      );
    } else {
      newSet = newSet.sort((x, y) =>
        x.text[0] === queryText ? -1 : y.text[0] === queryText ? 1 : 0
      );
    }

    return [...newSet];
  };

  const handlePanelChange = (_e, isExpanded) => {
    setPanelExpanded(isExpanded);
  };

  const handlePeriodFromChange = async (e) => {
    let value = e.target.value;
    await setPeriodFrom(value);
    let myStartDate = getStartDate(fiscalYear,value)
    console.log("🚀 ~ file: BookSales.js:861 ~ handlePeriodFromChange ~ startEndDate", myStartDate)

    setStartDate(myStartDate)
  };

  const handlePeriodToChange = async (e) => {
    let value = e.target.value;
    await setPeriodTo(value);
        let myEndDate = getEndDate(fiscalYear,value)
        console.log("🚀 ~ file: BookSales.js:878 ~ handlePeriodToChange ~ myEndDate", myEndDate)
    setEndDate(myEndDate)
  };

  const changeFiscalYear = (e) => {
    let value = e.target.value;
    setFiscalYear(value);

  };

  const onFilterTermChange = (e) => {
    let value = e.target.value;
    setFilterTerm(value);

  };

  const changeSubAccountCode = async (e, data) => {
    let value = data.value;

    await setSubAccountCode(value);
  };

  // const changeAccountCode = async (e, data) => {
  //   let value = data.value;

  //   await setAccountCode(value);
  //   setSubAccountCode("");

  //   getSubAccountList(value);
  // };

  const getSubAccountList = (accountCode) => {
    var rowDetails = [];
    Axios.get(
      "/pos/subLedger/getAllActiveSubLedgerByUnderCode/" + accountCode
    ).then((res) => {
      res.data.forEach((element) => {
        var item = {
          key: element.code,
          value: element.name,
          text: element.code + "-" + element.name,
        };
        rowDetails.push(item);
      });

      setSubAccountDropdownList(rowDetails);
    });
  };

  const applyAccount = () => {


    setPanelExpanded(false);

    getTableData(filterTerm, fiscalYear, periodFrom, periodTo);
  };

  const styledTableCell = {
    backgroundColor: "#F1F8FC",
    color: "#3F51B5",
    borderRight: "1px solid #EAEDEF",
  };

  const styledTableCellNarration = {
    backgroundColor: "#F1F8FC",
    color: "#3F51B5",
    borderRight: "1px solid #EAEDEF",
    maxWidth: "200px",
    //overflow:"scroll"
  };

  const getCompanyName = () => {
    Axios.get("/hrm/getGeneralCompanyInformation")
      .then((res) => {
        res.data.forEach((element) => {
          setCompanyName(element.name);
          setCompanyAddress(element.street + ", " + element.city);
          setCompanyContact(element.contactNumber);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const generatePdf = () => {
    var accountNamePdf = "300 - Sales";
    var subAccountNamePdf = "";

    subAccountDropdownList.forEach((item) => {
      if (item.value === subAccountCode) {
        subAccountNamePdf = item.text;
      } else {
        return null;
      }
    });

    let company = companyName;
    let companyAdd = companyAddress;
    let companyContactNumber = companyContact;

    function header() {
      doc.setFont("Helvetica", "bold");
      doc.setFontSize(12);
      doc.text(420, 70, company, {
        align: "center",
      });
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(12);
      doc.text(420, 90, companyAdd, {
        align: "center",
      });
      doc.setFontSize(12);
      doc.text(420, 110, companyContactNumber, {
        align: "center",
      });
    }
    function footer() {
      doc.setFont("Helvetica", "italic");
      doc.setFontSize(9);
      doc.text(40, doc.internal.pageSize.height - 20, window.location.href);
    }

    var doc = new jsPDF("l", "pt", "a4", true);
    //current date
    doc.setFont("Helvetica", "italic");
    doc.setFontSize(9);
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + "/" + mm + "/" + dd;
    doc.text(
      doc.internal.pageSize.width - 150,
      doc.internal.pageSize.height - 20,
      "Printed on: " + today
    );
    header();

    doc.setFont("Helvetica", "bold");
    doc.setFontSize(12);
    doc.text(40, 160, "Sales Book", {
      align: "left",
    });

    var x = 40;
    var y = 190;

    if (filterTerm === "fiscalYear") {
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11);
      doc.text(x + 5, y, "Fiscal Year:");
      doc.setFont("Helvetica", "italic");
      doc.text(120, y, fiscalYear);

      y = y + 25;
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11);
      doc.text(x, y, "Period From:");
      doc.setFont("Helvetica", "italic");
      doc.text(120, y, periodFrom);

      y = y + 25;
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11);
      doc.text(x + 13, y, "Period To:");
      doc.setFont("Helvetica", "italic");
      doc.text(120, y, periodTo);

      x = x + 510;
      y = 190;
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11);
      doc.text(x + 22, y, "Account:");
      doc.setFont("Helvetica", "italic");
      doc.text(630, y, accountNamePdf);

      y = y + 25;
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11);
      doc.text(x, y, "Sub Account:");
      doc.setFont("Helvetica", "italic");
      doc.text(630, y, subAccountNamePdf);
    } else if (filterTerm === "date") {
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(12);
      doc.text(x, y, "Date Range");

      y = y + 25;
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11);
      doc.text(x + 10, y, "Date From:");
      doc.setFont("Helvetica", "italic");
      doc.text(120, y, dateFrom);

      y = y + 25;
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11);
      doc.text(x + 23, y, "Date To:");
      doc.setFont("Helvetica", "italic");
      doc.text(120, y, dateTo);

      y = y + 25;
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11);
      doc.text(x + 23, y, "Account:");
      doc.setFont("Helvetica", "italic");
      doc.text(120, y, accountNamePdf);

      y = y + 25;
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11);
      doc.text(40, y, "Sub Account:");
      doc.setFont("Helvetica", "italic");
      doc.text(120, y, subAccountNamePdf);
    }

    //formatting Amount
    var format = new Intl.NumberFormat("ne-NP", {
      // style: 'currency',
      // currency: 'NPR',
      minimumFractionDigits: 2,
    });

    let creditorReport = [];

    data.bookSalesData.map((amountItem) => {
      creditorReport.push([
        amountItem.fiscalYear,
        amountItem.period,
        amountItem.saleDate,
        amountItem.customer.fullName,
        "Sales",
        amountItem.invoiceNumber,
        format.format(amountItem.totalAmount)
      
      ])


    })
    creditorReport.push([
      "",
      "",
      "",
      "Grand Total",
      "",
      "",
      format.format(myTotalAmount)
      ,
    ])



    // creditorReport.push([
    //   "",
    //   "",
    //   "",
    //   "",
    //   "Total",
    //   format.format(data.totalDebitAmount),
    //   format.format(data.totalCreditAmount + data.runningBalance),
    // ]);

    doc.autoTable({
      startY: y + 60,
      styles: {
        overflow: "linebreak",
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.3,
        halign: "center",
        font: "helvetica",
      },
      didParseCell: function (data) {
        var rows = data.table.body;
        if (data.row.index === rows.length - 1) {
          data.cell.styles.fontStyle = "bold";
        }
      },
      bodyStyles: { valign: "top" },
      theme: "grid",
      columnStyles: {
        0: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          cellWidth: "auto",
          halign: "left",
        },
        1: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          cellWidth: "auto",
          halign: "left",
        },
        2: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          cellWidth: "auto",
          halign: "left",
        },
        3: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          cellWidth: "auto",
          halign: "left",
        },
        4: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          cellWidth: "auto",
          halign: "left",
        },
        5: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          cellWidth: "auto",
          halign: "left",
        },
        6: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          cellWidth: "wrap",
          halign: "right",
        },

      },
      head: [
        [
          "Fiscal Year",
          "Period",
          "Transaction Date",
          "Customer Name",
          "Transaction Type",
          "Sales No.",
          "Total Amount",
          // "Running Balance",
        ],
      ],
      body: creditorReport,
    });
    footer();
    const pages = doc.internal.getNumberOfPages();
    const pageWidth = doc.internal.pageSize.width; //Optional
    const pageHeight = doc.internal.pageSize.height; //Optional
    doc.setFontSize(10); //Optional
    for (let j = 1; j < pages + 1; j++) {
      let horizontalPos = pageWidth / 2; //Can be fixed number
      let verticalPos = pageHeight - 10; //Can be fixed number
      doc.setPage(j);
      doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
        align: "center",
      });
    }
    doc.save("Sales Book");
  };
  const generatePdfSummary = () => {
    var accountNamePdf = "300 - Sales";
    var subAccountNamePdf = "";

    subAccountDropdownList.forEach((item) => {
      if (item.value === subAccountCode) {
        subAccountNamePdf = item.text;
      } else {
        return null;
      }
    });

    let company = companyName;
    let companyAdd = companyAddress;
    let companyContactNumber = companyContact;

    function header() {
      doc.setFont("Helvetica", "bold");
      doc.setFontSize(12);
      doc.text(420, 70, company, {
        align: "center",
      });
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(12);
      doc.text(420, 90, companyAdd, {
        align: "center",
      });
      doc.setFontSize(12);
      doc.text(420, 110, companyContactNumber, {
        align: "center",
      });
    }
    function footer() {
      doc.setFont("Helvetica", "italic");
      doc.setFontSize(9);
      doc.text(40, doc.internal.pageSize.height - 20, window.location.href);
    }

    var doc = new jsPDF("l", "pt", "a4", true);
    //current date
    doc.setFont("Helvetica", "italic");
    doc.setFontSize(9);
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + "/" + mm + "/" + dd;
    doc.text(
      doc.internal.pageSize.width - 150,
      doc.internal.pageSize.height - 20,
      "Printed on: " + today
    );
    header();

    doc.setFont("Helvetica", "bold");
    doc.setFontSize(12);
    doc.text(40, 160, "Sales Book", {
      align: "left",
    });

    var x = 40;
    var y = 190;

    if (filterTerm === "fiscalYear") {
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11);
      doc.text(x + 5, y, "Fiscal Year:");
      doc.setFont("Helvetica", "italic");
      doc.text(120, y, fiscalYear);

      y = y + 25;
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11);
      doc.text(x, y, "Period From:");
      doc.setFont("Helvetica", "italic");
      doc.text(120, y, periodFrom);

      y = y + 25;
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11);
      doc.text(x + 13, y, "Period To:");
      doc.setFont("Helvetica", "italic");
      doc.text(120, y, periodTo);

      x = x + 510;
      y = 190;
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11);
      doc.text(x + 22, y, "Account:");
      doc.setFont("Helvetica", "italic");
      doc.text(630, y, accountNamePdf);

      y = y + 25;
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11);
      doc.text(x, y, "Sub Account:");
      doc.setFont("Helvetica", "italic");
      doc.text(630, y, subAccountNamePdf);
    } else if (filterTerm === "date") {
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(12);
      doc.text(x, y, "Date Range");

      y = y + 25;
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11);
      doc.text(x + 10, y, "Date From:");
      doc.setFont("Helvetica", "italic");
      doc.text(120, y, dateFrom);

      y = y + 25;
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11);
      doc.text(x + 23, y, "Date To:");
      doc.setFont("Helvetica", "italic");
      doc.text(120, y, dateTo);

      y = y + 25;
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11);
      doc.text(x + 23, y, "Account:");
      doc.setFont("Helvetica", "italic");
      doc.text(120, y, accountNamePdf);

      y = y + 25;
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11);
      doc.text(40, y, "Sub Account:");
      doc.setFont("Helvetica", "italic");
      doc.text(120, y, subAccountNamePdf);
    }

    //formatting Amount
    var format = new Intl.NumberFormat("ne-NP", {
      // style: 'currency',
      // currency: 'NPR',
      minimumFractionDigits: 2,
    });

    let creditorReport = [];

    data.bookSalesData.map((amountItem,index) => {
      creditorReport.push([
        index +1,
        amountItem.customer.fullName,

        format.format(amountItem.totalAmount)
      
      ])


    })
    creditorReport.push([
      "",
      "Grand Total",

      format.format(myTotalAmount)
      ,
    ])



    // creditorReport.push([
    //   "",
    //   "",
    //   "",
    //   "",
    //   "Total",
    //   format.format(data.totalDebitAmount),
    //   format.format(data.totalCreditAmount + data.runningBalance),
    // ]);

    doc.autoTable({
      startY: y + 60,
      styles: {
        overflow: "linebreak",
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.3,
        halign: "center",
        font: "helvetica",
      },
      didParseCell: function (data) {
        var rows = data.table.body;
        if (data.row.index === rows.length - 1) {
          data.cell.styles.fontStyle = "bold";
        }
      },
      bodyStyles: { valign: "top" },
      theme: "grid",
      columnStyles: {
        0: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          cellWidth: "auto",
          halign: "left",
        },
        1: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          cellWidth: "auto",
          halign: "left",
        },
        2: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          cellWidth: "auto",
          halign: "right",
        },


      },
      head: [
        [
          "S.No.",
          "Customer Name",
          "Total Amount",
          // "Running Balance",
        ],
      ],
      body: creditorReport,
    });
    footer();
    const pages = doc.internal.getNumberOfPages();
    const pageWidth = doc.internal.pageSize.width; //Optional
    const pageHeight = doc.internal.pageSize.height; //Optional
    doc.setFontSize(10); //Optional
    for (let j = 1; j < pages + 1; j++) {
      let horizontalPos = pageWidth / 2; //Can be fixed number
      let verticalPos = pageHeight - 10; //Can be fixed number
      doc.setPage(j);
      doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
        align: "center",
      });
    }
    doc.save("Sales Book");
  };

  var format = new Intl.NumberFormat("ne-NP", {
    minimumFractionDigits: 2,
  });

  const snRow = {
    borderRight: "1px solid #EAEDEF",
    borderBottom: "1px solid #EAEDEF",
    width: "100px",
    maxWidth: "100px",
  };

  const particularRow = {
    borderRight: "1px solid #EAEDEF",
    borderBottom: "1px solid #EAEDEF",
    width: "400px",
    maxWidth: "270px",
  };

  const amountRow = {
    borderRight: "1px solid #EAEDEF",
    borderBottom: "1px solid #EAEDEF",
    width: "150px",
    minWidth: "130px",
    textAlign: "end",
  };


  return (
    <div>
      <Grid container justify="center" className={classes.root} >
        <Card className={classes.tabMargin} style={{ marginBottom: "100px" }}>
          <CardContent>
            <Grid container style={{ width: "100%" }} justify="space-between">
              <Grid item xs={12} sm={6}>
                <ExpansionPanel
                  expanded={panelExpanded}
                  onChange={handlePanelChange}
                  style={{ maxWidth: "800px" }}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      Report By
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid container>
                      <Grid item xs={12} md={6}>
                        <RadioGroup
                          row
                          value={filterTerm}
                          onChange={onFilterTermChange}
                        >
                          <FormControlLabel
                            value="fiscalYear"
                            control={<Radio color="primary" />}
                            label="Fiscal Year and Period"
                          />
                          <FormControlLabel
                            value="date"
                            control={<Radio color="primary" />}
                            label="Date Range"
                          />
                        </RadioGroup>

                        {filterTerm === "fiscalYear" ? (
                          <>
                            <Grid
                              container
                              justify="flex-start"
                              spacing={2}
                              style={{ marginTop: "20px" }}
                            >
                              <Grid item>
                                <TextField
                                  select
                                  style={{
                                    width: "15vw",
                                    minWidth: "120px",
                                  }}
                                  label="Fiscal Year"
                                  name="fiscalYear"
                                  onChange={changeFiscalYear}
                                  value={fiscalYear}
                                >
                                  {distinctFyList.map((item, i) => (
                                    <MenuItem key={i} value={item}>
                                      {item}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Grid>
                            </Grid>

                            <Grid
                              container
                              justify="flex-start"
                              style={{ marginTop: "20px" }}
                            >
                              <Grid>
                                <TextField
                                  select
                                  style={{
                                    width: "15vw",
                                    minWidth: "120px",
                                  }}
                                  label="Period From"
                                  name="periodFrom"
                                  value={periodFrom}
                                  onChange={handlePeriodFromChange}
                                >
                                  {distinctPeriodList.map((item, i) => (
                                    <MenuItem key={i} value={item}>
                                      {item}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Grid>
                              <Grid item>
                                <TextField
                                  select
                                  style={{
                                    width: "15vw",
                                    minWidth: "120px",
                                  }}
                                  label="Period To"
                                  name="periodTo"
                                  value={periodTo}
                                  onChange={handlePeriodToChange}
                                >
                                  {distinctPeriodList.map((item, i) => (
                                    <MenuItem key={i} value={item}>
                                      {item}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Grid>
                            </Grid>
                          </>
                        ) : null}

                        {filterTerm === "date" ? (
                          <>
                            <Grid container justify="flex-start" spacing={2}>
                              <Grid item>
                                <TextField
                                  placeholder="Date From"
                                  label="Date From"
                                  name="dateFrom"
                                  style={{
                                    width: "15vw",
                                    minWidth: "130px",
                                    marginTop: "20px",
                                  }}
                                  type="date"
                                  value={dateFrom}
                                  onChange={handleFromDate}
                                />
                              </Grid>
                              <Grid item>
                                <TextField
                                  placeholder="Date To"
                                  label="Date To"
                                  name="dateTo"
                                  style={{
                                    width: "15vw",
                                    minWidth: "130px",
                                    marginTop: "20px",
                                  }}
                                  type="date"
                                  value={dateTo}
                                  onChange={handleToDate}
                                />
                              </Grid>
                            </Grid>
                          </>
                        ) : null}
                      </Grid>

                      <Hidden smDown>
                        <Grid xs={1}>
                          <Divider
                            orientation="vertical"
                            inset
                            style={{
                              minHeight: "100px",
                              marginBottom: "10px",
                            }}
                          />
                        </Grid>
                      </Hidden>
                      <Hidden mdUp>
                        <Grid xs={12}>
                          <Divider
                            style={{
                              marginTop: "15px",
                              marginBottom: "10px",
                            }}
                          />
                        </Grid>
                      </Hidden>

                      <Grid item md={5} xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              id="standard-read-only-input"
                              label="Account"
                              defaultValue="300-Sales"
                              style={{
                                width: "200px",
                                borderTop: "0px",
                                borderRight: 0,
                                borderLeft: 0,
                                borderBottom: "1px",
                                borderRadius: "0px",
                              }}
                              InputProps={{
                                readOnly: true,
                              }}
                              variant="standard"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Dropdown
                              clearable
                              placeholder="Sub Account"
                              floating
                              fluid
                              fullWidth
                              selectOnBlur={false}
                              search={customSearchSubAccount}
                              style={{
                                width: "200px",
                                borderTop: "0px",
                                borderRight: 0,
                                borderLeft: 0,
                                borderBottom: "1px solid ",
                                borderRadius: "0px",
                                borderColor: "#8c8a84",
                              }}
                              selection
                              value={subAccountCode}
                              onChange={changeSubAccountCode}
                              options={[...subAccountDropdownList]}
                            ></Dropdown>
                          </Grid>
                          <Grid item xs={12} style={{ marginTop: "20px" }}>
                            <FormControl component="fieldset">

                              <RadioGroup aria-label="gender" name="gender1" value={uiType} onChange={handleUiTypeChange} >
                                <div style={{ display: "flex" }}> <FormControlLabel value="Full" control={<Radio />} label="Full" />
                                  <FormControlLabel value="Summary" control={<Radio />} label="Summary" /></div>

                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        justify="flex-end"
                        container
                        style={{ marginTop: "27px" }}
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={applyAccount}
                        >
                          Apply
                        </Button>
                      </Grid>
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid
                  container
                  justify="flex-end"
                  style={{ paddingTop: "10px", width: "100%" }}
                >
                  <Grid item>
                    {uiType ==  "Full" ?  <Button
                      variant="outlined"
                      color="primary"
                      style={{
                        minWidth: "100px",
                        width: "6vw",
                        margin: "3px",
                        height: "38px",
                        fontSize: "16px",
                      }}
                      onClick={generatePdf}
                    >
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        color="#3f51b5"
                        size="30px"
                        style={{
                          marginRight: "10px",
                        }}
                      ></FontAwesomeIcon>
                      Pdf
                    </Button> :  <Button
                      variant="outlined"
                      color="primary"
                      style={{
                        minWidth: "100px",
                        width: "6vw",
                        margin: "3px",
                        height: "38px",
                        fontSize: "16px",
                      }}
                      onClick={generatePdfSummary}
                    >
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        color="#3f51b5"
                        size="30px"
                        style={{
                          marginRight: "10px",
                        }}
                      ></FontAwesomeIcon>
                      Pdf
                    </Button> }
                  
                  </Grid>

                  <Grid item>
                    <ReactToPrint
                      trigger={() => (
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{
                            minWidth: "100px",
                            width: "6vw",
                            margin: "3px",
                            height: "38px",
                            fontSize: "16px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPrint}
                            color="#3f51b5"
                            size="30px"
                            style={{
                              marginRight: "10px",
                            }}
                          ></FontAwesomeIcon>
                          Print
                        </Button>
                      )}
                      content={() => componentRef}
                    />
                  </Grid>

                  <Grid item>
                    {uiType == "Full" ? <ExcelFile
                      filename="CreditorReport"
                      element={
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{
                            minWidth: "100px",
                            width: "6vw",
                            margin: "3px",
                            height: "38px",
                            fontSize: "16px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faFileExcel}
                            color="#3f51b5"
                            size="30px"
                            style={{
                              marginRight: "10px",
                            }}
                          ></FontAwesomeIcon>
                          Excel
                        </Button>
                      }
                    >

                      <ExcelSheet data={excel} name="Sales Book">

                        <ExcelColumn value="fiscalYear" />
                        <ExcelColumn value="period" />
                        <ExcelColumn value="saleDate" />
                        <ExcelColumn value="customerName" />
                        <ExcelColumn value="salesType" />
                        <ExcelColumn value="salesNo" />

                        <ExcelColumn value="totalAmount" />
                        {/* <ExcelColumn value="runningBalance" /> */}
                      </ExcelSheet>
                    </ExcelFile>: <ExcelFile
                      filename="CreditorReport"
                      element={
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{
                            minWidth: "100px",
                            width: "6vw",
                            margin: "3px",
                            height: "38px",
                            fontSize: "16px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faFileExcel}
                            color="#3f51b5"
                            size="30px"
                            style={{
                              marginRight: "10px",
                            }}
                          ></FontAwesomeIcon>
                          Excel
                        </Button>
                      }
                    >

                      <ExcelSheet data={excelSummary} name="Sales Book">

                        <ExcelColumn value="sn" />

                        <ExcelColumn value="customerName" />
  

                        <ExcelColumn value="totalAmount" />

                      </ExcelSheet>
                    </ExcelFile>}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {showDetails ? (
              <>
                {filterTerm === "fiscalYear" ? (
                  <Grid container style={{ margin: "20px", maxWidth: "700px" }}>
                    <Grid item xs={4}>
                      <Grid container>
                        <Grid>
                          <Typography
                            style={{
                              fontSize: "15px",
                              fontWeight: 600,
                              marginRight: "10px",
                              color: "#108DCD",
                            }}
                          >
                            Fiscal Year:
                          </Typography>
                        </Grid>
                        <Grid>
                          <Typography
                            style={{
                              fontSize: "15px",
                              fontWeight: 400,
                              color: "#108DCD",
                            }}
                          >
                            {fiscalYear}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container>
                        <Grid>
                          <Typography
                            style={{
                              fontSize: "15px",
                              fontWeight: 600,
                              marginRight: "10px",
                              color: "#108DCD",
                            }}
                          >
                            Period From:
                          </Typography>
                        </Grid>
                        <Grid>
                          <Typography
                            style={{
                              fontSize: "15px",
                              fontWeight: 400,
                              color: "#108DCD",
                            }}
                          >
                            {periodFrom}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container>
                        <Grid>
                          <Typography
                            style={{
                              fontSize: "15px",
                              fontWeight: 600,
                              marginRight: "10px",
                              color: "#108DCD",
                            }}
                          >
                            Period To:
                          </Typography>
                        </Grid>
                        <Grid>
                          <Typography
                            style={{
                              fontSize: "15px",
                              fontWeight: 400,
                              color: "#108DCD",
                            }}
                          >
                            {periodTo}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <Grid
                      container
                      style={{ margin: "20px", maxWidth: "500px" }}
                    >
                      <Grid item xs={6}>
                        <Grid container>
                          <Grid>
                            <Typography
                              style={{
                                fontSize: "15px",
                                fontWeight: 600,
                                marginRight: "10px",
                                color: "#108DCD",
                              }}
                            >
                              Date From:
                            </Typography>
                          </Grid>
                          <Grid>
                            <Typography
                              style={{
                                fontSize: "15px",
                                fontWeight: 400,
                                color: "#108DCD",
                              }}
                            >
                              {dateFrom}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <Grid container>
                          <Grid>
                            <Typography
                              style={{
                                fontSize: "15px",
                                fontWeight: 600,
                                color: "#108DCD",
                                marginRight: "10px",
                              }}
                            >
                              Date To:
                            </Typography>
                          </Grid>
                          <Grid>
                            <Typography
                              style={{
                                fontSize: "15px",
                                fontWeight: 400,
                                color: "#108DCD",
                              }}
                            >
                              {dateTo}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            ) : null}

            {loading ? (
              <Grid container justify="center" style={{ margin: "50px 0px" }}>
                <Grid item>
                  <CircularProgress thickness="2" size="50px" />
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid container justify="center" style={{ marginTop: "30px" }}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell style={styledTableCell}>S.No.</TableCell>
                          <TableCell style={styledTableCell}>Fiscal Year</TableCell>
                          <TableCell style={styledTableCell}>Period</TableCell>
                          <TableCell style={styledTableCell}>Transaction Date</TableCell>
                          <TableCell style={styledTableCell}>Customer Name</TableCell>
                          <TableCell style={styledTableCell}>Transaction Type</TableCell>
                          <TableCell style={styledTableCell}>Sales No.</TableCell>
                        
                          <TableCell style={styledTableCell}>
                            Amount
                          </TableCell>


                        </TableRow>
                      </TableHead>
                      <TableBody>

                        {data.bookSalesData.map((value,index) => (
                          <>
                            <TableRow>
                              <TableCell>{index +1}</TableCell>
                              <TableCell>{value.fiscalYear}</TableCell>
                              <TableCell>{value.period}</TableCell>
                              <TableCell>{value.saleDate}</TableCell>
                              <TableCell>
                                {value.customer?.fullName}
                              </TableCell>
                              <TableCell style={{ textAlign: "left" }}>
                                Sales

                              </TableCell>
                              <TableCell>
                                {value.invoiceNumber}
                              </TableCell>


          
                              <TableCell style={{ textAlign: "right" }}>
                                {format.format(value.totalAmount)}

                              </TableCell>

                              {/* <TableCell style={{ textAlign: "right" }}>
                                {format.format(value.subTotal)}
                              </TableCell> */}
                            </TableRow>

                          </>
                        ))}

                        <Table>

                        </Table>

                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>

                          <TableCell>
                            <Typography
                              style={{ fontWeight: "bold", textAlign: "left" }}
                            >
                              Total
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              style={{ fontWeight: "bold", textAlign: "right" }}
                            >
                              {format.format(myTotalAmount)}

                            </Typography>
                          </TableCell>
                          {/* <TableCell>
                            <Typography
                              style={{ fontWeight: "bold", textAlign: "right" }}
                            >
                          
                              50000
                            </Typography>
                          </TableCell> */}
                          <TableCell></TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    {data.bookSalesData.length <=0 && 
                    <Grid container style={{ display: "flex",padding: "20px", flexDirection: "column", alignItems: "center" }}>
                    <img src={emptyDataIcon} />
                    <p style={{color: "#C04E50"}}>Empty Data</p>
                  </Grid>
                    }
                    
                  </TableContainer>
                </Grid>

              </>
            )}
          </CardContent>
        </Card>
      </Grid>
      <div style={{ display: "none" }}>
        {/* <CreditorReportPrint
          ref={(el) => (componentRef = el)}
          data={data}
          dateFrom={dateFrom}
          dateTo={dateTo}
          fiscalYear={fiscalYear}
          accountCode={accountCode}
          subAccountCode={subAccountCode}
          periodFrom={periodFrom}
          periodTo={periodTo}
          filterTerm={filterTerm}
        /> */}
      </div>
    </div>
  );
}
export default withRouter(withStyles(styles)(BookSales));
