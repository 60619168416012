import React, { Component } from "react";
import { Tab, Tabs, Grid, withStyles, Badge } from "@material-ui/core";
import HeaderTitle from "../components/InterfaceElements/HeaderTitle";
import PaymentVoucherList from "../components/PaymentVoucher/PaymentVoucherList";
import AddPaymentVoucher from "../components/PaymentVoucher/AddPaymentVoucher";
import PaymentVoucherAuthorization from "../components/PaymentVoucher/PaymentVoucherAuthorization";
import Axios from "axios";
import { v4 as uuidv4 } from "uuid";
import UserContext from "../utils/UserContext";

const styles = (theme) => ({
  tabMargin: {
    marginBottom: "7px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      marginTop: "37px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "0px",
    },
  },
});

class PaymentVoucher extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      resData: [],
      periodList: [],
      fyList: [],
      supplierData: [],
      supplierOptionList: [],
      idOptionList: [],
      value: 0,
      list: {
        visible: false,
        hidden: true,
        secondVisible: false,
        secondHidden: true,
        animationIn: "",
      },
      auth: {
        visible: false,
        hidden: true,
        secondVisible: false,
        secondHidden: true,
        animationIn: "",
      },
      authDataList: [],
      origAuthDataList: [],
      listDataList: [],
      origListDataList: [],
      authVoucherList: [],
      listVoucherList: [],
      authDateFrom: "",
      authDateTo: "",
      listDateFrom: "",
      listDateTo: "",
      data: {
        id: 0,
        date: "",
        voucherNumber: "",
        invoiceNumber: "",
        paymentReferenceNumber: "",
        paymentMethod: "",
        paymentReference: "",
        paymentReceivedBy: "",
        paymentReceiverId: "",
        paymentReceiverEmail: "",
        paymentReceiverPosition: "",
        paymentBankTransfer: "",
        paymentDate: "",
        bankReferenceNumber: "",
        total: "",
        invoiceTotal: "",
        remarks: "",
        period: "",
        fiscalYear: "",
        masterNarration: "",
        purchaseVoucherMaster: {},
        supplier: { id: "", fullName: "" },
        paymentVoucherCredit: [],
        paymentVoucherDebit: [],
        tableList: [],
      },
      dataToSend: {
        id: 0,
        date: "",
        voucherNumber: "",
        invoiceNumber: "",
        paymentReferenceNumber: "",
        paymentMethod: "",
        paymentReference: "",
        paymentReceivedBy: "",
        paymentReceiverId: "",
        paymentReceiverEmail: "",
        paymentReceiverPosition: "",
        paymentBankTransfer: "",
        paymentDate: "",
        bankReferenceNumber: "",
        total: "",
        remarks: "",
        period: "",
        fiscalYear: "",
        purchaseVoucherMaster: {},
        supplier: { id: "", fullName: "" },
        paymentVoucherCredit: [],
        paymentVoucherDebit: [],
      },
    };
  }

  handleViewCounter = (id) => {
    console.log(id);

    Axios.get("pos/paymentVoucher/findByStatusAndRequest")
      .then((res) => {
        res.data.forEach((element, i) => {
          if (id === element.id) {
            var tableList = [];

            res.data[i].paymentVoucherDebit.forEach(async (element) => {
              let subAccountList = [];

              if (
                element.accountCode !== null ||
                element.accountCode !== undefined ||
                element.accountCode !== ""
              ) {
                let res = await Axios.get(
                  "/pos/subLedger/getAllActiveSubLedgerByUnderCode/" +
                    element.accountCode
                );

                subAccountList = res.data.length>0 ? res.data : [{id: "None", code: "None" , name: "None"}];

              }

              tableList.push({
                id: uuidv4(),
                narration:
                  element.narration === null || element.narration === undefined
                    ? ""
                    : element.narration,
                costCenter:
                  element.costCenter === null ||
                  element.costCenter === undefined
                    ? ""
                    : element.costCenter,
                accountCode:
                  element.accountCode === null ||
                  element.accountCode === undefined
                    ? ""
                    : element.accountCode,
                subAccount:
                  element.subAccount === null ||
                  element.subAccount === undefined
                    ? ""
                    : element.subAccount,
                amount:
                  element.amount === null || element.amount === undefined
                    ? ""
                    : element.amount,
                debitCredit: "Debit",
                currencyAnalysis: element.currency,
                subAccountList,
              });

              this.setState((prevState) => ({
                ...prevState,
                data: {
                  ...res.data[i],
                  tableList,
                },
              }));
            });

            res.data[i].paymentVoucherCredit.forEach(async (element) => {
              let subAccountList = [];

              if (
                element.accountCode !== null ||
                element.accountCode !== undefined ||
                element.accountCode !== ""
              ) {
                let res = await Axios.get(
                  "/pos/subLedger/getAllActiveSubLedgerByUnderCode/" +
                    element.accountCode
                );

                subAccountList = res.data.length>0 ? res.data : [{id: "None", code: "None" , name: "None"}];

              }

              tableList.push({
                narration:
                  element.narration === null || element.narration === undefined
                    ? ""
                    : element.narration,
                costCenter:
                  element.costCenter === null ||
                  element.costCenter === undefined
                    ? ""
                    : element.costCenter,
                accountCode:
                  element.accountCode === null ||
                  element.accountCode === undefined
                    ? ""
                    : element.accountCode,
                subAccount:
                  element.subAccount === null ||
                  element.subAccount === undefined
                    ? ""
                    : element.subAccount,
                amount:
                  element.amount === null || element.amount === undefined
                    ? ""
                    : "-" + element.amount,
                id: uuidv4(),
                debitCredit: "Credit",
                currencyAnalysis: element.analysis,
                subAccountList,
              });

              this.setState((prevState) => ({
                ...prevState,
                data: {
                  ...res.data[i],
                  tableList,
                },
              }));
            });
          }
        });

        this.setState({
          rowData: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //this one is to send list to list View
  handleListViewCounter = (id) => {
    Axios.get("pos/paymentVoucher/findAllApproved")
      .then((res) => {
        console.log(res);

        res.data.forEach((element, i) => {
          if (id === element.id) {
            this.setState({
              dataToSend: res.data[i],
            });
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getSubAccountListforAuth = async (id, value) => {
    let tableList = this.state.data.tableList;

    let res = await Axios.get(
      "/pos/subLedger/getAllActiveSubLedgerByUnderCode/" + value
    );

    tableList.forEach((element) => {
      if (element.id === id) {
        element.accountCode = value;
        element.subAccount = "";
        element.subAccountList = res.data;
      }
    });

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        tableList,
      },
    }));
  };

  listAnimate = (id) => {
    this.handleListViewCounter(id);
    this.setState((prevState) => ({
      ...prevState,
      list: {
        ...prevState.list,
        visible: false,
        animationIn: "zoomIn",
      },
    }));

    setTimeout(() => {
      this.setState((prevState) => ({
        ...prevState,
        list: {
          ...prevState.list,
          secondVisible: true,
          hidden: true,
          secondHidden: false,
        },
      }));
    }, 150);
  };

  listAnimateOut = (id) => {
    const token = this.context;
    token.setVoucherNo({ value: "" });
    token.setVoucherType({ value: "" });

    this.handleListViewCounter(id);
    this.setState((prevState) => ({
      ...prevState,
      list: {
        ...prevState.list,
        secondVisible: false,
      },
    }));
    setTimeout(() => {
      this.setState((prevState) => ({
        ...prevState,
        list: {
          ...prevState.list,
          visible: true,
          hidden: false,
          secondHidden: true,
        },
      }));
    }, 150);
  };

  authAnimate = (id) => {
    this.handleViewCounter(id);
    this.setState((prevState) => ({
      ...prevState,
      auth: {
        ...prevState.auth,
        visible: false,
        animationIn: "zoomIn",
      },
    }));
    setTimeout(() => {
      this.setState((prevState) => ({
        ...prevState,
        auth: {
          ...prevState.auth,
          secondVisible: true,
          hidden: true,
          secondHidden: false,
        },
      }));
    }, 150);
  };

  componentDidMount() {
    this.getTableDataForAuth();
    this.getTableDataForList();
    this.getAllPeriodListing();
    this.getAllSuppliers();

    const token = this.context;

    if (token.voucherNo !== "" && token.voucherType === "Payment Voucher") {
      this.listAnimateFromRedirect(token.voucherNo);
    }

    var today = new Date();
    var month = (today.getMonth() + 1).toString();
    var day = today.getDate().toString();
    if (month.length === 1) {
      month = "0" + month;
    }
    if (day.length === 1) {
      day = "0" + day;
    }
    var date = today.getFullYear() + "-" + month + "-" + day;

    this.setState({
      authDateFrom: date,
      authDateTo: date,
      listDateFrom: date,
      listDateTo: date,
    });
  }

  handleFyChange = (fiscalYear, type) => {
    if (type === "auth") {
      let newAuthList = [];
      this.state.origAuthDataList.map((val) =>
        val.fiscalYear === fiscalYear ? newAuthList.push(val) : null
      );

      this.setState({
        authDataList: newAuthList,
      });
    } else {
      let newDataList = [];
      this.state.origListDataList.map((val) =>
        val.fiscalYear === fiscalYear ? newDataList.push(val) : null
      );

      this.setState({
        listDataList: newDataList,
      });
    }
  };

  handleVoucherCodeChange = (voucherCode, type) => {
    if (type === "auth") {
      let newAuthList = [];

      this.state.origAuthDataList.map((val) =>
        val.voucherNumber === voucherCode ? newAuthList.push(val) : null
      );

      this.setState({
        authDataList: newAuthList,
      });
    } else {
      let newDataList = [];

      this.state.origListDataList.map((val) =>
        val.voucherNumber === voucherCode ? newDataList.push(val) : null
      );

      this.setState({
        listDataList: newDataList,
      });
    }
  };

  handleDateFrom = (dateFrom, type) => {
    if (type === "auth") {
      let formmatedDate = new Date(dateFrom);

      let newAuthList = [];

      this.state.origAuthDataList.map((val) =>
        new Date(val.date).getTime() >= formmatedDate &&
        new Date(val.date).getTime() <= new Date(this.state.authDateTo)
          ? newAuthList.push(val)
          : null
      );

      this.setState({
        authDateFrom: dateFrom,
        authDataList: newAuthList,
      });
    } else {
      let formmatedDate = new Date(dateFrom);

      let newDataList = [];

      this.state.origListDataList.map((val) =>
        new Date(val.date).getTime() >= formmatedDate &&
        new Date(val.date).getTime() <= new Date(this.state.listDateTo)
          ? newDataList.push(val)
          : null
      );

      this.setState({
        listDateFrom: dateFrom,
        listDataList: newDataList,
      });
    }
  };

  handleDateTo = (dateTo, type) => {
    if (type === "auth") {
      let formmatedDate = new Date(dateTo);

      let newAuthList = [];

      this.state.origAuthDataList.map((val) =>
        new Date(val.date).getTime() <= formmatedDate &&
        new Date(val.date).getTime() >= new Date(this.state.authDateFrom)
          ? newAuthList.push(val)
          : null
      );

      this.setState({
        authDateTo: dateTo,
        authDataList: newAuthList,
      });
    } else {
      let formmatedDate = new Date(dateTo);

      let newDataList = [];

      this.state.origListDataList.map((val) =>
        new Date(val.date).getTime() <= formmatedDate &&
        new Date(val.date).getTime() >= new Date(this.state.listDateFrom)
          ? newDataList.push(val)
          : null
      );

      this.setState({
        listDateTo: dateTo,
        listDataList: newDataList,
      });
    }
  };

  getTableDataForAuth = () => {
    Axios.get("pos/paymentVoucher/findByStatusAndRequest")
      .then((res) => {
        console.log(res);

        let authVoucherList = [];

        res.data.map((val) =>
          authVoucherList.push({
            key: val.id,
            text: val.voucherNumber,
            value: val.voucherNumber,
          })
        );

        this.setState({
          authVoucherList,
          authDataList: res.data,
          origAuthDataList: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getTableDataForList = () => {
    Axios.get("pos/paymentVoucher/findAllApproved")
      .then((res) => {
        console.log(res);

        let listVoucherList = [];

        res.data.map((val) =>
          listVoucherList.push({
            key: val.id,
            text: val.voucherNumber,
            value: val.voucherNumber,
          })
        );

        this.setState({
          listDataList: res.data,
          origListDataList: res.data,
          listVoucherList,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  authAnimateOut = () => {
    console.log({ "animate out called": true });

    this.setState((prevState) => ({
      ...prevState,
      auth: {
        ...prevState.auth,
        secondVisible: false,
      },
    }));
    setTimeout(() => {
      this.setState((prevState) => ({
        ...prevState,
        auth: {
          ...prevState.auth,
          visible: true,
          hidden: false,
          secondHidden: true,
        },
      }));
    }, 150);
  };

  animate = (id) => {
    console.log({ "voucher number:": id });
    this.handleViewCounter(id);

    this.setState({
      visible: false,
      animationIn: "zoomIn",
    });
    setTimeout(() => {
      this.setState({ secondVisible: true, hidden: true, secondHidden: false });
    }, 150);
  };

  animateOut = () => {
    this.setState({
      secondVisible: false,
    });
    setTimeout(() => {
      this.setState({ visible: true, hidden: false, secondHidden: true });
    }, 150);
  };

  listAnimateFromRedirect = (voucherNo) => {
    Axios.get("pos/paymentVoucher/findAllApproved")
      .then((res) => {
        console.log(res);

        res.data.forEach((element, i) => {
          if (voucherNo === element.voucherNumber) {
            this.setState({
              dataToSend: element,
            });
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });

    this.setState((prevState) => ({
      ...prevState,
      value: 2,
      list: {
        ...prevState.list,
        visible: false,
        animationIn: "zoomIn",
      },
    }));

    setTimeout(() => {
      this.setState((prevState) => ({
        ...prevState,
        list: {
          ...prevState.list,
          secondVisible: true,
          hidden: true,
          secondHidden: false,
        },
      }));
    }, 150);
  };

  handleChange = (event, value) => {
    const token = this.context;
    token.setVoucherNo({ value: "" });
    token.setVoucherType({ value: "" });

    this.setState({ value });

    console.log({ handleChange: value });

    if (value === 1) {
      this.setState((prevState) => ({
        ...prevState,
        list: {
          ...prevState.list,
          hidden: true,
          secondHidden: true,
        },
        auth: {
          ...prevState.auth,
          animationIn: "",
          secondVisible: false,
          visible: true,
          hidden: false,
          secondHidden: true,
        },
      }));
    } else if (value === 2) {
      this.setState((prevState) => ({
        ...prevState,
        list: {
          ...prevState.list,
          animationIn: "",
          secondVisible: false,
          visible: true,
          hidden: false,
          secondHidden: true,
        },
        auth: {
          ...prevState.auth,
          hidden: true,
          secondHidden: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        auth: {
          ...prevState.auth,
          hidden: true,
          secondHidden: true,
        },
        list: {
          ...prevState.list,
          hidden: true,
          secondHidden: true,
        },
      }));
    }
  };

  getAllSuppliers() {
    Axios.get("/pos/supplier/getAllSuppliers")
      .then((res) => {
        var optionList = [
          {
            key: "None",
            value: "None",
            text: "None",
          },
        ];
        var idOptionList = [
          {
            key: "None",
            value: "None",
            text: "None",
          },
        ];
        res.data.forEach((element) => {
          const item = {
            key: element.supplierIdNumber,
            value: element.fullName,
            text: element.fullName,
          };
          const idList = {
            key: element.supplierIdNumber,
            value: element.supplierIdNumber,
            text: element.supplierIdNumber,
          };
          optionList.push(item);
          idOptionList.push(idList);
        });

        this.setState((prevState) => ({
          ...prevState,
          supplierData: res.data,
          supplierOptionList: optionList,
          idOptionList: idOptionList,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleSupplierSelect = (event, data) => {
    const { value } = data;
    const { key } = data.options.find((o) => o.value === value);

    if (value === "None") {
      var supplierData = {
        fullName: "None",
        supplierIdNumber: "None",
      };
      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          supplierName: supplierData.fullName,
          supplierIdNumber: supplierData.supplierIdNumber,
        },
      }));
    } else {
      this.state.supplierOptionList.forEach((element) => {
        if (element.key === key) {
          this.setState((prevState) => ({
            ...prevState,
            data: {
              ...prevState.data,
              supplierName: element.value,
              supplierIdNumber: element.key,
            },
          }));
        }
      });
    }
  };

  handleSupplierIdSelect = (event, data) => {
    const { value } = data;
    const { key } = data.options.find((o) => o.value === value);

    if (value === "None") {
      var supplierData = {
        fullName: "None",
        supplierIdNumber: "None",
      };
      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          supplierName: supplierData.fullName,
          supplierIdNumber: supplierData.supplierIdNumber,
        },
      }));
    } else {
      this.state.supplierData.forEach((element) => {
        if (element.supplierIdNumber === key) {
          this.setState((prevState) => ({
            ...prevState,
            data: {
              ...prevState.data,
              supplierName: element.value,
              supplierIdNumber: element.key,
            },
          }));
        }
      });
    }
  };

  getAllPeriodListing = () => {
    Axios.get("/fy/getAllActivePeriods")
      .then(async (res) => {
        var fiscalYearLists = [];
        res.data.forEach((element) => {
          var fy = Object.keys(element);

          fiscalYearLists.push(fy[0]);
        });
        await this.setState({
          fyList: fiscalYearLists,
          resData: res.data,
        });

        this.getCurrentPeriod();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getCurrentPeriod() {
    Axios.get("/fy/getCurrentPeriod")
      .then(async (res) => {
        await this.setState((prevState) => ({
          ...prevState,
          fiscalYear: res.data.fiscalYear,
          currentPeriod: res.data.name,
        }));
        this.getPeriodList();
      })
      .catch((err) => {
        console.log(err);
      });
  }
  getPeriodList() {
    var pLists = [];
    this.state.resData.map((val) =>
      Object.keys(val).includes(this.state.fiscalYear)
        ? (pLists = val[this.state.fiscalYear])
        : null
    );
    this.setState({
      periodList: pLists,
    });
  }

  handleFYChangeView = async (e) => {
    let Fy = e.target.value;
    await this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        fiscalYear: Fy,
      },
    }));
    this.getPeriodList();
  };

  handlePeriodChange = async (e) => {
    let Py = e.target.value;
    console.log({ Py });
    await this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        period: Py,
      },
    }));
    console.log(this.state.data.period);
  };

  handleTextChange = async (e) => {
    var targetName = e.target.name;
    var value = e.target.value;
    await this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [targetName]: value,
      },
    }));
  };

  render() {
    const { classes } = this.props;

    function a11yProps(index) {
      return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
      };
    }

    return (
      <>
        <HeaderTitle title="Payment Voucher" />
        <Grid container justify="center" className={classes.tabMargin}>
          <Tabs
            variant="scrollable"
            value={this.state.value}
            onChange={this.handleChange}
            aria-label="Vertical tabs example"
            indicatorColor="primary"
          >
            <Tab
              label="Payment Voucher Entry"
              {...a11yProps(0)}
              style={{
                minWidth: 50,
                fontSize: "12px",
              }}
            />
            <Tab
              label={
                <Badge
                  badgeContent={this.state.authDataList.length}
                  color="primary"
                  style={{ paddingRight: "11px" }}
                >
                  Post Payment Voucher
                </Badge>
              }
              {...a11yProps(1)}
              style={{
                minWidth: "190px",
                fontSize: "12px",
              }}
            />
            <Tab
              label="Authorized Payment Voucher"
              {...a11yProps(2)}
              style={{
                minWidth: 50,
                fontSize: "12px",
              }}
            />
          </Tabs>
        </Grid>

        <AddPaymentVoucher
          value={this.state.value}
          index={0}
          getTableDataForAuth={this.getTableDataForAuth}
        ></AddPaymentVoucher>
        <PaymentVoucherAuthorization
          visible={this.state.auth}
          data={this.state.data}
          animate={this.authAnimate}
          animateOut={this.authAnimateOut}
          dataList={this.state.authDataList}
          handleViewCounter={this.handleViewCounter}
          getTableDataForAuth={this.getTableDataForAuth}
          getTableDataForList={this.getTableDataForList}
          handleFyChange={this.handleFyChange}
          handleVoucherCodeChange={this.handleVoucherCodeChange}
          handleDateFrom={this.handleDateFrom}
          handleDateTo={this.handleDateTo}
          voucherList={this.state.authVoucherList}
          getSubAccountListforAuth={this.getSubAccountListforAuth}
          handleTextChange={this.handleTextChange}
          handleSupplierSelect={this.handleSupplierSelect}
          handleSupplierIdSelect={this.handleSupplierIdSelect}
          supplierOptionList={this.state.supplierOptionList}
          idOptionList={this.state.idOptionList}
          fyList={this.state.fyList}
          periodList={this.state.periodList}
          handleFyChangeView={this.handleFYChangeView}
          handlePeriodChange={this.handlePeriodChange}
        ></PaymentVoucherAuthorization>
        <PaymentVoucherList
          visible={this.state.list}
          animate={this.listAnimate}
          animateOut={this.listAnimateOut}
          data={this.state.dataToSend}
          dataList={this.state.listDataList}
          handleFyChangeView={this.handleFyChangeView}
          handleVoucherCodeChange={this.handleVoucherCodeChange}
          handleDateFrom={this.handleDateFrom}
          handleDateTo={this.handleDateTo}
          voucherList={this.state.listVoucherList}
        ></PaymentVoucherList>
      </>
    );
  }
}

export default withStyles(styles)(PaymentVoucher);
