import React, { Component } from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  TextField,
  Button,
  withStyles,
  MenuItem,
} from "@material-ui/core";
import SalesForm from "../components/SalesForm";
import Axios from "axios";
import adbs, { bs2ad } from "ad-bs-converter";
import dateFormat from "dateformat";
import SalesConfirm from "../components/Sales/SalesConfirm";
import UserContext from "../utils/UserContext";
import HeaderTitle from "../components/InterfaceElements/HeaderTitle";
// import ReactToPrint from "react-to-print";
import VoucherNumberRestriction from "../components/InterfaceElements/VoucherNumberRestriction";
import { withRouter } from "react-router-dom";
import ReactToPrint from "react-to-print";
import SalesInvoice3mm from "../components/PrintableComponents/SalesInvoice3mm";
import SalesInvoice3mmVAT from "../components/PrintableComponents/SalesInvoice3mmVAT";
import SalesInvoice3mmAbb from "../components/PrintableComponents/SalesInvoice3mmAbb";
// import SalesInvoice3mm from "../components/PrintableComponents/SalesInvoice3mm";

const styles = (theme) => ({
  root: {
    [theme.breakpoints.down("md")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px",
    },
  },
  resMargin: {
    [theme.breakpoints.up("lg")]: {
      marginBottom: "41px",
    },
  },
});

class Sales extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      num: "",
      aboveAbb: false,
      invoicingMethod: "",
      cashButtonColor: "primary",
      chequeButtonColor: "",
      creditButtonColor: "",
      loyaltyButtonColor: "",
      cardButtonColor: "",
      staticBillInfo: {
        billNo: "",
        soldBy: "",
        invoiceDate: "",
        nepaliDate: "",
      },
      billAmount: 0,
      change: 0,
      tender: 0,
      paymentMethod: "cash",
      saveDetails: {
        billTo: "",
        pan: "",
        address: "",
        salesOrderNumber: "",
        ginNumber: "",
        chalanNumber: "",
        salesDetail: [],
        subTotal: 0,
        totalDiscount: 0,
        totalTax: 0,
        period: "",
        fiscalYear: "",
      },
      customerData: {
        id: "",
        fullName: "",
        address: "",
        mobileNo: "",
        email: "",
        pan: "",
      },
      customer: "None",
      open: false,
      printOpen: false,
      salesNoList: [],
      voucherNumberError: false,
      voucherNumber: "",
      windowHeight: "",
      windowWidth: "",
      emptyBill: false,
      emptyTender: false,
      errorMessage: "",
      errorBillMessage: "",

      grossTotal: 0,
      dataForCalculation: [],
    };
    this.changeBillAmount = this.changeBillAmount.bind(this);
    this.changeSaveDetails = this.changeSaveDetails.bind(this);
  }

  handleOpen = async () => {
    if(this.state.billAmount < 1) {
      this.setState({ 
        emptyBill: true,
        errorBillMessage: "Bill amount should not be empty",
      });
      return;
    }
    if (this.state.paymentMethod === "cash") {
      if (this.state.tender === 0 || this.state.tender === "") {
        this.setState({
          emptyTender: true,
          errorMessage: "Tender should not be empty",
        });
        return;
      }
      if (parseFloat(this.state.tender) < parseFloat(this.state.billAmount)) {
        this.setState({
          emptyTender: true,
          errorMessage: "Tender Amount is less than Bill Amount",
        });
        return;
      }
    }

    this.setState({ open: true });
  };

  resetData = () => {
    this.setState((prevState) => ({
      ...prevState,
      cashButtonColor: "primary",
      chequeButtonColor: "",
      creditButtonColor: "",
      loyaltyButtonColor: "",
      cardButtonColor: "",
      staticBillInfo: {
        billNo: "",
        soldBy: "",
      },
      billAmount: 0,
      change: 0,
      tender: "",
      paymentMethod: "cash",
      saveDetails: {
        ...prevState.saveDetails,
        billTo: "",
        pan: "",
        address: "",
        salesOrderNumber: "",
        ginNumber: "",
        chalanNumber: "",
        subTotal: 0,
        totalDiscount: 0,
        totalTax: 0,
      },
      customerData: {
        id: "",
        fullName: "",
        address: "",
        mobileNo: "",
        email: "",
        pan: "",
      },
      customer: "None",
      open: false,
      printOpen: false,
      emptyBill: false,
      emptyTender: false,
      errorMessage: "",
      dataForCalculation: [],
    }));

    Axios.get("pos/sales/getSalesBillInfo")
      .then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          staticBillInfo: {
            ...prevState.staticBillInfo,
            billNo: res.data.billNo,
            soldBy: res.data.soldBy,
            invoiceDate: res.data.invoiceDate,
          },
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  async changeBillAmount(amount, grossTotal, dataForCalculation) {
    await this.setState({
      billAmount: amount,
      grossTotal: grossTotal,
      dataForCalculation: dataForCalculation,
    });
  }

  changeSaveDetailsIndividual = async (target, value) => {
    await this.setState((prevState) => ({
      ...prevState,
      saveDetails: {
        ...prevState.saveDetails,
        [target]: value,
      },
    }));
  };

  changeSaveDetails(obj) {
    this.setState((prevState) => ({
      ...prevState,
      saveDetails: obj,
    }));

  }

  handlePrint = () => {
    this.ref.handlePrint();
    this.resetData();
  };

  handleSubmit = () => {
    console.log();
    let transactionDateTo = (this.state.staticBillInfo.invoiceDate.split("-"));
    
    let tdt = (
      transactionDateTo[0] +
        "/" +
        transactionDateTo[1] +
        "/" +
        transactionDateTo[2]
    );
      let dateToArr = bs2ad(tdt);
      
      let finalDateToYear = dateToArr.year;
      let finalDateToMonth = dateToArr.month;
      let finalDateToDay = dateToArr.day; 

      if(finalDateToMonth < 10) {
        finalDateToMonth = "0" + finalDateToMonth;
      }

      if(finalDateToDay < 10) {
        finalDateToDay = "0" + finalDateToDay;
      }
      let finalDateTo = finalDateToYear + "-" + finalDateToMonth + "-" + finalDateToDay;

    var saveDetails = this.state.saveDetails;
    //saveDetails["totalTax"] = 0;

    var toSend = {};
    if (
      this.state.customerData.id === "" ||
      this.state.customerData.id === "None"
    ) {
      toSend = {
        ...saveDetails,
        enteredBy: saveDetails.billTo,
        soldBy: this.state.staticBillInfo.soldBy,
        tenderAmount: this.state.tender,
        totalAmount: this.state.billAmount,
        invoiceDate: finalDateTo,
        saleDate: finalDateTo,
        nepaliDate: this.state.staticBillInfo.nepaliDate,
        paymentMode: this.state.paymentMethod,
        change: this.state.change,
        invoiceNumber: this.state.voucherNumber,
        totalTax: this.state.saveDetails.totalTax,
      };
    } else {
      toSend = {
        ...saveDetails,
        enteredBy: saveDetails.billTo,
        soldBy: this.state.staticBillInfo.soldBy,
        tenderAmount: this.state.tender,
        totalAmount: this.state.billAmount,
        invoiceDate: finalDateTo,
        saleDate: finalDateTo,
        nepaliDate: this.state.staticBillInfo.nepaliDate,
        paymentMode: this.state.paymentMethod,
        change: this.state.change,
        invoiceNumber: this.state.voucherNumber,
        totalTax: this.state.saveDetails.totalTax,
        customer: {
          id: this.state.customerData.id,
        },
      };
    }

    const token = this.context;

    Axios.post(
      "pos/sales/saveValues/" + this.state.staticBillInfo.billNo,
      toSend
    )
      .then((res) => {
        this.handlePrint();

        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000,
        };

        token.setSnackbarProps({ snackbarProps: snackbarProps });

        this.handleClose();
        this.resetTable();
        this.resetData();
      })
      .catch((err) => {
        var message = err.message;
        if (err.response !== undefined) {
          message = err.response.data;
        }
        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000,
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });
      });
  };

  getVoucherNumber = (num) => {
    this.setState({ voucherNumber: num });
  };

  getCompanyInformation = () => {
    Axios.get("/hrm/getGeneralCompanyInformation").then((res) => {
      this.setState((prevState) => ({
        ...prevState,
        invoicingMethod: res.data[0].invoicingMethod,
      }));
    });
  };

  componentDidMount() {
    
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    var todayDate = dateFormat(date, "yyyy-mm-dd");

    let transactionDate = (todayDate.split("-"));
    
    let tdf = (
      transactionDate[0] +
        "/" +
        transactionDate[1] +
        "/" +
        transactionDate[2]
    );
    let df= adbs.ad2bs(tdf)

    let finalYear = df.en.year;
    let finalMonth = df.en.month;
    let finalDay = df.en.day;
    if(df.en.month < 10){
      finalMonth = "0" + df.en.month;
    }    
    if(df.en.day < 10){
      finalDay = "0" + df.en.day;
    }

    let finalDate = finalYear + "-" + finalMonth + "-" + finalDay;

    this.setState((prevState) => ({
      ...prevState,
      staticBillInfo: {
        ...prevState.staticBillInfo,
        invoiceDate: finalDate,
        nepaliDate: finalDate,
      },
    }));

    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    });
    window.addEventListener("resize", this.updateWindowSize);

    Axios.get("pos/sales/getSalesBillInfo")
      .then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          staticBillInfo: {
            ...prevState.staticBillInfo,
            billNo: res.data.billNo,
            soldBy: res.data.soldBy,
           // invoiceDate: res.data.invoiceDate,
          },
        }));
      })
      .catch((err) => {
        console.log(err);
      });
    this.getCompanyInformation();
  }

  // componentWillUnmount() {
  //   window.removeEventListener("resize", this.updateWindowSize);
  // }

  updateWindowSize = () => {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    });
  };

  handleButtonClick = (val) => {
    this.setState((prevState) => ({
      ...prevState,
      cashButtonColor: "",
      chequeButtonColor: "",
      creditButtonColor: "",
      loyaltyButtonColor: "",
      cardButtonColor: "",
    }));

    this.setState((prevState) => ({
      ...prevState,
      [val]: "primary",
    }));

    switch (val) {
      case "cashButtonColor":
        this.setState({ paymentMethod: "cash" });

        break;

      case "chequeButtonColor":
        this.setState({ paymentMethod: "cheque" });
        break;

      case "creditButtonColor":
        this.setState({ paymentMethod: "credit" });
        break;

      case "loyaltyButtonColor":
        this.setState({ paymentMethod: "loyalty card" });
        break;

      case "cardButtonColor":
        this.setState({ paymentMethod: "card" });
        break;

      default:
        this.setState({ paymentMethod: "cash" });
        break;
    }
  };

  handleTender = async (evt) => {
    /* if (evt.target.value < 0) {
      this.setState({ tender: 0 });
    } else { */
    await this.setState({
      emptyTender: false,
      tender: evt.target.value,
      change: (evt.target.value - this.state.billAmount).toFixed(2),
    });
    // }
  };

  handleChange = (e) => {
    let val = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      voucherNumber: val,
    }));
  };

  checkBillFormat = () => {
    const token = this.context;

    let format = this.state.invoicingMethod;

    if (format === "PAN") {
      return (
        <SalesInvoice3mm
          ref={(el) => (this.componentRef = el)}
          billAmount={this.state.grossTotal}
          tenderAmount={this.state.tender}
          paymentMethod={this.state.paymentMethod}
          change={this.state.change}
          soldBy={this.state.staticBillInfo.soldBy}
          invoiceNumber={this.state.voucherNumber}
          invoiceDate={this.state.staticBillInfo.invoiceDate}
          customerData={this.state.customerData}
          salesDetails={this.state.saveDetails.salesDetail}
          totalDiscount={this.state.saveDetails.totalDiscount}
          display={false}
          billCount={0}
        />
      );
    } else if (
      format === "VAT" ||
      (format === "Abbreviated Tax Invoice" && this.state.billAmount > 5000)
    ) {
      if (
        format === "Abbreviated Tax Invoice" &&
        this.state.billAmount > 5000
      ) {
        // this.setState({ aboveAbb: true });
        var rowData = [];
        var billDiscount = 0;
        var billTaxAmount = 0;
        var grossBillAmount = 0;
        this.state.saveDetails.salesDetail.forEach((element) => {
          var abbToVatCalculation = {
            discount: element.discount,
            itemCode: element.itemCode,
            itemName: element.itemName,
            quantity: element.quantity,
            price: element.price,
            lineTotal: element.totalWithoutVat * element.quantity,
            taxRate: element.taxRate,
            totalWithoutVat: element.totalWithoutVat,
            totalWithVat: element.totalWithVat,
            totalDiscount: element.discount * element.quantity,
            totalTax:
              ((element.totalWithoutVat * element.quantity -
                element.discount * element.quantity) *
                element.taxRate) /
              100,
          };
          rowData.push(abbToVatCalculation);
        });
        rowData.forEach((val) => {
          billDiscount += val.totalDiscount;
          billTaxAmount += val.totalTax;
          grossBillAmount += val.lineTotal;
        });

        /*   this.setState((prevState) => ({
          ...prevState,
          salesDetails: rowData,
          totalTax: billTaxAmount,
          totalDiscount: billDiscount,
        })); */
        return (
          <SalesInvoice3mmVAT
            ref={(el) => (this.componentRef = el)}
            billAmount={grossBillAmount}
            tenderAmount={this.state.tender}
            paymentMethod={this.state.paymentMethod}
            change={this.state.change}
            soldBy={this.state.staticBillInfo.soldBy}
            invoiceNumber={this.state.voucherNumber}
            invoiceDate={this.state.staticBillInfo.invoiceDate}
            customerData={this.state.customerData}
            salesDetails={rowData}
            totalDiscount={billDiscount}
            totalTax={billTaxAmount}
            display={false}
            bilCount={0}
            dataForCalculation={this.state.dataForCalculation}
            aboveAbb={this.state.aboveAbb}
            subTotal={grossBillAmount}
          />
        );
      } else {
        
        return (
          <SalesInvoice3mmVAT
            ref={(el) => (this.componentRef = el)}
            billAmount={this.state.billAmount}
            tenderAmount={this.state.tender}
            paymentMethod={this.state.paymentMethod}
            change={this.state.change}
            soldBy={this.state.staticBillInfo.soldBy}
            invoiceNumber={this.state.voucherNumber}
            invoiceDate={this.state.staticBillInfo.invoiceDate}
            customerData={this.state.customerData}
            salesDetails={this.state.saveDetails.salesDetail}
            totalDiscount={this.state.saveDetails.totalDiscount}
            totalTax={this.state.saveDetails.totalTax}
            display={false}
            bilCount={0}
            dataForCalculation={this.state.dataForCalculation}
            aboveAbb={this.state.aboveAbb}
            subTotal={this.state.saveDetails.subTotal}
          />
        );
      }
    } else if (format === "Abbreviated Tax Invoice") {
      return (
        <SalesInvoice3mmAbb
          ref={(el) => (this.componentRef = el)}
          grossTotal={this.state.grossTotal}
          // billAmount={this.state.billAmount}
          tenderAmount={parseFloat(this.state.tender).toFixed(2)}
          paymentMethod={this.state.paymentMethod}
          change={parseFloat(this.state.change).toFixed(2)}
          soldBy={this.state.staticBillInfo.soldBy}
          invoiceNumber={this.state.voucherNumber}
          invoiceDate={this.state.staticBillInfo.invoiceDate}
          customerData={this.state.customerData}
          salesDetails={this.state.saveDetails.salesDetail}
          totalDiscount={this.state.saveDetails.totalDiscount}
          totalTax={this.state.saveDetails.totalTax}
          display={false}
          billCount={0}
        />
      );
    } else {
    }
  };

  render() {
    const { classes } = this.props;
    const buttonStyle = {
      paddingTop: "6px",
      paddingBottom: "6px",
      width: "100%",
    };

    const longButtonStyle = {
      paddingTop: "6px",
      paddingBottom: "6px",
      width: "100%",
      marginTop: "8px",
    };

    const lgButtonStyle = {
      paddingTop: "10px",
      paddingBottom: "10px",
      marginTop: "15px",
      width: "100%",
    };

    const textFieldStyle = {
      width: "100%",
      marginTop: "3px",
    };

    return (
      <div>
        <div style={{ backgroundColor: "#EAEDEF" }}>
          <HeaderTitle title="Sales" />

          {this.state.windowHeight < 1000 && this.state.windowWidth < 1900 ? (
            <div>
              <Grid container className={classes.root} spacing={1}>
                <Grid item xs={8}>
                  <Card
                    style={{
                      marginTop: "5px",
                      marginLeft: "7px",
                      width: "100%",
                      height: "auto",
                    }}
                  >
                    <CardContent>
                      <SalesForm
                        changeBillAmount={this.changeBillAmount}
                        changeSaveDetails={this.changeSaveDetails}
                        changeSaveDetailsIndividual={
                          this.changeSaveDetailsIndividual
                        }
                        getSalesNoList={this.getSalesNoList}
                        setReset={(reset) => (this.resetTable = reset)}
                        customerData={this.state.customerData}
                        customer={this.state.customer}
                        saveDetails={this.state.saveDetails}
                        changeCustomer={(customer) => {
                          this.setState({ customer: customer });
                        }}
                        changeCustomerData={(customerData) => {
                          this.setState({ customerData: customerData });
                        }}
                        invoicingMethod={this.state.invoicingMethod}
                        getVoucherNumber={this.getVoucherNumber}
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    style={{
                      marginTop: "5px",
                      marginLeft: "10px",
                      marginRight: "10px",
                      height: "89vh",
                    }}
                  >
                    <CardContent>
                      <Grid container className={classes.resMargin} spacing={1}>
                        <Grid item xs={6}>
                          <TextField
                            // select
                            id="outlined-dense"
                            label="Sales No."
                            value={this.state.voucherNumber}
                            //  onChange={this.handleChange}
                            margin="dense"
                            variant="outlined"
                            style={{
                              width: "100%",
                              marginTop: "0px",
                            }}
                          >
                            {this.state.salesNoList.map((value, i) => (
                              <MenuItem key={i} value={value}>
                                {value}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            id="outlined-dense"
                            label="Sales Date"
                            margin="dense"
                            value={this.state.staticBillInfo.invoiceDate}
                            variant="outlined"
                            style={{
                              width: "100%",
                              marginTop: "0px",
                            }}
                            InputLabelProps={{shrink: true}}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            id="outlined-dense"
                            label="Sold By"
                            margin="dense"
                            value={this.state.staticBillInfo.soldBy}
                            variant="outlined"
                            style={textFieldStyle}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="outlined-dense"
                            label="Invoice Date"
                            margin="dense"
                            value={this.state.staticBillInfo.invoiceDate}
                            variant="outlined"
                            style={textFieldStyle}
                            InputLabelProps={{shrink: true}}
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        justify="start"
                        className={classes.resMargin}
                        style={{ marginTop: "10px" }}
                        spacing={1}
                      >
                        <Grid item xs={4}>
                          <Button
                            onClick={() =>
                              this.handleButtonClick("cashButtonColor")
                            }
                            style={buttonStyle}
                            variant="contained"
                            color={this.state.cashButtonColor}
                            size="large"
                          >
                            Cash
                          </Button>
                        </Grid>
                        <Grid item xs={4}>
                          <Button
                            variant="contained"
                            onClick={() =>
                              this.handleButtonClick("chequeButtonColor")
                            }
                            style={buttonStyle}
                            color={this.state.chequeButtonColor}
                            size="large"
                          >
                            Cheque
                          </Button>
                        </Grid>

                        <Grid item xs={4}>
                          <Button
                            variant="contained"
                            onClick={() =>
                              this.handleButtonClick("creditButtonColor")
                            }
                            style={buttonStyle}
                            color={this.state.creditButtonColor}
                            size="large"
                          >
                            Credit
                          </Button>
                        </Grid>
                        <Grid item xs={5}>
                          <Button
                            variant="contained"
                            onClick={() =>
                              this.handleButtonClick("cardButtonColor")
                            }
                            style={longButtonStyle}
                            color={this.state.cardButtonColor}
                            size="large"
                          >
                            Card
                          </Button>
                        </Grid>
                        {/* <Grid item xs={7}>
                          <Button
                            variant="contained"
                            onClick={() =>
                              this.handleButtonClick("loyaltyButtonColor")
                            }
                            style={longButtonStyle}
                            color={this.state.loyaltyButtonColor}
                            size="large"
                          >
                            Loyalty Card
                          </Button>
                        </Grid> */}
                      </Grid>

                      <Grid container style={{ marginTop: "15px" }}>
                        <Grid item xs={6}>
                          <Typography style={{ marginTop: "15px" }}>
                            Bill Amount
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          {this.state.emptyBill ? (
                            <TextField
                            id="filled-dense-hidden-label"
                            margin="dense"
                            hiddenLabel error
                            //helperText={this.state.errorBillMessage}
                            type="number"
                            value={this.state.billAmount}
                            variant="filled"
                            placeholder="900"
                            fullWidth
                          />
                          ):(
                            <TextField
                            id="filled-dense-hidden-label"
                            margin="dense"
                            hiddenLabel
                            type="number"
                            value={this.state.billAmount}
                            variant="filled"
                            placeholder="900"
                            fullWidth
                          />
                          )}
                          
                        </Grid>
                        {this.state.paymentMethod === "cash" && (
                          <Grid item xs={6}>
                            <Typography style={{ marginTop: "15px" }}>
                              Tender Amount
                            </Typography>
                          </Grid>
                        )}
                        {this.state.paymentMethod === "cash" && (
                          <Grid item xs={6}>
                            {this.state.emptyTender ? (
                              <TextField
                                id="filled-dense-hidden-label"
                                margin="dense"
                                error
                                helperText={this.state.errorMessage}
                                type="number"
                                value={this.state.tender}
                                min={0}
                                onChange={this.handleTender}
                                variant="filled"
                                placeholder="0"
                                fullWidth
                              />
                            ) : (
                              <TextField
                                id="filled-dense-hidden-label"
                                margin="dense"
                                hiddenLabel
                                type="number"
                                value={this.state.tender}
                                min={0}
                                onChange={this.handleTender}
                                variant="filled"
                                placeholder="0"
                                fullWidth
                              />
                            )}
                          </Grid>
                        )}

                        <Grid item xs={6}>
                          <Typography style={{ marginTop: "15px" }}>
                            Change
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="filled-dense-hidden-label"
                            margin="dense"
                            hiddenLabel
                            type="number"
                            value={this.state.change}
                            variant="filled"
                            placeholder="100"
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        justify="center"
                        style={{ marginTop: "20px" }}
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={this.handleOpen}
                          style={{
                            paddingRight: "30px",
                            paddingLeft: "30px",
                            height: "50px",
                          }}
                        >
                          Save & Print
                        </Button>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div>
              <Grid container className={classes.root} spacing={1}>
                <Grid item xs={8}>
                  <Card
                    style={{
                      marginTop: "5px",
                      marginLeft: "7px",
                      width: "100%",
                      height: "auto",
                    }}
                  >
                    <CardContent>
                      <SalesForm
                        changeBillAmount={this.changeBillAmount}
                        changeSaveDetails={this.changeSaveDetails}
                        changeSaveDetailsIndividual={
                          this.changeSaveDetailsIndividual
                        }
                        setReset={(reset) => (this.resetTable = reset)}
                        customerData={this.state.customerData}
                        customer={this.state.customer}
                        saveDetails={this.state.saveDetails}
                        changeCustomer={(customer) => {
                          this.setState({ customer: customer });
                        }}
                        changeCustomerData={(customerData) => {
                          this.setState({ customerData: customerData });
                        }}
                        invoicingMethod={this.state.invoicingMethod}
                        getVoucherNumber={this.getVoucherNumber}
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    style={{
                      marginTop: "5px",
                      marginLeft: "10px",
                      marginRight: "10px",
                      paddingRight: "5px",
                      paddingLeft: "5px",
                      height: "82vh",
                    }}
                  >
                    <CardContent>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <TextField
                            // select
                            id="outlined-dense"
                            label="Sales No."
                            value={this.state.voucherNumber}
                            //  onChange={this.handleChange}
                            margin="dense"
                            variant="outlined"
                            style={{
                              width: "100%",
                              marginTop: "25px",
                            }}
                          >
                            {this.state.salesNoList.map((value, i) => (
                              <MenuItem key={i} value={value}>
                                {value}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            id="outlined-dense"
                            label="Sales Date"
                            margin="dense"
                            value={this.state.staticBillInfo.invoiceDate}
                            variant="outlined"
                            style={{
                              width: "100%",
                            }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            id="outlined-dense"
                            label="Sold By"
                            margin="dense"
                            value={this.state.staticBillInfo.soldBy}
                            variant="outlined"
                            style={{
                              width: "100%",
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="outlined-dense"
                            label="Invoice Date"
                            margin="dense"
                            value={this.state.staticBillInfo.invoiceDate}
                            variant="outlined"
                            style={{
                              width: "100%",
                            }}
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        justify="center"
                        style={{ marginTop: "40px", padding: "0px" }}
                        spacing={3}
                      >
                        <Grid item xs={6}>
                          <Button
                            onClick={() =>
                              this.handleButtonClick("cashButtonColor")
                            }
                            style={lgButtonStyle}
                            variant="contained"
                            color={this.state.cashButtonColor}
                            size="large"
                          >
                            Cash
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            variant="contained"
                            onClick={() =>
                              this.handleButtonClick("chequeButtonColor")
                            }
                            style={lgButtonStyle}
                            color={this.state.chequeButtonColor}
                            size="large"
                          >
                            Cheque
                          </Button>
                        </Grid>

                        <Grid item xs={6}>
                          <Button
                            variant="contained"
                            onClick={() =>
                              this.handleButtonClick("creditButtonColor")
                            }
                            style={lgButtonStyle}
                            color={this.state.creditButtonColor}
                            size="large"
                          >
                            Credit
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            variant="contained"
                            onClick={() =>
                              this.handleButtonClick("cardButtonColor")
                            }
                            style={lgButtonStyle}
                            color={this.state.cardButtonColor}
                            size="large"
                          >
                            Card
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            onClick={() =>
                              this.handleButtonClick("loyaltyButtonColor")
                            }
                            style={lgButtonStyle}
                            color={this.state.loyaltyButtonColor}
                            size="large"
                          >
                            Loyalty Card
                          </Button>
                        </Grid>
                      </Grid>

                      <Grid container style={{ marginTop: "40px" }}>
                        <Grid item xs={6}>
                          <Typography
                            gutterBottom
                            style={{ marginTop: "35px" }}
                          >
                            Bill Amount
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          {this.state.emptyBill ? (
                            <TextField
                            id="filled-dense-hidden-label"
                            margin="dense"
                            hiddenLabel
                            style={{ marginTop: "25px" }}
                            error
                            //helperText={this.state.errorBillMessage}
                            type="number"
                            value={this.state.billAmount}
                            variant="filled"
                            placeholder="900"
                            fullWidth
                          />
                          ) : (
                            <TextField
                            id="filled-dense-hidden-label"
                            margin="dense"
                            hiddenLabel
                            style={{ marginTop: "25px" }}
                            type="number"
                            value={this.state.billAmount}
                            variant="filled"
                            placeholder="900"
                            fullWidth
                          />
                          )}
                          
                        </Grid>
                        {this.state.paymentMethod === "cash" && (
                          <Grid item xs={6}>
                            <Typography
                              gutterBottom
                              style={{ marginTop: "35px" }}
                            >
                              Tender Amount
                            </Typography>
                          </Grid>
                        )}

                        {this.state.paymentMethod === "cash" && (
                          <Grid item xs={6}>
                            {this.state.emptyTender ? (
                              <TextField
                                id="filled-dense-hidden-label"
                                margin="dense"
                                error
                                helperText={this.state.errorMessage}
                                type="number"
                                style={{ marginTop: "25px" }}
                                value={this.state.tender}
                                min={0}
                                onChange={this.handleTender}
                                variant="filled"
                                placeholder="0"
                                fullWidth
                              />
                            ) : (
                              <TextField
                                id="filled-dense-hidden-label"
                                margin="dense"
                                hiddenLabel
                                style={{ marginTop: "25px" }}
                                type="number"
                                value={this.state.tender}
                                min={0}
                                onChange={this.handleTender}
                                variant="filled"
                                placeholder="0"
                                fullWidth
                              />
                            )}
                          </Grid>
                        )}

                        <Grid item xs={6}>
                          <Typography style={{ marginTop: "35px" }}>
                            Change
                          </Typography>
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            id="filled-dense-hidden-label"
                            margin="dense"
                            hiddenLabel
                            style={{ marginTop: "25px" }}
                            type="number"
                            value={this.state.change}
                            variant="filled"
                            placeholder="100"
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          container
                          justify="center"
                          style={{ marginTop: "30px" }}
                        >
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={this.handleOpen}
                            style={{
                              paddingRight: "30px",
                              paddingLeft: "30px",
                              height: "50px",
                            }}
                          >
                            Save & Print
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          )}

          <SalesConfirm
            open={this.state.open}
            printOpen={this.state.printOpen}
            handlePrintClose={() => {
              this.setState({ printOpen: false });
            }}
            handlePrintOpen={() => {
              this.setState({ printOpen: true });
            }}
            handleClose={this.handleClose}
            billAmount={this.state.billAmount}
            tenderAmount={this.state.tender}
            paymentMethod={this.state.paymentMethod}
            change={this.state.change}
            handleSubmit={this.handleSubmit}
          />

          <ReactToPrint
            ref={(ref) => (this.ref = ref)}
            trigger={() => <div>Print</div>}
            content={() => this.componentRef}
            onAfterPrint={() => this.resetData}
          />

          {this.checkBillFormat()}

          <VoucherNumberRestriction
            open={this.state.voucherNumberError}
          ></VoucherNumberRestriction>
        </div>
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(Sales));
