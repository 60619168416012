import React, { Component } from "react";
import {
  CardContent,
  Button,
  withStyles,
  Card,
  Grid,
  TextField,
  MenuItem,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Typography,
  TableContainer,
  Modal,
  Backdrop,
  Fade,
  CircularProgress,
} from "@material-ui/core";
import Axios from "axios";
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExcel,
  faFilePdf,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import UserContext from "../utils/UserContext";
import { withRouter } from "react-router-dom";
import ReactToPrint from "react-to-print";
import BalanceSheetPrint from "../components/PrintableComponents/BalanceSheetPrint";
import jsPDF from "jspdf";
import "jspdf-autotable";
import adbs, { bs2ad } from "ad-bs-converter";
import dateFormat from "dateformat";
import "nepali-datepicker-reactjs/dist/index.css";
import NepaliDatepicker from 'nepali-datepicker-and-dateinput';

const styles = (theme) => ({
  tabMargin: {
    padding: "5px",
    height: "auto",
    width: "70%",
    margin: "10px",
    marginBottom: "100px",
    [theme.breakpoints.down("md")]: {
      marginTop: "100px",
      width: "90%",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "65px",
    },
  },
  root: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "150px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
    },
  },
});

class BalanceSheet extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      date: "",
      fyList: [],
      distinctFyList: [],
      fiscalYear: "",
      currentFy: "",
      balanceSheetChilds: [],
      liabilities: [],
      assets: [],
      excelData: [],
      totalLiabilities: 0,
      totalAssets: 0,
      companyName: "",
      companyAddress: "",
      companyContact: "",
      fiscalYearDateFrom: "",
      data: { netLoss: 0, netProfit: 0 },
      open: false,
    };
  }

  componentDidMount() {
    this.getAllPeriodListing();
    this.getCompanyName();
    this.getFiscalStartFrom();

    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    var todayDate = dateFormat(date, "yyyy-mm-dd");
    console.log(todayDate)

    let transactionDateTo = (todayDate.split("-"));
    
    let tdf = (
      transactionDateTo[0] +
        "/" +
        transactionDateTo[1] +
        "/" +
        transactionDateTo[2]
    );

    let df= adbs.ad2bs(tdf)

    let finalDateToYear = df.en.year;
    let finalDateToMonth = df.en.month;
    let finalDateToDay = df.en.day;
    if(df.en.month < 10){
      finalDateToMonth = "0" + df.en.month;
    }    
    if(df.en.day < 10){
      finalDateToDay = "0" + df.en.day;
    }

    let finalDateTo = finalDateToYear + "-" + finalDateToMonth + "-" + finalDateToDay;

    this.setState((prevState) => ({
      ...prevState,
      filterFields: {
        ...prevState.filterFields,
        dateTo: finalDateTo,
        dateTo: finalDateTo,
      },
    }));
  }

  getFiscalStartFrom = () => {
    Axios.get("fy/findStartOfFiscal")
      .then((res) => this.setState({ fiscalYearDateFrom: res.data }))
      .catch((err) => console.log(err));
  };

  onDoubleClick = (accountCode) => {
    const token = this.context;

    let newFilterFields = token.filterFields;
    newFilterFields.dateFrom = this.state.fiscalYearDateFrom;
    newFilterFields.dateTo = this.state.date;

    token.setAccountCode({ value: accountCode });
    token.setFilterFields({ value: newFilterFields });

    this.props.history.push("/booksOfAccount/view");
  };

  onDoubleClickSubAccount = (accountCode, subAccountCode) => {
    const token = this.context;

    let newFilterFields = token.filterFields;
    newFilterFields.dateFrom = this.state.fiscalYearDateFrom;
    newFilterFields.dateTo = this.state.date;

    token.setAccountCode({ value: accountCode });
    token.setSubAccountCode({ value: subAccountCode });
    token.setFilterFields({ value: newFilterFields });

    this.props.history.push("/booksOfAccount/view");
  };

  getTableData = () => {
    this.setState((prevState) => ({
      ...prevState,
      open: true,
    }));
    
  
      
    const token = this.context;
    let filterFields = token.filterFields;

    let obj = {
      fiscalYear: this.state.currentFy,
      dateTo: this.state.date,
    };

    if (filterFields.dateTo !== "") {
      obj.dateTo = filterFields.dateTo;
    }

    Axios.post("pos/balanceSheet/get", obj)
      .then((res) => {
        let liabilitiesList = [];
        let assetsList = [];
        let totalLiabilities = 0;
        let totalAssets = 0;

        // let totalAssetRows = 0;
        // let totalLiabilitiesRows = 0;

        res.data.balanceSheetChilds.map((val) => {
          if (val.primaryGroup === "Asset") {
            let safeToPushAsset = true;
            assetsList.map((asset) =>
              asset.name === val.accountGroup ? (safeToPushAsset = false) : null
            );

            if (safeToPushAsset) {
              assetsList.push({ name: val.accountGroup });
              // totalAssetRows++;
            }
          } else {
            let safeToPushLiability = true;
            liabilitiesList.map((liablity) =>
              liablity.name === val.accountGroup
                ? (safeToPushLiability = false)
                : null
            );
            if (safeToPushLiability) {
              liabilitiesList.push({ name: val.accountGroup });

              // totalLiabilitiesRows++;
            }
          }

          return null;
        });

        liabilitiesList.map((val) => {
          let childs = [];
          let totalAmount = 0;

          res.data.balanceSheetChilds.map((childVal) => {
            if (childVal.accountGroup === val.name) {
              childs.push({
                name: childVal.accountName,
                code: childVal.accountCode,
              });
              totalLiabilities += childVal.amount;
              totalAmount += childVal.amount;
            }
            return null;
          });

          val.accountList = childs.filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.code === item.code)
          );

          val.amount = totalAmount;

          val.accountList.forEach((element) => {
            let totalAmount = 0;
            let subAccountChilds = [];

            //total amount
            res.data.balanceSheetChilds.map((val) =>
              val.accountName === element.name
                ? (totalAmount += val.amount)
                : null
            );

            element.amount = totalAmount;

            //sub account list
            res.data.balanceSheetChilds.map((val) =>
              val.accountName === element.name
                ? subAccountChilds.push(val)
                : null
            );

            element.childs = subAccountChilds;
            element.expanded = false;
          });

          // totalLiabilitiesRows += val.accountList.length;

          return null;
        });

        assetsList.map((val) => {
          let childs = [];

          res.data.balanceSheetChilds.map((childVal) => {
            if (childVal.accountGroup === val.name) {
              childs.push({
                name: childVal.accountName,
                code: childVal.accountCode,
              });
              totalAssets += childVal.amount;
            }

            return null;
          });

          val.accountList = childs.filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.code === item.code)
          );

          val.accountList.forEach((element) => {
            let totalAmount = 0;
            let subAccountList = [];

            //total amount
            res.data.balanceSheetChilds.map((val) =>
              val.accountName === element.name
                ? (totalAmount += val.amount)
                : null
            );

            element.amount = totalAmount;

            //sub account list
            res.data.balanceSheetChilds.map((val) =>
              val.accountName === element.name ? subAccountList.push(val) : null
            );

            element.childs = subAccountList;
            element.expanded = false;
          });

          // totalAssetRows += val.accountList.length;

          return null;
        });

        // if (totalLiabilitiesRows > totalAssetRows) {
        //   for (
        //     let index = 0;
        //     index < totalLiabilitiesRows - totalAssetRows;
        //     index++
        //   ) {
        //     assetsList.push({ name: "", accountList: [] });
        //   }
        // } else {
        //   for (
        //     let index = 0;
        //     index < totalAssetRows - totalLiabilitiesRows;
        //     index++
        //   ) {
        //     liabilitiesList.push({ name: "", accountList: [] });
        //   }
        // }

        //if (res.data.netProfit === 0) {
        //  totalAssets += res.data.netLoss;
        //} else {
        totalLiabilities += res.data.netProfit;
        // }

        this.setState((prevState) => ({
          ...prevState,
          liabilities: liabilitiesList,
          assets: assetsList,
          totalAssets,
          totalLiabilities,
          data: res.data,
        }));

        let allAssets = [];
        let allLiabilites = [];

        liabilitiesList.forEach((element) => {
          allLiabilites.push({
            accountCode: "",
            accountName: element.name,
            amount: "",
          });

          element.accountList.map((val) =>
            allLiabilites.push({
              accountCode: val.code,
              amount: val.amount,
              accountName: val.name,
            })
          );
        });

        assetsList.forEach((element) => {
          allAssets.push({
            accountCode: "",
            accountName: element.name,
            amount: "",
          });

          element.accountList.map((val) =>
            allAssets.push({
              accountCode: val.code,
              amount: val.amount,
              accountName: val.name,
            })
          );
        });

        //-------EXCEL DATA--------
        var format = new Intl.NumberFormat("ne-NP", {
          minimumFractionDigits: 2,
        });
        let excelData = [];

        excelData.push({
          accountCode: "",
          accountName: "Fiscal Year",
          amount: this.state.currentFy,
        });

        excelData.push({
          accountCode: "",
          accountName: "As on",
          amount: this.state.date,
        });

        excelData.push({
          accountCode: "",
          accountName: "",
          amount: "",
        });

        excelData.push({
          accountCode: "Code",
          accountName: "Particulars",
          amount: "Amounts",
        });

        excelData.push({
          accountCode: "EQUITY & LIABILITIES",
          accountName: "",
          amount: "",
        });

        liabilitiesList.map((val) => {
          if (val.name === null || undefined) {
            return null;
          } else {
            excelData.push({
              accountCode: "",
              accountName: val.name,
              amount: "",
            });
          }

          val.accountList.map((child) =>
            excelData.push({
              accountCode: child.code,
              accountName: child.name,
              amount: format.format(child.amount),
            })
          );
          return null;
        });

        excelData.push({
          accountCode: "",
          accountName: "Net (Profit)/Loss",
          amount: res.data.netProfit,
        });

        excelData.push({
          accountCode: "",
          accountName: "Total Equity & Liabilities",
          amount: format.format(totalLiabilities),
        });

        excelData.push({
          accountCode: "",
          accountName: "",
          amount: "",
        });

        excelData.push({
          accountCode: "ASSETS",
          accountName: "",
          amount: "",
        });

        assetsList.map((val) => {
          if (val.name === null || undefined) {
            return null;
          } else {
            excelData.push({
              accountCode: "",
              accountName: val.name,
              amount: "",
            });
          }

          val.accountList.map((child) =>
            excelData.push({
              accountCode: child.code,
              accountName: child.name,
              amount: format.format(Math.abs(child.amount)),
            })
          );
          return null;
        });

        excelData.push({
          accountCode: "",
          accountName: "",
          amount: "",
        });

        excelData.push({
          accountCode: "",
          accountName: "Total Assets",
          amount: format.format(Math.abs(this.state.totalAssets)),
        });

        this.setState((prevState) => ({
          ...prevState,
          excelData,
          open: false,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getAllPeriodListing = () => {
    Axios.get("/fy/findTillCurrentPeriod")
      .then((res) => {
        var data = [];
        var nonDistinctFyList = [];
        res.data.forEach((element) => {
          var item = {
            status: element.currentStatus,
            fiscalYear: element.fiscalYear,
          };
          data.push(item);
          nonDistinctFyList.push(item.fiscalYear);
        });
        this.setState({
          fyList: data,
          distinctFyList: [...new Set(nonDistinctFyList)],
        });
        this.getCurrentPeriod();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleFiscalYearChange = async (e) => {
    e.stopPropagation();
    await this.setState((prevState) => ({
      ...prevState,
      currentFy: e.target.value,
    }));
    // this.getTableData();
  };

  getCurrentPeriod() {
    this.state.fyList.forEach((element) => {
      if (element.status === true) {
        this.setState((prevState) => ({
          ...prevState,
          currentFy: element.fiscalYear,
        }));
      }
    });

    // this.getTableData();
  }

  handleDateChange= (name, dateInMilli, bsDate, adDate) => {

    this.setState((prevState) => ({
      ...prevState,
        date: adDate,
    }));
  };

  applySearch = () => {
    this.getTableData();
  };

  expandLiabilites = (accountCode) => {
    let liabilitiesList = this.state.liabilities;
    let assetsList = this.state.assets;

    liabilitiesList.forEach((element) => {
      let accountList = element.accountList;

      accountList.map((val) =>
        val.code === accountCode ? (val.expanded = !val.expanded) : null
      );
    });

    //remove empty rows
    liabilitiesList = liabilitiesList.filter((val) => val.name !== "");
    assetsList = assetsList.filter((val) => val.name !== "");

    //get total rows
    // let totalLiabilitiesRows = this.getLiabilitiesRows(liabilitiesList);
    // let totalAssetsRows = this.getAssetsRows(assetsList);

    //add remaining rows on lesser side
    // if (totalLiabilitiesRows > totalAssetsRows) {
    //   for (
    //     let index = 0;
    //     index < totalLiabilitiesRows - totalAssetsRows;
    //     index++
    //   ) {
    //     assetsList.push({ name: "", accountList: [] });
    //   }
    // } else {
    //   for (
    //     let index = 0;
    //     index < totalAssetsRows - totalLiabilitiesRows;
    //     index++
    //   ) {
    //     liabilitiesList.push({ name: "", accountList: [] });
    //   }
    // }

    this.setState((prevState) => ({
      ...prevState,
      liabilities: liabilitiesList,
      assets: assetsList,
    }));
  };

  getAssetsRows = (assetsList) => {
    let totalAssetsRows = 0;

    assetsList.forEach((element) => {
      totalAssetsRows++;

      element.accountList.forEach((accountElement) => {
        if (element.name !== "") totalAssetsRows++;

        if (accountElement.expanded) {
          accountElement.childs.map((val) => totalAssetsRows++);
        }
      });
    });

    return totalAssetsRows;
  };

  getLiabilitiesRows = (liabilitiesList) => {
    let totalLiabilitiesRows = 0;

    liabilitiesList.forEach((element) => {
      if (element.name !== "") totalLiabilitiesRows++;

      element.accountList.forEach((accountElement) => {
        totalLiabilitiesRows++;

        if (accountElement.expanded) {
          accountElement.childs.map((val) => totalLiabilitiesRows++);
        }
      });
    });

    return totalLiabilitiesRows;
  };

  expandAssets = (accountCode) => {
    let assetsList = this.state.assets;
    let liabilitiesList = this.state.liabilities;

    assetsList.forEach((element) => {
      let accountList = element.accountList;

      accountList.map((val) =>
        val.code === accountCode ? (val.expanded = !val.expanded) : null
      );

      //remove empty rows
      liabilitiesList = liabilitiesList.filter((val) => val.name !== "");
      assetsList = assetsList.filter((val) => val.name !== "");

      //get total rows
      // let totalLiabilitiesRows = this.getLiabilitiesRows(liabilitiesList);
      // let totalAssetsRows = this.getAssetsRows(assetsList);

      //add remaining rows on lesser side
      // if (totalLiabilitiesRows > totalAssetsRows) {
      //   for (
      //     let index = 0;
      //     index < totalLiabilitiesRows - totalAssetsRows;
      //     index++
      //   ) {
      //     assetsList.push({ name: "", accountList: [] });
      //   }
      // } else {
      //   for (
      //     let index = 0;
      //     index < totalAssetsRows - totalLiabilitiesRows;
      //     index++
      //   ) {
      //     liabilitiesList.push({ name: "", accountList: [] });
      //   }
      // }
    });

    this.setState((prevState) => ({
      ...prevState,
      assets: assetsList,
      liabilities: liabilitiesList,
    }));
  };

  getCompanyName() {
    Axios.get("/hrm/getGeneralCompanyInformation")
      .then((res) => {
        res.data.forEach((element) => {
          this.setState({
            companyName: element.name,
            companyAddress: element.street + ", " + element.city,
            companyContact: element.contactNumber,
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  generatePdf = () => {
    let company = this.state.companyName;
    let companyAddress = this.state.companyAddress;
    let companyContactNumber = this.state.companyContact;
    function header() {
      doc.setFont("Helvetica", "bold");
      doc.setFontSize(12);
      doc.text(300, 40, company, {
        align: "center",
      });
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(12);
      doc.text(300, 60, companyAddress, {
        align: "center",
      });
      doc.setFontSize(12);
      doc.text(300, 80, companyContactNumber, {
        align: "center",
      });
    }
    function footer() {
      doc.setFont("Helvetica", "italic");
      doc.setFontSize(9);
      doc.text(40, doc.internal.pageSize.height - 20, window.location.href);

      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      today = yyyy + "/" + mm + "/" + dd;
      doc.text(
        doc.internal.pageSize.width - 150,
        doc.internal.pageSize.height - 20,
        "Printed on: " + today
      );
    }

    var doc = new jsPDF("p", "pt", "a4", true);
    //current date
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(9);

    header();
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(12);
    doc.text(300, 130, "Balance Sheet", {
      align: "center",
    });

    // var x = 40;
    var y = 150;

    doc.setFont("Helvetica", "normal");
    doc.setFontSize(11);
    doc.text(260, y, "As on ");
    doc.setFont("Helvetica", "italic");
    doc.text(
      292,
      y,
      this.state.date === null || undefined ? "" : this.state.date
    );

    /*   y = y + 25;
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11);
      doc.text(x + 23, y, "As On:");
      doc.setFont("Helvetica", "italic");
      doc.text(
        120,
        y,
        this.state.date === null || undefined ? "" : this.state.date
      ); */

    //formatting Amount
    var format = new Intl.NumberFormat("ne-NP", {
      minimumFractionDigits: 2,
    });

    let liabilities = [];
    this.state.liabilities.map((val) => {
      if (val.name === null || undefined) {
        return null;
      } else {
        liabilities.push(["", val.name, ""]);
      }

      val.accountList.map((child) =>
        liabilities.push([child.code, child.name, format.format(child.amount)])
      );

      return null;
    });

    liabilities.push([
      "",
      "Total Equity & Liabilities    ",
      format.format(this.state.totalLiabilities),
    ]);

    let assets = [];
    this.state.assets.map((val) => {
      if (val.name === null || undefined) {
        return null;
      } else {
        assets.push(["", val.name, ""]);
      }

      val.accountList.map((child) =>
        assets.push([child.code, child.name, format.format(child.amount)])
      );

      return null;
    });

    assets.push([
      "",
      "Total Assets    ",
      format.format(Math.abs(this.state.totalAssets)),
    ]);

    //-------------FOR HEADER--------------
    doc.autoTable({
      styles: {
        overflow: "linebreak",
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.3,
        halign: "center",
        font: "helvetica",
        fontStyle: "bold",
        fontSize: 11,
      },

      bodyStyles: { valign: "top" },
      startY: y + 20,
      theme: "grid",
      columnStyles: {
        0: { cellWidth: 100 },
        1: { cellWidth: 295.3 },
        2: { cellWidth: 120 },
      },
      body: [["Code", "Particulars", "Amounts"]],
    });

    //---------FOR LIABILITIES---------
    // var startingPage = doc.internal.getCurrentPageInfo().pageNumber;
    doc.autoTable({
      styles: {
        overflow: "linebreak",
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.3,
        halign: "center",
        font: "helvetica",
      },

      bodyStyles: { valign: "top" },
      startY: doc.lastAutoTable.finalY,
      theme: "grid",

      columnStyles: {
        0: {
          fontSize: 10,
          cellWidth: 100,
          halign: "center",
        },
        1: {
          fontSize: 10,
          cellWidth: "auto",
          halign: "left",
        },
        2: {
          fontSize: 10,
          cellWidth: 120,
          halign: "right",
        },
      },
      didParseCell: function (data) {
        var rows = data.table.body;
        let arr = [];
        let firstItemIndex = [];
        firstItemIndex.push(rows.indexOf(rows[0]));

        rows.map((item) => {
          if (item.raw[0] === "" && item.raw[1] !== "" && item.raw[2] === "") {
            arr.push(rows.indexOf(item));
          }
          return null;
        });

        arr.map((index) => {
          if (data.row.index === index) {
            data.cell.styles.fontStyle = "bolditalic";
          }
          return null;
        });

        if (data.row.index === rows.length - 1) {
          data.cell.styles.fontStyle = "bold";
          data.cell.styles.halign = "right";
          data.cell.styles.lineWidth = 1.2;
        }
      },
      head: [
        [
          {
            content: "EQUITY & LIABILITIES",
            colSpan: 3,
            styles: { halign: "left" },
          },
        ],
      ],
      body: liabilities,
    });

    //---------------FOR EMPTY ROW----------------
    doc.autoTable({
      startY: doc.lastAutoTable.finalY,
      theme: "grid",
      columnStyles: {
        0: { cellWidth: 100 },
        1: { cellWidth: 295.3 },
        2: { cellWidth: 120 },
      },
      body: [
        [
          {
            content: "",
            colSpan: 3,
            styles: {
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              lineColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
        ],
      ],
    });

    //---------------FOR ASSETS---------------
    //doc.setPage(startingPage);
    doc.autoTable({
      styles: {
        overflow: "linebreak",
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.3,
        halign: "center",
        font: "helvetica",
      },

      bodyStyles: { valign: "top" },
      startY: doc.lastAutoTable.finalY,
      theme: "grid",
      columnStyles: {
        0: {
          fontSize: 10,
          cellWidth: 100,
          halign: "center",
        },
        1: {
          fontSize: 10,
          cellWidth: "auto",
          halign: "left",
        },
        2: {
          fontSize: 10,
          cellWidth: 120,
          halign: "right",
        },
      },
      didParseCell: function (data) {
        var rows = data.table.body;
        let arr = [];

        rows.map((item) => {
          if (item.raw[0] === "" && item.raw[1] !== "" && item.raw[2] === "") {
            arr.push(rows.indexOf(item));
          }
          return null;
        });

        arr.map((index) => {
          if (data.row.index === index) {
            data.cell.styles.fontStyle = "bolditalic";
          }
          return null;
        });

        if (data.row.index === rows.length - 1) {
          data.cell.styles.fontStyle = "bold";
          data.cell.styles.halign = "right";
          data.cell.styles.lineWidth = 1.2;
        }
      },

      showHead: "firstPage",
      head: [[{ content: "ASSETS", colSpan: 3, styles: { halign: "left" } }]],
      body: assets,
    });

    footer();
    const pages = doc.internal.getNumberOfPages();
    const pageWidth = doc.internal.pageSize.width; //Optional
    const pageHeight = doc.internal.pageSize.height; //Optional
    doc.setFontSize(10); //Optional
    for (let j = 1; j < pages + 1; j++) {
      let horizontalPos = pageWidth / 2; //Can be fixed number
      let verticalPos = pageHeight - 10; //Can be fixed number
      doc.setPage(j);
      doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
        align: "center",
      });
    }
    doc.save("Balance Sheet");
  };

  render() {
    const modal = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };
    const styledTableCell = {
      backgroundColor: "#F1F8FC",
      color: "#3F51B5",
      borderRight: "1px solid #EAEDEF",
      width: "300px",
      minWidth: "300px",
    };

    const snRow = {
      borderRight: "1px solid #EAEDEF",
      borderBottom: "1px solid #EAEDEF",
      width: "100px",
      height: "35px",
    };

    const particularRow = {
      borderRight: "1px solid #EAEDEF",
      borderBottom: "1px solid #EAEDEF",
      height: "35px",
      width: "200px",
      minWidth: "200px",
    };

    const amountRow = {
      borderRight: "1px solid #EAEDEF",
      borderBottom: "1px solid #EAEDEF",
      height: "35px",
      width: "100px",
      textAlign: "end",
    };

    const { classes } = this.props;

    var format = new Intl.NumberFormat("ne-NP", {
      minimumFractionDigits: 2,
    });

    return (
      <>
        <div
          style={{
            backgroundColor: "#EAEDEF",
          }}
        >
          <Grid container justify="center" className={classes.root}>
            <Card className={classes.tabMargin}>
              <CardContent>
                <Grid container>
                  <Grid container justify="space-between">
                    <Grid item xs={12} sm={6} style={{ marginBottom: "10px" }}>
                      <Grid container spacing={1}>
                        <Grid item>
                          <TextField
                            select
                            style={{
                              width: "150px",
                              minWidth: "130px",
                              marginTop: "5px",
                            }}
                            label="Fiscal Year"
                            name="period"
                            onChange={this.handleFiscalYearChange}
                            value={this.state.currentFy}
                          >
                            {this.state.distinctFyList.map((item, i) => (
                              <MenuItem key={i} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item style={{marginTop: "20px"}}>
                          <NepaliDatepicker
                            id="nepali-date"
                            onChange={this.handleDateChange}
                            label="As On"
                            value={this.state.date}
                            locale= "en"
                            showDefaultDate="true"
                            style={{height: "5px", width: "5px"}}
                          />
                        </Grid>
                        <Grid item>
                          <Button
                            variant="outlined"
                            color="primary"
                            style={{
                              marginTop: "20px",
                            }}
                            onClick={this.applySearch}
                          >
                            Apply
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Grid container justify="flex-end">
                        <Grid item>
                          <Button
                            variant="outlined"
                            color="primary"
                            style={{
                              minWidth: "100px",
                              width: "6vw",
                              margin: "3px",
                              height: "38px",
                              fontSize: "16px",
                            }}
                            onClick={this.generatePdf}
                          >
                            <FontAwesomeIcon
                              icon={faFilePdf}
                              color="#3f51b5"
                              size="30px"
                              style={{
                                marginRight: "10px",
                              }}
                            ></FontAwesomeIcon>
                            Pdf
                          </Button>
                        </Grid>

                        <Grid item>
                          <ReactToPrint
                            trigger={() => (
                              <Button
                                variant="outlined"
                                color="primary"
                                style={{
                                  minWidth: "100px",
                                  width: "6vw",
                                  margin: "3px",
                                  height: "38px",
                                  fontSize: "16px",
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faPrint}
                                  color="#3f51b5"
                                  size="30px"
                                  style={{
                                    marginRight: "10px",
                                  }}
                                ></FontAwesomeIcon>
                                Print
                              </Button>
                            )}
                            content={() => this.componentRef}
                          />
                        </Grid>

                        <Grid item>
                          <ExcelFile
                            filename="Balance Sheet"
                            element={
                              <Button
                                variant="outlined"
                                color="primary"
                                style={{
                                  minWidth: "100px",
                                  width: "6vw",
                                  margin: "3px",
                                  height: "38px",
                                  fontSize: "16px",
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faFileExcel}
                                  color="#3f51b5"
                                  size="30px"
                                  style={{
                                    marginRight: "10px",
                                  }}
                                ></FontAwesomeIcon>
                                Excel
                              </Button>
                            }
                          >
                            <ExcelSheet
                              data={this.state.excelData}
                              name="Balance Sheet"
                            >
                              <ExcelColumn value="accountCode" />
                              <ExcelColumn value="accountName" />
                              <ExcelColumn value="amount" />
                            </ExcelSheet>
                          </ExcelFile>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {this.state.open ? (
                    <>
                      <Grid
                        container
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "50px",
                          marginBottom: "50px",
                          height: "200px",
                        }}
                      >
                        <CircularProgress thickness="2" size="60px" />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Typography
                        variant="h5"
                        style={{ marginTop: "15px" }}
                        gutterBottom
                      >
                        Equity and Liabilities
                      </Typography>
                      <Grid container justify="center">
                        <TableContainer>
                          <Table
                            aria-label="customized table"
                            fixedHeader={false}
                            style={{
                              padding: 0,
                              border: "1px solid #EAEDEF",
                              borderTop: "0px",
                            }}
                            size="small"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  style={{
                                    backgroundColor: "#F1F8FC",
                                    color: "#3F51B5",
                                    borderRight: "1px solid #EAEDEF",
                                    width: "100px",
                                  }}
                                >
                                  Account Code
                                </TableCell>
                                <TableCell style={styledTableCell}>
                                  Account Name
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "100px",
                                    backgroundColor: "#F1F8FC",
                                    color: "#3F51B5",
                                    borderRight: "1px solid #EAEDEF",
                                  }}
                                >
                                  Amount
                                </TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {this.state.liabilities.map((val) => (
                                <>
                                  <TableRow>
                                    <TableCell style={snRow}></TableCell>
                                    <TableCell style={particularRow}>
                                      <Typography
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {val.name}
                                      </Typography>
                                    </TableCell>
                                    <TableCell style={amountRow}>
                                      <Typography
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {format.format(val.amount)}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  {val.accountList.map((childVal) => (
                                    <>
                                      <TableRow>
                                        <TableCell
                                          style={snRow}
                                          onDoubleClick={() =>
                                            this.onDoubleClick(childVal.code)
                                          }
                                        >
                                          <Typography>
                                            {childVal.code}
                                          </Typography>
                                        </TableCell>
                                        <TableCell style={particularRow}>
                                          <Grid container spacing={2}>
                                            <Grid item>
                                              {childVal.expanded ? (
                                                <ExpandMoreIcon
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    this.expandLiabilites(
                                                      childVal.code
                                                    )
                                                  }
                                                />
                                              ) : (
                                                <ChevronRightIcon
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    this.expandLiabilites(
                                                      childVal.code
                                                    )
                                                  }
                                                />
                                              )}
                                            </Grid>
                                            <Grid item>
                                              <Typography
                                                onDoubleClick={() =>
                                                  this.onDoubleClick(
                                                    childVal.code
                                                  )
                                                }
                                              >
                                                {childVal.name}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </TableCell>
                                        <TableCell style={amountRow}>
                                          <Typography>
                                            {format.format(childVal.amount)}
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                      {childVal.expanded
                                        ? childVal.childs.map((subAccount) => (
                                            <TableRow
                                              style={{ fontStyle: "italic" }}
                                            >
                                              <TableCell
                                                style={snRow}
                                                onDoubleClick={() =>
                                                  this.onDoubleClickSubAccount(
                                                    childVal.code,
                                                    subAccount.subAccountCode
                                                  )
                                                }
                                              >
                                                {subAccount.subAccountCode}
                                              </TableCell>

                                              <TableCell
                                                style={particularRow}
                                                onDoubleClick={() =>
                                                  this.onDoubleClickSubAccount(
                                                    childVal.code,
                                                    subAccount.subAccountCode
                                                  )
                                                }
                                              >
                                                {subAccount.subAccountName}
                                              </TableCell>
                                              <TableCell style={amountRow}>
                                                {format.format(
                                                  subAccount.amount
                                                )}
                                              </TableCell>
                                            </TableRow>
                                          ))
                                        : null}
                                    </>
                                  ))}
                                </>
                              ))}

                              <TableRow>
                                <TableCell style={snRow}></TableCell>
                                <TableCell style={particularRow}>
                                  <Grid container justify="flex-end">
                                    <Typography style={{ fontWeight: "bold" }}>
                                      NET (PROFIT)/Loss
                                    </Typography>
                                  </Grid>
                                </TableCell>
                                <TableCell style={amountRow}>
                                  <Typography style={{ fontWeight: "bold" }}>
                                    {format.format(this.state.data.netProfit)}
                                  </Typography>
                                </TableCell>
                              </TableRow>

                              <TableRow style={{ border: "5px solid #EAEDEF" }}>
                                <TableCell style={snRow}></TableCell>
                                <TableCell style={particularRow}>
                                  <Grid container justify="flex-end">
                                    <Typography style={{ fontWeight: "bold" }}>
                                      TOTAL EQUITY & LIABILITIES
                                    </Typography>
                                  </Grid>
                                </TableCell>
                                <TableCell style={amountRow}>
                                  <Typography style={{ fontWeight: "bold" }}>
                                    {format.format(this.state.totalLiabilities)}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>

                          <Typography
                            variant="h5"
                            style={{ marginTop: "15px" }}
                            gutterBottom
                          >
                            Assets
                          </Typography>

                          <Table
                            aria-label="customized table"
                            //   fixedHeader={false} style={{ width: "auto", tableLayout: "auto",  marginTop: "10px", marginBottom: "15px"  }}
                            fixedHeader={false}
                            style={{
                              border: "1px solid #EAEDEF",
                              borderTop: "0px",
                            }}
                            size="small"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  style={{
                                    backgroundColor: "#F1F8FC",
                                    color: "#3F51B5",
                                    borderRight: "1px solid #EAEDEF",
                                    width: "100px",
                                  }}
                                >
                                  Account Code
                                </TableCell>
                                <TableCell style={styledTableCell}>
                                  Account Name
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "100px",
                                    backgroundColor: "#F1F8FC",
                                    color: "#3F51B5",
                                    borderRight: "1px solid #EAEDEF",
                                  }}
                                >
                                  Amount
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.assets.map((val) => (
                                <>
                                  <TableRow>
                                    <TableCell style={snRow}></TableCell>
                                    <TableCell style={particularRow}>
                                      <Typography
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {val.name}
                                      </Typography>
                                    </TableCell>
                                    <TableCell style={amountRow}></TableCell>
                                  </TableRow>
                                  {val.accountList.map((childVal) => (
                                    <>
                                      <TableRow>
                                        <TableCell
                                          style={snRow}
                                          onDoubleClick={() =>
                                            this.onDoubleClick(childVal.code)
                                          }
                                        >
                                          <Typography>
                                            {childVal.code}
                                          </Typography>
                                        </TableCell>
                                        <TableCell style={particularRow}>
                                          <Typography>
                                            <Grid container spacing={2}>
                                              <Grid item>
                                                {childVal.expanded ? (
                                                  <ExpandMoreIcon
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      this.expandAssets(
                                                        childVal.code
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <ChevronRightIcon
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      this.expandAssets(
                                                        childVal.code
                                                      )
                                                    }
                                                  />
                                                )}
                                              </Grid>
                                              <Grid item>
                                                <Typography
                                                  onDoubleClick={() =>
                                                    this.onDoubleClick(
                                                      childVal.code
                                                    )
                                                  }
                                                >
                                                  {childVal.name}
                                                </Typography>
                                              </Grid>
                                            </Grid>
                                          </Typography>
                                        </TableCell>
                                        <TableCell style={amountRow}>
                                          <Typography>
                                            {format.format(childVal.amount)}
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                      {childVal.expanded
                                        ? childVal.childs.map((subAccount) => (
                                            <TableRow
                                              style={{ fontStyle: "italic" }}
                                            >
                                              <TableCell
                                                style={snRow}
                                                onDoubleClick={() =>
                                                  this.onDoubleClickSubAccount(
                                                    childVal.code,
                                                    subAccount.subAccountCode
                                                  )
                                                }
                                              >
                                                {subAccount.subAccountCode}
                                              </TableCell>
                                              <TableCell
                                                style={particularRow}
                                                onDoubleClick={() =>
                                                  this.onDoubleClickSubAccount(
                                                    childVal.code,
                                                    subAccount.subAccountCode
                                                  )
                                                }
                                              >
                                                {subAccount.subAccountName}
                                              </TableCell>
                                              <TableCell style={amountRow}>
                                                {format.format(
                                                  subAccount.amount
                                                )}
                                              </TableCell>
                                            </TableRow>
                                          ))
                                        : null}
                                    </>
                                  ))}
                                </>
                              ))}
                              {/* {this.state.data.netProfit === 0 ? (
                                <TableRow>
                                  <TableCell style={snRow}></TableCell>
                                  <TableCell style={particularRow}>
                                    <Grid container justify="flex-end">
                                      <Typography
                                        style={{ fontWeight: "bold" }}
                                      >
                                        NET LOSS
                                      </Typography>
                                    </Grid>
                                  </TableCell>

                                  <TableCell style={amountRow}>
                                    <Typography style={{ fontWeight: "bold" }}>
                                      {format.format(
                                        Math.abs(this.state.data.netLoss)
                                      )}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              ) : null} */}
                              <TableRow style={{ border: "5px solid #EAEDEF" }}>
                                <TableCell style={snRow}></TableCell>
                                <TableCell style={particularRow}>
                                  <Grid container justify="flex-end">
                                    <Typography style={{ fontWeight: "bold" }}>
                                      TOTAL ASSETS
                                    </Typography>
                                  </Grid>
                                </TableCell>
                                <TableCell style={amountRow}>
                                  <Typography style={{ fontWeight: "bold" }}>
                                    {format.format(
                                      Math.abs(this.state.totalAssets)
                                    )}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </div>
        <div style={{ display: "none" }}>
          <BalanceSheetPrint
            ref={(el) => (this.componentRef = el)}
            liabilities={this.state.liabilities}
            assets={this.state.assets}
            totalLiabilities={this.state.totalLiabilities}
            totalAssets={this.state.totalAssets}
            currentFy={this.state.currentFy}
            date={this.state.date}
            data={this.state.data}
          />
        </div>
      </>
    );
  }
}

export default withRouter(withStyles(styles)(BalanceSheet));
