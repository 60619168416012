import React, { Component } from "react";
import { Card, CardContent, Container, withStyles } from "@material-ui/core";
import PurchaseReturnForm from "../components/PurchaseReturnForm";
import HeaderTitle from "../components/InterfaceElements/HeaderTitle";

const styles = theme => ({
  root: {
    [theme.breakpoints.down("md")]: {
      marginBottom: "170px",
      marginTop: "40px",
      width: "100%"
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px",
      marginTop: "10px",
      margin: 15
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "10px",
      marginBottom: "100px",
      margin: 15
    }
  }
});

class PurchaseReturn extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <div style={{ backgroundColor: "#EAEDEF" }}>
          <HeaderTitle title="Purchase Return" />

          <Container>
            <Card className={classes.root}>
              <CardContent>
                <PurchaseReturnForm />
              </CardContent>
            </Card>
          </Container>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(PurchaseReturn);
