import React, { Component } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Button,
  Modal,
  Backdrop,
  Fade,
  TextField,
  withStyles,
  TableContainer,
  IconButton
} from "@material-ui/core";
import Axios from "axios";
import UserContext from "../utils/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import Close from "@material-ui/icons/Close";

const styles = theme => ({
  modalCardHeight: {
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%"
    },
    [theme.breakpoints.up("md")]: {
      width: "600px"
    },

  },
  styledTableCell: {
    backgroundColor: "#F1F8FC",
    color: "#3F51B5"
  },
  tabMargin: {
    width: "100%",
    maxWidth: "700px",
    margin: "10px",
    minHeight: "67vh",
    [theme.breakpoints.down('md')]: {
      marginTop: "105px",
      marginBottom: "170px"
    },
    [theme.breakpoints.up('md')]: {
      marginTop: "65px",
      marginBottom: "100px"
    }
  },
});
export class BudgetCodeMaster extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      data: {
        name: "",
        code: ""
      },
      nameStatus: true,
      codeStatus: true,
      nameHelperText: "",
      codeHelperText: "",
      dataList: []
    };
  }
  getCostCenterCode = () => {
    Axios.get("pos/costCenter/getAutoCode")
      .then((res) => {
        let newCode = res.data;
        this.setState(prevState => ({
          ...prevState,
          data: {
            code: newCode
          }
        }));
      });
  }
  handleOpen = () => {
    this.setState(prevState => ({
      ...prevState,
      open: true,
      data: {
        ...prevState.data,
        name: ""
      }
    }));
    this.getCostCenterCode();
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  componentDidMount() {
    this.getTableData();
  }

  handleSubmit = () => {
    const token = this.context;

    let status = this.validateForm();

    if (status) {
      Axios.post("pos/costCenter/save", this.state.data)
        .then(res => {
          var snackbarProps = {
            open: true,
            variant: "success",
            message: res.data,
            autoHideDuaratiion: 2000
          };
          token.setSnackbarProps({ snackbarProps: snackbarProps });

          this.setState(prevState => ({
            ...prevState,
            data: {
              name: "",
              code: ""
            }
          }));

          this.getTableData();
          this.handleClose();
        })
        .catch(err => {
          var message = err.message;
          if (err.response !== undefined) {
            message = err.response.data;
          }
          var snackbarProps = {
            open: true,
            variant: "error",
            message: message,
            autoHideDuration: 2000
          };

          token.setSnackbarProps({ snackbarProps: snackbarProps });
        });
    }
  };

  validateForm = () => {
    let nameStatus = true;
    let codeStatus = true;

    let nameHelperText = "";
    let codeHelperText = "";

    let val = this.state.data.name;
    let code = this.state.data.code;
    if (val === "" || val === undefined) {
      nameHelperText = "Name should be entered!";
      nameStatus = false;
    }
    if (code === "" || code === undefined) {
      codeHelperText = "Code should be entered";
      codeStatus = false;
    }

    this.setState(prevState => ({
      ...prevState,
      nameStatus,
      codeStatus,
      nameHelperText,
      codeHelperText
    }));

    return nameStatus && codeStatus;
  };

  handleDelete = id => {
    const token = this.context;

    Axios.delete("pos/costCenter/delete/" + id)
      .then(res => {
        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuaratiion: 2000
        };

        token.setSnackbarProps({ snackbarProps: snackbarProps });

        this.getTableData();
      })
      .catch(err => {
        var message = err.message;
        if (err.response !== undefined) {
          message = err.response.data;
        }

        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });
      });
  };

  onChanged = e => {
    var targetName = e.target.name;
    var targetValue = e.target.value;

    this.setState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        [targetName]: targetValue
      }
    }));
  };

  getTableData = () => {
    Axios.get("pos/costCenter/findAll")
      .then(res => {
        this.setState(prevState => ({
          ...prevState,
          dataList: res.data
        }));
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const { classes } = this.props;
    const modal = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    };
    return (
      <div>
        <Grid container justify="center">
          <Card className={classes.tabMargin}>
            <CardContent>
              <Grid container justify="space-between">
                <Grid item>
                  <Typography variant="h5" component="h2" style={{ fontSize: "18px" }}>
                    Cost Center List
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    size="large"
                    onClick={this.handleOpen}
                    color="primary"
                    style={{
                      minWidth: "100px",
                      fontSize: "13px"
                    }}
                  >
                    Add Cost Center
                  </Button>
                </Grid>
              </Grid>
              <Grid
                container
                justify="center"
                style={{ marginBottom: "50px", marginTop: "20px" }}
              >
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow
                        style={{
                          backgroundColor: "#F1F8FC",
                          color: "#3F51B5"
                        }}
                      >
                        <TableCell
                          style={{ width: "100px" }}
                          className={classes.styledTableCell}
                        >
                          Code
                      </TableCell>
                        <TableCell className={classes.styledTableCell}>
                          Name
                      </TableCell>
                        <TableCell
                          style={{ width: "100px" }}
                          className={classes.styledTableCell}
                          align="center"
                        >
                          Action
                      </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.dataList.map(val => (
                        <TableRow>
                          <TableCell>{val.code} </TableCell>
                          <TableCell>{val.name}</TableCell>
                          <TableCell align="center">
                            <Button onClick={() => this.handleDelete(val.id)}>
                              <FontAwesomeIcon
                                icon={faMinusCircle}
                                color="#FF8070"
                                size="2x"
                                style={{ cursor: "pointer" }}
                              ></FontAwesomeIcon>
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          open={this.state.open}
          onClose={this.handleClose}
          closeAfterTransition
          errorStatus={this.state.errorStatus}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={this.state.open}>
            <Card className={classes.modalCardHeight}>
              <CardContent style={{ padding: "0px" }}>
                <Grid container justify="flex-end">
                  <IconButton onClick={this.handleClose}>
                    <Close />
                  </IconButton>
                </Grid>
                <Grid container style={{ padding: "20px" }}>
                  <Grid container>
                    <Grid>
                      <Typography variant="h5">Add Cost Center</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ marginTop: 7 }}>
                      {this.state.codeStatus ? (
                        <TextField
                          label="Code"
                          name="code"
                          style={{ width: "100%" }}
                          value={this.state.data.code}
                          onChange={this.onChanged}
                          margin="auto"
                        />
                      ) : (
                          <TextField
                            label="Code"
                            name="code"
                            error
                            helperText={this.state.codeHelperText}
                            value={this.state.data.code}
                            fullWidth
                            onChange={this.onChanged}
                            margin="auto"
                          />
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      {this.state.nameStatus ? (
                        <TextField
                          label="Name"
                          name="name"
                          fullWidth
                          value={this.state.data.name}
                          onChange={this.onChanged}
                          margin="auto"
                        />
                      ) : (
                          <TextField
                            label="Name"
                            name="name"
                            fullWidth
                            error
                            helperText={this.state.nameHelperText}
                            value={this.state.data.name}
                            onChange={this.onChanged}
                            margin="auto"
                          />
                        )}
                    </Grid>
                  </Grid>
                  <Grid container justify="flex-end">
                    <Button
                      variant="outlined"
                      size="large"
                      color="primary"
                      onClick={this.handleSubmit}
                      style={{
                        margin: "10px "
                      }}
                    >
                      Add
                  </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Fade>
        </Modal>
      </div>
    );
  }
}
export default withStyles(styles)(BudgetCodeMaster);
