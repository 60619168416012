import React, { Component } from "react";
import {
  CardContent,
  Grid,
  Card,
  withStyles,
  InputAdornment
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import ValidatedTextField from "../InterfaceElements/ValidatedTextField";

const styles = theme => ({
  root: {
    padding: 15,
    paddingTop: "5px",
    paddingBottom: "5px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "170px"
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px"
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px"
    }
  },
  containerWidth: {
    margin: "auto",
    [theme.breakpoints.up("xs")]: {
      width: "100%"
    },
    [theme.breakpoints.up("sm")]: {
      width: "95%"
    },
    [theme.breakpoints.up("md")]: {
      width: "90%"
    }
  }
});

class AddCustomer extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      children,
      value,
      index,
      data,
      customerType,
      paymentMethod,
      onChange,
      errorStatus,
      handleSubmit,
      classes,
      ...other
    } = this.props;

    const textFieldStyle = {
      marginLeft: "10px",
      marginRight: "10px",
      marginTop: "0px",
      width: "100%"
    };

    const inputStyles = {
      fontSize: 14
    };

    return (
      <div>
        <Card
          role="tabpanel"
          className={classes.root}
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
        >
          <CardContent>
            <Grid
              container
              justify="center"
              style={{ height: "10px", marginBottom: "60px" }}
            >
              <h6>Customer Details</h6>
            </Grid>

            <Grid
              container
              spacing={2}
              justify="center"
              className={classes.containerWidth}
            >
              <Grid item xs={12} sm={6} md={6}>
                <ValidatedTextField
                  label="Full Name"
                  name="fullName"
                  error={errorStatus.fullName}
                  value={data.fullName}
                  onChange={onChange}
                  textFieldStyle={textFieldStyle}
                  inputStyles={inputStyles}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Customer PAN"
                  name="pan"
                  value={data.pan}
                  onChange={onChange}
                  style={textFieldStyle}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="normal"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Customer ID Number"
                  value={data.customerIdNumber}
                  onChange={onChange}
                  name="customerIdNumber"
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">112-</InputAdornment>
                    ),
                    style: inputStyles
                  }}
                  InputLabelProps={{ style: inputStyles }}
                  style={textFieldStyle}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Effective Date"
                  name="effectiveDate"
                  value={data.effectiveDate}
                  onChange={onChange}
                  type="date"
                  style={textFieldStyle}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="normal"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Mobile Number"
                  name="mobileNumber"
                  value={data.mobileNumber}
                  onChange={onChange}
                  style={textFieldStyle}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Email"
                  name="emailAddress"
                  value={data.emailAddress}
                  onChange={onChange}
                  style={textFieldStyle}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="normal"
                />
              </Grid>
            </Grid>

            <Grid
              container
              justify="center"
              style={{ height: "10px", marginBottom: "60px" }}
            >
              <h6>Full Address</h6>
            </Grid>

            <Grid
              container
              spacing={2}
              justify="center"
              className={classes.containerWidth}
            >
              <Grid item xs={12} sm={6} md={6}>
                <ValidatedTextField
                  label="Country"
                  name="country"
                  error={errorStatus.country}
                  value={data.country}
                  onChange={onChange}
                  textFieldStyle={textFieldStyle}
                  inputStyles={inputStyles}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <ValidatedTextField
                  label="State"
                  name="state"
                  error={errorStatus.state}
                  value={data.state}
                  onChange={onChange}
                  textFieldStyle={textFieldStyle}
                  inputStyles={inputStyles}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <ValidatedTextField
                  label="City"
                  name="city"
                  error={errorStatus.city}
                  value={data.city}
                  onChange={onChange}
                  textFieldStyle={textFieldStyle}
                  inputStyles={inputStyles}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <ValidatedTextField
                  label="Street"
                  name="street"
                  error={errorStatus.street}
                  value={data.street}
                  onChange={onChange}
                  textFieldStyle={textFieldStyle}
                  inputStyles={inputStyles}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justify="center"
              style={{ height: "10px", marginBottom: "60px" }}
            >
              <h6>Customer Type Details</h6>
            </Grid>

            <Grid
              container
              spacing={2}
              justify="center"
              className={classes.containerWidth}
            >
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Customer Type"
                  style={textFieldStyle}
                  InputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  value={customerType}
                  name="customerType"
                  onChange={onChange}
                  margin="normal"
                >
                  <MenuItem key={1} value={"Regular Customer"}>
                    Regular Customer
                  </MenuItem>
                  <MenuItem key={2} value={"New Customer"}>
                    New Customer
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Payment Mode"
                  style={textFieldStyle}
                  name="paymentMethod"
                  onChange={onChange}
                  InputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  value={paymentMethod}
                  margin="normal"
                >
                  <MenuItem key={1} value={"Cash"}>
                    Cash
                  </MenuItem>
                  <MenuItem key={2} value={"Cheque"}>
                    Cheque
                  </MenuItem>
                  <MenuItem key={3} value={"Credit"}>
                    Credit
                  </MenuItem>
                  <MenuItem key={4} value={"Bank Transfer"}>
                    Bank Transfer
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Credit Term"
                  name="creditTerm"
                  value={data.creditTerm}
                  onChange={onChange}
                  style={textFieldStyle}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Other Reference"
                  name="otherReference"
                  value={data.otherReference}
                  onChange={onChange}
                  style={textFieldStyle}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="normal"
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Button
                variant="outlined"
                onClick={handleSubmit}
                size="large"
                color="primary"
                style={{
                  minWidth: "150px",
                  margin: "auto",
                  marginTop: "20px"
                }}
              >
                Add Customer
              </Button>
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(AddCustomer);
