import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import Barcode from "react-barcode";
import PropTypes from "prop-types";

const BarCodeItem = (props) => {
  const data = props.arr;
  const index = props.index;

  var botMarginTop = "28px";
  if (index > 0) botMarginTop = "28px";

  const [rows] = useState(["0px", "28px", "28px", botMarginTop]);

  const [barcodeSize] = useState([30, 30, 30, 30]);
  const [columns] = useState([1, 2, 3]);

  const splitUp = (arr, n) => {
    var res = [];

    while (arr.length) {
      res.push(arr.splice(0, n));
    }
    return res;
  };

  const newData = splitUp(data, 3);

  var marginTop = "-5px";

  if (index > 0) marginTop = "60px";

  return (
    <Grid
      container
      style={{ marginBottom: "0px !important", marginTop: marginTop }}
    >
      {rows.map((val, i) => (
        <Grid container style={{ marginTop: val }}>
          {columns.map((val, j) => (
            <Grid item xs={3} style={{ marginRight: "30px" }}>
              <div style={{ backgroundColor: "#fff" }}>
                <Grid container style={{ marginTop: "-10px", width: "100%" }}>
                  <div
                    style={{
                      width: "100%",
                      fontSize: "8px",
                      marginLeft: "22px",
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    POS
                  </div>
                </Grid>
                <div>
                  <span className="rotateText">
                    <Typography style={{ fontSize: "8px" }}>
                      {newData[i][j]["itemCode"]}
                    </Typography>
                  </span>
                </div>

                <div style={{ marginLeft: "10px", marginTop: "0px" }}>
                  <div>
                    <Barcode
                      value={newData[i][j]["itemCode"]}
                      displayValue={false}
                      width={0.88}
                      height={barcodeSize[i]}
                      //fontSize={10}
                      //font= "monospace"
                      textAlign="center"
                    />
                    <div
                      style={{
                        marginLeft: "33px",
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      <span>
                        <Typography
                          style={{
                            fontSize: "9px",
                            marginTop: "-2px",
                          }}
                        >
                          NPR {newData[i][j]["markedPrice"]}
                        </Typography>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      ))}
    </Grid>
  );
};

BarCodeItem.propTypes = {
  marginBottom: PropTypes.string.isRequired,
};

export default BarCodeItem;
