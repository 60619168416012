import React, { Component } from "react";
import {
  CardContent,
  Card,
  Grid,
  TextField,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  withStyles,
  TableContainer,
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  RadioGroup,
  Radio,
  FormControlLabel,
  CircularProgress,
} from "@material-ui/core";
import Axios from "axios";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
// import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExcel,
  faPrint,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";
import UserContext from "../utils/UserContext";
import { withRouter } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import ReactToPrint from "react-to-print";
import ProfitAndLossAccountPrint from "../components/PrintableComponents/ProfitAndLossAccountPrint";
import jsPDF from "jspdf";
import "jspdf-autotable";
import adbs, { bs2ad } from "ad-bs-converter";
import dateFormat from "dateformat";
import "nepali-datepicker-reactjs/dist/index.css";
import NepaliDatepicker from 'nepali-datepicker-and-dateinput';

const styles = (theme) => ({
  tabMargin: {
    overflowY : "auto",
    height: "700px",
    width: "100%",
    margin: "10px",
    marginBottom: "100px",
    [theme.breakpoints.down("md")]: {
      marginTop: "100px",
      width: "90%",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "65px",
    },
  },
  root: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "150px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
    },
  },
});

class ProfitAndLossAccount extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      totalDirectExpenses: 0,
      costOfGoodsSold: 0,
      grossProfitLoss: 0,
      totalIndirectExpenses: 0,
      totalIndirectIncome: 0,
      netProfitLoss: 0,
      companyName: "",
      companyAddress: "",
      showTable: false,
      companyContact: "",
      fyList: [],
      distinctFyList: [],
      distinctPeriodList: [],
      currentFy: "",
      fiscalYear: "",
      currentPeriod: "",
      directExpenses: [],
      indirectExpenses: [],
      otherIncome: [],
      bottomTable: [],
      staticValues: [
        {
          name: "StockInHandOpening",
          amount: 0,
          profitAndLossSubAccounts: [],
          type: "Debit",
          expanded: false,
        },
        {
          name: "Sales",
          amount: 0,
          profitAndLossSubAccounts: [],
          type: "Credit",
          expanded: false,
        },
        {
          name: "Purchase",
          amount: 0,
          profitAndLossSubAccounts: [],
          type: "Debit",
          expanded: false,
        },
        {
          name: "Changes in Inventory",
          amount: 0,
          profitAndLossSubAccounts: [],
          type: "debit",
          expanded: false,
        },
      ],
      excelData: [],
      purchase: 0,
      sales: 0,
      stockInHandClosing: 0,
      toGrossProfit: 0,
      netProfit: 0,
      subTotalDebit: 0,
      subTotalCredit: 0,
      totalDebit: 0,
      totalCredit: 0,
      filterFields: {
        dateFrom: "",
        dateTo: "",
        periodFrom: "",
        periodTo: "",
      },
      filterTerm: "",
      panelExpanded: true,
      showLoading: false,
      showDetails: false,
    };
  }

  componentDidMount = async () => {
    this.getAllPeriodListing();
    this.getCompanyName();

    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    var todayDate = dateFormat(date, "yyyy-mm-dd");
    console.log(todayDate)

    let transactionDateFrom = (todayDate.split("-"));
    
    let tdf = (
      transactionDateFrom[0] +
        "/" +
        transactionDateFrom[1] +
        "/" +
        transactionDateFrom[2]
    );

    let df= adbs.ad2bs(tdf)

    let finalDateFromYear = df.en.year;
    let finalDateFromMonth = df.en.month;
    let finalDateFromDay = df.en.day;
    if(df.en.month < 10){
      finalDateFromMonth = "0" + df.en.month;
    }    
    if(df.en.day < 10){
      finalDateFromDay = "0" + df.en.day;
    }

    let finalDateFrom = finalDateFromYear + "-" + finalDateFromMonth + "-" + finalDateFromDay;

    this.setState((prevState) => ({
      ...prevState,
      filterFields: {
        ...prevState.filterFields,
        dateFrom: finalDateFrom,
        dateTo: finalDateFrom,
      },
    }));
  };

  onFilterTermChange = (e) => {
    let value = e.target.value;

    this.setState((prevState) => ({
      ...prevState,
      filterTerm: value,
    }));
  };

  handleFiscalYearChange = async (e) => {
    e.stopPropagation();
    await this.setState((prevState) => ({
      ...prevState,
      currentFy: e.target.value,
    }));
  };

  handlePeriodFromChange = async (e) => {
    e.stopPropagation();
    var period = e.target.value;
    await this.setState((prevState) => ({
      ...prevState,
      filterFields: {
        ...prevState.filterFields,
        periodFrom: period,
      },
    }));
  };

  handlePeriodToChange = async (e) => {
    e.stopPropagation();
    var period = e.target.value;
    await this.setState((prevState) => ({
      ...prevState,
      filterFields: {
        ...prevState.filterFields,
        periodTo: period,
      },
    }));
  };

  handlePanelChange = (_e, isExpanded) => {
    this.setState({
      panelExpanded: isExpanded,
    });
  };

  handleFromDate = (name, dateInMilli, bsDate, adDate) => {

    this.setState((prevState) => ({
      ...prevState,
      filterFields: {
        ...prevState.filterFields,
        dateFrom: adDate,
      },
    }));
  };
  handleToDate = (name, dateInMilli, bsDate, adDate) => {

    this.setState((prevState) => ({
      ...prevState,
      filterFields: {
        ...prevState.filterFields,
        dateTo: adDate,
      },
    }));
  };

  handleFiscalYearApply = () => {
    this.setState({
      panelExpanded: false,
    });

    this.setState({ showLoading: true });
    this.getTransactionByDate({
      fiscalYear: this.state.currentFy,
      periodFrom: this.state.filterFields.periodFrom,
      periodTo: this.state.filterFields.periodTo,
    });
  };

  handleDateRangeApply = () => {
    this.setState({
      panelExpanded: false,
    });
      
    this.getTransactionByDate({
      dateFrom: this.state.filterFields.dateFrom,
      dateTo: this.state.filterFields.dateTo,
    });
  };

  onDoubleClick = (accountCode) => {
    const token = this.context;

    let newFilterFields = this.state.filterFields;
    newFilterFields.fiscalYear = this.state.currentFy;

    token.setAccountCode({ value: accountCode });
    token.setFilterFields({ value: newFilterFields });

    this.props.history.push("/booksOfAccount/view");
  };

  onDoubleClickSubAccount = (accountCode, subAccountCode) => {
    const token = this.context;

    let newFilterFields = this.state.filterFields;
    newFilterFields.fiscalYear = this.state.currentFy;

    token.setAccountCode({ value: accountCode });
    token.setSubAccountCode({ value: subAccountCode });
    token.setFilterFields({ value: newFilterFields });

    this.props.history.push("/booksOfAccount/view");
  };

  expandDirectExpenses = (accountCode) => {
    let newData = this.state.directExpenses;

    newData.map((val) =>
      val.accountCode === accountCode ? (val.expanded = !val.expanded) : null
    );

    this.setState({
      directExpenses: newData,
    });
  };

  expandIndirectExpenses = (accountCode) => {
    let newData = this.state.indirectExpenses;

    newData.map((val) =>
      val.accountCode === accountCode ? (val.expanded = !val.expanded) : null
    );

    this.setState({
      indirectExpenses: newData,
    });
  };

  expandIndirectIncome = (accountCode) => {
    let newData = this.state.otherIncome;

    newData.map((val) =>
      val.accountCode === accountCode ? (val.expanded = !val.expanded) : null
    );

    this.setState({
      indirectIncome: newData,
    });
  };

  expandStaticValues = (name) => {
    let newData = this.state.staticValues;

    newData.map((val) =>
      val.name === name ? (val.expanded = !val.expanded) : null
    );

    this.setState({
      staticValues: newData,
    });
  };

  // getSubAccountsForRowOne = (stockInHandOpening, sales) => {
  //   let childs = [];

  //   let stockInHandOpeningLen = 0;
  //   let salesLen = 0;

  //   try {
  //     stockInHandOpeningLen = stockInHandOpening.length;
  //   } catch (error) {
  //     stockInHandOpeningLen = 0;
  //   }

  //   try {
  //     salesLen = sales.length;
  //   } catch (error) {
  //     salesLen = 0;
  //   }

  //  let largerValue = Math.max(stockInHandOpeningLen, salesLen);

  // for (let index = 0; index < largerValue; index++) {
  //   let item = {};

  //   try {
  //     item.debitSide = stockInHandOpening[index];
  //   } catch (error) {
  //     item.debitSide = {
  //       subAccountName: "",
  //       subAccountCode: "",
  //       amount: "",
  //     };
  //   }

  //   try {
  //     item.creditSide = sales[index];
  //   } catch (error) {
  //     item.creditSide = {
  //       subAccountName: "",
  //       subAccountCode: "",
  //       amount: "",
  //     };
  //   }

  //   if (item.debitSide === undefined) {
  //     item.debitSide = {
  //       subAccountName: "",
  //       subAccountCode: "",
  //       amount: "",
  //     };
  //   }
  //   if (item.creditSide === undefined) {
  //     item.creditSide = {
  //       subAccountName: "",
  //       subAccountCode: "",
  //       amount: "",
  //     };
  //   }

  //   childs.push(item);
  // }

  // this.setState({
  //   staticRowOne: { childs: childs, expanded: false },
  // });
  //   };

  getSubAccountsForRowTwo = (purchase, stockInHandClosing) => {
    let childs = [];

    let stockInHandClosingLen = 0;
    let purchaseLen = 0;

    try {
      stockInHandClosingLen = stockInHandClosing.length;
    } catch (error) {
      stockInHandClosingLen = 0;
    }

    try {
      purchaseLen = purchase.length;
    } catch (error) {
      purchaseLen = 0;
    }

    let largerValue = Math.max(stockInHandClosingLen, purchaseLen);

    for (let index = 0; index < largerValue; index++) {
      let item = {};

      try {
        item.debitSide = purchase[index];
      } catch (error) {
        item.debitSide = {
          subAccountName: "",
          subAccountCode: "",
          amount: "",
        };
      }

      try {
        item.creditSide = stockInHandClosing[index];
      } catch (error) {
        item.creditSide = {
          subAccountName: "",
          subAccountCode: "",
          amount: "",
        };
      }

      if (item.debitSide === undefined) {
        item.debitSide = {
          subAccountName: "",
          subAccountCode: "",
          amount: "",
        };
      }
      if (item.creditSide === undefined) {
        item.creditSide = {
          subAccountName: "",
          subAccountCode: "",
          amount: "",
        };
      }

      childs.push(item);
    }

    this.setState({
      staticRowTwo: { childs: childs, expanded: false },
    });
  };

  getSubAccounts = (bottomTable) => {
    bottomTable.forEach((element) => {
      let childs = [];

      let creditSideChilds = element.creditSide.profitAndLossSubAccounts;

      let debitSideChilds = element.debitSide.profitAndLossSubAccounts;

      let creditSideLen = 0;
      let debitSideLen = 0;

      try {
        creditSideLen = creditSideChilds.length;
      } catch (error) {
        creditSideLen = 0;
      }

      try {
        debitSideLen = debitSideChilds.length;
      } catch (error) {
        debitSideLen = 0;
      }

      let largerValue = Math.max(creditSideLen, debitSideLen);

      for (let index = 0; index < largerValue; index++) {
        let item = {};

        try {
          item.debitSide = debitSideChilds[index];
        } catch (error) {
          item.debitSide = {
            subAccountName: "",
            subAccountCode: "",
            amount: "",
          };
        }

        try {
          item.creditSide = creditSideChilds[index];
        } catch (error) {
          item.creditSide = {
            subAccountName: "",
            subAccountCode: "",
            amount: "",
          };
        }

        if (item.debitSide === undefined) {
          item.debitSide = {
            subAccountName: "",
            subAccountCode: "",
            amount: "",
          };
        }
        if (item.creditSide === undefined) {
          item.creditSide = {
            subAccountName: "",
            subAccountCode: "",
            amount: "",
          };
        }

        childs.push(item);
      }

      element.uuid = uuidv4();
      element.expanded = false;
      element.childs = childs;
    });
  };

  getTransactionByDate = (obj) => {
    this.setState({ showDetails: true });

    Axios.post("/pos/profitAndLoss/get", obj)
      .then((res) => {
        this.setState({ showLoading: false });
        this.setState({ showTable: true });
        let totalDirectExpenses = 0;
        let costOfGoodsSold = 0;
        let grossProfitLoss = 0;
        let totalIndirectExpenses = 0;
        let totalIndirectIncome = 0;
        let netProfitLoss = 0;
        let directExpenses = [];
        let indirectExpenses = [];
        let otherIncome = [];
        let bottomTable = [];
        let staticValues = this.state.staticValues;

        let grossProfit =
          res.data.grossProfit === 0
            ? parseFloat("-" + res.data.grossLoss)
            : res.data.grossProfit;
        let netProfit =
          res.data.netProfit === 0
            ? parseFloat("-" + res.data.netLoss)
            : res.data.netProfit;

        res.data.profitAndLossAccountChilds.forEach((element) => {
          if (element.accountGroup === "Direct Expenses") {
            totalDirectExpenses += element.amount;
            directExpenses.push({ ...element, expanded: false });
          } else if (element.accountGroup === "Indirect Expenses") {
            if (element.accountCode !== "492") {
              totalIndirectExpenses += element.amount;
              indirectExpenses.push(element);
            }
          } else if (
            element.accountGroup === "Indirect Income" ||
            element.accountGroup === "Other Income"
          ) {
            totalIndirectIncome += element.amount;
            otherIncome.push(element);
          }
        });

        staticValues.forEach((element) => {
          res.data.profitAndLossAccountChilds.forEach((childVal) => {
            if (childVal.accountName === element.name) {
              element.amount = childVal.amount;
              element.profitAndLossSubAccounts =
                childVal.profitAndLossSubAccounts === null
                  ? []
                  : childVal.profitAndLossSubAccounts;
            }
          });
        });

        // this.getSubAccountsForRowOne(
        //   staticValues[0].profitAndLossSubAccounts,
        //   staticValues[1].profitAndLossSubAccounts
        // );
        // this.getSubAccountsForRowTwo(
        //   staticValues[2].profitAndLossSubAccounts,
        //   staticValues[3].profitAndLossSubAccounts
        // );
        this.getSubAccounts(bottomTable);

        costOfGoodsSold = staticValues[2].amount + staticValues[3].amount;

        grossProfitLoss =
          staticValues[1].amount + costOfGoodsSold + totalDirectExpenses;
        // netProfitLoss =
        //   grossProfitLoss + totalIndirectExpenses + totalIndirectIncome;
        netProfitLoss =
          res.data.netProfit === 0 && res.data.netLoss === 0
            ? 0
            : res.data.netProfit === 0
            ? res.data.netLoss
            : res.data.netProfit * -1;

        this.setState((prevState) => ({
          ...prevState,
          totalDirectExpenses: totalDirectExpenses,
          totalIndirectExpenses: totalIndirectExpenses,
          totalIndirectIncome: totalIndirectIncome,
          directExpenses,
          indirectExpenses,
          otherIncome,
          bottomTable,
          staticValues,
          netProfit,
          toGrossProfit: grossProfit,
          costOfGoodsSold: costOfGoodsSold,
          grossProfitLoss: grossProfitLoss,
          netProfitLoss: netProfitLoss,
        }));

        //------pdf Calculation-------
        var directExpensesValuePdf = 0;
        directExpenses.map((value) => {
          directExpensesValuePdf += value.amount;
          return null;
        });

        var indirectExpensesValuePdf = 0;
        indirectExpenses.map((value) => {
          indirectExpensesValuePdf += value.amount;
          return null;
        });

        var indirectIncomeValuePdf = 0;
        otherIncome.map((value) => {
          indirectIncomeValuePdf += value.amount;
          return null;
        });

        var cogsValuePdf = 0;
        var purchaseValue = staticValues[2].amount;
        var sales = staticValues[1].amount;
        cogsValuePdf = +purchaseValue + sales;

        var grossProfitLossExcel = directExpensesValuePdf + cogsValuePdf;
        var netProfitLossExcel =
          grossProfitLossExcel +
          indirectExpensesValuePdf +
          indirectIncomeValuePdf;

        this.setState((prevState) => ({
          ...prevState,
          directExpenses,
          indirectExpenses,
          otherIncome,
          bottomTable,
          staticValues,
          netProfit,
          toGrossProfit: grossProfit,
          directExpensesValuePdf,
          indirectExpensesValuePdf,
          indirectIncomeValuePdf,
          cogsValuePdf,
        }));

        //----------for excel------
        var format = new Intl.NumberFormat("ne-NP", {
          minimumFractionDigits: 2,
        });
        let excelData = [];
        if (this.state.filterTerm === "fiscalYear") {
          excelData.push({
            firstAccountCode: "",
            firstAccountName: "Fiscal Year",
            firstSubAmount: this.state.currentFy,
            firstTotalAmount: "",
          });

          excelData.push({
            firstAccountCode: "",
            firstAccountName: "Period From",
            firstSubAmount: this.state.filterFields.periodFrom,
            firstTotalAmount: "",
          });

          excelData.push({
            firstAccountCode: "",
            firstAccountName: "Period To",
            firstSubAmount: this.state.filterFields.periodTo,
            firstTotalAmount: "",
          });
        } else if (this.state.filterTerm === "date") {
          excelData.push({
            firstAccountCode: "",
            firstAccountName: "Date From",
            firstSubAmount: this.state.filterFields.dateFrom,
            firstTotalAmount: "",
          });

          excelData.push({
            firstAccountCode: "",
            firstAccountName: "Date To",
            firstSubAmount: this.state.filterFields.dateTo,
            firstTotalAmount: "",
          });
        } else {
          return null;
        }

        excelData.push({
          firstAccountCode: "",
          firstAccountName: "",
          firstSubAmount: "",
          firstTotalAmount: "",
        });

        excelData.push({
          firstAccountCode: "Code",
          firstAccountName: "Particulars",
          firstSubAmount: "Sub Total",
          firstTotalAmount: "Total Amount",
        });

        excelData.push({
          firstAccountCode: "300",
          firstAccountName: "Sales",
          firstSubAmount: "",
          firstTotalAmount: format.format(staticValues[1].amount),
        });

        excelData.push({
          firstAccountCode: "400",
          firstAccountName: "Purchase",
          firstSubAmount: format.format(staticValues[2].amount),
          firstTotalAmount: "",
        });

        excelData.push({
          firstAccountCode: "492",
          firstAccountName: "Changes in Inventory",
          firstSubAmount: format.format(staticValues[3].amount),
          firstTotalAmount: "",
        });

        excelData.push({
          firstAccountCode: "",
          firstAccountName: "Less: Cost of Goods Sold",
          firstSubAmount: "",
          firstTotalAmount: format.format(costOfGoodsSold),
        });

        excelData.push({
          firstAccountCode: "",
          firstAccountName: "Direct Expenses",
          firstSubAmount: "",
          firstTotalAmount: format.format(directExpensesValuePdf),
        });

        directExpenses.map((val) =>
          excelData.push({
            firstAccountCode: val.accountCode,
            firstAccountName: val.accountName,
            firstSubAmount: format.format(val.amount),
            firstTotalAmount: "",
          })
        );

        excelData.push({
          firstAccountCode: "",
          firstAccountName: "To Gross (Profit)/Loss",
          firstSubAmount: "",
          firstTotalAmount: format.format(grossProfitLoss),
        });

        excelData.push({
          firstAccountCode: "",
          firstAccountName: "",
          firstSubAmount: "",
          firstTotalAmount: "",
        });

        excelData.push({
          firstAccountCode: "",
          firstAccountName: "Indirect Expenses",
          firstSubAmount: "",
          firstTotalAmount: format.format(indirectExpensesValuePdf),
        });

        indirectExpenses.map((val) =>
          excelData.push({
            firstAccountCode: val.accountCode,
            firstAccountName: val.accountName,
            firstSubAmount: format.format(val.amount),
            firstTotalAmount: "",
          })
        );

        excelData.push({
          firstAccountCode: "",
          firstAccountName: "Indirect Income",
          firstSubAmount: "",
          firstTotalAmount: format.format(indirectIncomeValuePdf),
        });

        otherIncome.map((val) =>
          excelData.push({
            firstAccountCode: val.accountCode,
            firstAccountName: val.accountName,
            firstSubAmount: format.format(val.amount),
            firstTotalAmount: "",
          })
        );

        excelData.push({
          firstAccountCode: "",
          firstAccountName: "",
          firstSubAmount: "",
          firstTotalAmount: "",
        });

        excelData.push({
          firstAccountCode: "",
          firstAccountName: " To Net (Profit)/Loss ",
          firstSubAmount: "",
          firstTotalAmount: format.format(netProfitLoss),
        });

        this.setState((prevState) => ({
          ...prevState,
          excelData,
        }));
      })
      .catch((err) => {
        this.setState({ showLoading: true });
        console.log(err);
      });
  };

  getAllPeriodListing() {
    Axios.get("/fy/findTillCurrentPeriod")
      .then((res) => {
        var data = [];
        var nonDistinctPeriodList = [];
        var nonDistinctFyList = [];
        var periodData = [];
        res.data.forEach((element) => {
          var item = {
            status: element.currentStatus,
            fiscalYear: element.fiscalYear,
            name: element.name,
          };
          var periodItem = {
            status: element.currentStatus,
            period: element.name,
          };
          data.push(item);
          nonDistinctFyList.push(item.fiscalYear);
          periodData.push(periodItem);
          nonDistinctPeriodList.push(periodItem.period);
        });

        this.setState((prevState) => ({
          ...prevState,
          fyList: data,
          distinctFyList: [...new Set(nonDistinctFyList)],
          periodList: periodData,
          distinctPeriodList: [...new Set(nonDistinctPeriodList)],
        }));
        this.getCurrentPeriod();
      })
      .catch((err) => {
        console.log(err);
      });
  }
  getCurrentPeriod = () => {
    Axios.get("/fy/getCurrentPeriod")
      .then(async (res) => {
        await this.setState((prevState) => ({
          ...prevState,
          currentFy: res.data.fiscalYear,
          filterFields: {
            ...prevState.filterFields,
            periodFrom: res.data.name,
            periodTo: res.data.name,
          },
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCompanyName() {
    Axios.get("/hrm/getGeneralCompanyInformation")
      .then((res) => {
        res.data.forEach((element) => {
          this.setState({
            companyName: element.name,
            companyAddress: element.street + ", " + element.city,
            companyContact: element.contactNumber,
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  generatePdf = () => {
    let company = this.state.companyName;
    let companyAddress = this.state.companyAddress;
    let companyContactNumber = this.state.companyContact;
    function header() {
      doc.setFont("Helvetica", "bold");
      doc.setFontSize(12);
      doc.text(300, 40, company, {
        align: "center",
      });
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(12);
      doc.text(300, 60, companyAddress, {
        align: "center",
      });
      doc.setFontSize(12);
      doc.text(300, 80, companyContactNumber, {
        align: "center",
      });
    }
    function footer() {
      doc.setFont("Helvetica", "italic");
      doc.setFontSize(9);
      doc.text(40, doc.internal.pageSize.height - 20, window.location.href);
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      today = yyyy + "/" + mm + "/" + dd;
      doc.text(
        doc.internal.pageSize.width - 150,
        doc.internal.pageSize.height - 20,
        "Printed on: " + today
      );
    }
    var doc = new jsPDF("p", "pt", "a4", true);
    //current date
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(9);
    header();
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(12);
    doc.text(40, 135, "Profit And Loss Account", {
      align: "left",
    });
    var x = 40;
    var y = 160;
    if (this.state.filterTerm === "fiscalYear") {
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11.5);
      doc.text(x + 7, y, "Fiscal Year:");
      doc.setFont("Helvetica", "italic");
      doc.setFontSize(10.5);
      doc.text(113, y, this.state.currentFy);
      x = x + 200;
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11.5);
      doc.text(x, y, "Period From:");
      doc.setFont("Helvetica", "italic");
      doc.setFontSize(10.5);
      doc.text(x + 72, y, this.state.filterFields.periodFrom);
      x = x + 200;
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11.5);
      doc.text(x, y, "Period To:");
      doc.setFont("Helvetica", "italic");
      doc.setFontSize(10.5);
      doc.text(x + 60, y, this.state.filterFields.periodTo);
    } else if (this.state.filterTerm === "date") {
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11.5);
      doc.text(x + 10, y, "Date From:");
      doc.setFont("Helvetica", "italic");
      doc.setFontSize(10.5);
      doc.text(110, y, this.state.filterFields.dateFrom);
      x = x + 250;
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11.5);
      doc.text(x, y, "Date To:");
      doc.setFont("Helvetica", "italic");
      doc.setFontSize(10.5);
      doc.text(340, y, this.state.filterFields.dateTo);
    }
    //formatting Amount
    var format = new Intl.NumberFormat("ne-NP", {
      // style: 'currency',
      // currency: 'NPR',
      minimumFractionDigits: 2,
    });
    /* ------------Profit and loss values----------------- */
    let profitAndLossDetails = [];
    profitAndLossDetails.push([
      "300",
      " Sales",
      "",
      format.format(this.state.staticValues[1].amount),
    ]);
    profitAndLossDetails.push([
      "",
      " Less: Cost of Goods Sold",
      "",
      format.format(this.state.cogsValuePdf),
    ]);

    profitAndLossDetails.push([
      "400",
      " Purchase",
      format.format(this.state.staticValues[2].amount),
    ]);
    profitAndLossDetails.push([
      "",
      " Direct Expenses",
      format.format(this.state.directExpensesValuePdf),
      "",
    ]);
    this.state.directExpenses.map((val) => {
      profitAndLossDetails.push([
        val.accountCode,
        val.accountName,
        format.format(val.amount),
      ]);
      val.expanded &&
        val.profitAndLossSubAccounts.map((childVal) =>
          profitAndLossDetails.push([
            childVal.subAccountCode,
            childVal.subAccountName,
            format.format(childVal.amount),
          ])
        );
      return null;
    });
    profitAndLossDetails.push([
      "",
      " To Gross Profit    ",
      "",
      format.format(this.state.toGrossProfit),
    ]);
    //-------after gross profit values-----------
    let plDetailsAfterGrossProfit = [];
    plDetailsAfterGrossProfit.push([
      "",
      " Indirect Expenses",
      "",
      format.format(this.state.indirectExpensesValuePdf),
    ]);
    this.state.indirectExpenses.map((val) => {
      plDetailsAfterGrossProfit.push([
        val.accountCode,
        val.accountName,
        format.format(val.amount),
      ]);
      val.expanded &&
        val.profitAndLossSubAccounts.map((childVal) =>
          plDetailsAfterGrossProfit.push([
            childVal.subAccountCode,
            childVal.subAccountName,
            format.format(childVal.amount),
          ])
        );
      return null;
    });
    plDetailsAfterGrossProfit.push([
      "",
      " Indirect Income",
      "",
      format.format(this.state.indirectIncomeValuePdf),
    ]);
    this.state.otherIncome.map((val) => {
      plDetailsAfterGrossProfit.push([
        val.accountCode,
        val.accountName,
        format.format(val.amount),
      ]);
      val.expanded &&
        val.profitAndLossSubAccounts.map((childVal) =>
          plDetailsAfterGrossProfit.push([
            childVal.subAccountCode,
            childVal.subAccountName,
            format.format(childVal.amount),
          ])
        );
      return null;
    });
    this.state.netProfit > 0
      ? plDetailsAfterGrossProfit.push([
          "",
          " To Net Profit    ",
          "",
          format.format(this.state.netProfit),
        ])
      : plDetailsAfterGrossProfit.push([
          "",
          " To Net Loss    ",
          "",
          format.format(this.state.netProfit),
        ]);
    doc.autoTable({
      styles: {
        overflow: "linebreak",
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.3,
        halign: "center",
        font: "helvetica",
      },
      headerStyles: { fontSize: 11.5 },
      bodyStyles: { valign: "top" },
      startY: y + 20,
      theme: "grid",
      columnStyles: {
        0: {
          fontSize: 10.5,
          cellWidth: 70,
          halign: "center",
        },
        1: {
          fontSize: 10.5,
          cellWidth: 210,
          halign: "left",
        },
        2: {
          fontSize: 10.5,
          cellWidth: 130,
          halign: "right",
        },
        3: {
          fontSize: 10.5,
          cellWidth: 110,
          halign: "right",
        },
      },
      allSectionHooks: true,
      didParseCell: function (data) {
        var rows = data.table.body;
        let arr = [];
        rows.map((item) => {
          if (item.raw[0] === "" && item.raw[1] !== "") {
            arr.push(rows.indexOf(item));
          }
          return null;
        });
        arr.map((index) => {
          if (data.row.index === index) {
            data.cell.styles.fontStyle = "bolditalic";
          }
          return null;
        });
        if (data.row.section === "body" && data.row.index === 1) {
          data.cell.styles.fontStyle = "bold";
          data.cell.styles.fontSize = 11;
        }
        if (data.row.index === rows.length - 1) {
          data.cell.styles.fontStyle = "bold";
          data.cell.styles.halign = "right";
          data.cell.styles.lineWidth = 1.2;
          data.cell.styles.fontSize = 11;
        }
      },
      head: [["Code", "Particulars", "Sub Total", "Total Amount"]],
      body: profitAndLossDetails,
    });
    doc.autoTable({
      styles: {
        overflow: "linebreak",
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.3,
        halign: "center",
        font: "helvetica",
      },
      headerStyles: { fontSize: 11.5 },
      bodyStyles: { valign: "top" },
      startY: doc.lastAutoTable.finalY,
      theme: "grid",
      columnStyles: {
        0: {
          fontSize: 10.5,
          cellWidth: 70,
          halign: "center",
        },
        1: {
          fontSize: 10.5,
          cellWidth: 210,
          halign: "left",
        },
        2: {
          fontSize: 10.5,
          cellWidth: 130,
          halign: "right",
        },
        3: {
          fontSize: 10.5,
          cellWidth: 110,
          halign: "right",
        },
      },
      allSectionHooks: true,
      didParseCell: function (data) {
        var rows = data.table.body;
        let arr = [];
        rows.map((item) => {
          if (item.raw[0] === "" && item.raw[1] !== "") {
            arr.push(rows.indexOf(item));
          }
          return null;
        });
        arr.map((index) => {
          if (data.row.index === index) {
            data.cell.styles.fontStyle = "bolditalic";
            data.cell.styles.fontSize = 11;
          }
          return null;
        });
        if (data.row.index === rows.length - 1) {
          data.cell.styles.fontStyle = "bold";
          data.cell.styles.halign = "right";
          data.cell.styles.lineWidth = 1.2;
          data.cell.styles.fontSize = 11;
        }
      },
      body: plDetailsAfterGrossProfit,
    });
    footer();
    const pages = doc.internal.getNumberOfPages();
    const pageWidth = doc.internal.pageSize.width; //Optional
    const pageHeight = doc.internal.pageSize.height; //Optional
    doc.setFontSize(10); //Optional
    for (let j = 1; j < pages + 1; j++) {
      let horizontalPos = pageWidth / 2; //Can be fixed number
      let verticalPos = pageHeight - 10; //Can be fixed number
      doc.setPage(j);
      doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
        align: "center",
      });
    }
    doc.save("Profit And Loss Account");
  };

  render() {
    const styledTableCell = {
      backgroundColor: "#F1F8FC",
      color: "#3F51B5",
      borderRight: "1px solid #EAEDEF",
      maxWidth: "300px",
    };

    const snRow = {
      borderRight: "1px solid #EAEDEF",
      borderBottom: "1px solid #EAEDEF",
      width: "100px",
      maxWidth: "100px",
    };

    const particularRow = {
      borderRight: "1px solid #EAEDEF",
      borderBottom: "1px solid #EAEDEF",
      width: "400px",
      maxWidth: "270px",
    };

    const amountRow = {
      borderRight: "1px solid #EAEDEF",
      borderBottom: "1px solid #EAEDEF",
      width: "150px",
      minWidth: "130px",
      textAlign: "end",
    };

    const totalRow = {
      border: "4px solid #EAEDEF",
    };

    const { classes } = this.props;

    var format = new Intl.NumberFormat("ne-NP", {
      minimumFractionDigits: 2,
    });

    return (
      <div
        style={{
          backgroundColor: "#EAEDEF",
        }}
      >
        <Grid container justify="center" className={classes.root}>
          <Grid item xs={10}>
            <Card className={classes.tabMargin}>
              <CardContent>
                <Grid container spacing={1} justify="space-between">
                  <Grid item xs={24} sm={6}>
                    <ExpansionPanel
                      expanded={this.state.panelExpanded}
                      onChange={this.handlePanelChange}
                      style={{ maxWidth: "550px" }}
                    >
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>
                          Report By
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Grid container>
                          <RadioGroup
                            row
                            value={this.state.filterTerm}
                            onChange={this.onFilterTermChange}
                          >
                            <FormControlLabel
                              value="fiscalYear"
                              control={<Radio color="primary" />}
                              label="Fiscal Year and Period"
                            />
                            <FormControlLabel
                              value="date"
                              control={<Radio color="primary" />}
                              label="Date Range"
                            />
                          </RadioGroup>

                          {this.state.filterTerm === "fiscalYear" ? (
                            <>
                              <Grid
                                container
                                justify="flex-start"
                                spacing={2}
                                style={{ marginTop: "20px" }}
                              >
                                <Grid item>
                                  <TextField
                                    select
                                    style={{
                                      width: "15vw",
                                      minWidth: "120px",
                                    }}
                                    label="Fiscal Year"
                                    name="fiscalYear"
                                    onChange={this.handleFiscalYearChange}
                                    value={this.state.currentFy}
                                  >
                                    {this.state.distinctFyList.map((item) => (
                                      <MenuItem value={item}>{item}</MenuItem>
                                    ))}
                                  </TextField>
                                </Grid>
                              </Grid>

                              <Grid
                                container
                                justify="flex-start"
                                spacing={2}
                                style={{ marginTop: "20px" }}
                              >
                                <Grid item>
                                  <TextField
                                    select
                                    style={{
                                      width: "15vw",
                                      minWidth: "120px",
                                    }}
                                    label="Period From"
                                    name="periodFrom"
                                    onChange={this.handlePeriodFromChange}
                                    value={this.state.filterFields.periodFrom}
                                  >
                                    {this.state.distinctPeriodList.map(
                                      (item) => (
                                        <MenuItem value={item}>{item}</MenuItem>
                                      )
                                    )}
                                  </TextField>
                                </Grid>
                                <Grid item>
                                  <TextField
                                    select
                                    style={{
                                      width: "15vw",
                                      minWidth: "120px",
                                    }}
                                    label="Period To"
                                    name="periodTo"
                                    value={this.state.filterFields.periodTo}
                                    onChange={this.handlePeriodToChange}
                                  >
                                    {this.state.distinctPeriodList.map(
                                      (item) => (
                                        <MenuItem value={item}>{item}</MenuItem>
                                      )
                                    )}
                                  </TextField>
                                </Grid>
                              </Grid>

                              <Grid container style={{ marginTop: "27px" }}>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={this.handleFiscalYearApply}
                                >
                                  Apply
                                </Button>
                              </Grid>
                            </>
                          ) : null}

                          {this.state.filterTerm === "date" ? (
                            <>
                              <Grid container justify="flex-start" spacing={2}>
                              <Grid item style={{marginTop: "8px"}}>
                                  <NepaliDatepicker
                                    id="nepali-date"
                                    onChange={this.handleFromDate}
                                    label="Date From"
                                    value={this.state.filterFields.dateFrom}
                                    locale= "en"
                                    showDefaultDate="true"
                                    style={{height: "5px", width: "5px"}}
                                  />
                                  </Grid>
                                  <Grid item style={{marginTop: "8px"}}> 
                                  <NepaliDatepicker
                                    id="nepali-date"
                                    onChange={this.handleToDate}
                                    label="Date To"
                                    value={this.state.filterFields.dateTo}
                                    locale= "en"
                                    showDefaultDate="true"
                                    style={{height: "5px", width: "5px"}}
                                  />
                                </Grid>
                              </Grid>
                              <Grid container style={{ marginTop: "27px" }}>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={this.handleDateRangeApply}
                                >
                                  Apply
                                </Button>
                              </Grid>
                            </>
                          ) : null}
                        </Grid>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </Grid>
                  <Grid item xs={12} md={6} style={{ marginTop: "15px" }}>
                    <Grid container justify="flex-end">
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{
                            minWidth: "100px",
                            width: "6vw",
                            margin: "3px",
                            height: "38px",
                            fontSize: "16px",
                          }}
                          onClick={this.generatePdf}
                        >
                          <FontAwesomeIcon
                            icon={faFilePdf}
                            color="#3f51b5"
                            size="30px"
                            style={{
                              marginRight: "10px",
                            }}
                          ></FontAwesomeIcon>
                          Pdf
                        </Button>
                      </Grid>

                      <Grid item>
                        <ReactToPrint
                          trigger={() => (
                            <Button
                              variant="outlined"
                              color="primary"
                              style={{
                                minWidth: "100px",
                                width: "6vw",
                                margin: "3px",
                                height: "38px",
                                fontSize: "16px",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faPrint}
                                color="#3f51b5"
                                size="30px"
                                style={{
                                  marginRight: "10px",
                                }}
                              ></FontAwesomeIcon>
                              Print
                            </Button>
                          )}
                          content={() => this.componentRef}
                        />
                      </Grid>

                      <Grid item>
                        <ExcelFile
                          filename="ProfitAndLossAccount"
                          element={
                            <Button
                              variant="outlined"
                              color="primary"
                              style={{
                                minWidth: "100px",
                                width: "6vw",
                                margin: "3px",
                                height: "38px",
                                fontSize: "16px",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faFileExcel}
                                color="#3f51b5"
                                size="30px"
                                style={{
                                  marginRight: "10px",
                                }}
                              ></FontAwesomeIcon>
                              Excel
                            </Button>
                          }
                        >
                          <ExcelSheet
                            data={this.state.excelData}
                            name="Profit and Loss Account"
                          >
                            <ExcelColumn value="firstAccountCode" />
                            <ExcelColumn value="firstAccountName" />
                            <ExcelColumn value="firstSubAmount" />
                            <ExcelColumn value="firstTotalAmount" />
                          </ExcelSheet>
                        </ExcelFile>
                      </Grid>
                    </Grid>
                  </Grid>

                  {this.state.showDetails ? (
                    <>
                      {this.state.filterTerm === "fiscalYear" ? (
                        <Grid
                          container
                          style={{
                            margin: "20px",
                            marginLeft: "5px",
                            maxWidth: "700px",
                          }}
                        >
                          <Grid item xs={4}>
                            <Grid container>
                              <Grid>
                                <Typography
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: 600,
                                    marginRight: "10px",
                                    color: "#108DCD",
                                  }}
                                >
                                  Fiscal Year:
                                </Typography>
                              </Grid>
                              <Grid>
                                <Typography
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: 400,
                                    color: "#108DCD",
                                  }}
                                >
                                  {this.state.currentFy}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={4}>
                            <Grid container>
                              <Grid>
                                <Typography
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: 600,
                                    marginRight: "10px",
                                    color: "#108DCD",
                                  }}
                                >
                                  Period From:
                                </Typography>
                              </Grid>
                              <Grid>
                                <Typography
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: 400,
                                    color: "#108DCD",
                                  }}
                                >
                                  {this.state.filterFields.periodFrom}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid xs={4}>
                            <Grid container>
                              <Grid>
                                <Typography
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: 600,
                                    marginRight: "10px",
                                    color: "#108DCD",
                                  }}
                                >
                                  Period To:
                                </Typography>
                              </Grid>
                              <Grid>
                                <Typography
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: 400,
                                    color: "#108DCD",
                                  }}
                                >
                                  {this.state.filterFields.periodTo}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <>
                          <Grid
                            container
                            style={{
                              margin: "20px",
                              marginLeft: "5px",
                              maxWidth: "500px",
                            }}
                          >
                            <Grid item xs={6}>
                              <Grid container>
                                <Grid>
                                  <Typography
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: 600,
                                      marginRight: "10px",
                                      color: "#108DCD",
                                    }}
                                  >
                                    Date From:
                                  </Typography>
                                </Grid>
                                <Grid>
                                  <Typography
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: 400,
                                      color: "#108DCD",
                                    }}
                                  >
                                    {this.state.filterFields.dateFrom}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={6}>
                              <Grid container>
                                <Grid>
                                  <Typography
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: 600,
                                      color: "#108DCD",
                                      marginRight: "10px",
                                    }}
                                  >
                                    Date To:
                                  </Typography>
                                </Grid>
                                <Grid>
                                  <Typography
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: 400,
                                      color: "#108DCD",
                                    }}
                                  >
                                    {this.state.filterFields.dateTo}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </>
                  ) : null}

                  {this.state.showLoading ? (
                    <Grid
                      container
                      justify="center"
                      style={{ marginTop: "50px" }}
                    >
                      <Grid item>
                        <CircularProgress />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      justify="center"
                      style={{ marginTop: "10px" }}
                    >
                      {/* <Grid container justify="space-between">
                        <Grid item>Dr.</Grid>
                        <Grid item>Cr.</Grid>
                      </Grid> */}

                      <TableContainer>
                        <Grid item>
                          <Table
                            aria-label="customized table"
                            fixedHeader={false}
                            style={{
                              marginTop: "10px",
                              border: "1px solid #EAEDEF",
                            }}
                            size="small"
                          >
                            <TableHead>
                              <TableRow>
                                {/* <TableCell
                                  style={{
                                    width: "40px",
                                    backgroundColor: "#F1F8FC",
                                    color: "#3F51B5",
                                    borderRight: "1px solid #EAEDEF",
                                  }}
                                >
                                  Action
                                </TableCell> */}
                                <TableCell
                                  style={{
                                    width: "100px",
                                    backgroundColor: "#F1F8FC",
                                    color: "#3F51B5",
                                    borderRight: "1px solid #EAEDEF",
                                  }}
                                >
                                  Account Code
                                </TableCell>
                                <TableCell style={styledTableCell}>
                                  Account Name
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "150px",
                                    backgroundColor: "#F1F8FC",
                                    color: "#3F51B5",
                                    borderRight: "1px solid #EAEDEF",
                                  }}
                                >
                                  Amount
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            {this.state.showTable ? (
                              <TableBody>
                                <TableRow>
                                  {/* <TableCell
                                  style={{
                                    borderRight: "1px solid #EAEDEF",
                                    borderBottom: "1px solid #EAEDEF",
                                    width: "40px",
                                    minWidth: "40px",
                                    cursor:
                                      this.state.staticRowOne.childs.length > 0
                                        ? "pointer"
                                        : "",
                                  }}
                                  onClick={this.expandRowOne}
                                >
                                  {this.state.staticRowOne.childs.length > 0 &&
                                    this.state.staticRowOne.expanded && (
                                      <ExpandMoreIcon />
                                    )}
                                  {this.state.staticRowOne.childs.length > 0 &&
                                    !this.state.staticRowOne.expanded && (
                                      <ChevronRightIcon />
                                    )}
                                </TableCell> */}

                                  <TableCell
                                    style={snRow}
                                    onDoubleClick={() =>
                                      this.onDoubleClick(300)
                                    }
                                  >
                                    300
                                  </TableCell>
                                  <TableCell
                                    style={particularRow}
                                    onDoubleClick={() =>
                                      this.onDoubleClick(300)
                                    }
                                  >
                                    {this.state.staticValues[1]
                                      .profitAndLossSubAccounts.length > 0 &&
                                      this.state.staticValues[1].expanded && (
                                        <ExpandMoreIcon
                                          onClick={() =>
                                            this.expandStaticValues("Sales")
                                          }
                                          style={{ cursor: "pointer" }}
                                        />
                                      )}
                                    {this.state.staticValues[1]
                                      .profitAndLossSubAccounts.length > 0 &&
                                      !this.state.staticValues[1].expanded && (
                                        <ChevronRightIcon
                                          onClick={() =>
                                            this.expandStaticValues("Sales")
                                          }
                                          style={{ cursor: "pointer" }}
                                        />
                                      )}
                                    Sales
                                  </TableCell>
                                  <TableCell
                                    style={amountRow}
                                    onDoubleClick={() =>
                                      this.onDoubleClick(300)
                                    }
                                  >
                                    {format.format(
                                      this.state.staticValues[1].amount
                                    )}
                                  </TableCell>
                                </TableRow>
                                {this.state.staticValues[1].expanded &&
                                  this.state.staticValues[1].profitAndLossSubAccounts.map(
                                    (childVal) => (
                                      <TableRow
                                        onDoubleClick={() =>
                                          this.onDoubleClickSubAccount(
                                            300,
                                            childVal.subAccountCode
                                          )
                                        }
                                        style={{
                                          backgroundColor: "#EAEDEF",
                                          fontStyle: "italic",
                                        }}
                                      >
                                        <TableCell style={snRow}>
                                          {childVal.subAccountCode}
                                        </TableCell>
                                        <TableCell style={particularRow}>
                                          {childVal.subAccountName}
                                        </TableCell>
                                        <TableCell style={amountRow}>
                                          {format.format(childVal.amount)}
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                <TableRow>
                                  <TableCell
                                    style={snRow}
                                    onDoubleClick={() =>
                                      this.onDoubleClick(400)
                                    }
                                  >
                                    400
                                  </TableCell>
                                  <TableCell
                                    style={particularRow}
                                    onDoubleClick={() =>
                                      this.onDoubleClick(400)
                                    }
                                  >
                                    {this.state.staticValues[2]
                                      .profitAndLossSubAccounts.length > 0 &&
                                      this.state.staticValues[2].expanded && (
                                        <ExpandMoreIcon
                                          onClick={() =>
                                            this.expandStaticValues("Purchase")
                                          }
                                          style={{ cursor: "pointer" }}
                                        />
                                      )}
                                    {this.state.staticValues[2]
                                      .profitAndLossSubAccounts.length > 0 &&
                                      !this.state.staticValues[2].expanded && (
                                        <ChevronRightIcon
                                          onClick={() =>
                                            this.expandStaticValues("Purchase")
                                          }
                                          style={{ cursor: "pointer" }}
                                        />
                                      )}
                                    Purchase
                                  </TableCell>
                                  <TableCell
                                    style={amountRow}
                                    onDoubleClick={() =>
                                      this.onDoubleClick(400)
                                    }
                                  >
                                    {format.format(
                                      this.state.staticValues[2].amount
                                    )}
                                  </TableCell>
                                </TableRow>

                                {this.state.staticValues[2].expanded &&
                                  this.state.staticValues[2].profitAndLossSubAccounts.map(
                                    (childVal) => (
                                      <TableRow
                                        onDoubleClick={() =>
                                          this.onDoubleClickSubAccount(
                                            400,
                                            childVal.subAccountCode
                                          )
                                        }
                                        style={{
                                          backgroundColor: "#EAEDEF",
                                          fontStyle: "italic",
                                        }}
                                      >
                                        <TableCell style={snRow}>
                                          {childVal.subAccountCode}
                                        </TableCell>
                                        <TableCell style={particularRow}>
                                          {childVal.subAccountName}
                                        </TableCell>
                                        <TableCell style={amountRow}>
                                          {format.format(childVal.amount)}
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}

                                <TableRow>
                                  <TableCell
                                    style={snRow}
                                    onDoubleClick={() =>
                                      this.onDoubleClick(120)
                                    }
                                  >
                                    492
                                  </TableCell>
                                  <TableCell
                                    style={particularRow}
                                    onDoubleClick={() =>
                                      this.onDoubleClick(120)
                                    }
                                  >
                                    {this.state.staticValues[3]
                                      .profitAndLossSubAccounts.length > 0 &&
                                      this.state.staticValues[3].expanded && (
                                        <ExpandMoreIcon
                                          onClick={() =>
                                            this.expandStaticValues(
                                              "ChangesInInventory"
                                            )
                                          }
                                          style={{ cursor: "pointer" }}
                                        />
                                      )}
                                    {this.state.staticValues[3]
                                      .profitAndLossSubAccounts.length > 0 &&
                                      !this.state.staticValues[3].expanded && (
                                        <ChevronRightIcon
                                          onClick={() =>
                                            this.expandStaticValues(
                                              "ChangesInInventory"
                                            )
                                          }
                                          style={{ cursor: "pointer" }}
                                        />
                                      )}
                                    Changes In Inventory
                                  </TableCell>
                                  <TableCell
                                    style={amountRow}
                                    onDoubleClick={() =>
                                      this.onDoubleClick(120)
                                    }
                                  >
                                    {format.format(
                                      this.state.staticValues[3].amount
                                    )}
                                  </TableCell>
                                </TableRow>
                                {this.state.staticValues[3].expanded &&
                                  this.state.staticValues[3].profitAndLossSubAccounts.map(
                                    (childVal) => (
                                      <TableRow
                                        onDoubleClick={() =>
                                          this.onDoubleClickSubAccount(
                                            492,
                                            childVal.subAccountCode
                                          )
                                        }
                                        style={{
                                          backgroundColor: "#EAEDEF",
                                          fontStyle: "italic",
                                        }}
                                      >
                                        <TableCell style={snRow}>
                                          {childVal.subAccountCode}
                                        </TableCell>
                                        <TableCell style={particularRow}>
                                          {childVal.subAccountName}
                                        </TableCell>
                                        <TableCell style={amountRow}>
                                          {format.format(childVal.amount)}
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                <TableRow>
                                  <TableCell style={snRow}></TableCell>
                                  <TableCell style={particularRow}>
                                    <Typography style={{ fontWeight: "bold" }}>
                                      Cost Of Goods Sold
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontWeight: "bold",
                                      textAlign: "right",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {format.format(this.state.costOfGoodsSold)}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  {/* <TableCell style={actionRow}></TableCell> */}
                                  <TableCell style={snRow}></TableCell>
                                  <TableCell style={particularRow}>
                                    <Typography style={{ fontWeight: "bold" }}>
                                      Direct Expenses
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontWeight: "bold",
                                      textAlign: "right",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {format.format(
                                      this.state.totalDirectExpenses
                                    )}
                                  </TableCell>
                                </TableRow>

                                {this.state.directExpenses.map((val) => (
                                  <>
                                    <TableRow
                                      onDoubleClick={() =>
                                        this.onDoubleClick(val.accountCode)
                                      }
                                    >
                                      {/* <TableCell
                                      style={{
                                        borderRight: "1px solid #EAEDEF",
                                        borderBottom: "1px solid #EAEDEF",
                                        width: "40px",
                                        minWidth: "40px",
                                        cursor:
                                          val.profitAndLossSubAccounts.length >
                                          0
                                            ? "pointer"
                                            : "",
                                      }}
                                      onClick={() =>
                                        this.expandDirectExpenses(
                                          val.accountCode
                                        )
                                      }
                                    >
                                      {val.profitAndLossSubAccounts.length >
                                        0 &&
                                        val.expanded && <ExpandMoreIcon />}
                                      {val.profitAndLossSubAccounts.length >
                                        0 &&
                                        !val.expanded && <ChevronRightIcon />}
                                    </TableCell> */}
                                      <TableCell style={snRow}>
                                        {val.accountCode}
                                      </TableCell>
                                      <TableCell style={particularRow}>
                                        {val.profitAndLossSubAccounts.length >
                                          0 &&
                                          val.expanded && (
                                            <ExpandMoreIcon
                                              onClick={() =>
                                                this.expandDirectExpenses(
                                                  val.accountCode
                                                )
                                              }
                                              style={{ cursor: "pointer" }}
                                            />
                                          )}
                                        {val.profitAndLossSubAccounts.length >
                                          0 &&
                                          !val.expanded && (
                                            <ChevronRightIcon
                                              onClick={() =>
                                                this.expandDirectExpenses(
                                                  val.accountCode
                                                )
                                              }
                                              style={{ cursor: "pointer" }}
                                            />
                                          )}
                                        {val.accountName}
                                      </TableCell>
                                      <TableCell style={amountRow}>
                                        {format.format(val.amount)}
                                      </TableCell>
                                    </TableRow>
                                    {val.expanded &&
                                      val.profitAndLossSubAccounts.map(
                                        (childVal) => (
                                          <TableRow
                                            onDoubleClick={() =>
                                              this.onDoubleClickSubAccount(
                                                val.accountCode,
                                                childVal.subAccountCode
                                              )
                                            }
                                            style={{
                                              backgroundColor: "#EAEDEF",
                                              fontStyle: "italic",
                                            }}
                                          >
                                            <TableCell style={snRow}>
                                              {childVal.subAccountCode}
                                            </TableCell>
                                            <TableCell style={particularRow}>
                                              {childVal.subAccountName}
                                            </TableCell>
                                            <TableCell style={amountRow}>
                                              {format.format(childVal.amount)}
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                  </>
                                ))}

                                {/* for gross total */}

                                <TableRow style={totalRow}>
                                  {/* <TableCell style={actionRow}></TableCell> */}

                                  <TableCell style={snRow}></TableCell>
                                  <TableCell style={particularRow}>
                                    <Typography style={{ fontWeight: "bold" }}>
                                      To (Gross Profit)/Loss
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontWeight: "bold",
                                      textAlign: "right",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {format.format(this.state.grossProfitLoss)}
                                  </TableCell>
                                </TableRow>

                                {/* </TableContainer> */}

                                <TableRow>
                                  {/* <TableCell style={actionRow}></TableCell> */}

                                  <TableCell style={snRow}></TableCell>
                                  <TableCell style={particularRow}>
                                    <Typography style={{ fontWeight: "bold" }}>
                                      Indirect Expenses
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontWeight: "bold",
                                      textAlign: "right",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {format.format(
                                      this.state.totalIndirectExpenses
                                    )}
                                  </TableCell>
                                </TableRow>

                                {this.state.indirectExpenses.map((val) => (
                                  <>
                                    <TableRow>
                                      {/* <TableCell
                                      style={{
                                        borderRight: "1px solid #EAEDEF",
                                        borderBottom: "1px solid #EAEDEF",
                                        width: "40px",
                                        minWidth: "40px",
                                        cursor:
                                          val.childs.length > 0
                                            ? "pointer"
                                            : "",
                                      }}
                                      onClick={() =>
                                        this.expandBottomTable(val.uuid)
                                      }
                                    >
                                      <Typography>
                                        {val.childs.length > 0 &&
                                          val.expanded && <ExpandMoreIcon />}
                                        {val.childs.length > 0 &&
                                          !val.expanded && <ChevronRightIcon />}
                                      </Typography>
                                    </TableCell> */}

                                      <TableCell
                                        style={snRow}
                                        onDoubleClick={() =>
                                          this.onDoubleClick(val.accountCode)
                                        }
                                      >
                                        <Typography>
                                          {val.accountCode}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        style={particularRow}
                                        onDoubleClick={() =>
                                          this.onDoubleClick(val.accountCode)
                                        }
                                      >
                                        <Typography>
                                          {val.profitAndLossSubAccounts.length >
                                            0 &&
                                            val.expanded && (
                                              <ExpandMoreIcon
                                                onClick={() =>
                                                  this.expandIndirectExpenses(
                                                    val.accountCode
                                                  )
                                                }
                                                style={{ cursor: "pointer" }}
                                              />
                                            )}
                                          {val.profitAndLossSubAccounts.length >
                                            0 &&
                                            !val.expanded && (
                                              <ChevronRightIcon
                                                onClick={() =>
                                                  this.expandIndirectExpenses(
                                                    val.accountCode
                                                  )
                                                }
                                                style={{ cursor: "pointer" }}
                                              />
                                            )}
                                          {val.accountName}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        style={amountRow}
                                        onDoubleClick={() =>
                                          this.onDoubleClick(val.accountCode)
                                        }
                                      >
                                        <Typography>
                                          {format.format(val.amount)}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                    {val.expanded &&
                                      val.profitAndLossSubAccounts.map(
                                        (childVal) => (
                                          <TableRow
                                            style={{
                                              backgroundColor: "#EAEDEF",
                                            }}
                                          >
                                            <TableCell
                                              style={snRow}
                                              onDoubleClick={() =>
                                                this.onDoubleClickSubAccount(
                                                  val.accountCode,
                                                  childVal.subAccountCode
                                                )
                                              }
                                            >
                                              <span
                                                style={{ fontStyle: "italic" }}
                                              >
                                                {childVal.subAccountCode}
                                              </span>
                                            </TableCell>
                                            <TableCell
                                              style={particularRow}
                                              onDoubleClick={() =>
                                                this.onDoubleClickSubAccount(
                                                  val.accountCode,
                                                  childVal.subAccountCode
                                                )
                                              }
                                            >
                                              <span
                                                style={{ fontStyle: "italic" }}
                                              >
                                                {childVal.subAccountName}
                                              </span>
                                            </TableCell>
                                            <TableCell
                                              style={amountRow}
                                              onDoubleClick={() =>
                                                this.onDoubleClickSubAccount(
                                                  val.accountCode,
                                                  childVal.subAccountCode
                                                )
                                              }
                                            >
                                              <span
                                                style={{ fontStyle: "italic" }}
                                              >
                                                {format.format(childVal.amount)}
                                              </span>
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                  </>
                                ))}

                                <TableRow>
                                  {/* <TableCell style={actionRow}></TableCell> */}

                                  <TableCell style={snRow}></TableCell>
                                  <TableCell style={particularRow}>
                                    <Typography style={{ fontWeight: "bold" }}>
                                      Indirect Income
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontWeight: "bold",
                                      textAlign: "right",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {format.format(
                                      this.state.totalIndirectIncome
                                    )}
                                  </TableCell>
                                </TableRow>

                                {this.state.otherIncome.map((val) => (
                                  <>
                                    <TableRow>
                                      <TableCell
                                        style={snRow}
                                        onDoubleClick={() =>
                                          this.onDoubleClick(val.accountCode)
                                        }
                                      >
                                        <Typography>
                                          {val.accountCode}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        style={particularRow}
                                        onDoubleClick={() =>
                                          this.onDoubleClick(val.accountCode)
                                        }
                                      >
                                        <Typography>
                                          {val.profitAndLossSubAccounts.length >
                                            0 &&
                                            val.expanded && (
                                              <ExpandMoreIcon
                                                onClick={() =>
                                                  this.expandIndirectIncome(
                                                    val.accountCode
                                                  )
                                                }
                                                style={{ cursor: "pointer" }}
                                              />
                                            )}
                                          {val.profitAndLossSubAccounts.length >
                                            0 &&
                                            !val.expanded && (
                                              <ChevronRightIcon
                                                onClick={() =>
                                                  this.expandIndirectIncome(
                                                    val.accountCode
                                                  )
                                                }
                                                style={{ cursor: "pointer" }}
                                              />
                                            )}
                                          {val.accountName}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        style={amountRow}
                                        onDoubleClick={() =>
                                          this.onDoubleClick(val.accountCode)
                                        }
                                      >
                                        <Typography>
                                          {format.format(val.amount)}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                    {val.expanded &&
                                      val.profitAndLossSubAccounts.map(
                                        (childVal) => (
                                          <TableRow
                                            style={{
                                              backgroundColor: "#EAEDEF",
                                            }}
                                          >
                                            <TableCell
                                              style={snRow}
                                              onDoubleClick={() =>
                                                this.onDoubleClickSubAccount(
                                                  val.accountCode,
                                                  childVal.subAccountCode
                                                )
                                              }
                                            >
                                              <span
                                                style={{ fontStyle: "italic" }}
                                              >
                                                {childVal.subAccountCode}
                                              </span>
                                            </TableCell>
                                            <TableCell
                                              style={particularRow}
                                              onDoubleClick={() =>
                                                this.onDoubleClickSubAccount(
                                                  val.accountCode,
                                                  childVal.subAccountCode
                                                )
                                              }
                                            >
                                              <span
                                                style={{ fontStyle: "italic" }}
                                              >
                                                {childVal.subAccountName}
                                              </span>
                                            </TableCell>
                                            <TableCell
                                              style={amountRow}
                                              onDoubleClick={() =>
                                                this.onDoubleClickSubAccount(
                                                  val.accountCode,
                                                  childVal.subAccountCode
                                                )
                                              }
                                            >
                                              <span
                                                style={{ fontStyle: "italic" }}
                                              >
                                                {format.format(childVal.amount)}
                                              </span>
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                  </>
                                ))}

                                <TableRow style={totalRow}>
                                  {/* <TableCell style={actionRow}></TableCell> */}
                                  <TableCell style={snRow}></TableCell>
                                  <TableCell style={particularRow}>
                                    <Typography style={{ fontWeight: "bold" }}>
                                      Net (Profit)/Loss
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontWeight: "bold",
                                      textAlign: "right",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {format.format(this.state.netProfitLoss)}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            ) : null}
                          </Table>
                        </Grid>
                      </TableContainer>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <div style={{ display: "none" }}>
          <ProfitAndLossAccountPrint
            ref={(el) => (this.componentRef = el)}
            staticValues={this.state.staticValues}
            staticRowOne={this.state.staticRowOne}
            staticRowTwo={this.state.staticRowTwo}
            bottomTable={this.state.bottomTable}
            directExpenses={this.state.directExpenses}
            indirectExpenses={this.state.indirectExpenses}
            otherIncome={this.state.otherIncome}
            toGrossProfit={this.state.toGrossProfit}
            subTotalDebit={this.state.subTotalDebit}
            subTotalCredit={this.state.subTotalCredit}
            netProfit={this.state.netProfit}
            totalDebit={this.state.totalDebit}
            totalCredit={this.state.totalCredit}
            filterTerm={this.state.filterTerm}
            currentFy={this.state.currentFy}
            filterFields={this.state.filterFields}
          />
        </div>
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(ProfitAndLossAccount));
