import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { Typography, Grid, withStyles, Button } from "@material-ui/core";
import Axios from "axios";
import { Dropdown, Form } from "semantic-ui-react";

const styles = theme => ({
  root: {
    padding: 20,
    paddingTop: "10px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "150px"
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px"
    },

    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px"
    }
  }
});

class ChooseItemsModal extends Component {
  constructor(props) {
    super(props);
    const rowIndex = params => params.node.rowIndex + 1;
    this.state = {
      columnDefs: [
        {
          headername: "",
          width: 70,
          headerCheckboxSelection: true,
          checkboxSelection: true
        },
        {
          headername: "Sn",
          field: "sn",
          sortable: true,
          resizable: true,
          valueGetter: rowIndex,
          width: 50
        },
        {
          headerName: "Item Code",
          field: "itemCode",
          resizable: true,
          sortable: true,
          width: 90
        },
        {
          headerName: "Item Name",
          field: "itemName",
          resizable: true
        },
        {
          headerName: "Major Category",
          field: "majorCategory",
          resizable: true,
          sortable: true,
          width: 110
        },
        {
          headerName: "Category",
          field: "category",
          resizable: true,
          sortable: true,
          width: 110
        },
        {
          headerName: "Sub Category",
          field: "subCategory",
          resizable: true,
          sortable: true,
          width: 110
        },
        {
          headerName: "Marked Price",
          field: "markedPrice",
          resizable: true,
          sortable: true,
          width: 100
        },
        {
          headerName: "Quantity",
          field: "quantity",
          resizable: true,
          sortable: true,
          width: 80
        }
      ],
      rowData: [],
      origData: [],
      open: false,
      ids: [],
      itemCodeList: [],
      categoriesList: [],
      majorCategoriesList: [],
      subCategoriesList: [],
      warehouseList: [],
      toPrintArr: [],
      propertyTypeList: [
        { key: 0, value: "None", text: "None" },
        { key: 1, value: "Stock", text: "Stock" },
        { key: 2, value: "Inventory", text: "Inventory" }
      ],
      totalItemCount: 0,
      overlayLoadingTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
      overlayNoRowsTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>'
    };
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  onSelectionChanged = params => {
    var selectedNodes = params.api.getSelectedNodes();

    var arr = [];

    selectedNodes.map(val => arr.push(val.data.id));

    this.setState({
      ids: arr
    });
  };

  handleAddAllToList = () => {
    var toSaveArr = [];

    this.state.rowData.map((val, i) =>
      this.state.ids.includes(val.id)
        ? toSaveArr.push({ ...val, sn: i + 1 })
        : null
    );

    this.props.addRows(toSaveArr);
    this.props.closeModal();
  };

  handleMajorCategorySelect = (event, data) => {
    const { value } = data;

    var rowAllData = [];

    if (value === "None") {
      this.setState({
        rowData: this.state.origData
      });
    } else {
      this.state.origData.forEach(element => {
        if (element.majorCategory === value) rowAllData.push(element);
      });

      this.setState({ rowData: rowAllData });
    }
  };

  handleCategorySelect = (event, data) => {
    const { value } = data;

    var rowAllData = [];

    if (value === "None") {
      this.setState({
        rowData: this.state.origData
      });
    } else {
      this.state.origData.forEach(element => {
        if (element.category === value) rowAllData.push(element);
      });

      this.setState({ rowData: rowAllData });
    }
  };

  handleSubCategorySelect = (event, data) => {
    const { value } = data;

    var rowAllData = [];

    if (value === "None") {
      this.setState({
        rowData: this.state.origData
      });
    } else {
      this.state.origData.forEach(element => {
        if (element.subCategory === value) rowAllData.push(element);
      });

      this.setState({ rowData: rowAllData });
    }
  };

  handleWarehouseSelect = (event, data) => {
    const { value } = data;

    var rowAllData = [];

    if (value === "None") {
      this.setState({
        rowData: this.state.origData
      });
    } else {
      this.state.origData.forEach(element => {
        if (element.warehouse === value) rowAllData.push(element);
      });

      this.setState({ rowData: rowAllData });
    }
  };

  handleItemCodeSelect = (event, data) => {
    const { value } = data;

    var rowAllData = [];

    if (value === "None") {
      this.setState({
        rowData: this.state.origData
      });
    } else {
      this.state.origData.forEach(element => {
        if (element.itemCode === value) rowAllData.push(element);
      });

      this.setState({ rowData: rowAllData });
    }
  };

  handlePropertyTypeSelect = (event, data) => {
    const { value } = data;

    var rowAllData = [];

    if (value === "None") {
      this.setState({
        rowData: this.state.origData
      });
    } else {
      this.state.origData.forEach(element => {
        if (element.propertyType === value) rowAllData.push(element);
      });
      this.setState({ rowData: rowAllData });
    }
  };

  componentDidUpdate() {
    if (!this.gridApi) return;
    this.gridApi.sizeColumnsToFit();
  }

  componentDidMount() {
    this.getInventoryListing();

    Axios.get("pos/category/getAllCategories")
      .then(res => {
        var listData = [];
        listData.push({ key: 0, value: "None", text: "None" });

        res.data.forEach(element => {
          var item = {
            key: element.id,
            value: element.categoryName,
            text: element.categoryName
          };

          listData.push(item);
        });

        this.setState({ categoriesList: listData });
      })
      .catch(err => {
        console.log(err);
      });

    Axios.get("pos/majorCategory/getAllMajorCategories")
      .then(res => {
        console.log("major category list");
        console.log(res);

        var listData = [];
        listData.push({ key: 0, value: "None", text: "None" });

        res.data.forEach(element => {
          var item = {
            key: element.id,
            value: element.majorCategoryName,
            text: element.majorCategoryName
          };

          listData.push(item);
        });

        this.setState({ majorCategoriesList: listData });
      })
      .catch(err => {
        console.log(err);
      });

    Axios.get("pos/subCategory/getAllSubCategories")
      .then(res => {
        console.log("sub category list");
        console.log(res);

        var listData = [];
        listData.push({ key: 0, value: "None", text: "None" });

        res.data.forEach(element => {
          var item = {
            key: element.id,
            value: element.subCategoryName,
            text: element.subCategoryName
          };

          listData.push(item);
        });

        this.setState({ subCategoriesList: listData });
      })
      .catch(err => {
        console.log(err);
      });

    Axios.get("pos/warehouse/getAllWarehouses")
      .then(res => {
        console.log("warehouse list");
        console.log(res);

        var listData = [];
        listData.push({ key: 0, value: "None", text: "None" });

        res.data.forEach(element => {
          var item = {
            key: element.id,
            value: element.name,
            text: element.name
          };

          listData.push(item);
        });

        this.setState({ warehouseList: listData });
      })
      .catch(err => {
        console.log(err);
      });
  }

  getInventoryListing = () => {
    var itemList = [];

    itemList.push({ key: 0, value: "None", text: "None" });

    Axios.get("pos/inventory/getAllInventories")
      .then(res => {
        var rowData = [];
        res.data.forEach((element, i) => {
          var obj = {
            itemCode: element.itemCode,
            itemName: element.itemName,
            majorCategory: element.majorCategory,
            category: element.category,
            subCategory: element.subCategory,
            quantity: element.quantity,
            status: element.status,
            assetsType: element.assetsType,
            custodian: element.custodian,
            id: element.id,
            inventorySubMaster: element.inventorySubMaster,
            propertyType: element.propertyType,
            manufactureDate: element.manufactureDate,
            manufactureName: element.manufacturerName,
            rate: element.rate,
            markedPrice: element.markedPrice,
            supplierName: element.supplierName,
            total: element.total,
            totalWithVat: element.totalWithVat,
            vatAmount: element.vatAmount,
            vatPercentage: element.vatPercentage,
            warehouse: element.warehouse
          };
          rowData.push(obj);

          itemList.push({
            key: i,
            value: element.itemCode,
            text: element.itemCode
          });
        });
        var rowDetailData = res.data;
        this.setState({
          rowData: rowData,
          origData: rowData,
          rowDetailData: rowDetailData,
          totalItemCount: res.data.length,
          itemCodeList: itemList
        });

        this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>"
        });
        try {
          this.gridApi.hideOverlay();
          if (res.data.length === 0) this.gridApi.showNoRowsOverlay();
        } catch (error) {
          console.log(error);
        }
      })
      .catch(err => {
        console.log(err);

        this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>"
        });
        try {
          this.gridApi.hideOverlay();
          this.gridApi.showNoRowsOverlay();
        } catch (error) {
          console.log(error);
        }
      });
  };

  componentWillMount() {
    window.removeEventListener("resize", this.daListener);
  }

  firstDataRendered = params => {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    window.addEventListener("resize", this.daListener);
  };

  daListener = () => {
    if (!this.gridApi) return;
    setTimeout(() => {
      this.gridApi.sizeColumnsToFit();
    }, 100);
  };

  setOpen = status => {
    this.setState({ open: status });
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  render(params) {
    return (
      <React.Fragment>
        <Grid container style={{ marginBottom: "100px" }}>
          <Grid container justify="center">
            <Typography variant="h5" component="h2">
              Inventory Catalogue
            </Typography>
          </Grid>
          <Grid
            container
            style={{
              border: "#87CEFA 2px solid",
              marginTop: "10px",
              borderRadius: "10px",
              paddingTop: "10px"
            }}
          >
            <Grid container spacing={1} xs={12} style={{ margin: "auto" }}>
              <Grid item xs={12} sm={6} lg={2}>
                <div
                  style={{
                    marginTop: "normal",
                    marginBottom: "25px"
                  }}
                >
                  <Form>
                    <Form.Field>
                      <label>Property Type</label>
                      <Dropdown
                        placeholder="Property Type"
                        fluid
                        search
                        style={{
                          width: "100%",
                          marginTop: "10px",
                          marginBottom: "5px",
                          fontSize: "14px"
                        }}
                        ref={search => {
                          this.searchInput = search;
                        }}
                        selection
                        onChange={this.handlePropertyTypeSelect}
                        options={this.state.propertyTypeList}
                      ></Dropdown>
                    </Form.Field>
                  </Form>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <div
                  style={{
                    marginTop: "normal",
                    marginBottom: "25px"
                  }}
                >
                  <Form>
                    <Form.Field>
                      <label>Warehouse</label>
                      <Dropdown
                        placeholder="Warehouse"
                        fluid
                        search
                        style={{
                          width: "100%",
                          marginTop: "10px",
                          marginBottom: "5px",
                          fontSize: "14px"
                        }}
                        ref={search => {
                          this.searchInput = search;
                        }}
                        selection
                        onChange={this.handleWarehouseSelect}
                        options={this.state.warehouseList}
                      ></Dropdown>
                    </Form.Field>
                  </Form>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} lg={2}>
                <div
                  style={{
                    marginTop: "normal",
                    marginBottom: "25px"
                  }}
                >
                  <Form>
                    <Form.Field>
                      <label>Major Category</label>

                      <Dropdown
                        placeholder="Major Category"
                        fluid
                        search
                        ref={search => {
                          this.searchInput = search;
                        }}
                        selection
                        style={{
                          width: "100%",
                          marginTop: "10px",
                          marginBottom: "5px",
                          fontSize: "14px"
                        }}
                        onChange={this.handleMajorCategorySelect}
                        options={this.state.majorCategoriesList}
                      ></Dropdown>
                    </Form.Field>
                  </Form>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <div
                  style={{
                    marginTop: "normal",
                    marginBottom: "25px"
                  }}
                >
                  <Form>
                    <Form.Field>
                      <label>Category</label>
                      <Dropdown
                        placeholder="Category"
                        fluid
                        search
                        style={{
                          width: "100%",
                          marginTop: "10px",
                          marginBottom: "5px",
                          fontSize: "14px"
                        }}
                        ref={search => {
                          this.searchInput = search;
                        }}
                        selection
                        onChange={this.handleCategorySelect}
                        options={this.state.categoriesList}
                      ></Dropdown>
                    </Form.Field>
                  </Form>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} lg={2}>
                <div
                  style={{
                    marginTop: "normal",
                    marginBottom: "25px"
                  }}
                >
                  <Form>
                    <Form.Field>
                      <label>Sub-Category</label>
                      <Dropdown
                        placeholder="Sub-Category"
                        fluid
                        search
                        style={{
                          width: "100%",
                          marginTop: "10px",
                          marginBottom: "5px",
                          fontSize: "14px"
                        }}
                        ref={search => {
                          this.searchInput = search;
                        }}
                        selection
                        onChange={this.handleSubCategorySelect}
                        options={this.state.subCategoriesList}
                      ></Dropdown>
                    </Form.Field>
                  </Form>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid container justify="center">
            <div
              className="ag-theme-balham"
              style={{
                height: "38vh",
                width: "100%",
                marginTop: "15px"
              }}
            >
              <Grid
                container
                justify="space-between"
                style={{ marginBottom: "10px" }}
              >
                <Grid item>
                  <h6 style={{ margin: "0px" }}>
                    {" "}
                    Item Count : {this.state.totalItemCount}
                  </h6>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={this.handleAddAllToList}
                  >
                    Add all to List
                  </Button>
                </Grid>
              </Grid>

              <AgGridReact
                columnDefs={this.state.columnDefs}
                rowData={this.state.rowData}
                onFirstDataRendered={this.firstDataRendered}
                onGridReady={this.onGridReady}
                overlayLoadingTemplate={this.state.overlayLoadingTemplate}
                overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                suppressRowClickSelection={true}
                rowSelection="multiple"
                onSelectionChanged={this.onSelectionChanged}
              ></AgGridReact>
            </div>
          </Grid>
        </Grid>
      </React.Fragment >
    );
  }
}

export default withStyles(styles)(ChooseItemsModal);
