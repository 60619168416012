import React, { Component } from "react";
import {
  Card,
  Typography,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  withStyles,
  TableContainer,
  TextField,
  MenuItem,
  Button,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { Animated } from "react-animated-css";
import { Dropdown } from "semantic-ui-react";
import Axios from "axios";
import ViewSalesVoucherAuthorization from "./ViewSalesVoucherAuthorizarion";

const styles = (theme) => ({
  root: {
    padding: 20,
    overflowY: "auto",
    [theme.breakpoints.up("xs")]: {
      width: "95vw",
      margin: "auto",
      marginBottom: "170px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "80vw",
      margin: "auto",
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "70vw",
      margin: "auto",
      marginBottom: "100px",
    },
  },
});

class SalesVoucherAuthorization extends Component {
  constructor(props) {
    super(props);

    this.state = {
      colorArr: {},
      filterShow: false,
      page: 0,
      rowsPerPage: 5,
      rowData: [],
      fyList: [],
      fiscalYear: "",
    };
  }

  componentDidMount() {
    this.getAllFYListing();
  }

  getAllFYListing = () => {
    Axios.get("/fy/getAllActivePeriods")
      .then((res) => {
        var fiscalYearLists = [];
        res.data.forEach((element) => {
          var fy = Object.keys(element);

          fiscalYearLists.push(fy[0]);
        });
        this.setState({
          fyList: fiscalYearLists,
          fiscalYear: fiscalYearLists[0],
        });
        this.props.handleFyChange(this.state.fiscalYear, "auth");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleFyChange = (e) => {
    var val = e.target.value;

    this.props.handleFyChange(val, "auth");
  };

  handleDateFrom = (e) => {
    var val = e.target.value;

    this.props.handleDateFrom(val, "auth");
  };

  handleDateTo = (e) => {
    var val = e.target.value;

    this.props.handleDateTo(val, "auth");
  };

  handleVoucherChange = (_e, { value }) => {
    this.props.handleVoucherCodeChange(value, "auth");
  };

  generateList() {
    let arr = [];
    let data = this.props.dataList;
    for (let index = 0; index < data.length; index++) {
      arr.push(
        <TableRow hover>
          <TableCell>{index + 1}</TableCell>
          <TableCell>
            {" "}
            <span style={{ fontWeight: "bold" }}>{data[index].period}</span>
          </TableCell>
          <TableCell>{data[index].voucherNumber}</TableCell>
          <TableCell>{data[index].date}</TableCell>
          <TableCell>{data[index].status}</TableCell>
          <TableCell>{data[index].narration}</TableCell>
          <TableCell
            align="center"
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.props.animate(data[index].id);
            }}
          >
            <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
          </TableCell>
        </TableRow>
      );
    }
    return arr;
  }

  handleRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  render() {
    const styledTableCell = {
      backgroundColor: "#F1F8FC",
      color: "#3F51B5",
    };

    const inputStyles = {
      fontSize: 14,
    };

    const textFieldStyle = {
      marginBottom: "23px",
      marginTop: "23px",
      width: "100%",
    };

    var today = new Date();
    var month = (today.getMonth() + 1).toString();
    var day = today.getDate().toString();
    if (month.length === 1) {
      month = "0" + month;
    }
    if (day.length === 1) {
      day = "0" + day;
    }
    var date = today.getFullYear() + "-" + month + "-" + day;

    const {
      hidden,
      secondHidden,
      animationIn,
      visible,
      secondVisible,
    } = this.props.visible;

    const { classes } = this.props;

    return (
      <div>
        <Grid container justify="center">
          <Grid container justify="center">
            <Grid item>
              <Animated
                animationIn={animationIn}
                animationOut="zoomOut"
                isVisible={visible}
                animationInDuration={500}
                animationOutDuration={500}
              >
                <Card className={classes.root} hidden={hidden}>
                  <Typography
                    gutterButtom
                    variant="h5"
                    style={{ fontWeight: "bolder", marginBottom: "10px" }}
                  >
                    Sales Voucher List
                  </Typography>

                  <Grid container spacing={1}>
                    <Grid item>
                      <TextField
                        select
                        fullWidth
                        label="Fiscal Year"
                        name="fiscalYear"
                        style={{ width: "130px" }}
                        value={this.state.fiscalYear}
                        onChange={this.handleFyChange}
                      >
                        {this.state.fyList.map((item) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        style={{ marginTop: "15px" }}
                        onClick={() => {
                          this.setState({ filterShow: !this.state.filterShow });
                        }}
                      >
                        <Typography variant="button">
                          <FontAwesomeIcon
                            icon={faSlidersH}
                            style={{ marginRight: "5px" }}
                          ></FontAwesomeIcon>
                          Filter
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>

                  {this.state.filterShow ? (
                    <Grid
                      container
                      spacing={4}
                      style={{
                        marginLeft: "0px",
                        marginTop: 20,
                        marginBottom: 20,
                        border: "#87CEFA 2px solid",
                        borderRadius: "10px",
                        width: "67vw",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          marginLeft: "2em",
                          height: "10px",
                          display: "inline",
                          backgroundColor: "#fff",
                          paddingLeft: "20px",
                          paddingRight: "20px",
                          paddingBottom: "15px",
                        }}
                      ></div>
                      <Grid container spacing={4} style={{ padding: 20 }}>
                        <Grid item xs={7} md={6} sm={6}>
                          <Dropdown
                            placeholder="Search by Voucher Code"
                            fluid
                            search
                            ref={(search) => {
                              this.searchInput = search;
                            }}
                            selection
                            value={this.props.customer}
                            onChange={this.handleVoucherChange}
                            options={this.props.voucherList}
                            style={{ marginTop: "33px" }}
                          ></Dropdown>
                        </Grid>
                        <Grid item xs sm={3}>
                          <TextField
                            id="date-picker-inline"
                            label="Date From"
                            name="dateFrom"
                            defaultValue={date}
                            type="date"
                            onChange={this.handleDateFrom}
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs sm={3}>
                          <TextField
                            id="date-picker-inline"
                            label="Date To"
                            name="dateTo"
                            defaultValue={date}
                            type="date"
                            onChange={this.handleDateTo}
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                  <TableContainer>
                    <Table
                      aria-label="customized table"
                      style={{ marginTop: "20px", marginBottom: "15px" }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell style={styledTableCell}>S. No.</TableCell>
                          <TableCell style={styledTableCell}>Period</TableCell>
                          <TableCell style={styledTableCell}>
                            Voucher Number
                          </TableCell>
                          <TableCell style={styledTableCell}>
                            Transaction Date
                          </TableCell>
                          <TableCell style={styledTableCell}>Status</TableCell>
                          <TableCell style={styledTableCell}>
                            Narration
                          </TableCell>
                          <TableCell align="center" style={styledTableCell}>
                            View
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {this.generateList().slice(
                        this.state.page * this.state.rowsPerPage,
                        this.state.page * this.state.rowsPerPage +
                          this.state.rowsPerPage
                      )}
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={this.props.dataList.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={(event, newPage) => {
                      this.setState({ page: newPage });
                    }}
                    onChangeRowsPerPage={this.handleRowsPerPage}
                  />
                </Card>
              </Animated>
            </Grid>
            <Grid item>
              <Animated
                animationIn="zoomIn"
                animationOut="zoomOut"
                isVisible={secondVisible}
                animationInDuration={500}
                animationOutDuration={500}
              >
                <ViewSalesVoucherAuthorization
                  hidden={secondHidden}
                  data={this.props.data}
                  animateOut={this.props.animateOut}
                  updateData={this.getTableData}
                  handleViewCounter={this.props.handleViewCounter}
                  getTableDataForAuth={this.props.getTableDataForAuth}
                  getTableDataForList={this.props.getTableDataForList}
                  getSubAccountListforAuth={this.props.getSubAccountListforAuth}
                  handleTextChange={this.props.handleTextChange}
                  handleCustomerSelect={this.props.handleCustomerSelect}
                  handleCustomerIdSelect={this.props.handleCustomerIdSelect}
                  handleMemberChange={this.props.handleMemberChange}
                  customerOptionList={this.props.customerOptionList}
                  idOptionList={this.props.idOptionList}
                  fyList={this.props.fyList}
                  periodList={this.props.periodList}
                  memberList={this.props.memberList}
                  handleFyChangeView={this.props.handleFyChangeView}
                  handlePeriodChange={this.props.handlePeriodChange}
                />
              </Animated>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(SalesVoucherAuthorization);
