import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import adbs from "ad-bs-converter";
var converter = require("number-to-words");

class SalesInvoice3mm extends Component {
  calculateTotalQty = (salesDetails) => {
    let total = 0;

    salesDetails.map((val) => (total += val.quantity));

    return total;
  };

  render() {
    const {
      billAmount,
      tenderAmount,
      paymentMethod,
      change,
      soldBy,
      invoiceDate,
      invoiceNumber,
      customerData,
      salesDetails,
      totalDiscount,
    } = this.props;

    const topHeader = {
      fontSize: "16px",
    };

    const bottomHeader = {
      fontSize: "15px",
    };

    var hours = new Date().getHours();
    var min = new Date().getMinutes();
    var sec = new Date().getSeconds();

    var date = new Date().getDate();
    var month = new Date().getMonth() + 1;
    var year = new Date().getFullYear();

    var nowTime = hours + ":" + min + ":" + sec;

    var miti = adbs.ad2bs(year + "/" + month + "/" + date);

    return (
      <div>
        <div
          onClick={this.props.handlePrint}
          className={this.props.display === false ? "printOnly" : ""}
        >
          <Grid container style={{ width: "330px" }}>
            <Grid container justify="center">
              <span style={topHeader}>Hamro Lok Upabhokta Sahakari</span>
              <span style={topHeader}>Sanstha Limited</span>
            </Grid>
            <Grid container justify="center">
              <span style={topHeader}>Shiva Marga, MajheriPathan, Pokhara</span>
            </Grid>
            <Grid container justify="center">
              <span style={topHeader}>Phone: 9846087701</span>
            </Grid>
            <Grid container justify="center">
              <span style={topHeader}>PAN NO. : 601108185</span>
            </Grid>
            <Grid container justify="center">
              <span style={topHeader}>
                PRINT COUNT: Demo-{this.props.billCount}
              </span>
            </Grid>
            <Grid container justify="center">
              <span style={topHeader}>**INVOICE**</span>
            </Grid>
            <Grid
              container
              justify="space-between"
              style={{ marginTop: "20px" }}
            >
              <Grid item>
                <span style={bottomHeader}>Invoice No. : {invoiceNumber}</span>
              </Grid>
              <Grid item>
                <span style={bottomHeader}>
                  Invoice Date: {invoiceDate} (
                  {miti.en.day + "-" + miti.en.month + "-" + miti.en.year})
                </span>
              </Grid>
            </Grid>
            <Grid container justify="space-between">
              <Grid item>
                <span style={bottomHeader}>
                  Buyer's Name : {customerData.fullName}
                </span>
              </Grid>
            </Grid>
            <Grid container justify="space-between">
              <Grid item>
                <span style={bottomHeader}>
                  Address: {customerData.address}
                </span>
              </Grid>
            </Grid>
            <Grid container justify="space-between">
              <Grid item>
                <span style={bottomHeader}>
                  Phone No. : {customerData.mobileNo}
                </span>
              </Grid>
            </Grid>
            <Grid container justify="space-between">
              <Grid item>
                <span style={bottomHeader}>
                  Buyer's VAT/PAN: {customerData.pan}
                </span>
              </Grid>
              <Grid item style={{ marginRight: "20px", padding: 0 }}>
                <span style={bottomHeader}>
                  Mode of Payment: {paymentMethod}
                </span>
              </Grid>
            </Grid>
            <table
              style={{
                width: "100%",
                marginTop: "25px",
                tableLayout: "fixed",
              }}
            >
              <thead>
                <th
                  style={{
                    borderTop: "2px dotted #000",
                    borderBottom: "2px dotted #000",
                    width: "80px",
                  }}
                >
                  Particulars
                </th>
                <th
                  style={{
                    borderTop: "2px dotted #000",
                    borderBottom: "2px dotted #000",
                    width: "40px",
                  }}
                >
                  Qty
                </th>
                <th
                  style={{
                    borderTop: "2px dotted #000",
                    borderBottom: "2px dotted #000",
                    width: "40px",
                  }}
                >
                  Dis
                </th>
                <th
                  style={{
                    borderTop: "2px dotted #000",
                    borderBottom: "2px dotted #000",
                    width: "40px",
                  }}
                >
                  Rate
                </th>
                <th
                  style={{
                    borderTop: "2px dotted #000",
                    borderBottom: "2px dotted #000",
                    width: "60px",
                  }}
                >
                  Amount
                </th>
              </thead>
              <tbody>
                {/* {this.returnRows()} */}
                {salesDetails.map((val, i) =>
                  i === salesDetails.length - 1 ? (
                    <tr>
                      <td
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          paddingBottom: "20px",
                        }}
                      >
                        {val.itemName}
                      </td>
                      <td style={{ paddingBottom: "20px" }}>{val.quantity}</td>
                      <td style={{ paddingBottom: "20px" }}>{val.discount}</td>
                      <td style={{ paddingBottom: "20px" }}>{val.price}</td>
                      <td style={{ paddingBottom: "20px" }}>{val.lineTotal}</td>
                    </tr>
                  ) : (
                    <tr>
                      <td
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {val.itemName}
                      </td>
                      <td>{val.quantity}</td>
                      <td>{val.discount}</td>
                      <td>{val.price}</td>
                      <td>{val.lineTotal}</td>
                    </tr>
                  )
                )}

                <tr>
                  <td></td>
                  <td></td>
                  <td colSpan={2} style={{ borderTop: "1px dotted #000" }}>
                    <span>Gross Amount</span>
                  </td>
                  <td style={{ borderTop: "1px dotted #000" }}>{billAmount}</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td colSpan={2}>Discount</td>
                  <td>{Math.round((totalDiscount * 100) / 100).toFixed(2)}</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td colSpan={2}>Total</td>
                  <td>
                    {(
                      Math.round((billAmount - totalDiscount) * 100) / 100
                    ).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td colSpan={2} style={{ borderTop: "1px dotted #000" }}>
                    Tender
                  </td>
                  <td style={{ borderTop: "1px dotted #000" }}>
                    {tenderAmount}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td colSpan={2}>
                    <span>Change</span>
                  </td>
                  <td>{change}</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td colSpan={2} style={{ borderTop: "1px dotted #000" }}>
                    <span>Total Qty</span>
                  </td>
                  <td style={{ borderTop: "1px dotted #000" }}>
                    {this.calculateTotalQty(salesDetails)}
                  </td>
                </tr>

                <tr
                  style={{
                    borderTop: "1px solid #000",
                    borderRight: "1px solid #000",
                    borderLeft: "1px solid #000",
                  }}
                ></tr>
              </tbody>
            </table>

            <Grid container>
              <Grid item>
                In words: {converter.toWords(billAmount - totalDiscount)} only
              </Grid>
            </Grid>

            <Grid container>
              <Grid item>E. & O.E</Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{
                marginTop: "5px",
              }}
            >
              <Grid item>Printed By: {soldBy}</Grid>
              <Grid item>
                Printed Date and Time: {invoiceDate} {nowTime}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default SalesInvoice3mm;
