import React, { useState, useEffect } from "react";
import {
  withStyles,
  Grid,
  Card,
  CardContent,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Fade,
  Modal,
  Backdrop,
} from "@material-ui/core";
import { Dropdown } from "semantic-ui-react";
import EditSchemeModal from "./EditSchemeModal";
import Close from "@material-ui/icons/Close";
import Axios from "axios";
import ViewSchemeButton from "../InterfaceElements/ViewSchemeButton";

const styles = (theme) => ({
  root: {
    padding: 10,
    paddingTop: "7px",
    paddingBottom: "7px",
    minHeight: "540px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "170px",
      marginRight: "10px",
      marginLeft: "10px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
    },
  },
});

function ManageScheme(props) {
  const { children, value, index, classes, ...other } = props;

  const [showModal, setShowModal] = useState(false);

  const [responseData, setResponseData] = useState([]);

  const styledTableCell = {
    backgroundColor: "#F1F8FC",
    color: "#3F51B5",
  };

  const handleEffectiveDateFrom = (e) => {
    console.log("date change called");

    let targetValue = e.target.value;

    let targetName = e.target.name;

    let id = targetName.split(".");

    let newResponseData = [];

    responseData.forEach((element) => {
      let newElement = element;

      if (parseInt(id[1]) === newElement.id) {
        newElement.dateFrom = targetValue;
      }

      newResponseData.push(element);
    });

    setResponseData(newResponseData);
  };

  const handleEffectiveDateTo = (e) => {
    console.log("date change called");
    let targetValue = e.target.value;

    let targetName = e.target.name;

    let id = targetName.split(".");

    let newResponseData = [];

    responseData.forEach((element) => {
      let newElement = element;

      if (parseInt(id[1]) === newElement.id) {
        newElement.dateTo = targetValue;
      }

      newResponseData.push(element);
    });

    setResponseData(newResponseData);
  };

  const modal = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  useEffect(() => {
    Axios.get("/pos/scheme/findAll")
      .then((res) => {
        let responseData = [];

        res.data.forEach((element) => {
          let responseElement = element;

          let tableData = [];

          if (responseElement.schemeType === "BonusItem") {
            responseElement.schemeBonus.map(async (element) => {
              var res = await Axios.get(
                "pos/inventory/getInventoryByItemCode/" + element.itemCode
              );
              tableData.push(res.data);
            });
          } else {
            responseElement.schemeDiscounts.map(async (element) => {
              var res = await Axios.get(
                "pos/inventory/getInventoryByItemCode/" + element.itemCode
              );
              tableData.push(res.data);
            });
          }

          responseElement.tableData = tableData;

          responseData.push(responseElement);
        });

        setResponseData(responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log("rerendered");

  return (
    <div>
      <Grid container style={{ width: "100vw" }} justify="center">
        <Grid item xs={12} lg={6} md={7} sm={10}>
          <Card
            className={classes.root}
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
          >
            <CardContent style={{ padding: "10px" }}>
              <Grid container>
                <Typography variant="h5">Manage Scheme</Typography>
              </Grid>
              <Grid container style={{ marginTop: "20px" }}>
                <Grid item xs={6}>
                  <Dropdown
                    placeholder="Search By Item Code or Item Name"
                    fluid
                    style={{
                      minWidth: "230px",
                      fontSize: "14px",
                    }}
                    search
                    selection
                  ></Dropdown>
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "15px" }}>
                <TableContainer>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={styledTableCell}>SN</TableCell>
                        <TableCell style={styledTableCell}>
                          Scheme Type
                        </TableCell>
                        <TableCell style={styledTableCell}>Date From</TableCell>
                        <TableCell style={styledTableCell}>Date To</TableCell>
                        <TableCell style={styledTableCell}>Remarks</TableCell>
                        <TableCell style={styledTableCell} align="center">
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {responseData.map((value, i) => (
                        <TableRow key={i}>
                          <TableCell>{i + 1}</TableCell>
                          <TableCell>{value.schemeType}</TableCell>
                          <TableCell>{value.dateFrom}</TableCell>
                          <TableCell>{value.dateTo}</TableCell>
                          <TableCell>{value.remarks}</TableCell>
                          <TableCell align="center">
                            <ViewSchemeButton
                              value={value}
                              handleEffectiveDateFrom={handleEffectiveDateFrom}
                              handleEffectiveDateTo={handleEffectiveDateTo}
                              responseData={responseData}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          open={showModal}
          onClose={() => setShowModal(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={showModal}>
            <Grid container justify="center" className={classes.root}>
              <Grid item xs={12} lg={7} md={7} sm={10}>
                <Card>
                  <CardContent style={{ overflowY: "auto", padding: "25px" }}>
                    <Grid container justify="flex-end">
                      <IconButton onClick={() => setShowModal(false)}>
                        <Close />
                      </IconButton>
                    </Grid>
                    <EditSchemeModal />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Fade>
        </Modal>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(ManageScheme);
