import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import {
  Modal,
  Fade,
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Backdrop,
  withStyles,
  MenuItem,
  IconButton
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import UserContext from "../../utils/UserContext";
import ConfirmationDialog from "./ConfirmationDialog";

const styles = theme => ({
  cardHeight: {
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      height: "76vh"
    },
    [theme.breakpoints.up("md")]: {
      width: "70%",
      height: "425px"
    },
    [theme.breakpoints.up("lg")]: {
      width: "70%",
      height: "520px"
    }
  }
});

class ViewAccountButton extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      primaryGroupData: [],
      data: {
        name: "",
        code: "",
        description: "",
        status: "",
        under: "",
        underCode: ""
      },
      open: false,
      editable: false,
      confirmOpen: false,
      unclicked: "#108dcd",
      clicked: "#1044CD",
      buttonColor: "#108dcd"
    };
  }

  componentDidMount() {
    this.setState({
      data: {
        name: this.props.node.data.name,
        code: this.props.node.data.code,
        description: this.props.node.data.description,
        under: this.props.node.data.under,
        underCode: this.props.node.data.underCode,
        status: this.props.node.data.status
      }
    });

    Axios.get("pos/primaryGroup/getAllPrimaryGroups")
      .then(res => {
        this.primaryGroupData = res.data;
        console.log(res);
        this.setState({ primaryGroupData: this.primaryGroupData });
      })
      .catch(err => {
        console.log(err);
      });
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false, editable: false });
  };

  onChanged = e => {
    e.stopPropagation();
    var targetName = e.target.name;
    var value = e.target.value;
    var code = "";

    if (!this.state.editable) return;

    if (targetName === "under") {
      this.state.primaryGroupData.forEach(element => {
        if (element.name === value) {
          code = element.code;
        }
      });

      this.setState(prevState => ({
        data: {
          ...prevState.data,
          underCode: code,
          [targetName]: value
        }
      }));
    } else {
      this.setState(prevState => ({
        data: {
          ...prevState.data,
          [targetName]: value
        }
      }));
    }
  };

  handleUpdate = () => {
    var id = this.props.node.data.id;

    Axios.put("pos/accountGroup/updateAccountGroup/" + id, this.state.data)
      .then(res => {
        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000
        };

        this.props.setSnackbar(snackbarProps);
        this.props.updateData();
        this.handleClose();
        console.log(res);
      })
      .catch(err => {
        console.log(err);
        var snackbarProps = {
          open: true,
          variant: "error",
          message: err.message,
          autoHideDuration: 2000
        };

        this.props.setSnackbar(snackbarProps);
      });
  };

  handleDelete = () => {
    var id = this.props.node.data.id;

    Axios.delete("pos/accountGroup/deleteAccountGroup/" + id, this.state.data)
      .then(res => {
        this.props.updateData();
        this.handleClose();
        console.log(res);

        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000
        };

        this.props.setSnackbar(snackbarProps);
      })
      .catch(err => {
        console.log(err);
        var snackbarProps = {
          open: true,
          variant: "error",
          message: err.message,
          autoHideDuration: 2000
        };
        this.props.setSnackbar(snackbarProps);
      });
  };

  render() {
    const modal = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    };

    const inputStyles = {
      fontSize: 14
    };

    const textFieldStyle = {
      width: "100%"
    };

    const { classes } = this.props;
    return (
      <div>
        <span>
          <FontAwesomeIcon
            icon={faSearch}
            color="#3f51b5"
            onClick={() => this.handleOpen()}
          ></FontAwesomeIcon>
        </span>
        <ConfirmationDialog
          open={this.state.confirmOpen}
          handleClose={() => {
            this.setState({ confirmOpen: false });
          }}
          handleConfirm={this.handleDelete}
          titleMessage={"Delete Account?"}
          bodyMessage={
            "Account group entry will be removed and will not be available to use in ledger master."
          }
        />
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          open={this.state.open}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={this.state.open}>
            <Card className={classes.cardHeight}>
              <CardContent style={{ padding: "0px" }}>
                <Grid container justify="flex-end">
                  <IconButton onClick={this.handleClose}>
                    <Close />
                  </IconButton>
                </Grid>

                <Grid container style={{ padding: "20px" }}>
                  <Grid container justify="flex-end">
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      onClick={() => {
                        this.setState({
                          editable: true,
                          buttonColor: this.state.clicked
                        });
                      }}
                      style={{
                        margin: "15px",
                        backgroundColor: this.state.buttonColor
                      }}
                    >
                      Edit
                    </Button>
                  </Grid>
                  <Grid container spacing={2} justify="center">
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Name"
                        placeholder=""
                        name="name"
                        onChange={this.onChanged}
                        value={this.state.data.name}
                        inputProps={{ style: inputStyles }}
                        InputLabelProps={{ style: inputStyles }}
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Code"
                        placeholder=""
                        name="code"
                        onChange={this.onChanged}
                        value={this.state.data.code}
                        inputProps={{ style: inputStyles }}
                        InputLabelProps={{ style: inputStyles }}
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="standard-select-currency"
                        select
                        value={this.state.data.under}
                        InputProps={{ style: inputStyles }}
                        InputLabelProps={{ style: inputStyles }}
                        label="Under"
                        name="under"
                        onChange={this.onChanged}
                        style={textFieldStyle}
                        margin="normal"
                      >
                        {this.state.primaryGroupData.map((item, i) => (
                          <MenuItem key={i} value={item.name}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Code"
                        placeholder=""
                        name="underCode"
                        disabled
                        value={this.state.data.underCode}
                        inputProps={{ style: inputStyles }}
                        InputLabelProps={{ style: inputStyles }}
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Description"
                        placeholder=""
                        name="description"
                        onChange={this.onChanged}
                        value={this.state.data.description}
                        inputProps={{ style: inputStyles }}
                        InputLabelProps={{ style: inputStyles }}
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        id="standard-select-currency"
                        select
                        InputProps={{ style: inputStyles }}
                        InputLabelProps={{ style: inputStyles }}
                        label="Status"
                        name="status"
                        onChange={this.onChanged}
                        style={{ width: "30vw" }}
                        value={this.state.data.status}
                        margin="normal"
                      >
                        <MenuItem key={1} value={"ACTIVE"}>
                          Active
                        </MenuItem>
                        <MenuItem key={2} value={"PARKED"}>
                          Parked
                        </MenuItem>
                        <MenuItem key={3} value={"SUSPENDED"}>
                          Suspended
                        </MenuItem>
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={10}
                    justify="center"
                    style={{ width: "70%", margin: "auto", marginTop: "25px" }}
                  >
                    <Grid item xs={12} sm={4}>
                      <Grid container justify="center">
                        {this.state.editable ? (
                          <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            onClick={this.handleUpdate}
                            style={{
                              width: "100%",
                              maxWidth: "150px",
                              minWidth: "100px",
                              marginTop: "15px"
                            }}
                          >
                            Update
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            disabled
                            onClick={this.handleUpdate}
                            style={{
                              width: "100%",
                              maxWidth: "150px",
                              minWidth: "100px",
                              marginTop: "15px"
                            }}
                          >
                            Update
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Grid container justify="center">
                        <Button
                          variant="outlined"
                          size="large"
                          color="primary"
                          onClick={this.handleDelete}
                          style={{
                            width: "100%",
                            maxWidth: "150px",
                            minWidth: "100px",
                            marginTop: "15px"
                          }}
                        >
                          Delete
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Fade>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(ViewAccountButton);
