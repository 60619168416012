import React, { Component } from "react";
import {
  Grid,
  TextField,
  Divider,
  Button,
  MenuItem,
  withStyles,
} from "@material-ui/core";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import DeleteButton from "./InterfaceElements/DeleteButton";
import { Dropdown, Input } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import UserContext from "../utils/UserContext";
import { format } from "date-fns/esm";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Axios from "axios";
import VoucherNumberRestriction from "../components/InterfaceElements/VoucherNumberRestriction";
import ConfirmationDialog from "./InterfaceElements/ConfirmationDialog";
import adbs, { bs2ad } from "ad-bs-converter";
import dateFormat from "dateformat";
import "nepali-datepicker-reactjs/dist/index.css";
import NepaliDatepicker from 'nepali-datepicker-and-dateinput';

const styles = (theme) => ({
  containerWidth: {
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "70%",
    },
  },
  bottomContainerWidth: {
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "90%",
    },
  },
  bottonTopMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
    },

    [theme.breakpoints.up("sm")]: {
      marginTop: "20px",
      marginBottom: "5px",
    },
  },
});

class SalesReturnForm extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      confirmOpen: false,
      periodList: [],
      fyList: [],
      currentPeriod: "",
      periodResData: [],
      salesReturnNoList: [],
      voucherNumberError: false,
      voucherNumber: "",
      table: "",
      rowRefs: [],
      customerRowRefs: [],
      columnDefs: [
        {
          headerName: "SN",
          field: "sn",
          sortable: true,
          resizable: true,
          width: 40,
        },
        {
          headerName: "ItemCode",
          field: "itemCode",
          sortable: true,
          resizable: true,
          width: 70,
        },
        {
          headerName: "Name",
          field: "itemName",
          sortable: true,
          resizable: true,
        },
        {
          headerName: "Quantity",
          field: "quantity",
          sortable: true,
          resizable: true,
          editable: true,
          width: 70,
        },
        {
          headerName: "Price",
          field: "price",
          sortable: true,
          width: 70,
        },
        {
          headerName: "Discount",
          field: "discount",
          width: 70,
        },
        {
          headerName: "Line Total",
          field: "lineTotal",
          sortable: true,
          width: 70,
        },
        {
          headerName: "Delete",
          cellRendererFramework: DeleteButton,
          cellRendererParams: {
            deleteRow: this.deleteRow,
            getAllRows: this.getAllRows,
          },
          width: 50,
        },
      ],
      rowData: [],
      rowSelection: "multiple",
      value: "cash",
      data: {
        customerIdNumber: "",
        address: "",
        comment: "",
        creditMode: "Cash",
        creditNoteNumber: "",
        customerName: "",
        period: "",
        fiscalYear: "",
        date: "",
        nepaliDate: "",
        preparedBy: "",
        salesInvoiceNumber: "",
        salesReturnNumber: "",
        pan: "",
        salesReturnDetails: [],
        subTotal: 0,
        total: 0,
        totalDiscount: 0,
        totalTax: 0,
      },
      periodDateFrom: "",
      periodDateTo: "",

      quantityMax: 0,
      sn: 1,
      quantity: 1,
      subTotal: 0,
      taxes: 0,
      discount: 0,
      total: 0,
      itemDetails: {},
      resData: [],
      customerData: {
        customerName: "",
        address: "",
        pan: "",
      },
      emptyComment: false,
      numberSearch: true,
    };
  }

  handleInvoiceChange = (e) => {
    e.persist();

    let val = e.target.value;

    var rows = [];
    var customerDetails = this.state.customerData;

    var obj = { invoiceNumber: e.target.value };
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        salesInvoiceNumber: e.target.value,
      },
    }));

    Axios.post("pos/sales/getSalesByInvoiceNumberAndReturnStatus", obj)
      .then((res) => {
        if (res.data.salesDetail != null) {
          this.setState((prevState) => ({
            ...prevState,
            resData: res.data.salesDetail,
            numberSearch: true,
          }));

          this.setState((prevState) => ({
            ...prevState,
            data: {
              ...prevState.data,
              customerName:
                res.data.billTo === null
                  ? res.data.customer.fullName
                  : res.data.billTo,
              address:
                res.data.address === null
                  ? res.data.customer.city
                  : res.data.address,
              pan: res.data.pan === null ? res.data.customer.pan : res.data.pan,
              customerIdNumber:
                res.data.customerIdNumber === null
                  ? res.data.customer.customerIdNumber
                  : res.data.customerIdNumber,
              period: res.data.period,
            },
          }));

          res.data.salesDetail.forEach((element) => {
            const item = {
              key: element.itemCode,
              value: element.itemCode + element.itemName,
              text: element.itemName + " #" + element.itemCode,
              label: { content: "#" + element.itemCode, tag: true },
            };

            customerDetails.customerName =
              res.data.billTo === null || undefined ? "" : res.data.billTo;
            customerDetails.address =
              res.data.address === null || undefined ? "" : res.data.address;
            customerDetails.pan =
              res.data.pan === null || undefined ? "" : res.data.pan;

            this.setState(
              (prevState) => ({
                ...prevState,
                customerData: customerDetails,
                taxes: res.data.taxAmount,
                total: res.data.taxAmount,
              }),
              () => {
                console.log(this.state.customerData);
              }
            );

            rows.push(item);
          });
        } else {
          this.setState((prevState) => ({
            ...prevState,
            numberSearch: false,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          numberSearch: false,
        });

        if (val === "") {
          this.setState({
            numberSearch: true,
          });
        }
      });

    this.setState((prevState) => ({
      ...prevState,
      customerData: customerDetails,
      rowRefs: rows,

      data: {
        ...prevState.data,
        salesInvoiceNumber: e.target.value,
      },
    }));
  };

  updateInvoiceSelectData = () => {};

  componentDidMount() {

    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    var todayDate = dateFormat(date, "yyyy-mm-dd");

    let transactionDate = (todayDate.split("-"));
    
    let tdf = (
      transactionDate[0] +
        "/" +
        transactionDate[1] +
        "/" +
        transactionDate[2]
    );
    let df= adbs.ad2bs(tdf)

    let finalYear = df.en.year;
    let finalMonth = df.en.month;
    let finalDay = df.en.day;
    if(df.en.month < 10){
      finalMonth = "0" + df.en.month;
    }    
    if(df.en.day < 10){
      finalDay = "0" + df.en.day;
    }

    let finalDate = finalYear + "-" + finalMonth + "-" + finalDay;

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        date: date,
        nepaliDate: finalDate,
      },
    }));

    this.getAllPeriodListing();
    Axios.get("/pos/salesReturn/getCreditNoteInfo")
      .then((res) => {
        console.log(res);
        this.setState((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,

            /* date: res.data.date === null ? "" : res.data.date, */
            preparedBy: res.data.preparedBy === null ? "" : res.data.preparedBy,
            creditMode: "Cash",
          },
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getAllPeriodListing = () => {
    Axios.get("/fy/getAllActivePeriods")
      .then(async (res) => {
        var fiscalYearLists = [];
        res.data.forEach((element) => {
          var fy = Object.keys(element);

          fiscalYearLists.push(fy[0]);
        });
        await this.setState({
          fyList: fiscalYearLists,
          periodResData: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    this.getCurrentPeriod();
  };

  getCurrentPeriod() {
    Axios.get("/fy/getCurrentPeriod")
      .then(async (res) => {
        await this.setState((prevState) => ({
          ...prevState,
          currentPeriod: res.data.name,
          data: {
            ...prevState.data,
            fiscalYear: res.data.fiscalYear,
          },
        }));
        this.getPeriodList();
        this.getVoucherNumbering(res.data.fiscalYear);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleFYChange = async(e) => {
    let Fy = e.target.value;
    await this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        fiscalYear: Fy,
      },
    }));
    this.getPeriodList();
    this.getVoucherNumbering(Fy);
  };

  getPeriodList() {
    var pLists = [];

    // this.state.resData.map(val => Object.keys(val) === this.state.fiscalYear ? pLists = val : null);
    this.state.periodResData.map((val) =>
      Object.keys(val).includes(this.state.data.fiscalYear)
        ? (pLists = val[this.state.data.fiscalYear])
        : null
    );

    this.setState((prevState) => ({
      ...prevState,
      periodList: pLists,
      currentPeriod: pLists[pLists.length-1],
    }));
    this.getPeriodDateFromTo(this.state.data.fiscalYear,pLists[pLists.length-1]);
  }

  handlePeriodChange = async (e) => {
    let Py = e.target.value;

    await this.setState((prevState) => ({
      ...prevState,
        currentPeriod: Py,
    }));
    this.getPeriodDateFromTo(this.state.data.fiscalYear, Py);
  };

  getPeriodDateFromTo = (fiscalYear, period) => {
    Axios.post("fy/findByNameAndFiscalYear", { period, fiscalYear }).then(
      (res) =>
        this.setState((prevState)=>({
          ...prevState,
          periodDateFrom: res.data.dateFrom,
          periodDateTo: res.data.dateTo,
        }))
    );
  };

  onChangedDate = (name, dateInMilli, bsDate, adDate) => {

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        date: adDate,
        nepaliDate: bsDate,
      },
    }));
    console.log(adDate);
  };

  handleChange = (e) => {
    let val = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      voucherNumber: val,
      data: {
        ...prevState.data,
        salesReturnNumber: val,
      },
    }));
  };
  handleValueChange = (e) => {
    var targetName = e.target.name;
    var val = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [targetName]: val,
      },
    }));
  };

  handleTextChange = (e) => {
    e.stopPropagation();

    var targetName = e.target.name;
    var value = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [targetName]: value,
      },
    }));
  };

  handleConfirm = () => {
    this.setState({
      confirmOpen: true,
    })
  } 

  handleSubmit = () => {
    console.log(this.state.data.fiscalYear);

    const token = this.context;

    if (
      this.state.data.comment === "" ||
      this.state.data.comment === undefined
    ) {
      this.setState({ emptyComment: true });
      return;
    }

    if (this.state.tableData === false) {
      var errorSnackbarProps = {
        open: true,
        variant: "error",
        message: "No Item Selected",
        autoHideDuration: 2000,
      };
      token.setSnackbarProps({ snackbarProps: errorSnackbarProps });
      return;
    }

    if (this.state.table === "" || this.state.table === undefined) {
      var errorSnackbarPropsNew = {
        open: true,
        variant: "error",
        message: "No item selected",
        autoHideDuration: 2000,
      };
      token.setSnackbarProps({ snackbarProps: errorSnackbarPropsNew });
      return;
    }
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        salesReturnDetails: this.state.rowData,
        subTotal: this.state.data.subTotal,
        total: this.state.data.total,
        totalDiscount: this.state.data.totalDiscount,
        totalTax: this.state.data.totalTax,
        //fiscalYear: this.state.data.fiscalYear,
        period: this.state.currentPeriod,
        date: this.state.data.date,
        nepaliDate: this.state.data.nepaliDate,
        salesInvoiceNumber: this.state.data.salesInvoiceNumber,
        salesReturnNumber: this.state.data.salesReturnNumber,
      },
    }));
    Axios.post("pos/salesReturn/saveSalesReturn", this.state.data)
      .then((res) => {
        console.log(res);

        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000,
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });
        this.getVoucherNumbering(this.state.data.fiscalYear);
        this.setState((prevState)=>({
          ...prevState,
          rowData: [],
          rowRefs: [],
          quantity: 1,
          emptyComment: false,
          data: {
            ...prevState.data,
            date: "",
            salesInvoiceNumber: "",
            comment: "",
            customerName: "",
            address: "",
            pan: "",
            creditMode: "Cash",
            subTotal: 0,
            taxes: 0,
            discount: 0,
            total: 0,
          },
        }));

        Axios.get("/pos/salesReturn/getCreditNoteInfo").then((res) => {
          console.log(res);
          this.setState((prevState) => ({
            ...prevState,
            data: {
              ...prevState.data,
              creditNoteNumber:
                res.data.creditNoteNumber === null
                  ? ""
                  : res.data.creditNoteNumber,
              date: res.data.date === null ? "" : res.data.date,
              preparedBy:
                res.data.preparedBy === null ? "" : res.data.preparedBy,
            },
          }));
        });
      })
      .catch((err) => {
        console.log(err);
        var message = err.message;
        if (err.response !== undefined) {
          message = err.response.data;
        }
        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000,
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });
      });
      this.setState({confirmOpen:false})
  };

  getVoucherNumbering(fy) {
    Axios.post("/pos/voucherNumbering/getVoucherNumbering", {fiscalYear: fy, name: "Sales Return"})
    .then(async(res) => {
      if (res.data === "Not Found") {
        await this.setState({ voucherNumberError: true });
       } else {
       await this.setState((prevState) => ({
           ...prevState,
           salesReturnNoList: res.data,
           data: {
             ...prevState.data,
             salesReturnNumber: res.data[0],
           },
         }));
       }
    })
      .catch((err) => {
        console.log(err);
      });
  }

  handle0Index = (rowAllData, rowsToSave, itemData) => {
    console.log(itemData);
    var rowItemData = {
      sn: this.state.sn,
      itemCode: itemData.itemCode,
      itemName: itemData.itemName,
      quantity: this.state.quantity,
      taxes: itemData.taxable,
      price: itemData.price,
      discount: 0,
      lineTotal: itemData.price * this.state.quantity - 0,
    };

    this.setState((prevState) => ({
      ...prevState,
      table: rowItemData,
    }));

    var toSave = true;

    rowAllData.forEach((element) => {
      if (element.itemName === itemData.itemName) {
        toSave = false;
      }
    });

    if (toSave) {
      rowAllData.push(rowItemData);
      rowAllData.forEach((element) => {
        var itemToAddDiff = {
          discount: element.discount,
          itemCode: element.itemCode,
          itemName: element.itemName,
          lineTotal: element.lineTotal,
          price: element.price,
          quantity: element.quantity,
        };

        rowsToSave.push(itemToAddDiff);
      });
    }
  };

  getAllRows = () => {
    var rowData = [];
    this.gridApi.forEachNode((node) => rowData.push(node.data));
    return rowData;
  };

  handleItemAdd = () => {
    var rows = this.getAllRows();

    var itemData = this.state.itemDetails;

    if (itemData.itemName === undefined || this.state.quantity === 0) {
      return;
    }

    var rowAllData = this.state.rowData;

    var total = this.state.data.total + itemData.price * this.state.quantity;

    this.setState((prevState)=>({
        ...prevState,
        data: {
            ...prevState.data,
      subTotal: this.state.data.subTotal + itemData.price * this.state.quantity,
      totalDiscount: this.state.data.totalDiscount + itemData.discount,
      total: total,
      quantityMax: this.state.quantityMax - this.state.quantity,
        },
    }));

    var rowsToSave = [];

    if (rows.length === 0) {
      this.handle0Index(rowAllData, rowsToSave, itemData);
    } else {
      for (let i = 0; i < rows.length; i++) {
        const element = rowAllData[i];
        if (element.itemName === itemData.itemName) {
          var newQuantity = element.quantity + this.state.quantity;
          var newDiscount = element.discount * newQuantity;
          var rowSpecificData = {
            ...element,
            quantity: newQuantity,
            discount: newDiscount,
            lineTotal: itemData.price * newQuantity - newDiscount,
          };
          rowAllData[i] = rowSpecificData;

          var itemToAdd = {
            discount: rowSpecificData.discount,
            itemCode: rowSpecificData.itemCode,
            itemName: rowSpecificData.itemName,
            lineTotal: rowSpecificData.lineTotal,
            price: rowSpecificData.sellingPrice,
            quantity: rowSpecificData.quantity,
          };
          rowsToSave.push(itemToAdd);
        } else {
          this.handle0Index(rowAllData, rowsToSave, itemData);
        }
      }
    }

    this.setState({ sn: this.state.sn + 1 });

    this.setState({ rowData: rowAllData });

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        salesReturnDetails: rowAllData,
      },
    }));

    console.log("new salesReturndata");
    console.log(this.state.salesReturnDetails);

    this.getAllRows(rowAllData);

    this.gridApi.setRowData(rowAllData);
    this.setState({ quantity: 1 });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  onRemoveSelected = () => {
    const selectedData = this.gridApi.getSelectedRows();
    this.gridApi.updateRowData({ remove: selectedData });
  };

  handleAddCustomer = () => {
    this.props.history.push(`/customer/master`);
  };

  handleQuantityChange = (evt) => {
    var val = evt.target.value;

    if (val < 0) {
      val = 0;
    }
    if (val > this.state.quantityMax) {
      this.setState({ quantity: this.state.quantityMax });
    } else {
      this.setState({ quantity: Math.round(val * 100) / 100 });
    }
  };

  deleteRow = (data) => {
    console.log(data);
    var rowAllData = this.state.rowData;

    var length = rowAllData.length;

    var finalRows = [];

    finalRows = rowAllData;
    console.log(finalRows);

    for (var i = 0; i < length; i++) {
      console.log("pos: " + i);
      var obj = rowAllData[i];
      if (obj.sn === data.sn) {
        console.log(obj);
        finalRows.splice(i, 1);

        var subTotalNow = this.state.data.subTotal;
        //var taxesNow = this.state.data.totalTax;
        var discountNow = this.state.data.totalDiscount;
        var totalNow = this.state.data.total;

        var subTotalNew = subTotalNow - obj.price * obj.quantity;

        //var taxesNew = obj.taxes;
        var discountNew = discountNow - 0;
        var totalNew = totalNow - obj.price * obj.quantity;

        this.setState((prevState)=>({
            ...prevState,
            data: {
                ...prevState.data,
          subTotal: subTotalNew,
          //totalTax: taxesNew,
          totalDiscount: discountNew,
          total: totalNew,
            },
        }));

        break;
      }
    }
  };

  handleItemSelect = (event, data) => {
    const { value } = data;
    const { key } = data.options.find((o) => o.value === value);

    this.state.resData.forEach((element) => {
      if (element.itemCode === key) {
        this.setState({
          quantityMax: element.quantity,
          itemDetails: element,
        });
      }
    });
  };

  handleRadioChange = (evt) => {
    this.setState({ value: evt.target.value });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Grid container spacing={2} className={classes.containerWidth}>
          <Grid item xs={12}>
            {this.state.numberSearch ? (
              <TextField
                id="outlined-dense"
                label="Search By Invoice Number"
                margin="dense"
                style={{ borderColor: "green" }}
                onChange={this.handleInvoiceChange}
                value={this.state.data.salesInvoiceNumber}
                variant="outlined"
                fullWidth
              />
            ) : (
              <TextField
                id="outlined-dense"
                label="Search By Invoice Number"
                margin="dense"
                error
                helperText="Invoice No. not found"
                onChange={this.handleInvoiceChange}
                value={this.state.data.salesInvoiceNumber}
                variant="outlined"
                fullWidth
              />
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              variant="outlined"
              select
              margin="dense"
              fullWidth
              label="Fiscal Year"
              name="fiscalYear"
              onChange={this.handleFYChange}
              value={this.state.data.fiscalYear}
            >
              {this.state.fyList.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="outlined-dense"
              label="Sales Return No."
              name="creditNumber"
              fullWidth
              value={this.state.data.salesReturnNumber}
              //onChange={this.handleChange}
              margin="dense"
              variant="outlined"
            >
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              variant="outlined"
              select
              margin="dense"
              fullWidth
              label="Period"
              name="period"
              onChange={this.handlePeriodChange}
              value={this.state.currentPeriod}
            >
              {this.state.periodList.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={3} style={{marginTop: "8px"}}>

          <NepaliDatepicker
              id="nepali-date"
              onChange={this.onChangedDate}
              label="Date"
              value={this.state.data.date}
              locale= "en"
              showDefaultDate="true"
              style={{height: "5px", width: "5px"}}
            />
               {/*  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                  label="Date"
                  value={this.state.data.date}
                    inputVariant="outlined"
                    autoOk={true}
                    format="yyyy-MM-dd"
                    margin="dense"
                    fullWidth
                    minDate={this.state.periodDateFrom}
                    maxDate={this.state.periodDateTo}
                    onChange={this.onChangedDate}
                    shouldDisableDate={this.shouldDisableDate}
                    animateYearScrolling
                  />
                </MuiPickersUtilsProvider> */}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="outlined-dense"
              label="Customer PAN"
              name="pan"
              onChange={this.handleValueChange}
              value={this.state.data.pan}
              fullWidth
              margin="dense"
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="outlined-dense"
              label="Customer Name"
              onChange={this.handleValueChange}
              value={this.state.data.customerName}
              fullWidth
              name="customerName"
              margin="dense"
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="outlined-dense"
              label="Address"
              onChange={this.handleValueChange}
              value={this.state.data.address}
              name="address"
              fullWidth
              margin="dense"
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="outlined-dense"
              select
              label="Credit Mode"
              name="creditMode"
              value={this.state.data.creditMode}
              onChange={this.handleTextChange}
              margin="dense"
              variant="outlined"
              fullWidth
            >
              <MenuItem key={1} value={"Cash"}>
                Cash
              </MenuItem>
              <MenuItem key={2} value={"Cheque"}>
                Cheque
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          spacing={2}
          style={{ marginTop: "15px" }}
        ></Grid>

        <Divider style={{ marginTop: "15px", marginBottom: "5px" }} />
        <Grid
          container
          justify="center"
          spacing={2}
          className={classes.bottomContainerWidth}
        >
          <Grid item xs={12} sm={12} md={7}>
            <Dropdown
              placeholder="Search by Item Code or Item Name"
              fluid
              style={{ marginTop: "15px" }}
              fullWidth
              search
              ref={(search) => {
                this.searchInput = search;
              }}
              selection
              onChange={this.handleItemSelect}
              options={this.state.rowRefs}
            ></Dropdown>
          </Grid>

          <Grid item xs={7} sm={8} md={3}>
            <Input
              placeholder="Quantity"
              style={{ width: "100%", marginTop: "15px" }}
              type="number"
              onChange={this.handleQuantityChange}
              value={this.state.quantity}
              max={this.state.quantityMax}
            ></Input>
          </Grid>
          <Grid item xs={5} sm={4} md={2}>
            <Button
              variant="outlined"
              fullWidth
              color="primary"
              onClick={this.handleItemAdd}
              style={{
                minWidth: "60px",
                margin: "auto",
                height: "38px",
                marginTop: "15px",
              }}
            >
              +Add
            </Button>
          </Grid>

          <div
            className="ag-theme-balham"
            style={{
              height: "240px",
              width: "100%",
              margin: "10px",
            }}
          >
            <AgGridReact
              columnDefs={this.state.columnDefs}
              rowData={this.state.rowData}
              animateRows={true}
              rowSelection={this.state.rowSelection}
              onGridReady={this.onGridReady}
            ></AgGridReact>
          </div>
          <div></div>
        </Grid>

        <Grid
          container
          justify="center"
          spacing={4}
          style={{ margin: "auto", width: "90%" }}
        >
          <Grid item xs={12} sm={6} md={3}>
            <Grid container justify="center">
              <p>
                Sub Total: <span style={{ display: "inline-block" }}></span>
                <strong>{this.state.data.subTotal}</strong>
              </p>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid container justify="center">
              <p>
                Taxes: <span style={{ display: "inline-block" }}></span>
                <strong>{this.state.data.totalTax}</strong>
              </p>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid container justify="center">
              <p>
                Discount: <span style={{ display: "inline-block" }}></span>
                <strong>{this.state.data.totalDiscount}</strong>
              </p>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid container justify="center">
              <p>
                Total: <span style={{ display: "inline-block" }}></span>
                <strong>{this.state.data.total}</strong>
              </p>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          spacing={2}
          className={classes.bottomContainerWidth}
        >
          <Grid item xs={12}>
            {this.state.emptyComment ? (
              <TextField
                id="outlined-dense"
                fullWidth
                error
                helperText="Comment should not be empty"
                label="Comment"
                value={this.state.data.comment}
                onChange={this.handleTextChange}
                name="comment"
                margin="normal"
                variant="outlined"
              />
            ) : (
              <TextField
                id="outlined-dense"
                fullWidth
                label="Comment"
                value={this.state.data.comment}
                onChange={this.handleTextChange}
                name="comment"
                margin="normal"
                variant="outlined"
              />
            )}
          </Grid>
        </Grid>

        <Grid container justify="center" className={classes.bottonTopMargin}>
          <Button
            onClick={this.handleConfirm}
            variant="outlined"
            color="primary"
            style={{
              minWidth: "100px",
              width: "20vw",
              margin: "auto",
              height: "50px",
            }}
          >
            Save
          </Button>
        </Grid>
        <ConfirmationDialog
          open={this.state.confirmOpen}
          handleClose={() => {
            this.setState({ confirmOpen: false });
          }}
          handleConfirm={this.handleSubmit}
          titleMessage={"Return Sales?"}
          bodyMessage={
            "Are you sure to return this Sales?"
          }
        />
        <VoucherNumberRestriction
          open={this.state.voucherNumberError}
        ></VoucherNumberRestriction>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(SalesReturnForm));
