import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  TextField,
  withStyles,
  MenuItem,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  RadioGroup,
  Radio,
  FormControlLabel,
  CircularProgress,
} from "@material-ui/core";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dateFormat from "dateformat";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import adbs from "ad-bs-converter";

const styles = (theme) => ({
  tabMargin: {
    height: "auto",
    padding: 15,
    [theme.breakpoints.down("md")]: {
      marginTop: "98px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "65px",
    },
  },
  root: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "150px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
    },
  },
});

class StockTransactionDetails extends Component {
  constructor(props) {
    super(props);
    const rowIndex = (params) => params.node.rowIndex + 1;
    this.state = {
      loading: false,
      showDetails: false,
      filterShow: false,
      columnDefs: [
        {
          headerName: "S.No",
          field: "sn",
          sortable: true,
          resizable: true,
          valueGetter: rowIndex,
          minWidth: 50,
        },
        {
          headerName: " Date",
          field: "transactionDate",
          resizable: true,
          sortable: true,
          minWidth: 120,
        },
        {
          headerName: " Miti",
          field: "transactionMiti",
          resizable: true,
          sortable: true,
          minWidth: 120,
        },
        {
          headerName: "Item Code",
          field: "itemCode",
          resizable: true,
          sortable: true,
          minWidth: 80,
        },

        {
          headerName: "Item Name",
          field: "itemName",
          resizable: true,
          minWidth: 100,
        },
        {
          headerName: "Fiscal Year ",
          field: "fiscalYear",
          resizable: true,
          sortable: true,
          minWidth: 110,
        },
        {
          headerName: "Period",
          field: "period",
          resizable: true,
          sortable: true,
          minWidth: 110,
        },
        {
          headerName: "Category",
          field: "category",
          resizable: true,
          sortable: true,
          minWidth: 110,
        },
        {
          headerName: "Transaction Type",
          field: "transactionType",
          resizable: true,
          sortable: true,
          minWidth: 110,
        },
        {
          headerName: "Transaction Number",
          field: "transactionNumber",
          resizable: true,
          sortable: true,
          minWidth: 110,
        },
        {
          headerName: "In Quantity",
          field: "inQuantity",
          resizable: true,
          sortable: true,
          minWidth: 80,
        },
        {
          headerName: "Out Quantity",
          field: "outQuantity",
          resizable: true,
          sortable: true,
          minWidth: 80,
        },
        {
          headerName: "Amount",
          field: "amount",
          resizable: true,
          sortable: true,
          minWidth: 80,
        },

        {
          headerName: "Running Balance",
          field: "runningBalance",
          resizable: true,
          sortable: true,
          minWidth: 80,
        },
      ],
      rowData: [],
      origRowData: [],
      periodList: [],
      resData: [],
      currentPeriod: "",
      fyList: [],
      fiscalYear: "",
      itemCount: 0,
      filterTerm: "",
      panelExpanded: false,
      filterFields: {
        dateFrom: "",
        dateTo: "",
        periodFrom: "",
        periodTo: "",
      },
    };
  }

  handleFilterChange = (e) => {
    let targetName = e.target.name;
    let targetValue = e.target.value;

    let newData = [];

    this.state.origRowData.map((val) =>
      val[targetName] === targetValue ? newData.push(val) : null
    );
    this.setState((prevState) => ({
      ...prevState,
      rowData: newData,
    }));
  };

  // handlePeriodChange = async (e) => {
  //   let period = e.target.value;

  //   await this.setState((prevState) => ({
  //     ...prevState,
  //     currentPeriod: period,
  //   }));

  // };

  handleFiscalYearChange = async (e) => {
    let fiscalYear = e.target.value;

    await this.setState((prevState) => ({
      ...prevState,
      fiscalYear: fiscalYear,
    }));
  };

  getAllPeriodListing() {
    Axios.get("/fy/findTillCurrentPeriod")
      .then((res) => {
        var data = [];
        var fyList = [];
        res.data.forEach((element) => {
          data.push(element.name);
          fyList.push(element.fiscalYear);
        });
        this.setState({
          periodList: [...new Set(data)],
          fyList: [...new Set(fyList)],
          resData: res.data,
        });
        this.getCurrentPeriod();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getCurrentPeriod() {
    this.state.resData.forEach((element) => {
      if (element.currentStatus === true) {
        this.setState((prevState) => ({
          ...prevState,
          currentPeriod: element.name,
          fiscalYear: element.fiscalYear,
        }));
      }
    });
  }

  getAllCategories() {
    Axios.get("pos/category/getAllCategories")
      .then((res) => {
        var listData = [];
        listData.push({ key: 0, value: "None", text: "None" });

        res.data.forEach((element) => {
          var item = {
            key: element.id,
            value: element.categoryName,
            text: element.categoryName,
          };

          listData.push(item);
        });

        this.setState({ categoriesList: listData });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getAllWarehouses() {
    Axios.get("pos/warehouse/getAllWarehouses")
      .then((res) => {
        var listData = [];
        listData.push({ key: 0, value: "None", text: "None" });

        res.data.forEach((element) => {
          var item = {
            key: element.id,
            value: element.name,
            text: element.name,
          };

          listData.push(item);
        });

        this.setState({ warehouseList: listData });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    this.getAllPeriodListing();
    this.getAllCategories();
    this.getAllWarehouses();
  }

  onFilterTermChange = (e) => {
    let value = e.target.value;

    this.setState((prevState) => ({
      ...prevState,
      filterTerm: value,
    }));
  };

  handlePeriodFromChange = async (e) => {
    e.stopPropagation();
    var period = e.target.value;
    await this.setState((prevState) => ({
      ...prevState,
      filterFields: {
        ...prevState.filterFields,
        periodFrom: period,
      },
    }));
  };

  handlePeriodToChange = async (e) => {
    e.stopPropagation();
    var period = e.target.value;
    await this.setState((prevState) => ({
      ...prevState,
      filterFields: {
        ...prevState.filterFields,
        periodTo: period,
      },
    }));
  };

  handleFiscalYearApply = () => {
    this.setState({
      panelExpanded: false,
    });

    this.getInventoryListing({
      fiscalYear: this.state.fiscalYear,
      periodFrom: this.state.filterFields.periodFrom,
      periodTo: this.state.filterFields.periodTo,
    });
  };

  handleFromDate = (event) => {
    var date = event.target.value;
    let formattedDateFrom = dateFormat(date, "yyyy-mm-dd");
    this.setState((prevState) => ({
      ...prevState,
      filterFields: {
        ...prevState.filterFields,
        dateFrom: formattedDateFrom,
      },
    }));
  };

  handleToDate = (event) => {
    var date = event.target.value;
    let formattedDateTo = dateFormat(date, "yyyy-mm-dd");
    this.setState((prevState) => ({
      ...prevState,
      filterFields: {
        ...prevState.filterFields,
        dateTo: formattedDateTo,
      },
    }));
  };

  handleDateRangeApply = () => {
    this.setState({
      panelExpanded: false,
    });

    this.getInventoryListing({
      dateFrom: this.state.filterFields.dateFrom,
      dateTo: this.state.filterFields.dateTo,
    });
  };

  handlePanelChange = (_e, isExpanded) => {
    this.setState({
      panelExpanded: isExpanded,
    });
  };

  getInventoryListing = async (obj) => {
    this.setState({ loading: true, showDetails: true });
    Axios.post("/pos/stock/getStockTransaction", obj)
      .then((res) => {
        this.setState({ loading: false });
        var rowData = [];
        res.data.details.forEach((element) => {
          let transactionDate = element.transactionDate.split("-");

          let miti = adbs.ad2bs(
            transactionDate[0] +
              "/" +
              transactionDate[1] +
              "/" +
              transactionDate[2]
          );

          let transactionMiti =
            miti.en.year + "-" + miti.en.month + "-" + miti.en.day;

          var obj = {
            itemCode: element.itemCode,
            transactionDate: element.transactionDate,
            transactionMiti: transactionMiti,
            transactionType: element.transactionType,
            fiscalYear: element.fiscalYear,
            period: element.period,
            outQuantity: element.outQuantity,
            inQuantity: element.inQuantity,
            transactionNumber: element.transactionNumber,
            category: element.category,
            itemName: element.itemName,
            amount: element.amount,
            purchaseAmount: element.purchaseAmount,
            salesAmount: element.salesAmount,
            runningBalance: element.runningBalance,
          };
          rowData.push(obj);
        });

        this.setState({
          rowData: rowData,
          origRowData: rowData,
        });

        //---------EXCE DATA ---------
        let excel = [];
        if (this.state.filterTerm === "fiscalYear") {
          let object = {
            sn: "",
            transactionDate: "Fiscal Year",
            itemCode: this.state.fiscalYear,
            itemName: "",
            fiscalYear: "",
            period: "",
            category: "",
            transactionType: "",
            transactionNumber: "",
            purchaseCount: "",
            salesCount: "",
            purchaseAmount: "",
            salesAmount: "",
            runningBalance: "",
          };
          excel.push(object);

          object = {
            sn: "",
            transactionDate: "Period From",
            itemCode: this.state.filterFields.periodFrom,
            itemName: "",
            fiscalYear: "",
            period: "",
            category: "",
            transactionType: "",
            transactionNumber: "",
            purchaseCount: "",
            salesCount: "",
            purchaseAmount: "",
            salesAmount: "",
            runningBalance: "",
          };
          excel.push(object);

          object = {
            sn: "",
            transactionDate: "Period To",
            itemCode: this.state.filterFields.periodTo,
            itemName: "",
            fiscalYear: "",
            period: "",
            category: "",
            transactionType: "",
            transactionNumber: "",
            purchaseCount: "",
            salesCount: "",
            purchaseAmount: "",
            salesAmount: "",
            runningBalance: "",
          };
          excel.push(object);

          object = {
            sn: "",
            transactionDate: "",
            itemCode: "",
            itemName: "",
            fiscalYear: "",
            period: "",
            category: "",
            transactionType: "",
            transactionNumber: "",
            purchaseCount: "",
            salesCount: "",
            purchaseAmount: "",
            salesAmount: "",
            runningBalance: "",
          };
          excel.push(object);
        } else if (this.state.filterTerm === "date") {
          let object = {
            sn: "",
            transactionDate: "Date Range",
            itemCode: "",
            itemName: "",
            fiscalYear: "",
            period: "",
            category: "",
            transactionType: "",
            transactionNumber: "",
            purchaseCount: "",
            salesCount: "",
            purchaseAmount: "",
            salesAmount: "",
            runningBalance: "",
          };
          excel.push(object);

          object = {
            sn: "",
            transactionDate: "Date From",
            itemCode: this.state.filterFields.dateFrom,
            itemName: "",
            fiscalYear: "",
            period: "",
            category: "",
            transactionType: "",
            transactionNumber: "",
            purchaseCount: "",
            salesCount: "",
            purchaseAmount: "",
            salesAmount: "",
            runningBalance: "",
          };
          excel.push(object);

          object = {
            sn: "",
            transactionDate: "Date To",
            itemCode: this.state.filterFields.dateTo,
            itemName: "",
            fiscalYear: "",
            period: "",
            category: "",
            transactionType: "",
            transactionNumber: "",
            purchaseCount: "",
            salesCount: "",
            purchaseAmount: "",
            salesAmount: "",
            runningBalance: "",
          };
          excel.push(object);

          object = {
            sn: "",
            transactionDate: "",
            itemCode: "",
            itemName: "",
            fiscalYear: "",
            period: "",
            category: "",
            transactionType: "",
            transactionNumber: "",
            purchaseCount: "",
            salesCount: "",
            purchaseAmount: "",
            salesAmount: "",
            runningBalance: "",
          };
          excel.push(object);
        } else {
          return null;
        }

        let object = {
          sn: "S.No",
          transactionDate: "Date",
          itemCode: "Item Code",
          itemName: "Item Name",
          fiscalYear: "Fiscal Year",
          period: "Period",
          category: "Category",
          transactionType: "Transaction Type",
          transactionNumber: "Transaction Number",
          purchaseCount: "Purchase Count",
          salesCount: "Sales Count",
          purchaseAmount: "Purchase Amount",
          salesAmount: "Sales Amount",
          runningBalance: "Running Balance",
        };
        excel.push(object);

        rowData.map((item, i) => {
          let object = {
            sn: i + 1,
            transactionDate: item.transactionDate,
            itemCode: item.itemCode,
            itemName: item.itemName,
            fiscalYear: item.fiscalYear,
            period: item.period,
            category: item.category,
            transactionType: item.transactionType,
            transactionNumber: item.transactionNumber,
            purchaseCount: item.purchaseCount,
            salesCount: item.salesCount,
            purchaseAmount: item.purchaseAmount,
            salesAmount: item.salesAmount,
            runningBalance: item.runningBalance,
          };
          excel.push(object);
          return null;
        });

        this.setState({
          excelData: excel,
        });
        //--------EXCEL DATA ENDS--------

        this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>",
        });
        try {
          this.gridApi.hideOverlay();
          if (res.data.length === 0) this.gridApi.showNoRowsOverlay();
        } catch (error) {
          console.log(error);
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
        /* this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>",
        }); */
        try {
          this.gridApi.hideOverlay();
          this.gridApi.showNoRowsOverlay();
        } catch (error) {
          console.log(error);
        }
      });
  };

  componentWillMount() {
    window.removeEventListener("resize", this.daListener);
  }

  firstDataRendered = (params) => {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    window.addEventListener("resize", this.daListener);
  };

  daListener = () => {
    if (!this.gridApi) return;
    setTimeout(() => {
      this.gridApi.sizeColumnsToFit();
    }, 100);
  };

  setOpen = (status) => {
    this.setState({ open: status });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  render(params) {
    const { classes } = this.props;

    return (
      <div
        style={{
          backgroundColor: "#EAEDEF",
        }}
      >
        <Grid container justify="center" className={classes.root}>
          <Grid item xs={11} sm={11} md={9}>
            <Card className={classes.tabMargin}>
              <CardContent>
                <Grid
                  container
                  style={{
                    paddingRight: "15px",
                    paddingLeft: "15px",
                  }}
                >
                  <Grid container style={{ marginTop: "10px" }}>
                    <Grid item xs={24} sm={6}>
                      <ExpansionPanel
                        expanded={this.state.panelExpanded}
                        onChange={this.handlePanelChange}
                        style={{ maxWidth: "550px", marginBottom: "20px" }}
                      >
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.heading}>
                            Report By
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <Grid container>
                            <RadioGroup
                              row
                              value={this.state.filterTerm}
                              onChange={this.onFilterTermChange}
                            >
                              <FormControlLabel
                                value="fiscalYear"
                                control={<Radio color="primary" />}
                                label="Fiscal Year and Period"
                              />
                              <FormControlLabel
                                value="date"
                                control={<Radio color="primary" />}
                                label="Date Range"
                              />
                            </RadioGroup>

                            {this.state.filterTerm === "fiscalYear" ? (
                              <>
                                <Grid
                                  container
                                  justify="flex-start"
                                  spacing={2}
                                  style={{ marginTop: "20px" }}
                                >
                                  <Grid item>
                                    <TextField
                                      select
                                      style={{
                                        width: "15vw",
                                        minWidth: "120px",
                                      }}
                                      label="Fiscal Year"
                                      name="fiscalYear"
                                      onChange={this.handleFiscalYearChange}
                                      value={this.state.currentFy}
                                    >
                                      {this.state.fyList.map((item) => (
                                        <MenuItem value={item}>{item}</MenuItem>
                                      ))}
                                    </TextField>
                                  </Grid>
                                </Grid>

                                <Grid
                                  container
                                  justify="flex-start"
                                  spacing={2}
                                  style={{ marginTop: "20px" }}
                                >
                                  <Grid item>
                                    <TextField
                                      select
                                      style={{
                                        width: "15vw",
                                        minWidth: "120px",
                                      }}
                                      label="Period From"
                                      name="periodFrom"
                                      onChange={this.handlePeriodFromChange}
                                    >
                                      {this.state.periodList.map((item) => (
                                        <MenuItem value={item}>{item}</MenuItem>
                                      ))}
                                    </TextField>
                                  </Grid>
                                  <Grid item>
                                    <TextField
                                      select
                                      style={{
                                        width: "15vw",
                                        minWidth: "120px",
                                      }}
                                      label="Period To"
                                      name="periodTo"
                                      onChange={this.handlePeriodToChange}
                                    >
                                      {this.state.periodList.map((item) => (
                                        <MenuItem value={item}>{item}</MenuItem>
                                      ))}
                                    </TextField>
                                  </Grid>
                                </Grid>

                                <Grid container style={{ marginTop: "27px" }}>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={this.handleFiscalYearApply}
                                  >
                                    Apply
                                  </Button>
                                </Grid>
                              </>
                            ) : null}

                            {this.state.filterTerm === "date" ? (
                              <>
                                <Grid
                                  container
                                  justify="flex-start"
                                  spacing={2}
                                >
                                  <Grid item>
                                    <TextField
                                      placeholder="Date From"
                                      label="Date From"
                                      name="dateFrom"
                                      value={this.state.filterFields.dateFrom}
                                      style={{
                                        width: "15vw",
                                        minWidth: "130px",
                                        marginTop: "20px",
                                      }}
                                      type="date"
                                      onChange={this.handleFromDate}
                                    />
                                  </Grid>
                                  <Grid item>
                                    <TextField
                                      placeholder="Date To"
                                      label="Date To"
                                      name="dateTo"
                                      value={this.state.filterFields.dateTo}
                                      style={{
                                        width: "15vw",
                                        minWidth: "130px",
                                        marginTop: "20px",
                                      }}
                                      type="date"
                                      onChange={this.handleToDate}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid container style={{ marginTop: "27px" }}>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={this.handleDateRangeApply}
                                  >
                                    Apply
                                  </Button>
                                </Grid>
                              </>
                            ) : null}
                          </Grid>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </Grid>
                    <Grid item xs>
                      <Grid
                        container
                        spacing={2}
                        justify="flex-end"
                        style={{ marginBottom: "7px" }}
                      >
                        <Grid item>
                          <ExcelFile
                            filename="InventoryTransactionDetails.xlsx"
                            element={
                              <Button
                                variant="outlined"
                                color="primary"
                                style={{
                                  minWidth: "100px",
                                  width: "6vw",
                                  margin: "auto",
                                  marginTop: "10px",
                                  marginLeft: "20px",
                                  height: "40px",
                                  fontSize: "16px",
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faFileExcel}
                                  color="#3f51b5"
                                  size="30px"
                                  style={{
                                    marginRight: "10px",
                                  }}
                                ></FontAwesomeIcon>
                                Excel
                              </Button>
                            }
                          >
                            <ExcelSheet
                              data={this.state.excelData}
                              name="Sheet A"
                            >
                              <ExcelColumn value="sn" />
                              <ExcelColumn value="transactionDate" />
                              <ExcelColumn value="itemCode" />
                              <ExcelColumn value="itemName" />
                              <ExcelColumn value="fiscalYear" />
                              <ExcelColumn value="period" />
                              <ExcelColumn value="category" />
                              <ExcelColumn value="transactionType" />
                              <ExcelColumn value="transactionNumber" />
                              <ExcelColumn value="purchaseCount" />
                              <ExcelColumn value="salesCount" />
                              <ExcelColumn value="purchaseAmount" />
                              <ExcelColumn value="salesAmount" />
                              <ExcelColumn value="runningBalance" />
                            </ExcelSheet>
                          </ExcelFile>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {this.state.showDetails ? (
                  <>
                    {this.state.filterTerm === "fiscalYear" ? (
                      <Grid
                        container
                        style={{ margin: "20px", maxWidth: "700px" }}
                      >
                        <Grid item xs={4}>
                          <Grid container>
                            <Grid>
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 600,
                                  marginRight: "10px",
                                  color: "#108DCD",
                                }}
                              >
                                Fiscal Year:
                              </Typography>
                            </Grid>
                            <Grid>
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 400,
                                  color: "#108DCD",
                                }}
                              >
                                {this.state.fiscalYear}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={4}>
                          <Grid container>
                            <Grid>
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 600,
                                  marginRight: "10px",
                                  color: "#108DCD",
                                }}
                              >
                                Period From:
                              </Typography>
                            </Grid>
                            <Grid>
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 400,
                                  color: "#108DCD",
                                }}
                              >
                                {this.state.filterFields.periodFrom}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={4}>
                          <Grid container>
                            <Grid>
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 600,
                                  marginRight: "10px",
                                  color: "#108DCD",
                                }}
                              >
                                Period To:
                              </Typography>
                            </Grid>
                            <Grid>
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 400,
                                  color: "#108DCD",
                                }}
                              >
                                {this.state.filterFields.periodTo}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <>
                        <Grid
                          container
                          style={{
                            margin: "20px",
                            marginLeft: "0px",
                            maxWidth: "500px",
                          }}
                        >
                          <Grid item xs={6}>
                            <Grid container>
                              <Grid>
                                <Typography
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: 600,
                                    marginRight: "10px",
                                    color: "#108DCD",
                                  }}
                                >
                                  Date From:
                                </Typography>
                              </Grid>
                              <Grid>
                                <Typography
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: 400,
                                    color: "#108DCD",
                                  }}
                                >
                                  {this.state.filterFields.dateFrom}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid container>
                              <Grid>
                                <Typography
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: 600,
                                    color: "#108DCD",
                                    marginRight: "10px",
                                  }}
                                >
                                  Date To:
                                </Typography>
                              </Grid>
                              <Grid>
                                <Typography
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: 400,
                                    color: "#108DCD",
                                  }}
                                >
                                  {this.state.filterFields.dateTo}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </>
                ) : null}

                {this.state.loading ? (
                  <Grid
                    container
                    justify="center"
                    style={{ marginTop: "50px" }}
                  >
                    <Grid item>
                      <CircularProgress />
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <Grid container justify="center">
                      <div
                        className="ag-theme-balham"
                        style={{
                          height: "65vh",
                          width: "100%",
                          marginTop: "10px",
                        }}
                      >
                        <AgGridReact
                          columnDefs={this.state.columnDefs}
                          rowData={this.state.rowData}
                          onFirstDataRendered={this.firstDataRendered}
                          onGridReady={this.onGridReady}
                          overlayLoadingTemplate={
                            this.state.overlayLoadingTemplate
                          }
                          overlayNoRowsTemplate={
                            this.state.overlayNoRowsTemplate
                          }
                        ></AgGridReact>
                      </div>
                    </Grid>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(StockTransactionDetails);
