import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import SalesInvoice3mm from "../PrintableComponents/SalesInvoice3mm";
import SalesInvoice3mmVAT from "../PrintableComponents/SalesInvoice3mmVAT";
import UserContext from "../../utils/UserContext";
import SalesInvoice3mmAbb from "../PrintableComponents/SalesInvoice3mmAbb";
import ReactToPrint from "react-to-print";

class PrintManageInvoice extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      billNo: "",
      data: {
        billTo: "",
        saleDate: "",
        invoiceNumber: "",
        invoiceDate: "",
        address: "",
        pan: "",
        soldBy: "",
        chalanNumber: "",
        salesOrderNumber: "",
        ginNumber: "",
        paymentMode: "cash",
        subTotal: 0,
        totalDiscount: 0,
        totalAmount: 0,
        totalTax: 0,
        tenderAmount: 0,
        change: 0,
        fiscalYear: "",
        period: "",
        taxableAmount: 0.0,
        taxAmount: 0.0,
        salesReturnStatus: false,
        replacementStatus: false,
        syncWithIRD: true,
        printedTime: null,
        enteredBy: "",
        printedBy: null,
        billPrintCount: 0,
        salesDetail: [],
        createdAt: "",
        createdBy: "",
        customer: null,
        billActive: true,
        billPrinted: true,
        realTime: true,
      },
    };
  }

  componentDidMount() {
    console.log({ hereprops: this.props.node.data });
    this.setState({ billNo: this.props.node.data.billNo });

    Axios.post("pos/sales/findByInvoiceNumber", {
      invoiceNumber: this.props.node.data.billNo,
    })
      .then((res) => {
        this.setState({ data: res.data });
      })
      .catch((err) => console.log(err));
  }

  handlePrint = () => {
    this.ref.handlePrint();
  };

  afterPrint = () => {
    Axios.put("pos/sales/updateBillPrintCount", {
      invoiceNumber: this.state.data.invoiceNumber,
    })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  checkBillFormat = () => {
    const token = this.context;

    let format = token.invoicingMethod;

    if (format === "PAN") {
      return (
        <SalesInvoice3mm
          ref={(el) => (this.componentRef = el)}
          billAmount={this.state.data.totalAmount}
          tenderAmount={this.state.data.tenderAmount}
          paymentMethod={this.state.data.paymentMode}
          change={this.state.data.change}
          soldBy={this.state.data.soldBy}
          invoiceNumber={this.state.data.invoiceNumber}
          invoiceDate={this.state.data.invoiceDate}
          customerData={{
            fullName: this.state.data.billTo,
            address: this.state.data.address,
            mobileNo: "",
            pan: this.state.data.pan,
          }}
          salesDetails={this.state.data.salesDetail}
          totalDiscount={this.state.data.totalDiscount}
          totalTax={this.state.data.totalTax}
          display={true}
          billCount={this.state.data.billPrintCount}
        />
      );
    } else if (format === "VAT") {
      return (
        <SalesInvoice3mmVAT
          ref={(el) => (this.componentRef = el)}
          billAmount={this.state.data.totalAmount}
          tenderAmount={this.state.data.tenderAmount}
          paymentMethod={this.state.data.paymentMode}
          change={this.state.data.change}
          soldBy={this.state.data.soldBy}
          invoiceNumber={this.state.data.invoiceNumber}
          invoiceDate={this.state.data.invoiceDate}
          customerData={{
            fullName: this.state.data.billTo,
            address: this.state.data.address,
            mobileNo: "",
            pan: this.state.data.pan,
          }}
          salesDetails={this.state.data.salesDetail}
          totalDiscount={this.state.data.totalDiscount}
          totalTax={this.state.data.totalTax}
          display={true}
          billCount={this.state.data.billPrintCount}
        />
      );
    } else {
      return (
        <SalesInvoice3mmAbb
          ref={(el) => (this.componentRef = el)}
          grossTotal={this.state.data.totalAmount}
          billAmount={this.state.data.totalAmount}
          tenderAmount={this.state.data.tenderAmount}
          paymentMethod={this.state.data.paymentMode}
          change={this.state.data.change}
          soldBy={this.state.data.soldBy}
          invoiceNumber={this.state.data.invoiceNumber}
          invoiceDate={this.state.data.invoiceDate}
          customerData={{
            fullName: this.state.data.billTo,
            address: this.state.data.address,
            mobileNo: "",
            pan: this.state.data.pan,
          }}
          salesDetails={this.state.data.salesDetail}
          totalDiscount={this.state.data.totalDiscount}
          totalTax={this.state.data.totalTax}
          display={true}
          billCount={this.state.data.billPrintCount}
        />
      );
    }
  };

  render() {
    return (
      <div>
        {this.state.billNo !== "" && (
          <span>
            <FontAwesomeIcon
              icon={faPrint}
              color="#3f51b5"
              onClick={() => this.handlePrint()}
            ></FontAwesomeIcon>
          </span>
        )}

        <ReactToPrint
          ref={(ref) => (this.ref = ref)}
          trigger={() => <div>Print</div>}
          content={() => this.componentRef}
          onAfterPrint={() => this.afterPrint}
        />

        {this.checkBillFormat()}
      </div>
    );
  }
}

export default PrintManageInvoice;
