import React, { Component } from "react";
import {
  Grid,
  TextField,
  Divider,
  Button,
  withStyles,
  MenuItem,
} from "@material-ui/core";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import DeleteButton from "./InterfaceElements/DeleteButton";
import { Dropdown, Input } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import Axios from "axios";
import UserContext from "../utils/UserContext";
import { format } from "date-fns/esm";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import VoucherNumberRestriction from "../components/InterfaceElements/VoucherNumberRestriction";
import ConfirmationDialog from "./InterfaceElements/ConfirmationDialog";
import adbs, { bs2ad } from "ad-bs-converter";
import dateFormat from "dateformat";
import "nepali-datepicker-reactjs/dist/index.css";
import NepaliDatepicker from 'nepali-datepicker-and-dateinput';

const styles = (theme) => ({
  containerWidth: {
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "70%",
    },
  },
  bottomContainerWidth: {
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "90%",
    },
  },
  bottonTopMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
    },

    [theme.breakpoints.up("sm")]: {
      marginTop: "20px",
      marginBottom: "5px",
    },
  },
});

class PurchaseReturnForm extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      confirmOpen: false,
      periodList: [],
      fyList: [],
      currentPeriod: "",
      fiscalYear: "",
      periodDateFrom: "",
      periodDateTo: "",
      periodResData: [],
      rowRefs: [],
      columnDefs: [
        {
          headerName: "SN",
          field: "sn",
          sortable: true,
          resizable: true,
          width: 40,
        },
        {
          headerName: "Item Code",
          field: "itemCode",
          sortable: true,
          resizable: true,
          width: 70,
        },
        {
          headerName: "Item Name",
          field: "itemName",
          sortable: true,
          resizable: true,
        },
        {
          headerName: "Quantity",
          field: "quantity",
          sortable: true,
          resizable: true,
          editable: true,
          width: 70,
        },
        {
          headerName: "Price",
          field: "price",
          sortable: true,
          editable: true,
          width: 70,
        },
        {
          headerName: "Discount",
          field: "discount",
          width: 70,
        },
        {
          headerName: "Line Total",
          field: "lineTotal",
          sortable: true,
          width: 70,
        },
        {
          headerName: "Delete",
          cellRendererFramework: DeleteButton,
          cellRendererParams: {
            deleteRow: this.deleteRow,
          },
          width: 50,
        },
      ],
      tableData: false,
      rowData: [],
      rowSelection: "multiple",
      value: "cash",
      data: {
        address: "",
        comment: "",
        debitMode: "",
        debitNoteNumber: "",
        supplierName: "",
        date: "",
        nepaliDate: "",
        invoiceNumber: "",
        pan: "",
        purchaseReturnDetails: [
          {
            discount: "",
            id: "",
            itemCode: "",
            itemName: "",
            lineTotal: "",
            price: "",
            quantity: "",
          },
        ],
        supplier: {
          id: "",
        },

        subTotal: 0,
        total: 0,
        totalDiscount: 0,
        totalTax: 0,
      },
      preparedBy: "",
      quantity: 1,
      quantityMax: 0,
      subTotal: 0,
      taxes: 0,
      sn: 1,
      resData: [],
      discount: 0,
      total: 0,
      itemDetails: {},
      supplierData: {
        id: "",
        supplierName: "",
        pan: "",
        address: "",
      },
      purchaseReturnNoList: [],
      voucherNumberError: false,
      purchaseReturnNumber: "",
      emptyComment: false,
      invoiceNumberError: false,
      invoiceNumberErrorText: "",
      numberSearch: false,
      supplierNameList: [],
      emptySupplierName: false,
      emptyInvoiceNumber: false,
    };
  }

  componentDidMount() {

    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    var todayDate = dateFormat(date, "yyyy-mm-dd");

    let transactionDate = (todayDate.split("-"));
    
    let tdf = (
      transactionDate[0] +
        "/" +
        transactionDate[1] +
        "/" +
        transactionDate[2]
    );
    let df= adbs.ad2bs(tdf)

    let finalYear = df.en.year;
    let finalMonth = df.en.month;
    let finalDay = df.en.day;
    if(df.en.month < 10){
      finalMonth = "0" + df.en.month;
    }    
    if(df.en.day < 10){
      finalDay = "0" + df.en.day;
    }

    let finalDate = finalYear + "-" + finalMonth + "-" + finalDay;

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        date: date,
        nepaliDate: finalDate,
      },
    }));

    this.getAllPeriodListing();

    Axios.get("/pos/purchaseReturn/getDebitNoteInfo")
      .then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          preparedBy: res.data.preparedBy === null ? "" : res.data.preparedBy,
          //date: res.data.date === null ? "" : res.data.date,
          data: {
            ...prevState.data,
            debitNoteNumber:
              res.data.debitNoteNumber === null ? "" : res.data.debitNoteNumber,
          },
        }));
      })
      .catch((err) => {
        console.log(err);
      });

    this.getSupplierName();
  }

  getAllPeriodListing = () => {
    Axios.get("/fy/getAllActivePeriods")
      .then(async (res) => {
        var fiscalYearLists = [];
        res.data.forEach((element) => {
          var fy = Object.keys(element);

          fiscalYearLists.push(fy[0]);
        });
        await this.setState({
          fyList: fiscalYearLists,
          periodResData: res.data,
        });

        this.getCurrentPeriod();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCurrentPeriod() {
    Axios.get("/fy/getCurrentPeriod")
      .then(async (res) => {
        await this.setState((prevState) => ({
          ...prevState,
          fiscalYear: res.data.fiscalYear,
          currentPeriod: res.data.name,
        }));
        this.getPeriodList();
        this.getVoucherNumbering(res.data.fiscalYear);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getSupplierName = async () => {
    // var obj = [];
    Axios.get("pos/supplier/getAllSuppliers").then(async (res) => {
      // obj.push(element.name);
      await this.setState({ supplierNameList: res.data });
    });
  };

  handleFYChange = async (e) => {
    let Fy = e.target.value;
    await this.setState((prevState) => ({
      ...prevState,
      fiscalYear: Fy,
    }));
    this.getPeriodList();
    this.getVoucherNumbering(Fy);
  };

  getPeriodList() {
    var pLists = [];

    // this.state.resData.map(val => Object.keys(val) === this.state.fiscalYear ? pLists = val : null);

    this.state.periodResData.map((val) =>
      Object.keys(val).includes(this.state.fiscalYear)
        ? (pLists = val[this.state.fiscalYear])
        : null
    );

    this.setState((prevState) => ({
      ...prevState,
      periodList: pLists,
      currentPeriod: pLists[pLists.length-1],
    }));
    this.getPeriodDateFromTo(this.state.fiscalYear,pLists[pLists.length-1]);
  }

  handlePeriodChange = async (e) => {
    let Py = e.target.value;

    await this.setState((prevState) => ({
      ...prevState,
      currentPeriod: Py,
    }));
    this.getPeriodDateFromTo(this.state.fiscalYear, Py);
  };

  getPeriodDateFromTo = (fiscalYear, period) => {
    Axios.post("fy/findByNameAndFiscalYear", { period, fiscalYear }).then(
      (res) =>
        this.setState((prevState)=>({
          ...prevState,
          periodDateFrom: res.data.dateFrom,
          periodDateTo: res.data.dateTo,
        }))
    );
  };

  onChangedDate = (name, dateInMilli, bsDate, adDate) => {

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        date: adDate,
        nepaliDate: bsDate,
      },
    }));
    console.log(adDate);
  };

  getVoucherNumbering(fy) {
    Axios.post("/pos/voucherNumbering/getVoucherNumbering", {fiscalYear: fy, name: "Purchase Return"})
    .then(async(res) => {
      if (res.data === "Not Found") {
        await this.setState({ voucherNumberError: true });
       } else {
       await this.setState((prevState) => ({
           ...prevState,
           purchaseReturnNoList: res.data,
             purchaseReturnNumber: res.data[0],
         }));
       }
    })
      .catch((err) => {
        console.log(err);
      });
      console.log(this.state.purchaseReturnNumber);
  }

  handleChange = (e) => {
    let val = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      purchaseReturnNumber: val,
    }));
  };

  handleQuantityChange = (evt) => {
    var val = evt.target.value;

    if (val < 0) {
      val = 0;
    }

    if (val > this.state.quantityMax) {
      this.setState({ quantity: this.state.quantityMax });
    } else {
      this.setState({ quantity: Math.round(val * 100) / 100 });
    }
  };

  handleInvoiceChange = async (e) => {
    e.preventDefault();
    var targetValue = e.target.value;
    this.setState({ data: { invoiceNumber: targetValue } });
  };

  handleSupplierChange = async (e, data) => {
    e.preventDefault();

    this.state.supplierNameList.forEach((val) => {
      if (val.id === e.target.value) {
        this.setState((prevState) => ({
          ...prevState,
          supplierData: {
            ...prevState.supplierData,
            address: val.city,
            pan: val.panNumber,
          },
        }));
      }
    });

    await this.setState((prevState) => ({
      ...prevState,
      supplierData: {
        ...prevState.supplierData,
        supplierName: e.target.value,
      },
    }));
  };

  handleSearch = () => {
    if (this.state.supplierData.supplierName === "" || undefined) {
      this.setState({ emptySupplierName: true });

      return;
    }

    if (this.state.data.invoiceNumber === "" || undefined) {
      this.setState({ emptyInvoiceNumber: true });
      return;
    }

    //  var rowDetails = [];
    //var supplierDetails = this.state.supplierData;
    var obj = {
      supplier: this.state.supplierData.supplierName,
      invoiceNumber: this.state.data.invoiceNumber,
    };

    var allData = [];
    Axios.post("pos/purchase/getPurchaseBySupplierAndBillNumber", obj)
      .then((res) => {
        let total = 0;
        res.data.purchaseDetails.forEach((element) => {
          let allRowData = {
            sn: this.state.sn,
            itemCode: element.itemCode,
            itemName: element.itemName,
            quantity: element.quantity,
            price: element.price,
            discount: element.discount,
            lineTotal: element.lineTotal,
          };
          total = total + element.lineTotal;
          allData.push(allRowData);

          this.setState({
            numberSearch: false,
            total: total,
          });
        });

        this.setState((prevState) => ({
          ...prevState,
          rowData: allData,
          subTotal: res.data.subTotal,
          totalTaxes: res.data.taxes,
          totalDiscount: res.data.totalDiscount,
          total: res.data.totalAmount,
          data: {
            ...prevState.data,
            supplier: {
              ...prevState.data.supplier,
              id:
                res.data.supplier.id === null || undefined
                  ? null
                  : res.data.supplier.id,
            },
          },

          // numberSearch: true,
        }));

        /*  this.setState((prevState) => ({
            ...prevState,
            data: {
              ...prevState.data,
              invoiceNumber: targetValue,
              supplier: {
                ...prevState.data.supplier,
                id:
                  res.data.supplier.id === null || undefined
                    ? null
                    : res.data.supplier.id,
              },
            },
          })); */

        /* res.data.purchaseDetails.forEach((element) => {
            const item = {
              key: element.itemCode,
              value: element.itemCode + element.itemName,
              text: element.itemName + " #" + element.itemCode,
              label: { content: "#" + element.itemCode, tag: true },
            };
  
            supplierDetails.supplierName =
              res.data.billFrom === null || undefined ? "" : res.data.billFrom;
            supplierDetails.address =
              res.data.address === null || undefined ? "" : res.data.address;
            supplierDetails.pan =
              res.data.pan === null || undefined ? "" : res.data.pan;
  
            this.setState((prevState) => ({
              ...prevState,
              supplierData: supplierDetails,
              taxes: res.data.taxes,
              total: res.data.taxes,
            }));
  
            rowDetails.push(item);
          }); */
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          numberSearch: true,
        });
      });
    /*
/* 
    this.setState((prevState) => ({
      ...prevState,
      supplierData: supplierDetails,
      rowRefs: rowDetails,
      data: {
        ...prevState.data,
        invoiceNumber: targetValue,
      },
    }));
    if (
      this.state.data.invoiceNumber === "" ||
      this.state.data.invoiceNumber === undefined
    ) {
      this.resetFormValues();
    } */
  };

  resetFormValues() {
    this.setState({
      rowData: [],
      emptyComment: false,
      data: {
        address: "",
        comment: "",
        debitMode: "",
        debitNoteNumber: "",
        supplierName: "",
        date: "",
        invoiceNumber: "",
        pan: "",
        purchaseReturnDetails: [],
        preparedBy: "",
        subTotal: 0,
        supplier: { id: "" },
        total: 0,
        totalDiscount: 0,
        totalTax: 0,
      },
      supplierData: {
        supplierName: "",
        pan: "",
        address: "",
      },
      quantity: 1,
      subTotal: 0,
      taxes: 0,
      discount: 0,
      total: 0,
    });

    Axios.get("/pos/purchaseReturn/getDebitNoteInfo").then((res) => {
      this.setState((prevState) => ({
        ...prevState,

        preparedBy: res.data.preparedBy === null ? "" : res.data.preparedBy,
        //date: res.data.date === null ? "" : res.data.date,
        data: {
          ...prevState.data,
          debitNoteNumber:
            res.data.debitNoteNumber === null ? "" : res.data.debitNoteNumber,
        },
      }));
    });
    this.getVoucherNumbering(this.state.fiscalYear);
  }

  handleConfirm = () => {
    this.setState({
      confirmOpen: true,
    })
  }

  handleSubmit = () => {
    const token = this.context;

    if (
      this.state.data.comment === "" ||
      this.state.data.comment === undefined
    ) {
      this.setState({
        emptyComment: true,
      });
      return;
    }

    /* if (this.state.tableData === false) {
      var errorSnackbarProps = {
        open: true,
        variant: "error",
        message: "No Item Selected",
        autoHideDuration: 2000,
      };
      token.setSnackbarProps({ snackbarProps: errorSnackbarProps });
      return;
    } */

    this.setState(
      (prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          date: this.state.data.date,
          nepaliDate: this.state.data.nepaliDate,
          address: this.state.supplierData.address,
          pan: this.state.supplierData.pan,
          salesRepresentative: this.state.preparedBy,
          invoiceNumber: this.state.data.invoiceNumber,
          debitMode: this.state.data.debitMode,
          purchaseReturnDetails: this.state.rowData,
          purchaseReturnNumber: this.state.purchaseReturnNumber,
          subTotal: this.state.subTotal,
          total: this.state.total,
          totalDiscount: this.state.discount,
          totalTax: this.state.totalTaxes,
          fiscalYear: this.state.fiscalYear,
          period: this.state.currentPeriod,
          supplier: {
            ...prevState.data.supplier,
          },
        },
      }),
      () => {
        Axios.post("pos/purchaseReturn/savePurchaseReturn", this.state.data)
          .then((res) => {
            var snackbarProps = {
              open: true,
              variant: "success",
              message: res.data,
              autoHideDuration: 2000,
            };
            token.setSnackbarProps({ snackbarProps: snackbarProps });
            this.resetFormValues();
          })
          .catch((err) => {
            console.log(err);
            var message = err.message;
            if (err.response !== undefined) {
              message = err.response.data;
            }
            var snackbarProps = {
              open: true,
              variant: "error",
              message: message,
              autoHideDuration: 2000,
            };
            token.setSnackbarProps({ snackbarProps: snackbarProps });
          });
      }
    );
    this.setState({confirmOpen:false})
  };

  handleItemSelect = (event, data) => {
    const { value } = data;
    const { key } = data.options.find((o) => o.value === value);
    this.state.resData.forEach((element) => {
      if (element.itemCode === key) {
        this.setState({
          quantityMax: element.quantity,
          itemDetails: element,
        });
      }
    });
  };

  // handle0Index = (rowAllData, rowsToSave, itemData) => {

  //   var rowItemData = {
  //     sn: this.state.sn,
  //     itemCode: itemData.itemCode,
  //     itemName: itemData.itemName,
  //     quantity: this.state.quantity,
  //     taxes: itemData.taxable,
  //     price: itemData.price,
  //     discount: 0,
  //     lineTotal: itemData.price * this.state.quantity - 0
  //   };

  //   var toSave = true;

  //   rowAllData.forEach(element => {
  //     if (element.itemName === itemData.itemName) {
  //       toSave = false;
  //     }
  //   });

  //   if (toSave) {
  //     rowAllData.push(rowItemData);
  //     rowAllData.forEach(element => {
  //       var itemToAddDiff = {
  //         discount: element.discount,
  //         itemCode: element.itemCode,
  //         itemName: element.itemName,
  //         lineTotal: element.lineTotal,
  //         price: element.price,
  //         quantity: element.quantity
  //       };

  //       rowsToSave.push(itemToAddDiff);
  //     });
  //   }
  // };

  getAllRows = () => {
    let rowData = [];
    this.gridApi.forEachNode((node) => rowData.push(node.data));
    return rowData;
  };

  handle0Index = (rowAllData, rowsToSave, itemData) => {
    this.setState((prevState) => ({ ...prevState, tableData: true }));
    var rowItemData = {
      sn: this.state.sn,
      itemCode: itemData.itemCode,
      itemName: itemData.itemName,
      quantity: this.state.quantity,
      taxes: itemData.taxable,
      price: itemData.price,
      discount: 0,
      lineTotal: itemData.price * this.state.quantity - 0,
    };

    var toSave = true;

    rowAllData.forEach((element) => {
      if (element.itemName === itemData.itemName) {
        toSave = false;
      }
    });

    if (toSave) {
      rowAllData.push(rowItemData);
      rowAllData.forEach((element) => {
        var itemToAddDiff = {
          discount: element.discount,
          itemCode: element.itemCode,
          itemName: element.itemName,
          lineTotal: element.lineTotal,
          price: element.price,
          quantity: element.quantity,
        };

        rowsToSave.push(itemToAddDiff);
      });
    }
  };

  handleItemAdd = () => {
    var rows = this.getAllRows();

    var itemData = this.state.itemDetails;

    if (itemData.itemName === undefined || this.state.quantity === 0) {
      return;
    }
    var rowAllData = this.state.rowData;

    var total = this.state.total + itemData.price * this.state.quantity;

    this.setState({
      subTotal: this.state.subTotal + itemData.price * this.state.quantity,
      discount: this.state.discount + itemData.discount,
      total: total,
      quantityMax: this.state.quantityMax - this.state.quantity,
    });

    var rowsToSave = [];

    if (rows.length === 0) {
      this.handle0Index(rowAllData, rowsToSave, itemData);
    } else {
      for (let i = 0; i < rows.length; i++) {
        const element = rowAllData[i];
        if (element.itemName === itemData.itemName) {
          var newQuantity = element.quantity + this.state.quantity;
          var newDiscount = element.discount * newQuantity;

          var rowSpecificData = {
            ...element,
            quantity: newQuantity,
            discount: newDiscount,
            lineTotal: itemData.price * newQuantity - newDiscount,
          };

          rowAllData[i] = rowSpecificData;

          var itemToAdd = {
            discount: rowSpecificData.discount,
            itemCode: rowSpecificData.itemCode,
            itemName: rowSpecificData.itemName,
            lineTotal: rowSpecificData.lineTotal,
            price: rowSpecificData.sellingPrice,
            quantity: rowSpecificData.quantity,
          };
          rowsToSave.push(itemToAdd);
        } else {
          this.handle0Index(rowAllData, rowsToSave, itemData);
        }
      }
    }

    this.setState({ sn: this.state.sn + 1 });

    this.setState({ rowData: rowAllData });

    this.gridApi.setRowData(rowAllData);

    this.setState({ quantity: 1 });
  };

  deleteRow = (data) => {
    var rowAllData = this.state.rowData;

    var length = rowAllData.length;

    var finalRows = [];

    finalRows = rowAllData;

    for (var i = 0; i < length; i++) {
      var obj = rowAllData[i];
      if (obj.sn === data.sn) {
        finalRows.splice(i, 1);

        var subTotalNow = this.state.subTotal;
        var taxesNow = this.state.taxes;
        var discountNow = this.state.discount;
        var totalNow = this.state.total;

        var subTotalNew = subTotalNow - obj.price * obj.quantity;

        var taxesNew = taxesNow - obj.taxes;
        var discountNew = discountNow - 0;
        var totalNew = totalNow - obj.price * obj.quantity;

        this.setState({
          subTotal: subTotalNew,
          taxes: taxesNew,
          discount: discountNew,
          total: totalNew,
        });

        break;
      }
    }
  };

  handleAddSupplier = () => {
    this.props.history.push(`/supplier/master`);
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  onRemoveSelected = () => {
    const selectedData = this.gridApi.getSelectedRows();
    this.gridApi.updateRowData({ remove: selectedData });
  };

  handleRadioChange = (evt) => {
    this.setState({ value: evt.target.value });
  };

  handleTextChange = (e) => {
    e.stopPropagation();
    var targetName = e.target.name;
    var value = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [targetName]: value,
      },
    }));
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Grid container spacing={2} className={classes.containerWidth}>
          <Grid item xs={12} sm={6} md={6}>
            {this.state.emptySupplierName ? (
              <TextField
                error
                helperText="Supplier should not empty!"
                id="outlined-dense"
                label="Supplier Name"
                margin="dense"
                variant="outlined"
                name="supplierName"
                fullWidth
                onChange={this.handleSupplierChange}
                value={this.state.supplierData.supplierName}
                select
              >
                {this.state.supplierNameList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.fullName}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              <TextField
                id="outlined-dense"
                label="Supplier Name"
                margin="dense"
                variant="outlined"
                name="supplierName"
                fullWidth
                onChange={this.handleSupplierChange}
                value={this.state.supplierData.supplierName}
                select
              >
                {this.state.supplierNameList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.fullName}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Grid>
          <Grid item xs={4}>
            {this.state.emptyInvoiceNumber ? (
              <TextField
                id="outlined-dense"
                label="Invoice Number"
                margin="dense"
                value={this.state.data.invoiceNumber}
                onChange={this.handleInvoiceChange}
                variant="outlined"
                fullWidth
                error
                helperText="Invoice No. should not empty!"
              />
            ) : this.state.numberSearch ? (
              <TextField
                id="outlined-dense"
                label="Invoice Number"
                margin="dense"
                value={this.state.data.invoiceNumber}
                onChange={this.handleInvoiceChange}
                variant="outlined"
                fullWidth
                error
                helperText="Invoice No. not found!"
              />
            ) : (
              <TextField
                id="outlined-dense"
                label="Invoice Number"
                margin="dense"
                onChange={this.handleInvoiceChange}
                variant="outlined"
                fullWidth
              />
            )}
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              onClick={this.handleSearch}
              style={{ margin: "10px" }}
            >
              {" "}
              Search
            </Button>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              variant="outlined"
              select
              margin="dense"
              fullWidth
              label="Fiscal Year"
              name="fiscalYear"
              onChange={this.handleFYChange}
              value={this.state.fiscalYear}
            >
              {this.state.fyList.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="outlined-dense"
              label="Purchase Return No."
              value={this.state.purchaseReturnNumber}
              //onChange={this.handleChange}
              fullWidth
              name="purchaseReturnNumber"
              margin="dense"
              variant="outlined"
            >
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              variant="outlined"
              select
              margin="dense"
              fullWidth
              label="Period"
              name="period"
              onChange={this.handlePeriodChange}
              value={this.state.currentPeriod}
            >
              {this.state.periodList.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={3} style={{marginTop: "8px"}}>

          <NepaliDatepicker
              id="nepali-date"
              onChange={this.onChangedDate}
              label="Date"
              value={this.state.data.date}
              locale= "en"
              showDefaultDate="true"
              style={{height: "5px", width: "5px"}}
            />

            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                label="Date"
                value={this.state.date}
                inputVariant="outlined"
                autoOk={true}
                format="yyyy-MM-dd"
                margin="dense"
                fullWidth
                minDate={this.state.periodDateFrom}
                maxDate={this.state.periodDateTo}
                onChange={this.onChangedDate}
                shouldDisableDate={this.shouldDisableDate}
                animateYearScrolling
              />
            </MuiPickersUtilsProvider> */}
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              id="outlined-dense"
              label="Supplier PAN"
              name="pan"
              fullWidth
              value={this.state.supplierData.pan}
              margin="dense"
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              id="outlined-dense"
              label="Supplier Address"
              name="address"
              fullWidth
              value={this.state.supplierData.address}
              margin="dense"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              id="outlined-dense"
              label="Debit Mode"
              fullWidth
              value={this.state.data.debitMode}
              onChange={this.handleTextChange}
              name="debitMode"
              margin="dense"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <TextField
              id="outlined-dense"
              label="Prepared By"
              name="preparedBy"
              fullWidth
              value={this.state.preparedBy}
              margin="dense"
              variant="outlined"
            />
          </Grid>
        </Grid>

        <Divider style={{ marginTop: "15px", marginBottom: "5px" }} />

        <Grid
          container
          justify="center"
          spacing={2}
          className={classes.bottomContainerWidth}
        >
          <Grid item xs={12} sm={12} md={7}>
            <Dropdown
              placeholder="Search by Item Code or Item Name"
              fluid
              fullWidth
              search
              style={{ marginTop: "15px" }}
              ref={(search) => {
                this.searchInput = search;
              }}
              selection
              onChange={this.handleItemSelect}
              options={this.state.rowRefs}
            ></Dropdown>
          </Grid>

          <Grid item xs={7} sm={8} md={3}>
            <Input
              placeholder="Quantity"
              style={{ marginTop: "15px", width: "100%" }}
              type="number"
              onChange={this.handleQuantityChange}
              value={this.state.quantity}
              max={this.state.quantityMax}
            ></Input>
          </Grid>
          <Grid item xs={5} sm={4} md={2}>
            <Button
              variant="outlined"
              fullWidth
              color="primary"
              onClick={this.handleItemAdd}
              style={{
                margin: "auto",
                height: "38px",
                marginTop: "15px",
              }}
            >
              +Add
            </Button>
          </Grid>

          <div
            className="ag-theme-balham"
            style={{
              minWidth: "60px",
              height: "240px",
              width: "100%",
              margin: "10px",
            }}
          >
            <AgGridReact
              columnDefs={this.state.columnDefs}
              rowData={this.state.rowData}
              animateRows={true}
              rowSelection={this.state.rowSelection}
              onGridReady={this.onGridReady}
            ></AgGridReact>
          </div>
          <div></div>
        </Grid>

        <Grid
          container
          justify="center"
          spacing={2}
          style={{ width: "90%", margin: "auto" }}
        >
          <Grid item xs={12} sm={6} md={3}>
            <Grid container justify="center">
              <p>
                Sub Total:{" "}
                <span style={{ display: "inline-block", width: "20px" }}></span>
                <strong>{this.state.subTotal}</strong>
              </p>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid container justify="center">
              <p>
                Taxes:{" "}
                <span style={{ display: "inline-block", width: "48px" }}></span>
                <strong>{this.state.totalTax}</strong>
              </p>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid container justify="center">
              <p>
                Discount:{" "}
                <span style={{ display: "inline-block", width: "23px" }}></span>
                <strong>{this.state.totalDiscount}</strong>
              </p>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid container justify="center">
              <p>
                Total:{" "}
                <span style={{ display: "inline-block", width: "43px" }}></span>
                <strong>{this.state.total}</strong>
              </p>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          spacing={2}
          className={classes.bottomContainerWidth}
        >
          <Grid item xs={12}>
            {this.state.emptyComment ? (
              <TextField
                id="standard-with-placeholder"
                error
                helperText="Comment should not be empty"
                label="Comment"
                name="comment"
                variant="outlined"
                fullWidth
                onChange={this.handleTextChange}
                value={this.state.data.comment}
                style={{ marginTop: "15px" }}
                margin="normal"
              />
            ) : (
              <TextField
                id="standard-with-placeholder"
                label="Comment"
                name="comment"
                variant="outlined"
                fullWidth
                onChange={this.handleTextChange}
                value={this.state.data.comment}
                style={{ marginTop: "15px" }}
                margin="normal"
              />
            )}
          </Grid>
        </Grid>

        <Grid container justify="center" className={classes.bottomTopMargin}>
          <Button
            variant="outlined"
            color="primary"
            onClick={this.handleConfirm}
            style={{
              minWidth: "100px",
              width: "20vw",
              height: "50px",
              margin: "auto",
            }}
          >
            Save
          </Button>
        </Grid>
        <ConfirmationDialog
          open={this.state.confirmOpen}
          handleClose={() => {
            this.setState({ confirmOpen: false });
          }}
          handleConfirm={this.handleSubmit}
          titleMessage={"Return Purchase?"}
          bodyMessage={
            "Are you sure to return this Purchase?"
          }
        />
        <VoucherNumberRestriction
          open={this.state.voucherNumberError}
        ></VoucherNumberRestriction>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(PurchaseReturnForm));
