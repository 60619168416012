import React from "react";
import { Grid } from "@material-ui/core";
require("../../assets/css/bootstrap.css");
require("../../assets/css/semantic.css");
require("ag-grid-community/dist/styles/ag-grid.css");
require("ag-grid-community/dist/styles/ag-theme-balham.css");

function HeaderTitle(props) {
  // const { title } = props;
  return (
    <Grid container>
      <div style={{ marginTop: "60px" }}></div>
    </Grid>

    // <Grid container>
    //   <Typography
    //     variant="h4"
    //     gutterBottom
    //     style={{
    //       paddingLeft: "10px",
    //       paddingTop: "15px",
    //       paddingBottom: "15px",
    //       fontSize: "20px",
    //       marginTop: "50px"
    //     }}
    //   >
    //     {title}
    //   </Typography>
    // </Grid>
  );
}

export default HeaderTitle;
