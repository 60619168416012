import React, { Component } from "react";
import {
  Grid,
  TextField,
  Divider,
  Button,
  Container,
  Card,
  CardContent,
  withStyles,
  MenuItem
} from "@material-ui/core";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import DeleteButton from "../components/InterfaceElements/DeleteButton";
import { Dropdown, Input } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import Axios from "axios";
import UserContext from "../utils/UserContext";
import HeaderTitle from "../components/InterfaceElements/HeaderTitle";
import { format } from "date-fns/esm";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import adbs, { bs2ad } from "ad-bs-converter";
import dateFormat from "dateformat";
import "nepali-datepicker-reactjs/dist/index.css";
import NepaliDatepicker from 'nepali-datepicker-and-dateinput';
import VoucherNumberRestriction from "../components/InterfaceElements/VoucherNumberRestriction";

const styles = theme => ({
  root: {
    [theme.breakpoints.down("md")]: {
      marginBottom: "170px",
      marginTop: "40px",
      width: "100%"
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px",
      marginTop: "10px",
      margin: 15
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "10px",
      marginBottom: "100px",
      margin: 15
    }
  },
  containerWidth: {
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.up("sm")]: {
      width: "70%"
    }
  },
  bottomContainerWidth: {
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.up("sm")]: {
      width: "90%"
    }
  },
  bottonTopMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px"
    },

    [theme.breakpoints.up("sm")]: {
      marginTop: "20px",
      marginBottom: "5px"
    }
  }
});

class Replacement extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      periodList: [],
      fyList: [],
      currentPeriod: "",
      fiscalYear: "",
      periodResData: [],
      commentError: false,
      rowRefs: [],
      customerRowRefs: [],
      columnDefs: [
        {
          headerName: "SN",
          field: "sn",
          sortable: true,
          resizable: true,
          width: 40
        },
        {
          headerName: "ItemCode",
          field: "code",
          sortable: true,
          resizable: true,
          width: 70
        },
        {
          headerName: "Name",
          field: "name",
          sortable: true,
          resizable: true
        },
        {
          headerName: "Quantity",
          field: "quantity",
          sortable: true,
          resizable: true,
          editable: true,
          width: 70
        },
        {
          headerName: "Price",
          field: "price",
          sortable: true,
          editable: true,
          width: 70
        },
        {
          headerName: "Discount",
          field: "discount",
          width: 70
        },
        {
          headerName: "Line Total",
          field: "lineTotal",
          sortable: true,
          width: 70
        },
        {
          headerName: "Delete",
          cellRendererFramework: DeleteButton,
          cellRendererParams: {
            deleteRow: this.deleteRow
          },
          width: 50
        }
      ],
      rowData: [],
      rowToSave: [],
      rowSelection: "multiple",
      value: "cash",
      data: {
        address: "",
        comment: "",
        creditMode: "",
        replacementNumber: "",
        customerName: "",
        date: "",
        nepaliDate: "",
        salesInvoiceNumber: "",
        pan: "",
        preparedBy: "",
        replacementDetails: []
      },
      periodDateFrom: "",
      periodDateTo: "",
      quantity: 1,
      quantityMax: 0,
      subTotal: 0,
      taxes: 0,
      sn: 1,
      discount: 0,
      total: 0,
      itemDetails: {},
      resData: [],
      customerData: {
        customerName: "",
        address: "",
        pan: ""
      },
      replacementNoList: [],
      voucherNumberError: false,
      voucherNumber: "",
      searchNumber: true
    };
  }

  handleInvoiceChange = e => {
    e.persist();

    let val = e.target.value

    console.log({ val });

    var rows = [];
    var customerDetails = this.state.customerData;
    var obj = {
      invoiceNumber: e.target.value,
    };

    Axios.post("pos/sales/getSalesByInvoiceNumberAndReplacementStatus", obj)
      .then(res => {
        console.log(res);

        this.setState({
          resData: res.data.salesDetail,
          searchNumber: true
        });

        res.data.salesDetail.forEach(element => {
          const item = {
            key: element.itemCode,
            value: element.itemCode + element.itemName,
            text: element.itemName + " #" + element.itemCode,
            label: { content: "#" + element.itemCode, tag: true }
          };

          customerDetails.customerName =
            res.data.billTo === null || undefined ? "" : res.data.billTo;
          customerDetails.address =
            res.data.address === null || undefined ? "" : res.data.address;
          customerDetails.pan =
            res.data.pan === null || undefined ? "" : res.data.pan;

          this.setState(
            {
              customerData: customerDetails,
              taxes: res.data.taxAmount,
              total: res.data.taxAmount
            },
            () => {
              console.log(this.state.customerData);
            }
          );

          rows.push(item);
        });
      })
      .catch(err => {
        console.log(err);

        this.setState({
          searchNumber: false
        })
        if (val === "") {
          this.setState({ searchNumber: true });
        }

      });

    this.setState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        salesInvoiceNumber: e.target.value
      }
    }));

    this.setState(prevState => ({
      ...prevState,
      customerData: customerDetails,
      rowRefs: rows,
      data: {
        ...prevState.data,
        salesInvoiceNumber: e.target.value
      }
    }));
  };

  updateInvoiceSelectData = () => { };

  componentDidMount() {
    this.getAllPeriodListing();

    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    var todayDate = dateFormat(date, "yyyy-mm-dd");

    let transactionDate = (todayDate.split("-"));
    
    let tdf = (
      transactionDate[0] +
        "/" +
        transactionDate[1] +
        "/" +
        transactionDate[2]
    );
    let df= adbs.ad2bs(tdf)

    let finalYear = df.en.year;
    let finalMonth = df.en.month;
    let finalDay = df.en.day;
    if(df.en.month < 10){
      finalMonth = "0" + df.en.month;
    }    
    if(df.en.day < 10){
      finalDay = "0" + df.en.day;
    }

    let finalDate = finalYear + "-" + finalMonth + "-" + finalDay;

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        date: date,
        nepaliDate: finalDate,
      },
    }));

    Axios.get("pos/replacement/getReplacement")
                .then(res => {
                  this.setState(prevState => ({
                    ...prevState,
                    data: {
                      ...prevState.data,
                      //date: res.data.date,
                      preparedBy: res.data.preparedBy
                    }
                  }));
                })
                .catch(err => {
                  console.log(err);
                });
  }

  getAllPeriodListing = () => {
    Axios.get("/fy/getAllActivePeriods")
      .then(async res => {
        var fiscalYearLists = [];
        res.data.forEach(element => {
          var fy = Object.keys(element);

          fiscalYearLists.push(fy[0]);
        });
        await this.setState({
          fyList: fiscalYearLists,
          periodResData: res.data
        });

        this.getCurrentPeriod();
      })
      .catch(err => {
        console.log(err);
      });
  };

  getCurrentPeriod() {
    Axios.get("/fy/getCurrentPeriod")
      .then(async res => {
        await this.setState(prevState => ({
          ...prevState,
          fiscalYear: res.data.fiscalYear,
          currentPeriod: res.data.name
        }));
        this.getPeriodList();
        this.getNumbering(res.data.fiscalYear);
      })
      .catch(err => {
        console.log(err);
      });
  }

  getPeriodList() {
    var pLists = [];

    // this.state.resData.map(val => Object.keys(val) === this.state.fiscalYear ? pLists = val : null);

    this.state.periodResData.map(val =>
      Object.keys(val).includes(this.state.fiscalYear)
        ? (pLists = val[this.state.fiscalYear])
        : null
    );

    this.setState(prevState => ({
      ...prevState,
      periodList: pLists,
      currentPeriod: pLists[pLists.length-1],
    }));
    this.getPeriodDateFromTo(this.state.fiscalYear,pLists[pLists.length-1]);
  }

  handleFYChange = async e => {
    let Fy = e.target.value;
    await this.setState(prevState => ({
      ...prevState,
      fiscalYear: Fy
    }));
    this.getPeriodList();
    this.getNumbering(Fy);
  };

  handlePeriodChange = async e => {
    let Py = e.target.value;

    await this.setState(prevState => ({
      ...prevState,
      currentPeriod: Py
    }));
    this.getPeriodDateFromTo(this.state.fiscalYear, Py);
  };

  getPeriodDateFromTo = (fiscalYear, period) => {
    Axios.post("fy/findByNameAndFiscalYear", { period, fiscalYear }).then(
      (res) =>
        this.setState((prevState)=>({
          ...prevState,
          periodDateFrom: res.data.dateFrom,
          periodDateTo: res.data.dateTo,
        }))
    );
  };


  onChangedDate = (name, dateInMilli, bsDate, adDate) => {

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        date: adDate,
        nepaliDate: bsDate,
      },
    }));
    console.log(adDate);
  };

  handleChange = e => {
    let val = e.target.value;
    this.setState(prevState => ({
      ...prevState,
      voucherNumber: val,
      data: {
        ...prevState.data,
        replacementNumber: val
      }
    }));
  };

  handleTextChange = e => {
    e.stopPropagation();
    var targetName = e.target.name;
    var value = e.target.value;
    this.setState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        [targetName]: value
      }
    }));
  };

  //handle submit

  handleSubmit = () => {
    const token = this.context;

    if (
      this.state.data.comment === "" ||
      this.state.data.comment === undefined
    ) {
      this.setState({ commentError: true });
    } else {
      this.setState(
        prevState => ({
          ...prevState,
          data: {
            ...prevState.data,

            fiscalYear: this.state.fiscalYear,
            period: this.state.currentPeriod,
            replacementDetails: this.state.rowData
          }
        }),
        () => {
          Axios.post("pos/replacement/save", this.state.data)
            .then(res => {
              var snackbarProps = {
                open: true,
                variant: "success",
                message: res.data,
                autoHideDuration: 2000
              };
              token.setSnackbarProps({ snackbarProps: snackbarProps });
              this.setState({
                data: {
                  address: "",
                  comment: "",
                  creditMode: "",
                  replacementNumber: "",
                  customerName: "",
                  date: "",
                  nepaliDate:"",
                  salesInvoiceNumber: "",
                  pan: "",
                  preparedBy: "",
                  replacementDetails: []
                },
                customerData: {
                  customerName: "",
                  address: "",
                  pan: ""
                },
                quantity: 1,
                rowData: []
              });
              this.getNumbering(this.state.fiscalYear);
              Axios.get("pos/replacement/getReplacement")
                .then(res => {
                  this.setState(prevState => ({
                    ...prevState,
                    data: {
                      ...prevState.data,
                      //date: res.data.date,
                      preparedBy: res.data.preparedBy
                    }
                  }));
                })
                .catch(err => {
                  console.log(err);
                });
            })
            .catch(err => {
              var message = err.message;
              if (err.response !== undefined) {
                message = err.response.data;
              }
              var snackbarProps = {
                open: true,
                variant: "error",
                message: message,
                autoHideDuration: 2000
              };
              token.setSnackbarProps({ snackbarProps: snackbarProps });
            });
        }
      );
    }
  };

  getNumbering(fy) {
    Axios.post("/pos/voucherNumbering/getVoucherNumbering", {fiscalYear: fy, name: "Replacement"})
      .then(res => {
        if (res.data === "Not Found") {
          this.setState({ voucherNumberError: true });
        } else {
          this.setState(prevState => ({
            ...prevState,
            replacementNoList: res.data,
             data: {
              ...prevState.data,
              replacementNumber: res.data[0],
            }
          }));
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  handleItemAdd = () => {
    var itemData = this.state.itemDetails;

    if (itemData.itemName === undefined || this.state.quantity === 0) {
      return;
    }

    var rowAllData = this.state.rowData;
    var rowAllToSave = this.state.rowToSave;

    this.setState({
      subTotal: 0,
      discount: 0,
      total: 0,
      quantityMax: this.state.quantityMax - this.state.quantity
    });

    var rowItemData = {
      sn: this.state.sn,
      code: itemData.itemCode,
      name: itemData.itemName,
      quantity: this.state.quantity,

      price: itemData.price,
      discount: 0,
      lineTotal: itemData.price * this.state.quantity
    };

    rowAllToSave.push(rowItemData);

    var rowNextItemData = {
      sn: this.state.sn + 1,
      code: itemData.itemCode,
      name: itemData.itemName,
      quantity: this.state.quantity,
      price: itemData.price,
      discount: 0,
      lineTotal:
        itemData.price * this.state.quantity -
        itemData.price * this.state.quantity * 2
    };

    this.setState({ sn: this.state.sn + 1 });

    rowAllData.push(rowItemData);
    rowAllData.push(rowNextItemData);

    this.setState({ rowData: rowAllData, rowToSave: rowAllToSave });

    var rowsToSave = [];

    rowAllData.forEach(element => {
      var itemToAdd = {
        discount: element.discount,
        itemCode: element.code,
        itemName: element.name,
        lineTotal: element.lineTotal,
        price: element.price,
        quantity: element.quantity
      };

      rowsToSave.push(itemToAdd);
    });

    console.log(this.state.rowData);

    this.gridApi.setRowData(rowAllData);

    this.setState({ quantity: 1 });
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  onRemoveSelected = () => {
    const selectedData = this.gridApi.getSelectedRows();
    this.gridApi.updateRowData({ remove: selectedData });
  };

  handleAddCustomer = () => {
    this.props.history.push(`/customer/master`);
  };

  handleQuantityChange = evt => {
    if (evt.target.value > this.state.quantityMax) {
      this.setState({ quantity: this.state.quantityMax });
    } else {
      this.setState({ quantity: evt.target.value });
    }
  };

  deleteRow = data => {
    console.log(data);
    var rowAllData = this.state.rowData;

    var length = rowAllData.length;

    var finalRows = [];

    finalRows = rowAllData;

    for (var i = 0; i < length; i++) {
      console.log("pos: " + i);
      var obj = rowAllData[i];
      if (obj.sn === data.sn) {
        console.log(obj);
        finalRows.splice(i, 1);

        var subTotalNow = this.state.subTotal;
        var taxesNow = this.state.taxes;
        var discountNow = this.state.discount;
        var totalNow = this.state.total;

        var subTotalNew = subTotalNow - obj.price * obj.quantity;

        var taxesNew = taxesNow - obj.taxes;
        var discountNew = discountNow - 0;
        var totalNew = totalNow - obj.price * obj.quantity;

        this.setState({
          subTotal: subTotalNew,
          taxes: taxesNew,
          discount: discountNew,
          total: totalNew
        });

        break;
      }
    }
  };

  handleItemSelect = (event, data) => {
    const { value } = data;
    const { key } = data.options.find(o => o.value === value);

    this.state.resData.forEach(element => {
      if (element.itemCode === key) {
        this.setState({
          quantityMax: element.quantity,
          itemDetails: element
        });
      }
    });
  };

  handleRadioChange = evt => {
    this.setState({ value: evt.target.value });
  };

  render() {
    const { classes } = this.props;

    return (
      <div style={{ backgroundColor: "#EAEDEF" }}>
        <HeaderTitle title="Replacement" />

        <Container>
          <Card className={classes.root}>
            <CardContent>
              <Grid
                container
                //justify="center"
                spacing={2}
                className={classes.containerWidth}
              >
                <Grid item xs={12}>
                  {this.state.searchNumber ? (
                    <TextField
                      id="outlined-dense"
                      label="Search By Invoice Number"
                      margin="dense"
                      value={this.state.data.salesInvoiceNumber}
                      onChange={this.handleInvoiceChange}
                      variant="outlined"
                      fullWidth
                    />) : (
                      <TextField
                        id="outlined-dense"
                        error
                        helperText="Invoice No. not found"
                        label="Search By Invoice Number"
                        margin="dense"
                        value={this.state.data.salesInvoiceNumber}
                        onChange={this.handleInvoiceChange}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    variant="outlined"
                    select
                    margin="dense"
                    fullWidth
                    label="Fiscal Year"
                    name="fiscalYear"
                    onChange={this.handleFYChange}
                    value={this.state.fiscalYear}
                  >
                    {this.state.fyList.map(item => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    id="outlined-dense"
                    label="Replacement No"
                    name="replacementNumber"
                    fullWidth
                    value={this.state.data.replacementNumber}
                    //onChange={this.handleChange}
                    margin="dense"
                    variant="outlined"
                  >
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    variant="outlined"
                    select
                    margin="dense"
                    fullWidth
                    label="Period"
                    name="period"
                    onChange={this.handlePeriodChange}
                    value={this.state.currentPeriod}
                  >
                    {this.state.periodList.map(item => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={6} md={3} style={{marginTop: "8px"}}>

                  <NepaliDatepicker
                    id="nepali-date"
                    onChange={this.onChangedDate}
                    label="Date"
                    value={this.state.data.date}
                    locale= "en"
                    showDefaultDate="true"
                    style={{height: "5px", width: "5px"}}
                  />

                  {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      label="Date"
                      value={this.state.data.date}
                      inputVariant="outlined"
                      autoOk={true}
                      format="yyyy-MM-dd"
                      margin="dense"
                      fullWidth
                      minDate={this.state.periodDateFrom}
                      maxDate={this.state.periodDateTo}
                      onChange={this.onChangedDate}
                      shouldDisableDate={this.shouldDisableDate}
                      animateYearScrolling
                    />
                  </MuiPickersUtilsProvider> */}
                </Grid>

                <Grid item xs={12} sm={12} md={3}>
                  <TextField
                    id="outlined-dense"
                    label="Customer Name"
                    fullWidth
                    value={this.state.customerData.customerName}
                    name="customerName"
                    margin="dense"
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    id="outlined-dense"
                    label="Address"
                    fullWidth
                    value={this.state.customerData.address}
                    name="address"
                    margin="dense"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    id="outlined-dense"
                    label="PAN"
                    name="pan"
                    fullWidth
                    value={this.state.customerData.pan}
                    margin="dense"
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    id="outlined-dense"
                    label="Prepared By"
                    name="preparedBy"
                    fullWidth
                    value={this.state.data.preparedBy}
                    margin="dense"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    id="outlined-dense"
                    label="Credit Mode"
                    name="creditMode"
                    fullWidth
                    value={this.state.data.creditMode}
                    onChange={this.handleTextChange}
                    margin="dense"
                    variant="outlined"
                  />
                </Grid>
              </Grid>

              <Divider style={{ marginTop: "15px", marginBottom: "5px" }} />
              <Grid
                container
                justify="center"
                spacing={2}
                className={classes.bottomContainerWidth}
              >
                <Grid item xs={12} sm={12} md={7}>
                  <Dropdown
                    placeholder="Search by Item Code or Item Name"
                    fullWidth
                    fluid
                    search
                    style={{ marginTop: "15px" }}
                    ref={search => {
                      this.searchInput = search;
                    }}
                    selection
                    onChange={this.handleItemSelect}
                    options={this.state.rowRefs}
                  ></Dropdown>
                </Grid>

                <Grid item xs={7} sm={8} md={3}>
                  <Input
                    placeholder="Quantity"
                    type="number"
                    style={{ width: "100%", marginTop: "15px" }}
                    onChange={this.handleQuantityChange}
                    value={this.state.quantity}
                    max={this.state.quantityMax}
                  ></Input>
                </Grid>
                <Grid item xs={5} sm={4} md={2}>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={this.handleItemAdd}
                    style={{
                      minWidth: "60px",
                      margin: "auto",
                      height: "38px",
                      marginTop: "15px"
                    }}
                  >
                    +Add
                  </Button>
                </Grid>

                <div
                  className="ag-theme-balham"
                  style={{
                    height: "240px",
                    width: "100%",
                    margin: "10px"
                  }}
                >
                  <AgGridReact
                    columnDefs={this.state.columnDefs}
                    rowData={this.state.rowData}
                    animateRows={true}
                    rowSelection={this.state.rowSelection}
                    onGridReady={this.onGridReady}
                  ></AgGridReact>
                </div>
                <div></div>
              </Grid>

              <Grid
                container
                justify="center"
                spacing={4}
                style={{ marginBottom: "10px", marginTop: "7px" }}
              >
                <Grid item xs={12} sm={6} md={3}>
                  <Grid container justify="center">
                    <p>
                      Sub Total:{" "}
                      <span style={{ display: "inline-block" }}></span>
                      <strong>{this.state.subTotal}</strong>
                    </p>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Grid container justify="center">
                    <p>
                      Taxes: <span style={{ display: "inline-block" }}></span>
                      <strong>{this.state.taxes}</strong>
                    </p>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Grid container justify="center">
                    <p>
                      Discount:{" "}
                      <span style={{ display: "inline-block" }}></span>
                      <strong>{this.state.discount}</strong>
                    </p>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Grid container justify="center">
                    <p>
                      Total: <span style={{ display: "inline-block" }}></span>
                      <strong>{this.state.total}</strong>
                    </p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                justify="center"
                spacing={2}
                className={classes.bottomContainerWidth}
              >
                <Grid item xs={12}>
                  {this.state.commentError ? (
                    <TextField
                      id="standard-with-placeholder"
                      label="Comment"
                      name="comment"
                      variant="outlined"
                      error
                      helperText="comment cannot be empty!"
                      fullWidth
                      onChange={this.handleTextChange}
                      value={this.state.data.comment}
                      style={{ marginTop: "5px" }}
                      margin="normal"
                    />
                  ) : (
                      <TextField
                        id="standard-with-placeholder"
                        label="Comment"
                        name="comment"
                        variant="outlined"
                        fullWidth
                        onChange={this.handleTextChange}
                        value={this.state.data.comment}
                        style={{ marginTop: "5px" }}
                        margin="normal"
                      />
                    )}
                </Grid>
              </Grid>

              <Grid
                container
                justify="center"
                className={classes.bottonTopMargin}
              >
                <Button
                  onClick={this.handleSubmit}
                  variant="outlined"
                  color="primary"
                  style={{
                    minWidth: "100px",
                    width: "20vw",
                    height: "50px",
                    margin: "auto"
                  }}
                >
                  Save
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Container>
        <VoucherNumberRestriction
          open={this.state.voucherNumberError}
        ></VoucherNumberRestriction>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Replacement));
