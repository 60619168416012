import React, { Component } from "react";
import {
  Modal,
  Backdrop,
  Fade,
  CardContent,
  Card,
  Grid,
  TextField,
  MenuItem,
  Button
} from "@material-ui/core";
import Axios from "axios";
import UserContext from "../../utils/UserContext";


class AddSupplierModal extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      data: {}
    };
  }

  handleSubmit = () => {
    console.log("i am here on supplier");

    var objChild = {
      accountName: this.state.data.accountName,
      accountNumber: this.state.data.accountNumber,
      accountType: this.state.data.accountType,
      bankCode: this.state.data.bankCode,
      bankName: this.state.data.bankName,
      branchCode: this.state.data.branchCode,
      city: this.state.data.bankCity,
      country: this.state.data.bankCountry,
      currency: this.state.data.currency,
      email: this.state.data.email,
      fax: this.state.data.fax,
      ibanNumber: this.state.data.ibanNumber,
      postBoxNumber: this.state.data.postBoxNumber,
      state: this.state.data.bankState,
      street: this.state.data.bankStreet,
      swiftCode: this.state.data.swiftCode,
      telephone: this.state.data.telephone
    };
    var objParent = {
      city: this.state.data.city,
      contactPerson: this.state.data.contactPerson,
      country: this.state.data.country,
      creditTerm: this.state.data.creditTerm,
      emailAddress: this.state.data.emailAddress,
      faxNumber: this.state.data.faxNumber,
      fullName: this.state.data.fullName,
      mobileNumber: this.state.data.mobileNumber,
      otherReference: this.state.data.otherReference,
      panNumber: this.state.data.panNumber,
      postBoxNumber: this.state.data.postBoxNumber,
      productType: this.state.data.productType,
      registrationNumber: this.state.data.registrationNumber,
      state: this.state.data.state,
      street: this.state.data.street,
      supplierBankDetails: objChild,
      supplierType: this.state.data.supplierType,
      telephoneNumber: this.state.data.telephoneNumber
    };

    console.log(objParent);
    
    const token = this.context;

    Axios.post("pos/supplier/addSuppler", objParent)
      .then(res => {
        console.log(res);

        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000
        };

        token.setSnackbarProps({ snackbarProps: snackbarProps });
        this.props.handleClose();
      })
      .catch(err => {
        var message = err.message;
        if(err.response !== undefined){
          message=err.response.data;
        }
        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000
        };

        token.setSnackbarProps({ snackbarProps: snackbarProps });
      });
  };

  onChanged = e => {
    e.stopPropagation();
    var targetName = e.target.name;
    var value = e.target.value;
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [targetName]: value
      }
    }));
  };

  render() {
    const { open, handleClose } = this.props;

    const modal = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    };

    const textFieldStyle = {
      marginLeft: "10px",
      marginRight: "10px",
      marginTop: "0px",
      width: 250
    };
    const longTextFieldStyle = {
      marginLeft: "10px",
      marginRight: "10px",
      marginTop: "0px",
      width: 520
    };
    const inputStyles = {
      fontSize: 11
    };

    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={open}>
            <div>
              <Card >
                <CardContent>
                  <Grid container justify="center">
                    <Grid item xs>
                      <Grid
                        container
                        justify="center"
                        style={{ marginTop: "25px" }}
                      >
                        <h6>Supplier Details</h6>
                      </Grid>

                      <Grid container justify="center">
                        <TextField
                          id="standard-with-placeholder"
                          label="Full Name"
                          name="fullName"
                          onChange={this.onChanged}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          style={longTextFieldStyle}
                          margin="normal"
                        />
                      </Grid>

                      <Grid container justify="center">
                        <TextField
                          id="standard-with-placeholder"
                          label="Mobile Number"
                          name="mobileNumber"
                          onChange={this.onChanged}
                          placeholder=""
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          style={textFieldStyle}
                          margin="normal"
                        />
                        <TextField
                          id="standard-with-placeholder"
                          label="Telephone Number"
                          name="telephoneNumber"
                          onChange={this.onChanged}
                          placeholder=""
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          style={textFieldStyle}
                          margin="normal"
                        />
                      </Grid>
                      <Grid container justify="center">
                        <TextField
                          id="standard-with-placeholder"
                          label="Fax Number"
                          name="faxNumber"
                          onChange={this.onChanged}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          placeholder=""
                          style={textFieldStyle}
                          margin="normal"
                        />
                        <TextField
                          id="standard-with-placeholder"
                          label="Post Box Number"
                          name="postBoxNumber"
                          onChange={this.onChanged}
                          placeholder=""
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          style={textFieldStyle}
                          margin="normal"
                        />
                      </Grid>
                      <Grid container justify="center">
                        <TextField
                          id="standard-with-placeholder"
                          label="Registration Number"
                          name="registrationNumber"
                          onChange={this.onChanged}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          placeholder=""
                          style={textFieldStyle}
                          margin="normal"
                        />
                        <TextField
                          id="standard-with-placeholder"
                          label="PAN Number"
                          name="panNumber"
                          onChange={this.onChanged}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          placeholder=""
                          style={textFieldStyle}
                          margin="normal"
                        />
                      </Grid>
                      <Grid container justify="center">
                        <TextField
                          id="standard-with-placeholder"
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          label="Email Address"
                          name="emailAddress"
                          onChange={this.onChanged}
                          placeholder=""
                          style={textFieldStyle}
                          margin="normal"
                        />
                        <TextField
                          id="standard-with-placeholder"
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          label="Contact Person"
                          name="contactPerson"
                          onChange={this.onChanged}
                          placeholder=""
                          style={textFieldStyle}
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs>
                      <Grid
                        container
                        justify="center"
                        style={{ marginTop: "25px" }}
                      >
                        <h6>Supplier Bank Details</h6>
                      </Grid>

                      <Grid container justify="center">
                        <TextField
                          id="standard-with-placeholder"
                          label="Bank Name"
                          name="bankName"
                          onChange={this.onChanged}
                          // value={this.state.data.supplierBankDetails.bankName}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          placeholder=""
                          style={longTextFieldStyle}
                          margin="normal"
                        />
                      </Grid>
                      <Grid container justify="center">
                        <TextField
                          id="standard-with-placeholder"
                          label="Bank Account Name"
                          name="accountName"
                          onChange={this.onChanged}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          placeholder=""
                          style={textFieldStyle}
                          margin="normal"
                        />

                        <TextField
                          id="standard-with-placeholder"
                          label="Bank Account Number"
                          name="accountNumber"
                          onChange={this.onChanged}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          placeholder=""
                          style={textFieldStyle}
                          margin="normal"
                        />
                      </Grid>
                      <Grid container justify="center">
                        <TextField
                          id="standard-with-placeholder"
                          label="Bank Code"
                          name="bankCode"
                          onChange={this.onChanged}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          placeholder=""
                          style={textFieldStyle}
                          margin="normal"
                        />
                        <TextField
                          id="standard-with-placeholder"
                          label="Branch Code"
                          name="branchCode"
                          onChange={this.onChanged}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          placeholder=""
                          style={textFieldStyle}
                          margin="normal"
                        />
                      </Grid>
                      <Grid container justify="center">
                        <TextField
                          id="standard-with-placeholder"
                          label="Account Type"
                          name="accountType"
                          onChange={this.onChanged}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          placeholder=""
                          style={textFieldStyle}
                          margin="normal"
                        />
                        <TextField
                          id="standard-with-placeholder"
                          label="SWIFT"
                          name="swiftCode"
                          onChange={this.onChanged}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          placeholder=""
                          style={textFieldStyle}
                          margin="normal"
                        />
                      </Grid>

                      <Grid container justify="center">
                        <TextField
                          id="standard-with-placeholder"
                          label="Currency"
                          name="currency"
                          onChange={this.onChanged}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          placeholder=""
                          style={textFieldStyle}
                          margin="normal"
                        />
                        <TextField
                          id="standard-with-placeholder"
                          label="IBAN"
                          name="ibanNumber"
                          onChange={this.onChanged}
                          placeholder=""
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          style={textFieldStyle}
                          margin="normal"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justify="center"
                    style={{ marginTop: "25px" }}
                  >
                    <Grid item xs>
                      <Grid
                        container
                        justify="center"
                        style={{ marginTop: "25px" }}
                      >
                        <h6>Full Address</h6>
                      </Grid>

                      <Grid container justify="center">
                        <TextField
                          id="standard-with-placeholder"
                          label="Country"
                          name="country"
                          onChange={this.onChanged}
                          placeholder=""
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          style={textFieldStyle}
                          margin="normal"
                        />
                        <TextField
                          id="standard-with-placeholder"
                          label="State"
                          name="state"
                          onChange={this.onChanged}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          placeholder=""
                          style={textFieldStyle}
                          margin="normal"
                        />
                      </Grid>
                      <Grid container justify="center">
                        <TextField
                          id="standard-with-placeholder"
                          label="City"
                          name="city"
                          onChange={this.onChanged}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          placeholder=""
                          style={textFieldStyle}
                          margin="normal"
                        />
                        <TextField
                          id="standard-with-placeholder"
                          label="Street"
                          name="street"
                          onChange={this.onChanged}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          placeholder=""
                          style={textFieldStyle}
                          margin="normal"
                        />
                      </Grid>

                      <Grid
                        container
                        justify="center"
                        style={{ marginTop: "15px" }}
                      >
                        <TextField
                          id="standard-select-currency"
                          select
                          InputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          label="Product Type"
                          name="productType"
                          onChange={this.onChanged}
                          style={textFieldStyle}
                          value={1}
                          margin="normal"
                        >
                          <MenuItem key={1} value={1}>
                            Product Type 1
                          </MenuItem>
                          <MenuItem key={1} value={2}>
                            Product type 2
                          </MenuItem>
                        </TextField>
                        <TextField
                          id="standard-select-currency"
                          select
                          label="Supplier Type"
                          name="supplierType"
                          onChange={this.onChanged}
                          InputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          style={textFieldStyle}
                          value={1}
                          margin="normal"
                        >
                          <MenuItem key={1} value={1}>
                            Supplier Type 1
                          </MenuItem>
                          <MenuItem key={1} value={2}>
                            Supplier type 2
                          </MenuItem>
                        </TextField>
                      </Grid>

                      <Grid container justify="center">
                        <TextField
                          id="standard-with-placeholder"
                          label="Credit Term "
                          name="creditTerm"
                          onChange={this.onChanged}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          placeholder=""
                          style={textFieldStyle}
                          margin="normal"
                        />
                        <TextField
                          id="standard-with-placeholder"
                          label="Other Reference"
                          name="otherReference"
                          onChange={this.onChanged}
                          placeholder=""
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          style={textFieldStyle}
                          margin="normal"
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs>
                      <Grid
                        container
                        justify="center"
                        style={{ marginTop: "25px" }}
                      >
                        <h6>Bank Address</h6>
                      </Grid>

                      <Grid container justify="center">
                        <TextField
                          id="standard-with-placeholder"
                          label="Country"
                          name="bankCountry"
                          onChange={this.onChanged}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          placeholder=""
                          style={textFieldStyle}
                          margin="normal"
                        />
                        <TextField
                          id="standard-with-placeholder"
                          label="State"
                          name="bankState"
                          onChange={this.onChanged}
                          placeholder=""
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          style={textFieldStyle}
                          margin="normal"
                        />
                      </Grid>
                      <Grid container justify="center">
                        <TextField
                          id="standard-with-placeholder"
                          label="City"
                          name="bankCity"
                          onChange={this.onChanged}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          placeholder=""
                          style={textFieldStyle}
                          margin="normal"
                        />
                        <TextField
                          id="standard-with-placeholder"
                          label="Street"
                          name="bankStreet"
                          onChange={this.onChanged}
                          placeholder=""
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          style={textFieldStyle}
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    <Grid container justify="center">
                      <Button
                        variant="outlined"
                        size="large"
                        color="primary"
                        onClick={this.handleSubmit}
                        style={{
                          margin: "20px",
                          marginTop: "45px",
                          marginRight: "15px"
                        }}
                      >
                        Add Supplier
                      </Button>
                      <Button
                        variant="outlined"
                        size="large"
                        color="primary"
                        onClick={handleClose}
                        style={{
                          margin: "20px",
                          marginTop: "45px",
                          marginRight: "25px"
                        }}
                      >
                        Close
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  }
}

export default AddSupplierModal;
