import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import {
  Menu,
  MenuItem,
  Modal,
  Backdrop,
  Fade,
  Card,
  CardContent,
  Grid,
  FormControl,
  TextField,
  withStyles,
  Button,
  IconButton,
} from "@material-ui/core";
import Axios from "axios";
// import { Dropdown } from "semantic-ui-react";
import UserContext from "../../utils/UserContext";
import StateCheckbox from "./StateCheckbox";
import ConfirmationDialog from "./ConfirmationDialog";
import Close from "@material-ui/icons/Close";

const styles = (theme) => ({
  cardHeight: {
    minWidth: "300px",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      height: "85%",
      width: "90%",
    },
    [theme.breakpoints.up("md")]: {
      height: "85%",
      width: "90%",
    },
    [theme.breakpoints.up("lg")]: {
      height: "95%",
      width: "90%",
      maxHeight: "1150px",
      maxWidth: "1270px",
    },
  },
});

class ViewMoreButton extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    var routeList = {
      allAdmin: [
        "Company Master",
        "User Administration",
        "Role Administration",
        "Authorization Master",
        "Document Numbering Scheme",
        "Budget Code Master",
        "Department Master",
        "Period Master",
      ],
      allMaster: [
        "Catalogue Master",
        "Inventory Master",
        "Account Master",
        "Manufacturer Master",
        "Supplier Master",
        "Customer Master",
        "Member Master",
        "Item Master",
        "Scheme Master",
        "Voucher Master",
        "Opening Balances",
        "Budget Master",
      ],
      allTransaction: [
        "Sales",
        "Purchase",
        "Sales Return",
        "Purchase Return",
        "Replacement",
        "Sales Order",
        "Quotation",
      ],
      allSCM: [
        "Purchase Request",
        "Sourcing",
        "Purchase Order",
        "Good Received Note",
        "Good Issued Note",
      ],
      allInventory: [
        "Inventory Transaction Details",
        "Stock Card",
        "Inventory Physical Count",
        "Cost Trail",
        "Closing Stock Value",
        "Inventory CF",
      ],
      allAccounts: [
        "Chart Of Accounts",
        "Received Voucher",
        "Payment Voucher",
        "Journal Voucher",
        "Purchase Voucher",
        "Sales Voucher",
        "Contra Voucher",
        "Bank Reconciliation",
        "Debit Note Voucher",
        "Credit Note Voucher",
        "Depreciation Voucher",
        "Batch Input Voucher"
      ],
      allReports: [
        "Managing Invoices",
        "Books of Account",
        "Assets Register",
        "Transaction Details",
        "Trial Balance",
        "Profit and Loss Account",
        "Balance Sheet",
        "Fund Accountability",
      ],
    };

    var checkAll = [
      ...routeList.allAdmin,
      ...routeList.allMaster,
      ...routeList.allTransaction,
      ...routeList.allSCM,
      ...routeList.allInventory,
      ...routeList.allAccounts,
      ...routeList.allReports,
    ];

    var toCheck = {};

    checkAll.forEach((element) => {
      toCheck[element] = false;
    });

    this.state = {
      open: false,
      anchorEl: null,
      modalOpen: false,
      data: {
        type: "",
        roleTasks: [],
      },
      id: 0,
      checkStates: toCheck,
      //FIXME: refactor in global route list
      routeList: routeList,
      editable: false,
      confirmOpen: false,
    };
  }

  componentDidMount() {
    this.getRoleData();
  }

  getRoleData = () => {
    var rowData = this.props.rowData;

    var roleTaskArray = [];

    var checkAllStatus = this.state.checkStates;

    rowData.roleTasks.forEach((element) => {
      checkAllStatus[element.type] = true;
      roleTaskArray.push(element.type);
    });

    //for api
    this.setState((prevState) => ({
      ...prevState,
      data: {
        type: rowData.type,
        roleTasks: roleTaskArray,
      },
      id: rowData.id,
    }));

    //for render
    this.setState((prevState) => ({
      ...prevState,
      checkStates: checkAllStatus,
    }));
  };

  handleDelete = () => {
    const token = this.context;

    Axios.delete("role/delete/" + this.state.id)
      .then((res) => {
        console.log(res);
        this.props.populateData();

        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000,
        };

        token.setSnackbarProps({ snackbarProps: snackbarProps });
      })
      .catch((err) => {
        console.log(err);
        var message = err.message;
        if (err.response !== undefined) {
          message = err.response.data;
        }
        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000,
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });
      });
  };

  handleSubmit = () => {
    const token = this.context;

    var objs = [];

    this.state.data.roleTasks.forEach((element) => {
      var item = {
        type: element,
      };

      objs.push(item);
    });

    var toSave = {
      type: this.state.data.type,
      roleTasks: objs,
    };

    console.log(toSave);

    Axios.put("role/update/" + this.state.id, toSave)
      .then((res) => {
        console.log(res);
        this.props.populateData();
        this.handleModalClose();

        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000,
        };

        token.setSnackbarProps({ snackbarProps: snackbarProps });
      })
      .catch((err) => {
        console.log(err);
        var message = err.message;
        if (err.response !== undefined) {
          message = err.response.data;
        }
        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000,
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });
      });
  };

  handleAllRoutesCheck = (e, x) => {
    var checkStates = [];
    var checkAllStatus = {};

    if (x) {
      checkStates = [
        ...this.state.routeList.allAdmin,
        ...this.state.routeList.allMaster,
        ...this.state.routeList.allTransaction,
        ...this.state.routeList.allSCM,
        ...this.state.routeList.allInventory,
        ...this.state.routeList.allAccounts,
        ...this.state.routeList.allReports,
      ];

      checkStates.forEach((element) => {
        checkAllStatus[element] = true;
      });

      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          roleTasks: checkStates,
        },
        checkStates: checkAllStatus,
      }));
    } else {
      checkStates = [
        ...this.state.routeList.allAdmin,
        ...this.state.routeList.allMaster,
        ...this.state.routeList.allTransaction,
        ...this.state.routeList.allSCM,
        ...this.state.routeList.allInventory,
        ...this.state.routeList.allAccounts,
        ...this.state.routeList.allReports,
      ];

      checkStates.forEach((element) => {
        checkAllStatus[element] = false;
      });

      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          roleTasks: [],
        },
        checkStates: checkAllStatus,
      }));
    }
  };

  handleAllCheck = (e, x) => {
    var name = e.target.name;

    var checkStates = [];
    var checkAllStatus = this.state.checkStates;

    if (x) {
      checkStates = [...this.state.routeList[name]];

      checkStates.forEach((element) => {
        checkAllStatus[element] = true;
      });

      var toSave = [...new Set([...checkStates, ...this.state.data.roleTasks])];

      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          roleTasks: toSave,
        },
        checkStates: checkAllStatus,
      }));
    } else {
      checkStates = [...this.state.routeList[name]];

      checkStates.forEach((element) => {
        checkAllStatus[element] = false;
      });

      var saveDelete = this.state.data.roleTasks.filter(function (el) {
        return !checkStates.includes(el);
      });

      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          roleTasks: saveDelete,
        },
        checkStates: checkAllStatus,
      }));
    }
  };

  onChanged = (e) => {
    e.stopPropagation();
    console.log(e.target.value);

    var value = e.target.value;

    if (this.state.editable) {
      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          type: value,
        },
      }));
    }
  };

  onChecked = (e, x) => {
    var checkStates = this.state.data.roleTasks;

    var len = checkStates.length;

    var checkAllStatus = {};

    if (x) {
      checkStates.push(e.target.name);

      checkAllStatus = { ...this.state.checkStates, [e.target.name]: true };
    } else {
      for (var i = 0; i < len; i++) {
        if (checkStates[i] === e.target.name) {
          checkStates.splice(i, 1);
        }
      }

      checkAllStatus = { ...this.state.checkStates, [e.target.name]: false };
    }

    checkStates = [...new Set(checkStates)];

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        roleTasks: checkStates,
      },
      checkStates: checkAllStatus,
    }));
  };

  handleView = () => {
    this.setState({ anchorEl: null, open: false });
    this.setState({ modalOpen: true, editable: false });
    this.getRoleData();
  };

  handleClick = (event) => {

    console.log("props bata aayeko")
    console.log(event.currentTarget)

    this.setState({
      anchorEl: event.currentTarget,
      open: Boolean(event.currentTarget),
    });
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, open: false });
  };

  render() {
    const modal = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };

    const textFieldStyle = {
      width: "100%",
      minWidth: "263px",
      fontSize: 11,
    };

    const inputStyles = {
      fontSize: 11,
    };

    const { classes } = this.props;

    return (
      <div>
        {console.log("::" + this.state.editable)}
        <span>
          <FontAwesomeIcon
            icon={faEllipsisV}
            onClick={this.handleClick}
            size="2x"
            style={{ cursor: "pointer" }}
          ></FontAwesomeIcon>
        </span>
        <ConfirmationDialog
          open={this.state.confirmOpen}
          handleClose={() => {
            this.setState({ confirmOpen: false });
          }}
          handleConfirm={this.handleDelete}
          titleMessage={"Terminate Role?"}
          bodyMessage={
            "Role will be terminated and won't be available for use with available users. Role will not be terminated if it is currently in use."
          }
        />
        <Menu
          id="long-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={this.state.open}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              width: 200,
            },
          }}
        >
          <MenuItem key={1} onClick={this.handleView}>
            View
          </MenuItem>
          <MenuItem
            key={3}
            onClick={() => {
              this.setState({ confirmOpen: true });
            }}
          >
            <span style={{ color: "#FF5733" }}>Terminate</span>
          </MenuItem>
        </Menu>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          open={this.state.modalOpen}
          onClose={this.handleModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.modalOpen}>
            <Card className={classes.cardHeight}>
              <CardContent style={{ padding: "0px", margin: "auto" }}>
                <Grid container>
                  <Grid container justify="flex-end">
                    <IconButton onClick={this.handleModalClose}>
                      <Close />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container style={{ padding: "20px" }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={7} md={8}>
                      <TextField
                        id="standard-with-placeholder"
                        variant="filled"
                        name="roleName"
                        value={this.state.data.type}
                        onChange={this.onChanged}
                        style={textFieldStyle}
                        inputProps={{ style: inputStyles }}
                        InputLabelProps={{ style: inputStyles }}
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={6} sm={2} md={2}>
                      <Button
                        onClick={() => {
                          this.setState({ editable: true });
                        }}
                        variant="contained"
                        size="large"
                        color="primary"
                        style={{
                          height: "40px",
                          marginTop: "10px",
                          width: "100%",
                          fontSize: "13px",
                        }}
                      >
                        Edit
                      </Button>
                    </Grid>
                    <Grid item xs={6} sm={3} md={2}>
                      {this.state.editable ? (
                        <Button
                          onClick={this.handleSubmit}
                          variant="contained"
                          size="large"
                          color="primary"
                          style={{
                            height: "40px",
                            marginTop: "10px",
                            width: "100%",
                            minWidth: "140px",
                            fontSize: "13px",
                          }}
                        >
                          Update Role
                        </Button>
                      ) : (
                          <Button
                            onClick={this.handleSubmit}
                            variant="contained"
                            size="large"
                            disabled
                            color="primary"
                            style={{
                              height: "40px",
                              fontSize: "13px",
                              marginTop: "10px",
                              width: "100%",
                              minWidth: "135px",
                            }}
                          >
                            Update Role
                          </Button>
                        )}
                    </Grid>
                  </Grid>

                  <Grid container style={{ marginTop: "10px" }}>
                    <FormControl>
                      <StateCheckbox
                        label={"Select All Routes"}
                        editable={this.state.editable}
                        onChange={this.handleAllRoutesCheck}
                        name=""
                      />
                    </FormControl>
                  </Grid>

                  <Grid container style={{ margin: "auto" }}>
                    <Grid item>
                      <Grid container style={{ marginRight: "2px" }}>
                        <FormControl>
                          <StateCheckbox
                            label={"Select Administration Route"}
                            editable={this.state.editable}
                            onChange={this.handleAllCheck}
                            name="allAdmin"
                          />

                          {this.state.routeList.allAdmin.map((value) => (
                            <Grid container key={value}>
                              <StateCheckbox
                                label={value}
                                editable={this.state.editable}
                                onChange={this.onChecked}
                                name={value}
                                checked={this.state.checkStates[value]}
                              />
                            </Grid>
                          ))}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container style={{ marginRight: "2px" }}>
                        <FormControl>
                          <StateCheckbox
                            label={"Select Master Route"}
                            editable={this.state.editable}
                            onChange={this.handleAllCheck}
                            name="allMaster"
                          />

                          {/* <FormLabel component="legend">Master Route</FormLabel> */}
                          {this.state.routeList.allMaster.map((value) => (
                            <Grid container key={value}>
                              <StateCheckbox
                                label={value}
                                editable={this.state.editable}
                                onChange={this.onChecked}
                                name={value}
                                checked={this.state.checkStates[value]}
                              />
                            </Grid>
                          ))}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container style={{ marginRight: "2px" }}>
                        <FormControl>
                          <StateCheckbox
                            label={"Select Transaction Route"}
                            editable={this.state.editable}
                            onChange={this.handleAllCheck}
                            name="allTransaction"
                          />

                          {/* <FormLabel component="legend">
                            Transaction Route
                          </FormLabel> */}
                          {this.state.routeList.allTransaction.map((value) => (
                            <Grid container>
                              <StateCheckbox
                                label={value}
                                editable={this.state.editable}
                                onChange={this.onChecked}
                                name={value}
                                checked={this.state.checkStates[value]}
                              />
                            </Grid>
                          ))}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container style={{ marginRight: "2px" }}>
                        <FormControl>
                          <StateCheckbox
                            label={"Select SCM Route"}
                            editable={this.state.editable}
                            onChange={this.handleAllCheck}
                            name="allSCM"
                          />

                          {/* <FormLabel component="legend">
                            Transaction Route
                          </FormLabel> */}
                          {this.state.routeList.allSCM.map((value) => (
                            <Grid container>
                              <StateCheckbox
                                label={value}
                                editable={this.state.editable}
                                onChange={this.onChecked}
                                name={value}
                                checked={this.state.checkStates[value]}
                              />
                            </Grid>
                          ))}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container style={{ marginRight: "2px" }}>
                        <FormControl>
                          <StateCheckbox
                            label={"Select Inventory Route"}
                            editable={this.state.editable}
                            onChange={this.handleAllCheck}
                            name="allInventory"
                          />

                          {/* <FormLabel component="legend">
                            Inventory Route
                          </FormLabel> */}
                          {this.state.routeList.allInventory.map((value) => (
                            <Grid container>
                              <StateCheckbox
                                label={value}
                                editable={this.state.editable}
                                onChange={this.onChecked}
                                name={value}
                                checked={this.state.checkStates[value]}
                              />
                            </Grid>
                          ))}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container style={{ marginRight: "2px" }}>
                        <FormControl>
                          <StateCheckbox
                            label={"Select Accounts Route"}
                            editable={this.state.editable}
                            onChange={this.handleAllCheck}
                            name="allAccounts"
                          />

                          {/* <FormLabel component="legend">
                            Accounts Route
                          </FormLabel> */}
                          {this.state.routeList.allAccounts.map((value) => (
                            <Grid container>
                              <StateCheckbox
                                label={value}
                                editable={this.state.editable}
                                onChange={this.onChecked}
                                name={value}
                                checked={this.state.checkStates[value]}
                              />
                            </Grid>
                          ))}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container>
                        <FormControl>
                          <StateCheckbox
                            label={"Select Reports Route"}
                            editable={this.state.editable}
                            onChange={this.handleAllCheck}
                            name="allReports"
                          />

                          {/* <FormLabel component="legend">
                            Reports Route
                          </FormLabel> */}
                          {this.state.routeList.allReports.map((value) => (
                            <Grid container>
                              <StateCheckbox
                                label={value}
                                editable={this.state.editable}
                                onChange={this.onChecked}
                                name={value}
                                checked={this.state.checkStates[value]}
                              />
                            </Grid>
                          ))}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Fade>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(ViewMoreButton);
