import React, { Component } from "react";
import {
  Grid,
  withStyles,
  Card,
  CardContent,
  Button,
  Typography,
  TextField,
  MenuItem,
} from "@material-ui/core";
import Axios from "axios";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import UserContext from "../../utils/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
import ViewGoodsIssuedButton from "../InterfaceElements/ViewGoodsIssuedButton";

const styles = (theme) => ({
  root: {
    padding: 10,
    paddingTop: "5px",
    paddingBottom: "5px",
    width: "100%",
    height: "70vh",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "170px",
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
    },
  },
});

class GoodsIssuedNoteAuthorization extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    const rowIndex = (params) => params.node.rowIndex + 1;
    this.state = {
      periodList: [],
      fyList: [],
      currentPeriod: "",
      fiscalYear: "",
      resData: [],
      columnDefs: [
        {
          headerName: "",
          headerCheckboxSelection: true,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: this.isFirstColumn,
          checkboxSelection: this.isFirstColumn,
          minWidth: 40,
          width: 40,
        },
        {
          headerName: "Sn",
          field: "sn",
          sortable: true,
          resizable: true,
          valueGetter: rowIndex,
          minWidth: 50,
          width: 50,
        },
        {
          headerName: "GIN No.",
          field: "ginNumber",
          sortable: true,
          resizable: true,
          minWidth: 125,
        },
        {
          headerName: "GIN Date",
          field: "ginDate",
          sortable: true,
          resizable: true,
          minWidth: 100,
          width: 100,
        },
        {
          headerName: "SO Number",
          field: "salesOrderNumber",
          sortable: true,
          resizable: true,
          minWidth: 100,
          width: 100,
        },
        {
          headerName: "Issued To",
          field: "issuedTo",
          resizable: true,
          sortable: true,
          minWidth: 130,
          width: 130,
        },
        {
          headerName: "Sold By",
          field: "soldByUser",
          sortable: true,
          resizable: true,
          minWidth: 130,
          width: 130,
        },
        {
          headerName: "Invoice No.",
          field: "invoiceNumber",
          resizable: true,
          sortable: true,
          minWidth: 115,
          width: 115,
        },
        {
          headerName: "Status",
          field: "status",
          resizable: true,
          sortable: true,
          minWidth: 90,
          width: 90,
        },
        {
          headerName: "Grand Total",
          field: "grandTotal",
          resizable: true,
          sortable: true,
          minWidth: 120,
          width: 120,
        },
        {
          headerName: "View",
          cellRendererFramework: ViewGoodsIssuedButton,
          cellRendererParams: {
            showSnackbar: this.showSnackbar,
            getGINData: this.props.getGinDataForAuth,
            getListData: this.getListData,
          },
          minWidth: 53,
          width: 53,
        },
      ],
      rowData: [],
      rowDataOriginal: [],
      rowSelection: "multiple",
      rowAllData: [],
      ids: [],
      checkIds: [],
      approveIds: [],
      pendingNum: 0,
      approveNum: 0,
      comment: "",
      dateFrom: "",
      dateTo: "",
      filters: {
        checkbox: true,
      },
      maxNumber: 0,
      commentError: false,
      overlayLoadingTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
      overlayNoRowsTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
    };
  }

  isFirstColumn = (params) => {
    var displayedColumns = params.columnApi.getAllDisplayedColumns();
    var thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  };

  resetState() {
    this.setState((prevState) => ({
      ...prevState,
      ids: [],
      checkIds: [],
      approveIds: [],
      pendingNum: 0,
      approveNum: 0,
      comment: "",
    }));
  }

  // getGINData = () => {
  //   Axios.get("pos/ginMaster/findAllDesc")
  //     .then(res => {
  //       var rows = [];
  //       res.data.forEach(element => {
  //         const data = {
  //           id: element.id,
  //           ginNumber: element.ginNumber,
  //           ginDate: element.ginDate,
  //           soNumber: element.salesOrderNumber,
  //           issuedTo: element.issuedTo,
  //           soldBy: element.soldByUser,
  //           invoiceNumber: element.invoiceNumber,
  //           status: element.status,
  //           grandTotal: element.grandTotal
  //         };
  //         rows.push(data);
  //         this.setState({ maxNumber: element.invoiceNumber });
  //       });

  //       this.gridApi.sizeColumnsToFit();

  //       this.setState({ rowData: rows });
  //       this.setState({ rowDataOriginal: rows });

  //       this.setState({
  //         overlayNoRowsTemplate: "<span>No rows to show</span>"
  //       });
  //       try {
  //         this.gridApi.hideOverlay();
  //         if (res.data.length === 0) this.gridApi.showNoRowsOverlay();
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     })
  //     .catch(err => {
  //       console.log(err);
  //       this.setState({
  //         overlayNoRowsTemplate: "<span>No rows to show</span>"
  //       });
  //       try {
  //         this.gridApi.hideOverlay();
  //         this.gridApi.showNoRowsOverlay();
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     });

  //   var today = new Date();
  //   var month = (today.getMonth() + 1).toString();
  //   var day = today.getDate().toString();
  //   if (month.length === 1) {
  //     month = "0" + month;
  //   }
  //   if (day.length === 1) {
  //     day = "0" + day;
  //   }
  //   var date = today.getFullYear() + "-" + month + "-" + day;

  //   this.setState({ dateTo: date, dateFrom: date });
  // };

  /* componentDidMount() {
    this.getAllPeriodListing();
    this.getMaxNumber();

    var today = new Date();
    var month = (today.getMonth() + 1).toString();
    var day = today.getDate().toString();
    if (month.length === 1) {
      month = "0" + month;
    }
    if (day.length === 1) {
      day = "0" + day;
    }
    var date = today.getFullYear() + "-" + month + "-" + day;

    this.setState({ dateTo: date, dateFrom: date });
  }
  
  getMaxNumber = () => {
    Axios.get("/pos/ginMaster/findAllDesc")
      .then((res) => {
        console.log("max");
        console.log(res.data);

        res.data.forEach((element) => {
          this.setState({ maxNumber: element.invoiceNumber });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
 */
  componentDidMount() {
    this.getAllPeriodListing();
  }

  getAllPeriodListing = () => {
    Axios.get("/fy/getAllActivePeriods")
      .then(async (res) => {
        var fiscalYearLists = [];
        res.data.forEach((element) => {
          var fy = Object.keys(element);

          fiscalYearLists.push(fy[0]);
        });
        await this.setState({
          fyList: fiscalYearLists,
          resData: res.data,
        });

        this.getCurrentPeriod();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCurrentPeriod() {
    Axios.get("/fy/getCurrentPeriod")
      .then(async (res) => {
        await this.setState((prevState) => ({
          ...prevState,
          fiscalYear: res.data.fiscalYear,
          currentPeriod: res.data.name,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleFYChange = async (e) => {
    let Fy = e.target.value;
    await this.setState((prevState) => ({
      ...prevState,
      fiscalYear: Fy,
    }));
  };

  getListData = () => {
    this.props.getListData();
  };

  showSnackbar = (snackbarProps) => {
    const token = this.context;

    token.setSnackbarProps({ snackbarProps: snackbarProps });
  };

  componentDidUpdate() {
    if (!this.gridApi) return;
    this.gridApi.sizeColumnsToFit();
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  onSelectionChanged = (params) => {
    var pendingNum = this.state.pendingNum;
    var approvedNum = this.state.approveNum;

    var checkArr = this.state.checkIds;
    var approvedArr = this.state.approveIds;
    if (params.node.selected === true) {
      if (params.data.status === "Pending") {
        pendingNum++;
        checkArr.push(params.data.id);
      } else {
        approvedNum++;
        approvedArr.push(params.data.id);
      }
    }
    if (params.node.selected === false) {
      if (params.data.status === "Pending") {
        pendingNum--;
        for (var i = 0; i < checkArr.length; i++) {
          if (checkArr[i] === params.data.id) {
            checkArr.splice(i, 1);
          }
        }
      } else {
        approvedNum--;

        for (var j = 0; j < approvedArr.length; j++) {
          if (approvedArr[j] === params.data.id) {
            approvedArr.splice(j, 1);
          }
        }
      }
    }

    this.setState({
      checkIds: checkArr,
      approveIds: approvedArr,
      pendingNum,
      approveNum: approvedNum,
    });
  };

  onChanged = (e) => {
    e.stopPropagation();

    var targetName = e.target.name;
    var value = e.target.value;

    if (targetName === "comment" && value !== "") {
      this.setState({ commentError: false });
    }

    this.setState((prevState) => ({
      comment: value,
    }));
  };

  /*  getTodayDate() {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return year + "-" + month + "-" + date;
  }

  handleInvoiceTo = (e) => {
    var targetName = e.target.name;
    var value = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      [targetName]: value,
    }));

    if (e.target.value < 0) {
      e.target.value = 0;
    }

    if (e.target.value > this.state.maxNumber) {
      e.target.value = this.state.maxNumber;
    }

    this.props.handleInvoiceTo(e.target.value);
  };

  handleInvoiceFrom = (e) => {
    console.log(e.target.value);

    var targetName = e.target.name;
    var value = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      [targetName]: value,
    }));

    if (e.target.value < 0) {
      e.target.value = 0;
    }

    if (e.target.value > this.state.maxNumber) {
      e.target.value = this.state.maxNumber;
    }

    this.props.handleInvoiceFrom(e.target.value);
  };

  handleMinimumAmount = (e) => {
    var targetName = e.target.name;
    var value = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      [targetName]: value,
    }));

    this.props.handleMinimumAmount(e.target.value);
  };

  handleMaximumAmount = (e) => {
    var targetName = e.target.name;
    var value = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      [targetName]: value,
    }));

    this.props.handleMaximumAmount(e.target.value);
  }; */

  handleSubmit = (targetName) => {
    var arr = [];

    if (targetName === "Rejected") {
      arr = [...this.state.checkIds, ...this.state.approveIds];
    } else if (targetName === "Approved") {
      arr = this.state.approveIds;
    } else {
      arr = this.state.checkIds;
    }

    var obj = { ids: arr, comment: this.state.comment };

    if (targetName === "Checked") {
      Axios.put("pos/ginMaster/updateByIds/Checked", obj)
        .then((res) => {
          console.log(res);

          var snackbarProps = {
            open: true,
            variant: "success",
            message: res.data,
            autoHideDuration: 2000,
          };
          this.showSnackbar(snackbarProps);
          this.resetState();
          this.props.getGinDataForAuth();
          this.props.getListData();
        })
        .catch((err) => {
          var message = err.message;
          if (err.response !== undefined) {
            message = err.response.data;
          }

          var snackbarProps = {
            open: true,
            variant: "error",
            message: message,
            autoHideDuration: 2000,
          };
          this.showSnackbar(snackbarProps);
        });
    } else if (targetName === "Approved") {
      Axios.put("pos/ginMaster/updateByIds/Approved", obj)
        .then((res) => {
          console.log(res);
          var snackbarProps = {
            open: true,
            variant: "success",
            message: res.data,
            autoHideDuration: 2000,
          };

          this.showSnackbar(snackbarProps);
          this.props.getGinDataForAuth();
          this.resetState();
          this.props.getListData();
        })
        .catch((err) => {
          console.log(err);
          var message = err.message;
          if (err.response !== undefined) {
            message = err.response.data;
          }

          var snackbarProps = {
            open: true,
            variant: "error",
            message: message,
            autoHideDuration: 2000,
          };
          this.showSnackbar(snackbarProps);
        });
    } else if (targetName === "Rejected") {
      if (obj.ids == null || obj.comment === "") {
        this.setState({ commentError: true });
      } else {
        Axios.put("pos/ginMaster/updateByIds/Rejected", obj)
          .then((res) => {
            console.log(res);
            var snackbarProps = {
              open: true,
              variant: "success",
              message: res.data,
              autoHideDuration: 2000,
            };

            this.showSnackbar(snackbarProps);
            this.props.getGinDataForAuth();
            this.resetState();
            this.props.getListData();
          })
          .catch((err) => {
            console.log(err);
            var message = err.message;
            if (err.response !== undefined) {
              message = err.response.data;
            }
            var snackbarProps = {
              open: true,
              variant: "error",
              message: message,
              autoHideDuration: 2000,
            };
            this.showSnackbar(snackbarProps);
          });
      }
    }
  };

  /* handleDateFrom = (event) => {
    var formattedDate = event.target.value;

    this.setState({ dateFrom: formattedDate });

    this.props.handleDateFrom(formattedDate, this.state.dateFrom);
  };

  handleDateTo = (event) => {
    var formattedDate = event.target.value;

    this.setState({ dateTo: formattedDate });

    this.props.handleDateTo(formattedDate, this.state.dateTo);
  }; */

  render() {
    const { classes, index, value, ...other } = this.props;
    const inputStyles = {
      fontSize: 14,
      width: "140px",
    };

    const textFieldStyle = {
      width: "100%",
    };

    const buttonStyle = {
      width: "220px",
      marginTop: "25px",
      marginBottom: "10px",
      paddingTop: "15px",
      paddingBottom: "15px",
      paddingRight: "50px",
      paddingLeft: "50px",
    };

    var today = new Date();
    var month = (today.getMonth() + 1).toString();
    var day = today.getDate().toString();
    if (month.length === 1) {
      month = "0" + month;
    }
    if (day.length === 1) {
      day = "0" + day;
    }
    var date = today.getFullYear() + "-" + month + "-" + day;

    return (
      <div style={{ backgroundColor: "#EAEDEF" }}>
        <Grid container>
          <Card
            className={classes.root}
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            style={{}}
            {...other}
          >
            <CardContent>
              <Grid
                container
                spacing={1}
                justify="space-between"
                style={{ margin: "auto" }}
              >
                <Grid item xs>
                  <TextField
                    select
                    style={{ minWidth: "100px" }}
                    label="Fiscal Year"
                    value={this.state.fiscalYear}
                  >
                    {this.state.fyList.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                    /*  onClick={() => {
                      this.setState({ filterShow: !this.state.filterShow });
                    }} */
                  >
                    <Typography variant="button">
                      <FontAwesomeIcon
                        icon={faSlidersH}
                        style={{ marginRight: "5px" }}
                      ></FontAwesomeIcon>
                      Filter
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
              {/*  {this.state.filterShow ? (
                <Grid
                  container
                  style={{
                    marginTop: "0px",
                    border: "#87CEFA 2px solid",
                    borderRadius: "10px",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      marginLeft: "2em",
                      display: "inline",
                      backgroundColor: "#fff",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      paddingBottom: "15px",
                    }}
                  ></div>
                  <Grid
                    container
                    spacing={2}
                    xs={12}
                    style={{ margin: "auto" }}
                  >
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                      <div
                        style={{
                          minWidth: "160px",
                          marginTop: "normal",
                          marginBottom: "25px",
                        }}
                      >
                        <TextField
                          id="standard-with-placeholder"
                          label="From Invoice Number"
                          placeholder=""
                          type="number"
                          variant="outlined"
                          name="invoiceFrom"
                          value={this.state.invoiceFrom}
                          onChange={this.handleInvoiceFrom}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          style={textFieldStyle}
                          margin="dense"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                      <div
                        style={{
                          minWidth: "160px",
                          marginTop: "normal",
                          marginBottom: "25px",
                        }}
                      >
                        <TextField
                          id="standard-with-placeholder"
                          label="To Invoice Number"
                          placeholder=""
                          type="number"
                          name="invoiceTo"
                          variant="outlined"
                          value={this.state.invoiceTo}
                          onChange={this.handleInvoiceTo}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          style={textFieldStyle}
                          margin="dense"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                      <div
                        style={{
                          minWidth: "160px",
                          marginTop: "normal",
                          marginBottom: "25px",
                        }}
                      >
                        <TextField
                          id="standard-with-placeholder"
                          label="Minumum Amount"
                          name="minimumAmount"
                          value={this.state.minimumAmount}
                          placeholder=""
                          type="number"
                          variant="outlined"
                          onChange={this.handleMinimumAmount}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          style={textFieldStyle}
                          margin="dense"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                      <div
                        style={{
                          minWidth: "160px",
                          marginTop: "normal",
                          marginBottom: "25px",
                        }}
                      >
                        <TextField
                          id="standard-with-placeholder"
                          label="Maximum Amount"
                          placeholder=""
                          name="maximumAmount"
                          value={this.state.maximumAmount}
                          type="number"
                          variant="outlined"
                          onChange={this.handleMaximumAmount}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          style={textFieldStyle}
                          margin="dense"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                      <div
                        style={{
                          minWidth: "160px",
                          marginTop: "normal",
                          marginBottom: "25px",
                        }}
                      >
                        <TextField
                          id="standard-with-placeholder"
                          label="Date From"
                          placeholder=""
                          type="date"
                          variant="outlined"
                          name="dateFrom"
                          value={this.state.dateFrom}
                          onChange={this.handleDateFrom}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          style={textFieldStyle}
                          margin="dense"
                          defaultValue={date}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                      <div
                        style={{
                          minWidth: "160px",
                          marginTop: "normal",
                          marginBottom: "25px",
                        }}
                      >
                        <TextField
                          id="standard-with-placeholder"
                          label="Date To"
                          placeholder=""
                          type="date"
                          name="dateTo"
                          value={this.state.dateTo}
                          variant="outlined"
                          onChange={this.handleDateTo}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          style={textFieldStyle}
                          margin="dense"
                          defaultValue={date}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null} */}

              <Grid container justify="center">
                <div
                  className="ag-theme-balham"
                  style={{
                    height: "31vh",
                    width: "100%",
                    marginTop: "20px",
                    marginBottom: "10px",
                  }}
                >
                  <AgGridReact
                    columnDefs={this.state.columnDefs}
                    rowData={this.props.data}
                    animateRows={true}
                    rowSelection={this.state.rowSelection}
                    onRowSelected={this.onSelectionChanged}
                    onGridReady={this.onGridReady}
                    overlayLoadingTemplate={this.state.overlayLoadingTemplate}
                    overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                  ></AgGridReact>
                </div>
              </Grid>

              <Grid container justify="center">
                {this.state.commentError ? (
                  <TextField
                    id="standard-with-placeholder"
                    label="Comment"
                    name="comment"
                    variant="outlined"
                    error
                    helperText="Comment field is required"
                    fullWidth
                    onChange={this.onChanged}
                    value={this.state.comment}
                    style={{ marginLeft: "0px", marginRight: "0px" }}
                    margin="normal"
                  />
                ) : (
                  <TextField
                    id="standard-with-placeholder"
                    label="Comment"
                    name="comment"
                    variant="outlined"
                    fullWidth
                    onChange={this.onChanged}
                    value={this.state.comment}
                    style={{ margin: "auto" }}
                    margin="normal"
                  />
                )}
              </Grid>

              <Grid
                container
                spacing={2}
                style={{ width: "70%", margin: "auto" }}
                justify="center"
              >
                <Grid item xs={12} md={4} sm={5}>
                  <Grid container justify="center">
                    <Button
                      variant="outlined"
                      size="large"
                      color="inherit"
                      onClick={() => {
                        this.handleSubmit("Checked");
                      }}
                      style={buttonStyle}
                    >
                      Check ({this.state.pendingNum})
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4} sm={5}>
                  <Grid container justify="center">
                    <Button
                      variant="outlined"
                      size="large"
                      color="primary"
                      onClick={() => {
                        this.handleSubmit("Approved");
                      }}
                      style={buttonStyle}
                    >
                      Approve ({this.state.approveNum})
                    </Button>
                  </Grid>
                </Grid>
                {/*  <Grid item xs={12} md={4} sm={5}>
                  <Grid container justify="center">
                    <Button
                      variant="outlined"
                      size="large"
                      color="secondary"
                      onClick={() => {
                        this.handleSubmit("Rejected");
                      }}
                      style={buttonStyle}
                    >
                      Reject
                    </Button>
                  </Grid>
                </Grid> */}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(GoodsIssuedNoteAuthorization);
