import React, { Component } from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  TextField,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Checkbox,
  Button,
  withStyles,
} from "@material-ui/core";
import Axios from "axios";
import UserContext from "../../utils/UserContext";

const styles = (theme) => ({
  root: {
    padding: 10,
    paddingTop: "7px",
    paddingBottom: "7px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "170px",
      marginRight: "10px",
      marginLeft: "10px",
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
    },
  },
});

class AddRole extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    var routeList = {
      allAdmin: [
        "Company Master",
        "User Administration",
        "Role Administration",
        "Authorization Master",
        "Document Numbering Scheme",
        "Budget Code Master",
        "Department Master",
        "Period Master",
      ],
      allMaster: [
        "Catalogue Master",
        "Inventory Master",
        "Account Master",
        "Manufacturer Master",
        "Supplier Master",
        "Customer Master",
        "Member Master",
        "Item Master",
        "Scheme Master",
        "Voucher Master",
        "Opening Balances",
        "Budget Master",
      ],
      allTransaction: [
        "Sales",
        "Purchase",
        "Sales Return",
        "Purchase Return",
        "Replacement",
        "Sales Order",
        "Quotation",
      ],
      allSCM: [
        "Purchase Request",
        "Sourcing",
        "Purchase Order",
        "Good Received Note",
        "Good Issued Note",
      ],
      allInventory: [
        "Inventory Transaction Details",
        "Stock Card",
        "Inventory Physical Count",
        "Cost Trail",
        "Closing Stock Value",
        "Inventory CF",
      ],
      allAccounts: [
        "Chart Of Accounts",
        "Received Voucher",
        "Payment Voucher",
        "Journal Voucher",
        "Purchase Voucher",
        "Sales Voucher",
        "Contra Voucher",
        "Bank Reconciliation",
        "Debit Note Voucher",
        "Credit Note Voucher",
        "Depreciation Voucher",
        "Batch Input Voucher"
      ],
      allReports: [
        "Managing Invoices",
        "Books of Account",
        "Assets Register",
        "Transaction Details",
        "Trial Balance",
        "Profit and Loss Account",
        "Balance Sheet",
        "Fund Accountability",
      ],
    };

    var checkAll = [
      ...routeList.allAdmin,
      ...routeList.allMaster,
      ...routeList.allTransaction,
      ...routeList.allSCM,
      ...routeList.allInventory,
      ...routeList.allAccounts,
      ...routeList.allReports,
    ];

    var toCheck = {};

    checkAll.forEach((element) => {
      toCheck[element] = false;
    });

    this.state = {
      data: {
        type: "",
        roleTasks: [],
      },
      checkStates: toCheck,
      //FIXME: refactor in global route list
      routeList: routeList,
      rowRefs: [],
      allTypes: {
        allRoutes: false,
        allAdmin: false,
        allMaster: false,
        allTransaction: false,
        allSCM: false,
        allInventory: false,
        allReports: false,
        allAccounts: false,
      },
      roleNameError: false,
    };
  }

  handleScroll = () => {
    this.setState({
      scrollPixelsY: window.scrollY,
    });
  };

  componentDidMount() {
    var allRoutes = [
      ...this.state.routeList.allAdmin,
      ...this.state.routeList.allMaster,
      ...this.state.routeList.allTransaction,
      ...this.state.routeList.allSCM,
      ...this.state.routeList.allInventory,
      ...this.state.routeList.allAccounts,
      ...this.state.routeList.allReports,
    ];

    var rowRefs = [];

    for (let index = 0; index < allRoutes.length; index++) {
      var item = {
        key: index,
        value: allRoutes[index],
        text: allRoutes[index],
      };

      rowRefs.push(item);
    }

    this.setState({ rowRefs });
  }

  handleItemSelect = (event, data) => {
    const token = this.context;

    var element = document.getElementById("clickMe");
    element.style.color = "red";
    token.scrollWindow({ value: 1500 });
  };

  resetValues = () => {
    var checkAll = [
      ...this.state.routeList.allAdmin,
      ...this.state.routeList.allMaster,
      ...this.state.routeList.allTransaction,
      ...this.state.routeList.allSCM,
      ...this.state.routeList.allInventory,
      ...this.state.routeList.allAccounts,
      ...this.state.routeList.allReports,
    ];

    var toCheck = {};

    checkAll.forEach((element) => {
      toCheck[element] = false;
    });

    this.setState({
      data: {
        type: "",
        roleTasks: [],
      },
      checkStates: toCheck,
      allTypes: {
        allAdmin: false,
        allRoutes: false,
        allMaster: false,
        allTransaction: false,
        allSCM: false,
        allInventory: false,
        allReports: false,
        allAccounts: false,
      },
    });
  };

  handleAllRoutesCheck = (e, x) => {
    var checkStates = [];
    var checkAllStatus = {};

    if (x) {
      checkStates = [
        ...this.state.routeList.allAdmin,
        ...this.state.routeList.allMaster,
        ...this.state.routeList.allTransaction,
        ...this.state.routeList.allSCM,
        ...this.state.routeList.allInventory,
        ...this.state.routeList.allAccounts,
        ...this.state.routeList.allReports,
      ];

      checkStates.forEach((element) => {
        checkAllStatus[element] = true;
      });

      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          roleTasks: checkStates,
        },
        checkStates: checkAllStatus,
        allTypes: {
          ...prevState.allTypes,
          allRoutes: true,
        },
      }));
    } else {
      checkStates = [
        ...this.state.routeList.allAdmin,
        ...this.state.routeList.allMaster,
        ...this.state.routeList.allTransaction,
        ...this.state.routeList.allSCM,
        ...this.state.routeList.allInventory,
        ...this.state.routeList.allAccounts,
        ...this.state.routeList.allReports,
      ];

      checkStates.forEach((element) => {
        checkAllStatus[element] = false;
      });

      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          roleTasks: [],
        },
        checkStates: checkAllStatus,
        allTypes: {
          ...prevState.allTypes,
          allRoutes: false,
        },
      }));
    }
  };

  handleAllCheck = (e, x) => {
    var name = e.target.name;

    var checkStates = [];
    var checkAllStatus = this.state.checkStates;

    if (x) {
      checkStates = [...this.state.routeList[name]];

      checkStates.forEach((element) => {
        checkAllStatus[element] = true;
      });

      var toSave = [...new Set([...checkStates, ...this.state.data.roleTasks])];

      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          roleTasks: toSave,
        },
        checkStates: checkAllStatus,
        allTypes: {
          ...prevState.allTypes,
          [name]: true,
        },
      }));
    } else {
      checkStates = [...this.state.routeList[name]];

      checkStates.forEach((element) => {
        checkAllStatus[element] = false;
      });

      var saveDelete = this.state.data.roleTasks.filter(function (el) {
        return !checkStates.includes(el);
      });

      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          roleTasks: saveDelete,
        },
        checkStates: checkAllStatus,
        allTypes: {
          ...prevState.allTypes,
          [name]: false,
        },
      }));
    }
  };

  handleSubmit = () => {
    const token = this.context;
    var arr = [];

    this.state.data.roleTasks.forEach((element) => {
      var item = {
        type: element,
      };

      arr.push(item);
    });

    var obj = {
      type: this.state.data.type,
      roleTasks: arr,
    };

    if (this.state.data.type === "" || this.state.data.type === undefined) {
      this.setState({ roleNameError: true });
    } else {
      Axios.post("role/save", obj)
        .then((res) => {
          console.log(res);
          this.resetValues();
          var snackbarProps = {
            open: true,
            variant: "success",
            message: res.data,
            autoHideDuration: 2000,
          };

          token.setSnackbarProps({ snackbarProps: snackbarProps });
          this.props.populateData();
        })
        .catch((err) => {
          console.log(err);
          var message = err.message;
          if (err.response !== undefined) {
            message = err.response.data;
          }
          var snackbarProps = {
            open: true,
            variant: "error",
            message: message,
            autoHideDuration: 2000,
          };
          token.setSnackbarProps({ snackbarProps: snackbarProps });
        });
    }
  };

  onChanged = (e) => {
    e.stopPropagation();
    console.log(e.target.value);

    var value = e.target.value;
    if (value !== "") {
      this.setState({ roleNameError: false });
    }
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        type: value,
      },
    }));
  };

  onChecked = (e, x) => {
    var checkStates = this.state.data.roleTasks;

    var len = checkStates.length;

    var checkAllStatus = {};

    if (x) {
      checkStates.push(e.target.name);

      checkAllStatus = { ...this.state.checkStates, [e.target.name]: true };
    } else {
      for (var i = 0; i < len; i++) {
        if (checkStates[i] === e.target.name) {
          checkStates.splice(i, 1);
        }
      }

      checkAllStatus = { ...this.state.checkStates, [e.target.name]: false };
    }

    checkStates = [...new Set(checkStates)];

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        roleTasks: checkStates,
      },
      checkStates: checkAllStatus,
    }));
  };

  render() {
    const { children, value, index, classes, ...other } = this.props;

    const inputStyles = {
      fontSize: 13,
    };

    return (
      <div>
        <Grid container style={{ width: "100vw" }} justify="center">
          <Grid item xs={12} lg={6} md={7} sm={10}>
            <Card
              className={classes.root}
              role="tabpanel"
              hidden={value !== index}
              id={`vertical-tabpanel-${index}`}
              aria-labelledby={`vertical-tab-${index}`}
              {...other}
            >
              <CardContent>
                <Grid container>
                  <Typography
                    variant="h6"
                    component="h2"
                    style={{ fontSize: "18px" }}
                  >
                    Add Role
                  </Typography>
                </Grid>

                <Grid container>
                  <FormControl style={{ marginTop: "10px", width: "100%" }}>
                    <FormLabel component="legend" style={{ color: "#000" }}>
                      Role Name
                    </FormLabel>
                    <FormGroup>
                      {this.state.roleNameError ? (
                        <TextField
                          id="standard-with-placeholder"
                          variant="filled"
                          name="roleName"
                          fullWidth
                          error
                          helperText="Role Name cannot be empty"
                          value={this.state.data.type}
                          onChange={this.onChanged}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          margin="dense"
                        />
                      ) : (
                          <TextField
                            id="standard-with-placeholder"
                            variant="filled"
                            name="roleName"
                            fullWidth
                            value={this.state.data.type}
                            onChange={this.onChanged}
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            margin="dense"
                          />
                        )}
                    </FormGroup>
                  </FormControl>
                </Grid>

                {/* <Grid container style={{ marginTop: "25px" }}>
              <FormControl>
                <FormLabel component="legend" style={{ color: "#000" }}>
                  Search Permission
                </FormLabel>
                <FormGroup>
                  <div style={{ minWidth: "46.2vw", marginTop: "7px" }}>
                    <Dropdown
                      placeholder="Permission Name"
                      fluid
                      search
                      ref={search => {
                        this.searchInput = search;
                      }}
                      selection
                      options={this.state.rowRefs}
                      onChange={this.handleItemSelect}
                      //   options={this.state.rowRefs}
                    ></Dropdown>
                  </div>
                </FormGroup>
              </FormControl>
            </Grid> */}

                <Grid container style={{ marginTop: "30px" }}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          onChange={this.handleAllRoutesCheck}
                          name="allRoutes"
                          checked={this.state.allTypes.allRoutes}
                        />
                      }
                      label={"Select All Routes"}
                      labelPlacement="end"
                      style={{ color: "#000", marginBottom: "20px" }}
                    ></FormControlLabel>
                  </FormControl>
                </Grid>

                <Grid container style={{ marginTop: "0px" }}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          onChange={this.handleAllCheck}
                          name="allAdmin"
                          checked={this.state.allTypes.allAdmin}
                        />
                      }
                      label={"Select Administration Route"}
                      labelPlacement="end"
                      style={{ color: "#000", marginBottom: "20px" }}
                    ></FormControlLabel>
                    <FormLabel component="legend">
                      Administration Route
                    </FormLabel>
                    {this.state.routeList.allAdmin.map((value, key) => (
                      <Grid container key={value}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              name={value}
                              onChange={this.onChecked}
                              checked={this.state.checkStates[value]}
                            />
                          }
                          label={value}
                          labelPlacement="end"
                        ></FormControlLabel>
                      </Grid>
                    ))}
                  </FormControl>
                </Grid>

                <Grid container style={{ marginTop: "50px" }}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          onChange={this.handleAllCheck}
                          name="allMaster"
                          checked={this.state.allTypes.allMaster}
                        />
                      }
                      label={"Select Master Route"}
                      labelPlacement="end"
                      style={{ color: "#000", marginBottom: "20px" }}
                    ></FormControlLabel>
                    <FormLabel component="legend">Master Route</FormLabel>
                    {this.state.routeList.allMaster.map((value, key) => (
                      <Grid container key={value}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              name={value}
                              onChange={this.onChecked}
                              checked={this.state.checkStates[value]}
                            />
                          }
                          label={value}
                          labelPlacement="end"
                        ></FormControlLabel>
                      </Grid>
                    ))}
                  </FormControl>
                </Grid>

                <Grid container style={{ marginTop: "50px" }}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          name={"allTransaction"}
                          onChange={this.handleAllCheck}
                          checked={this.state.allTypes.allTransaction}
                        />
                      }
                      label={"Select Transaction Route"}
                      labelPlacement="end"
                      style={{ color: "#000", marginBottom: "20px" }}
                    ></FormControlLabel>
                    <FormLabel component="legend">Transaction Route</FormLabel>
                    {this.state.routeList.allTransaction.map((value) => (
                      <Grid container>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              name={value}
                              onChange={this.onChecked}
                              checked={this.state.checkStates[value]}
                            />
                          }
                          label={value}
                          labelPlacement="end"
                        ></FormControlLabel>
                      </Grid>
                    ))}
                  </FormControl>
                </Grid>

                <Grid container style={{ marginTop: "50px" }}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          name={"allSCM"}
                          onChange={this.handleAllCheck}
                          checked={this.state.allTypes.allSCM}
                        />
                      }
                      label={"Select SCM Route"}
                      labelPlacement="end"
                      style={{ color: "#000", marginBottom: "20px" }}
                    ></FormControlLabel>
                    <FormLabel component="legend">SCM Route</FormLabel>
                    {this.state.routeList.allSCM.map((value) => (
                      <Grid container>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              name={value}
                              onChange={this.onChecked}
                              checked={this.state.checkStates[value]}
                            />
                          }
                          label={value}
                          labelPlacement="end"
                        ></FormControlLabel>
                      </Grid>
                    ))}
                  </FormControl>
                </Grid>

                <Grid container style={{ marginTop: "50px" }}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          name={"allInventory"}
                          onChange={this.handleAllCheck}
                          checked={this.state.allTypes.allInventory}
                        />
                      }
                      label={"Select Inventory Route"}
                      labelPlacement="end"
                      style={{ color: "#000", marginBottom: "20px" }}
                    ></FormControlLabel>
                    <FormLabel component="legend">Inventory Route</FormLabel>
                    {this.state.routeList.allInventory.map((value, key) => (
                      <Grid container>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              name={value}
                              onChange={this.onChecked}
                              checked={this.state.checkStates[value]}
                            />
                          }
                          label={value}
                          labelPlacement="end"
                        ></FormControlLabel>
                      </Grid>
                    ))}
                  </FormControl>
                </Grid>
                <Grid container style={{ marginTop: "50px" }}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          name={"allAccounts"}
                          onChange={this.handleAllCheck}
                          checked={this.state.allTypes.allAccounts}
                        />
                      }
                      label={"Select Accounts Route"}
                      labelPlacement="end"
                      style={{ color: "#000", marginBottom: "20px" }}
                    ></FormControlLabel>
                    <FormLabel component="legend">Accounts Route</FormLabel>
                    {this.state.routeList.allAccounts.map((value) => (
                      <Grid container>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              name={value}
                              onChange={this.onChecked}
                              checked={this.state.checkStates[value]}
                            />
                          }
                          label={value}
                          labelPlacement="end"
                        ></FormControlLabel>
                      </Grid>
                    ))}
                  </FormControl>
                </Grid>
                <Grid container style={{ marginTop: "50px" }}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          name={"allReports"}
                          onChange={this.handleAllCheck}
                          checked={this.state.allTypes.allReports}
                        />
                      }
                      label={"Select Reports Route"}
                      labelPlacement="end"
                      style={{ color: "#000", marginBottom: "20px" }}
                    ></FormControlLabel>
                    <FormLabel component="legend">Reports Route</FormLabel>
                    {this.state.routeList.allReports.map((value) => (
                      <Grid container>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              name={value}
                              onChange={this.onChecked}
                              checked={this.state.checkStates[value]}
                            />
                          }
                          label={value}
                          labelPlacement="end"
                        ></FormControlLabel>
                      </Grid>
                    ))}
                  </FormControl>
                </Grid>
                <Grid container justify="center">
                  <Button
                    onClick={this.handleSubmit}
                    variant="contained"
                    size="large"
                    color="primary"
                    style={{
                      marginTop: "15px",
                      height: "50px",
                      width: "100px",
                      minWidth: "115px",
                      fontSize: "13px",
                    }}
                  >
                    Add Role
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(AddRole);
