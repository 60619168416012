import React, { Component } from "react";
import { Tabs, Tab, Grid, withStyles } from "@material-ui/core";
import AddInventory from "../components/InventoryMaster/AddInventory";
import Axios from "axios";
import dateFormat from "dateformat";
import AddSupplierModal from "../components/Purchase/AddSupplierModal";
import UserContext from "../utils/UserContext";
import AddWarehouseModal from "../components/ItemMaster/Warehouse/AddWarehouseModal";
import AddCategoryModal from "../components/InterfaceElements/CategoryModals/AddCategoryModal";
import AddMajorCategoryModal from "../components/InterfaceElements/CategoryModals/AddMajorCategoryModal";
import AddSubCategoryModal from "../components/InterfaceElements/CategoryModals/AddSubCategoryModal";
import HeaderTitle from "../components/InterfaceElements/HeaderTitle";
import InventoryList from "../components/InventoryMaster/InventoryList";
import BarCodeGenerator from "../components/InventoryMaster/BarCodeGenerator";
import AddManufacturerModal from "../components/ManufactureMaster/AddManufacturerModal";

require("nepali-date-picker/dist/jquery.nepaliDatePicker.min.js");
require("nepali-date-picker/dist/nepaliDatePicker.min.css");

const styles = (theme) => ({
  tabMargin: {
    marginBottom: "7px",
    [theme.breakpoints.down("md")]: {
      marginTop: "37px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "0px",
    },
  },
});

class InventoryMaster extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      companyInvoicingMethod: "",
      loading: false,
      value: 0,
      data: {
        itemCode: "",
        itemName: "",
        majorCategory: "",
        subCategory: "",
        category: "",
        supplierName: "",
        warehouse: "",
        custodian: "",
        itemDescription: "",
        propertyType: "",
        unit: "",
        total: 0,
        vatAmount: 0,
        totalWithVat: 0,
        quantity: "",
        rate: "",
        markedPrice: "",
        vatPercentage: "",
        manufactureDate: "",
        inventorySubMaster: {
          invoiceDateEnglish: "",
          invoiceDateNepali: "",
          transactionDateEnglish: "",
          transactionDateNepali: "",
          expiryDate: "",
          depreciation: 0,
        },
      },
      manufacturerList: [],
      supplier: [],
      supplierOptionList: [],
      category: [],
      origCategory: [],
      majorCategory: [],
      origMajorCategory: [],
      subCategory: [],
      origSubCategory: [],
      propertyType: [],
      warehouse: [],
      assetType: [],
      unitList: [
        { name: "FIT" },
        { name: "KGS" },
        { name: "LTR" },
        { name: "MTR" },
        { name: "PAC" },
        { name: "PIC" },
        { name: "PKT" },
        { name: "ROL" },
        { name: "SET" },
        { name: "BOX" },
      ],

      errorStatus: {},
      supplierAddOpen: false,
      majorCategoryAddOpen: false,
      categoryAddOpen: false,
      allCategoryAddOpen: false,
      subCategoryAddOpen: false,
      warehouseAddOpen: false,
      manufacturerAddOpen: false,
      majorCategoryId: 0,
      categoryId: 0,
      warehouseData: {
        name: "",
        code: "",
        remarks: "",
        address: "",
      },
    };
  }

  handleFormReset() {
    this.setState({
      data: {
        itemCode: "",
        itemName: "",
        unit: "",
        manufacturerName: "",
        itemDescription: "",
        custodian: "",
        majorCategory: "",
        subCategory: "",
        category: "",
        supplierName: "",
        warehouse: "",
        propertyType: "",
        total: 0,
        vatAmount: 0,
        totalWithVat: 0,
        quantity: 0,
        rate: 0,
        markedPrice: 0,
        vatPercentage: 0,
        manufactureDate: this.state.data.manufactureDate,
        inventorySubMaster: {
          referenceNumber: "",
          specificationNumber: "",
          model: "",
          goodsReceivedNote: "",
          batchNumber: "",
          invoiceNumber: "",
          transactionNumber: "",
          materialControlId: "",
          transactionDateEnglish:
            this.state.data.inventorySubMaster.transactionDateEnglish,
          invoiceDateNepali:
            this.state.data.inventorySubMaster.invoiceDateNepali,
          transactionDateNepali:
            this.state.data.inventorySubMaster.transactionDateNepali,
          invoiceDateEnglish:
            this.state.data.inventorySubMaster.invoiceDateEnglish,
          depreciation: 0,
          expiryDate: this.state.data.inventorySubMaster.expiryDate,
        },
      },
      manufacturerList: [],
      rowData: [],
      origData: [],
      totalItemCount: 0,
      inventoryData: [],
      category: [],
      origCategory: [],
      supplier: [],
      majorCategory: [],
      origMajorCategory: [],
      subCategory: [],
      origSubCategory: [],

      warehouse: [],
      origCategoriesList: [],
      origSubCategoriesList: [],
      categoriesList: [],
      majorCategoriesList: [],
      subCategoriesList: [],
      warehouseList: [],
      supplierAddOpen: false,
      majorCategoryAddOpen: false,
      categoryAddOpen: false,
      allCategoryAddOpen: false,
      subCategoryAddOpen: false,
      warehouseAddOpen: false,
      overlayLoadingTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
      overlayNoRowsTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
    });

    this.populateDropdowns();
  }

  populateCategoriesForListing = () => {
    Axios.get("pos/category/getAllCategories")
      .then((res) => {
        var listData = [];
        listData.push({ key: 0, value: "None", text: "None" });

        res.data.forEach((element) => {
          var item = {
            key: element.id,
            value: element.categoryName,
            text: element.categoryName,
          };

          listData.push(item);
        });

        this.setState({
          categoriesList: listData,
          origCategoriesList: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    Axios.get("pos/majorCategory/getAllMajorCategories")
      .then((res) => {
        var listData = [];
        listData.push({ key: 0, value: "None", text: "None" });

        res.data.forEach((element) => {
          var item = {
            key: element.id,
            value: element.majorCategoryName,
            text: element.majorCategoryName,
          };

          listData.push(item);
        });

        this.setState({ majorCategoriesList: listData });
      })
      .catch((err) => {
        console.log(err);
      });

    Axios.get("pos/subCategory/getAllSubCategories")
      .then((res) => {
        var listData = [];
        listData.push({ key: 0, value: "None", text: "None" });

        res.data.forEach((element) => {
          var item = {
            key: element.id,
            value: element.subCategoryName,
            text: element.subCategoryName,
          };

          listData.push(item);
        });

        this.setState({
          subCategoriesList: listData,
          origSubCategoriesList: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    Axios.get("pos/warehouse/getAllWarehouses")
      .then((res) => {
        var listData = [];
        listData.push({ key: 0, value: "None", text: "None" });

        res.data.forEach((element) => {
          var item = {
            key: element.id,
            value: element.name,
            text: element.name,
          };

          listData.push(item);
        });

        this.setState({ warehouseList: listData });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleMajorCategorySelect = (event, data) => {
    const { value, options } = data;

    let id = "";

    options.map((val) => (val.value === value ? (id = val.key) : null));

    var rowAllData = [];
    var newCategoryList = [];
    newCategoryList.push({ key: 0, value: "None", text: "None" });

    if (value === "None") {
      rowAllData = this.state.origData;

      this.state.origCategoriesList.map((val) =>
        newCategoryList.push({
          key: val.id,
          value: val.categoryName,
          text: val.categoryName,
        })
      );
    } else if (value === "Furniture") {
      this.state.origData.forEach((element) => {
        if (element.majorCategory === value) rowAllData.push(element);
      });

      this.setState({
        rowData: rowAllData,
        totalItemCount: rowAllData.length,
      });
      return;
    } else if (value === "Fittings") {
      this.state.origData.forEach((element) => {
        if (element.majorCategory === value) rowAllData.push(element);
      });

      this.setState({
        rowData: rowAllData,
        totalItemCount: rowAllData.length,
      });
      return;
    } else if (value === "Furnishings") {
      this.state.origData.forEach((element) => {
        if (element.majorCategory === value) rowAllData.push(element);
      });

      this.setState({
        rowData: rowAllData,
        totalItemCount: rowAllData.length,
      });
      return;
    }

    this.setState({
      rowData: rowAllData,
      totalItemCount: rowAllData.length,
      categoriesList: newCategoryList,
    });
  };

  handleItemCode = (e, data) => {
    const { value } = data;

    var rowAllData = [];

    if (value === "") {
      this.setState({
        rowData: this.state.origData,
      });
    } else {
      this.state.origData.forEach((element) => {
        if (element.itemCode === value) rowAllData.push(element);
      });

      this.setState({ rowData: rowAllData, totalItemCount: rowAllData.length });
    }
  };

  handleItemName = (e, data) => {
    const { value } = data;

    var rowAllData = [];

    if (value === "") {
      this.setState({
        rowData: this.state.origData,
      });
    } else {
      this.state.origData.forEach((element) => {
        if (element.itemName === value) rowAllData.push(element);
      });

      this.setState({ rowData: rowAllData, totalItemCount: rowAllData.length });
    }
  };

  handleItemMarkedPrice = (e, data) => {
    const { value } = data;

    var rowAllData = [];

    if (value === "") {
      this.setState({
        rowData: this.state.origData,
      });
    } else {
      this.state.origData.forEach((element) => {
        if (element.markedPrice === parseInt(value)) rowAllData.push(element);
      });

      this.setState({ rowData: rowAllData, totalItemCount: rowAllData.length });
    }
  };

  handleCategorySelect = (event, data) => {
    const { value, options } = data;

    let id = "";

    options.map((val) => (val.value === value ? (id = val.key) : null));

    var rowAllData = [];
    var newSubCategoryList = [];
    newSubCategoryList.push({ key: 0, value: "None", text: "None" });

    if (value === value) {
      this.populateCustomSubCategory(id);

      this.state.origData.forEach((element) => {
        if (element.category === value) rowAllData.push(element);
      });

      this.setState({
        rowData: rowAllData,
        totalItemCount: rowAllData.length,
      });

      return;
    }

    if (value === "None") {
      rowAllData = this.state.origData;

      this.state.origSubCategoriesList.map((val) =>
        newSubCategoryList.push({
          key: val.id,
          value: val.subCategoryName,
          text: val.subCategoryName,
        })
      );
    } else {
      this.state.origData.forEach((element) => {
        if (
          element.category === value &&
          element.propertyType === this.state.data.propertyType
        )
          rowAllData.push(element);
      });

      this.state.origSubCategoriesList.forEach((element) => {
        if (element.category.id === undefined) {
          if (parseInt(element.category) === parseInt(id))
            newSubCategoryList.push({
              key: element.id,
              value: element.subCategoryName,
              text: element.subCategoryName,
            });
        } else {
          if (parseInt(element.category.id) === parseInt(id))
            newSubCategoryList.push({
              key: element.id,
              value: element.subCategoryName,
              text: element.subCategoryName,
            });
        }
      });
    }

    this.setState({
      rowData: rowAllData,
      totalItemCount: rowAllData.length,
      subCategoriesList: newSubCategoryList,
    });
  };

  handleSubCategorySelect = (event, data) => {
    const { value, options } = data;
    let id = "";

    options.map((val) => (val.value === value ? (id = val.key) : null));
    var rowAllData = [];
    var newSubCategoryList = [];
    newSubCategoryList.push({ key: 0, value: "None", text: "None" });

    if (value === value) {
      this.state.origData.forEach((element) => {
        if (element.subCategory === value) rowAllData.push(element);
      });

      this.setState({
        rowData: rowAllData,
        totalItemCount: rowAllData.length,
      });

      return;
    }

    if (value === "None") {
      this.setState({
        rowData: this.state.origData,
      });
    } else {
      this.state.origData.forEach((element) => {
        if (
          element.subCategory === value &&
          element.propertyType === this.state.data.propertyType
        )
          rowAllData.push(element);
      });

      this.state.origSubCategoriesList.forEach((element) => {
        if (element.subCategory.id === undefined) {
          if (parseInt(element.subCategory) === parseInt(id))
            newSubCategoryList.push({
              key: element.id,
              value: element.subCategoryName,
              text: element.subCategoryName,
            });
        } else {
          if (parseInt(element.category.id) === parseInt(id))
            newSubCategoryList.push({
              key: element.id,
              value: element.subCategoryName,
              text: element.subCategoryName,
            });
        }
      });
    }

    this.setState({
      rowData: rowAllData,
      totalItemCount: rowAllData.length,
      subCategoriesList: newSubCategoryList,
    });
  };

  handleWarehouseSelect = (event, data) => {
    const { value } = data;

    var rowAllData = [];

    if (value === "None") {
      this.setState({
        rowData: this.state.origData,
      });
    } else {
      this.state.origData.forEach((element) => {
        if (element.warehouse === value) rowAllData.push(element);
      });

      this.setState({ rowData: rowAllData, totalItemCount: rowAllData.length });
    }
  };

  handlePropertyTypeSelect = (event, data) => {
    const { value } = data;

    var rowAllData = [];

    if (value === "None") {
      this.setState({
        rowData: this.state.origData,
      });
    } else {
      this.state.origData.forEach((element) => {
        if (element.propertyType === value) rowAllData.push(element);
      });
      this.setState({ rowData: rowAllData, totalItemCount: rowAllData.length });
    }

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        propertyType: value,
      },
    }));

    if (value === "Capital Assets") {
      this.populateStaticCategories();
      this.populateStaticMajorCategories();
      this.populateStaticSubCategories();
    } else {
      this.populateCategoriesForListing();
    }
  };

  getCompanyInformation = () => {
    Axios.get("/hrm/getGeneralCompanyInformation").then((res) => {
      this.setState((prevState) => ({
        ...prevState,
        companyInvoicingMethod: res.data[0].invoicingMethod,
      }));
    });
  };

  getInventoryListing = () => {
    this.setState({ loading: true });
    Axios.get("pos/inventory/getAllInventories")
      .then((res) => {
        var rowData = [];
        res.data.forEach((element) => {
          var obj = {
            itemCode: element.itemCode,
            itemName: element.itemName,
            majorCategory: element.majorCategory,
            category: element.category,
            subCategory: element.subCategory,
            quantity: element.quantity,
            status: element.status,
            assetsType: element.assetsType,
            custodian: element.custodian,
            unit: element.unit,
            id: element.id,
            inventorySubMaster: element.inventorySubMaster,
            propertyType: element.propertyType,
            manufactureDate: element.manufactureDate,
            manufactureName: element.manufacturerName,
            rate: element.rate,
            markedPrice: element.markedPrice,
            supplierName: element.supplierName,
            total: element.total,
            totalWithVat: element.totalWithVat,
            vatAmount: element.vatAmount,
            vatPercentage: element.vatPercentage,
            warehouse: element.warehouse,
          };
          rowData.push(obj);
        });
        var rowDetailData = res.data;
        this.setState({
          rowData: rowData,
          origData: rowData,
          rowDetailData: rowDetailData,
          totalItemCount: res.data.length,
          loading: false,
        });

        this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>",
        });
        this.gridApi.hideOverlay();
        if (res.data.length === 0) this.gridApi.showNoRowsOverlay();
      })
      .catch((err) => {
        console.log(err);

        this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>",
        });
        this.gridApi.hideOverlay();
        this.gridApi.showNoRowsOverlay();
      });
  };

  setGridApi = (gridApi) => {
    this.gridApi = gridApi;
  };

  handleOpen = (value) => {
    this.setState({ [value]: true });
  };

  populateSuppliers = (mode) => {
    var suppliers = [{ name: "+ Add Supplier" }];
    var supplierOptionList = [
      {
        key: "+ Add Supplier",
        value: "+ Add Supplier",
        text: "+ Add Supplier",
      },
    ];

    Axios.get("pos/supplier/getAllSuppliers")
      .then((res) => {
        var suppliers = [{ name: "+ Add Supplier" }];

        res.data.forEach((element) => {
          suppliers.push(element);

          const item = {
            key: element.fullName,
            value: element.fullName,
            text: element.fullName,
          };
          supplierOptionList.push(item);
        });

        this.setState({ supplier: suppliers });
        this.setState((prevState) => ({
          ...prevState,
          supplierOptionList: supplierOptionList,
        }));
      })
      .catch((err) => {
        console.log(err);

        this.setState({ supplier: suppliers });
        this.setState((prevState) => ({
          ...prevState,
        }));
      });
  };

  populateCategories = (mode) => {
    var categories = [{ categoryName: "+ Add Category", id: "COPEN" }];

    Axios.get("pos/category/getAllCategories")
      .then((res) => {
        res.data.forEach((element) => {
          categories.push(element);
        });

        this.setState((prevState) => ({
          ...prevState,
          category: categories,
          origCategory: categories,
        }));
      })
      .catch((err) => {
        console.log(err);
        this.setState({ category: categories });
        this.setState((prevState) => ({
          ...prevState,
        }));
      });
  };

  getItemCode() {
    var obj = { categoryName: this.state.data.category };

    Axios.post("pos/inventory/getItemCode", obj)
      .then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,
            itemCode: res.data.toFixed(3),
          },
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  populateMajorCategories = (mode) => {
    var majorCategories = [
      { majorCategoryName: "+ Add Major Category", id: "MCOPEN" },
    ];

    Axios.get("pos/majorCategory/getAllMajorCategories")
      .then((res) => {
        res.data.forEach((element) => {
          majorCategories.push(element);
        });

        this.setState({
          majorCategory: majorCategories,
          origMajorCategory: majorCategories,
        });
      })
      .catch((err) => {
        console.log(err);

        this.setState({ majorCategory: majorCategories });
      });
  };

  populateSubCategories = (mode) => {
    var subCategories = [
      {
        subCategoryName: "+ Add Sub Category",
        id: "SCOPEN",
        category: { id: "SCOPEN" },
      },
    ];

    Axios.get("pos/subCategory/getAllSubCategories")
      .then((res) => {
        res.data.forEach((element) => {
          subCategories.push(element);
        });

        this.setState({
          subCategory: subCategories,
          origSubCategory: subCategories,
        });
      })
      .catch((err) => {
        this.setState({ subCategory: subCategories });
      });
  };

  populateWarehouses = (mode) => {
    var warehouseList = [{ name: "+ Add Warehouse" }];

    Axios.get("pos/warehouse/getAllWarehouses")
      .then((res) => {
        res.data.forEach((element) => {
          warehouseList.push(element);
        });

        this.setState({ warehouse: warehouseList });
      })
      .catch((err) => {
        console.log(err);

        this.setState({ warehouse: warehouseList });
      });
  };

  populateStaticMajorCategories() {
    this.setState((prevState) => ({
      ...prevState,
      majorCategory: [{ id: "1", majorCategoryName: "Fixed Assets" }],
      majorCategoriesList: [
        {
          id: "0",
          value: "None",
          text: "None",
        },
        { id: "1", value: "Fixed Assets", text: "Fixed Assets" },
      ],
    }));
  }

  populateStaticCategories() {
    this.setState((prevState) => ({
      ...prevState,
      category: [
        { categoryName: "Class A-Buildings and Structures", id: "1" },
        { categoryName: "Class B-Office Equipment and Furniture", id: "2" },
        { categoryName: "Class C-Automobiles and Vehicles", id: "3" },
        { categoryName: "Class D-Construction and Others ", id: "4" },
        { categoryName: "Class E-Intangible Assets", id: "5" },
      ],
      categoriesList: [
        {
          key: "0",
          value: "None",
          text: "None",
        },
        {
          key: "1",
          value: "Class A-Buildings and Structures",
          text: "Class A-Buildings and Structures",
        },
        {
          text: "Class B-Office Equipment and Furniture",
          value: "Class B-Office Equipment and Furniture",
          key: "2",
        },
        {
          text: "Class C-Automobiles and Vehicles",
          value: "Class C-Automobiles and Vehicles",
          key: "3",
        },
        {
          text: "Class D-Construction and Others",
          value: "Class D-Construction and Others",
          key: "4",
        },
        {
          text: "Class E-Intangible Assets",
          value: "Class E-Intangible Assets",
          key: "5",
        },
      ],
    }));
  }

  populateCustomSubCategory = (categoryId) => {
    let subCategory = [
      { subCategoryName: "Building", id: "1", categoryId: "1" },
      { subCategoryName: "Structure", id: "2", categoryId: "1" },
      { subCategoryName: "Office Equipments", id: "3", categoryId: "2" },
      { subCategoryName: "Furniture", id: "4", categoryId: "2" },
      { subCategoryName: "Fixture", id: "5", categoryId: "2" },
      { subCategoryName: "Bus", id: "6", categoryId: "3" },
      { subCategoryName: "Car and Jeep", id: "7", categoryId: "" },
      { subCategoryName: "Motorbike", id: "8", categoryId: "3" },
      { subCategoryName: "Construction", id: "9", categoryId: "4" },
      { subCategoryName: "Plant and Machinery", id: "10", categoryId: "4" },
      { subCategoryName: "Production Equipment", id: "11", categoryId: "4" },
      { subCategoryName: "R&D Costs", id: "12", categoryId: "4" },
      { subCategoryName: "Goodwill", id: "13", categoryId: "5" },
      { subCategoryName: "Patents", id: "14", categoryId: "5" },
      { subCategoryName: "Copyright", id: "15", categoryId: "5" },
      { subCategoryName: "Trademark", id: "16", categoryId: "5" },
      { subCategoryName: "Software", id: "17", categoryId: "5" },
    ];

    let newList = [];
    subCategory.map((val) =>
      val.categoryId === categoryId
        ? newList.push({
            id: val.id,
            text: val.subCategoryName,
            value: val.subCategoryName,
          })
        : null
    );

    this.setState((prevState) => ({
      ...prevState,
      subCategoriesList: newList,
    }));
  };

  populateStaticSubCategories() {
    this.setState((prevState) => ({
      ...prevState,
      subCategory: [
        { subCategoryName: "Building", id: "1", categoryId: "1" },
        { subCategoryName: "Structure", id: "2", categoryId: "1" },
        { subCategoryName: "Office Equipments", id: "3", categoryId: "2" },
        { subCategoryName: "Furniture", id: "4", categoryId: "2" },
        { subCategoryName: "Fixture", id: "5", categoryId: "2" },
        { subCategoryName: "Bus", id: "6", categoryId: "3" },
        { subCategoryName: "Car and Jeep", id: "7", categoryId: "" },
        { subCategoryName: "Motorbike", id: "8", categoryId: "3" },
        { subCategoryName: "Construction", id: "9", categoryId: "4" },
        { subCategoryName: "Plant and Machinery", id: "10", categoryId: "4" },
        { subCategoryName: "Production Equipment", id: "11", categoryId: "4" },
        { subCategoryName: "R&D Costs", id: "12", categoryId: "4" },
        { subCategoryName: "Goodwill", id: "13", categoryId: "5" },
        { subCategoryName: "Patents", id: "14", categoryId: "5" },
        { subCategoryName: "Copyright", id: "15", categoryId: "5" },
        { subCategoryName: "Trademark", id: "16", categoryId: "5" },
        { subCategoryName: "Software", id: "17", categoryId: "5" },
      ],
      subCategoriesList: [
        {
          id: "0",
          value: "None",
          text: "None",
        },
        { value: "Building", text: "Building", id: "1" },
        { value: "Structure", text: "Structure", id: "2" },
        {
          value: "Office Equipments",
          text: "Office Equipments",
          id: "3",
        },
        { value: "Furniture", text: "Furniture", id: "4" },
        { value: "Fixture", text: "Fixture", id: "5" },
        { value: "Bus", text: "Bus", id: "6" },
        {
          value: "Car and Jeep",
          text: "Car and Jeep",
          id: "7",
        },
        { value: "Motorbike", text: "Motorbike", id: "8" },
        {
          value: "Construction",
          text: "Construction",
          id: "9",
        },
        {
          value: "Plant and Machinery",
          text: "Plant and Machinery",
          id: "10",
        },
        {
          value: "Production Equipment",
          text: "Production Equipment",
          id: "11",
        },
        { value: "R&D Costs", id: "12", text: "R&D Costs" },
        { value: "Goodwill", id: "13", text: "Goodwill" },
        { value: "Patents", id: "14", text: "Patents" },
        { value: "Copyright", id: "15", text: "Copyright" },
        { value: "Trademark", id: "16", text: "Trademark" },
        { value: "Software", id: "17", text: "Software" },
      ],
    }));
  }

  handleClose = (value) => {
    this.setState({ [value]: false });

    switch (value) {
      case "supplierAddOpen":
        this.populateSuppliers("fromModal");

        break;

      case "majorCategoryAddOpen":
        this.populateMajorCategories("fromModal");
        break;

      case "categoryAddOpen":
        this.populateCategories("fromModal");
        break;

      case "subCategoryAddOpen":
        this.populateSubCategories("fromModal");
        break;

      case "warehouseAddOpen":
        this.populateWarehouses("fromModal");
        break;

      case "manufacturerAddOpen":
        this.populateManufactuers("fromModal");
        break;

      default:
        this.populateCategories("fromModal");
        this.populateMajorCategories("fromModal");
        this.populateSubCategories("fromModal");
        break;
    }
  };

  handleTextChangeSupplier = (event, data) => {
    const { value } = data;
    console.log("change supplier called" + value);

    if (value === "+ Add Supplier") {
      this.handleOpen("supplierAddOpen");
    } else {
      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          supplier: value,
        },
      }));
    }
  };

  handleSubCategoryChangedInAdd = async (event, data) => {
    let { value } = data;
    console.log("");
    console.log("sub Category:" + value);

    if (value === "+ Add Sub Category") {
      this.handleOpen("subCategoryAddOpen");
    }
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        subCategory: value,
      },
    }));
  };

  handleCategoryChangedInAdd = async (event, data) => {
    let { value } = data;

    if (value === "+ Add Category") {
      this.handleOpen("categoryAddOpen");
    }

    if (this.state.data.propertyType === "Capital Assets") {
      this.populateStaticSubCategories();

      var cat = "";
      var id3 = value;

      this.state.category.map((val) => {
        if (parseInt(val.id) === parseInt(id3)) {
          cat = val.categoryName;
        }
        return null;
      });

      await this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          category: cat,
        },
      }));

      var newList = [];
      this.state.subCategory.forEach((element) => {
        if (element.categoryId === value) {
          newList.push(element);
        }
      });

      this.setState((prevState) => ({
        ...prevState,
        subCategory: newList,
        data: {
          ...prevState.data,
          subCategory: newList[0].subCategoryName,
        },
      }));
      this.getItemCode();
    } else {
      var categoryId = value;
      this.state.category.map((val) => {
        if (val.categoryName === value) {
          categoryId = val.id;
          value = val.categoryName;
        }
        return null;
      });

      var newSubCategoryList = [
        {
          subCategoryName: "+ Add Sub Category",
          id: "SCOPEN",
          category: { id: "SCOPEN" },
        },
      ];
      var subCategoryList = [
        {
          value: "+ Add Sub Category",
          id: "SCOPEN",
          text: "+ Add Brand",
        },
      ];

      this.state.origSubCategory.forEach((element) => {
        try {
          if (parseInt(element.category.id) === categoryId) {
            newSubCategoryList.push(element);
            const item = {
              id: element.id,
              text: element.subCategoryName,
              value: element.subCategoryName,
            };
            subCategoryList.push(item);
          }
        } catch (e) {
          console.log(e);
        }
      });

      await this.setState((prevState) => ({
        ...prevState,
        subCategory: newSubCategoryList,
        subCategoryList: subCategoryList,
        data: {
          ...prevState.data,
          category: value,
        },
      }));
      this.getItemCode();
    }
  };

  onChanged = async (e) => {
    e.stopPropagation();
    var targetName = e.target.name;
    var value = e.target.value;

    switch (value) {
      case "+ Add Supplier":
        this.handleOpen("supplierAddOpen");
        break;

      case "+ Add Warehouse":
        Axios.get("pos/warehouse/getAutoCode").then(async (res) => {
          await this.setState({ warehouseData: { code: res.data } });
        });
        this.handleOpen("warehouseAddOpen");
        break;

      case "+ Add Manufacturer":
        this.handleOpen("manufacturerAddOpen");
        break;

      case "COPEN":
        this.handleOpen("categoryAddOpen");
        break;

      case "MCOPEN":
        this.handleOpen("majorCategoryAddOpen");
        break;

      case "SCOPEN":
        this.handleOpen("subCategoryAddOpen");
        break;

      default:
        break;
    }

    if (
      targetName === "majorCategory" ||
      targetName === "category" ||
      targetName === "subCategory" ||
      targetName === "propertyType"
    ) {
      switch (targetName) {
        case "propertyType":
          this.setState((prevState) => ({
            ...prevState,
            data: {
              ...prevState.data,
              propertyType: value,
            },
          }));
          if (value === "Capital Assets") {
            this.setState((prevState) => ({
              ...prevState,
              majorCategory: [{ id: "1", majorCategoryName: "Fixed Assets" }],
              data: {
                ...prevState.data,
                majorCategory: "Fixed Assets",
                category: "Class A-Buildings and Structures",
                subCategory: "Building",
              },
            }));
            this.populateStaticMajorCategories();
            this.populateStaticCategories();
            this.populateStaticSubCategories();
          } else {
            this.populateMajorCategories();
            this.populateCategories();
            this.populateSubCategories();
          }
          break;

        case "majorCategory":
          if (this.state.data.propertyType === "Capital Assets") {
            var majorCat = "";
            var id = value;

            this.state.majorCategory.map((val) => {
              if (parseInt(val.id) === parseInt(id)) {
                majorCat = val.majorCategoryName;
              }
              return null;
            });

            await this.setState((prevState) => ({
              ...prevState,
              data: {
                ...prevState.data,
                majorCategory: majorCat,
              },
            }));

            this.populateStaticCategories();
          } else {
            var id2 = value;
            this.state.majorCategory.map((val) => {
              if (parseInt(val.id) === parseInt(value)) {
                id2 = value;
                value = val.majorCategoryName;
              }
              return null;
            });

            var newCategoryList = [
              { categoryName: "+ Add Category", id: "COPEN" },
            ];
            this.state.origCategory.forEach((element) => {
              try {
                if (parseInt(element.majorCategory.id) === parseInt(id2)) {
                  newCategoryList.push(element);
                }
              } catch (e) {}
            });

            let categoryFormatted = [];
            newCategoryList.map((val) => {
              const item = {
                id: val.id,
                value: val.categoryName,
                text: val.categoryName,
              };
              categoryFormatted.push(item);
            });

            this.setState((prevState) => ({
              ...prevState,
              category: newCategoryList,
              categoriesList: categoryFormatted,
              data: {
                ...prevState.data,
                majorCategory: value,
              },
              subCategory: [],
            }));
          }
          break;

        case "category":
          if (this.state.data.propertyType === "Capital Assets") {
            this.populateStaticSubCategories();

            var cat = "";
            var id3 = value;

            this.state.category.map((val) => {
              if (parseInt(val.id) === parseInt(id3)) {
                cat = val.categoryName;
              }
              return null;
            });

            await this.setState((prevState) => ({
              ...prevState,
              data: {
                ...prevState.data,
                category: cat,
              },
            }));

            var newList = [];
            this.state.subCategory.forEach((element) => {
              if (element.categoryId === value) {
                newList.push(element);
              }
            });

            this.setState((prevState) => ({
              ...prevState,
              subCategory: newList,
              data: {
                ...prevState.data,
                subCategory: newList[0].subCategoryName,
              },
            }));
            this.getItemCode();
          } else {
            var categoryId = value;
            this.state.category.map((val) => {
              if (parseInt(val.id) === parseInt(value)) {
                categoryId = value;
                value = val.categoryName;
              }
              return null;
            });

            var newSubCategoryList = [
              {
                subCategoryName: "+ Add Sub Category",
                id: "SCOPEN",
                category: { id: "SCOPEN" },
              },
            ];

            this.state.origSubCategory.forEach((element) => {
              try {
                if (parseInt(element.category.id) === parseInt(categoryId)) {
                  newSubCategoryList.push(element);
                }
              } catch (e) {
                console.log(e);
              }
            });

            await this.setState((prevState) => ({
              ...prevState,
              subCategory: newSubCategoryList,
              data: {
                ...prevState.data,
                category: value,
              },
            }));
            this.getItemCode();
          }

          break;

        case "subCategory":
          if (this.state.data.propertyType === "Capital Assets") {
            var subCat = "";
            var id4 = value;

            this.state.subCategory.map((val) => {
              if (parseInt(val.id) === parseInt(id4)) {
                subCat = val.subCategoryName;
              }
              return null;
            });
            await this.setState((prevState) => ({
              ...prevState,
              data: {
                ...prevState.data,
                subCategory: subCat,
              },
            }));
          } else {
            this.state.subCategory.map((val) => {
              this.setState((prevState) => ({
                ...prevState,
                data: {
                  ...prevState.data,
                  subCategory: value,
                },
              }));
              if (parseInt(val.id) === value) {
                value = val.subCategoryName;
              }
              return null;
            });
          }

          break;

        default:
          break;
      }

      return;
    }

    if (
      targetName === "referenceNumber" ||
      targetName === "invoiceNumber" ||
      targetName === "goodsReceivedNote" ||
      targetName === "batchNumber" ||
      targetName === "model" ||
      targetName === "specificationNumber" ||
      targetName === "depreciation" ||
      targetName === "materialControlId" ||
      targetName === "transactionNumber"
    ) {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          inventorySubMaster: {
            ...prevState.data.inventorySubMaster,
            [targetName]: value,
          },
        },
      }));
    } else {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          [targetName]: value,
        },
      }));
    }
  };

  handleCalculation = (event, value) => {
    event.stopPropagation();

    var total = this.state.data.total;

    var targetName = event.target.name;
    var val = event.target.value;

    if (targetName === "quantity") {
      if (parseInt(val) <= 0) {
        val = 1;
      }
      total = parseFloat(this.state.data.rate * val);
    } else {
      total = parseFloat(this.state.data.quantity * val);
    }

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        total,
        [targetName]: val,
      },
    }));

    setTimeout(() => this.handleTotal(), 100);
  };

  handleTotal = () => {
    var total = this.state.data.quantity * this.state.data.rate;

    var vatAmount =
      Math.round(
        (parseFloat(this.state.data.vatPercentage) / 100) * total * 100
      ) / 100;

    var totalWithVat =
      Math.round((parseFloat(vatAmount) + parseFloat(total)) * 100) / 100;

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        totalWithVat,
        vatAmount,
      },
    }));
  };

  handleVatCalculation = (event, value) => {
    event.stopPropagation();

    var val = (event.target.value / 100 + 1).toFixed(2);

    var total = this.state.data.totalWithVat / val;
    var vatAmount = this.state.data.totalWithVat - total;

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        vatPercentage: event.target.value,
        vatAmount: vatAmount.toFixed(2),
        total: total.toFixed(2),
      },
    }));

    /* var vatAmount =
      Math.round((val / 100) * parseFloat(this.state.data.total) * 100) / 100;

    var totalWithVat =
      Math.round(
        (parseFloat(vatAmount) + parseFloat(this.state.data.total)) * 100
      ) / 100;

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        vatPercentage: val,
        vatAmount,
        totalWithVat,
      },
    })); */
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleInvoiceDateEnglish = (e) => {
    var newDate = dateFormat(e.target.value, "yyyy-mm-dd");

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        inventorySubMaster: {
          ...prevState.data.inventorySubMaster,
          invoiceDateEnglish: newDate,
        },
      },
    }));
  };

  handleInvoiceDateNepali = (date) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        inventorySubMaster: {
          ...prevState.data.inventorySubMaster,
          invoiceDateNepali: date,
        },
      },
    }));
  };

  handleTransactionDateNepali = (date) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        inventorySubMaster: {
          ...prevState.data.inventorySubMaster,
          transactionDateNepali: date,
        },
      },
    }));
  };

  handleTransactionDateEnglish = (e) => {
    var newDate = dateFormat(e.target.value, "yyyy-mm-dd");
    console.log({ newDate });
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        inventorySubMaster: {
          ...prevState.data.inventorySubMaster,
          transactionDateEnglish: newDate,
        },
      },
    }));
  };

  handleMfgDate = (e) => {
    var newDate = dateFormat(e.target.value, "yyyy-mm-dd");

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        manufactureDate: newDate,
      },
    }));
  };

  handleExpiryDate = (e) => {
    var newDate = dateFormat(e.target.value, "yyyy-mm-dd");
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        inventorySubMaster: {
          ...prevState.data.inventorySubMaster,
          expiryDate: newDate,
        },
      },
    }));
  };

  handleSubmit = () => {
    const token = this.context;
    var status = this.validateForm();
    console.log("----before save---");
    console.log(this.state.data);
    console.log("----before save---");
    console.log(status);
    if (status) {
      Axios.post("pos/inventory/addInventory", this.state.data)
        .then((res) => {
          var snackbarProps = {
            open: true,
            variant: "success",
            message: res.data,
            autoHideDuration: 2000,
          };

          token.setSnackbarProps({ snackbarProps: snackbarProps });
          this.handleFormReset();

          this.getInventoryListing();
        })
        .catch((err) => {
          var message = err.message;
          if (err.response !== undefined) {
            message = err.response.data;
          }
          var snackbarProps = {
            open: true,
            variant: "error",
            message: message,
            autoHideDuration: 2000,
          };
          token.setSnackbarProps({ snackbarProps: snackbarProps });
        });
    }
  };

  validateForm() {
    var toCheck = [
      "itemCode",
      "itemName",
      "unit",
      "propertyType",
      "majorCategory",
      "category",
      // "subCategory",
      "warehouse",
      // "rate",
    ];

    var newErrStatus = this.state.errorStatus;
    var status = true;

    toCheck.forEach((element) => {
      let val = this.state.data[element];

      if (val === "" || val === undefined || String(val).startsWith("+ Add ")) {
        newErrStatus[element] = true;
        status = false;
      } else {
        newErrStatus[element] = false;
      }
    });

    this.setState((prevState) => ({
      ...prevState,
      errorStatus: {
        ...prevState.errorStatus,
        newErrStatus,
      },
    }));

    return status;
  }

  populateDropdowns = () => {
    //Get categories list for dropdown
    this.populateCategories();

    //Get suppliers list for dropdown
    this.populateSuppliers();

    //Get major categories list for dropdown
    this.populateMajorCategories();

    //Get subcategories list for dropdown
    this.populateSubCategories();

    //Get warehouse list for dropdown
    this.populateWarehouses();

    //for inventory list categories
    this.populateCategoriesForListing();

    //for property type list
    var propertyTypeList = [
      { propertyType: "Stock" },
      { propertyType: "Inventory" },
      { propertyType: "Capital Assets" },
    ];

    this.setState({ propertyType: propertyTypeList });

    this.populateManufactuers();
  };

  populateManufactuers = () => {
    let manufacturers = [{ fullName: "+ Add Manufacturer" }];

    Axios.get("/pos/manufacturer/findAll")
      .then((res) => {
        res.data.map((val) => manufacturers.push(val));

        this.setState((prevState) => ({
          ...prevState,
          manufacturerList: manufacturers,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    this.getCompanyInformation();
    this.populateDropdowns();
    this.getInventoryListing();
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    var todayDate = dateFormat(date, "yyyy-mm-dd");

    this.setState((prevState) => ({
      ...prevState,

      data: {
        ...prevState.data,
        manufactureDate: todayDate,
        inventorySubMaster: {
          ...prevState.data.inventorySubMaster,
          transactionDateEnglish: todayDate,
          invoiceDateEnglish: todayDate,
          expiryDate: todayDate,
        },
      },
    }));
  }

  setSnackBar = (snackbarProps) => {
    const token = this.context;

    token.setSnackbarProps({ snackbarProps: snackbarProps });
  };

  render() {
    const { classes } = this.props;

    function a11yProps(index) {
      return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
      };
    }

    return (
      <div>
        <div
          style={{
            backgroundColor: "#EAEDEF",
          }}
        >
          <HeaderTitle title="Inventory Master" />
          <Grid container justify="center" className={classes.tabMargin}>
            <Tabs
              variant="scrollable"
              value={this.state.value}
              onChange={this.handleChange}
              aria-label="Vertical tabs example"
              indicatorColor="primary"
              centered
            >
              <Tab
                label="Add Inventory"
                {...a11yProps(0)}
                style={{ minWidth: 50, fontSize: "12px" }}
              />
              <Tab
                label="Manage Inventory"
                {...a11yProps(1)}
                style={{
                  minWidth: 50,
                  fontSize: "12px",
                }}
              />
              <Tab
                label="Barcode Generator"
                style={{ minWidth: 50, fontSize: "12px" }}
              />
            </Tabs>
          </Grid>
          <Grid container justify="center">
            <Grid item xs>
              <InventoryList
                index={1}
                value={this.state.value}
                setSnackbarProps={this.setSnackBar}
                setGridApi={this.setGridApi}
                rowData={this.state.rowData}
                origData={this.state.origData}
                totalItemCount={this.state.totalItemCount}
                manufacturerList={this.state.manufacturerList}
                handleCategorySelect={this.handleCategorySelect}
                handleMajorCategorySelect={this.handleMajorCategorySelect}
                handleSubCategorySelect={this.handleSubCategorySelect}
                handleWarehouseSelect={this.handleWarehouseSelect}
                handlePropertyTypeSelect={this.handlePropertyTypeSelect}
                handleItemCode={this.handleItemCode}
                handleItemName={this.handleItemName}
                handleItemMarkedPrice={this.handleItemMarkedPrice}
                categoriesList={this.state.categoriesList}
                majorCategoriesList={this.state.majorCategoriesList}
                unitList={this.state.unitList}
                subCategoriesList={this.state.subCategoriesList}
                warehouseList={this.state.warehouseList}
                overlayLoadingTemplate={this.state.overlayLoadingTemplate}
                overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                loading={this.state.loading}
                companyInvoicingMethod={this.state.companyInvoicingMethod}
              ></InventoryList>
            </Grid>
          </Grid>

          <Grid container justify="center">
            <Grid item>
              <AddInventory
                value={this.state.value}
                index={0}
                onChange={this.onChanged}
                data={this.state.data}
                errorStatus={this.state.errorStatus}
                onInvoiceDateEnglishChange={this.handleInvoiceDateEnglish}
                onInvoiceDateNepaliChange={this.handleInvoiceDateNepali}
                onTransactionNepaliDateChange={this.handleTransactionDateNepali}
                onTransactionEnglishDateChange={
                  this.handleTransactionDateEnglish
                }
                handleSupplierChange={this.handleTextChangeSupplier}
                handleCategoryChange={this.handleCategoryChangedInAdd}
                handleSubCategoryChange={this.handleSubCategoryChangedInAdd}
                handleCalculation={this.handleCalculation}
                handleVatCalculation={this.handleVatCalculation}
                quantity={this.state.data.quantity}
                rate={this.state.data.rate}
                markedPrice={this.state.data.mrp}
                vatPercentage={this.state.data.vatPercentage}
                total={this.state.data.total}
                vatAmount={this.state.data.vatAmount}
                totalWithVat={this.state.data.totalWithVat}
                propertyList={this.state.propertyType}
                propertyType={this.state.data.propertyType}
                onMfgDateChange={this.handleMfgDate}
                onExpiryDateChange={this.handleExpiryDate}
                handleSubmit={this.handleSubmit}
                category={this.state.data.category}
                majorCategory={this.state.data.majorCategory}
                unitList={this.state.unitList}
                subCategory={this.state.data.subCategory}
                warehouse={this.state.data.warehouse}
                categoryList={this.state.categoriesList}
                manufacturerList={this.state.manufacturerList}
                majorCategoryList={this.state.majorCategory}
                subCategoryList={this.state.subCategoryList}
                warehouseList={this.state.warehouse}
                supplierList={this.state.supplier}
                supplierOptionList={this.state.supplierOptionList}
                supplierName={this.state.data.supplierName}
                invoiceDateEnglish={
                  this.state.data.inventorySubMaster.invoiceDateEnglish
                }
                invoiceDateNepali={
                  this.state.data.inventorySubMaster.invoiceDateNepali
                }
                transactionDateEnglish={
                  this.state.data.inventorySubMaster.transactionDateEnglish
                }
                transactionDateNepali={
                  this.state.data.inventorySubMaster.transactionDateNepali
                }
                manufactureDate={this.state.data.manufactureDate}
                expiryDate={this.state.data.inventorySubMaster.expiryDate}
                companyInvoicingMethod={this.state.companyInvoicingMethod}
              ></AddInventory>
            </Grid>
          </Grid>

          <Grid container justify="center">
            <Grid item xs>
              <BarCodeGenerator
                value={this.state.value}
                index={2}
                companyInvoicingMethod={this.state.companyInvoicingMethod}
              />
            </Grid>
          </Grid>

          <AddSupplierModal
            open={this.state.supplierAddOpen}
            handleClose={() => this.handleClose("supplierAddOpen")}
          />

          <AddWarehouseModal
            open={this.state.warehouseAddOpen}
            handleClose={() => this.handleClose("warehouseAddOpen")}
            data={this.state.warehouseData}
          />

          <AddManufacturerModal
            open={this.state.manufacturerAddOpen}
            handleClose={() => this.handleClose("manufacturerAddOpen")}
          />

          <AddCategoryModal
            open={this.state.categoryAddOpen}
            handleClose={() => this.handleClose("categoryAddOpen")}
            data={this.state.majorCategory}
          />

          <AddMajorCategoryModal
            open={this.state.majorCategoryAddOpen}
            handleClose={() => this.handleClose("majorCategoryAddOpen")}
          />

          <AddSubCategoryModal
            open={this.state.subCategoryAddOpen}
            handleClose={() => this.handleClose("subCategoryAddOpen")}
            data={this.state.category}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(InventoryMaster);
