import React, { Component } from "react";
import { UserProvider } from "./utils/UserContext";
import axios from "axios";
import Axios from "axios";
import AppWithCSS from "./AppWithCSS";
import "./App.css";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import QueueSnackbar from "./components/QueueSnackbar";
require("animate.css");

class App extends Component {
  constructor(props) {
    super(props);

    var token = localStorage.getItem("Authorization");

    console.log(token);

    var loggedIn = false;

    if (token !== null) {
      loggedIn = true;
    }

    const expirationDuration = 1000 * 60 * 60 * 8; // 8 hours

    const prevAccepted = localStorage.getItem("accepted");
    const currentTime = new Date().getTime();

    const notAccepted = prevAccepted === undefined;
    const prevAcceptedExpired =
      prevAccepted !== undefined &&
      currentTime - prevAccepted > expirationDuration;

    if (notAccepted || prevAcceptedExpired) {
      loggedIn = false;
      localStorage.setItem("accepted", currentTime);
      localStorage.removeItem("Authorization");
    }

    this.state = {
      token: "token",
      companyInfoSet: true,
      boaFrom: "",
      setBoaFrom: ({ value }) => this.setState({ boaFrom: value }),
      voucherNo: "",
      setVoucherNo: ({ value }) => this.setState({ voucherNo: value }),
      voucherType: "",
      setVoucherType: ({ value }) => this.setState({ voucherType: value }),
      accountCode: "",
      setAccountCode: ({ value }) => this.setState({ accountCode: value }),
      subAccountCode: "",
      setSubAccountCode: ({ value }) =>
        this.setState({ subAccountCode: value }),
      filterFields: {
        dateFrom: "",
        dateTo: "",
        periodFrom: "",
        periodTo: "",
        fiscalYear: "",
      },
      setFilterFields: ({ value }) => this.setState({ filterFields: value }),
      invoicingMethod: "",
      setInvoicingMethod: ({ value }) =>
        this.setState({ invoicingMethod: value }),
      barCodes: [],
      roles: [],
      notifications: [],
      employeeInfo: { firstName: "", surname: "" },
      setEmployeeInfo: ({ value }) => this.setState({ employeeInfo: value }),
      loadingRoles: true,
      setRoles: ({ value }) => this.setState({ roles: value }),
      setBarCodes: ({ value }) => this.setState({ barCodes: value }),
      setToken: ({ value }) => this.setState({ token: value }),
      isLoggedIn: loggedIn,
      myNotifications: [],
      setMyNotifications: ({ value }) =>
        this.setState({ myNotifications: value }),
      unseenCount: 0,
      setUnseenCount: ({ value }) => this.setState({ unseenCount: value }),
      setLoggedIn: ({ value }) => this.setState({ isLoggedIn: value }),
      setCompanyInfoSet: ({ value }) =>
        this.setState({ companyInfoSet: value }),
      snackbarProps: {
        open: false,
        autoHideDuration: 2000,
        variant: "",
        message: "",
      },
      setSnackbarProps: ({ snackbarProps }) =>
        this.setState((prevState) => ({
          ...prevState.snackbarProps,
          snackbarProps,
        })),
    };

  //  var defaultURL = "http://localhost:6002";
    var defaultURL = "https://pos.snigdhaspire.com/api";

    axios.defaults.baseURL = defaultURL;

    if (token !== null) {
      axios.interceptors.request.use(
        function (config) {
          const token = localStorage.getItem("Authorization");
          config.headers.Authorization = token;
          return config;
        },
        function (error) {
          return Promise.reject(error);
        }
      );
    }

    Axios.get("/hrm/getStartupStatus")
      .then((res) => {
        this.setState({
          companyInfoSet: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    Axios.get("hrm/getGeneralCompanyInformation")
      .then((res) => {
        this.setState({
          invoicingMethod: res.data.invoicingMethod,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    Axios.get("/role/findMyRoles")
      .then((res) => {
        this.setState({
          roles: res.data,
          loadingRoles: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loadingRoles: false,
        });
      });

    Axios.get("hrm/findEmployeeDetails")
      .then((res) => {
        this.setState({
          employeeInfo: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    this.getMyAllNotifications();

    this.socket = new SockJS(defaultURL + "/gs-guide-websocket");

    this.stompClient = Stomp.over(this.socket);

    this.stompClient.connect({}, this.onConnected);
  }

  getMyAllNotifications = () => {
    Axios.get("pos/notification/findMyAllNotification")
      .then((res) => {
        console.log(res);

        let total = 0;

        res.data.map((val) => (val.status === true ? total++ : null));

        this.setState((prevState) => ({
          ...prevState,
          myNotifications: res.data,
          unseenCount: total,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onConnected = () => {
    this.stompClient.subscribe("/topic/notification", (messageOutput) => {
      var jsonBody = JSON.parse(messageOutput.body);

      var newNotifications = [];

      newNotifications.push(jsonBody.remarks);

      this.setState((prevState) => ({
        ...prevState,
        notifications: newNotifications,
      }));

      this.getMyAllNotifications();
    });
  };

  render() {
    return (
      <UserProvider value={this.state}>
        <AppWithCSS value={this.state} />
        <QueueSnackbar notis={this.state.notifications} />
      </UserProvider>
    );
  }
}

export default App;
