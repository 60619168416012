import React, { Component } from "react";
import {
  Grid,
  TextField,
  Divider,
  Button,
  MenuItem,
  withStyles,
} from "@material-ui/core";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import DeleteButton from "./InterfaceElements/DeleteButton";
import { Dropdown, Input } from "semantic-ui-react";
import Axios from "axios";
import AddSupplierModal from "./Purchase/AddSupplierModal";
import UserContext from "../utils/UserContext";
import { format } from "date-fns/esm";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import VoucherNumberRestriction from "../components/InterfaceElements/VoucherNumberRestriction";
import ConfirmationDialog from "./InterfaceElements/ConfirmationDialog";
import adbs, { bs2ad } from "ad-bs-converter";
import dateFormat from "dateformat";
import "nepali-datepicker-reactjs/dist/index.css";
import NepaliDatepicker from 'nepali-datepicker-and-dateinput';
//import { NepaliDatePicker } from "nepali-datepicker-reactjs";


const styles = (theme) => ({
  containerWidth: {
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "90%",
    },
  },
  bottomContainerWidth: {
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "90%",
    },
  },
  bottonTopMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
    },

    [theme.breakpoints.up("sm")]: {
      marginTop: "25px",
      marginBottom: "5px",
    },
  },
});

class PurchaseForm extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      confirmOpen: false,
      periodList: [],
      fyList: [],
      currentPeriod: "",
      fiscalYear: "",
      periodDateFrom: "",
      periodDateTo: "",
      periodResData: [],
      rowRefs: [],
      supplierRowRefs: [],
      columnDefs: [
        {
          headerName: "SN",
          field: "sn",
          sortable: true,
          resizable: true,
          width: 40,
        },
        {
          headerName: "Item Code",
          field: "code",
          sortable: true,
          resizable: true,
          width: 70,
        },
        {
          headerName: "Name",
          field: "name",
          sortable: true,
          resizable: true,
        },
        {
          headerName: "Quantity",
          field: "quantity",
          sortable: true,
          resizable: true,
          editable: true,
          width: 70,
        },
        {
          headerName: "Price",
          field: "price",
          sortable: true,
          editable: true,
          width: 70,
        },
        {
          headerName: "Discount",
          field: "discount",
          sortable: true,
          editable: true,
          width: 70,
        },
        {
          headerName: "Line Total",
          field: "lineTotal",
          sortable: true,
          width: 70,
        },
        {
          headerName: "Delete",
          cellRendererFramework: DeleteButton,
          cellRendererParams: {
            deleteRow: this.deleteRow,
            getAllRows: this.getAllRows,
          },
          width: 50,
        },
      ],

      purchaseOrderStatus: false,
      subTotal: 0,
      taxes: 0,
      totalDiscount: 0,
      grandTotal: 0,
      rowData: [],
      rowSelection: "multiple",
      value: "cash",
      sn: 1,
      quantity: 1,
      quantityMax: 0,
      discount: 0,
      price: 0,
      lineTotal: 0,
      itemDetails: {},
      invoiceNumber: "",
      supplierDetails: {
        id: "",
        supplierIdNumber: "",
        billFrom: "",
        address: "",
        pan: "",
      },
      data: {
        purchaseDate: "",
        grnNo: "",
        invoiceDate: "",
        purchaseDateNepali: "",
        purchaseBy: "",
        paymentMode: "Cash",
        purchaseOrderNo: "",
      },
      open: false,
      supplier: "None",
      purchaseNoList: [],
      voucherNumberError: false,
      purchaseNo: "",
      tableData: false,
      emptySupplier: false,
      searchNumber: true,
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  /* handleClose = () => {
    this.setState({ open: false });
    Axios.get("pos/supplier/getAllSupplierList")
      .then((res) => {
        var rows = [];
        var supplierId = "";

        rows.push({ key: 0, value: "None", text: "None" });

        res.data.forEach((element) => {
          const supplier = {
            key: element.id,
            value: element.id,
            text: element.name + "     #" + element.id,
          };
          rows.push(supplier);
          supplierId = element.id;
        });
        this.setState({
          supplierRowRefs: rows,
          supplier: supplierId,
        });

        Axios.get("pos/supplier/getSupplier/" + supplierId)
          .then((res) => {
            this.setState((prevState) => ({
              ...prevState,
              supplierDetails: {
                ...prevState.supplierDetails,
                id: res.data.id === null ? "" : res.data.id,
                billFrom: res.data.fullName === null ? "" : res.data.fullName,
                address: res.data.city === null ? "" : res.data.city,
                pan: res.data.panNumber === null ? "" : res.data.panNumber,
              },
            }));
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }; */

  componentDidMount() {
    this.getAllPeriodListing();
    
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    var todayDate = dateFormat(date, "yyyy-mm-dd");

    let transactionDate = (todayDate.split("-"));
    
    let tdf = (
      transactionDate[0] +
        "/" +
        transactionDate[1] +
        "/" +
        transactionDate[2]
    );
    let df= adbs.ad2bs(tdf)
    
    let finalYear = df.en.year;
    let finalMonth = df.en.month;
    let finalDay = df.en.day;
    if(df.en.month < 10){
      finalMonth = "0" + df.en.month;
    }    
    if(df.en.day < 10){
      finalDay = "0" + df.en.day;
    }

    let finalDate = finalYear + "-" + finalMonth + "-" + finalDay;

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        purchaseDate: finalDate,
        invoiceDate: finalDate,
        purchaseDateNepali: finalDate,
      },
    }));
    this.getInventoryItems();
    this.getSupplier();
    //this.getBillingInfo();
  }

  getAllPeriodListing = () => {
    Axios.get("/fy/getAllActivePeriods")
      .then(async (res) => {
        var fiscalYearLists = [];
        res.data.forEach((element) => {
          var fy = Object.keys(element);

          fiscalYearLists.push(fy[0]);
        });
        await this.setState({
          fyList: fiscalYearLists,
          periodResData: res.data,
        });

        this.getCurrentPeriod();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCurrentPeriod() {
    Axios.get("/fy/getCurrentPeriod")
      .then(async (res) => {
        await this.setState((prevState) => ({
          ...prevState,
          fiscalYear: res.data.fiscalYear,
          currentPeriod: res.data.name,
        }));
        this.getPeriodList();
        this.getNumbering(res.data.fiscalYear);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getNumbering(fy) {
    Axios.post("/pos/voucherNumbering/getVoucherNumbering", {fiscalYear: fy, name: "Purchase"})
      .then(async(res) => {
        if (res.data === "Not Found") {
          await this.setState({ voucherNumberError: true });
         } else {
         await this.setState((prevState) => ({
             ...prevState,
             purchaseNoList: res.data,
               purchaseNo: res.data[0],
           }));
         }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getSupplier() {
    Axios.get("pos/supplier/getAllSupplierList")
      .then((res) => {
        var rows = [];
        var supplierId = "";
        
        res.data.forEach((element) => {
          const supplier = {
            key: element.id,
            value: element.id,
            text: element.name + "     #" + element.id,
          };
          rows.push(supplier);
          supplierId = element.id;
        });
        
        rows.push({ key: 0, value: "None", text: "None" });
        this.setState((prevState)=>({
          ...prevState,
          supplierRowRefs: rows,
          // supplierDetails: {
          //   ...prevState.supplierDetails,
          //   supplierIdNumber: supplierId,
          // }
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleFYChange = async (e) => {
    let Fy = e.target.value;
    await this.setState((prevState) => ({
      ...prevState,
      fiscalYear: Fy,
    }));
    this.getPeriodList();
    this.getNumbering(Fy);
  };

  getPeriodList() {
    var pLists = [];

    // this.state.resData.map(val => Object.keys(val) === this.state.fiscalYear ? pLists = val : null);

    this.state.periodResData.map((val) =>
      Object.keys(val).includes(this.state.fiscalYear)
        ? (pLists = val[this.state.fiscalYear])
        : null
    );

    this.setState((prevState) => ({
      ...prevState,
      periodList: pLists,
      currentPeriod: pLists[pLists.length-1],
    }));
    this.getPeriodDateFromTo(this.state.fiscalYear,pLists[pLists.length-1]);
  }

  handlePeriodChange = async (e) => {
    let Py = e.target.value;

    await this.setState((prevState) => ({
      ...prevState,
      currentPeriod: Py,
    }));
    this.getPeriodDateFromTo(this.state.fiscalYear, Py);
  };

  getPeriodDateFromTo = (fiscalYear, period) => {
    Axios.post("fy/findByNameAndFiscalYear", { period, fiscalYear }).then(
      (res) =>
        this.setState((prevState)=>({
          ...prevState,
          periodDateFrom: res.data.dateFrom,
          periodDateTo: res.data.dateTo,
        }))
    );
  };

  onChangedDate = (event) => {
    this.setState((prevState) => ({
      ...prevState,
      date: event,
    }));
  };

  handleDateChange = (name, dateInMilli, bsDate, adDate) => {

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        invoiceDate: adDate,
      },
    }));
    console.log(adDate);
  };
  
  handlePurchaseByChange = async (e) => {
    let val = e.target.value;
    await this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        purchaseBy: val,
      },
    }));
  };
  handleBillNumberChange = async (e) => {
    let val = e.target.value;
    await this.setState((prevState) => ({
      ...prevState,
      billNumber: val,
    }));
  };

  handleChange = (e) => {
    let val = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      voucherNumber: val,
    }));
  };

  handleOrderNumberChange = (e) => {
    e.persist();
    var rowDetails = [];
    let val = e.target.value;

    Axios.get("/pos/purchaseOrder/findByPurchaseOrderNumber/" + val)
      .then((res) => {
        this.setState({ searchNumber: false });

        if (res.data) {
          this.setState((prevState) => ({
            ...prevState,
            purchaseOrderStatus: true,
            searchNumber: true,
            resData: res.data.purchaseOrderDetails,
            supplier:
              res.data.supplierName === null ? "" : res.data.supplierName,

            data: {
              ...prevState.data,
              purchaseOrderNo:
                res.data.purchaseOrderNumber === null
                  ? ""
                  : res.data.purchaseOrderNumber,
              purchaseBy:
                res.data.purchaseBy === null ? "" : res.data.purchaseBy,
            },
            supplierDetails: {
              ...prevState.supplierDetails,
              supplierIdNumber: res.data.supplier.id === null ? "" : res.data.supplier.id,
              address:
                res.data.supplierAddress === null
                  ? ""
                  : res.data.supplierAddress,
              pan: res.data.supplierPan === null ? "" : res.data.supplierPan,
            },
          }));

          let supplierSingleArr = [];

          let supplierId = res.data.supplier.id;

          supplierSingleArr.push({
            name: res.data.supplierName,
            id: supplierId,
          });

          res.data.purchaseOrderDetails.forEach((element) => {
            var item = {
              key: element.itemCode,
              value: element.itemCode,
              text: element.itemName + "#" + element.itemCode,
              label: { content: "#" + element.itemCode, tag: true },
            };
            rowDetails.push(item);
          });

          this.setState((prevState) => ({
            ...prevState,
            rowRefs: rowDetails,
            supplierRowRefs: supplierSingleArr,
            supplierDetails: {
              ...prevState.supplierDetails,
            supplier: supplierId,
            }
          }));
        } else {
          this.setState((prevState) => ({
            ...prevState,
            supplier: "",
            data: {
              ...prevState.data,
              purchaseOrderNo: val,
              purchaseBy: "",
            },
            supplierDetails: {
              ...prevState.supplierDetails,
              id: "",
              address: "",
              pan: "",
            },
          }));
          this.getInventoryItems();
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          searchNumber: false,
        });

        if (val === "") {
          this.setState({
            searchNumber: true,
          });
        }

       // this.resetFormValues();
      });
  };

  handleSupplierSelect = async(e) => {
    e.stopPropagation();
    var val = e.target.value;
    await this.setState((prevState) => ({
      ...prevState,
      supplier: val,
    }));
    console.log("========")
    console.log(val)
    if(val === "None") {
      this.setState((prevState) => ({
        ...prevState,
        emptySupplier: false,
        supplierDetails: {
          ...prevState.supplierDetails,
          id: "none",
          supplierIdNumber: "none",
          billFrom: "none",
          address: "none",
          pan: "none",
        },
      })); 
    }
    else {
      Axios.get("pos/supplier/getSupplier/" + val)
      .then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          emptySupplier: false,
          supplierDetails: {
            ...prevState.supplierDetails,
            id: res.data.id === null ? "" : res.data.id,
            supplierIdNumber:
              res.data.supplierIdNumber === null
                ? ""
                : res.data.supplierIdNumber,
            billFrom: res.data.fullName === null ? "" : res.data.fullName,
            address: res.data.city === null ? "" : res.data.city,
            pan: res.data.panNumber === null ? "" : res.data.panNumber,
          },
        }));
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };

  // if purchase order not present
  getInventoryItems() {
    var rowDetails = [];

    Axios.get("pos/inventory/getInventories")
      .then((res) => {
        res.data.forEach((element) => {
          var item = {
            key: element.itemCode,
            value: element.itemCode,
            text: element.itemName + "  #" + element.itemCode,
            label: { content: "#" + element.itemCode, tag: true },
          };
          rowDetails.push(item);
        });
        this.setState({ rowRefs: rowDetails, resData: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleConfirm = () => {
    this.setState({
      confirmOpen: true,
    })
  }
  handleSubmit = () => {

    let transactionDateTo = (this.state.data.purchaseDate.split("-"));
    
    let tdt = (
      transactionDateTo[0] +
        "/" +
        transactionDateTo[1] +
        "/" +
        transactionDateTo[2]
    );
      let dateToArr = bs2ad(tdt);
      
      let finalDateToYear = dateToArr.year;
      let finalDateToMonth = dateToArr.month;
      let finalDateToDay = dateToArr.day; 

      if(finalDateToMonth < 10) {
        finalDateToMonth = "0" + finalDateToMonth;
      }

      if(finalDateToDay < 10) {
        finalDateToDay = "0" + finalDateToDay;
      }
      let finalDateTo = finalDateToYear + "-" + finalDateToMonth + "-" + finalDateToDay;

    var rowsToAdd = [];

    this.state.rowData.forEach((element) => {
      var itemToAdd = {
        discount: element.discount,
        itemCode: element.code,
        itemName: element.name,
        lineTotal: element.lineTotal,
        price: element.price,
        quantity: element.quantity,
      };
      rowsToAdd.push(itemToAdd);
    });

    if(this.state.supplierDetails.id === "none" || this.state.supplierDetails.id === ""){
      var obj = {
        billNumber: this.state.invoiceNumber,
        address: this.state.supplierDetails.address,
        billFrom: this.state.supplierDetails.billFrom,
        grnNumber: this.state.data.grnNo,
        invoiceDate: this.state.data.invoiceDate,
        purchaseDateNepali: this.state.data.purchaseDateNepali,
        pan: this.state.supplierDetails.pan,
        paymentMode: this.state.data.paymentMode,
        purchaseBy:
          this.state.data.purchaseBy === undefined
            ? ""
            : this.state.data.purchaseBy,
        purchaseDate: finalDateTo,
        purchaseOrderNumber:
          this.state.data.purchaseOrderNo === undefined
            ? ""
            : this.state.data.purchaseOrderNo,
        fiscalYear: this.state.fiscalYear,
        period: this.state.currentPeriod,
        purchaseDetails: rowsToAdd,
        subTotal: this.state.subTotal,
        taxes: this.state.taxes,
        totalAmount: this.state.grandTotal,
        totalDiscount: this.state.totalDiscount,
        purchaseNo: this.state.purchaseNo,
        
        }
    } else{
      var obj = {
        billNumber: this.state.invoiceNumber,
        address: this.state.supplierDetails.address,
        billFrom: this.state.supplierDetails.billFrom,
        grnNumber: this.state.data.grnNo,
        invoiceDate: this.state.data.invoiceDate,
        purchaseDateNepali: this.state.data.purchaseDateNepali,
        pan: this.state.supplierDetails.pan,
        paymentMode: this.state.data.paymentMode,
        purchaseBy:
          this.state.data.purchaseBy === undefined
            ? ""
            : this.state.data.purchaseBy,
        purchaseDate: finalDateTo,
        purchaseOrderNumber:
          this.state.data.purchaseOrderNo === undefined
            ? ""
            : this.state.data.purchaseOrderNo,
        fiscalYear: this.state.fiscalYear,
        period: this.state.currentPeriod,
        purchaseDetails: rowsToAdd,
        subTotal: this.state.subTotal,
        taxes: this.state.taxes,
        totalAmount: this.state.grandTotal,
        totalDiscount: this.state.totalDiscount,
        purchaseNo: this.state.purchaseNo,
        supplier: {
          id: this.state.supplierDetails.id,
        },
      }
    }

    const token = this.context;

    // if (obj.supplier.id === "") {
    //   this.setState({ emptySupplier: true });
    //   return;
    // }

    if (obj.purchaseDetails.length === 0) {
      var errorSnackbarProps = {
        open: true,
        variant: "error",
        message: "No item selected",
        autoHideDuration: 2000,
      };
      token.setSnackbarProps({ snackbarProps: errorSnackbarProps });
      return;
    }
    Axios.post("pos/purchase/addPurchase", obj)
      .then((res) => {
        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000,
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });
        this.resetFormValues();
      })
      .catch((err) => {
        var message = err.message;
        if (err.response !== undefined) {
          message = err.response.data;
        }
        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000,
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });
      });
      this.setState({confirmOpen:false})
  };

  resetFormValues() {
    this.setState((prevState) => ({
      ...prevState,
      rowRefs: [],
      supplierRowRefs: [],
      invoiceNumber: "",
      rowData: [],
      supplierDetails: {
        supplierIdNumber: "",
        id: "",
        billFrom: "",
        address: "",
        pan: "",
      },
      data: {
        //purchaseDate: "",
        invoiceDate: "",
        billNo: 0,
        grnNo: "",
        purchaseBy: "",
        paymentMode: "Cash",
        purchaseOrderNo: "",
      },
      subTotal: 0,
      taxes: 0,
      totalDiscount: 0,
      grandTotal: 0,
      supplier: "None",
      emptySupplier: false,
      sn: 1,
    }));

    //this.getBillingInfo();
    this.getNumbering(this.state.fiscalYear);
    this.getSupplier();
    this.getInventoryItems();
  }

  getBillingInfo() {
    Axios.get("pos/purchase/getBillInfo")
      .then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          billNumber: res.data.billNo,
          data: {
            ...prevState.data,
            invoiceDate: res.data.invoiceDate,
            purchaseDate: res.data.invoiceDate,
          },
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleQuantityChange = (evt) => {
    var val = evt.target.value;

    if (this.state.purchaseOrderStatus === true) {
      if (val < 1) {
        this.setState({ quantity: 0 });
      } else if (val > this.state.quantityMax) {
        this.setState({ quantity: this.state.quantityMax });
      } else {
        this.setState({ quantity: Math.round(val * 100) / 100 });
      }
    } else {
      this.setState({ quantity: Math.round(val * 100) / 100 });
    }
  };

  handleItemSelect = async (event, data) => {
    const { value } = data;

    await this.state.resData.forEach(async (element) => {
      if (element.itemCode === value) {
        this.setState((prevState) => ({
          ...prevState,
          quantityMax: element.quantity,
          itemDetails: element,
        }));
      }
    });
  };

  handle0Index = (rowAllData, itemData) => {
    var rowItemData = {
      sn: this.state.sn,
      code: itemData.itemCode,
      name: itemData.itemName,
      quantity: this.state.quantity,
      discount: this.state.discount,
      price: itemData.rate,
      lineTotal: this.state.quantity * itemData.rate - this.state.discount,
    };
    var toSave = true;

    rowAllData.forEach((element) => {
      if (element.name === itemData.itemName) {
        toSave = false;
      }
    });

    if (toSave) {
      rowAllData.push(rowItemData);
    }
  };

  getAllRows = () => {
    let rowData = [];
    this.gridApi.forEachNode((node) => rowData.push(node.data));

    return rowData;
  };

  handleItemAdd = async () => {
    var rows = this.getAllRows();
    var itemData = this.state.itemDetails;

    if (
      this.state.quantity === "0" ||
      itemData.itemName === undefined ||
      this.state.quantity === 0
    ) {
      return;
    }

    let schemeType =
    itemData.scheme === undefined ? "" : itemData.scheme.schemeType;
    let discount = 0;

    if (schemeType === "DiscountPrice") {
      discount = (
        (itemData.scheme.discountPercentage / 100) *
        itemData.total
      ).toFixed(2);
    }

    var rowAllData = this.state.rowData;

    var taxAmount = 0;
     var taxTotal = this.state.taxes;
     var subTotal =
      this.state.subTotal + itemData.rate * this.state.quantity;
     var totalDiscount = parseFloat(
      this.state.discount + discount * this.state.quantity
    );
    
    
    console.log(this.state.discount);
    console.log({ totalDiscount });
    console.log(discount);
    var total = subTotal - totalDiscount;
    var currentMax = this.state.quantityMax;

    await new Promise((accept) =>
      this.setState(
        {
          subTotal: subTotal,
          taxes: taxTotal,
          discount: totalDiscount,
          grandTotal: total.toFixed(2),
          quantityMax: this.state.quantityMax - this.state.quantity,
        },
        accept
      )
    );
    var rowsToSave = [];

    if (rows.length === 0) {
      this.handle0Index(rowAllData, itemData);
    } else {
      for (let index = 0; index < rows.length; index++) {
        const element = rowAllData[index];

        if (element.name === itemData.itemName) {
          var newQuantity = element.quantity + this.state.quantity;
          var newDiscount = element.discount * newQuantity;
          var newPrice = element.price;
          var newLineTotal = element.price * newQuantity - this.state.discount;

          var rowSpecificData = {
            ...element,
            taxes: taxAmount,
            quantity: newQuantity,
            discount: newDiscount,
            price: newPrice,
            lineTotal: newLineTotal.toFixed(2),
          };

          rowAllData[index] = rowSpecificData;
          rowAllData.forEach((element) => {
            var itemToAdd = {
              discount: element.discount,
              itemCode: element.code,
              itemName: element.name,
              lineTotal: element.lineTotal,
              sellingPrice: element.sellingPrice,
              quantity: element.quantity,
              taxRate: element.taxes,
            };
            rowsToSave.push(itemToAdd);
          });
        } else {
          this.handle0Index(rowAllData, itemData);
        }
      }
    }

    this.setState({ sn: this.state.sn + 1 });
    this.setState({ rowData: rowAllData });
    this.gridApi.setRowData(rowAllData);
    this.setState({
      quantityMax: this.state.quantityMax - this.state.quantity,
      quantity: 1,
    });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  onCellValueChanged(params) {
    var rowAllData = this.state.rowData;

    var subTotal = 0;
    var totalDiscount = 0;
    var grandTotal = 0;

    rowAllData.forEach((element) => {
      console.log("On Cell Value Changed")
      console.log(rowAllData)
      if (element.sn === params.data.sn) {
        var obj = element;
        obj.lineTotal = obj.quantity * obj.price - obj.discount * obj.quantity;
      }

      totalDiscount += element.discount * element.quantity;
      subTotal += element.lineTotal;
      grandTotal += element.lineTotal;
    });

    this.setState({
      subTotal,
      totalDiscount,
      grandTotal,
    });
    this.setState({ rowData: rowAllData });
    this.gridApi.setRowData(rowAllData);
  }

  deleteRow = (data) => {
    var rowAllData = this.state.rowData;
    var length = rowAllData.length;
    var finalRows = [];

    finalRows = rowAllData;

    for (var i = 0; i < length; i++) {
      var obj = rowAllData[i];

      if (obj.sn === data.sn) {
        finalRows.splice(i, 1);
        var subTotalNow = this.state.subTotal;

        var discountNow = this.state.totalDiscount;
        var totalNow = this.state.grandTotal;

        var subTotalNew = subTotalNow - obj.price * obj.quantity;
        var discountNew = discountNow - obj.discount * obj.quantity;
        var totalNew =
          totalNow - (obj.price * obj.quantity - obj.discount * obj.quantity);

        this.setState({
          subTotal: subTotalNew,
          totalDiscount: discountNew,
          grandTotal: totalNew,
        });
        break;
      }
    }
  };

  handleRadioChange = (evt) => {
    this.setState({ value: evt.target.value });
  };

  handleAddSupplier = () => {
    this.handleOpen();
  };

  handleTextChange = (e) => {
    e.stopPropagation();
    var targetName = e.target.name;
    var value = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [targetName]: value,
      },
    }));
  };

  handleInvoiceNumberChange = (e) => {
    e.stopPropagation();

    var value = e.target.value;

    this.setState((prevState) => ({
      ...prevState,
      invoiceNumber: value,
    }));
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Grid
          container
          justify="center"
          spacing={2}
          className={classes.containerWidth}
        >
          <Grid item xs={12} sm={6} md={9}>
            {this.state.emptySupplier ? (
              <TextField
                id="outlined-dense"
                select
                error
                helperText="Select a supplier first"
                label="Supplier Name"
                name="supplierName"
                value={this.state.supplier}
                onChange={this.handleSupplierSelect}
                fullWidth
                margin="dense"
                variant="outlined"
              >
                {this.state.supplierRowRefs.map((value, id) => (
                  <MenuItem key={id} value={value.value}>
                    {value.text}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              <TextField
                id="outlined-dense"
                select
                label="Supplier Name"
                name="supplierName"
                value={this.state.supplier}
                onChange={this.handleSupplierSelect}
                fullWidth
                margin="dense"
                variant="outlined"
              >
                {this.state.supplierRowRefs.map((value, id) => (
                  <MenuItem key={id} value={value.value}>
                    {value.text}
                  </MenuItem>
                ))}
              </TextField>
            )}

          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="outlined-dense"
              label="Supplier Id Number"
              name="supplierIdNumber"
              value={this.state.supplierDetails.supplierIdNumber}
              margin="dense"
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              variant="outlined"
              select
              margin="dense"
              fullWidth
              label="Fiscal Year"
              name="fiscalYear"
              onChange={this.handleFYChange}
              value={this.state.fiscalYear}
            >
              {this.state.fyList.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="outlined-dense"
              label="Purchase No."
              name="purchaseNo"
              margin="dense"
              fullWidth
              variant="outlined"
              value={this.state.purchaseNo}
             // onChange={this.handleChange}
            >
            </TextField>
          </Grid>
          
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              variant="outlined"
              select
              margin="dense"
              fullWidth
              label="Period"
              name="period"
              onChange={this.handlePeriodChange}
              value={this.state.currentPeriod}
            >
              {this.state.periodList.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
          <TextField
              id="outlined-dense"
              label="Purchase Date"
              name="purchaseDate"
              margin="dense"
              fullWidth
              variant="outlined"
              value={this.state.data.purchaseDate}
              InputLabelProps={{shrink:true}}
            >
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            {this.state.searchNumber ? (
              <TextField
                fullWidth
                id="outlined-dense"
                label="Purchase Order No."
                margin="dense"
                onChange={this.handleOrderNumberChange}
                variant="outlined"
              />
            ) : (
              <TextField
                fullWidth
                error
                helperText="Purchase Order No. not found"
                id="outlined-dense"
                label="Purchase Order No."
                margin="dense"
                onChange={this.handleOrderNumberChange}
                variant="outlined"
              />
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="outlined-dense"
              label="Purchase By"
              name="purchaseBy"
              value={this.state.data.purchaseBy}
              onChange={this.handlePurchaseByChange}
              fullWidth
              margin="dense"
              variant="outlined"
            />
          </Grid>
          
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="outlined-dense"
              label="Invoice Number"
              name="invoiceNumber"
              value={this.state.invoiceNumber}
              onChange={this.handleInvoiceNumberChange}
              margin="dense"
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} style={{marginTop: "8px"}}>

            <NepaliDatepicker
              id="nepali-date"
              onChange={this.handleDateChange}
              label="Invoice Date"
              value={this.state.data.invoiceDate}
              locale= "en"
              showDefaultDate="true"
              style={{height: "5px", width: "5px"}}
            />

           {/*  <TextField
              id="outlined-dense"
              label="Invoice Date"
              name="invoiceDate"
              type="date"
              value={this.state.data.invoiceDate}
              onChange={this.handleTextChange}
              fullWidth
              margin="dense"
              variant="outlined"
            /> */}
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="outlined-dense"
              label="Address"
              name="address"
              value={this.state.supplierDetails.address}
              fullWidth
              margin="dense"
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="outlined-dense"
              label="PAN"
              name="pan"
              value={this.state.supplierDetails.pan}
              fullWidth
              margin="dense"
              variant="outlined"
            />
          </Grid>
          
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="outlined-dense"
              select
              label="Payment Mode"
              name="paymentMode"
              value={this.state.data.paymentMode}
              onChange={this.handleTextChange}
              margin="dense"
              variant="outlined"
              fullWidth
            >
              <MenuItem key={1} value={"Cash"}>
                Cash
              </MenuItem>
              <MenuItem key={2} value={"Cheque"}>
                Cheque
              </MenuItem>
              <MenuItem key={3} value={"Credit"}>
                Credit
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="outlined-dense"
              label="GRN Number"
              onChange={this.handleTextChange}
              name="grnNo"
              value={this.state.data.grnNo}
              fullWidth
              margin="dense"
              variant="outlined"
            />
          </Grid>

        </Grid>

        <Divider style={{ marginTop: "15px", marginBottom: "5px" }} />

        <Grid
          container
          justify="center"
          spacing={2}
          className={classes.bottomContainerWidth}
        >
          <Grid item xs={12} sm={12} md={7}>
            <Dropdown
              placeholder="Search by Item Code or Item Name"
              clearable={true}
              fluid
              fullWidth
              search
              style={{ marginTop: "15px" }}
              ref={(search) => {
                this.searchInput = search;
              }}
              selection
              onChange={this.handleItemSelect}
              options={this.state.rowRefs}
            ></Dropdown>
          </Grid>

          <Grid item xs={7} sm={8} md={3}>
            <Input
              placeholder="Quantity"
              style={{ width: "100%", marginTop: "15px" }}
              type="number"
              min={1}
              onChange={this.handleQuantityChange}
              value={this.state.quantity}
            ></Input>
          </Grid>
          <Grid item xs={5} sm={4} md={2}>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              onClick={this.handleItemAdd}
              style={{
                minWidth: "60px",
                margin: "auto",
                height: "38px",
                marginTop: "15px",
              }}
            >
              +Add
            </Button>
          </Grid>

          <div
            className="ag-theme-balham"
            style={{
              height: "240px",
              width: "100%",
              margin: "10px",
            }}
          >
            <AgGridReact
              columnDefs={this.state.columnDefs}
              rowData={this.state.rowData}
              animateRows={true}
              rowSelection={this.state.rowSelection}
              onGridReady={this.onGridReady}
              onCellValueChanged={this.onCellValueChanged.bind(this)}
              enableCellChangeFlash={true}
            ></AgGridReact>
          </div>
          <div></div>
        </Grid>

        <Grid
          container
          justify="center"
          spacing={4}
          className={classes.bottomContainerWidth}
        >
          <Grid item xs={12} sm={6} md={3}>
            <Grid container justify="center">
              <p>
                Sub Total: <span style={{ display: "inline-block" }}></span>
                <strong>Rs. {this.state.subTotal}</strong>
              </p>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid container justify="center">
              <p>
                Taxes: <span style={{ display: "inline-block" }}></span>
                <strong>Rs. {this.state.taxes}</strong>
              </p>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid container justify="center">
              <p>
                Discount: <span style={{ display: "inline-block" }}></span>
                <strong>Rs. {this.state.totalDiscount}</strong>
              </p>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid container justify="center">
              <p>
                Total: <span style={{ display: "inline-block" }}></span>
                <strong>Rs. {this.state.grandTotal}</strong>
              </p>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justify="center" className={classes.bottonTopMargin}>
          <Button
            variant="outlined"
            color="primary"
            onClick={this.handleConfirm}
            style={{
              minWidth: "100px",
              width: "20vw",
              margin: "auto",
              height: "50px",
            }}
          >
            Save
          </Button>
        </Grid>
        <ConfirmationDialog
          open={this.state.confirmOpen}
          handleClose={() => {
            this.setState({ confirmOpen: false });
          }}
          handleConfirm={this.handleSubmit}
          titleMessage={"Save Purchase?"}
          bodyMessage={
            "Are you sure to save this Purchase?"
          }
        />
        <AddSupplierModal
          open={this.state.open}
          handleClose={this.handleClose}
        />
        <VoucherNumberRestriction
          open={this.state.voucherNumberError}
        ></VoucherNumberRestriction>
      </div>
    );
  }
}

export default withStyles(styles)(PurchaseForm);
