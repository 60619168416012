import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Card,
  CardContent,
  Button,
  TableHead,
  TableRow,
  TableCell,
  Table,
  FormControl,
  FormLabel,
  withStyles,
} from "@material-ui/core";
import BarCodeInventoryList from "./BarCodeInventoryList";
import { Input } from "semantic-ui-react";
import { useState } from "react";
import { withRouter } from "react-router-dom";
import TableContainer from "@material-ui/core/TableContainer";
import UserContext from "../../utils/UserContext";

const styles = (theme) => ({
  root: {
    width: "100%",
    [theme.breakpoints.up("xs")]: {
      marginBottom: "170px",
      margin: "auto",
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "120px",
      margin: "auto",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
      margin: "auto",
    },
  },
  cardSize: {
    [theme.breakpoints.down("sm")]: {
      height: "135vh",
    },
    [theme.breakpoints.up("sm")]: {
      height: "117vh",
    },
    [theme.breakpoints.up("lg")]: {
      height: "80vh",
    },
  },
  tableHeight: {
    marginBottom: "10px",
    border: "1px solid #EAEDEF",
    [theme.breakpoints.up("sm")]: {
      height: "62vh",
    },
    [theme.breakpoints.up("md")]: {
      height: "70vh",
    },
    [theme.breakpoints.up("lg")]: {
      height: "42vh",
    },
  },
});

function BarCodeGenerator(props) {
  const { index, value, companyInvoicingMethod, classes } = props;

  const [printList, setPrintList] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);

  const context = useContext(UserContext);

  const [error, setError] = useState(false);

  const styledTableCell = {
    backgroundColor: "#F1F8FC",
    color: "#3F51B5",
    borderRight: "1px solid #EAEDEF",
  };

  const saveTableList = (tableList) => {
    var totalQuantity = 0;

    tableList.forEach((element) => {
      totalQuantity += parseInt(element.quantity);
    });
    setPrintList(tableList);
    saveToContext(tableList);
    setTotalQuantity(totalQuantity);
  };

  const rowTableCell = {
    borderRight: "1px solid #EAEDEF",
    borderBottom: "1px solid #EAEDEF",
  };

  const handleQuantityChange = (e) => {
    e.stopPropagation();

    console.log(printList);

    var targetName = e.target.name;
    var value = e.target.value;

    console.log(value);

    var id = targetName.split(".")[1];

    var tableList = Object.assign([], printList);

    tableList.forEach((element) => {
      console.log(element.id + " " + id);
      if (parseInt(element.id) === parseInt(id)) {
        element["quantity"] = value;
      }
    });

    setPrintList(tableList);

    saveToContext(tableList, id);
  };

  const reBalance = (tableList, totalQ, id) => {
    tableList.forEach((element, i) => {
      if (totalQ % 12 !== 0 && parseInt(element.id) !== parseInt(id)) {
        console.log("><><><><><>");
        element.quantity = parseInt(element.quantity) + 1;

        console.log("before q" + totalQ);

        totalQ = 0;
        tableList.map((val) => (totalQ += parseInt(val.quantity)));
        console.log("after q" + totalQ);
      }
    });

    if (totalQ % 12 !== 0) {
      reBalance(tableList, totalQ, id);
    }
  };

  const saveToContext = (tableList, id) => {
    let toSave = [];

    var totalQuantity = 0;

    tableList.map((val) => (totalQuantity += parseInt(val.quantity)));

    try {
      if (totalQuantity % 12 !== 0) reBalance(tableList, totalQuantity, id);
    } catch (e) {
      console.log(e);
    }

    totalQuantity = 0;
    tableList.map((val) => (totalQuantity += parseInt(val.quantity)));

    setTotalQuantity(totalQuantity);

   // if (companyInvoicingMethod === "PAN") {
      tableList.forEach((element) => {
        for (let index = 0; index < parseInt(element.quantity); index++) {
          toSave.push({
            itemCode: element.itemCode,
            itemName: element.itemName,
            markedPrice: element.markedPrice,
            quantity: element.quantity,
          });
        }
      });
    // } else {
    //   tableList.forEach((element) => {
    //     for (let index = 0; index < parseInt(element.quantity); index++) {
    //       toSave.push({
    //         itemCode: element.itemCode,
    //         itemName: element.itemName,
    //         markedPrice: element.totalWithVat,
    //         quantity: element.quantity,
    //       });
    //     }
    //   });
    // }

    toSave = splitUp(toSave, 12);
    console.log(toSave);

    console.log(context);
    context.setBarCodes({ value: toSave });
  };

  const handleAllQuantityChange = (e) => {
    e.stopPropagation();

    var value = e.target.value;

    var tableList = Object.assign([], printList);

    tableList.map((val) => (val.quantity = value));

    setPrintList(tableList);
    saveToContext(tableList);
  };

  const splitUp = (arr, n) => {
    var res = [];

    while (arr.length) {
      res.push(arr.splice(0, n));
    }
    return res;
  };

  const handlePrint = () => {
    if (totalQuantity < 12) setError(true);
    else props.history.push("/barcodeDisplay");
  };

  return (
    <>
      <Grid container spacing={2} className={classes.root} justify="center">
        <Grid item xs={8}>
          <Card hidden={index !== value} className={classes.cardSize}>
            <CardContent>
              <BarCodeInventoryList
                setPrintList={saveTableList}
                saveToContext={saveToContext}
                companyInvoicingMethod={companyInvoicingMethod}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card hidden={index !== value} className={classes.cardSize}>
            <CardContent>
              <Grid
                container
                spacing={2}
                justify="center"
                style={{ width: "100%", marginBottom: "20px" }}
              >
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <FormLabel>
                      {error ? (
                        <span style={{ fontSize: "11px", color: "red" }}>
                          Total Quantity
                        </span>
                      ) : (
                        <span style={{ fontSize: "11px" }}>Total Quantity</span>
                      )}
                    </FormLabel>
                  </FormControl>
                  {error ? (
                    <Input
                      placeholder="Total Quantity"
                      value={totalQuantity}
                      style={{ width: "100%" }}
                      error
                    />
                  ) : (
                    <Input
                      style={{ width: "100%" }}
                      placeholder="Total Quantity"
                      value={totalQuantity}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <FormLabel>
                      <span style={{ fontSize: "11px" }}>Global Quantity</span>
                    </FormLabel>
                    <Input
                      style={{ width: "100%" }}
                      onChange={handleAllQuantityChange}
                    ></Input>
                  </FormControl>
                </Grid>
              </Grid>

              <TableContainer className={classes.tableHeight}>
                <Table
                  aria-label="customized table"
                  //   fixedHeader={false} style={{ width: "auto", tableLayout: "auto",  marginTop: "10px", marginBottom: "15px"  }}
                  stickyHeader
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell style={styledTableCell} align="center">
                        S. No.
                      </TableCell>
                      <TableCell style={styledTableCell} align="center">
                        Item Code
                      </TableCell>
                      <TableCell style={styledTableCell}>Item Name</TableCell>
                      <TableCell style={styledTableCell}>
                        Selling Price
                      </TableCell>
                      <TableCell style={styledTableCell}>Quantity</TableCell>
                    </TableRow>
                  </TableHead>

                  {printList.map((value, i) => (
                    <TableRow>
                      <TableCell align="center" style={rowTableCell}>
                        {i + 1}
                      </TableCell>
                      <TableCell align="center" style={rowTableCell}>
                        {value.itemCode}
                      </TableCell>
                      <TableCell
                        style={{
                          borderRight: "1px solid #EAEDEF",
                          borderBottom: "1px solid #EAEDEF",
                          width: "250px",
                        }}
                      >
                        {value.itemName}
                      </TableCell>
                      <TableCell style={rowTableCell}>
                        {value.markedPrice}
                        {/* {companyInvoicingMethod == "PAN" ? (
                          <> {value.markedPrice}</>
                        ) : (
                          <> {value.totalWithVat}</>
                        )} */}
                      </TableCell>
                      <TableCell style={rowTableCell}>
                        <Input
                          type="number"
                          value={value.quantity}
                          name={`quantity.${value.id}`}
                          onChange={handleQuantityChange}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </TableContainer>
              <Grid container justify="flex-end">
                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  onClick={handlePrint}
                  style={{
                    marginTop: "25px",
                  }}
                >
                  Print
                </Button>
              </Grid>
              {error ? (
                <span style={{ color: "red" }}>
                  Minimum number of total barcodes is{" "}
                  <span style={{ fontWeight: "bold" }}>12</span>
                </span>
              ) : null}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

BarCodeGenerator.propTypes = {
  index: PropTypes.number,
  value: PropTypes.number,
  companyInvoicingMethod: PropTypes.string,
};

export default withRouter(withStyles(styles)(BarCodeGenerator));
