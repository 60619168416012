import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import {
  Menu,
  MenuItem,
  Modal,
  Backdrop,
  Fade,
  Card,
  CardContent,
  Grid,
  Radio,
  Typography,
  RadioGroup,
  FormControlLabel,
  InputAdornment,
  TextField,
  withStyles,
  Button,
  IconButton,
} from "@material-ui/core";
import Axios from "axios";
// import { Dropdown } from "semantic-ui-react";
import UserContext from "../../utils/UserContext";
import Close from "@material-ui/icons/Close";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { Form } from "semantic-ui-react";

const styles = (theme) => ({
  cardHeight: {
    minWidth: "300px",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      height: "85%",
      width: "90%",
    },
    [theme.breakpoints.up("md")]: {
      height: "85%",
      width: "90%",
    },
    [theme.breakpoints.up("lg")]: {
      height: "95%",
      width: "90%",
      maxHeight: "1150px",
      maxWidth: "1270px",
    },
  },
});

// export default withStyles(styles)(ViewSchemeButton);

function ViewSchemeButton(props) {
  const rowIndex = (params) => params.node.rowIndex + 1;

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [schemeType, setSchemeType] = useState("DiscountPrice");

  const { setSnackbarProps } = useContext(UserContext);

  const handleView = () => {
    setAnchorEl(null);
    setOpen(false);
    setModalOpen(true);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(Boolean(event.currentTarget));
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();
  };

  const handleSchemeTypeChange = (e) => {
    let value = e.target.value;

    setSchemeType(value);
  };

  const getDiscountRate = (schemeType, schemeDiscounts) => {
    if (schemeType === "DiscountPrice") {
      if (schemeDiscounts.length > 0) {
        return schemeDiscounts[0].discountPercentage;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const getBonusItemAndRate = (schemeType, schemeBonus, field) => {
    if (schemeType === "BonusItem") {
      if (schemeBonus.length > 0) {
        return schemeBonus[0][field];
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const handleSchemeUpdate = (id) => {
    var obj = props.value;

    Axios.put("pos/scheme/updateScheme/" + id, obj)
      .then((res) => {
        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000,
        };

        setSnackbarProps({ snackbarProps: snackbarProps });
        setModalOpen(false);
      })
      .catch((err) => {
        var snackbarProps = {
          open: true,
          variant: "error",
          message: err.message,
          autoHideDuration: 2000,
        };

        setSnackbarProps({ snackbarProps: snackbarProps });
      });
  };

  const modal = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const { classes } = props;
  const {
    id,
    dateFrom,
    dateTo,
    schemeBonus,
    tableData,
    schemeDiscounts,
  } = props.value;

  const returnView = (type) => {
    if (type === "DiscountPrice" || type === "sales") {
      return (
        <Grid container style={{ marginTop: "10px" }}>
          <Form>
            <Form.Field>
              <label>Discount Percentage</label>
              <TextField
                margin="dense"
                variant="outlined"
                value={getDiscountRate(schemeType, schemeDiscounts)}
                name="discountRate"
                //onChange={handleDiscountRateChanged}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Form.Field>
          </Form>
        </Grid>
      );
    } else if (type === "bonusItem") {
      return (
        <Grid container style={{ marginTop: "10px" }} spacing={2}>
          <Grid item xs={8}>
            <Form>
              <Form.Field>
                <label>Item Code</label>
                <TextField
                  variant="filled"
                  margin="dense"
                  fullWidth
                  value={getBonusItemAndRate(
                    schemeType,
                    schemeBonus,
                    "bonusItemCode"
                  )}
                  name="bonusItemCode"
                  //  onChange={handleBonusItemChanged}
                />
              </Form.Field>
            </Form>
          </Grid>
          <Grid item xs>
            <Form>
              <Form.Field>
                <label>Quantity</label>
                <TextField
                  variant="filled"
                  margin="dense"
                  fullWidth
                  value={getBonusItemAndRate(
                    schemeType,
                    schemeBonus,
                    "bonusItemQuantity"
                  )}
                  name="bonusItemQuantity"
                  //onChange={handleBonusQuantityChanged}
                />
              </Form.Field>
            </Form>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Form>
          <Grid container spacing={3} style={{ marginTop: "10px" }}>
            <Grid item xs={6}>
              <Form.Field>
                <label>On Total</label>
                <TextField
                  margin="dense"
                  variant="outlined"
                  style={{ width: "100%" }}
                  name="onTotal"
                  // onChange={handleOnTotalChanged}
                />
              </Form.Field>
            </Grid>
            <Grid item xs={6}>
              <Form.Field>
                <label>Give bonus item worth </label>
                <TextField
                  margin="dense"
                  variant="outlined"
                  style={{ width: "100%" }}
                  name="bonusWorth"
                  // onChange={handleBonusWorthChanged}
                />
              </Form.Field>
            </Grid>
          </Grid>
        </Form>
      );
    }
  };

  return (
    <div>
      <span>
        <FontAwesomeIcon
          icon={faEllipsisV}
          onClick={handleClick}
          size="2x"
          style={{ cursor: "pointer" }}
        ></FontAwesomeIcon>
      </span>

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        <MenuItem key={1} onClick={handleView}>
          View
        </MenuItem>
        <MenuItem key={3}>
          <span style={{ color: "#FF5733" }}>Terminate</span>
        </MenuItem>
      </Menu>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={modal}
        open={modalOpen}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOpen}>
          <Card className={classes.cardHeight}>
            <CardContent style={{ padding: "20px", margin: "auto" }}>
              <Grid container>
                <Grid container justify="flex-end">
                  <IconButton onClick={handleModalClose}>
                    <Close />
                  </IconButton>
                </Grid>

                <Grid container>
                  <Grid item xs={12}>
                    <Grid container justify="flex-end">
                      <Button variant="contained" color="primary">
                        Edit Scheme
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container style={{ marginTop: "20px" }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    //onClick={() => setShowModal(true)}
                  >
                    Choose Item/Items
                  </Button>
                </Grid>

                <div
                  className="ag-theme-balham"
                  style={{
                    height: "240px",
                    width: "100%",
                    marginTop: "10px",
                  }}
                >
                  <AgGridReact
                    columnDefs={[
                      {
                        headerName: "SN",
                        field: "sn",
                        sortable: true,
                        valueGetter: rowIndex,
                        resizable: true,
                        width: 60,
                      },
                      {
                        headerName: "Item Code",
                        field: "itemCode",
                        sortable: true,
                        resizable: true,
                        width: 110,
                      },
                      {
                        headerName: "Item Name",
                        field: "itemName",
                        sortable: true,
                        resizable: true,
                      },
                      {
                        headerName: "Major Category",
                        field: "majorCategory",
                        sortable: true,
                        resizable: true,
                      },
                      {
                        headerName: "Category",
                        field: "category",
                        sortable: true,
                        resizable: true,
                      },
                      {
                        headerName: "Sub Category",
                        field: "subCategory",
                        sortable: true,
                        resizable: true,
                      },
                      {
                        headerName: "Delete",
                        width: 70,
                      },
                    ]}
                    rowData={tableData}
                    animateRows={true}
                    rowSelection="multiple"
                    onGridReady={onGridReady}
                    enableCellChangeFlash={true}
                  ></AgGridReact>
                </div>

                <Grid container style={{ marginTop: "10px" }} spacing={2}>
                  <Grid item>
                    <Form>
                      <Form.Field>
                        <label>Quantity</label>
                        <TextField
                          variant="filled"
                          margin="dense"
                          type="number"
                          name="quantity"
                          value={
                            schemeType === "BonusItem" && schemeBonus.length > 0
                              ? schemeBonus[0].itemQuantity
                              : ""
                          }
                          //onChange={handleQuantityChanged}
                        />
                      </Form.Field>
                    </Form>
                  </Grid>
                  <Grid item>
                    <Form>
                      <Form.Field>
                        <label>Effective Date From</label>
                        <TextField
                          variant="filled"
                          margin="dense"
                          type="date"
                          value={dateFrom}
                          name={"effective." + id}
                          onChange={props.handleEffectiveDateFrom}
                          //onChange={handleEffectiveDateFromChanged}
                        />
                      </Form.Field>
                    </Form>
                  </Grid>
                  <Grid item>
                    <Form>
                      <Form.Field>
                        <label>Effective Date To</label>
                        <TextField
                          variant="filled"
                          margin="dense"
                          type="date"
                          value={dateTo}
                          name={"effective." + id}
                          onChange={props.handleEffectiveDateTo}
                          // onChange={handleEffectiveDateToChanged}
                        />
                      </Form.Field>
                    </Form>
                  </Grid>
                </Grid>

                <Grid container style={{ marginTop: "20px" }}>
                  <Typography variant="h6">Scheme Type</Typography>
                </Grid>
                <Grid container style={{ marginTop: "10px" }}>
                  <RadioGroup
                    row
                    value={schemeType}
                    onChange={handleSchemeTypeChange}
                  >
                    <FormControlLabel
                      value="DiscountPrice"
                      control={<Radio color="primary" />}
                      label="Discount Rate"
                    />
                    <FormControlLabel
                      value="bonusItem"
                      control={<Radio color="primary" />}
                      label="Bonus Item"
                    />
                    <FormControlLabel
                      value="Loyalty"
                      control={<Radio color="primary" />}
                      label="Loyalty"
                    />
                    <FormControlLabel
                      value="sales"
                      control={<Radio color="primary" />}
                      label="Sales"
                    />
                  </RadioGroup>
                </Grid>

                {returnView(schemeType)}

                <Grid container justify="center">
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    //  onClick={this.handleSchemeUpdate(id)}
                    onClick={() => handleSchemeUpdate(id)}
                    style={{
                      marginBottom: "20px",
                      marginTop: "40px",
                      paddingLeft: "47px",
                      paddingRight: "47px",
                      paddingTop: "15px",
                      paddingBottom: "15px",
                    }}
                  >
                    Update Scheme
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Fade>
      </Modal>
    </div>
  );
}

export default withStyles(styles)(ViewSchemeButton);
