import React, { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import { NavLink, withRouter } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import NotificationIcon from "@material-ui/icons/Notifications";
import Container from "react-bootstrap/Container";
import UserContext, { UserConsumer } from "../utils/UserContext";
import {
  MenuList,
  MenuItem,
  ClickAwayListener,
  Avatar,
  Badge,
  Hidden,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemSecondaryAction,
  ListItemIcon,
} from "@material-ui/core";
import routes from "../utils/routes";
import { Popup } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCircle, faBars } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";

class Header extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      notiOpen: false,
      parentArr: [],
      routes: [],
      routeName: "",
      popUpOpen: false,
      windowWidth: "",
      openNav: false,
      profileOpen: false,
    };
  }

  handleArrowRef = (node) => {
    this.setState({
      arrowRef: node,
    });
  };

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      var routeName = "";

      routes.map((item, i) => {
        var pathName = window.location.pathname;

        if (pathName === item.path) {
          routeName = item.name;

          return null;
        } else {
          if (pathName === "/Dashboard") {
            routeName = "Dashboard";
          } else if (pathName === "/profile") {
            routeName = "Profile";
          }
        }

        return null;
      });

      this.setState({ routeName });
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  navigateToPath = (path) => {
    const token = this.context;

    token.setFilterFields({
      value: {
        dateFrom: "",
        dateTo: "",
        periodFrom: "",
        periodTo: "",
        fiscalYear: "",
      },
    });

    this.props.history.push(path);
  };

  componentDidMount() {
    this.setState({
      windowWidth: window.innerWidth,
    });
    window.addEventListener("resize", this.updateWindowSize);

    var parentArr = this.state.parentArr;

    var routeName = "";

    routes.map((item, i) => {
      var pathName = window.location.pathname;

      if (pathName === item.path) routeName = item.name;

      parentArr.push(item.parent);
      return null;
    });

    this.setState({ routeName });

    parentArr = [...new Set(parentArr)];

    this.setState({ parentArr });
  }

  updateWindowSize = () => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };

  handleToggle = (event) => {
    console.log("***************");
    console.log(event);
    console.log(this.state.profileOpen);
    console.log("***************");

    this.setState({
      profileOpen: !this.state.profileOpen,
      anchorEl: event.currentTarget,
    });
  };

  // handleOpenNav = event => {
  //   console.log(this.state.openNav)
  //   this.setState({ openNav: !this.state.openNav, anchorEl: event.currentTarget });
  // };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleProfileNav = () => {
    this.setState({ open: false });
    this.props.history.push("/profile");
  };

  handleLogOut = () => {
    localStorage.removeItem("Authorization");
    this.setState({ redirectToLogin: true, open: false });

    const token = this.context;
    token.setLoggedIn({ value: false });
    token.setRoles({ value: [] });

    this.props.history.push("/login");
  };

  handleSeen = (id, type) => {
    Axios.put("pos/notification/updateMyNotification/" + id)
      .then((res) => {
        console.log(res);
        Axios.get("pos/notification/findMyAllNotification")
          .then((res) => {
            console.log(res);

            let total = 0;

            res.data.map((val) => (val.status === true ? total++ : null));

            const token = this.context;

            this.setState({ notiOpen: false });

            token.setMyNotifications({ value: res.data });
            token.setUnseenCount({ value: total });

            routes.map((val) =>
              val.name === type ? this.props.history.push(val.path) : null
            );
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <UserConsumer>
        {(context) =>
          context.isLoggedIn ? (
            <div
              style={{ position: "fixed", zIndex: 19, width: "100vw" }}
              className="hideOnPrint"
            >
              <Hidden mdUp>
                <Navbar
                  bg="white"
                  expand="lg"
                  style={{ paddingLeft: "10px", paddingRight: "10px" }}
                >
                  <Grid container justify="space-between">
                    <Grid item>
                      <Grid
                        container
                        justify="flex-start"
                        style={{
                          padding: "7px",
                          border: "#108DCD 1px solid",
                          borderRadius: "5px",
                        }}
                      >
                        <Grid item>
                          <Popup
                            on="click"
                            open={this.state.openNav}
                            onOpen={() => this.setState({ openNav: true })}
                            onClose={() => this.setState({ openNav: false })}
                            basic
                            trigger={
                              <FontAwesomeIcon
                                icon={faBars}
                                size="lg"
                                style={{
                                  color: "#108DCD",
                                }}
                              ></FontAwesomeIcon>
                            }
                          >
                            <MenuList id="menu-list-grow">
                              {this.state.parentArr.map((category, i) => {
                                if (category === "Dashboard") {
                                  return null;
                                }
                                if (category === "Help") {
                                  return (
                                    <MenuItem
                                      as={NavLink}
                                      to={"/" + category}
                                      className="item"
                                      activeClassName="item"
                                      key={i}
                                    >
                                      {category}
                                    </MenuItem>
                                  );
                                }

                                let parentExists = false;

                                try {
                                  context.roles.forEach((element) => {
                                    element.roleTasks.map((contextVal) =>
                                      routes.map((val) =>
                                        val.name === contextVal.type &&
                                        val.parent === category
                                          ? (parentExists = true)
                                          : null
                                      )
                                    );
                                  });
                                } catch (error) {
                                  console.log(error);
                                }

                                if (parentExists) {
                                  return (
                                    <NavDropdown
                                      title={category}
                                      id="basic-nav-dropdown"
                                      key={i}
                                    >
                                      {routes.map((item, i) => {
                                        let exists = false;

                                        try {
                                          context.roles.forEach((element) => {
                                            element.roleTasks.map((val) =>
                                              val.type === item.name
                                                ? (exists = true)
                                                : null
                                            );
                                          });
                                        } catch (error) {
                                          console.log(error);
                                        }

                                        if (
                                          item.parent === category &&
                                          exists
                                        ) {
                                          return (
                                            <Grid
                                              key={i}
                                              container
                                              justify="flex-start"
                                            >
                                              <NavDropdown.Item key={i}>
                                                <Nav.Link
                                                  as={NavLink}
                                                  to={item.path}
                                                  className="item"
                                                  activeClassName="item"
                                                  style={{
                                                    padding: "4px",
                                                    fontSize: "14px",
                                                  }}
                                                >
                                                  <span
                                                    onClick={() =>
                                                      this.setState({
                                                        openNav: false,
                                                      })
                                                    }
                                                  >
                                                    {item.name}
                                                  </span>
                                                </Nav.Link>
                                              </NavDropdown.Item>
                                            </Grid>
                                          );
                                        } else {
                                          return null;
                                        }
                                      })}
                                    </NavDropdown>
                                  );
                                }

                                return null;
                              })}
                            </MenuList>
                          </Popup>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Navbar.Brand>
                        <Typography
                          variant="h6"
                          style={{
                            fontWeight: "bold",
                            color: "#108DCD",
                            fontSize: "14px",
                          }}
                        >
                          <span>
                            <NavLink
                              as={NavLink}
                              to="/Dashboard"
                              className="itemd"
                              activeClassName="itemd"
                            >
                              ASPIRE |{" "}
                            </NavLink>
                          </span>
                          <span
                            style={{ fontWeight: "normal", fontSize: "12px" }}
                          >
                            {this.state.routeName}
                          </span>
                        </Typography>
                      </Navbar.Brand>
                    </Grid>
                    <Grid item>
                      <Grid container justify="flex-end">
                        <Grid
                          item
                          style={{ marginTop: "10px", marginRight: "10px" }}
                        >
                          <Popup
                            on="click"
                            basic
                            style={{ padding: "5px" }}
                            offset="9, 0px"
                            open={this.state.notiOpen}
                            onOpen={() => this.setState({ notiOpen: true })}
                            onClose={() => this.setState({ notiOpen: false })}
                            trigger={
                              <Badge
                                style={{ marginRight: "10px" }}
                                badgeContent={context.unseenCount}
                                color="primary"
                              >
                                <NotificationIcon
                                  fontSize="default"
                                  style={{ cursor: "pointer" }}
                                />
                              </Badge>
                            }
                          >
                            <List
                              style={{
                                maxWidth: "500px",
                                width: "90vw",
                                marginRight: "5px",
                                marginLeft: "5px",
                                height: "400px",
                                overflowY: "auto",
                                padding: "0px",
                              }}
                            >
                              {context.myNotifications.map((val) => (
                                <>
                                  <ListItem alignItems="flex-start" button>
                                    <ListItemIcon
                                      onClick={() => this.handleSeen(val.id)}
                                    >
                                      <FontAwesomeIcon
                                        icon={faBell}
                                        style={{
                                          color: "#108DCD",
                                          marginTop: "5px",
                                          fontSize: "19px",
                                        }}
                                      ></FontAwesomeIcon>
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={val.remarks}
                                      onClick={() =>
                                        this.handleSeen(val.id, val.type)
                                      }
                                      secondary={
                                        <React.Fragment>
                                          {val.createdAt}
                                        </React.Fragment>
                                      }
                                    />
                                    {val.status ? (
                                      <ListItemSecondaryAction
                                        onClick={() => this.handleSeen(val.id)}
                                      >
                                        <FontAwesomeIcon
                                          icon={faCircle}
                                          size="sm"
                                          style={{
                                            color: "#108DCD",
                                          }}
                                        ></FontAwesomeIcon>
                                      </ListItemSecondaryAction>
                                    ) : null}
                                  </ListItem>
                                  <Divider variant="inset" component="li" />
                                </>
                              ))}
                            </List>
                          </Popup>
                        </Grid>
                        <Grid item>
                          <Popup
                            on="click"
                            open={this.state.profileOpen}
                            style={{ padding: "5px" }}
                            onOpen={() => this.setState({ profileOpen: true })}
                            onClose={() =>
                              this.setState({ profileOpen: false })
                            }
                            position="bottom right"
                            trigger={
                              <Avatar
                                style={{
                                  backgroundColor: "#3F51B5",
                                  color: "#fff",
                                  cursor: "pointer",
                                }}
                                onClick={this.handleToggle}
                              >
                                {context.employeeInfo.firstName[0] +
                                  context.employeeInfo.surname[0]}
                              </Avatar>
                            }
                          >
                            <ClickAwayListener onClickAway={this.handleClose}>
                              <MenuList
                                autoFocusItem={this.state.open}
                                id="menu-list-grow"
                              >
                                <MenuItem onClick={this.handleProfileNav}>
                                  My Account
                                </MenuItem>
                                <MenuItem>Settings</MenuItem>
                                <MenuItem onClick={this.handleLogOut}>
                                  Sign Out
                                </MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Popup>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Navbar>
              </Hidden>
              <Hidden smDown>
                {this.state.windowWidth > 1024 ? (
                  <Navbar
                    bg="white"
                    expand="lg"
                    style={{ paddingLeft: "15px", paddingRight: "15px" }}
                  >
                    <Navbar.Brand>
                      <Typography
                        variant="h5"
                        style={{
                          fontWeight: "bold",
                          color: "#108DCD",
                          fontSize: "21px",
                        }}
                      >
                        <span>
                          <NavLink
                            as={NavLink}
                            to="/Dashboard"
                            className="itemd"
                            activeClassName="itemd"
                          >
                            ASPIRE |{" "}
                          </NavLink>
                        </span>
                        <span
                          style={{ fontWeight: "normal", fontSize: "18px" }}
                        >
                          {this.state.routeName}
                        </span>
                      </Typography>
                    </Navbar.Brand>
                    <Grid container justify="center">
                      <Grid item>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                          <Container style={{ fontSize: "14px" }}>
                            <Nav className="mr-auto">
                              {this.state.parentArr.map((category, i) => {
                                if (category === "Dashboard") {
                                  return null;
                                }
                                if (category === "Help") {
                                  return (
                                    <Nav.Link
                                      as={NavLink}
                                      to={"/" + category}
                                      className="item"
                                      activeClassName="item"
                                      key={i}
                                    >
                                      {category}
                                    </Nav.Link>
                                  );
                                }

                                let parentExists = false;

                                try {
                                  context.roles.forEach((element) => {
                                    element.roleTasks.map((contextVal) =>
                                      routes.map((val) =>
                                        val.name === contextVal.type &&
                                        val.parent === category
                                          ? (parentExists = true)
                                          : null
                                      )
                                    );
                                  });
                                } catch (error) {
                                  console.log(error);
                                }

                                if (parentExists) {
                                  return (
                                    <NavDropdown
                                      title={category}
                                      id="basic-nav-dropdown"
                                      key={i}
                                    >
                                      {routes.map((item, i) => {
                                        let exists = false;

                                        try {
                                          context.roles.forEach((element) => {
                                            element.roleTasks.map((val) =>
                                              val.type === item.name
                                                ? (exists = true)
                                                : null
                                            );
                                          });
                                        } catch (error) {
                                          console.log(error);
                                        }

                                        if (
                                          item.parent === category &&
                                          exists
                                        ) {
                                          return (
                                            <NavDropdown.Item key={i}>
                                              <Nav.Link
                                                as={NavLink}
                                                to={item.path}
                                                onClick={() =>
                                                  this.navigateToPath(item.path)
                                                }
                                                className="item"
                                                activeClassName="item"
                                                style={{
                                                  padding: "7px",
                                                  fontSize: "14px",
                                                }}
                                              >
                                                {item.name}
                                              </Nav.Link>
                                            </NavDropdown.Item>
                                          );
                                        } else {
                                          return null;
                                        }
                                      })}
                                    </NavDropdown>
                                  );
                                }

                                return null;
                              })}
                            </Nav>
                          </Container>
                        </Navbar.Collapse>
                      </Grid>
                    </Grid>
                    <Popup
                      on="click"
                      open={this.state.notiOpen}
                      onOpen={() => this.setState({ notiOpen: true })}
                      onClose={() => this.setState({ notiOpen: false })}
                      position="bottom right"
                      offset="9, 0px"
                      trigger={
                        <Badge
                          style={{ marginRight: "25px" }}
                          badgeContent={context.unseenCount}
                          color="primary"
                        >
                          <NotificationIcon
                            fontSize="default"
                            style={{ cursor: "pointer" }}
                          />
                        </Badge>
                      }
                    >
                      <List
                        style={{
                          width: "500px",
                          height: "400px",
                          overflowY: "auto",
                        }}
                      >
                        {context.myNotifications.map((val) => (
                          <>
                            <ListItem alignItems="flex-start" button>
                              <ListItemIcon
                                onClick={() => this.handleSeen(val.id)}
                              >
                                <FontAwesomeIcon
                                  icon={faBell}
                                  style={{
                                    color: "#108DCD",
                                    marginTop: "5px",
                                    fontSize: "19px",
                                  }}
                                ></FontAwesomeIcon>
                              </ListItemIcon>
                              <ListItemText
                                primary={val.remarks}
                                onClick={() =>
                                  this.handleSeen(val.id, val.type)
                                }
                                secondary={
                                  <React.Fragment>
                                    {val.createdAt}
                                  </React.Fragment>
                                }
                              />
                              {val.status ? (
                                <ListItemSecondaryAction
                                  onClick={() => this.handleSeen(val.id)}
                                >
                                  <FontAwesomeIcon
                                    icon={faCircle}
                                    size="sm"
                                    style={{
                                      color: "#108DCD",
                                    }}
                                  ></FontAwesomeIcon>
                                </ListItemSecondaryAction>
                              ) : null}
                            </ListItem>
                            <Divider variant="inset" component="li" />
                          </>
                        ))}
                      </List>
                    </Popup>

                    <Popup
                      on="click"
                      position="bottom right"
                      open={this.state.open}
                      onOpen={() => this.setState({ profileOpen: true })}
                      onClose={() => this.setState({ profileOpen: false })}
                      trigger={
                        <Avatar
                          style={{
                            backgroundColor: "#3F51B5",
                            color: "#fff",
                            cursor: "pointer",
                          }}
                          onClick={this.handleToggle}
                        >
                          {context.employeeInfo.firstName[0] +
                            context.employeeInfo.surname[0]}
                        </Avatar>
                      }
                    >
                      <ClickAwayListener onClickAway={this.handleClose}>
                        <MenuList
                          autoFocusItem={this.state.open}
                          id="menu-list-grow"
                        >
                          <MenuItem onClick={this.handleProfileNav}>
                            My Account
                          </MenuItem>
                          <MenuItem>Settings</MenuItem>
                          <MenuItem onClick={this.handleLogOut}>
                            Sign Out
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Popup>
                  </Navbar>
                ) : (
                  <Navbar
                    bg="white"
                    expand="lg"
                    style={{ paddingLeft: "15px", paddingRight: "15px" }}
                  >
                    <Grid container justify="space-between">
                      <Grid item>
                        <Grid container justify="flex=start">
                          <Grid
                            item
                            style={{
                              marginRight: "20px",
                            }}
                          >
                            <Grid
                              container
                              style={{
                                padding: "7px",
                                border: "#108DCD 1px solid",
                                borderRadius: "5px",
                              }}
                            >
                              <Grid item>
                                <Popup
                                  on="click"
                                  open={this.state.openNav}
                                  onOpen={() =>
                                    this.setState({ openNav: true })
                                  }
                                  onClose={() =>
                                    this.setState({ openNav: false })
                                  }
                                  basic
                                  trigger={
                                    <FontAwesomeIcon
                                      icon={faBars}
                                      size="lg"
                                      style={{
                                        color: "#108DCD",
                                      }}
                                    ></FontAwesomeIcon>
                                  }
                                >
                                  <MenuList id="menu-list-grow">
                                    {this.state.parentArr.map((category, i) => {
                                      if (category === "Dashboard") {
                                        return null;
                                      }
                                      if (category === "Help") {
                                        return (
                                          <MenuItem
                                            as={NavLink}
                                            to={"/" + category}
                                            className="item"
                                            activeClassName="item"
                                            key={i}
                                          >
                                            {category}
                                          </MenuItem>
                                        );
                                      }

                                      let parentExists = false;

                                      try {
                                        context.roles.forEach((element) => {
                                          element.roleTasks.map((contextVal) =>
                                            routes.map((val) =>
                                              val.name === contextVal.type &&
                                              val.parent === category
                                                ? (parentExists = true)
                                                : null
                                            )
                                          );
                                        });
                                      } catch (error) {
                                        console.log(error);
                                      }

                                      if (parentExists) {
                                        return (
                                          <NavDropdown
                                            title={category}
                                            id="basic-nav-dropdown"
                                            key={i}
                                          >
                                            {routes.map((item, i) => {
                                              let exists = false;

                                              try {
                                                context.roles.forEach(
                                                  (element) => {
                                                    element.roleTasks.map(
                                                      (val) =>
                                                        val.type === item.name
                                                          ? (exists = true)
                                                          : null
                                                    );
                                                  }
                                                );
                                              } catch (error) {
                                                console.log(error);
                                              }

                                              if (
                                                item.parent === category &&
                                                exists
                                              ) {
                                                return (
                                                  <Grid
                                                    container
                                                    justify="flex-start"
                                                  >
                                                    <NavDropdown.Item key={i}>
                                                      <Nav.Link
                                                        as={NavLink}
                                                        to={item.path}
                                                        className="item"
                                                        activeClassName="item"
                                                        style={{
                                                          padding: "4px",
                                                          fontSize: "14px",
                                                        }}
                                                      >
                                                        {item.name}
                                                      </Nav.Link>
                                                    </NavDropdown.Item>
                                                  </Grid>
                                                );
                                              } else {
                                                return null;
                                              }
                                            })}
                                          </NavDropdown>
                                        );
                                      }

                                      return null;
                                    })}
                                  </MenuList>
                                </Popup>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Navbar.Brand>
                              <Typography
                                variant="h5"
                                style={{
                                  fontWeight: "bold",
                                  color: "#108DCD",
                                  fontSize: "21px",
                                }}
                              >
                                <span>
                                  <NavLink
                                    as={NavLink}
                                    to="/Dashboard"
                                    className="itemd"
                                    activeClassName="itemd"
                                  >
                                    ASPIRE |{" "}
                                  </NavLink>
                                </span>
                                <span
                                  style={{
                                    fontWeight: "normal",
                                    fontSize: "18px",
                                  }}
                                >
                                  {this.state.routeName}
                                </span>
                              </Typography>
                            </Navbar.Brand>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container justify="flex-end">
                          <Grid item style={{ marginTop: "10px" }}>
                            <Popup
                              on="click"
                              basic
                              style={{ padding: "5px" }}
                              offset="9, 0px"
                              open={this.state.notiOpen}
                              onOpen={() => this.setState({ notiOpen: true })}
                              onClose={() => this.setState({ notiOpen: false })}
                              trigger={
                                <Badge
                                  style={{ marginRight: "20px" }}
                                  badgeContent={context.unseenCount}
                                  color="primary"
                                >
                                  <NotificationIcon
                                    fontSize="default"
                                    style={{ cursor: "pointer" }}
                                  />
                                </Badge>
                              }
                            >
                              <List
                                style={{
                                  maxWidth: "500px",
                                  width: "90vw",
                                  marginRight: "5px",
                                  marginLeft: "5px",
                                  height: "400px",
                                  overflowY: "auto",
                                  padding: "0px",
                                }}
                              >
                                {context.myNotifications.map((val) => (
                                  <>
                                    <ListItem alignItems="flex-start" button>
                                      <ListItemIcon
                                        onClick={() => this.handleSeen(val.id)}
                                      >
                                        <FontAwesomeIcon
                                          icon={faBell}
                                          style={{
                                            color: "#108DCD",
                                            marginTop: "5px",
                                            fontSize: "19px",
                                          }}
                                        ></FontAwesomeIcon>
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={val.remarks}
                                        onClick={() =>
                                          this.handleSeen(val.id, val.type)
                                        }
                                        secondary={
                                          <React.Fragment>
                                            {val.createdAt}
                                          </React.Fragment>
                                        }
                                      />
                                      {val.status ? (
                                        <ListItemSecondaryAction
                                          onClick={() =>
                                            this.handleSeen(val.id)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={faCircle}
                                            size="sm"
                                            style={{
                                              color: "#108DCD",
                                            }}
                                          ></FontAwesomeIcon>
                                        </ListItemSecondaryAction>
                                      ) : null}
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                  </>
                                ))}
                              </List>
                            </Popup>
                          </Grid>
                          <Grid item>
                            <Popup
                              on="click"
                              open={this.state.profileOpen}
                              style={{ padding: "5px" }}
                              onOpen={() =>
                                this.setState({ profileOpen: true })
                              }
                              onClose={() =>
                                this.setState({ profileOpen: false })
                              }
                              position="bottom right"
                              trigger={
                                <Avatar
                                  style={{
                                    marginRight: "20px",
                                    backgroundColor: "#3F51B5",
                                    color: "#fff",
                                    cursor: "pointer",
                                  }}
                                  onClick={this.handleToggle}
                                >
                                  AM
                                </Avatar>
                              }
                            >
                              <ClickAwayListener onClickAway={this.handleClose}>
                                <MenuList
                                  autoFocusItem={this.state.open}
                                  id="menu-list-grow"
                                >
                                  <MenuItem onClick={this.handleProfileNav}>
                                    My Account
                                  </MenuItem>
                                  <MenuItem>Settings</MenuItem>
                                  <MenuItem onClick={this.handleLogOut}>
                                    Sign Out
                                  </MenuItem>
                                </MenuList>
                              </ClickAwayListener>
                            </Popup>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Navbar>
                )}
              </Hidden>
            </div>
          ) : null
        }
      </UserConsumer>
    );
  }
}

export default withRouter(Header);
