import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { Card, CardContent, Grid, withStyles, Button } from "@material-ui/core";
import UserContext from "../../utils/UserContext";
import ViewAuthorizedGoodReceived from "./ViewAuthorizedGoodReceived";
import ReactExport from "react-export-excel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const styles = (theme) => ({
  root: {
    padding: 10,
    paddingTop: "5px",
    paddingBottom: "5px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "170px",
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
    },
  },
});

class GoodReceivedNoteList extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    const rowIndex = (params) => params.node.rowIndex + 1;
    this.state = {
      excelData: [],
      columnDefs: [
        {
          headername: "S.No.",
          field: "sn",
          sortable: true,
          resizable: true,
          valueGetter: rowIndex,
          minWidth: 60,
          width: 60,
        },
        {
          headerName: "GRN No.",
          field: "grnNumber",
          sortable: true,
          resizable: true,
          minWidth: 125,
        },
        {
          headerName: "GRN Date",
          field: "grnDate",
          sortable: true,
          resizable: true,
          minWidth: 100,
        },
        {
          headerName: "PO Number",
          field: "purchaseOrderNumber",
          sortable: true,
          resizable: true,
          minWidth: 90,
        },
        {
          headerName: "Received From",
          field: "receivedFrom",
          resizable: true,
          sortable: true,
          minWidth: 130,
        },
        {
          headerName: "Received By",
          field: "receivedBy",
          sortable: true,
          resizable: true,
          minWidth: 130,
        },
        {
          headerName: "Invoice Number",
          field: "invoiceNumber",
          resizable: true,
          sortable: true,
          minWidth: 115,
        },
        {
          headerName: "Grand Total",
          field: "grandTotal",
          resizable: true,
          sortable: true,
          minWidth: 90,
        },
        {
          headerName: "View",
          cellRendererFramework: ViewAuthorizedGoodReceived,
          cellRendererParams: {
            showSnackbar: this.showSnackbar,
            getGRNData: this.getGRNData,
          },
          minWidth: 53,
          width: 53,
        },
      ],
      rowData: [],
    };
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  componentDidUpdate() {
    if (!this.gridApi) return;
    this.gridApi.sizeColumnsToFit();
  }

  componentWillReceiveProps(nextProps) {
    this.getExcelData(nextProps.data);
  }

  getExcelData = async (data) => {
    let excel = [];

    let object = {
      sn: "S.No.",
      grnNumber: "GRN Number",
      grnDate: "GRN Date",
      purchaseOrderNumber: "PO Number",
      receivedFrom: "Received From",
      receivedBy: "Received By",
      invoiceNumber: "Invoice Number",
      grandTotal: "Total",
    };
    excel.push(object);

    await data.map((el, i) => {
      let obj = {
        sn: i + 1,
        grnNumber: el.grnNumber,
        grnDate: el.grnDate,
        purchaseOrderNumber: el.purchaseOrderNumber,
        receivedFrom: el.receivedFrom,
        receivedBy: el.receivedBy,
        invoiceNumber: el.invoiceNumber,
        grandTotal: el.grandTotal,
      };
      excel.push(obj);

      return null;
    });

    await this.setState({
      excelData: excel,
    });
  };

  render() {
    const { children, value, index, classes, ...other } = this.props;

    return (
      <div>
        <Grid container>
          <Card
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            className={classes.root}
          >
            <CardContent>
              <Grid
                container
                spacing={2}
                justify="flex-end"
                style={{ marginBottom: "7px" }}
              >
                <Grid item>
                  <ExcelFile
                    filename="GRNAuthorizedList"
                    element={
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{
                          minWidth: "100px",
                          width: "6vw",
                          margin: "auto",
                          marginTop: "10px",
                          marginLeft: "20px",
                          height: "40px",
                          fontSize: "16px",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faFileExcel}
                          color="#3f51b5"
                          size="30px"
                          style={{
                            marginRight: "10px",
                          }}
                        ></FontAwesomeIcon>
                        Excel
                      </Button>
                    }
                  >
                    <ExcelSheet data={this.state.excelData} name="Sheet A">
                      <ExcelColumn value="sn" />
                      <ExcelColumn value="grnNumber" />
                      <ExcelColumn value="grnDate" />
                      <ExcelColumn value="purchaseOrderNumber" />
                      <ExcelColumn value="receivedFrom" />
                      <ExcelColumn value="receivedBy" />
                      <ExcelColumn value="invoiceNumber" />
                      <ExcelColumn value="grandTotal" />
                    </ExcelSheet>
                  </ExcelFile>
                </Grid>
              </Grid>

              <Grid container justify="flex-end">
                <div
                  className="ag-theme-balham"
                  style={{
                    height: "56vh",
                    width: "100%",
                    marginTop: "0px",
                  }}
                >
                  <AgGridReact
                    columnDefs={this.state.columnDefs}
                    rowData={this.props.data}
                    animateRows={true}
                    onGridReady={this.onGridReady}
                    rowSelection={this.state.rowSelection}
                    onRowSelected={this.onSelectionChanged}
                    overlayLoadingTemplate={this.state.overlayLoadingTemplate}
                    overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                  ></AgGridReact>
                </div>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(GoodReceivedNoteList);
