import React, { Component } from "react";
import {
  Grid,
  Card,
  Typography,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  withStyles,
  TableBody,
  Button,
  TableContainer,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuidv4 } from "uuid";
import {
  faChevronLeft,
  faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Label, Dropdown, Input } from "semantic-ui-react";
import Axios from "axios";
import UserContext from "../../utils/UserContext";
import Close from "@material-ui/icons/Close";

const styles = (theme) => ({
  root: {
    padding: 20,
    overflowY: "auto",
    [theme.breakpoints.up("xs")]: {
      width: "95vw",
      margin: "auto",
      marginBottom: "170px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "80vw",
      margin: "auto",
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "90vw",
      margin: "auto",
      marginBottom: "100px",
    },
  },
});

class ViewPaymentVoucherAuthorization extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      myTableList: [],
      rowRefs: [],
      subRowRefs: [],
      customerOptionList: [],
      idOptionList: [],
      customerData: [],
      requestData: {
        purchase: {
          id: "",
          supplier: "",
          purchaseDate: "",
          invoiceDate: "",
          totalAmount: 0,
          paymentMode: "",
        },
        supplier: {
          id: "",
          fullName: "",
        },
        id: "",
        paymentReference: "",
        paymentReferenceNumber: "",
        preparedBy: "",
        preparedDate: "",
        checkedBy: "",
        checkedDate: "",
        approvedBy: "",
        approvedDate: "",
        purchaseVoucherCredit: [],
        purchaseVoucherDebit: [],
        paymentReceivedBy: "",
        paymentDate: "",
        paymentReceiverPosition: "",
        paymentReceiverId: "",
        paymentReceiverEmail: "",
        paymentBankTransfer: "",
        bankReferenceNumber: "",
        total: "0",
      },
      accountList: [],
      subAccountList: [],
      costCenterList: [],
      accountOptions: [],
      subAccountOptions: [],
      costCenterOptions: [],
      tableList: [],
      tableRowData: {
        narration: "",
        costCenter: "",
        accountCode: "Account Code",
        subAccount: "Sub Account",
        currencyAnalysis: "",
        amount: "",
      },
      totalColor: "#FF8070",
      gridColor: [],
      editable: false,
      clicked: "#003F64",
      buttonColor: "#4aa5d8",
      emptyAmount: false,
      emptyCostCenter: false,
    };
  }

  handleCheck = () => {
    const token = this.context;

    var idList = [];

    if (parseInt(this.state.requestData.total) !== 0) {
      this.props.data.tableList.map((value, i) =>
        parseInt(value.amount) === parseInt(this.state.requestData.total)
          ? idList.push(i + 1)
          : null
      );
    }

    if (idList.length === 0 && parseInt(this.state.requestData.total) !== 0) {
      if (parseInt(this.state.requestData.total) < 0) {
        this.props.data.tableList.map((value, i) =>
          parseInt(value.amount) < 0 ? idList.push(i + 1) : null
        );
      } else {
        this.props.data.tableList.map((value, i) =>
          parseInt(value.amount) > 0 ? idList.push(i + 1) : null
        );
      }
    }

    console.log(idList);

    if (idList.length === 0) {
      var snackbarProps = {
        open: true,
        variant: "success",
        message: "Credit and Debit line balanced. Safe to POST.",
        autoHideDuration: 2000,
      };
      token.setSnackbarProps({ snackbarProps: snackbarProps });
    }

    var gridColor = this.state.gridColor;

    idList.map((value) => (gridColor[value] = "#FFA99E"));

    console.log({ gridColor: gridColor });

    this.setState({
      gridColor,
    });

    var newGridColors = [];

    idList.map((value) => newGridColors.push("#FFFFFF"));

    setTimeout(() => {
      this.setState({
        gridColor: newGridColors,
      });
    }, 1000);
  };

  getAllCostCenters = () => {
    Axios.get("pos/costCenter/findAll")
      .then((res) => {
        var optionList = [];

        res.data.map((val) =>
          optionList.push({ key: val.id, text: val.name, value: val.code })
        );

        this.setState((prevState) => ({
          ...prevState,
          costCenterList: res.data,
          costCenterOptions: optionList,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    this.getAllCostCenters();

    // Axios.get("pos/subLedger/getAllSubLedgers")
    //   .then((res) => {
    //     var optionList = [];

    //     res.data.map((val) =>
    //       optionList.push({ key: val.id, text: val.name, value: val.code })
    //     );

    //     this.setState({
    //       subAccountList: res.data,
    //       subAccountOptions: optionList,
    //     });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    var rowDetails = [];
    Axios.get("pos/ledgerMaster/getAllActiveAccountGroup")
      .then(async (res) => {
        res.data.forEach((element) => {
          var item = {
            key: element.code,
            value: element.code,
            text: element.code + "-" + element.name,
          };
          rowDetails.push(item);
        });

        await this.setState({
          accountList: res.data,
          rowRefs: rowDetails,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    var today = new Date();

    var month = (today.getMonth() + 1).toString();

    var day = today.getDate().toString();

    if (month.length === 1) {
      month = "0" + month;
    }

    if (day.length === 1) {
      day = "0" + day;
    }

    var date = today.getFullYear() + "-" + month + "-" + day;

    this.setState((prevState) => ({
      ...prevState,
      requestData: {
        ...prevState.requestData,
        date: date,
      },
    }));
  }

  deleteRow = (index) => {
    var currentRows = this.props.data.tableList;

    currentRows.splice(
      currentRows.findIndex((e) => e.id === index),
      1
    );

    var totalAmount = 0;
    currentRows.forEach((element, i) => {
      element.id = i + 1;
      totalAmount += parseInt(element.amount);
    });

    this.setState((prevState) => ({
      ...prevState,
      tableList: currentRows,
      requestData: {
        ...prevState.requestData,
        total: totalAmount,
      },
    }));
  };

  getSubAccountList = (accountCode) => {
    console.log("here");

    let rowDetails = [];

    Axios.get(
      "/pos/subLedger/getAllActiveSubLedgerByUnderCode/" + accountCode
    ).then((res) => {
      res.data.forEach((element) => {
        var item = {
          key: element.code,
          value: element.code,
          text: element.code + "-" + element.name,
        };
        rowDetails.push(item);
      });

      this.setState((prevState) => ({
        ...prevState,
        subAccountList: res.data.length >0 ? res.data : [{
          id: "None",
          code: "None",
          name: "None",
        }],
        subRowRefs: rowDetails.length > 0 ? rowDetails : [{
          key: "None",
          value: "None",
          text: "None"
        }],
      }));
    });
  };

  onChangedTableRow = (e) => {
    e.stopPropagation();

    if (!this.state.editable) return;

    var totalAmount = 0;

    var targetName = e.target.name;
    var value = e.target.value;

    var id = targetName.split(".")[1];
    var name = targetName.split(".")[0];

    var tableList = this.props.data.tableList;

    var arrIndex = tableList.findIndex((e) => e.id === id);

    var currentRow = tableList[arrIndex];

    if (name === "accountCode") {
      this.props.getSubAccountListforAuth(id, value);
    }

    if (name === "amount") {
      console.log({ value });
      var isCredit = Number(value).toFixed(2) < 0;

      var debitCredit = isCredit ? "Credit" : "Debit";

      currentRow["debitCredit"] = debitCredit;
      currentRow[name] = Number(value).toFixed(2);
    }

    currentRow[name] = value;

    tableList[arrIndex] = currentRow;

    tableList.forEach((element) => {
      totalAmount += Number(element.amount);
    });
    console.log({ totalAmount });
    var totalColor = "#FF8070";

    if (Number(totalAmount) === 0) totalColor = "#43A047";

    this.setState((prevState) => ({
      ...prevState,
      requestData: {
        ...prevState.requestData,
        total: totalAmount.toFixed(2),
      },
      totalColor,
    }));
  };
  handleTableInputChange= async(e,v)=> {
    e.stopPropagation();

    if (!this.state.editable) return;
    let subAccountList = []
    var value = e.target.value;

    await Axios.get(
      "/pos/subLedger/getAllActiveSubLedgerByUnderCode/" + value
    ).then((res) => {
      subAccountList = res.data.length>0 ? res.data : [{id: "None", code: "None",name: "None"}]
  })

  let newMyTableList = this.state.myTableList.map((item,index)=> {
    if(index === v) {
        item.subAccount = ""
        item.accountCode = value
        item.subAccountList = subAccountList
        return item
    } else {
      return item
    }
  })
  this.setState((prevState)=> ({
    ...prevState,
    myTableList: newMyTableList
  }))
}

  handleStatus = (status) => {
    const token = this.context;

    var id = this.props.data.id;

    Axios.put("pos/purchaseVoucher/updateStatus/" + id, { status: status })
      .then((res) => {
        console.log(res);

        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000,
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });

        this.props.handleViewCounter(id);
        this.props.getTableDataForAuth();
        this.props.getTableDataForList();
        if (status === "Approved" || status === "Rejected") {
          this.props.animateOut();
        }
      })
      .catch((err) => {
        console.log(err);

        var message = err.message;

        if (err.response !== undefined) {
          message = err.response.data;
        }

        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000,
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });
      });
  };

  onChangedAccountEntry = (e, data) => {
    this.setState((prevState) => ({
      ...prevState,
      tableRowData: {
        ...prevState.tableRowData,
        [data.name]: data.value,
      },
    }));

    if (data.name === "accountCode") this.getSubAccountList(data.value);
  };

  onChangedEntry = (e) => {
    e.stopPropagation();

    var targetName = e.target.name;
    var value = e.target.value;

    if (targetName === "accountCode") {
      this.getSubAccountList(value);
    }

    this.setState((prevState) => ({
      ...prevState,
      tableRowData: {
        ...prevState.tableRowData,
        [targetName]: value,
      },
    }));
  };

  addNewRow = () => {
    var tableList = this.state.myTableList;
    var gridColor = this.state.gridColor;

    var totalAmount = this.state.requestData.total;

    const {
      narration,
      costCenter,
      accountCode,
      subAccount,
      currencyAnalysis,
      amount,
    } = this.state.tableRowData;

    if (
      this.state.tableRowData.amount === "" &&
      this.state.tableRowData.costCenter === ""
    ) {
      this.setState((prevState) => ({
        ...prevState,
        emptyAmount: true,
        emptyCostCenter: true,
      }));
      return;
    } else if (this.state.tableRowData.amount === "") {
      this.setState((prevState) => ({
        ...prevState,
        emptyAmount: true,
        emptyCostCenter: false,
      }));
      return;
    } else if (this.state.tableRowData.costCenter === "") {
      this.setState((prevState) => ({
        ...prevState,
        emptyCostCenter: true,
        emptyAmount: false,
      }));
      return;
    }

    totalAmount = (parseFloat(totalAmount) + parseFloat(amount)).toFixed(2);

    var isCredit = parseFloat(this.state.tableRowData.amount).toFixed(2) < 0;
    var debitCredit = isCredit ? "Credit" : "Debit";

    var accountCodeSave = accountCode === "Account Code" ? "" : accountCode;
    var subAccountSave = subAccount === "Sub Account" ? "" : subAccount;
    var costCenterSave = costCenter === "Cost center" ? "" : costCenter;

    var newRowsPrep = {
      narration: narration,
      costCenter: costCenterSave,
      accountCode: accountCodeSave,
      subAccount: subAccountSave,
      subAccountList: this.state.subAccountList,
      amount: amount,
    };

    tableList.push({
      ...newRowsPrep,
      id: uuidv4(),
      debitCredit: debitCredit,
      currencyAnalysis: currencyAnalysis,
    });

    gridColor[this.props.data.tableList.length] = "#FFFFFF";

    var totalColor = "#FF8070";
    var toCheckTotal = 0;
    if (
      parseFloat(totalAmount).toFixed(2) === parseFloat(toCheckTotal).toFixed(2)
    )
      totalColor = "#43A047";

    this.setState((prevState) => ({
      ...prevState,
      tableList,
      tableRowData: {
        narration: "",
        costCenter: "",
        accountCode: "Account Code",
        subAccount: "Sub Account",
        currencyAnalysis: "",
        amount: "",
      },
      requestData: {
        ...prevState.requestData,
        total: totalAmount,
      },
      totalColor,
    }));
  };

  handleUpdate = async () => {
    const token = this.context;

    var supplierName = this.props.data.supplierName;
    var supplierIdNumber = this.props.data.supplierIdNumber;
    var invoiceNumber = this.props.data.invoiceNumber;
    var total = this.props.data.total;
    var paymentReferenceNumber = this.props.data.paymentReferenceNumber;
    var paymentMethod = this.props.data.paymentMethod;
    var fiscalYear = this.props.data.fiscalYear;
    var period = this.props.data.period;
    var date = this.props.data.date;
    var masterNarration = this.props.data.masterNarration;
    var chalanNumber = this.props.data.chalanNumber;
    var transportNumber = this.props.data.transportNumber;
    var toCheckTotal = 0;

    if (this.state.requestData.total === "-0.00") {
      await this.setState((prevState) => ({
        ...prevState,
        requestData: {
          ...prevState.requestData,
          total: parseFloat(toCheckTotal).toFixed(2),
        },
      }));
    }

    if (this.state.requestData.total !== parseFloat(toCheckTotal).toFixed(2)) {
      var snackbarProps = {
        open: true,
        variant: "error",
        message: "CREDIT and DEBIT line NOT BALANCED",
        autoHideDuration: 2000,
      };
      token.setSnackbarProps({ snackbarProps: snackbarProps });

      this.handleCheck();

      return;
    }

    var purchaseVoucherCredit = [];
    var purchaseVoucherDebit = [];

    this.props.data.tableList.forEach((element) => {
      var dataPrep = {
        narration: element.narration,
        costCenter: element.costCenter,
        accountCode: element.accountCode,
        subAccount: element.subAccount,
        amount: element.amount,
      };

      if (element.debitCredit === "Credit") {
        let splitAmount = element.amount.split("-");
        let creditAmount = splitAmount[1];

        purchaseVoucherCredit.push({
          ...dataPrep,
          analysis: element.currencyAnalysis,
          amount: creditAmount,
        });
      } else {
        purchaseVoucherDebit.push({
          ...dataPrep,
          currency: element.currencyAnalysis,
        });
      }
    });

    var reqData = this.props.data;

    reqData = {
      ...reqData,
      supplierName,
      supplierIdNumber,
      paymentReferenceNumber,
      paymentMethod,
      invoiceNumber,
      total,
      transportNumber,
      chalanNumber,
      fiscalYear,
      period,
      date,
      masterNarration,
      purchaseVoucherCredit,
      purchaseVoucherDebit,
    };

    var id = reqData.id;

    delete reqData.tableList;
    delete reqData.id;

    console.log({ reqData });

    Axios.put("pos/purchaseVoucher/updateVoucher/" + id, reqData)
      .then((res) => {
        console.log(res);

        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000,
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });

        this.props.handleViewCounter(id);
        this.props.getTableDataForAuth();
        this.setState((prevState) => ({
          ...prevState,
          editable: false,
        }));
      })
      .catch((err) => {
        console.log(err);

        var message = err.message;

        if (err.response !== undefined) {
          message = err.response.data;
        }

        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000,
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });
      });
  };

  handleItemSelect = (e, data) => {
    e.stopPropagation();
    const { value } = data;

    if (value === "add") {
      if (data.name === "accountCode") {
        this.setState({ addAccountOpen: true });
      } else {
        this.setState({ addSubAccountOpen: true });
      }
      return;
    }

    var targetName = data.name;

    if (targetName === "accountCode") {
      this.getSubAccountList(value);
      this.setState((prevState) => ({
        ...prevState,
        tableRowData: {
          ...prevState.tableRowData,
          subAccount: "",
          [targetName]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        tableRowData: {
          ...prevState.tableRowData,
          [targetName]: value,
        },
      }));
    }
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      myTableList: this.props.data.tableList
    })
  }


  customSearch = (_options, query) => {
    let queryText = query.toLowerCase();

    let arr = this.state.rowRefs;

    let newSet = arr.filter(
      (val) => val.text.toLowerCase().indexOf(queryText.toLowerCase()) !== -1
    );

    console.log({ newSet });

    if (isNaN(queryText)) {
      newSet = newSet.sort((x, y) =>
        x.text.split("-")[1][0].toLowerCase() === queryText
          ? -1
          : y.text.split("-")[1][0].toLowerCase() === queryText
          ? 1
          : 0
      );
    } else {
      newSet = newSet.sort((x, y) =>
        x.text[0] === queryText ? -1 : y.text[0] === queryText ? 1 : 0
      );
    }

    console.log({ newSet });

    return [
      {
        key: "add",
        value: "add",
        text: "Add new account code",
      },
      ...newSet,
    ];
  };

  customSearchForSub = (_options, query) => {
    let queryText = query.toLowerCase();

    let arr = this.state.subRowRefs;

    let newSet = arr.filter(
      (val) => val.text.toLowerCase().indexOf(queryText.toLowerCase()) !== -1
    );

    if (isNaN(queryText)) {
      newSet = newSet.sort((x, y) =>
        x.text.split("-")[1][0].toLowerCase() === queryText
          ? -1
          : y.text.split("-")[1][0].toLowerCase() === queryText
          ? 1
          : 0
      );
    } else {
      newSet = newSet.sort((x, y) =>
        x.text[0] === queryText ? -1 : y.text[0] === queryText ? 1 : 0
      );
    }

    console.log({ newSet });

    return [
      {
        key: "add",
        value: "add",
        text: "Add new sub-account code",
      },
      ...newSet,
    ];
  };

  render() {
    const {
      date,
      voucherNumber,
      invoiceNumber,
      paymentReferenceNumber,
      paymentMode,
      total,
      status,
      tableList,
      supplierIdNumber,
      supplierName,
      transportNumber,
      chalanNumber,
      grnReference,
      fiscalYear,
      period,
      masterNarration,
    } = this.props.data;

    const {
      fyList,
      periodList,
      handleFyChangeView,
      handlePeriodChange,
      idOptionList,
      supplierOptionList,
      handleTextChange,
      handleSupplierIdSelect,
      handleSupplierSelect,
    } = this.props;

    const { classes } = this.props;

    const styledTableCell = {
      padding: "10px",
      backgroundColor: "#F1F8FC",
      color: "#3F51B5",
      borderRight: "1px solid #EAEDEF",
    };

    const buttonStyle = {
      margin: "20px",
      marginTop: "25px",
      marginRight: "50px",
      paddingTop: "15px",
      paddingBottom: "15px",
      paddingRight: "50px",
      paddingLeft: "50px",
    };

    /*   var format = new Intl.NumberFormat("ne-NP", {
      minimumFractionDigits: 2,
    }); */

    return (
      <>
        <Card className={classes.root} hidden={this.props.hidden}>
          <Grid container justify="space-between">
            <Grid item>
              <Typography
                variant="h6"
                onClick={this.props.animateOut}
                style={{ cursor: "pointer", padding: "10px" }}
              >
                <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon> Voucher
                List
              </Typography>
            </Grid>
            <Grid item>
              <Grid container justify="flex-end">
                <IconButton onClick={this.props.animateOut}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>

          <Grid container style={{ padding: "20px" }}>
            <Grid container justify="flex-end" style={{ marginBottom: "20px" }}>
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={() => {
                  this.setState({
                    editable: !this.state.editable,
                    buttonColor: this.state.clicked,
                  });
                }}
                style={{ backgroundColor: this.state.buttonColor }}
              >
                Edit
              </Button>
            </Grid>
            <Grid container spacing={2}>
              {this.state.editable ? (
                <>
                  <Grid item xs={10}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Voucher Number"
                          name="voucerNumber"
                          value={voucherNumber}
                          margin="dense"
                        ></TextField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          fullWidth
                          select
                          variant="outlined"
                          label="Fiscal Year"
                          name="fiscalYear"
                          value={fiscalYear}
                          margin="dense"
                          onChange={handleFyChangeView}
                        >
                          {fyList.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          fullWidth
                          select
                          variant="outlined"
                          label="period"
                          name="period"
                          value={period}
                          margin="dense"
                          onChange={handlePeriodChange}
                        >
                          {periodList.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Date"
                          name="date"
                          type="date"
                          value={date}
                          margin="dense"
                          onChange={handleTextChange}
                        ></TextField>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Dropdown
                          placeholder="Search by Customer name"
                          fluid
                          fullWidth
                          search
                          style={{ marginTop: "8px" }}
                          selection
                          value={supplierName}
                          onChange={handleSupplierSelect}
                          options={supplierOptionList}
                        ></Dropdown>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Dropdown
                          placeholder="Search by Customer Id Number"
                          fluid
                          fullWidth
                          search
                          style={{ marginTop: "8px" }}
                          selection
                          value={supplierIdNumber}
                          onChange={handleSupplierIdSelect}
                          options={idOptionList}
                        ></Dropdown>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Transport Number"
                          name="transportNumber"
                          value={transportNumber}
                          margin="dense"
                          onChange={handleTextChange}
                        ></TextField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Chalan Number"
                          name="chalanNumber"
                          value={chalanNumber}
                          margin="dense"
                          onChange={handleTextChange}
                        ></TextField>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Invoice Number"
                          name="invoiceNumber"
                          value={invoiceNumber}
                          margin="dense"
                          onChange={handleTextChange}
                        ></TextField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Invoice Amount"
                          name="total"
                          value={total}
                          margin="dense"
                          onChange={handleTextChange}
                        ></TextField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Payment Reference Number"
                          name="paymentReferenceNumber"
                          value={paymentReferenceNumber}
                          margin="dense"
                          onChange={handleTextChange}
                        ></TextField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          fullWidth
                          select
                          variant="outlined"
                          name="paymenMode"
                          value={paymentMode}
                          margin="dense"
                          onChange={handleTextChange}
                        >
                          <MenuItem key={1} value={"Cash"}>
                            Cash
                          </MenuItem>
                          <MenuItem key={2} value={"Cheque"}>
                            Cheque
                          </MenuItem>
                          <MenuItem key={3} value={"Credit"}>
                            Credit
                          </MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="GRN Reference"
                          name="grnReference"
                          value={grnReference}
                          margin="dense"
                          onChange={handleTextChange}
                        ></TextField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={8}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Master Narration"
                          name="masterNarration"
                          value={masterNarration}
                          margin="dense"
                          onChange={handleTextChange}
                        ></TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} sm={6} md={4}>
                    <Input
                      fluid
                      style={{ marginTop: "5px" }}
                      label="Voucher Number"
                      value={voucherNumber}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Input
                      fluid
                      style={{ marginTop: "5px" }}
                      label="Fiscal Year"
                      value={fiscalYear}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Input
                      fluid
                      style={{ marginTop: "5px" }}
                      label="Period"
                      value={period}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Input
                      fluid
                      style={{ marginTop: "5px" }}
                      label="Date"
                      value={date}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Input
                      fluid
                      style={{ marginTop: "5px" }}
                      label="Supplier ID"
                      value={supplierIdNumber}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Input
                      fluid
                      style={{ marginTop: "5px" }}
                      label="Supplier Name"
                      value={supplierName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Input
                      fluid
                      style={{ marginTop: "5px" }}
                      label="Transport Number"
                      value={transportNumber}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Input
                      fluid
                      style={{ marginTop: "5px" }}
                      label="Chalan Name"
                      value={chalanNumber}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Input
                      fluid
                      style={{ marginTop: "5px" }}
                      label="Invoice Number"
                      value={invoiceNumber}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Input
                      fluid
                      style={{ marginTop: "5px" }}
                      label="Invoice Total"
                      value={total}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Input
                      fluid
                      style={{ marginTop: "5px" }}
                      label="Payment Reference Number"
                      value={paymentReferenceNumber}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Input
                      fluid
                      style={{ marginTop: "5px" }}
                      label="Payment Method"
                      value={paymentMode}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={8}>
                    <Input
                      fluid
                      style={{ marginTop: "5px" }}
                      label="Master Narration"
                      value={masterNarration}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Input
                      fluid
                      style={{ marginTop: "5px" }}
                      label="GRN Reference"
                      value={grnReference}
                    />
                  </Grid>
                </>
              )}
            </Grid>

            <Divider style={{ marginTop: "25px", marginBottom: "20px" }} />
            {this.state.editable ? (
              <>
                <Grid container>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: "bold", marginTop: "15px" }}
                  >
                    Entry
                  </Typography>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={8} lg={3} style={{ marginTop: "3px" }}>
                    <TextField
                      label="Narration"
                      fullWidth
                      variant="outlined"
                      onChange={this.onChangedEntry}
                      value={this.state.tableRowData.narration}
                      name="narration"
                      margin="dense"
                      // inputRef={this.textInput}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} lg={1} style={{ marginTop: "3px" }}>
                    {this.state.emptyCostCenter ? (
                      <>
                        <TextField
                          label="Cost Center"
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          select
                          value={this.state.tableRowData.costCenter}
                          name="costCenter"
                          helperText="field should not be empty"
                          error
                        ></TextField>
                      </>
                    ) : (
                      <>
                        <TextField
                          label="Cost Center"
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          select
                          value={this.state.tableRowData.costCenter}
                          name="costCenter"
                          onChange={this.onChangedEntry}
                        >
                          {this.state.costCenterList.map((value) => (
                            <MenuItem key={value.id} value={value.code}>
                              {value.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} lg={2} style={{ marginTop: "3px" }}>
                    {this.state.emptyAccountCode ? (
                      <>
                        <Dropdown
                          placeholder="Account Code"
                          name="accountCode"
                          fluid
                          fullWidth
                          //error
                          select
                          selectOnBlur={false}
                          variant="outlined"
                          search={this.customSearch}
                          style={{
                            marginTop: "8px",
                            border: "1px solid red",
                          }}
                          ref={(search) => {
                            this.searchInput = search;
                          }}
                          selection
                          onChange={this.handleItemSelect}
                          value={this.state.tableRowData.accountCode}
                          options={[
                            {
                              key: "add",
                              value: "add",
                              text: "Add new account code",
                            },
                            ...this.state.rowRefs,
                          ]}
                        ></Dropdown>
                        <Label
                          basic
                          style={{
                            border: "0px",
                            fontWeight: 400,
                            fontSize: "0.75rem",
                            font: "Helvetica",
                            color: "#f44336",
                            letterSpacing: "0.03333em",
                          }}
                        >
                          field should not be empty
                        </Label>
                      </>
                    ) : (
                      <Dropdown
                        placeholder="Account Code"
                        name="accountCode"
                        floating
                        fluid
                        fullWidth
                        selectOnBlur={false}
                        search={this.customSearch}
                        style={{ marginTop: "8px", zIndex: 200 }}
                        ref={(search) => {
                          this.searchInput = search;
                        }}
                        selection
                        onChange={this.handleItemSelect}
                        value={this.state.tableRowData.accountCode}
                        options={[
                          {
                            key: "add",
                            value: "add",
                            text: "Add new account code",
                          },
                          ...this.state.rowRefs,
                        ]}
                      ></Dropdown>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} lg={2} style={{ marginTop: "3px" }}>
                    <Dropdown
                      placeholder="Sub Account"
                      name="subAccount"
                      floating
                      fluid
                      fullWidth
                      selectOnBlur={false}
                      search={this.customSearchForSub}
                      style={{ marginTop: "8px" }}
                      ref={(search) => {
                        this.searchInput = search;
                      }}
                      selection
                      onChange={this.handleItemSelect}
                      value={this.state.tableRowData.subAccount}
                      options={[
                        {
                          key: "add",
                          value: "add",
                          text: "Add new sub-account code",
                        },
                        ...this.state.subRowRefs,
                      ]}
                    ></Dropdown>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={1} style={{ marginTop: "3px" }}>
                    <TextField
                      label="Currency"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      name="currencyAnalysis"
                      onChange={this.onChangedEntry}
                      value={this.state.tableRowData.currencyAnalysis}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={2} style={{ marginTop: "3px" }}>
                    {this.state.emptyAmount ? (
                      <TextField
                        label="Amount"
                        type="number"
                        name="amount"
                        fullWidth
                        error
                        helperText="field should not be empty"
                        variant="outlined"
                        margin="dense"
                        onChange={this.onChangedEntry}
                        value={this.state.tableRowData.amount}
                      ></TextField>
                    ) : (
                      <TextField
                        label="Amount"
                        type="number"
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        name="amount"
                        onChange={this.onChangedEntry}
                        value={this.state.tableRowData.amount}
                      ></TextField>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} lg={1} style={{ marginTop: "3px" }}>
                    <Button
                      style={{
                        marginTop: "8px",
                        padding: "7px",
                        width: "100px",
                      }}
                      variant="contained"
                      color="primary"
                      onClick={this.addNewRow}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
                <Divider style={{ marginTop: "25px", marginBottom: "10px" }} />
              </>
            ) : null}

            {tableList.length > 0 ? (
              <>
                <Typography
                  variant="h6"
                  style={{ fontWeight: "bold", marginTop: "20px" }}
                >
                  Transaction List
                </Typography>

                <Grid
                  container
                  justify="flex-end"
                  style={{ paddingRight: "10px" }}
                  spacing={4}
                >
                  <Grid item>
                    <Button
                      style={{
                        marginTop: "8px",
                        paddingTop: "10px",
                        paddingRight: "25px",
                        paddingLeft: "25px",
                        paddingBottom: "10px",
                        marginBottom: "20px",
                        width: "100%",
                      }}
                      variant="contained"
                      color="primary"
                      onClick={this.handleCheck}
                    >
                      Check
                    </Button>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h6"
                      style={{
                        marginTop: "8px",
                        marginBottom: "8px",
                        paddingTop: "6px",
                        paddingBottom: "11px",
                      }}
                    >
                      Total:{" "}
                      <span style={{ color: this.state.totalColor }}>
                        {this.state.requestData.total}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
                <TableContainer>
                  <Table
                    aria-label="customized table"
                    //   fixedHeader={false} style={{ width: "auto", tableLayout: "auto",  marginTop: "10px", marginBottom: "15px"  }}
                    fixedHeader={false}
                    style={{
                      marginTop: "10px",
                      marginBottom: "15px",
                      border: "1px solid #EAEDEF",
                    }}
                    size="small"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell style={styledTableCell} align="center">
                          {" "}
                          S. No.{" "}
                        </TableCell>
                        <TableCell style={styledTableCell} align="center">
                          Dr./Cr.
                        </TableCell>
                        <TableCell style={styledTableCell}>Narration</TableCell>
                        <TableCell style={styledTableCell}>
                          Cost Center
                        </TableCell>
                        <TableCell style={styledTableCell}>
                          Account Code
                        </TableCell>
                        <TableCell style={styledTableCell}>
                          Sub Account
                        </TableCell>
                        <TableCell style={styledTableCell}>Currency</TableCell>
                        <TableCell style={styledTableCell}>Amount</TableCell>
                        <TableCell style={styledTableCell} align="center">
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.myTableList.map((value, i) => (
                        <TableRow
                          style={{
                            backgroundColor: this.state.gridColor[value.id],
                            transition: "background-color 1s",
                          }}
                        >
                          <TableCell
                            align="center"
                            style={{
                              width: "50px",
                              padding: "10px",
                              borderRight: "1px solid #EAEDEF",
                              borderBottom: "1px solid #EAEDEF",
                            }}
                          >
                            {i + 1}
                          </TableCell>
                          <TableCell
                            style={{
                              width: "60px",
                              padding: "10px",
                              borderRight: "1px solid #EAEDEF",
                              borderBottom: "1px solid #EAEDEF",
                            }}
                            align="center"
                          >
                            {value.debitCredit}
                          </TableCell>
                          <TableCell
                            style={{
                              padding: "10px",
                              borderRight: "1px solid #EAEDEF",
                              borderBottom: "1px solid #EAEDEF",
                            }}
                          >
                            <TextField
                              value={value.narration}
                              name={"narration." + value.id}
                              onChange={this.onChangedTableRow}
                              fullWidth
                              style={{ minWidth: "160px" }}
                              variant="outlined"
                              margin="dense"
                            ></TextField>
                          </TableCell>
                          <TableCell
                            style={{
                              width: "60px",
                              padding: "10px",
                              borderRight: "1px solid #EAEDEF",
                              borderBottom: "1px solid #EAEDEF",
                            }}
                          >
                            <TextField
                              label={value.costCenter}
                              value={value.costCenter}
                              name={"costCenter." + value.id}
                              select
                              fullWidth
                              style={{
                                width: "80px",
                              }}
                              onChange={this.onChangedTableRow}
                              variant="outlined"
                              margin="dense"
                            >
                              {this.state.costCenterList.map((value) => (
                                <MenuItem key={value.id} value={value.code}>
                                  {value.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                          <TableCell
                            style={{
                              width: "130px",
                              padding: "10px",
                              borderRight: "1px solid #EAEDEF",
                              borderBottom: "1px solid #EAEDEF",
                            }}
                          >
                            <TextField
                              label={value.accountCode}
                              value={value.accountCode}
                              select
                              fullWidth
                              style={{ width: "130px" }}
                              name={"accountCode." + value.id}
                              onChange={(e)=> this.handleTableInputChange(e,i)}
                              variant="outlined"
                              margin="dense"
                            >
                              {this.state.accountList.map((value) => (
                                <MenuItem key={value.id} value={value.code}>
                                  {value.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                          <TableCell
                            style={{
                              width: "130px",
                              padding: "10px",
                              borderRight: "1px solid #EAEDEF",
                              borderBottom: "1px solid #EAEDEF",
                            }}
                          >
                            <TextField
                              label={value.subAccount}
                              value={value.subAccount}
                              fullWidth
                              style={{ width: "130px" }}
                              select
                              name={"subAccount." + value.id}
                              onChange={this.onChangedTableRow}
                              variant="outlined"
                              margin="dense"
                            >
                              {value.subAccountList.map((value) => (
                                <MenuItem key={value.id} value={value.code}>
                                  {value.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                          <TableCell
                            style={{
                              width: "65px",
                              padding: "10px",
                              borderRight: "1px solid #EAEDEF",
                              borderBottom: "1px solid #EAEDEF",
                            }}
                          >
                            <TextField
                              value={value.currencyAnalysis}
                              name={"currencyAnalysis." + value.id}
                              style={{ width: "65px" }}
                              fullWidth
                              onChange={this.onChangedTableRow}
                              variant="outlined"
                              margin="dense"
                            ></TextField>
                          </TableCell>
                          <TableCell
                            style={{
                              width: "130px",
                              padding: "10px",
                              borderRight: "1px solid #EAEDEF",
                              borderBottom: "1px solid #EAEDEF",
                            }}
                          >
                            <TextField
                              // value={format.format(value.amount)}
                              // value={this.getFormattedVal(value.amount)}
                              value={value.amount}
                              style={{ minWidth: "130px" }}
                              fullWidth
                              name={"amount." + value.id}
                              onChange={this.onChangedTableRow}
                              variant="outlined"
                              margin="dense"
                              type="number"
                            ></TextField>
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              width: "30px",
                              padding: "10px",
                              borderRight: "1px solid #EAEDEF",
                              borderBottom: "1px solid #EAEDEF",
                            }}
                          >
                            <span>
                              {this.state.editable ? (
                                <FontAwesomeIcon
                                  icon={faMinusCircle}
                                  size="lg"
                                  style={{
                                    color: "#FF8070",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.deleteRow(value.id, "debit")
                                  }
                                ></FontAwesomeIcon>
                              ) : (
                                <FontAwesomeIcon
                                  icon={faMinusCircle}
                                  size="lg"
                                  style={{
                                    color: "#FF8070",
                                    cursor: "pointer",
                                  }}
                                ></FontAwesomeIcon>
                              )}
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : null}

            <Grid container justify="center">
              {this.state.editable ? (
                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  onClick={this.handleUpdate}
                  style={buttonStyle}
                >
                  Update
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  style={buttonStyle}
                  disabled
                >
                  Update
                </Button>
              )}
              {status === "Pending" ? (
                <Button
                  variant="outlined"
                  size="large"
                  color="inherit"
                  onClick={() => this.handleStatus("Checked")}
                  style={buttonStyle}
                >
                  Check
                </Button>
              ) : null}
              {status === "Checked" ? (
                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  onClick={() => this.handleStatus("Approved")}
                  style={buttonStyle}
                >
                  Approve
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </Card>
      </>
    );
  }
}

export default withStyles(styles)(ViewPaymentVoucherAuthorization);
