import React, { Component } from "react";
import {
  Grid,
  TextField,
  Divider,
  Button,
  withStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import DeleteButton from "./InterfaceElements/DeleteButton";
import { Dropdown, Input } from "semantic-ui-react";
import Axios from "axios";
import UserContext from "../utils/UserContext";
import { format } from "date-fns/esm";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { parseISO, isAfter, isBefore} from "date-fns";
import VoucherNumberRestriction from "../components/InterfaceElements/VoucherNumberRestriction";
import adbs, { bs2ad } from "ad-bs-converter";
import dateFormat from "dateformat";
import "nepali-datepicker-reactjs/dist/index.css";
import NepaliDatepicker from 'nepali-datepicker-and-dateinput';

const styles = (theme) => ({
  containerWidth: {
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "70%",
    },
  },
  bottomContainerWidth: {
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "90%",
    },
  },
});

class PurchaseOrderForm extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      periodList: [],
      fyList: [],
      currentPeriod: "",
      fiscalYear: "",
      periodDateFrom: "",
      periodDateTo: "",
      purchaseOrderDate: "",
      purchaseOrderDateNepali: "",
      periodResData: [],
      rowRefs: [],
      supplierRowRefs: [],
      columnDefs: [
        {
          headerName: "SN",
          field: "sn",
          sortable: true,
          resizable: true,
          width: 40,
        },
        {
          headerName: "ItemCode",
          field: "itemCode",
          sortable: true,
          resizable: true,
          width: 70,
        },
        {
          headerName: "Name",
          field: "itemName",
          sortable: true,
          resizable: true,
        },
        {
          headerName: "Quantity",
          field: "quantity",
          sortable: true,
          resizable: true,
          editable: true,
          width: 70,
        },
        {
          headerName: "Price",
          field: "price",
          sortable: true,
          editable: true,
          resizable: true,
          width: 70,
        },
        {
          headerName: "Discount",
          field: "discount",
          editable: true,
          sortable: true,
          resizable: true,
          width: 70,
        },
        {
          headerName: "Line Total",
          field: "lineTotal",
          sortable: true,
          width: 70,
        },
        {
          headerName: "Delete",
          cellRendererFramework: DeleteButton,
          cellRendererParams: {
            deleteRow: this.deleteRow,
          },
          width: 50,
        },
      ],
      rowData: [],
      rowSelection: "multiple",
      value: "cash",
      inventoryDetails: {},
      attention: "",
      purchaseBy: "",
      data: {
        companyAddress: "",
        companyContact: "",
        companyPan: "",
        nameOfCompany: "",
        purchaseOrderDate: "",
        purchaseOrderDetails: [],
        purchaseOrderNumber: "",
        supplierAddress: "",
        supplierContact: "",
        supplierName: "",
        supplierPan: "",
      },
      companyInfo: {
        nameOfCompany: "",
        companyAddress: "",
        companyContact: "",
        companyPan: "",
      },
      supplierDetails: {
        id: "",
        billFrom: "",
        address: "",
        pan: "",
        contactPerson: "",
      },
      price: 0,
      quantity: 1,
      subTotal: 0,
      taxes: 0,
      discount: 0,
      total: 0,
      sn: 1,
      purchaseOrderNoList: [],
      voucherNumberError: false,
      voucherNumber: "",
      emptyAttention: false,
      emptySupplier: false,
      itemValue: "",
      supplierValue: "",
    };
  }

  deleteRow = (data) => {
    var rowAllData = this.state.rowData;
    var length = rowAllData.length;
    var finalRows = [];
    finalRows = rowAllData;

    for (var i = 0; i < length; i++) {
      var obj = rowAllData[i];
      if (obj.sn === data.sn) {
        finalRows.splice(i, 1);

        var subTotalNow = this.state.subTotal;
        // var taxesNow = this.state.taxes;
        var discountNow = this.state.discount;
        var totalNow = this.state.total;

        var subTotalNew = subTotalNow - obj.price * obj.quantity;

        //   var taxesNew = taxesNow - obj.taxes;
        var discountNew = discountNow - obj.discount;
        var totalNew = totalNow - (obj.price * obj.quantity - obj.discount);

        this.setState({
          subTotal: subTotalNew,
          //    taxes: taxesNew,
          discount: discountNew,
          total: totalNew,
        });

        break;
      }
    }
  };

  /* getPurchaseOrderDetails() {
    Axios.get("pos/purchaseOrder/getPurchaseOrder")
      .then((res) => {
        var purchaseOrderDate = res.data.date;
        this.setState({
            purchaseOrderDate: purchaseOrderDate,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  } */

  componentDidMount() {
    this.getAllPeriodListing();

    //this.getPurchaseOrderDetails();
    
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    var todayDate = dateFormat(date, "yyyy-mm-dd");

    let transactionDate = (todayDate.split("-"));
    
    let tdf = (
      transactionDate[0] +
        "/" +
        transactionDate[1] +
        "/" +
        transactionDate[2]
    );
    let df= adbs.ad2bs(tdf)

    let finalYear = df.en.year;
    let finalMonth = df.en.month;
    let finalDay = df.en.day;
    if(df.en.month < 10){
      finalMonth = "0" + df.en.month;
    }    
    if(df.en.day < 10){
      finalDay = "0" + df.en.day;
    }

    let finalDate = finalYear + "-" + finalMonth + "-" + finalDay;

    this.setState((prevState) => ({
      ...prevState,
        purchaseOrderDate: finalDate,
        purchaseOrderDateNepali: finalDate,
    }));

    Axios.get("pos/supplier/getAllSupplierList")
      .then((res) => {
        console.log(res);
        var rows = [];
        res.data.forEach((element) => {
          const supplier = {
            key: element.id,
            value: element.id,
            text: element.name + "#" + element.id,
          };
          rows.push(supplier);
        });
        this.setState({
          supplierRowRefs: rows,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    Axios.get("pos/inventory/getSelectedValues")
      .then((res) => {
        console.log(res);

        var rows = [];

        res.data.forEach((element) => {
          const item = {
            key: element.itemCode,
            value: element.itemCode + element.itemName,
            text: element.itemName + " #" + element.itemCode,
            label: { content: "#" + element.itemCode, tag: true },
          };
          rows.push(item);
        });

        this.setState({ rowRefs: rows });
      })
      .catch((err) => {
        console.log(err);
      });

    Axios.get("hrm/getGeneralCompanyInformation")
      .then((res) => {
        console.log(res);
        var response = res.data[0];
        this.setState({
          companyInfo: {
            nameOfCompany: response.name,
            companyAddress: response.city,
            companyContact: response.contactNumber,
            companyPan: response.panNumber,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getVoucherNoList = (fy) => {
    Axios.post("/pos/voucherNumbering/getVoucherNumbering", {fiscalYear: fy, name: "Purchase Order"})
    .then(async(res) => {
      if (res.data === "Not Found") {
        await this.setState({ voucherNumberError: true });
       } else {
       await this.setState((prevState) => ({
           ...prevState,
           purchaseOrderNoList: res.data,
           data: {
             ...prevState.data,
             purchaseOrderNumber: res.data[0],
           },
         }));
       }
    })
    .catch((err) => {
      console.log(err);
    });
  }

  getAllPeriodListing = () => {
    Axios.get("/fy/getAllActivePeriods")
      .then(async (res) => {
        var fiscalYearLists = [];
        res.data.forEach((element) => {
          var fy = Object.keys(element);

          fiscalYearLists.push(fy[0]);
        });
        await this.setState({
          fyList: fiscalYearLists,
          periodResData: res.data,
        });

        this.getCurrentPeriod();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCurrentPeriod() {
    Axios.get("/fy/getCurrentPeriod")
      .then(async (res) => {
        await this.setState((prevState) => ({
          ...prevState,
          fiscalYear: res.data.fiscalYear,
          currentPeriod: res.data.name,
        }));
        this.getPeriodList();
        this.getVoucherNoList(res.data.fiscalYear)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleFYChange = async (e) => {
    let Fy = e.target.value;
    await this.setState((prevState) => ({
      ...prevState,
      fiscalYear: Fy,
    }));
    this.getPeriodList();
    this.getVoucherNoList(Fy)
  };

  getPeriodList() {
    var pLists = [];

    this.state.periodResData.map((val) =>
      Object.keys(val).includes(this.state.fiscalYear)
        ? (pLists = val[this.state.fiscalYear])
        : null
    );

    this.setState((prevState) => ({
      ...prevState,
      periodList: pLists,
      currentPeriod: pLists[pLists.length-1],
    }));
    this.getPeriodDateFromTo(this.state.fiscalYear,pLists[pLists.length-1]);
  }

  handlePeriodChange = async (e) => {
    let Py = e.target.value;

    await this.setState((prevState) => ({
      ...prevState,
        currentPeriod: Py,
    }));
    this.getPeriodDateFromTo(this.state.fiscalYear, Py);
  };

  getPeriodDateFromTo = (fiscalYear, period) => {
    Axios.post("fy/findByNameAndFiscalYear", { period, fiscalYear }).then(
      (res) =>{
        this.setState((prevState)=>({
          ...prevState,
          periodDateFrom: res.data.dateFrom,
          periodDateTo: res.data.dateTo,
        }))
       } );
    
  };

  shouldDisableDate = (date) => {
    let periodFrom = parseISO(this.state.periodDateFrom);
    let periodTo = parseISO(this.state.periodDateTo);
    let currentDate = new Date(date);

    if (isAfter(currentDate, periodTo) || isBefore(currentDate, periodFrom)) {
      return true;
    } else {
      return false;
    }
  };

  onChangedDate = (name, dateInMilli, bsDate, adDate) => {

    this.setState((prevState) => ({
      ...prevState,
        purchaseOrderDate: adDate,
        purchaseOrderDateNepali: bsDate,
    }));
  };

  handleChange = (e) => {
    let val = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      voucherNumber: val,
    }));
  };

  handleSupplierSelect = (event, data) => {
    const { value } = data;

    console.log(value);

    this.setState({
      supplierValue: value,
    });

    Axios.get("pos/supplier/getSupplier/" + value)
      .then((res) => {
        console.log(res);
        this.setState((prevState) => ({
          ...prevState,
          emptySupplier: false,
          supplierDetails: {
            ...prevState.supplierDetails,
            id: res.data.id === null ? "" : res.data.id,
            billFrom: res.data.fullName === null ? "" : res.data.fullName,
            address: res.data.city === null ? "" : res.data.city,
            pan: res.data.panNumber === null ? "" : res.data.panNumber,
            contactPerson:
              res.data.contactPerson === null ? "" : res.data.contactPerson,
          },
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  onRemoveSelected = () => {
    const selectedData = this.gridApi.getSelectedRows();
    this.gridApi.updateRowData({ remove: selectedData });
  };

  handleRadioChange = (evt) => {
    this.setState({ value: evt.target.value });
  };

  handle0Index = (rowAllData, rowsToSave, itemData) => {
    var rowItemData = {
      sn: this.state.sn,
      itemCode: itemData.itemCode,
      itemName: itemData.itemName,
      quantity: this.state.quantity,
      taxes: itemData.taxable,
      price: 0,
      discount: 0,
      lineTotal:
        itemData.sellingPrice !== undefined
          ? itemData.sellingPrice * this.state.quantity - 0
          : 0 * this.state.quantity,
    };

    var toSave = true;

    rowAllData.forEach((element) => {
      if (element.itemName === itemData.itemName) {
        toSave = false;
      }
    });

    if (toSave) {
      rowAllData.push(rowItemData);
      rowAllData.forEach((element) => {
        var itemToAddDiff = {
          discount: element.discount,
          itemCode: element.itemCode,
          itemName: element.itemName,
          lineTotal: element.lineTotal,
          price: element.price,
          quantity: element.quantity,
        };

        rowsToSave.push(itemToAddDiff);
      });
    }
  };

  getAllRows = () => {
    let rowData = [];
    this.gridApi.forEachNode((node) => rowData.push(node.data));
    return rowData;
  };

  handleSubmit = () => {
    const token = this.context;

    var obj = {
      attention: this.state.attention,
      purchaseBy: this.state.purchaseBy,
      companyAddress: this.state.companyInfo.companyAddress,
      companyContact: this.state.companyInfo.companyContact,
      companyPan: this.state.companyInfo.companyPan,
      nameOfCompany: this.state.companyInfo.nameOfCompany,
      purchaseOrderDate: this.state.purchaseOrderDate,
      purchaseOrderDateNepali: this.state.purchaseOrderDateNepali,
      purchaseOrderDetails: this.state.rowData,
      purchaseOrderNumber: this.state.data.purchaseOrderNumber,
      supplierAddress: this.state.supplierDetails.address,
      supplierContact: this.state.supplierDetails.contactPerson,
      supplierName: this.state.supplierDetails.billFrom,
      supplierPan: this.state.supplierDetails.pan,
      supplier: {
        id: this.state.supplierDetails.id,
      },
      fiscalYear: this.state.fiscalYear,
      period: this.state.currentPeriod,
    };

    if (obj.supplier.id === "") {
      this.setState({ emptySupplier: true });
      return;
    }

    if (this.state.attention === "" || this.state.attention === undefined) {
      this.setState({ emptyAttention: true });
      return;
    }

    if (obj.purchaseOrderDetails.length === 0) {
      var errorSnackbarProps = {
        open: true,
        variant: "error",
        message: "No item selected",
        autoHideDuration: 2000,
      };
      token.setSnackbarProps({ snackbarProps: errorSnackbarProps });
      return;
    }

    Axios.post("pos/purchaseOrder/save", obj)
      .then((res) => {
        console.log(res);
        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000,
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });
        this.clearFields();
      })
      .catch((err) => {
        console.log(err);
        var message = err.message;
        if (err.response !== undefined) {
          message = err.response.data;
        }
        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000,
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });
      });
  };

  clearFields() {
    this.setState({
      rowData: [],
      companyInfo: {
        nameOfCompany: "",
        companyAddress: "",
        companyContact: "",
        companyPan: "",
      },
      supplierDetails: {
        billFrom: "",
        address: "",
        pan: "",
        contactPerson: "",
      },
      quantity: 1,
      subTotal: 0,
      taxes: 0,
      discount: 0,
      total: 0,
      purchaseBy: "",
      attention: "",
      emptyAttention: false,
      itemValue: "",
      supplierValue: "",
    });

    //this.getPurchaseOrderDetails();

    Axios.get("hrm/getGeneralCompanyInformation")
      .then((res) => {
        console.log(res);
        var response = res.data[0];
        this.setState({
          companyInfo: {
            nameOfCompany: response.name,
            companyAddress: response.city,
            companyContact: response.contactNumber,
            companyPan: response.panNumber,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
      this.getVoucherNoList(this.state.fiscalYear)
  }

  handleItemSelect = (event, data) => {
    const { value } = data;
    const { key } = data.options.find((o) => o.value === value);

    this.setState({
      itemValue: value,
    });

    Axios.get("pos/inventory/getInventoryByItemCode/" + key)
      .then((res) => {
        console.log("jaysambho");
        console.log(res);

        this.setState({
          itemDetails: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleItemAdd = () => {
    var rows = this.getAllRows();

    var itemData = this.state.itemDetails;

    if (itemData.itemName === undefined || this.state.quantity === 0) {
      return;
    }

    var rowAllData = this.state.rowData;

    var subTotal = this.state.subTotal + this.state.price * this.state.quantity;

    var total = subTotal;

    this.setState({
      subTotal: subTotal,
      //taxes: taxTotal,
      discount: this.state.discount,
      total: total,
    });

    var rowsToSave = [];
    if (rows.length === 0) {
      this.handle0Index(rowAllData, rowsToSave, itemData);
    } else {
      for (let i = 0; i < rows.length; i++) {
        const element = rowAllData[i];

        if (element.itemName === itemData.itemName) {
          var newQuantity = element.quantity + this.state.quantity;
          var newDiscount = element.discount + this.state.discount;

          var rowSpecificData = {
            ...element,
            quantity: newQuantity,
            discount: newDiscount,
            lineTotal:
              itemData.sellingPrice !== undefined
                ? element.price * newQuantity - newDiscount
                : 1 * newQuantity,
          };

          rowAllData[i] = rowSpecificData;

          let updatedSubTotal =
            this.state.subTotal + element.price * this.state.quantity;

          this.setState({
            subTotal: updatedSubTotal,
            total: updatedSubTotal,
          });

          var itemToAdd = {
            discount: rowSpecificData.discount,
            quantity: rowSpecificData.quantity,
            lineTotal: rowSpecificData.lineTotal,
            itemCode: rowSpecificData.itemCode,
            itemName: rowSpecificData.itemName,
            price: rowSpecificData.price,
          };
          rowsToSave.push(itemToAdd);
        } else {
          this.handle0Index(rowAllData, rowsToSave, itemData);
        }
      }
    }

    this.setState({ sn: this.state.sn + 1 });
    this.setState({ rowData: rowAllData });

    this.gridApi.setRowData(rowAllData);
    this.setState({ quantity: 1 });
  };

  handleQuantityChange = (evt) => {
    if (evt.target.value < 1) {
      evt.target.value = 1;
    }
    this.setState({ quantity: parseInt(evt.target.value) });
  };

  handleTextChange = (e) => {
    e.stopPropagation();
    var value = e.target.value;
    var name = e.target.name;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  onCellValueChanged(params) {
    console.log(params.data);
    var rowAllData = this.state.rowData;

    var subTotal = 0;
    var discount = 0;
    var total = 0;

    rowAllData.forEach((element) => {
      if (element.sn === params.data.sn) {
        var obj = element;
        obj.lineTotal = obj.quantity * obj.price - obj.discount;
      }

      subTotal += element.price * element.quantity;
      discount += element.discount * 1;
      total += element.lineTotal;
    });

    this.setState({
      subTotal,
      discount,
      total,
    });
    this.setState({ rowData: rowAllData });
    this.gridApi.setRowData(rowAllData);
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Grid
          container
          // justify="center"
          className={classes.containerWidth}
          spacing={2}
        >
          <Grid item xs={12}>
            {this.state.emptySupplier ? (
              <>
                <Dropdown
                  placeholder="Search by Supplier Name"
                  fluid
                  style={{ borderColor: "red" }}
                  search
                  ref={(search) => {
                    this.searchInput = search;
                  }}
                  selection
                  value={this.state.supplierValue}
                  onChange={this.handleSupplierSelect}
                  options={this.state.supplierRowRefs}
                ></Dropdown>
                <Typography style={{ fontSize: "12px", color: "red" }}>
                  Select a supplier first
                </Typography>
              </>
            ) : (
              <Dropdown
                placeholder="Search by Supplier Name"
                fluid
                search
                ref={(search) => {
                  this.searchInput = search;
                }}
                selection
                value={this.state.supplierValue}
                onChange={this.handleSupplierSelect}
                options={this.state.supplierRowRefs}
              ></Dropdown>
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              variant="outlined"
              select
              margin="dense"
              fullWidth
              label="Fiscal Year"
              name="fiscalYear"
              onChange={this.handleFYChange}
              value={this.state.fiscalYear}
            >
              {this.state.fyList.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="outlined-dense"
              label="Purchase Order No"
              name="purchaseOrderNumber"
              value={this.state.data.purchaseOrderNumber}
              //onChange={this.handleChange}
              margin="dense"
              variant="outlined"
              fullWidth
            >
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              variant="outlined"
              select
              margin="dense"
              fullWidth
              label="Period"
              name="period"
              onChange={this.handlePeriodChange}
              value={this.state.currentPeriod}
            >
              {this.state.periodList.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={3} style={{marginTop: "8px"}}>
            
            <NepaliDatepicker
                id="nepali-date"
                onChange={this.onChangedDate}
                label=" Purchase Order Date"
                value={this.state.purchaseOrderDate}
                locale= "en"
                showDefaultDate="true"
                style={{height: "5px", width: "5px"}}
              />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <TextField
              id="outlined-dense"
              label="Name of Company"
              name="nameOfCompany"
              value={this.state.companyInfo.nameOfCompany}
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              id="outlined-dense"
              label="Company Address"
              name="companyAddress"
              value={this.state.companyInfo.companyAddress}
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              id="outlined-dense"
              label="Company PAN"
              name="companyPan"
              value={this.state.companyInfo.companyPan}
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              id="outlined-dense"
              label="Supplier Address"
              margin="dense"
              variant="outlined"
              value={this.state.supplierDetails.address}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              id="outlined-dense"
              label="Supplier PAN"
              value={this.state.supplierDetails.pan}
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              id="outlined-dense"
              label="Supplier Contact"
              value={this.state.supplierDetails.contactPerson}
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              id="outlined-dense"
              label="Purchase By"
              name="purchaseBy"
              value={this.state.purchaseBy}
              onChange={this.handleTextChange}
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            {this.state.emptyAttention ? (
              <TextField
                id="outlined-dense"
                error
                helperText="Attention should not be empty"
                label="Attention"
                name="attention"
                value={this.state.attention}
                onChange={this.handleTextChange}
                margin="dense"
                variant="outlined"
                fullWidth
              />
            ) : (
              <TextField
                id="outlined-dense"
                label="Attention"
                name="attention"
                value={this.state.attention}
                onChange={this.handleTextChange}
                margin="dense"
                variant="outlined"
                fullWidth
              />
            )}
          </Grid>
        </Grid>

        <Divider style={{ marginTop: "15px", marginBottom: "5px" }} />

        <Grid
          container
          justify="center"
          spacing={2}
          className={classes.bottomContainerWidth}
        >
          <Grid item xs={12} sm={12} md={7}>
            <Dropdown
              placeholder="Search by Item Code or Item Name"
              fluid
              fullWidth
              style={{ marginTop: "15px" }}
              search
              ref={(search) => {
                this.searchInput = search;
              }}
              selection
              value={this.state.itemValue}
              onChange={this.handleItemSelect}
              options={this.state.rowRefs}
            ></Dropdown>
          </Grid>

          <Grid item xs={7} sm={8} md={3}>
            <Input
              placeholder="Quantity"
              type="number"
              style={{ marginTop: "15px", width: "100%" }}
              onChange={this.handleQuantityChange}
              value={this.state.quantity}
            ></Input>
          </Grid>
          <Grid item xs={5} sm={4} md={2}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={this.handleItemAdd}
              style={{
                margin: "auto",
                height: "38px",
                marginTop: "15px",
              }}
            >
              +Add
            </Button>
          </Grid>

          <div
            className="ag-theme-balham"
            style={{
              height: "240px",
              width: "100%",
              margin: "10px",
            }}
          >
            <AgGridReact
              columnDefs={this.state.columnDefs}
              rowData={this.state.rowData}
              animateRows={true}
              rowSelection={this.state.rowSelection}
              onGridReady={this.onGridReady}
              onCellValueChanged={this.onCellValueChanged.bind(this)}
            ></AgGridReact>
          </div>
        </Grid>

        <Grid
          container
          justify="center"
          spacing={4}
          style={{ marginBottom: "10px", marginTop: "10px" }}
        >
          <Grid item>
            <p>
              Sub Total:{" "}
              <span style={{ display: "inline-block", width: "20px" }}></span>
              <strong>{this.state.subTotal}</strong>
            </p>
          </Grid>
          <Grid item>
            <p>
              Taxes:{" "}
              <span style={{ display: "inline-block", width: "48px" }}></span>
              <strong>{0}</strong>
            </p>
          </Grid>
          <Grid item>
            <p>
              Discount:{" "}
              <span style={{ display: "inline-block", width: "23px" }}></span>
              <strong>{this.state.discount}</strong>
            </p>
          </Grid>
          <Grid item>
            <p>
              Total:{" "}
              <span style={{ display: "inline-block", width: "43px" }}></span>
              <strong>{this.state.total}</strong>
            </p>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Button
            variant="outlined"
            color="primary"
            onClick={this.handleSubmit}
            style={{
              minWidth: "100px",
              width: "20vw",
              height: "50px",
              margin: "auto",
            }}
          >
            Save
          </Button>
        </Grid>
        <VoucherNumberRestriction
          open={this.state.voucherNumberError}
        ></VoucherNumberRestriction>
      </div>
    );
  }
}

export default withStyles(styles)(PurchaseOrderForm);
