import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import BarCodeItem from "./BarCodeItem";
import UserContext from "../../utils/UserContext";

function BarCodeDisplay() {
  const handlePrint = () => {
    document.execCommand("print");
  };

  const { barCodes } = useContext(UserContext);

console.log("---------")
console.log({barCodes})
console.log("---------")
  return (
    <>
      <Grid container>
        <div style={{ marginTop: "60px" }}></div>
      </Grid>
      <Grid container style={{ marginTop: "0px" }}>
        <Grid container>
          <button
            onClick={handlePrint}
            className="hideOnPrint"
            style={{ margin: "20px" }}
          >
            Print
          </button>
        </Grid>

        {barCodes.map((val, i) => (
          <BarCodeItem arr={val} index={i} />
        ))}
      </Grid>
    </>
  );
}

export default BarCodeDisplay;
