import React, { useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
  Modal,
  Backdrop,
  IconButton,
  Fade,
  withStyles
} from "@material-ui/core";
import { useState } from "react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { Form } from "semantic-ui-react";
import ChooseItemsModal from "./ChooseItemsModal";
import Close from "@material-ui/icons/Close";

const styles = theme => ({
  root: {
    padding: 10,
    paddingTop: "7px",
    paddingBottom: "7px",
    minHeight: "640px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "170px",
      marginRight: "10px",
      marginLeft: "10px"
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px"
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px"
    }
  }
});

function EditSchemeModal(props) {
  const { classes } = props;

  const [showModal, setShowModal] = useState(false);
  const [schemeType, setSchemeType] = useState("discountType");
  const [rowData, setRowData] = useState([]);

  var globalParams = null;

  const handleSchemeTypeChange = e => {
    let value = e.target.value;

    setSchemeType(value);
  };

  const addRows = rowData => {
    setRowData(rowData);
  };

  const onGridReady = params => {
    globalParams = params;
    params.api.sizeColumnsToFit();
  };

  useEffect(() => {
    try {
      globalParams.api.sizeColumnsToFit();
    } catch (error) {}
  }, [globalParams]);

  const modal = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  };

  return (
    <div>
      <Grid container justify="center" className={classes.root}>
        <Grid item xs={12} lg={12} md={12} sm={12}>
          <Grid container>
            <Typography variant="h5">Edit Scheme</Typography>
          </Grid>
          <Grid container style={{ marginTop: "20px" }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setShowModal(true)}
            >
              Add Item/Items
            </Button>
          </Grid>
          <div
            className="ag-theme-balham"
            style={{
              height: "240px",
              width: "100%",
              marginTop: "10px"
            }}
          >
            <AgGridReact
              columnDefs={[
                {
                  headerName: "SN",
                  field: "sn",
                  sortable: true,
                  resizable: true,
                  width: 60
                },
                {
                  headerName: "Item Code",
                  field: "itemCode",
                  sortable: true,
                  resizable: true,
                  width: 110
                },
                {
                  headerName: "Item Name",
                  field: "itemName",
                  sortable: true,
                  resizable: true
                },
                {
                  headerName: "Major Category",
                  field: "majorCategory",
                  sortable: true,
                  resizable: true
                },
                {
                  headerName: "Category",
                  field: "category",
                  sortable: true,
                  resizable: true
                },
                {
                  headerName: "Sub Category",
                  field: "subCategory",
                  sortable: true,
                  resizable: true
                }
              ]}
              rowData={rowData}
              animateRows={true}
              rowSelection="multiple"
              onGridReady={onGridReady}
              enableCellChangeFlash={true}
            ></AgGridReact>
            <Grid container style={{ marginTop: "10px" }} spacing={2}>
              <Grid item>
                <Form>
                  <Form.Field>
                    <label>Quantity</label>
                    <TextField variant="filled" margin="dense" type="number" />
                  </Form.Field>
                </Form>
              </Grid>
              <Grid item>
                <Form>
                  <Form.Field>
                    <label>Effective Date From</label>
                    <TextField variant="filled" margin="dense" type="date" />
                  </Form.Field>
                </Form>
              </Grid>
              <Grid item>
                <Form>
                  <Form.Field>
                    <label>Effective Date To</label>
                    <TextField variant="filled" margin="dense" type="date" />
                  </Form.Field>
                </Form>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: "20px" }}>
              <Typography variant="h6">Scheme Type</Typography>
            </Grid>
            <Grid container style={{ marginTop: "10px" }}>
              <RadioGroup
                row
                defaultValue="discountType"
                onChange={handleSchemeTypeChange}
              >
                <FormControlLabel
                  value="discountType"
                  control={<Radio color="primary" />}
                  label="Discount Type"
                />
                <FormControlLabel
                  value="bonusType"
                  control={<Radio color="primary" />}
                  label="Bonus Type"
                />
              </RadioGroup>
            </Grid>
            {schemeType === "discountType" ? (
              <Grid container style={{ marginTop: "10px" }}>
                <Form>
                  <Form.Field>
                    <label>Discount Percentage</label>
                    <TextField
                      margin="dense"
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        )
                      }}
                    />
                  </Form.Field>
                </Form>
              </Grid>
            ) : (
              <Grid container style={{ marginTop: "10px" }} spacing={2}>
                <Grid item xs={8}>
                  <Form>
                    <Form.Field>
                      <label>Item Name</label>
                      <TextField variant="filled" margin="dense" fullWidth />
                    </Form.Field>
                  </Form>
                </Grid>
                <Grid item xs>
                  <Form>
                    <Form.Field>
                      <label>Quantity</label>
                      <TextField variant="filled" margin="dense" fullWidth />
                    </Form.Field>
                  </Form>
                </Grid>
              </Grid>
            )}
            <Grid container justify="center">
              <Button
                variant="contained"
                size="large"
                color="primary"
                style={{
                  marginBottom: "20px",
                  marginTop: "20px",
                  paddingLeft: "47px",
                  paddingRight: "47px",
                  paddingTop: "15px",
                  paddingBottom: "15px"
                }}
              >
                Update Scheme
              </Button>
            </Grid>
          </div>
        </Grid>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          open={showModal}
          onClose={() => setShowModal(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={showModal}>
            <Card style={{ width: "90vw" }}>
              <CardContent style={{ overflowY: "auto", padding: "25px" }}>
                <Grid container justify="flex-end">
                  <IconButton onClick={() => setShowModal(false)}>
                    <Close />
                  </IconButton>
                </Grid>
                <ChooseItemsModal
                  addRows={addRows}
                  closeModal={() => setShowModal(false)}
                />
              </CardContent>
            </Card>
          </Fade>
        </Modal>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(EditSchemeModal);
