import React, { useState, useContext } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  withStyles,
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  InputAdornment,
  Modal,
  Backdrop,
  Fade,
  IconButton,
} from "@material-ui/core";
import { Form, Dropdown } from "semantic-ui-react";
import ChooseItemsModal from "./ChooseItemsModal";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import DeleteButton from "../InterfaceElements/DeleteButton";
import { useEffect } from "react";
import Close from "@material-ui/icons/Close";
import Axios from "axios";
import UserContext from "../../utils/UserContext";

const styles = (theme) => ({
  root: {
    padding: 10,
    paddingTop: "7px",
    paddingBottom: "7px",
    minHeight: "450px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "170px",
      marginRight: "10px",
      marginLeft: "10px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
    },
  },
});

function AddScheme(props) {
  const { children, value, index, classes, ...other } = props;

  var globalParams = null;

  useEffect(() => {
    try {
      globalParams.api.sizeColumnsToFit();
    } catch (error) {}
  }, [value, globalParams]);

  const modal = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  useEffect(() => {
    let rowDetails = [];
    Axios.get("pos/inventory/getInventories")
      .then((res) => {
        console.log(res);

        res.data.forEach((element) => {
          var item = {
            key: element.itemCode,
            value: element.itemCode,
            text: element.itemName + "  #" + element.itemCode,
            label: { content: "#", tag: true },
          };
          rowDetails.push(item);
        });
        setItemList(rowDetails);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const { setSnackbarProps } = useContext(UserContext);
  const [schemeType, setSchemeType] = useState("discountRate");
  const [showModal, setShowModal] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [quantity, setQuantity] = useState();
  const [effectiveDateFrom, setEffectiveDateFrom] = useState();
  const [effectiveDateTo, setEffectiveDateTo] = useState();
  const [discountRate, setDiscountRate] = useState();
  const [bonusItemName, setBonusItemName] = useState();
  const [bonusItemQuantity, setBonusItemQuantity] = useState();
  const [onTotal, setOnTotal] = useState();
  const [bonusWorth, setBonusWorth] = useState();
  const [itemList, setItemList] = useState([]);

  const [discountRateError, setDiscountRateError] = useState(false);
  const [dateFromError, setDateFromError] = useState(false);
  const [dateToError, setDateToError] = useState(false);

  const [bonusItemError, setBonusItemError] = useState(false);
  const [bonusQuantityError, setBonusQuantityError] = useState(false);
  const [quantityError, setQuantityError] = useState(false);

  const [itemError, setItemError] = useState(false);

  const handleSchemeTypeChange = (e) => {
    let value = e.target.value;
    setSchemeType(value);
    if (value === "discountRate" || value === "sales") {
      setBonusItemQuantity();
      setBonusItemName();
      setOnTotal();
      setBonusWorth();
      setBonusItemError(false);
      setBonusQuantityError(false);
      setDateFromError(false);
      setDateToError(false);
      setQuantityError(false);
      setItemError(false);
      setDiscountRateError(false);
    } else if (value === "bonusItem") {
      setOnTotal();
      setBonusWorth();
      setDiscountRate();
      setDateFromError(false);
      setDateToError(false);
      setQuantityError(false);
      setDiscountRateError(false);
      setItemError(false);
    } else {
      setDiscountRate();
      setBonusItemName();
      setBonusItemQuantity();
      setDateFromError(false);
      setDateToError(false);
      setQuantityError(false);
      setDiscountRateError(false);
      setItemError(false);
      setBonusItemError(false);
      setBonusQuantityError(false);
    }
  };

  const returnView = (type) => {
    if (type === "discountRate" || type === "sales") {
      return (
        <Grid container style={{ marginTop: "10px" }}>
          <Form>
            {discountRateError ? (
              <Form.Field>
                <label>Discount Percentage</label>
                <TextField
                  margin="dense"
                  variant="outlined"
                  type="number"
                  value={discountRate}
                  name="discountRate"
                  error
                  helperText="Discount Percentage cannot be empty"
                  onChange={handleDiscountRateChanged}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </Form.Field>
            ) : (
              <Form.Field>
                <label>Discount Percentage</label>
                <TextField
                  margin="dense"
                  variant="outlined"
                  type="number"
                  value={discountRate}
                  name="discountRate"
                  onChange={handleDiscountRateChanged}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </Form.Field>
            )}
          </Form>
        </Grid>
      );
    } else if (type === "bonusItem") {
      return (
        <Grid container style={{ marginTop: "10px" }} spacing={2}>
          {/* <Grid item xs={8}>
            <Form>
              {bonusItemError? ( <Form.Field>
                <label>Item Code</label>
                <TextField 
                variant="filled" 
                margin="dense" 
                fullWidth 
                error 
                helperText="Bonus Item cannot be empty"
                value={bonusItemName}
                name="bonusItemName"
                onChange={handleBonusItemChanged}
                />
              </Form.Field>) : ( <Form.Field>
                <label>Item Code</label>
                <TextField 
                variant="filled" 
                margin="dense" 
                fullWidth 
                value={bonusItemName}
                name="bonusItemName"
                onChange={handleBonusItemChanged}
                />
              </Form.Field>)}
             
            </Form>
          </Grid> */}

          <Grid item xs="8">
            <Form>
              {bonusItemError ? (
                <Form.Field>
                  <label>Bonus Item </label>

                  <Dropdown
                    placeholder="Search by Item Code or Item Name"
                    fluid
                    fullWidth
                    search
                    error
                    helperText="Bonus Item cannot be empty"
                    style={{ marginTop: "15px" }}
                    selection
                    onChange={handleBonusItemChanged}
                    options={itemList}
                  ></Dropdown>
                </Form.Field>
              ) : (
                <Form.Field>
                  <label>Bonus Item </label>

                  <Dropdown
                    placeholder="Search by Item Code or Item Name"
                    fluid
                    fullWidth
                    search
                    style={{ marginTop: "15px" }}
                    selection
                    onChange={handleBonusItemChanged}
                    options={itemList}
                  ></Dropdown>
                </Form.Field>
              )}
            </Form>
          </Grid>

          <Grid item xs="4">
            <Form>
              {bonusQuantityError ? (
                <Form.Field>
                  <label>Quantity</label>
                  <TextField
                    variant="filled"
                    margin="dense"
                    fullWidth
                    error
                    helperText="Bonus Quantity cannot be empty"
                    value={bonusItemQuantity}
                    name="bonusItemQuantity"
                    onChange={handleBonusQuantityChanged}
                  />
                </Form.Field>
              ) : (
                <Form.Field>
                  <label>Quantity</label>
                  <TextField
                    variant="filled"
                    margin="dense"
                    fullWidth
                    value={bonusItemQuantity}
                    name="bonusItemQuantity"
                    onChange={handleBonusQuantityChanged}
                  />
                </Form.Field>
              )}
            </Form>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Form>
          <Grid container spacing={3} style={{ marginTop: "10px" }}>
            <Grid item xs={6}>
              <Form.Field>
                <label>On Total</label>
                <TextField
                  margin="dense"
                  variant="outlined"
                  style={{ width: "100%" }}
                  value={onTotal}
                  name="onTotal"
                  onChange={handleOnTotalChanged}
                />
              </Form.Field>
            </Grid>
            <Grid item xs={6}>
              <Form.Field>
                <label>Give bonus item worth </label>
                <TextField
                  margin="dense"
                  variant="outlined"
                  style={{ width: "100%" }}
                  value={bonusWorth}
                  name="bonusWorth"
                  onChange={handleBonusWorthChanged}
                />
              </Form.Field>
            </Grid>
          </Grid>
        </Form>
      );
    }
  };

  const deleteRow = (data) => {
    var rowAllData = rowData;

    var length = rowAllData.length;

    var finalRows = [];

    finalRows = rowAllData;

    for (var i = 0; i < length; i++) {
      var obj = rowAllData[i];

      if (obj.sn === data.sn) {
        finalRows.splice(i, 1);

        break;
      }
    }

    setRowData(finalRows);
  };

  const addRows = (rowData) => {
    setRowData(rowData);
  };

  const onGridReady = (params) => {
    globalParams = params;
    params.api.sizeColumnsToFit();
  };

  const handleSubmit = () => {
    if (schemeType === "discountRate") {
      let errorFlag = false;

      if (
        discountRate === "" ||
        discountRate === undefined ||
        discountRate === null
      ) {
        setDiscountRateError(true);
        errorFlag = true;
      }
      if (
        effectiveDateFrom === "" ||
        effectiveDateFrom === undefined ||
        effectiveDateFrom === null
      ) {
        setDateFromError(true);
        errorFlag = true;
      }
      if (
        effectiveDateTo === "" ||
        effectiveDateTo === undefined ||
        effectiveDateTo === null
      ) {
        setDateToError(true);
        errorFlag = true;
      }

      if (rowData.length < 1) {
        setItemError(true);
        errorFlag = true;
      }

      var toSendDiscount = {
        dateFrom: effectiveDateFrom,
        dateTo: effectiveDateTo,
        schemeType: "DiscountPrice",
        remarks: "remarks",
        schemeDiscounts: [],
      };
      rowData.forEach((element) => {
        var obj = {
          itemCode: element.itemCode,
          discountPercentage: discountRate,
        };
        toSendDiscount.schemeDiscounts.push(obj);
      });

      if (!errorFlag) {
        Axios.post("/pos/scheme/save", toSendDiscount)
          .then((res) => {
            var snackbarProps = {
              open: true,
              variant: "success",
              message: res.data,
              autoHideDuration: 2000,
            };
            setSnackbarProps({ snackbarProps: snackbarProps });
            resetForm();
          })
          .catch((err) => {
            console.log(err);
            var message = err.message;
            if (err.response !== undefined) {
              message = err.response.data;
            }
            var snackbarProps = {
              open: true,
              variant: "error",
              message: message,
              autoHideDuration: 2000,
            };
            setSnackbarProps({ snackbarProps: snackbarProps });
          });
      }
    } else if (schemeType === "bonusItem") {
      let errorFlag = false;

      if (
        bonusItemName === "" ||
        bonusItemName === undefined ||
        bonusItemName === null
      ) {
        setBonusItemError(true);
        errorFlag = true;
      }
      if (
        bonusItemQuantity === "" ||
        bonusItemQuantity === undefined ||
        bonusItemQuantity === null
      ) {
        setBonusQuantityError(true);
        errorFlag = true;
      }

      if (
        effectiveDateFrom === "" ||
        effectiveDateFrom === undefined ||
        effectiveDateFrom === null
      ) {
        setDateFromError(true);
        errorFlag = true;
      }
      if (
        effectiveDateTo === "" ||
        effectiveDateTo === undefined ||
        effectiveDateTo === null
      ) {
        setDateToError(true);
        errorFlag = true;
      }

      if (quantity === "" || quantity === undefined || quantity === null) {
        setQuantityError(true);
        errorFlag = true;
      }

      var toSendBonus = {
        dateFrom: effectiveDateFrom,
        dateTo: effectiveDateTo,
        schemeType: "BonusItem",
        remarks: "remarks",
        schemeBonus: [],
      };
      rowData.forEach((element) => {
        var obj = {
          itemCode: element.itemCode,
          bonusItemCode: bonusItemName,
          bonusItemQuantity: bonusItemQuantity,
          itemQuantity: quantity,
        };
        toSendBonus.schemeBonus.push(obj);
      });

      console.log("before send");
      console.log(toSendBonus);
      console.log("before send");

      if (!errorFlag) {
        Axios.post("/pos/scheme/save", toSendBonus)
          .then((res) => {
            var snackbarProps = {
              open: true,
              variant: "success",
              message: res.data,
              autoHideDuration: 2000,
            };
            setSnackbarProps({ snackbarProps: snackbarProps });
            resetForm();
          })
          .catch((err) => {
            console.log(err);
            var message = err.message;
            if (err.response !== undefined) {
              message = err.response.data;
            }
            var snackbarProps = {
              open: true,
              variant: "error",
              message: message,
              autoHideDuration: 2000,
            };
            setSnackbarProps({ snackbarProps: snackbarProps });
          });
      }
    }

    if (schemeType === "sales") {
      let errorFlag = false;

      if (
        discountRate === "" ||
        discountRate === undefined ||
        discountRate === null
      ) {
        setDiscountRateError(true);
        errorFlag = true;
      }
      if (
        effectiveDateFrom === "" ||
        effectiveDateFrom === undefined ||
        effectiveDateFrom === null
      ) {
        setDateFromError(true);
        errorFlag = true;
      }
      if (
        effectiveDateTo === "" ||
        effectiveDateTo === undefined ||
        effectiveDateTo === null
      ) {
        setDateToError(true);
        errorFlag = true;
      }

      if (rowData.length < 1) {
        setItemError(true);
        errorFlag = true;
      }

      rowData.forEach((element) => {
        var obj = {
          itemCode: element.itemCode,
          discountPercentage: discountRate,
        };
        toSendDiscount.schemeDiscounts.push(obj);
      });
      if (!errorFlag) {
        Axios.post("/pos/scheme/save", toSendDiscount)
          .then((res) => {
            var snackbarProps = {
              open: true,
              variant: "success",
              message: res.data,
              autoHideDuration: 2000,
            };
            setSnackbarProps({ snackbarProps: snackbarProps });
            resetForm();
          })
          .catch((err) => {
            console.log(err);
            var message = err.message;
            if (err.response !== undefined) {
              message = err.response.data;
            }
            var snackbarProps = {
              open: true,
              variant: "error",
              message: message,
              autoHideDuration: 2000,
            };
            setSnackbarProps({ snackbarProps: snackbarProps });
          });
      }
    }
  };

  const handleQuantityChanged = (e) => {
    setQuantity(e.target.value);
    setQuantityError(false);
  };

  const handleEffectiveDateFromChanged = (e) => {
    setEffectiveDateFrom(e.target.value);
    setDateFromError(false);
  };

  const handleEffectiveDateToChanged = (e) => {
    setEffectiveDateTo(e.target.value);
    setDateToError(false);
  };

  const handleDiscountRateChanged = (e) => {
    setDiscountRate(e.target.value);
    setDiscountRateError(false);
  };

  const handleBonusItemChanged = (e, data) => {
    setBonusItemName(data.value);
    setBonusItemError(false);
  };

  const handleBonusQuantityChanged = (e) => {
    setBonusItemQuantity(e.target.value);
    setBonusQuantityError(false);
  };

  const handleOnTotalChanged = (e) => {
    setOnTotal(e.target.value);
  };

  const handleBonusWorthChanged = (e) => {
    setBonusWorth(e.target.value);
  };

  const resetForm = () => {
    setSchemeType("discountRate");
    setShowModal(false);
    setRowData([]);
    setQuantity("");
    setEffectiveDateFrom("");
    setEffectiveDateTo("");
    setDiscountRate("");
    setBonusItemName("");
    setBonusItemQuantity("");
    setOnTotal("");
    setBonusWorth("");
  };

  return (
    <div>
      <Grid container justify="center">
        <Grid item xs={12} lg={7} md={7} sm={10}>
          <Card
            className={classes.root}
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
          >
            <CardContent style={{ padding: "10px" }}>
              <Grid container>
                <Typography variant="h5">Add Scheme</Typography>
              </Grid>

              <Grid container style={{ marginTop: "20px" }}>
                {itemError ? (
                  <Button
                    variant="outlined"
                    color="secondary"
                    error
                    helperText="item cannot be empty"
                    onClick={() => setShowModal(true)}
                  >
                    Choose Item/Items
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setShowModal(true)}
                  >
                    Choose Item/Items
                  </Button>
                )}
              </Grid>
              <div
                className="ag-theme-balham"
                style={{
                  height: "240px",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <AgGridReact
                  columnDefs={[
                    {
                      headerName: "SN",
                      field: "sn",
                      sortable: true,
                      resizable: true,
                      width: 60,
                    },
                    {
                      headerName: "Item Code",
                      field: "itemCode",
                      sortable: true,
                      resizable: true,
                      width: 110,
                    },
                    {
                      headerName: "Item Name",
                      field: "itemName",
                      sortable: true,
                      resizable: true,
                    },
                    {
                      headerName: "Major Category",
                      field: "majorCategory",
                      sortable: true,
                      resizable: true,
                    },
                    {
                      headerName: "Category",
                      field: "category",
                      sortable: true,
                      resizable: true,
                    },
                    {
                      headerName: "Sub Category",
                      field: "subCategory",
                      sortable: true,
                      resizable: true,
                    },
                    {
                      headerName: "Delete",
                      cellRendererFramework: DeleteButton,
                      cellRendererParams: {
                        deleteRow: deleteRow,
                      },
                      width: 70,
                    },
                  ]}
                  rowData={rowData}
                  animateRows={true}
                  rowSelection="multiple"
                  onGridReady={onGridReady}
                  enableCellChangeFlash={true}
                ></AgGridReact>
              </div>
              <Grid container style={{ marginTop: "10px" }} spacing={2}>
                <Grid item>
                  <Form>
                    {quantityError ? (
                      <Form.Field>
                        <label>Quantity</label>
                        <TextField
                          variant="filled"
                          margin="dense"
                          type="number"
                          name="quantity"
                          value={quantity}
                          error
                          helperText="Quantity cannot be empty"
                          onChange={handleQuantityChanged}
                        />
                      </Form.Field>
                    ) : (
                      <Form.Field>
                        <label>Quantity</label>
                        <TextField
                          variant="filled"
                          margin="dense"
                          type="number"
                          name="quantity"
                          value={quantity}
                          onChange={handleQuantityChanged}
                        />
                      </Form.Field>
                    )}
                  </Form>
                </Grid>
                <Grid item>
                  <Form>
                    {dateFromError ? (
                      <Form.Field>
                        <label>Effective Date From</label>
                        <TextField
                          variant="filled"
                          margin="dense"
                          type="date"
                          error
                          helperText="Effective Date From cannot be empty"
                          name="effectiveDateFrom"
                          value={effectiveDateFrom}
                          onChange={handleEffectiveDateFromChanged}
                        />
                      </Form.Field>
                    ) : (
                      <Form.Field>
                        <label>Effective Date From</label>
                        <TextField
                          variant="filled"
                          margin="dense"
                          type="date"
                          name="effectiveDateFrom"
                          value={effectiveDateFrom}
                          onChange={handleEffectiveDateFromChanged}
                        />
                      </Form.Field>
                    )}
                  </Form>
                </Grid>
                <Grid item>
                  <Form>
                    {dateToError ? (
                      <Form.Field>
                        <label>Effective Date To</label>
                        <TextField
                          variant="filled"
                          margin="dense"
                          type="date"
                          error
                          helperText="Effective Date To cannot be empty"
                          name="effectiveDateTo"
                          value={effectiveDateTo}
                          onChange={handleEffectiveDateToChanged}
                        />
                      </Form.Field>
                    ) : (
                      <Form.Field>
                        <label>Effective Date To</label>
                        <TextField
                          variant="filled"
                          margin="dense"
                          type="date"
                          name="effectiveDateTo"
                          value={effectiveDateTo}
                          onChange={handleEffectiveDateToChanged}
                        />
                      </Form.Field>
                    )}
                  </Form>
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "20px" }}>
                <Typography variant="h6">Scheme Type</Typography>
              </Grid>
              <Grid container style={{ marginTop: "10px" }}>
                <RadioGroup
                  row
                  value={schemeType}
                  onChange={handleSchemeTypeChange}
                >
                  <FormControlLabel
                    value="discountRate"
                    control={<Radio color="primary" />}
                    label="Discount Rate"
                  />
                  <FormControlLabel
                    value="bonusItem"
                    control={<Radio color="primary" />}
                    label="Bonus Item"
                  />
                  <FormControlLabel
                    value="Loyalty"
                    control={<Radio color="primary" />}
                    label="Loyalty"
                  />
                  <FormControlLabel
                    value="sales"
                    control={<Radio color="primary" />}
                    label="Sales"
                  />
                </RadioGroup>
              </Grid>
              {returnView(schemeType)}

              <Grid container justify="center">
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={handleSubmit}
                  style={{
                    marginBottom: "20px",
                    marginTop: "40px",
                    paddingLeft: "47px",
                    paddingRight: "47px",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                  }}
                >
                  Add Scheme
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          open={showModal}
          onClose={() => setShowModal(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={showModal}>
            <Card style={{ width: "90vw" }}>
              <CardContent style={{ overflowY: "auto", padding: "25px" }}>
                <Grid container justify="flex-end">
                  <IconButton onClick={() => setShowModal(false)}>
                    <Close />
                  </IconButton>
                </Grid>
                <ChooseItemsModal
                  addRows={addRows}
                  closeModal={() => setShowModal(false)}
                />
              </CardContent>
            </Card>
          </Fade>
        </Modal>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(AddScheme);
