import React, { Component } from "react";
import { Grid, TextField, Divider, Button, MenuItem } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import DeleteButton from "./InterfaceElements/DeleteButton";
import { Dropdown, Input } from "semantic-ui-react";
import Axios from "axios";
import AddCustomerModal from "./Sales/AddCustomerModal";

class SalesForm extends Component {
  constructor(props) {
    super(props);

    this.state = {                               
      periodList: [],
      fyList: [],
      currentPeriod: "",
      fiscalYear: "",
      resData: [],
      columnDefs: [
        {
          headerName: "ItemCode",
          field: "code",
          sortable: true,
          resizable: true,
          width: 70,
        },
        {
          headerName: "Name",
          field: "name",
          sortable: true,
          resizable: true,
        },
        {
          headerName: "Quantity",
          field: "quantity",
          sortable: true,
          resizable: true,
          editable: true,
          width: 70,
        },
        {
          headerName: "Price",
          field: "sellingPrice",
          width: 70,
        },
        {
          headerName: "Discount",
          field: "discount",
          width: 70,
        },
        {
          headerName: "Scheme Type",
          field: "schemeType",
          width: 70,
        },
        {
          headerName: "Line Total",
          field: "lineTotal",
          sortable: true,
          width: 70,
        },
        {
          headerName: "Delete",
          cellRendererFramework: DeleteButton,
          cellRendererParams: {
            deleteRow: this.deleteRow,
            getAllRows: this.getAllRows,
          },
          width: 70,
        },
      ],
      rowData: [],
      rowSelection: "multiple",
      value: "cash",
      customerRowRefs: [],
      rowRefs: [],
      sn: 0,
      quantityMax: 0,
      quantity: 1,
      subTotal: 0,
      taxes: 0,
      discount: 0,
      total: 0,
      salesOrderNo: "",
      gin: "",
      chalanNo: "",
      itemDetails: {},
      openCustomerModal: false,
      openConfirmationModal: false,
      windowHeight: "",
      windowWidth: "",
      customerIdNumber: "",
      schemeItems: [],
      dataForCalculation: [],
      periodDateFrom:"",
      periodDateTo:"",
    };

    this.deleteRow = this.deleteRow.bind(this);
  }

  handleCustomerModalOpen = () => {
    this.setState({ openCustomerModal: true });
  };

  handleCustomerModalClose = () => {
    this.setState({ openCustomerModal: false });
    Axios.get("pos/customer/getAllCustomersList")
      .then((res) => {
        var rows = [];

        var customer = "";

        rows.push({
          key: "00",
          value: "Add Customer",
          text: "Add Customer",
          content: (
            <Dropdown.Header
              icon="add"
              content={<span style={{ cursor: "pointer" }}>Add Customer</span>}
            />
          ),
        });

        rows.push({ key: 0, value: "None", text: "None" });

        res.data.forEach((element) => {
          const item = {
            key: element.id,
            value: element.customerName,
            text: element.customerName,
          };

          customer = element.customerName;

          rows.push(item);
        });

        this.setState({ customerRowRefs: rows });
        this.props.changeCustomer(customer);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleAddCustomer = () => {
    this.handleCustomerModalOpen();
  };

  deleteRow = async (data) => {
    var rowAllData = this.state.rowData;
    var length = rowAllData.length;
    var finalRows = [];
    finalRows = rowAllData;

    if (rowAllData.length > 1) {
      for (var i = 0; i < length; i++) {
        var obj = rowAllData[i];

        if (obj.sn === data.sn) {
          finalRows.splice(i, 1);

          var subTotalNow = this.state.subTotal;
          var taxesNow = this.state.taxes;
          var discountNow = this.state.discount;
          // var totalNow = this.state.total;

          /*  var subTotalNow = this.state.subTotal;
          var taxesNow = this.state.taxes;
          var discountNow = this.state.discount;
          var totalNow = this.state.total; */

          /*   var subTotalNew = subTotalNow - obj.price * obj.quantity;
          var taxesNew = taxesNow - obj.taxes;
          var discountNew = discountNow - 0;
          var totalNew = totalNow - obj.price * obj.quantity; 
          */

          var taxesNew = 0;
          var subTotalNew = subTotalNow - obj.price * obj.quantity;

          var discountNew = discountNow - obj.discount;
          //  var totalNew = totalNow - obj.price * obj.quantity;

          if (this.props.invoicingMethod === "Abbreviated Tax Invoice") {
            taxesNew = taxesNew;
          } else {
            // taxesNew = taxesNow - obj.taxes * obj.quantity;
            taxesNew =
              taxesNow -
              (obj.totalWithoutVat * obj.quantity -
                obj.discount * obj.quantity) *
                (obj.taxes / 100);
          }

          discountNew = discountNow - obj.discount;
          // var totalNew = totalNow - obj.price * obj.quantity;

          await this.setState({
            subTotal: subTotalNew,
            taxes: taxesNew,
            discount: discountNew,
            total: (subTotalNew + taxesNew - discountNew).toFixed(2),
          });

          setTimeout(() => {
            this.props.changeBillAmount(this.state.total, this.state.subTotal);
          }, 100);
          break;
        }
      }
    } else {
      this.setState({
        subTotal: 0,
        taxes: 0,
        discount: 0,
        total: 0,
        rowData: [],
      });
      setTimeout(() => {
        this.props.changeBillAmount(this.state.total, this.state.subTotal);
      }, 100);
    }

    var rowDataAfterDel = [];

    rowAllData.forEach((el) => {
      var rowLineData = {
        discount: el.discount,
        itemCode: el.code,
        itemName: el.name,
        sellingPrice: el.sellingPrice,
        lineTotal: el.lineTotal,
        quantity: el.quantity,
        taxRate: el.taxes,
        totalWithoutVat: el.totalWithoutVat,
        totalWithVat: el.totalWithVat,
      };
      rowDataAfterDel.push(rowLineData);
    });

    var dataAll = {
      customerId: this.props.customerData.id,
      billTo: this.props.customerData.fullName,
      pan: this.props.customerData.pan,
      address: this.props.customerData.address,
      salesOrderNumber: this.props.saveDetails.salesOrderNumber,
      ginNumber: this.props.saveDetails.ginNumber,
      chalanNumber: this.props.saveDetails.chalanNumber,
      fiscalYear: this.state.fiscalYear,
      period: this.state.currentPeriod,
      salesDetail: rowDataAfterDel,
      subTotal: this.state.subTotal,
      totalDiscount: this.state.discount,
      totalTax: this.state.taxes,
    };
    console.log({ dataAll });
    this.props.changeSaveDetails(dataAll);
  };

  onCellValueChanged = (params) => {
    /*   var rowAllData = this.state.rowData;

    var subTotal = 0;
    var totalDiscount = 0;
    var totalTax = 0;
    var grandTotal = 0;
    rowAllData.forEach((element) => {
      if (element.sn === params.data.sn) {
        var obj = element;

        if (obj.quantity > this.state.quantityMax)
          obj.quantity = this.state.quantityMax;

        obj.lineTotal =
          obj.quantity * obj.price -
          obj.discount * obj.quantity +
          obj.taxes * obj.quantity;
      }

      subTotal += element.price * element.quantity;
      totalDiscount += element.discount * element.quantity;
      totalTax += element.taxes * element.quantity;
      grandTotal += element.lineTotal;
    });
    this.setState({
      subTotal,
      discount: totalDiscount,
      total: grandTotal,
      rowData: rowAllData,
      taxes: totalTax,
    });

    this.gridApi.setRowData(rowAllData);

    var obj = {
      customerId: this.props.customerData.id,
      billTo: this.props.customerData.fullName,
      pan: this.props.customerData.pan,
      address: this.props.customerData.address,
      salesOrderNumber: this.props.saveDetails.salesOrderNumber,
      ginNumber: this.props.saveDetails.ginNumber,
      chalanNumber: this.props.saveDetails.chalanNumber,
      fiscalYear: this.state.fiscalYear,
      period: this.state.currentPeriod,
      salesDetail: rowAllData,
      subTotal,
      totalDiscount: this.state.discount + 0,
      totalTax: totalTax,
    };
    this.props.changeBillAmount(this.state.total);
    this.props.changeSaveDetails(obj); */
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  handleRadioChange = (evt) => {
    this.setState({ value: evt.target.value });
  };

  componentDidMount() {
    this.getAllPeriodListing();
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    });

    window.addEventListener("resize", this.updateWindowSize);

    Axios.get("pos/inventory/getSelectedValues")
      .then((res) => {
        var rows = [];
        res.data.forEach((element) => {
          const item = {
            key: element.id,
            value: element.itemCode,
            text: element.itemName + " #" + element.itemCode,
            label: { content: "#" + element.itemCode, tag: true },
          };
          rows.push(item);
        });

        this.setState({ rowRefs: rows });
      })
      .catch((err) => {
        console.log(err);
      });

    Axios.get("pos/customer/getAllCustomersList")
      .then((res) => {
        var rows = [];

        rows.push({
          key: "00",
          value: "Add Customer",
          text: "Add Customer",
          content: (
            <Dropdown.Header
              icon="add"
              content={<span style={{ cursor: "pointer" }}>Add Customer</span>}
            />
          ),
        });

        rows.push({ key: 0, value: "None", text: "None" });

        res.data.forEach((element) => {
          const item = {
            key: element.id,
            value: element.customerName,
            text: element.customerName,
          };
          rows.push(item);
        });

        this.setState({ customerRowRefs: rows });
      })
      .catch((err) => {
        console.log(err);
      });

    this.props.setReset(this.resetTable);
  }

  updateWindowSize = () => {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    });
  };

  getAllPeriodListing = () => {
    Axios.get("/fy/getAllActivePeriods")
      .then(async (res) => {
        var fiscalYearLists = [];
        res.data.forEach((element) => {
          var fy = Object.keys(element);

          fiscalYearLists.push(fy[0]);
        });
        await this.setState({
          fyList: fiscalYearLists,
          resData: res.data,
        });

        this.getCurrentPeriod();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCurrentPeriod() {
    Axios.get("/fy/getCurrentPeriod")
      .then(async (res) => {
        await this.setState((prevState) => ({
          ...prevState,
          fiscalYear: res.data.fiscalYear,
          currentPeriod: res.data.name,
        }));
        this.getPeriodList();
        this.getSalesNoList(res.data.fiscalYear);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleFYChange = async (e) => {
    let Fy = e.target.value;
    await this.setState((prevState) => ({
      ...prevState,
      fiscalYear: Fy,
    }));
    this.getPeriodList();
    this.getSalesNoList(Fy);
  };

  getSalesNoList = async (fy) => {
    Axios.post("/pos/voucherNumbering/getVoucherNumbering", {
      fiscalYear: fy,
      name: "Sales",
    })
      .then(async (res) => {
        if (res.data === "Not Found") {
          await this.setState({ voucherNumberError: true });
        } else {
          this.props.getVoucherNumber(res.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getPeriodList() {
    var pLists = [];

    this.state.resData.map((val) =>
      Object.keys(val).includes(this.state.fiscalYear)
        ? (pLists = val[this.state.fiscalYear])
        : null
    );

    this.setState((prevState) => ({
      ...prevState,
      periodList: pLists,
      currentPeriod: pLists[pLists.length-1]
    }));
    this.getPeriodDateFromTo(this.state.fiscalYear,pLists[pLists.length-1]);
  }

  handlePeriodChange = async (e) => {
    let Py = e.target.value;

    await this.setState((prevState) => ({
      ...prevState,
      currentPeriod: Py,
    }));
    this.getPeriodDateFromTo(this.state.fiscalYear, Py);
  };

  getPeriodDateFromTo = (fiscalYear, period) => {
    Axios.post("fy/findByNameAndFiscalYear", { period, fiscalYear }).then(
      (res) =>
        this.setState((prevState)=>({
          ...prevState,
          periodDateFrom: res.data.dateFrom,
          periodDateTo: res.data.dateTo,
        }))
    );
  };

  handleItemSelect = (event, data) => {
    const { value } = data;

    Axios.get("pos/inventory/findItemAndScheme/" + value)
      .then(async (res) => {
     
        let quantityMax = res.data.quantity;
        this.state.rowData.map((el) => {
          if (el.code === value) {
            quantityMax = quantityMax - el.quantity;
          }
        });
        await this.setState({
          quantityMax: quantityMax,
          itemDetails: res.data,
          taxRate: 0,
        });
        if (parseFloat(quantityMax) > 0) {
          await this.setState({
            quantity: 1,
          });
        } else {
          await this.setState({
            quantity: 0,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  handleCustomerSelect = (event, data) => {
    const { value } = data;
    const { key } = data.options.find((o) => o.value === value);

    console.log(key);

    // this.setState({
    //   customerIdNumber: key
    // });

    if (value === "None") {
      var customerData = {
        id: "",
        fullName: "",
        address: "",
        mobileNo: "",
        email: "",
        pan: "",
      };

      this.props.changeCustomerData(customerData);
    } else if (value === "Add Customer") {
      this.handleAddCustomer();
      return;
    } else {
      Axios.get("pos/customer/getCustomer/" + key)
        .then((res) => {
          var customerData = {
            id: res.data.id === null ? "" : res.data.id,
            fullName: res.data.fullName === null ? "" : res.data.fullName,
            address:
              res.data.city === null
                ? ""
                : res.data.city + ", " + res.data.country,
            mobileNo:
              res.data.mobileNumber === null ? "" : res.data.mobileNumber,
            email: res.data.emailAddress === null ? "" : res.data.emailAddress,
            pan: res.data.pan === null ? "" : res.data.pan,
          };
          this.setState((prevState) => ({
            ...prevState,
            customerIdNumber: res.data.customerIdNumber,
          }));
          this.props.changeCustomerData(customerData);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // this.setState({
    //   customer: value
    // });
    this.props.changeCustomer(value);
  };

  handleTextChange = (e) => {
    e.stopPropagation();
    var targetName = e.target.name;
    var value = e.target.value;

    this.props.changeSaveDetailsIndividual(targetName, value);

    // this.setState(prevState => ({
    //   ...prevState,
    //   [targetName]: value
    // }));
  };

  handleQuantityChange = (evt) => {
    var val = evt.target.value;

    if (val > this.state.quantityMax) {
      this.setState((prevState) => ({
        ...prevState,
        quantity: this.state.quantityMax,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        quantity: Math.round(val * 100) / 100,
      }));
    }
  };

  resetTable = () => {
    this.setState({ rowData: [] });
    this.setState({
      subTotal: 0,
      taxes: 0,
      discount: 0,
      total: 0,
      quantityMax: 0,
    });
    this.getSalesNoList(this.state.fiscalYear);
    // this.props.changeBillAmount(0);
  };

  handleOnKeyPress = (e) => {
    if (e.key === "Enter") this.handleItemAdd();
  };

  handle0Index = async (rowAllData, rowsToSave, itemData, invoicingMethod) => {
    // let taxAmount = (itemData.taxable.rate / 100) * itemData.sellingPrice;
    // let taxAmount = 0;
    //---------------------------Abbreviate Tax zero Index------------------------------
    if (invoicingMethod === "Abbreviated Tax Invoice") {
      let discount = 0;
      //let vatPercentage = 0;
      console.log({ itemData });
      let schemeType =
        itemData.scheme === undefined ? "" : itemData.scheme.schemeType;

      if (schemeType === "DiscountPrice") {
        //vatPercentage = itemData.vatPercentage;
        discount = parseFloat(
          (itemData.scheme.discountPercentage / 100) * itemData.markedPrice
        );
      }
      var rowItemData = {
        sn: this.state.sn,
        code: itemData.itemCode,
        name: itemData.itemName,
        quantity: this.state.quantity,
        taxes: itemData.vatPercentage,
        price: itemData.totalWithVat.toFixed(2),
        discount: discount.toFixed(2),
        lineTotal: (itemData.markedPrice * this.state.quantity).toFixed(2),
        schemeType:
          itemData.scheme === undefined ? "" : itemData.scheme.schemeType,
        sellingPrice: itemData.markedPrice,
        totalWithoutVat: itemData.total,
        totalWithVat: itemData.totalWithVat,
      };

      var toSave = true;

      rowAllData.forEach((element) => {
        if (element.name === itemData.itemName) {
          toSave = false;
        }
      });

      if (toSave) {
        rowAllData.push(rowItemData);

        if (schemeType === "BonusItem") {
          let res = await Axios.get(
            "pos/inventory/getItemDetails/" + itemData.scheme.bonusItemCode
          );
   
          let itemQuantity = itemData.scheme.itemQuantity;
          if (itemQuantity === this.state.quantity) {
            rowAllData.push({
              name: res.data.itemName,
              code: itemData.scheme.bonusItemCode,
              quantity: itemData.scheme.bonusItemQuantity,
            });
          }
        }
        rowAllData.forEach((element) => {
          var itemToAddDiff = {
            discount: element.discount,
            itemCode: element.code,
            itemName: element.name,
            lineTotal: parseFloat(element.lineTotal),
            price: parseFloat(element.sellingPrice),
            quantity: element.quantity,
            taxRate: element.taxes,
            totalWithoutVat: element.totalWithoutVat,
            totalWithVat: element.totalWithVat,
          };

          rowsToSave.push(itemToAddDiff);
        });
      }
    }

    //---------------------------VAT Zero Index------------------------------
    else if (invoicingMethod === "VAT") {
      var vatPercentage = 0;
      let discount = 0;
      let schemeType =
        itemData.scheme === undefined ? "" : itemData.scheme.schemeType;

      if (schemeType === "DiscountPrice") {
        discount = (itemData.scheme.discountPercentage / 100) * itemData.total;
      }
      vatPercentage =
        (itemData.total - discount) * (itemData.vatPercentage / 100);
      rowItemData = {
        sn: this.state.sn,
        code: itemData.itemCode,
        name: itemData.itemName,
        quantity: this.state.quantity,
        // taxes: taxAmount,
        taxes: vatPercentage.toFixed(2),
        price: itemData.total.toFixed(2),
        discount: discount.toFixed(2),
        lineTotal: (itemData.markedPrice * this.state.quantity).toFixed(2),
        schemeType:
          itemData.scheme === undefined ? "" : itemData.scheme.schemeType,
        sellingPrice: itemData.markedPrice,
        totalWithoutVat: itemData.total,
        totalWithVat: itemData.totalWithVat,
      };

      //  this.setState({ dataForCalculation: rowItemData });
      toSave = true;

      rowAllData.forEach((element) => {
        if (element.name === itemData.itemName) {
          toSave = false;
        }
      });

      if (toSave) {
        rowAllData.push(rowItemData);

        if (schemeType === "BonusItem") {
          let res = await Axios.get(
            "pos/inventory/getItemDetails/" + itemData.scheme.bonusItemCode
          );

          let itemQuantity = itemData.scheme.itemQuantity;
          if (itemQuantity === this.state.quantity) {
            rowAllData.push({
              name: res.data.itemName,
              code: itemData.scheme.bonusItemCode,
              quantity: itemData.scheme.bonusItemQuantity,
            });
          }
        }
        rowAllData.forEach((element) => {
          var itemToAddDiff = {
            discount: element.discount,
            itemCode: element.code,
            itemName: element.name,
            lineTotal: parseFloat(element.lineTotal),
            price: parseFloat(element.price),
            quantity: element.quantity,
            taxRate: element.taxes,
            totalWithoutVat: element.totalWithoutVat,
            totalWithVat: element.totalWithVat,
          };

          rowsToSave.push(itemToAddDiff);
        });
      }
    }
    //------------------------PAN Zero index------------------------
    else if (this.props.invoicingMethod === "PAN") {
      let discount = 0;
      let vatPercentage = 0;

      let schemeType =
        itemData.scheme === undefined ? "" : itemData.scheme.schemeType;

      if (schemeType === "DiscountPrice") {
        //vatPercentage = itemData.vatPercentage;
        discount = parseFloat(
          (itemData.scheme.discountPercentage / 100) * itemData.markedPrice
        );
      }
      rowItemData = {
        sn: this.state.sn,
        code: itemData.itemCode,
        name: itemData.itemName,
        quantity: this.state.quantity,
        taxes: vatPercentage,
        price: itemData.markedPrice.toFixed(2),
        discount: discount.toFixed(2),
        lineTotal: (itemData.markedPrice * this.state.quantity).toFixed(2),
        schemeType:
          itemData.scheme === undefined ? "" : itemData.scheme.schemeType,
        sellingPrice: itemData.markedPrice,
        totalWithoutVat: itemData.total,
        totalWithVat: itemData.totalWithVat,
      };

      toSave = true;

      rowAllData.forEach((element) => {
        if (element.name === itemData.itemName) {
          toSave = false;
        }
      });

      if (toSave) {
        rowAllData.push(rowItemData);

        if (schemeType === "BonusItem") {
          let res = await Axios.get(
            "pos/inventory/getItemDetails/" + itemData.scheme.bonusItemCode
          );

          let itemQuantity = itemData.scheme.itemQuantity;
          if (itemQuantity === this.state.quantity) {
            rowAllData.push({
              name: res.data.itemName,
              code: itemData.scheme.bonusItemCode,
              quantity: itemData.scheme.bonusItemQuantity,
            });
          }
        }
        rowAllData.forEach((element) => {
          var itemToAddDiff = {
            discount: element.discount,
            itemCode: element.code,
            itemName: element.name,
            lineTotal: parseFloat(element.lineTotal),
            price: parseFloat(element.price),
            quantity: element.quantity,
            taxRate: element.taxes,
            totalWithoutVat: element.totalWithoutVat,
            totalWithVat: element.totalWithVat,
          };

          rowsToSave.push(itemToAddDiff);
        });
      }
    } else {
    }
  };

  getAllRows = () => {
    let rowData = [];
    this.gridApi.forEachNode((node) => rowData.push(node.data));
    return rowData;
  };

  handleItemAdd = async () => { 
    //-----------------------------Abbreviated Tax Invoice-------------------------
    if (this.props.invoicingMethod === "Abbreviated Tax Invoice") {

      let invoicingMethod = this.props.invoicingMethod;
      var rows = this.getAllRows();

      var itemData = this.state.itemDetails;

      if (
        itemData.itemName === undefined ||
        this.state.quantity === 0 ||
        this.state.quantity === ""
      ) {
        return;
      }

      let schemeType =
        itemData.scheme === undefined ? "" : itemData.scheme.schemeType;
      let discount = 0;
      if (schemeType === "DiscountPrice") {
        discount = (
          (itemData.scheme.discountPercentage / 100) *
          itemData.markedPrice
        ).toFixed(2);
      }

      var rowAllData = this.state.rowData;

      // var taxValue = (itemData.taxable.rate / 100) * itemData.sellingPrice;
      var taxValue = 0;
      var taxTotal = this.state.taxes;
      var subTotal =
        this.state.subTotal + itemData.markedPrice * this.state.quantity;

      var totalDiscount = parseFloat(
        this.state.discount + discount * this.state.quantity
      );

      var total = subTotal - totalDiscount;

      var currentMax = this.state.quantityMax;

      await new Promise((accept) =>
        this.setState(
          {
            subTotal: subTotal,
            taxes: taxTotal,
            discount: totalDiscount,
            total: total.toFixed(2),
            quantityMax: this.state.quantityMax - this.state.quantity,
          },
          accept
        )
      );
      var rowsToSave = [];

      if (rows.length === 0) {
        this.handle0Index(rowAllData, rowsToSave, itemData, invoicingMethod);
      } else {
        for (let index = 0; index < rows.length; index++) {
          const element = rowAllData[index];
          // let taxAmount = (itemData.taxable.rate / 100) * itemData.sellingPrice;
          // let taxAmount = 0;

          if (element.name === itemData.itemName) {
            var newQuantity = element.quantity + this.state.quantity;
            var newDiscount = element.discount;
            console.log({ newDiscount });
            if (schemeType === "BonusItem") {
              let itemQuantity = itemData.scheme.itemQuantity;

              if (newQuantity % itemQuantity === 0) {
                let matched = false;

                rowAllData.forEach((element) => {
                  if (element.code === itemData.scheme.bonusItemCode) {
                    element.quantity += itemData.scheme.bonusItemQuantity;
                    matched = true;
                  }
                });

                console.log({ matched });

                if (!matched) {
                  let res = await Axios.get(
                    "pos/inventory/getItemDetails/" +
                      itemData.scheme.bonusItemCode
                  );

                  rowAllData.push({
                    name: res.data.itemName,
                    code: itemData.scheme.bonusItemCode,
                    quantity: itemData.scheme.bonusItemQuantity,
                  });
                }
              }
            }

            var rowSpecificData = {
              ...element,
              taxes: itemData.vatPercentage,
              quantity: newQuantity,
              discount: newDiscount,
              lineTotal: itemData.markedPrice * newQuantity, //- element.discount * newQuantity,
            };
            rowAllData[index] = rowSpecificData;

            rowAllData.forEach((element) => {
              var itemToAdd = {
                discount: element.discount,
                itemCode: element.code,
                itemName: element.name,
                lineTotal: element.lineTotal,
                sellingPrice: element.sellingPrice,
                quantity: element.quantity,
                taxRate: element.taxes,
                totalWithoutVat: element.totalWithoutVat,
                totalWithVat: element.totalWithVat,
              };
              rowsToSave.push(itemToAdd);
            });
          } else {
            this.handle0Index(
              rowAllData,
              rowsToSave,
              itemData,
              invoicingMethod
            );
          }
        }
      }
      this.setState({ sn: this.state.sn + 1 });

      this.setState({ rowData: rowAllData });
      
      var obj = {
        customerId: this.props.customerData.id,
        billTo: this.props.customerData.fullName,
        pan: this.props.customerData.pan,
        address: this.props.customerData.address,
        salesOrderNumber: this.props.saveDetails.salesOrderNumber,
        ginNumber: this.props.saveDetails.ginNumber,
        chalanNumber: this.props.saveDetails.chalanNumber,
        fiscalYear: this.state.fiscalYear,
        period: this.state.currentPeriod,
        salesDetail: rowsToSave,
        subTotal: this.state.subTotal,
        totalDiscount: this.state.discount,
        totalTax: taxTotal,
      };
      this.gridApi.setRowData(rowAllData);
      //const handleItemAddObj = obj;
      //this.setState({ dataForCalculation: handleItemAddObj });

      this.props.changeBillAmount(
        this.state.total,
        this.state.subTotal,
        this.state.dataForCalculation
      );
      this.props.changeSaveDetails(obj);

      if (this.state.quantity === currentMax) {
        this.setState({ quantity: 0 });
      } else {
        this.setState({ quantity: 1 });
      }
    }

    //----------------------Invoicing Method VAT-------------------
    else if (this.props.invoicingMethod === "VAT") {
      let invoicingMethod = this.props.invoicingMethod;
      rows = this.getAllRows();

      itemData = this.state.itemDetails;

      if (
        itemData.itemName === undefined ||
        this.state.quantity === 0 ||
        this.state.quantity === ""
      ) {
        return;
      }

      let schemeType =
        itemData.scheme === undefined ? "" : itemData.scheme.schemeType;
      let discount = 0;

      if (schemeType === "DiscountPrice") {
        discount = (
          (itemData.scheme.discountPercentage / 100) *
          itemData.total
        ).toFixed(2);
      }

      rowAllData = this.state.rowData;
      taxValue = (itemData.vatPercentage / 100) * (itemData.total - discount);

      taxTotal = this.state.taxes + taxValue * this.state.quantity;

      subTotal = this.state.subTotal + itemData.total * this.state.quantity;

      totalDiscount = this.state.discount + discount * this.state.quantity;

      total = subTotal + taxTotal - totalDiscount;

      currentMax = this.state.quantityMax;

      await new Promise((accept) =>
        this.setState(
          {
            subTotal: subTotal,
            taxes: taxTotal,
            discount: totalDiscount,
            total: total.toFixed(2),
            quantityMax: this.state.quantityMax - this.state.quantity,
          },
          accept
        )
      );

      rowsToSave = [];

      if (rows.length === 0) {
        this.handle0Index(rowAllData, rowsToSave, itemData, invoicingMethod);
      } else {
        for (let index = 0; index < rows.length; index++) {
          const element = rowAllData[index];

          // let taxAmount = 0;

          if (element.name === itemData.itemName) {
            newQuantity = element.quantity + this.state.quantity;
            newDiscount = element.discount;
            var taxAmount = element.taxes;
            /*    let taxAmount =
              (element.totalWithoutVat * newQuantity -
                newDiscount * newQuantity) *
              (element.taxes / 100); */

            if (schemeType === "BonusItem") {
              let itemQuantity = itemData.scheme.itemQuantity;

              if (newQuantity % itemQuantity === 0) {
                let matched = false;

                rowAllData.forEach((element) => {
                  if (element.code === itemData.scheme.bonusItemCode) {
                    element.quantity += itemData.scheme.bonusItemQuantity;
                    matched = true;
                  }
                });

                console.log({ matched });

                if (!matched) {
                  let res = await Axios.get(
                    "pos/inventory/getItemDetails/" +
                      itemData.scheme.bonusItemCode
                  );

                  rowAllData.push({
                    name: res.data.itemName,
                    code: itemData.scheme.bonusItemCode,
                    quantity: itemData.scheme.bonusItemQuantity,
                  });
                }
              }
            }

            var rowSpecificData = {
              ...element,
              taxes: taxAmount,
              quantity: newQuantity,
              discount: newDiscount,
              lineTotal: itemData.markedPrice * newQuantity, //- element.discount * newQuantity,
            };
            rowAllData[index] = rowSpecificData;
            rowAllData.forEach((element) => {
              var itemToAdd = {
                discount: element.discount,
                itemCode: element.code,
                itemName: element.name,
                lineTotal: element.lineTotal,
                sellingPrice: element.sellingPrice,
                quantity: element.quantity,
                taxRate: element.taxes,
                totalWithoutVat: element.totalWithoutVat,
                totalWithVat: element.totalWithVat,
              };
              rowsToSave.push(itemToAdd);
            });
          } else {
            this.handle0Index(
              rowAllData,
              rowsToSave,
              itemData,
              invoicingMethod
            );
          }
        }
      }
      this.setState({ sn: this.state.sn + 1 });

      this.setState((prevState) => ({ ...prevState, rowData: rowAllData }));

      obj = {
        customerId: this.props.customerData.id,
        billTo: this.props.customerData.fullName,
        pan: this.props.customerData.pan,
        address: this.props.customerData.address,
        salesOrderNumber: this.props.saveDetails.salesOrderNumber,
        ginNumber: this.props.saveDetails.ginNumber,
        chalanNumber: this.props.saveDetails.chalanNumber,
        fiscalYear: this.state.fiscalYear,
        period: this.state.currentPeriod,
        salesDetail: rowsToSave,
        subTotal: this.state.subTotal,
        totalDiscount: this.state.discount,
        totalTax: taxTotal,
      };

      this.gridApi.setRowData(rowAllData);
      const handleItemAddObj = obj;

      this.setState({ dataForCalculation: handleItemAddObj });

      this.props.changeBillAmount(
        this.state.total,
        this.state.subTotal,
        this.state.dataForCalculation
      );
      this.props.changeSaveDetails(obj);

      if (this.state.quantity === currentMax) {
        this.setState({ quantity: 0 });
      } else {
        this.setState({ quantity: 1 });
      }
    }

    //----------------------Invoicing Method PAN-------------------
    else if (this.props.invoicingMethod === "PAN") {

      let invoicingMethod = this.props.invoicingMethod;
      rows = this.getAllRows();

      itemData = this.state.itemDetails;

      if (
        itemData.itemName === undefined ||
        this.state.quantity === 0 ||
        this.state.quantity === ""
      ) {
        return;
      }

      let schemeType =
        itemData.scheme === undefined ? "" : itemData.scheme.schemeType;
      let discount = 0;
      if (schemeType === "DiscountPrice") {
        discount = (
          (itemData.scheme.discountPercentage / 100) *
          itemData.markedPrice
        ).toFixed(2);
      }

      rowAllData = this.state.rowData;
      // var taxValue = (itemData.taxable.rate / 100) * itemData.sellingPrice;
      taxValue = 0;
      taxTotal = this.state.taxes;
      subTotal =
        this.state.subTotal + itemData.markedPrice * this.state.quantity;

      totalDiscount = parseFloat(
        this.state.discount + discount * this.state.quantity
      );
      console.log({ totalDiscount });
      total = subTotal - totalDiscount;

      currentMax = this.state.quantityMax;

      await new Promise((accept) =>
        this.setState(
          {
            subTotal: subTotal,
            taxes: taxTotal,
            discount: totalDiscount,
            total: total.toFixed(2),
            quantityMax: this.state.quantityMax - this.state.quantity,
          },
          accept
        )
      );
      rowsToSave = [];

      if (rows.length === 0) {
        this.handle0Index(rowAllData, rowsToSave, itemData, invoicingMethod);
      } else {
        for (let index = 0; index < rows.length; index++) {
          const element = rowAllData[index];
          // let taxAmount = (itemData.taxable.rate / 100) * itemData.sellingPrice;
          let taxAmount = 0;

          if (element.name === itemData.itemName) {
            newQuantity = element.quantity + this.state.quantity;
            newDiscount = element.discount;

            if (schemeType === "BonusItem") {
              let itemQuantity = itemData.scheme.itemQuantity;

              if (newQuantity % itemQuantity === 0) {
                let matched = false;

                rowAllData.forEach((element) => {
                  if (element.code === itemData.scheme.bonusItemCode) {
                    element.quantity += itemData.scheme.bonusItemQuantity;
                    matched = true;
                  }
                });

                console.log({ matched });

                if (!matched) {
                  let res = await Axios.get(
                    "pos/inventory/getItemDetails/" +
                      itemData.scheme.bonusItemCode
                  );

                  rowAllData.push({
                    name: res.data.itemName,
                    code: itemData.scheme.bonusItemCode,
                    quantity: itemData.scheme.bonusItemQuantity,
                  });
                }
              }
            }

            rowSpecificData = {
              ...element,
              taxes: taxAmount,
              quantity: newQuantity,
              discount: newDiscount,
              lineTotal: itemData.markedPrice * newQuantity, //- element.discount * newQuantity,
            };
            rowAllData[index] = rowSpecificData;
            rowAllData.forEach((element) => {
              var itemToAdd = {
                discount: element.discount,
                itemCode: element.code,
                itemName: element.name,
                lineTotal: element.lineTotal,
                sellingPrice: element.sellingPrice,
                quantity: element.quantity,
                taxRate: element.taxes,
                totalWithoutVat: element.totalWithoutVat,
                totalWithVat: element.totalWithVat,
              };
              rowsToSave.push(itemToAdd);
            });
          } else {
            this.handle0Index(
              rowAllData,
              rowsToSave,
              itemData,
              invoicingMethod
            );
          }
        }
      }
      this.setState({ sn: this.state.sn + 1 });

      this.setState({ rowData: rowAllData });

      obj = {
        customerId: this.props.customerData.id,
        billTo: this.props.customerData.fullName,
        pan: this.props.customerData.pan,
        address: this.props.customerData.address,
        salesOrderNumber: this.props.saveDetails.salesOrderNumber,
        ginNumber: this.props.saveDetails.ginNumber,
        chalanNumber: this.props.saveDetails.chalanNumber,
        fiscalYear: this.state.fiscalYear,
        period: this.state.currentPeriod,
        salesDetail: rowsToSave,
        subTotal: this.state.subTotal,
        totalDiscount: this.state.discount,
        totalTax: taxTotal,
      };
      this.gridApi.setRowData(rowAllData);
      const handleItemAddObj = obj;

      this.setState({ dataForCalculation: handleItemAddObj });

      this.props.changeBillAmount(
        this.state.total,
        this.state.subTotal,
        this.state.dataForCalculation
      );
      this.props.changeSaveDetails(obj);

      if (this.state.quantity === currentMax) {
        this.setState({ quantity: 0 });
      } else {
        this.setState({ quantity: 1 });
      }
    }
  };

  render() {
    return (
      <div>
        {this.state.windowHeight < 1000 && this.state.windowWidth < 1900 ? (
          <div>
            <Grid container spacing={2} style={{ width: "85%" }}>
              <Grid item xs={8}>
                <div style={{ width: "100%" }}>
                  <Dropdown
                    placeholder="Search by Customer Name"
                    fluid
                    search
                    selection
                    value={this.props.customer}
                    onChange={this.handleCustomerSelect}
                    options={this.state.customerRowRefs}
                  ></Dropdown>
                </div>
              </Grid>

              <Grid item xs={4}>
                <Input fluid value={this.state.customerIdNumber} />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  variant="outlined"
                  select
                  margin="dense"
                  fullWidth
                  label="Fiscal Year"
                  name="fiscalYear"
                  onChange={this.handleFYChange}
                  value={this.state.fiscalYear}
                >
                  {this.state.fyList.map((item, i) => (
                    <MenuItem value={item} key={i}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-dense"
                  label="Sales Order No"
                  value={this.props.saveDetails.salesOrderNumber}
                  onChange={this.handleTextChange}
                  style={{ width: "100%" }}
                  margin="dense"
                  name="salesOrderNumber"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  variant="outlined"
                  select
                  margin="dense"
                  fullWidth
                  label="Period"
                  name="period"
                  //onChange={this.handlePeriodChange}
                  value={this.state.currentPeriod}
                >
                  {this.state.periodList.map((item, i) => (
                    <MenuItem value={item} key={i}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-dense"
                  label="PAN"
                  value={this.props.customerData.pan}
                  style={{ width: "100%" }}
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-dense"
                  label="GIN"
                  value={this.props.saveDetails.ginNumber}
                  name="ginNumber"
                  onChange={this.handleTextChange}
                  style={{ width: "100%" }}
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-dense"
                  label="Chalan Number"
                  name="chalanNumber"
                  value={this.props.saveDetails.chalanNumber}
                  onChange={this.handleTextChange}
                  style={{ width: "100%" }}
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-dense"
                  label="Mobile No"
                  value={this.props.customerData.mobileNo}
                  style={{ width: "100%", marginTop: "3px" }}
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-dense"
                  label="Email"
                  value={this.props.customerData.email}
                  style={{ width: "100%", marginTop: "3px" }}
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-dense"
                  label="Address"
                  value={this.props.customerData.address}
                  style={{ width: "100%", marginTop: "3px" }}
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
            <Grid container spacing={2} style={{ marginTop: "5px" }}>
              <Grid item xs={7}>
                <div style={{ width: "100%" }}>
                  <Dropdown
                    placeholder="Search by Item Code or Item Name"
                    fluid
                    search
                    selection
                    searchInput={{ autoFocus: true }}
                    openOnFocus={false}
                    selectOnBlur={false}
                    onKeyDown={this.handleOnKeyPress}
                    onChange={this.handleItemSelect}
                    options={this.state.rowRefs}
                  ></Dropdown>
                </div>
              </Grid>
              <Grid item xs={3}>
                <Input
                  placeholder="Quantity"
                  type="number"
                  min={1}
                  onChange={this.handleQuantityChange}
                  max={this.state.quantityMax}
                  value={this.state.quantity}
                  style={{ width: "100%" }}
                ></Input>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={this.handleItemAdd}
                  style={{
                    margin: "auto",
                    height: "38px",
                    width: "100%",
                  }}
                >
                  + Add
                </Button>
              </Grid>
            </Grid>

            <Grid container>
              <div
                className="ag-theme-balham"
                style={{
                  height: "240px",
                  width: "100%",
                  marginTop: "30px",
                }}
              >
                <AgGridReact
                  columnDefs={this.state.columnDefs}
                  rowData={this.state.rowData}
                  animateRows={true}
                  rowSelection={this.state.rowSelection}
                  onGridReady={this.onGridReady}
                  onCellValueChanged={this.onCellValueChanged}
                  enableCellChangeFlash={true}
                ></AgGridReact>
              </div>
            </Grid>
            <Grid container spacing={4} style={{ marginTop: "10px" }}>
              <Grid item>
                <p>
                  Sub Total:{" "}
                  <span
                    style={{ display: "inline-block", width: "20px" }}
                  ></span>
                  <strong>Rs. {this.state.subTotal}</strong>
                </p>
              </Grid>
              <Grid item>
                <p>
                  Taxes:{" "}
                  <span
                    style={{ display: "inline-block", width: "48px" }}
                  ></span>
                  <strong>Rs. {this.state.taxes.toFixed(2)}</strong>
                </p>
              </Grid>
              <Grid item>
                <p>
                  Discount:{" "}
                  <span
                    style={{ display: "inline-block", width: "23px" }}
                  ></span>
                  <strong>Rs. {this.state.discount.toFixed(2)}</strong>
                </p>
              </Grid>
              <Grid item>
                <p>
                  Total:{" "}
                  <span
                    style={{ display: "inline-block", width: "43px" }}
                  ></span>
                  <strong>Rs. {this.state.total}</strong>
                </p>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div>
            <Grid
              container
              spacing={4}
              style={{ width: "85%", marginTop: "15px" }}
            >
              <Grid item xs={8}>
                <div style={{ width: "100%" }}>
                  <Dropdown
                    placeholder="Search by Customer Name"
                    fluid
                    search
                    selection
                    value={this.props.customer}
                    onChange={this.handleCustomerSelect}
                    options={this.state.customerRowRefs}
                  ></Dropdown>
                </div>
              </Grid>

              <Grid item xs={4}>
                <Input
                  label="Customer ID"
                  fluid
                  value={this.state.customerIdNumber}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  variant="outlined"
                  select
                  margin="dense"
                  fullWidth
                  label="Fiscal Year"
                  name="fiscalYear"
                  onChange={this.handleFYChange}
                  value={this.state.fiscalYear}
                >
                  {this.state.fyList.map((item, i) => (
                    <MenuItem value={item} key={i}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-dense"
                  label="Sales Order No"
                  value={this.props.saveDetails.salesOrderNumber}
                  onChange={this.handleTextChange}
                  style={{ width: "100%" }}
                  margin="dense"
                  name="salesOrderNumber"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  variant="outlined"
                  select
                  margin="dense"
                  fullWidth
                  label="Period"
                  name="period"
                  //onChange={this.handlePeriodChange}
                  value={this.state.currentPeriod}
                >
                  {this.state.periodList.map((item, i) => (
                    <MenuItem value={item} key={i}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-dense"
                  label="PAN"
                  value={this.props.customerData.pan}
                  style={{ width: "100%" }}
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-dense"
                  label="GIN"
                  value={this.props.saveDetails.ginNumber}
                  name="ginNumber"
                  onChange={this.handleTextChange}
                  style={{ width: "100%" }}
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-dense"
                  label="Chalan Number"
                  name="chalanNumber"
                  value={this.props.saveDetails.chalanNumber}
                  onChange={this.handleTextChange}
                  style={{ width: "100%" }}
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-dense"
                  label="Mobile No"
                  value={this.props.customerData.mobileNo}
                  style={{ width: "100%", marginTop: "3px" }}
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-dense"
                  label="Email"
                  value={this.props.customerData.email}
                  style={{ width: "100%", marginTop: "3px" }}
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-dense"
                  label="Address"
                  value={this.props.customerData.address}
                  style={{ width: "100%", marginTop: "3px" }}
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Divider style={{ marginTop: "35px", marginBottom: "25px" }} />
            <Grid container spacing={2} style={{ marginTop: "5px" }}>
              <Grid item xs={7}>
                <div style={{ width: "100%" }}>
                  <Dropdown
                    placeholder="Search by Item Code or Item Name"
                    fluid
                    search
                    selection
                    searchInput={{ autoFocus: true }}
                    openOnFocus={false}
                    selectOnBlur={false}
                    onKeyDown={this.handleOnKeyPress}
                    onChange={this.handleItemSelect}
                    options={this.state.rowRefs}
                  ></Dropdown>
                </div>
              </Grid>
              <Grid item xs={3}>
                <Input
                  placeholder="Quantity"
                  type="number"
                  min={1}
                  onChange={this.handleQuantityChange}
                  max={this.state.quantityMax}
                  value={this.state.quantity}
                  style={{ width: "100%" }}
                ></Input>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={this.handleItemAdd}
                  style={{
                    margin: "auto",
                    height: "38px",
                    width: "100%",
                  }}
                >
                  + Add
                </Button>
              </Grid>
            </Grid>

            <Grid container>
              <div
                className="ag-theme-balham"
                style={{
                  height: "40vh",
                  width: "100%",
                  marginTop: "30px",
                }}
              >
                <AgGridReact
                  columnDefs={this.state.columnDefs}
                  rowData={this.state.rowData}
                  animateRows={true}
                  rowSelection={this.state.rowSelection}
                  onGridReady={this.onGridReady}
                  onCellValueChanged={this.onCellValueChanged}
                  enableCellChangeFlash={true}
                ></AgGridReact>
              </div>
            </Grid>
            <Grid container spacing={4} style={{ marginTop: "10px" }}>
              <Grid item>
                <p>
                  Sub Total:{" "}
                  <span
                    style={{ display: "inline-block", width: "20px" }}
                  ></span>
                  <strong>Rs. {this.state.subTotal}</strong>
                </p>
              </Grid>
              <Grid item>
                <p>
                  Taxes:{" "}
                  <span
                    style={{ display: "inline-block", width: "48px" }}
                  ></span>
                  <strong>Rs. {this.state.taxes.toFixed(2)}</strong>
                </p>
              </Grid>
              <Grid item>
                <p>
                  Discount:{" "}
                  <span
                    style={{ display: "inline-block", width: "23px" }}
                  ></span>
                  <strong>Rs. {this.state.discount.toFixed(2)}</strong>
                </p>
              </Grid>
              <Grid item>
                <p>
                  Total:{" "}
                  <span
                    style={{ display: "inline-block", width: "43px" }}
                  ></span>
                  <strong>Rs. {this.state.total}</strong>
                </p>
              </Grid>
            </Grid>
          </div>
        )}

        {/* <Typography style={{ padding: "10px", fontSize: "16px" }}>
          Customer Details
        </Typography> */}

        <AddCustomerModal
          open={this.state.openCustomerModal}
          handleClose={this.handleCustomerModalClose}
        />
        {/*  <SalesInvoice3mmVAT
          dataForCalculation={this.state.dataForCalculation}
        /> */}
      </div>
    );
  }
}

export default SalesForm;
