import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  Card,
  CardContent,
  Grid,
  TextField,
  FormControlLabel,
  Typography,
  Button,
  withStyles,
  MenuItem,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import Axios from "axios";
import HeaderTitle from "../components/InterfaceElements/HeaderTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faPrint,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";
import Workbook from "react-xlsx-workbook";
import ViewManagingInvoice from "../components/InterfaceElements/ViewManagingInvoice";
import PrintManageInvoice from "../components/InterfaceElements/PrintManageInvoice";
import { Label } from "semantic-ui-react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import adbs, { bs2ad } from "ad-bs-converter";
import dateFormat from "dateformat";
import "nepali-datepicker-reactjs/dist/index.css";
import NepaliDatepicker from 'nepali-datepicker-and-dateinput';

const styles = (theme) => ({
  tabMargin: {
    paddingTop: "20px",
    height: "auto",
    [theme.breakpoints.down("md")]: {
      marginTop: "40px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "5px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "5px",
    },
  },
  root: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "150px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
    },
  },
});

class ManagingInvoices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        {
          headerName: "View",
          cellRendererFramework: ViewManagingInvoice,
        },
        {
          headerName: "Fiscal Year",
          field: "fiscalYear",
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Invoice Number",
          field: "billNo",
          resizable: true,
        },
        {
          headerName: "Customer Name",
          field: "customerName",
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Customer PAN",
          field: "customerPan",
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Bill Date",
          field: "invoicedate",
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Amount",
          field: "amount",
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Discount",
          field: "discount",
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Taxable Amount",
          field: "taxableAmount",
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Tax Amount",
          field: "taxAmount",
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Total Amount",
          field: "totalamount",
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Sync with IRD",
          field: "syncWithIrd",
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Is Bill Printable",
          field: "billPrinted",
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Is Bill Active",
          field: "billActive",
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Printed Time",
          field: "printedTime",
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Entered By",
          field: "enteredBy",
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Printed By",
          field: "printedBy",
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Is Real-time",
          field: "isRealTime",
          resizable: true,
          sortable: true,
          filter: true,
        },
        {
          headerName: "Print",
          cellRendererFramework: PrintManageInvoice,
        },
      ],
      rowData: [],
      origData: [],
      filterShow: false,
      open: false,
      sliderVal: [20, 47],
      fromDate: "11/11/2019",
      toDate: "11/11/2019",
      checkStatus: [],
      quantityMax: 0,
      fyList: [],
      distinctFyList: [],
      distinctPeriodList: [],
      currentPeriod: "",
      periodList: [],
      currentFy: "",
      period: "",
      filterFields: {
        dateFrom: "",
        dateTo: "",
        periodFrom: "",
        periodTo: "",
      },
      filterTerm: "",
      panelExpanded: true,
      showLoading: false,
      showDetails: false,
      overlayLoadingTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
      overlayNoRowsTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
    };
  }

  handleCustomerSelect = (event, data) => {
    const { value } = data;
    var dataList = this.state.origData;
    var newDataList = [];
    dataList.forEach((element) => {
      if (element.customerName === value) {
        newDataList.push(element);
      }
    });

    this.setState({ rowData: newDataList });
  };

  handleFromInvoice = (e) => {
    e.stopPropagation();

    if (e.target.value > this.state.quantityMax) {
      e.target.value = this.state.quantityMax;
    }

    if (e.target.value < 1) {
      e.target.value = 1;
    }

    var dataList = this.state.origData;

    var newDataList = [];

    dataList.forEach((element) => {
      if (element.billNo >= e.target.value) {
        newDataList.push(element);
      }
    });

    this.setState({ rowData: newDataList });
  };

  handleToInvoice = (e) => {
    e.stopPropagation();

    if (e.target.value > this.state.quantityMax) {
      e.target.value = this.state.quantityMax;
    }

    if (e.target.value < 1) {
      e.target.value = 1;
    }

    var dataList = this.state.origData;

    var newDataList = [];

    dataList.forEach((element) => {
      if (element.billNo <= e.target.value) {
        newDataList.push(element);
      }
    });

    this.setState({ rowData: newDataList });
  };

  getAllFiscalYear() {
    Axios.get("/fy/findTillCurrentPeriod")
      .then((res) => {
        var data = [];
        var nonDistinctPeriodList = [];
        var nonDistinctFyList = [];
        var periodData = [];
        res.data.forEach((element) => {
          var item = {
            status: element.currentStatus,
            fiscalYear: element.fiscalYear,
            name: element.name,
          };
          var periodItem = {
            status: element.currentStatus,
            period: element.name,
          };
          data.push(item);
          nonDistinctFyList.push(item.fiscalYear);
          periodData.push(periodItem);
          nonDistinctPeriodList.push(periodItem.period);
        });

        this.setState((prevState) => ({
          ...prevState,
          fyList: data,
          distinctFyList: [...new Set(nonDistinctFyList)],
          periodList: periodData,
          distinctPeriodList: [...new Set(nonDistinctPeriodList)],
        }));
        this.getCurrentFiscalYear();
        this.getCurrentPeriod();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getCurrentPeriod() {
    this.state.periodList.forEach((element) => {
      if (element.status === true) {
        this.setState((prevState) => ({
          ...prevState,
          currentPeriod: element.name,
          fiscalYear: element.fiscalYear,
        }));
      }
    });
  }

  getCurrentFiscalYear() {
    this.state.fyList.forEach((element) => {
      if (element.status === true) {
        this.setState((prevState) => ({
          ...prevState,
          currentFy: element.fiscalYear,
        }));
      }
    });
    this.state.periodList.forEach((element) => {
      if (element.status === true) {
        this.setState((prevState) => ({
          ...prevState,
          period: element.period,
        }));
      }
    });
    //this.getInvoices();
  }

  getInvoices(obj) {
    var obj = obj;

    Axios.post("/pos/sales/managingSalesInvoices", obj)
      .then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          rowData: res.data,
        }));
        this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>",
        });
        try {
          this.gridApi.hideOverlay();
          if (res.data.length === 0) this.gridApi.showNoRowsOverlay();
        } catch (error) {
          console.log(error);
        }
      })
      .catch((err) => {
        console.log(err);

        this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>",
        });
        try {
          this.gridApi.hideOverlay();
          this.gridApi.showNoRowsOverlay();
        } catch (error) {
          console.log(error);
        }
      });
  }

  handleFiscalYearChange = async (e) => {
    e.stopPropagation();
    await this.setState((prevState) => ({
      ...prevState,
      currentFy: e.target.value,
    }));
    //this.getInvoices();
  };

  handlePeriodChange = async (e) => {
    e.stopPropagation();
    await this.setState((prevState) => ({
      ...prevState,
      period: e.target.value,
    }));
    //this.getInvoices();
  };

  componentDidMount() {
    this.getAllFiscalYear();
    
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    var todayDate = dateFormat(date, "yyyy-mm-dd");
    console.log(todayDate)

    let transactionDateFrom = (todayDate.split("-"));
    let transactionDateTo = (todayDate.split("-"));
    
    let tdf = (
      transactionDateFrom[0] +
        "/" +
        transactionDateFrom[1] +
        "/" +
        transactionDateFrom[2]
    );
    let tdt = (
      transactionDateTo[0] +
        "/" +
        transactionDateTo[1] +
        "/" +
        transactionDateTo[2]
    );

    let df= adbs.ad2bs(tdf)
    let dt =adbs.ad2bs(tdt)

    let finalDateFromYear = df.en.year;
    let finalDateFromMonth = df.en.month;
    let finalDateFromDay = df.en.day;
    if(df.en.month < 10){
      finalDateFromMonth = "0" + df.en.month;
    }    
    if(df.en.day < 10){
      finalDateFromDay = "0" + df.en.day;
    }
    
    let finalDateFrom = finalDateFromYear + "-" + finalDateFromMonth + "-" + finalDateFromDay;

    this.setState((prevState) => ({
      ...prevState,
      filterFields: {
        ...prevState.filterFields,
        dateFrom: finalDateFrom,
        dateTo: finalDateFrom,
      },
    }));
  }

  handleClick = () => {
    var rowAllData = [];

    this.state.rowData.forEach((element) => {
      if (element.status === "Inactive") {
        rowAllData.push(element);
      }
    });
    this.setState({ rowData: rowAllData });
  };

  getCustomerName = () => {
    Axios.get("pos/customer/getAllCustomersList")
      .then((res) => {
        console.log(res);

        var rows = [];

        res.data.forEach((element) => {
          const item = {
            key: element.id,
            value: element.customerName,
            text: element.customerName,
          };
          rows.push(item);
        });

        this.setState({ customerRowRefs: rows });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentWillMount() {
    window.removeEventListener("resize", this.daListener);
  }

  firstDataRendered = (params) => {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    window.addEventListener("resize", this.daListener);
  };

  daListener = () => {
    if (!this.gridApi) return;
    setTimeout(() => {
      this.gridApi.sizeColumnsToFit();
    }, 100);
  };

  handleSliderChange = (event, newValue) => {
    this.setState({ sliderVal: newValue });
  };

  handleFromDate = (name, dateInMilli, bsDate, adDate) => {

    this.setState((prevState) => ({
      ...prevState,
      filterFields: {
        ...prevState.filterFields,
        dateFrom: adDate,
      },
    }));
  };
  handleToDate = (name, dateInMilli, bsDate, adDate) => {

    this.setState((prevState) => ({
      ...prevState,
      filterFields: {
        ...prevState.filterFields,
        dateTo: adDate,
      },
    }));
  };

  handleDateRangeApply = () => {
    this.setState({
      panelExpanded: false,
    });
      
    this.getInvoices({
      dateFrom: this.state.filterFields.dateFrom,
      dateTo: this.state.filterFields.dateTo,
    });
  };

  handleCheckbox = (checkbox) => {
    var val = this.state.checkStatus[checkbox];

    this.setState((prevState) => ({
      ...prevState,
      checkStatus: {
        ...prevState.checkStatus,
        [checkbox]: !val,
      },
    }));

    var dataList = this.state.origData;

    var newDataList = [];

    dataList.forEach((element) => {
      if (
        element.paymentMode === checkbox &&
        !this.state.checkStatus[checkbox]
      ) {
        newDataList.push(element);
      }
    });

    this.setState({ rowData: newDataList });
  };

  setOpen = (status) => {
    this.setState({ open: status });
  };

  handlePeriodFromChange = async (e) => {
    e.stopPropagation();
    var period = e.target.value;
    await this.setState((prevState) => ({
      ...prevState,
      filterFields: {
        ...prevState.filterFields,
        periodFrom: period,
      },
    }));
  };

  handlePeriodToChange = async (e) => {
    e.stopPropagation();
    var period = e.target.value;
    await this.setState((prevState) => ({
      ...prevState,
      filterFields: {
        ...prevState.filterFields,
        periodTo: period,
      },
    }));
  };

  handleFiscalYearApply = () => {
    this.setState({
      panelExpanded: false,
    });

    this.getInvoices({
      fiscalYear: this.state.currentFy,
      periodFrom: this.state.filterFields.periodFrom,
      periodTo: this.state.filterFields.periodTo,
    });
    // this.getExcelData();
  };

  handlePanelChange = (_e, isExpanded) => {
    this.setState({
      panelExpanded: isExpanded,
    });
  };

  onFilterTermChange = (e) => {
    let value = e.target.value;

    this.setState((prevState) => ({
      ...prevState,
      filterTerm: value,
    }));
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  render(params) {
    const { classes } = this.props;

    return (
      <div
        style={{
          backgroundColor: "#EAEDEF",
          marginTop: "0px",
        }}
      >
        <HeaderTitle title="Managing Invoices" />

        <Grid container justify="center" className={classes.root}>
          <Grid item xs={11}>
            <Card className={classes.tabMargin}>
              <CardContent>
                <Grid container spacing={1} justify="space-between">
                  <Grid item xs={24} sm={6}>
                    <ExpansionPanel
                      expanded={this.state.panelExpanded}
                      onChange={this.handlePanelChange}
                      style={{ maxWidth: "550px" }}
                    >
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>
                          Report By
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Grid container>
                          <RadioGroup
                            row
                            value={this.state.filterTerm}
                            onChange={this.onFilterTermChange}
                          >
                            <FormControlLabel
                              value="fiscalYear"
                              control={<Radio color="primary" />}
                              label="Fiscal Year and Period"
                            />
                            <FormControlLabel
                              value="date"
                              control={<Radio color="primary" />}
                              label="Date Range"
                            />
                          </RadioGroup>

                          {this.state.filterTerm === "fiscalYear" ? (
                            <>
                              <Grid
                                container
                                justify="flex-start"
                                spacing={2}
                                style={{ marginTop: "20px" }}
                              >
                                <Grid item>
                                  <TextField
                                    select
                                    style={{
                                      width: "15vw",
                                      minWidth: "120px",
                                    }}
                                    label="Fiscal Year"
                                    name="fiscalYear"
                                    onChange={this.handleFiscalYearChange}
                                    value={this.state.currentFy}
                                  >
                                    {this.state.distinctFyList.map((item) => (
                                      <MenuItem value={item}>{item}</MenuItem>
                                    ))}
                                  </TextField>
                                </Grid>
                              </Grid>

                              <Grid
                                container
                                justify="flex-start"
                                spacing={2}
                                style={{ marginTop: "20px" }}
                              >
                                <Grid item>
                                  <TextField
                                    select
                                    style={{
                                      width: "15vw",
                                      minWidth: "120px",
                                    }}
                                    label="Period From"
                                    name="periodFrom"
                                    onChange={this.handlePeriodFromChange}
                                    value={this.state.filterFields.periodFrom}
                                  >
                                    {this.state.distinctPeriodList.map(
                                      (item) => (
                                        <MenuItem value={item}>{item}</MenuItem>
                                      )
                                    )}
                                  </TextField>
                                </Grid>
                                <Grid item>
                                  <TextField
                                    select
                                    style={{
                                      width: "15vw",
                                      minWidth: "120px",
                                    }}
                                    label="Period To"
                                    name="periodTo"
                                    value={this.state.filterFields.periodTo}
                                    onChange={this.handlePeriodToChange}
                                  >
                                    {this.state.distinctPeriodList.map(
                                      (item) => (
                                        <MenuItem value={item}>{item}</MenuItem>
                                      )
                                    )}
                                  </TextField>
                                </Grid>
                              </Grid>

                              <Grid container style={{ marginTop: "27px" }}>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={this.handleFiscalYearApply}
                                >
                                  Apply
                                </Button>
                              </Grid>
                            </>
                          ) : null}

                          {this.state.filterTerm === "date" ? (
                            <>
                              <Grid container justify="flex-start" spacing={2}>
                              <Grid item style={{marginTop: "8px"}}>
                                  <NepaliDatepicker
                                    id="nepali-date"
                                    onChange={this.handleFromDate}
                                    label="Date From"
                                    value={this.state.filterFields.dateFrom}
                                    locale= "en"
                                    showDefaultDate="true"
                                    style={{height: "5px", width: "5px"}}
                                  />
                                  </Grid>
                                  <Grid item style={{marginTop: "8px"}}> 
                                  <NepaliDatepicker
                                    id="nepali-date"
                                    onChange={this.handleToDate}
                                    label="Date To"
                                    value={this.state.filterFields.dateTo}
                                    locale= "en"
                                    showDefaultDate="true"
                                    style={{height: "5px", width: "5px"}}
                                  />
                                </Grid>
                              </Grid>
                              <Grid container style={{ marginTop: "27px" }}>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={this.handleDateRangeApply}
                                >
                                  Apply
                                </Button>
                              </Grid>
                            </>
                          ) : null}
                        </Grid>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </Grid>

                  <Grid item xs={12} sm={6} style={{ marginTop: "15px" }}>
                    <Grid container spacing={2} justify="flex-end">
                      <Grid item style={{ marginTop: "10px" }}>
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{
                            minWidth: "100px",
                            width: "6vw",
                            margin: "auto",
                            height: "40px",
                            fontSize: "16px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faFilePdf}
                            color="#3f51b5"
                            size="30px"
                            style={{
                              marginRight: "10px",
                            }}
                          ></FontAwesomeIcon>
                          Pdf
                        </Button>
                      </Grid>

                      <Grid item style={{ marginTop: "10px" }}>
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{
                            minWidth: "100px",
                            width: "6vw",
                            margin: "auto",
                            height: "40px",
                            fontSize: "16px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPrint}
                            color="#3f51b5"
                            size="30px"
                            style={{
                              marginRight: "10px",
                            }}
                          ></FontAwesomeIcon>
                          Print
                        </Button>
                      </Grid>
                      <Grid item style={{ marginTop: "10px" }}>
                        <Workbook
                          filename="ManagingInvoice.xlsx"
                          element={
                            <Button
                              variant="outlined"
                              color="primary"
                              style={{
                                minWidth: "100px",
                                width: "6vw",
                                margin: "auto",
                                height: "40px",
                                fontSize: "16px",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faFileExcel}
                                color="#3f51b5"
                                size="30px"
                                style={{
                                  marginRight: "10px",
                                }}
                              ></FontAwesomeIcon>
                              Excel
                            </Button>
                          }
                        >
                          <Workbook.Sheet
                            data={this.state.rowData}
                            name="Sheet A"
                          >
                            {this.state.columnDefs.map((item) => (
                              <Workbook.Column
                                label={item.headerName}
                                value={item.field}
                              />
                            ))}
                          </Workbook.Sheet>
                        </Workbook>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {this.state.showDetails ? (
                  <>
                    {this.state.filterTerm === "fiscalYear" ? (
                      <Grid
                        container
                        style={{
                          margin: "20px",
                          marginLeft: "5px",
                          maxWidth: "700px",
                        }}
                      >
                        <Grid item xs={4}>
                          <Grid container>
                            <Grid>
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 600,
                                  marginRight: "10px",
                                  color: "#108DCD",
                                }}
                              >
                                Fiscal Year:
                              </Typography>
                            </Grid>
                            <Grid>
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 400,
                                  color: "#108DCD",
                                }}
                              >
                                {this.state.currentFy}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={4}>
                          <Grid container>
                            <Grid>
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 600,
                                  marginRight: "10px",
                                  color: "#108DCD",
                                }}
                              >
                                Period From:
                              </Typography>
                            </Grid>
                            <Grid>
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 400,
                                  color: "#108DCD",
                                }}
                              >
                                {this.state.filterFields.periodFrom}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid xs={4}>
                          <Grid container>
                            <Grid>
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 600,
                                  marginRight: "10px",
                                  color: "#108DCD",
                                }}
                              >
                                Period To:
                              </Typography>
                            </Grid>
                            <Grid>
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 400,
                                  color: "#108DCD",
                                }}
                              >
                                {this.state.filterFields.periodTo}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <>
                        <Grid
                          container
                          style={{
                            margin: "20px",
                            marginLeft: "5px",
                            maxWidth: "500px",
                          }}
                        >
                          <Grid item xs={6}>
                            <Grid container>
                              <Grid>
                                <Typography
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: 600,
                                    marginRight: "10px",
                                    color: "#108DCD",
                                  }}
                                >
                                  Date From:
                                </Typography>
                              </Grid>
                              <Grid>
                                <Typography
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: 400,
                                    color: "#108DCD",
                                  }}
                                >
                                  {this.state.filterFields.dateFrom}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid container>
                              <Grid>
                                <Typography
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: 600,
                                    color: "#108DCD",
                                    marginRight: "10px",
                                  }}
                                >
                                  Date To:
                                </Typography>
                              </Grid>
                              <Grid>
                                <Typography
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: 400,
                                    color: "#108DCD",
                                  }}
                                >
                                  {this.state.filterFields.dateTo}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </>
                ) : null}

                <Grid container justify="center">
                  <div
                    className="ag-theme-balham"
                    style={{
                      height: "50vh",
                      width: "100%",
                      paddingTop: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <AgGridReact
                      columnDefs={this.state.columnDefs}
                      rowData={this.state.rowData}
                      onGridReady={this.onGridReady}
                      onFirstDataRendered={this.firstDataRendered}
                      overlayLoadingTemplate={this.state.overlayLoadingTemplate}
                      overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                    ></AgGridReact>
                  </div>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(ManagingInvoices);
