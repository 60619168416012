import Dashboard from "../views/Dashboard";
import UserMaster from "../views/UserMaster";
import RoleMaster from "../views/RoleMaster";
import InventoryMaster from "../views/InventoryMaster";
import AccountMaster from "../views/AccountMaster";
import SupplierMaster from "../views/SupplierMaster";
import CustomerMaster from "../views/CustomerMaster";
import MemberMaster from "../views/MemberMaster";
import ItemMaster from "../views/ItemMaster";
import Sales from "../views/Sales";
import Purchase from "../views/Purchase";
import SalesReturn from "../views/SalesReturn";
import PurchaseReturn from "../views/PurchaseReturn";
import Replacement from "../views/Replacement";
import PurchaseOrder from "../views/PurchaseOrder";
import SalesOrder from "../views/SalesOrder";
import Quotation from "../views/Quotation";
import InventoryDetails from "../components/InventoryMaster/InventoryDetails";
import InventoryItemDetails from "../components/InventoryItemDetails";
import ChartOfAccounts from "../views/ChartOfAccounts";
import ManagingInvoices from "../views/ManagingInvoices";
import GoodReceivedNote from "../views/GoodsReceivedNote";
import GoodIssuedNote from "../views/GoodsIssuedNote";
import VoucherNumberingScheme from "../views/VoucherNumberingScheme";
import PaymentVoucher from "../views/PaymentVoucher";
import JournalVoucher from "../views/JournalVoucher";
import ReceivedVoucher from "../views/ReceivedVoucher";
import CreditNote from "../views/CreditNote";
import DebitNote from "../views/DebitNote";
import ContraVoucher from "../views/ContraVoucher";
import SalesVoucher from "../views/SalesVoucher";
import PurchaseVoucher from "../views/PurchaseVoucher";
import ManufacturerMaster from "../views/ManufacturerMaster";
import DepreciationVoucher from "../views/DepreciationVoucher";
import TrialBalance from "../views/TrialBalance";
import AssetsRegister from "../views/AssetsRegister";
import BalanceSheet from "../views/BalanceSheet";
import FundAccountability from "../views/FundAccountability";
import ProfitAndLossAccount from "../views/ProfitAndLossAccount";
import BooksOfAccount from "../views/BooksOfAccount";
import PeriodMaster from "../views/PeriodMaster";
import DepartmentMaster from "../views/DepartmentMaster";
import BudgetCodeMaster from "../views/BudgetCodeMaster";
import AuthorizationMaster from "../views/AuthorizationMaster";
import SchemeMaster from "../views/SchemeMaster";
import InventoryCount from "../views/InventoryCount";
import ClosingStockValue from "../components/InventoryMaster/ClosingStockValue";
import CompanyInformation from "../components/CompanyMaster/CompanyInformation";
import BatchInputVoucher from "../views/BatchInputVoucher";
import InventoryCatalougeMaster from "../views/InventoryCatalogueMaster";
import StockTransactionDetails from "../views/StockTransactionDetails";
import OpeningInventory from "../views/OpeningInventory";
import CostTrail from "../views/CostTrail";
import InventoryCF from "../views/InventoryCF";
import TransactionDetails from "../views/TransactionDetails";
import BookSales from "../views/BookSales";

const defaultRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    parent: "Dashboard",
    auth: "all",
    component: Dashboard,
  },
  {
    path: "/company/master",
    name: "Company Master",
    parent: "Administration",
    auth: "all",
    component: CompanyInformation,
  },
  {
    path: "/user/master",
    name: "User Administration",
    parent: "Administration",
    auth: "all",
    component: UserMaster,
  },
  {
    path: "/role/master",
    name: "Role Administration",
    parent: "Administration",
    auth: "all",
    component: RoleMaster,
  },
  {
    path: "/authorization/master",
    name: "Authorization Master",
    parent: "Administration",
    auth: "all",
    component: AuthorizationMaster,
  },
  {
    path: "/Document/numberingScheme",
    name: "Document Numbering Scheme",
    parent: "Administration",
    auth: "all",
    component: VoucherNumberingScheme,
  },
  {
    path: "/budgetCodeMaster",
    name: "Budget Code Master",
    parent: "Administration",
    auth: "all",
    component: BudgetCodeMaster,
  },
  {
    path: "/department",
    name: "Department Master",
    parent: "Administration",
    auth: "all",
    component: DepartmentMaster,
  },
  {
    path: "/periodMaster",
    name: "Period Master",
    parent: "Administration",
    auth: "all",
    component: PeriodMaster,
  },
  {
    path: "/inventoryCatalouge/master",
    name: "Catalogue Master",
    parent: "Master",
    auth: "all",
    component: InventoryCatalougeMaster,
  },
  {
    path: "/inventory/master",
    name: "Inventory Master",
    parent: "Master",
    auth: "all",
    component: InventoryMaster,
  },
  {
    path: "/account/master",
    name: "Account Master",
    parent: "Master",
    auth: "all",
    component: AccountMaster,
  },
  {
    path: "/manufacturer/master",
    name: "Manufacturer Master",
    parent: "Master",
    auth: "all",
    component: ManufacturerMaster,
  },
  {
    path: "/supplier/master",
    name: "Supplier Master",
    parent: "Master",
    auth: "all",
    component: SupplierMaster,
  },
  {
    path: "/customer/master",
    name: "Customer Master",
    parent: "Master",
    auth: "all",
    component: CustomerMaster,
  },
  {
    path: "/member/master",
    name: "Member Master",
    parent: "Master",
    auth: "all",
    component: MemberMaster,
  },
  {
    path: "/item/master",
    name: "Item Master",
    parent: "Master",
    auth: "all",
    component: ItemMaster,
  },
  {
    path: "/scheme/master",
    name: "Scheme Master",
    parent: "Master",
    auth: "all",
    component: SchemeMaster,
  },
  {
    path: "/voucher/master",
    name: "Voucher Master",
    parent: "Master",
    auth: "all",
    component: Dashboard,
  },
  {
    path: "/openingBalances",
    name: "Opening Balance",
    parent: "Master",
    auth: "all",
    component: Dashboard,
  },
  {
    path: "/transaction/sales",
    name: "Sales",
    parent: "Transaction",
    auth: "all",
    component: Sales,
  },
  {
    path: "/transaction/purchase",
    name: "Purchase",
    parent: "Transaction",
    auth: "all",
    component: Purchase,
  },
  {
    path: "/transaction/salesReturn",
    name: "Sales Return",
    parent: "Transaction",
    auth: "all",
    component: SalesReturn,
  },
  {
    path: "/transaction/purchaseReturn",
    name: "Purchase Return",
    parent: "Transaction",
    auth: "all",
    component: PurchaseReturn,
  },
  {
    path: "/transaction/replacement",
    name: "Replacement",
    parent: "Transaction",
    auth: "all",
    component: Replacement,
  },
  {
    path: "/transaction/salesOrder",
    name: "Sales Order",
    parent: "Transaction",
    auth: "all",
    component: SalesOrder,
  },
  {
    path: "/transaction/quotation",
    name: "Quotation",
    parent: "Transaction",
    auth: "all",
    component: Quotation,
  },
  {
    path: "/scm/purchaseRequest",
    name: "Purchase Request",
    parent: "SCM",
    auth: "all",
    component: Dashboard,
  },
  {
    path: "/scm/sourcing",
    name: "Sourcing",
    parent: "SCM",
    auth: "all",
    component: Dashboard,
  },
  {
    path: "/scm/purchaseOrder",
    name: "Purchase Order",
    parent: "SCM",
    auth: "all",
    component: PurchaseOrder,
  },
  {
    path: "/scm/grn",
    name: "Good Received Note",
    parent: "SCM",
    auth: "all",
    component: GoodReceivedNote,
  },
  {
    path: "/scm/gin",
    name: "Good Issued Note",
    parent: "SCM",
    auth: "all",
    component: GoodIssuedNote,
  },

  {
    path: "/inventory/open",
    name: "Inventory Transaction Details",
    parent: "Inventory",
    auth: "all",
    component: InventoryDetails,
  },
  {
    path: "/inventory/item/details",
    name: "Stock Card",
    parent: "Inventory",
    auth: "all",
    component: InventoryItemDetails,
  },
  {
    path: "/inventory/inventoryCount",
    name: "Inventory Physical Count",
    parent: "Inventory",
    auth: "all",
    component: InventoryCount,
  },
  {
    path: "/inventory/costTrail",
    name: "Cost Trail",
    parent: "Inventory",
    auth: "all",
    component: CostTrail,
  },
  {
    path: "/inventory/closingStockValue",
    name: "Closing Stock Value",
    parent: "Inventory",
    auth: "all",
    component: ClosingStockValue,
  },
  {
    path: "/inventory/stockTransactionDetails",
    name: "Stock Transaction Details",
    parent: "Inventory",
    auth: "all",
    component: StockTransactionDetails,
  },
  {
    path: "/inventory/openingInventory",
    name: "Opening Inventory",
    parent: "Inventory",
    auth: "all",
    component: OpeningInventory,
  },
  {
    path: "/inventory/InventoryCF",
    name: "Inventory CF",
    parent: "Inventory",
    auth: "all",
    component: InventoryCF,
  },
  {
    path: "/accounts/chartOfAccounts",
    name: "Chart Of Accounts",
    parent: "Accounts",
    auth: "all",
    component: ChartOfAccounts,
  },
  {
    path: "/received/voucher",
    name: "Received Voucher",
    parent: "Accounts",
    auth: "all",
    component: ReceivedVoucher,
  },
  {
    path: "/payment/voucher",
    name: "Payment Voucher",
    parent: "Accounts",
    auth: "all",
    component: PaymentVoucher,
  },
  {
    path: "/journal/voucher",
    name: "Journal Voucher",
    parent: "Accounts",
    auth: "all",
    component: JournalVoucher,
  },
  {
    path: "/purchase/voucher",
    name: "Purchase Voucher",
    parent: "Accounts",
    auth: "all",
    component: PurchaseVoucher,
  },
  {
    path: "/sales/voucher",
    name: "Sales Voucher",
    parent: "Accounts",
    auth: "all",
    component: SalesVoucher,
  },
  {
    path: "/contra/voucher",
    name: "Contra Voucher",
    parent: "Accounts",
    auth: "all",
    component: ContraVoucher,
  },
  {
    path: "/bank/reconciliation",
    name: "Bank Reconciliation",
    parent: "Accounts",
    auth: "all",
    component: Dashboard,
  },
  {
    path: "/debitNote",
    name: "Debit Note Voucher",
    parent: "Accounts",
    auth: "all",
    component: DebitNote,
  },
  {
    path: "/creditNote",
    name: "Credit Note Voucher",
    parent: "Accounts",
    auth: "all",
    component: CreditNote,
  },
  {
    path: "/depreciationVoucher",
    name: "Depreciation Voucher",
    parent: "Accounts",
    auth: "all",
    component: DepreciationVoucher,
  },
  {
    path: "/batchInputVoucher",
    name: "Batch Input Voucher",
    parent: "Accounts",
    auth: "all",
    component: BatchInputVoucher,
  },
  {
    path: "/reports/transactionDetails",
    name: "Transaction Details",
    parent: "Reports",
    auth: "all",
    component: TransactionDetails,
  },
  {
    path: "/reports/managingInvoices",
    name: "Managing Invoices",
    parent: "Reports",
    auth: "all",
    component: ManagingInvoices,
  },
  {
    path: "/booksOfAccount",
    name: "Books of Account",
    parent: "Reports",
    auth: "all",
    component: BooksOfAccount,
  },
  {
    path: "/assets/register",
    name: "Assets Register",
    parent: "Reports",
    auth: "all",
    component: AssetsRegister,
  },
  {
    path: "/trialBalance",
    name: "Trial Balance",
    parent: "Reports",
    auth: "all",
    component: TrialBalance,
  },
  {
    path: "/profitAndLossAccount",
    name: "Profit and Loss Account",
    parent: "Reports",
    auth: "all",
    component: ProfitAndLossAccount,
  },
  {
    path: "/balanceSheet",
    name: "Balance Sheet",
    parent: "Reports",
    auth: "all",
    component: BalanceSheet,
  },
  {
    path: "/fundAccountability",
    name: "Fund Accountability",
    parent: "Reports",
    auth: "all",
    component: FundAccountability,
  },
  {
    path: "/book-sales",
    name: "Sales Book",
    parent: "Reports",
    auth: "all",
    component: BookSales,
  },
  {
    path: "/help",
    name: "Help",
    parent: "Help",
    auth: "all",
    component: Dashboard,
  },
];

export default defaultRoutes;
