import React from "react";
import SalesInvoice3mm from "../components/PrintableComponents/SalesInvoice3mm";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  TextField,
  Button
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

function SalesPrint() {
  const handlePrint = () => {
    document.execCommand("print");
  };

  return (
    <>
      <div className="hideOnPrint">
        <Card>
          <CardContent style={{ padding: "50px" }}>
            <Grid container>
              <Typography
                variant="h6"
                // onClick={this.props.animateOut}
                style={{ cursor: "pointer" }}
              >
                <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon> Sales{" "}
              </Typography>
            </Grid>
            <Grid container justify="center">
              <Typography variant="h6" component="h2">
                Confirm Sales
              </Typography>
            </Grid>

            <Grid container justify="center">
              <Grid item>
                <table style={{ marginTop: "25px", marginLeft: "10px" }}>
                  <tbody>
                    <tr>
                      <td>Payment Mode</td>

                      <td style={{ paddingLeft: "100px" }}>
                        <TextField
                          id="filled-dense-hidden-label"
                          margin="dense"
                          //   value={paymentMethod}
                          hiddenLabel
                          variant="filled"
                          style={{ marginLeft: "20px" }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Bill Amount</td>

                      <td style={{ paddingLeft: "100px" }}>
                        <TextField
                          id="filled-dense-hidden-label"
                          margin="dense"
                          hiddenLabel
                          type="number"
                          //   value={billAmount}
                          variant="filled"
                          style={{ marginLeft: "20px" }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Tender Amount</td>

                      <td style={{ paddingLeft: "100px" }}>
                        <TextField
                          id="filled-dense-hidden-label"
                          margin="dense"
                          hiddenLabel
                          type="number"
                          //   value={tenderAmount}
                          min={0}
                          variant="filled"
                          style={{ marginLeft: "20px" }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Change</td>
                      <td style={{ paddingLeft: "100px" }}>
                        <TextField
                          id="filled-dense-hidden-label"
                          margin="dense"
                          hiddenLabel
                          type="number"
                          //   value={change}
                          variant="filled"
                          style={{ marginLeft: "20px" }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
            </Grid>
            <Grid container justify="center">
              <Button
                variant="outlined"
                onClick={handlePrint}
                size="large"
                color="primary"
                style={{
                  margin: "10px",
                  marginTop: "50px",
                  marginRight: "10px",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                  paddingRight: "55px",
                  paddingLeft: "55px"
                }}
              >
                Save & Print
              </Button>
            </Grid>
          </CardContent>
        </Card>
      </div>
      <SalesInvoice3mm handlePrint={handlePrint} />
    </>
  );
}

export default SalesPrint;
