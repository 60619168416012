import React, { useState } from "react";
import HeaderTitle from "../components/InterfaceElements/HeaderTitle";
import { Grid, Tabs, Tab, withStyles } from "@material-ui/core";
import AddScheme from "../components/SchemeMaster/AddScheme";
import ManageScheme from "../components/SchemeMaster/ManageScheme";

const styles = theme => ({
  tabMargin: {
    marginBottom: "7px",
    [theme.breakpoints.down("md")]: {
      marginTop: "37px"
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "0px"
    }
  }
});

function SchemeMaster(props) {
  const { classes } = props;

  const [value, setValue] = useState(0);

  const handleChange = (event, value) => {
    setValue(value);
  };

  return (
    <div>
      <HeaderTitle title="Role Master" />

      <Grid container>
        <Grid container justify="center" className={classes.tabMargin}>
          <Tabs
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            indicatorColor="primary"
          >
            <Tab style={{ fontSize: "12px" }} label="Manage Scheme" />
            <Tab style={{ fontSize: "12px" }} label="Add Scheme" />
          </Tabs>
        </Grid>
        <Grid container justify="center">
          <Grid item>
            <ManageScheme value={value} index={0} />
            <AddScheme value={value} index={1} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(SchemeMaster);
