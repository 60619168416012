import React, { Component } from "react";
import {
    Modal,
    Backdrop,
    Fade,
    Card,
    CardContent,
    Grid,
    TextField,
    withStyles,
    Button,
    IconButton,
} from "@material-ui/core";
import Axios from "axios";
import ValidatedTextField from "../../InterfaceElements/ValidatedTextField";
import Close from "@material-ui/icons/Close";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import UserContext from "../../../utils/UserContext";

const styles = (theme) => ({
    cardHeight: {
        overflowY: "auto",
        [theme.breakpoints.up("xs")]: {
            width: "90%",
        },
        [theme.breakpoints.up("sm")]: {
            width: "500px",
        },
    },
});
class EditWarehouse extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            errorStatus: {},
            rowData: [],
            data: {
                name: "",
                code: "",
                remarks: "",
                address: "",
            },
        };
    }

    componentDidMount() {
        this.setState({
            data: {
                name: this.props.node.data.name,
                code: this.props.node.data.code,
                remarks: this.props.node.data.remarks,
                address: this.props.node.data.address,
            },
        });
    }

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleValueChange = (e) => {
        e.stopPropagation();
        var targetName = e.target.name;
        var value = e.target.value;
        this.setState((prevState) => ({
            ...prevState,
            data: {
                ...prevState.data,
                [targetName]: value,
            },
        }));
    };

    validateForm() {
        var toCheck = ["name", "address"];

        var newErrStatus = this.state.errorStatus;
        var status = true;

        toCheck.forEach((element) => {
            let val = this.state.data[element];

            console.log(val);

            if (val === "" || val === undefined) {
                newErrStatus[element] = true;
                status = false;
            } else {
                newErrStatus[element] = false;
            }
        });

        console.log(newErrStatus);

        this.setState((prevState) => ({
            ...prevState,
            errorStatus: {
                ...prevState.errorStatus,
                newErrStatus,
            },
        }));

        return status;
    }

    handleUpdate = (id) => {
        let obj = {
            name: this.state.data.name,
            code: this.state.data.code,
            remarks: this.state.data.remarks,
            address: this.state.data.address,
        };
        const token = this.context;

        let status = this.validateForm();

        if (status) {
            Axios.put("pos/warehouse/updateWarehouse/" + id, obj)
                .then((res) => {
                    console.log(res);

                    /*   var snackbarProps = {
                          open: true,
                          variant: "success",
                          message: res.data,
                          autoHideDuration: 2000,
                      };
  
                      token.setSnackbarProps({ snackbarProps: snackbarProps }); */
                    this.props.updateData();
                    this.handleClose();
                })
                .catch((err) => {
                    console.log(err);
                    /* var snackbarProps = {
                        open: true,
                        variant: "error",
                        message: err.message,
                        autoHideDuration: 2000,
                    };

                    token.setSnackbarProps({ snackbarProps: snackbarProps }); */
                });
        }
    };

    render() {
        const { classes } = this.props;

        const modal = {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        };

        const textFieldStyle = {
            width: "100%",
        };
        return (
            <div>
                <span>
                    <Button onClick={() => this.handleOpen(this.props.node.data.id)}>
                        <FontAwesomeIcon
                            icon={faEdit}
                            color="#43a047"
                            size="1.5x"
                            style={{ cursor: "pointer" }}
                        >
                            {" "}
                        </FontAwesomeIcon>
                    </Button>
                </span>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={modal}
                    open={this.state.open}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.open}>
                        <Card className={classes.cardHeight}>
                            <CardContent style={{ padding: "0px" }}>
                                <Grid container justify="flex-end">
                                    <IconButton onClick={this.handleClose}>
                                        <Close />
                                    </IconButton>
                                </Grid>
                                <Grid container style={{ padding: "20px" }}>
                                    <Grid container justify="center" spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <ValidatedTextField
                                                label="Code"
                                                name="code"
                                                error={this.state.errorStatus.code}
                                                value={this.state.data.code}
                                                onChange={this.handleValueChange}
                                                textFieldStyle={textFieldStyle}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <ValidatedTextField
                                                label="Name"
                                                name="name"
                                                error={this.state.errorStatus.name}
                                                value={this.state.data.name}
                                                onChange={this.handleValueChange}
                                                textFieldStyle={textFieldStyle}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <ValidatedTextField
                                                label="Address"
                                                name="address"
                                                error={this.state.errorStatus.address}
                                                value={this.state.data.address}
                                                onChange={this.handleValueChange}
                                                textFieldStyle={textFieldStyle}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                id="standard-with-placeholder"
                                                label="Remarks"
                                                name="remarks"
                                                value={this.state.data.remarks}
                                                onChange={this.handleValueChange}
                                                placeholder=""
                                                style={textFieldStyle}
                                                margin="normal"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container justify="flex-end">
                                        <Button
                                            variant="outlined"
                                            size="large"
                                            color="primary"
                                            onClick={() => this.handleUpdate(this.props.node.data.id)}
                                            style={{
                                                margin: "20px",
                                                marginTop: "25px",
                                            }}
                                        >
                                            Update
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Fade>
                </Modal>
            </div>
        );
    }
}
export default withStyles(styles)(EditWarehouse);
