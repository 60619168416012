import React, { Component } from "react";
import {
  Grid,
  Card,
  CardContent,
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Table
} from "@material-ui/core";
import { Label } from "semantic-ui-react";

class ReceivedVoucherPrint extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      customerName,
      customerIdNumber,
      date,
      voucherNumber,
      invoiceNumber,
      invoiceTotal,
      receivedMethod,
      receivedReferenceNumber,
      receivedReference,
      receivedVoucherCredit,
      receivedVoucherDebit
    } = this.props.data;

    return (
      <Grid container justify="center">
        <Card
          style={{
            margin: 21,
            padding: 21,
            width: "90vw",
            marginBottom: "100px"
          }}
        >
          <CardContent>
            <Grid
              container
              justify="center"
              style={{ marginTop: 0, fontSize: 18 }}
            >
              <Grid container justify="space-between">
                <Grid item justify="flex-start">
                  <Label>Customer Name: </Label>
                  {customerName}
                </Grid>

                <Grid item justify="flex-end">
                  <Label>Customer Id Number:</Label>
                  {customerIdNumber}
                </Grid>
              </Grid>

              <Grid container justify="space-between">
                <Grid item justify="flex-start">
                  <Label>Date: </Label>
                  {date}
                </Grid>

                <Grid item justify="flex-end">
                  <Label>Voucher Number:</Label>
                  {voucherNumber}
                </Grid>
              </Grid>

              <Grid container justify="space-between">
                <Grid item justify="flex-start">
                  <Label>Invoice Number: </Label>
                  {invoiceNumber}
                </Grid>

                <Grid item justify="flex-end">
                  <Label>Invoice Total:</Label>
                  {invoiceTotal}
                </Grid>
              </Grid>

              <Grid container justify="space-between">
                <Grid item justify="flex-start">
                  <Label>Received Method: </Label>
                  {receivedMethod}
                </Grid>

                <Grid item justify="flex-end">
                  <Label>Received Reference Number:</Label>
                  {receivedReferenceNumber}
                </Grid>
              </Grid>

              <Grid container justify="space-between">
                <Grid item justify="flex-start">
                  <Label>Received Reference: </Label>
                  {receivedReference}
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              justify="center"
              style={{ marginTop: 50, fontSize: 18 }}
            >
              <Label>Received Voucher</Label>
            </Grid>

            <Grid
              container
              justify="center"
              style={{ marginTop: 30, fontSize: 18 }}
            >
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell align="right">Narration</TableCell>
                      <TableCell align="right">Cost Center</TableCell>
                      <TableCell align="right">Account Code</TableCell>
                      <TableCell align="right">Sub Account</TableCell>
                      <TableCell align="right">Currency</TableCell>
                      <TableCell align="right">Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {receivedVoucherDebit.map(row => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell align="right">{row.narration}</TableCell>
                        <TableCell align="right">{row.costCenter}</TableCell>
                        <TableCell align="right">{row.accountCode}</TableCell>
                        <TableCell align="right">{row.subAccount}</TableCell>
                        <TableCell align="right">{row.currency}</TableCell>
                        <TableCell align="right">{row.amount}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid
              container
              justify="center"
              style={{ marginTop: 30, fontSize: 18 }}
            >
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell align="right">Narration</TableCell>
                      <TableCell align="right">Cost Center</TableCell>
                      <TableCell align="right">Account Code</TableCell>
                      <TableCell align="right">Sub Account</TableCell>
                      <TableCell align="right">Currency</TableCell>
                      <TableCell align="right">Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {receivedVoucherCredit.map(row => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell align="right">{row.narration}</TableCell>
                        <TableCell align="right">{row.costCenter}</TableCell>
                        <TableCell align="right">{row.accountCode}</TableCell>
                        <TableCell align="right">{row.subAccount}</TableCell>
                        <TableCell align="right">{row.currency}</TableCell>
                        <TableCell align="right">{row.amount}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

export default ReceivedVoucherPrint;
