import React, { Component } from "react";
import {
  Modal,
  Backdrop,
  CardContent,
  Card,
  Grid,
  Typography,
  TextField,
  MenuItem,
  Button,
  withStyles,
  IconButton
} from "@material-ui/core";
import Axios from "axios";
import UserContext from "../../utils/UserContext";
import Close from "@material-ui/icons/Close"

const styles = theme => ({
  cardHeight: {
    padding: "20px",
    minWidth: "300px",
    overflowY: "auto",
    height: "95%",
    [theme.breakpoints.down("sm")]: {
      width: "90%"
    },
    [theme.breakpoints.up("md")]: {
      width: "60%"
    },
    [theme.breakpoints.up("lg")]: {
      width: "70%"
    }
  },
  containerWidth: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%"
    }
  },
});

class AddCustomerModal extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      data: {
        paymentMode: "Mode 1",
        customerType: "Customer Type 1"
      }
    };
  }

  onChange = e => {
    e.stopPropagation();
    var targetName = e.target.name;
    var value = e.target.value;
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [targetName]: value
      }
    }));
  };

  handleSubmit = () => {
    console.log(this.state.data);
    const token = this.context;

    Axios.post(
      "pos/customer/addCustomer", this.state.data
    )
      .then(res => {
        console.log(res);

        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000
        };

        token.setSnackbarProps({ snackbarProps: snackbarProps });

        console.log(res);
        this.props.handleClose();
      })
      .catch(err => {
        var message = err.message;
        if (err.response !== undefined) {
          message = err.response.data;
        }
        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000
        };

        token.setSnackbarProps({ snackbarProps: snackbarProps });
      });
  };


  render() {

    const { open, handleClose, classes } = this.props;

    const modal = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    };

    const textFieldStyle = {
      width: "100%"
    };


    const inputStyles = {
      fontSize: 14
    };


    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >

          <Card className={classes.cardHeight}>
            <Grid container justify="flex-end">
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </Grid>
            <CardContent>
              <Grid container spacing={4} className={classes.containerWidth}>
                <Grid item xs={12} >
                  <Grid container justify="center" style={{ height: "10px", marginBottom: "10px" }}>
                    <Typography variant="h6" component="h2">
                      {" "}
                      Add Customer{" "}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    justify="center"
                    style={{ height: "10px", marginBottom: "60px" }}
                  >
                    <h6>Customer Details</h6>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    id="standard-with-placeholder"
                    label="Full Name"
                    placeholder=""
                    name="fullName"
                    onChange={this.onChange}
                    style={textFieldStyle}
                    inputProps={{ style: inputStyles }}
                    InputLabelProps={{ style: inputStyles }}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="standard-with-placeholder"
                    label="Customer PAN"
                    placeholder=""
                    name="pan"
                    onChange={this.onChange}
                    style={textFieldStyle}
                    inputProps={{ style: inputStyles }}
                    InputLabelProps={{ style: inputStyles }}
                    margin="normal"
                  />
                </Grid>



                <Grid item xs={12} md={6}>
                  <TextField
                    id="standard-with-placeholder"
                    label="Customer ID Number"
                    placeholder=""
                    name="customerIdNumber"
                    onChange={this.onChange}
                    style={textFieldStyle}
                    inputProps={{ style: inputStyles }}
                    InputLabelProps={{ style: inputStyles }}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="standard-with-placeholder"
                    label="Effective Date"
                    name="effectiveDate"
                    onChange={this.onChange}
                    placeholder=""
                    style={textFieldStyle}
                    inputProps={{ style: inputStyles }}
                    InputLabelProps={{ style: inputStyles }}
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    id="standard-with-placeholder"
                    label="Mobile Number"
                    name="mobileNumber"
                    onChange={this.onChange}
                    placeholder=""
                    style={textFieldStyle}
                    inputProps={{ style: inputStyles }}
                    InputLabelProps={{ style: inputStyles }}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="standard-with-placeholder"
                    label="Email"
                    name="emailAddress"
                    onChange={this.onChange}
                    placeholder=""
                    style={textFieldStyle}
                    inputProps={{ style: inputStyles }}
                    InputLabelProps={{ style: inputStyles }}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    justify="center"
                    style={{ height: "10px", marginBottom: "60px" }}
                  >
                    <h6>Full Address</h6>
                  </Grid>
                </Grid>


                <Grid item xs={12} md={6}>
                  <TextField
                    id="standard-with-placeholder"
                    label="Country"
                    placeholder=""
                    name="country"
                    onChange={this.onChange}
                    style={textFieldStyle}
                    inputProps={{ style: inputStyles }}
                    InputLabelProps={{ style: inputStyles }}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="standard-with-placeholder"
                    label="State"
                    placeholder=""
                    name="state"
                    onChange={this.onChange}
                    style={textFieldStyle}
                    inputProps={{ style: inputStyles }}
                    InputLabelProps={{ style: inputStyles }}
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    id="standard-with-placeholder"
                    label="City"
                    placeholder=""
                    name="city"
                    onChange={this.onChange}
                    style={textFieldStyle}
                    inputProps={{ style: inputStyles }}
                    InputLabelProps={{ style: inputStyles }}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="standard-with-placeholder"
                    label="Street"
                    placeholder=""
                    name="street"
                    onChange={this.onChange}
                    style={textFieldStyle}
                    inputProps={{ style: inputStyles }}
                    InputLabelProps={{ style: inputStyles }}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} >
                  <Grid
                    container
                    justify="center"
                    style={{ height: "10px", marginBottom: "60px" }}
                  >
                    <h6>Customer Type Details</h6>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Customer Type"
                    style={textFieldStyle}
                    InputProps={{ style: inputStyles }}
                    InputLabelProps={{ style: inputStyles }}
                    value={this.state.data.customerType}
                    name="customerType"
                    onChange={this.onChange}
                    margin="normal"
                  >
                    <MenuItem key={1} value={"Customer Type 1"}>
                      Customer Type 1
                      </MenuItem>
                    <MenuItem key={1} value={"Customer Type 2"}>
                      Customer type 2
                      </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Payment Mode"
                    style={textFieldStyle}
                    name="paymentMode"
                    onChange={this.onChange}
                    InputProps={{ style: inputStyles }}
                    InputLabelProps={{ style: inputStyles }}
                    value={this.state.data.paymentMode}
                    margin="normal"
                  >
                    <MenuItem key={1} value={"Mode 1"}>
                      Mode 1
                      </MenuItem>
                    <MenuItem key={1} value={"Mode 2"}>
                      Mode 2
                      </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="standard-with-placeholder"
                    label="Credit Term "
                    placeholder=""
                    name="creditTerm"
                    onChange={this.onChange}
                    style={textFieldStyle}
                    inputProps={{ style: inputStyles }}
                    InputLabelProps={{ style: inputStyles }}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="standard-with-placeholder"
                    label="Other Reference"
                    placeholder=""
                    name="otherReference"
                    onChange={this.onChange}
                    style={textFieldStyle}
                    inputProps={{ style: inputStyles }}
                    InputLabelProps={{ style: inputStyles }}
                    margin="normal"
                  />
                </Grid>


                <Grid item xs={12}>
                  <Grid container justify="center">
                    <Button
                      variant="outlined"
                      onClick={this.handleSubmit}
                      size="large"
                      color="primary"
                      style={{
                        margin: "20px",
                        marginTop: "50px",
                        marginRight: "40px"
                      }}
                    >
                      Add Customer
                    </Button>
                  </Grid>
                </Grid>

              </Grid>
            </CardContent>
          </Card>

        </Modal>
      </div>
    );
  }
}

export default (withStyles(styles)(AddCustomerModal));
