import React, { Component } from "react";
import DashboardView from "../components/Dashboard/DashboardView";
import Card from "../components/Card/Card";
import {
  Typography,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Hidden,
  Avatar,
  Tooltip,
} from "@material-ui/core";

import UserContext from "../utils/UserContext";
import { Line, Bar } from "react-chartjs-2";
import { Label, Placeholder } from "semantic-ui-react";
import MinimalDashboardView from "../components/Dashboard/MinimalDashboardView";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Axios from "axios";

class Dashboard extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      transactionsData: [],
      expensesData: {
        periodList: [],
        dataDirect: [],
        dataIndirect: [],
      },
      bestSalesByQuantity: [],
      bestSalesByValue: [],
      cashAndBank: {
        labels: [],
        data: [],
      },
      dailyStatus: [],
      weeklyStatus: [],
      ageingReport: [],
      ageingReportLoading: false,
    };
  }

  componentDidMount() {
    this.setState({ ageingReportLoading: true });

    Axios.get("pos/dashboard/ageingReport")
      .then((res) => {
        let data = res.data;
        let arr = ["balance", "days30", "days60", "days90", "over90"];

        data.forEach((element) => {
          arr.map((val) =>
            element[val] < 0
              ? (element[val] = "(" + Math.abs(element[val]) + ")")
              : null
          );
        });

        this.setState({ ageingReport: data, ageingReportLoading: false });
      })
      .catch((err) => console.log(err));

    Axios.get("pos/dashboard/dailyStatus")
      .then((res) => {
        this.setState({
          dailyStatus: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    Axios.get("pos/dashboard/weeklyStatus")
      .then((res) => this.setState({ weeklyStatus: res.data }))
      .catch((err) => console.log(err));

    Axios.get("pos/dashboard/cashAndBank")
      .then((res) => {
        let labels = [];
        let data = [];

        res.data.map((val) => {
          labels.push(val.for);
          data.push(val.value);

          return null;
        });

        this.setState({
          cashAndBank: {
            labels,
            data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });

    Axios.get("pos/dashboard/bestSalesByQuantity")
      .then((res) => {
        this.setState({
          bestSalesByQuantity: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    Axios.get("pos/dashboard/bestSalesByValue")
      .then((res) => this.setState({ bestSalesByValue: res.data }))
      .catch((err) => console.log(err));

    Axios.get("pos/dashboard/expenses")
      .then((res) => {
        let periodList = [];
        let dataDirect = [];
        let dataIndirect = [];

        res.data.forEach((element) => {
          console.log(element);

          periodList.push(element.period);
          dataDirect.push(element.directExpenses);
          dataIndirect.push(element.indirectExpenses);
        });

        this.setState({
          expensesData: {
            periodList,
            dataDirect,
            dataIndirect,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });

    Axios.get("pos/dashboard/transactions")
      .then((res) => {
        this.setState({
          transactionsData: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  returnMinimalWord = (word) => {
    let finalWord = "";

    if (word.length > 20) {
      for (let index = 0; index < 15; index++) {
        const element = word[index];

        finalWord += element;
      }

      finalWord += "...";
    } else {
      finalWord = word;
    }

    return finalWord;
  };

  render() {
    var LineData = {
      labels: ["January", "February", "March", "April", "May", "June", "July"],
      datasets: [],
    };

    if (this.state.transactionsData.length !== 0) {
      try {
        LineData = {
          labels: this.state.transactionsData[0].period,
          datasets: [
            {
              label: this.state.transactionsData[0].for,
              fill: false,
              lineTension: 0.5,
              borderWidth: 6,
              backgroundColor: "rgba(75,192,192,0.4)",
              borderColor: "rgba(75,192,192,1)",
              borderCapStyle: "butt",
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              pointBorderColor: "rgba(75,192,192,1)",
              pointBackgroundColor: "#fff",
              pointBorderWidth: 8,
              pointHoverRadius: 9,
              pointHoverBackgroundColor: "rgba(75,192,192,1)",
              pointHoverBorderColor: "rgba(220,220,220,1)",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: this.state.transactionsData[0].data,
            },
            {
              label: this.state.transactionsData[1].for,
              fill: false,
              lineTension: 0.5,
              backgroundColor: "rgba(175,92,192,0.4)",
              borderColor: "rgba(175,92,192,1)",
              borderCapStyle: "butt",
              borderWidth: 6,
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              pointBorderColor: "rgba(175,92,192,1)",
              pointBackgroundColor: "#fff",
              pointBorderWidth: 8,
              pointHoverRadius: 9,
              pointHoverBackgroundColor: "rgba(175,92,192,1)",
              pointHoverBorderColor: "rgba(175,92,192,1)",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: this.state.transactionsData[1].data,
            },
          ],
        };
      } catch (error) {
        console.log(error);
      }
    }

    const cashData = {
      labels: this.state.cashAndBank.labels,
      datasets: [
        {
          label: "Figures",
          backgroundColor: "rgba(255,99,132,0.2)",
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: this.state.cashAndBank.data,
        },
      ],
    };

    let barData = {};

    try {
      barData = {
        labels: this.state.expensesData.periodList,
        datasets: [
          {
            label: "Direct",
            backgroundColor: "rgba(255,99,132,0.2)",
            borderColor: "rgba(255,99,132,1)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(255,99,132,0.4)",
            hoverBorderColor: "rgba(255,99,132,1)",
            data: this.state.expensesData.dataDirect,
          },
          {
            label: "Indirect",
            backgroundColor: "rgba(155,231,91,0.2)",
            borderColor: "rgba(155,231,91,1)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(155,231,91,0.4)",
            hoverBorderColor: "rgba(155,231,91,1)",
            data: this.state.expensesData.dataIndirect,
          },
        ],
      };
    } catch (error) {
      console.log(error);
    }

    const options = {
      responsive: true,
      legend: {
        display: true,
      },
      type: "bar",
      maintainAspectRatio: false,
    };

    return (
      <div>
        <Hidden smDown>
          <DashboardView
            dailyData={this.state.dailyStatus}
            weeklyData={this.state.weeklyStatus}
          />
          <div
            style={{
              width: "82vw",
              backgroundColor: "#EAEDEF",
              paddingTop: "50px",
              paddingBottom: "10px",
              paddingLeft: "10px",
              paddingRight: "15px",
            }}
          >
            <Grid container spacing={2} style={{ paddingRight: "25px" }}>
              <Grid item xs={6}>
                <Card
                  chart
                  style={{
                    marginTop: "20px",
                    paddingBottom: "50px",
                    paddingRight: "15px",
                    paddingLeft: "15px",
                    maxHeight: "370px",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    style={{ padding: "10px", fontWeight: "bold" }}
                  >
                    Transaction
                  </Typography>

                  <Line
                    data={LineData}
                    options={{ maintainAspectRatio: false }}
                  />
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  chart
                  style={{
                    marginTop: "20px",
                    paddingBottom: "50px",
                    paddingRight: "15px",
                    paddingLeft: "15px",
                    maxHeight: "370px",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    style={{ padding: "10px", fontWeight: "bold" }}
                  >
                    Expenses
                  </Typography>
                  <Bar data={barData} options={options} />
                </Card>
              </Grid>
            </Grid>
            {this.state.bestSalesByQuantity.length < 1 ? null : (
              <>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginBottom: "10px" }}
                >
                  Best Selling Products - By Quantity--
                </Typography>

                <div
                  className="bestSelling"
                  style={{
                    width: "79vw",
                    paddingLeft: "0px",
                    paddingRight: "25px",
                    marginTop: "10px",
                    overflow: "auto",
                    whiteSpace: "nowrap",
                  }}
                >
                  {this.state.bestSalesByQuantity.map((val, i) => (
                    <Card
                      key={i}
                      style={{
                        margin: "0px",
                        width: "275px",
                        padding: "20px",
                        display: "inline-block",
                        marginRight: "10px",
                      }}
                    >
                      <Grid container justify="space-between">
                        <Grid item xs>
                          <Avatar
                            style={{
                              backgroundColor: "#3F51B5",
                              verticalAlign: "middle",
                              marginTop: "5%",
                            }}
                          >
                            <ShoppingCart />
                          </Avatar>
                        </Grid>
                        <Grid item xs style={{ textAlign: "start" }}>
                          <Tooltip
                            title={val[0]}
                            aria-label={val[0]}
                            placement="top"
                          >
                            <Typography>
                              {this.returnMinimalWord(val[0])}
                            </Typography>
                          </Tooltip>

                          <Typography>
                            <Label
                              circular
                              style={{
                                backgroundColor: "#4BC0C0",
                                color: "#fff",
                              }}
                            >
                              {val[1]}
                            </Label>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                  ))}
                </div>
              </>
            )}

            {this.state.bestSalesByValue.length < 1 ? null : (
              <>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                >
                  Best Selling Products - By Value
                </Typography>
                <div
                  className="bestSelling"
                  style={{
                    width: "79vw",
                    paddingLeft: "0px",
                    paddingRight: "25px",
                    marginTop: "10px",
                    overflow: "auto",
                    whiteSpace: "nowrap",
                  }}
                >
                  {this.state.bestSalesByValue.map((val, i) => (
                    <Card
                      key={i}
                      style={{
                        margin: "0px",
                        width: "275px",
                        padding: "20px",
                        display: "inline-block",
                        marginRight: "10px",
                      }}
                    >
                      <Grid container justify="space-between">
                        <Grid item xs>
                          <Avatar
                            style={{
                              backgroundColor: "#3F51B5",
                              verticalAlign: "middle",
                              marginTop: "5%",
                            }}
                          >
                            <ShoppingCart />
                          </Avatar>
                        </Grid>
                        <Grid item xs style={{ textAlign: "start" }}>
                          <Tooltip
                            title={val[0]}
                            aria-label={val[0]}
                            placement="top"
                          >
                            <Typography>
                              {this.returnMinimalWord(val[0])}
                            </Typography>
                          </Tooltip>

                          <Typography>
                            <Label
                              circular
                              style={{
                                backgroundColor: "#4BC0C0",
                                color: "#fff",
                              }}
                            >
                              {val[1]}
                            </Label>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                  ))}
                </div>
              </>
            )}

            <Grid
              container
              spacing={2}
              style={{ paddingRight: "20px", marginBottom: "100px" }}
            >
              <Grid item xs={6}>
                <Card
                  chart
                  style={{
                    paddingRight: "15px",
                    paddingBottom: "50px",
                    paddingLeft: "15px",
                    width: "100%",
                    maxHeight: "370px",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    style={{ padding: "15px", fontWeight: "bold" }}
                  >
                    Cash & Bank
                  </Typography>
                  <Bar
                    data={cashData}
                    options={{
                      legend: {
                        display: false,
                      },
                      scales: {
                        yAxes: [
                          {
                            display: true,
                            ticks: {
                              suggestedMin: 0,
                              beginAtZero: true,
                            },
                          },
                        ],
                      },
                      maintainAspectRatio: false,
                    }}
                  />
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  chart
                  style={{
                    paddingRight: "15px",
                    paddingBottom: "15px",
                    paddingLeft: "15px",
                    width: "100%",
                    maxHeight: "370px",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    style={{ padding: "15px", fontWeight: "bold" }}
                  >
                    Aeging Report
                  </Typography>

                  {this.state.ageingReportLoading ? (
                    <Placeholder>
                      <Placeholder.Paragraph>
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                      </Placeholder.Paragraph>
                      <Placeholder.Paragraph>
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                      </Placeholder.Paragraph>
                    </Placeholder>
                  ) : (
                    <TableContainer>
                      <Table
                        aria-label="simple table"
                        size="small"
                        stickyHeader
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Items</TableCell>
                            <TableCell>Balance</TableCell>
                            <TableCell>30</TableCell>
                            <TableCell>60</TableCell>
                            <TableCell>90</TableCell>
                            <TableCell>Over</TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {this.state.ageingReport.map((val, i) => (
                            <TableRow key={i}>
                              <TableCell>{val.fore}</TableCell>
                              <TableCell>{val.balance}</TableCell>
                              <TableCell>{val["days30"]}</TableCell>
                              <TableCell>{val["days60"]}</TableCell>
                              <TableCell>{val["days90"]}</TableCell>
                              <TableCell>{val.over90}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Card>
              </Grid>
            </Grid>
          </div>
        </Hidden>

        <Hidden mdUp>
          <MinimalDashboardView
            dailyData={this.state.dailyStatus}
            weeklyData={this.state.weeklyStatus}
          />
          <div
            style={{
              backgroundColor: "#EAEDEF",
              paddingBottom: "10px",
              paddingLeft: "10px",
              paddingRight: "15px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs>
                <Card
                  chart
                  style={{
                    marginTop: "20px",
                    paddingBottom: "45px",
                    paddingRight: "15px",
                    paddingLeft: "15px",
                    maxHeight: "330px",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    style={{ padding: "10px", fontWeight: "bold" }}
                  >
                    Transaction
                  </Typography>

                  <Line
                    data={LineData}
                    options={{ responsive: true, maintainAspectRatio: false }}
                  />
                </Card>
              </Grid>
              <Grid item xs>
                <Card
                  chart
                  style={{
                    marginTop: "20px",
                    paddingBottom: "45px",
                    paddingRight: "15px",
                    paddingLeft: "15px",
                    maxHeight: "330px",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    style={{ padding: "10px", fontWeight: "bold" }}
                  >
                    Expenses
                  </Typography>
                  <Bar data={barData} options={options} />
                </Card>
              </Grid>
            </Grid>

            {this.state.bestSalesByQuantity.length < 1 ? null : (
              <>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginBottom: "10px" }}
                >
                  Best Selling Products - By Quantity
                </Typography>
                <div
                  className="bestSelling"
                  style={{
                    paddingLeft: "0px",
                    paddingRight: "25px",
                    marginTop: "10px",
                    overflow: "auto",
                    whiteSpace: "nowrap",
                  }}
                >
                  {this.state.bestSalesByQuantity.map((val, i) => (
                    <Card
                      key={i}
                      style={{
                        marginBottom: "5px",
                        width: "275px",
                        padding: "20px",
                        display: "inline-block",
                        marginRight: "10px",
                      }}
                    >
                      <Grid container justify="space-between">
                        <Grid item xs>
                          <Avatar
                            style={{
                              backgroundColor: "#3F51B5",
                              verticalAlign: "middle",
                              marginTop: "5%",
                            }}
                          >
                            <ShoppingCart />
                          </Avatar>
                        </Grid>
                        <Grid item xs style={{ textAlign: "start" }}>
                          <Tooltip
                            title={val[0]}
                            aria-label={val[0]}
                            placement="top"
                          >
                            <Typography>
                              {this.returnMinimalWord(val[0])}
                            </Typography>
                          </Tooltip>

                          <Typography>
                            <Label
                              circular
                              style={{
                                backgroundColor: "#4BC0C0",
                                color: "#fff",
                              }}
                            >
                              {val[1]}
                            </Label>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                  ))}
                </div>
              </>
            )}

            {this.state.bestSalesByValue.length < 1 ? null : (
              <>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                >
                  Best Selling Products - By Value
                </Typography>
                <div
                  className="bestSelling"
                  style={{
                    paddingLeft: "0px",
                    paddingRight: "25px",
                    marginTop: "10px",
                    overflow: "auto",
                    whiteSpace: "nowrap",
                  }}
                >
                  {this.state.bestSalesByValue.map((val, i) => (
                    <Card
                      key={i}
                      style={{
                        marginBottom: "5px",
                        width: "275px",
                        padding: "20px",
                        display: "inline-block",
                        marginRight: "10px",
                      }}
                    >
                      <Grid container justify="space-between">
                        <Grid item xs>
                          <Avatar
                            style={{
                              backgroundColor: "#3F51B5",
                              verticalAlign: "middle",
                              marginTop: "5%",
                            }}
                          >
                            <ShoppingCart />
                          </Avatar>
                        </Grid>
                        <Grid item xs style={{ textAlign: "start" }}>
                          <Tooltip
                            title={val[0]}
                            aria-label={val[0]}
                            placement="top"
                          >
                            <Typography>
                              {this.returnMinimalWord(val[0])}
                            </Typography>
                          </Tooltip>

                          <Typography>
                            <Label
                              circular
                              style={{
                                backgroundColor: "#4BC0C0",
                                color: "#fff",
                              }}
                            >
                              {val[1]}
                            </Label>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                  ))}
                </div>
              </>
            )}

            <Grid
              container
              spacing={2}
              style={{ paddingRight: "15px", marginBottom: "100px" }}
            >
              <Grid item xs>
                <Card
                  chart
                  style={{
                    paddingRight: "15px",
                    paddingBottom: "45px",
                    paddingLeft: "15px",
                    maxHeight: "330px",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    style={{ padding: "15px", fontWeight: "bold" }}
                  >
                    Cash & Bank
                  </Typography>
                  <Bar
                    data={cashData}
                    options={{
                      legend: {
                        display: false,
                      },
                      responsive: true,
                      maintainAspectRatio: false,
                    }}
                  />
                </Card>
              </Grid>
              <Grid item xs>
                <Card
                  chart
                  style={{
                    paddingRight: "15px",
                    paddingBottom: "15px",
                    paddingLeft: "15px",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    style={{ padding: "15px", fontWeight: "bold" }}
                  >
                    Aeging Report
                  </Typography>

                  {this.state.ageingReportLoading ? (
                    <Placeholder>
                      <Placeholder.Paragraph>
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                      </Placeholder.Paragraph>
                      <Placeholder.Paragraph>
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                      </Placeholder.Paragraph>
                    </Placeholder>
                  ) : (
                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Items</TableCell>
                            <TableCell>Balance</TableCell>
                            <TableCell>30</TableCell>
                            <TableCell>60</TableCell>
                            <TableCell>90</TableCell>
                            <TableCell>Over</TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {this.state.ageingReport.map((val, i) => (
                            <TableRow key={i}>
                              <TableCell>{val.fore}</TableCell>
                              <TableCell>{val.balance}</TableCell>
                              <TableCell>{val["days30"]}</TableCell>
                              <TableCell>{val["days60"]}</TableCell>
                              <TableCell>{val["90days"]}</TableCell>
                              <TableCell>{val.over90}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Card>
              </Grid>
            </Grid>
          </div>
        </Hidden>
      </div>
    );
  }
}

export default Dashboard;
