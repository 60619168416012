import React, { useState, useEffect, useRef } from "react";
import Axios from "axios";
import {
  Grid,
  Card,
  CardContent,
  TextField,
  MenuItem,
  TableRow,
  Table,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  withStyles,
  TableContainer,
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  RadioGroup,
  Radio,
  FormControlLabel,
  Divider,
  Hidden,
  CircularProgress,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReactExport from "react-export-excel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExcel,
  faFilePdf,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import UserContext from "../utils/UserContext";
import ReactToPrint from "react-to-print";
import BooksOfAccountPrint from "../components/PrintableComponents/BooksOfAccountPrint";
import jsPDF from "jspdf";
import "jspdf-autotable";
import defaultRoutes from "../utils/routes";
import { withRouter } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import adbs, { bs2ad } from "ad-bs-converter";
import dateFormat from "dateformat";
import "nepali-datepicker-reactjs/dist/index.css";
import NepaliDatepicker from 'nepali-datepicker-and-dateinput';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const styles = (theme) => ({
  tabMargin: {
    height: "700px",
    width: "100%",
    maxWidth: "1300px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "100px",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "65px",
    },
  },
  root: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "150px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
    },
  },
});

function BooksOfAccount(props) {
  let initialDetails = {
    period: "",
    openingDebitAmount: 0,
    closingCreditAmount: 0,
    openingDate: "",
    openingDateNepali: "",
    closingDate: "",
    closingDateNepali: "",
    booksOfAccountDetails: [],
    totalDebitAmount: 0,
    totalCreditAmount: 0,
    runningBalance: 0,
  };

  const [fiscalYear, setFiscalYear] = useState("");
  // const [currentPeriod, setCurrentPeriod] = useState("");
  const [accountList, setAccountList] = useState([]);
  const [subAccountList, setSubAccountList] = useState([]);
  const [accountCode, setAccountCode] = useState("");
  const [subAccountCode, setSubAccountCode] = useState("");
  const [data, setData] = useState(initialDetails);
  // const [orgData, setOrgData] = useState(initialDetails);
  const [distinctFyList, setDistinctFyList] = useState([]);
  const [distinctPeriodList, setDistinctPeriodList] = useState([]);
  const { classes } = props;
  const [excel, setExcel] = useState([]);
  // const [todayDate, setTodayDate] = useState([]);
  const [panelExpanded, setPanelExpanded] = useState(true);
  const [filterTerm, setFilterTerm] = useState();
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [periodFrom, setPeriodFrom] = useState("");
  const [periodTo, setPeriodTo] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyContact, setCompanyContact] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [dropdownList, setDropdownList] = useState([]);
  const [subAccountDropdownList, setSubAccountDropdownList] = useState([]);
  const [loading, setLoading] = useState(false);

  const token = useContext(UserContext);
  let componentRef = useRef();

  const getNepaliDate = (date) => {
    let parsedDate = new Date(Date.parse(date));
    let day = parsedDate.getDate();
    let month = parsedDate.getMonth() + 1;
    let year = parsedDate.getFullYear();

    let miti = adbs.ad2bs(year + "/" + month + "/" + day);
    let nepaliDate = miti.en.year + "-" + miti.en.month + "-" + miti.en.day;

    return nepaliDate;
  };

  const redirectVoucher = (voucherType, voucherNo) => {
    let routes = defaultRoutes;

    let obj = {};

    if (fiscalYear !== "") {
      obj = {
        fiscalYear: fiscalYear,
        periodFrom: periodFrom,
        periodTo: periodTo,
      };
    } else {
      obj = {
        dateFrom: dateFrom,
        dateTo: dateTo,
      };
    }

    if (voucherType === "Debit Note" || voucherType === "Credit Note")
      voucherType += " Voucher";

    token.setVoucherNo({ value: voucherNo });
    token.setBoaFrom({ value: "voucher" });
    token.setVoucherType({ value: voucherType });
    token.setAccountCode({ value: accountCode });
    token.setSubAccountCode({ value: subAccountCode });
    token.setFilterFields({ value: obj });

    routes.map((val) =>
      voucherType === val.name ? props.history.push(val.path) : null
    );
  };

  const getAccountName = (accountCode) => {
    let accountName = "";

    accountList.map((val) =>
      parseInt(val.code) === parseInt(accountCode)
        ? (accountName = val.name)
        : null
    );

    return accountName;
  };

  const getSubAccountName = (subAccountCode) => {
    let subAccountName = "";

    subAccountList.map((val) =>
      val.code === subAccountCode ? (subAccountName = val.name) : null
    );

    return subAccountName;
  };

  const getCurrentPeriod = () => {
    Axios.get("/fy/getCurrentPeriod")
      .then(async (res) => {
        setFiscalYear(res.data.fiscalYear);
        setPeriodFrom(res.data.name);
        setPeriodTo(res.data.name);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCurrentPeriod();

    let viewParam = props.match.params.id;

    const accountCode = token.accountCode;
    const subAccountCode = token.subAccountCode;
    const voucherNo = token.voucherNo;
    const filterFields = token.filterFields;
    const boaFrom = token.boaFrom;

    Axios.get("/fy/findTillCurrentPeriod")
      .then((res) => {
        var data = [];
        var nonDistinctFyList = [];
        var nonDistinctPeriodList = [];

        res.data.forEach((element) => {
          var item = {
            id: element.id,
            code: element.code,
            name: element.name,
            status: element.currentStatus,
            fiscalYear: element.fiscalYear,
          };

          data.push(item);
          nonDistinctFyList.push(item.fiscalYear);
          nonDistinctPeriodList.push(item.name);
        });

        setDistinctFyList([...new Set(nonDistinctFyList)]);
        setDistinctPeriodList([...new Set(nonDistinctPeriodList)]);

        // let currentPeriod = "";

        data.forEach(async (element) => {
          if (element.status === true) {
            // currentPeriod = element.name;
            // setCurrentPeriod(currentPeriod);
          }
        });
        var rowDetails = [];
        Axios.get("pos/ledgerMaster/getAllActiveAccountGroup")
          .then(async (res) => {
            res.data.forEach((element) => {
              var item = {
                key: element.code,
                value: element.code,
                text: element.code + "-" + element.name,
              };
              rowDetails.push(item);
            });
            setDropdownList(rowDetails);

            let newAccountList = [
              { id: 0, code: "", name: "Choose Account Group" },
            ];

            res.data.map((val) => newAccountList.push(val));
            setAccountList(newAccountList);
            if (viewParam === "view" || boaFrom === "voucher")
              setAccountCode(accountCode);

            let newSubAccountList = [];

            if (accountCode !== "") {
              await Axios.get(
                "/pos/subLedger/getAllActiveSubLedgerByUnderCode/" + accountCode
              ).then((res) => {
                newSubAccountList = res.data;
                setSubAccountList(res.data);
                if (viewParam === "view" || boaFrom === "voucher")
                  setSubAccountCode(subAccountCode);
              });
            }

            //get table data if accountCode is not empty
            if (
              accountCode !== "" &&
              (viewParam === "view" || voucherNo !== "")
            ) {
              let newFilterFields = filterFields;

              setShowDetails(true);
              setPanelExpanded(false);

              if (newFilterFields.fiscalYear === "") {
                newFilterFields = {
                  dateFrom: newFilterFields.dateFrom,
                  dateTo: newFilterFields.dateTo,
                };

                setDateFrom(filterFields.dateFrom);
                setDateTo(filterFields.dateTo);
                setFilterTerm("date");
              } else {
                setFiscalYear(filterFields.fiscalYear);
                setPeriodFrom(filterFields.periodFrom);
                setPeriodTo(filterFields.periodTo);

                setFilterTerm("fiscalYear");
              }

              Axios.post(
                "/pos/booksOfAccount/getBooksOfAccount/" + accountCode,
                newFilterFields
              )
                .then((res) => {
                  let initialBalance = res.data.openingDebitAmount;

                  let newData = res.data;

                  let runningBalance = initialBalance;
                  let totalDebitAmount = res.data.openingDebitAmount;
                  let totalCreditAmount = 0;

                  newData.accountCode = accountCode;

                  let accountName = "";

                  newAccountList.map((val) =>
                    parseInt(val.code) === parseInt(accountCode)
                      ? (accountName = val.name)
                      : null
                  );

                  let subAccountName = "";

                  newSubAccountList.map((val) =>
                    val.code === subAccountCode
                      ? (subAccountName = val.name)
                      : null
                  );

                  newData.accountName = accountName;
                  newData.subAccountCode = subAccountCode;
                  newData.subAccountName = subAccountName;

                  let excelData = [];

                  excelData.push({
                    accountCode: "",
                    fiscalYear: "Fiscal Year",
                    period: newFilterFields.fiscalYear,
                    transactionDate: "",
                    voucherType: "",
                    voucherNumber: "",
                    particulars: "",
                    debitAmount: "",
                    creditAmount: "",
                    runningBalance: "",
                  });

                  excelData.push({
                    accountCode: "",
                    fiscalYear: "Period From",
                    period: newFilterFields.periodFrom,
                    transactionDate: "",
                    voucherType: "",
                    voucherNumber: "",
                    particulars: "",
                    debitAmount: "",
                    creditAmount: "",
                    runningBalance: "",
                  });

                  excelData.push({
                    accountCode: "",
                    fiscalYear: "Period To",
                    period: newFilterFields.periodTo,
                    transactionDate: "",
                    voucherType: "",
                    voucherNumber: "",
                    particulars: "",
                    debitAmount: "",
                    creditAmount: "",
                    runningBalance: "",
                  });

                  excelData.push({
                    accountCode: "",
                    fiscalYear: "Date From",
                    period: newFilterFields.dateFrom,
                    transactionDate: "",
                    voucherType: "",
                    voucherNumber: "",
                    particulars: "",
                    debitAmount: "",
                    creditAmount: "",
                    runningBalance: "",
                  });

                  excelData.push({
                    accountCode: "",
                    fiscalYear: "Date To",
                    period: newFilterFields.dateTo,
                    transactionDate: "",
                    voucherType: "",
                    voucherNumber: "",
                    particulars: "",
                    debitAmount: "",
                    creditAmount: "",
                    runningBalance: "",
                  });

                  excelData.push({
                    accountCode: "",
                    fiscalYear: "",
                    period: "",
                    transactionDate: "",
                    voucherType: "",
                    voucherNumber: "",
                    particulars: "",
                    debitAmount: "",
                    creditAmount: "",
                    runningBalance: "",
                  });

                  excelData.push({
                    accountCode: "Account Code",
                    fiscalYear: "Fiscal Year",
                    period: "Period",
                    transactionDate: "Transaction Date",
                    voucherType: "Voucher Type",
                    voucherNumber: "Voucher Number",
                    particulars: "Particulars",
                    debitAmount: "Debit Amount",
                    creditAmount: "Credit Amount",
                    runningBalance: "Running Balance",
                  });

                  newData.booksOfAccountDetails.map((val) => {
                    val.runningBalance =
                      runningBalance + val.debitAmount - val.creditAmount;

                    runningBalance = val.runningBalance;
                    totalDebitAmount += val.debitAmount;
                    totalCreditAmount += val.creditAmount;

                    val.transactionDateNepali = getNepaliDate(
                      val.transactionDate
                    );

                    return runningBalance;
                  });

                  newData.totalDebitAmount = totalDebitAmount;
                  newData.totalCreditAmount = totalCreditAmount;

                  newData.openingDateNepali = getNepaliDate(
                    newData.openingDate
                  );
                  newData.closingDateNepali = getNepaliDate(
                    newData.closingDate
                  );

                  if (res.data.booksOfAccountDetails.length > 0) {
                    newData.runningBalance =
                      res.data.booksOfAccountDetails[
                        res.data.booksOfAccountDetails.length - 1
                      ].runningBalance;
                  } else {
                    newData.runningBalance = 0;
                  }

                  setData(newData);

                  let objOpening = {
                    accountCode: "",
                    fiscalYear: "",
                    period: "",
                    transactionDate: "",
                    voucherType: "",
                    voucherNumber: "",
                    particulars: "Opening Balance",
                    debitAmount: newData.openingDebitAmount,
                    creditAmount: "",
                    runningBalance: newData.openingDebitAmount,
                  };
                  excelData.push(objOpening);

                  newData.booksOfAccountDetails.forEach((val) => {
                    let obj = {
                      accountCode: newData.accountCode,
                      fiscalYear: newData.fiscalYear,
                      period: newData.period,
                      transactionDate: val.transactionDate,
                      voucherType: val.voucherType,
                      voucherNumber: val.voucherNumber,
                      particulars: val.particulars,
                      debitAmount: val.debitAmount,
                      creditAmount: val.creditAmount,
                      runningBalance: val.runningBalance,
                    };

                    excelData.push(obj);
                  });

                  let objClosing = {
                    accountCode: "",
                    fiscalYear: "",
                    period: "",
                    transactionDate: "",
                    voucherType: "",
                    voucherNumber: "",
                    particulars: "Closing Balance",
                    debitAmount: "",
                    creditAmount: newData.closingCreditAmount,
                    runningBalance: newData.closingCreditAmount,
                  };
                  excelData.push(objClosing);

                  let total = {
                    accountCode: "",
                    fiscalYear: "",
                    period: "",
                    transactionDate: "",
                    voucherType: "",
                    voucherNumber: "",
                    particulars: "Total",
                    debitAmount: totalDebitAmount,
                    creditAmount: totalCreditAmount,
                  };
                  excelData.push(total);

                  setExcel(excelData);
                })
                .catch((err) => {
                  console.log(err);
                });
            } else {
              getTodaysDate();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });

    getCompanyName();
  }, [
    token.accountCode,
    token.subAccountCode,
    token.filterFields,
    props.match.params.id,
    token.voucherNo,
    token.boaFrom,
  ]);

  const getTodaysDate = () => {
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    var todayDate = dateFormat(date, "yyyy-mm-dd");
    console.log(todayDate)

    let transactionDateFrom = (todayDate.split("-"));
    let transactionDateTo = (todayDate.split("-"));
    
    let tdf = (
      transactionDateFrom[0] +
        "/" +
        transactionDateFrom[1] +
        "/" +
        transactionDateFrom[2]
    );
    let tdt = (
      transactionDateTo[0] +
        "/" +
        transactionDateTo[1] +
        "/" +
        transactionDateTo[2]
    );

    let df= adbs.ad2bs(tdf)

    let finalDateFromYear = df.en.year;
    let finalDateFromMonth = df.en.month;
    let finalDateFromDay = df.en.day;
    if(df.en.month < 10){
      finalDateFromMonth = "0" + df.en.month;
    }    
    if(df.en.day < 10){
      finalDateFromDay = "0" + df.en.day;
    }

    let finalDateFrom = finalDateFromYear + "-" + finalDateFromMonth + "-" + finalDateFromDay;

    setDateFrom(finalDateFrom);
    setDateTo(finalDateFrom);
  };

  const handleFromDate = (name, dateInMilli, bsDate, adDate) => {
    setDateFrom(adDate);
  };

  const handleToDate = (name, dateInMilli, bsDate, adDate) => {
    setDateTo(adDate);
  };

  const getTableData = (obj) => {
    setLoading(true);

    setShowDetails(true);

    Axios.post("/pos/booksOfAccount/getBooksOfAccount/" + accountCode, obj)
      .then((res) => {
        setLoading(false);
        let sortedDate = res.data.booksOfAccountDetails.sort(function (a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(a.transactionDate) - new Date(b.transactionDate);
        });

        let initialBalance = res.data.openingDebitAmount;

        let newData = res.data;

        let runningBalance = initialBalance;

        let totalDebitAmount = res.data.openingDebitAmount;
        let totalCreditAmount = res.data.closingCreditAmount;

        var format = new Intl.NumberFormat("ne-NP", {
          minimumFractionDigits: 2,
        });

        newData.accountCode = accountCode;
        newData.accountName = getAccountName(accountCode);
        newData.subAccountCode = subAccountCode;
        newData.subAccountName = getSubAccountName(subAccountCode);

        newData.booksOfAccountDetails.map((val) => {
          val.runningBalance =
            runningBalance + val.debitAmount - val.creditAmount;

          runningBalance = val.runningBalance;
          totalDebitAmount += val.debitAmount;
          totalCreditAmount += val.creditAmount;

          val.transactionDateNepali = getNepaliDate(val.transactionDate);

          return runningBalance;
        });

        newData.totalDebitAmount = totalDebitAmount;
        newData.totalCreditAmount = totalCreditAmount;

        newData.openingDateNepali = getNepaliDate(newData.openingDate);
        newData.closingDateNepali = getNepaliDate(newData.closingDate);

        //------EXCEL DATA--------
        var accountNamePdf = "";
        var subAccountNamePdf = "";

        accountList.forEach((element) => {
          if (element.code === accountCode) {
            accountNamePdf = element.code + "-" + element.name;
          } else {
            return null;
          }
        });

        subAccountList.forEach((item) => {
          if (item.code === subAccountCode) {
            subAccountNamePdf = item.code + "-" + item.name;
          } else {
            return null;
          }
        });
        let excelData = [];

        if (filterTerm === "fiscalYear") {
          excelData.push({
            accountCode: "",
            fiscalYear: "Fiscal Year",
            period: fiscalYear,
            transactionDate: "",
            voucherType: "",
            voucherNumber: "",
            particulars: "",
            debitAmount: "",
            creditAmount: "",
            runningBalance: "",
          });

          excelData.push({
            accountCode: "",
            fiscalYear: "Period From",
            period: periodFrom,
            transactionDate: "",
            voucherType: "",
            voucherNumber: "",
            particulars: "",
            debitAmount: "",
            creditAmount: "",
            runningBalance: "",
          });

          excelData.push({
            accountCode: "",
            fiscalYear: "Period To",
            period: periodTo,
            transactionDate: "",
            voucherType: "",
            voucherNumber: "",
            particulars: "",
            debitAmount: "",
            creditAmount: "",
            runningBalance: "",
          });
        } else if (filterTerm === "date") {
          excelData.push({
            accountCode: "",
            fiscalYear: "Date Range",
            period: "",
            transactionDate: "",
            voucherType: "",
            voucherNumber: "",
            particulars: "",
            debitAmount: "",
            creditAmount: "",
            runningBalance: "",
          });
          excelData.push({
            accountCode: "",
            fiscalYear: "Date From",
            period: dateFrom,
            transactionDate: "",
            voucherType: "",
            voucherNumber: "",
            particulars: "",
            debitAmount: "",
            creditAmount: "",
            runningBalance: "",
          });

          excelData.push({
            accountCode: "",
            fiscalYear: "Date To",
            period: dateTo,
            transactionDate: "",
            voucherType: "",
            voucherNumber: "",
            particulars: "",
            debitAmount: "",
            creditAmount: "",
            runningBalance: "",
          });
        } else {
          return null;
        }

        excelData.push({
          accountCode: "",
          fiscalYear: "Accounts",
          period: accountNamePdf,
          transactionDate: "",
          voucherType: "",
          voucherNumber: "",
          particulars: "",
          debitAmount: "",
          creditAmount: "",
          runningBalance: "",
        });

        excelData.push({
          accountCode: "",
          fiscalYear: "Sub Accounts",
          period: subAccountNamePdf,
          transactionDate: "",
          voucherType: "",
          voucherNumber: "",
          particulars: "",
          debitAmount: "",
          creditAmount: "",
          runningBalance: "",
        });

        excelData.push({
          accountCode: "",
          fiscalYear: "",
          period: "",
          transactionDate: "",
          voucherType: "",
          voucherNumber: "",
          particulars: "",
          debitAmount: "",
          creditAmount: "",
          runningBalance: "",
        });

        excelData.push({
          accountCode: "Account Code",
          fiscalYear: "Fiscal Year",
          period: "Period",
          transactionDate: "Transaction Date",
          voucherType: "Voucher Type",
          voucherNumber: "Voucher Number",
          particulars: "Particulars",
          debitAmount: "Debit Amount",
          creditAmount: "Credit Amount",
          runningBalance: "Running Balance",
        });

        if (res.data.booksOfAccountDetails.length > 0) {
          newData.runningBalance =
            res.data.booksOfAccountDetails[
              res.data.booksOfAccountDetails.length - 1
            ].runningBalance;


        } else {
          newData.runningBalance = runningBalance;
          newData.totalCreditAmount = newData.closingCreditAmount;
        }

        setData(newData);

        sortedDate = newData.booksOfAccountDetails.sort(function (a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(a.transactionDate) - new Date(b.transactionDate);
        });

        // setOrgData(newData);

        let objOpening = {
          accountCode: "",
          fiscalYear: "",
          period: "",
          transactionDate: "",
          voucherType: "",
          voucherNumber: "",
          particulars: "Opening Balance",
          debitAmount: format.format(newData.openingDebitAmount),
          creditAmount: "",
          runningBalance: format.format(newData.openingDebitAmount),
        };
        excelData.push(objOpening);

        newData.booksOfAccountDetails.forEach((val) => {
          let obj = {
            accountCode: newData.accountCode,
            fiscalYear: newData.fiscalYear,
            period: val.period,
            transactionDate: val.transactionDate,
            voucherType: val.voucherType,
            voucherNumber: val.voucherNumber,
            particulars: val.particulars,
            debitAmount: format.format(val.debitAmount),
            creditAmount: format.format(val.creditAmount),
            runningBalance: format.format(val.runningBalance),
          };

          excelData.push(obj);
        });

        let objClosing = {
          accountCode: "",
          fiscalYear: "",
          period: "",
          transactionDate: "",
          voucherType: "",
          voucherNumber: "",
          particulars: "Closing Balance",
          debitAmount: "",
          creditAmount: format.format(newData.closingCreditAmount),
          runningBalance: format.format(newData.runningBalance),
        };
        excelData.push(objClosing);

        let total = {
          accountCode: "",
          fiscalYear: "",
          period: "",
          transactionDate: "",
          voucherType: "",
          voucherNumber: "",
          particulars: "Total",
          debitAmount: format.format(totalDebitAmount),
          creditAmount: format.format(totalCreditAmount),
        };
        excelData.push(total);

        setExcel(excelData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const customSearch = (_options, query) => {
    let queryText = query.toLowerCase();

    let arr = dropdownList;

    let newSet = arr.filter(
      (val) => val.text.toLowerCase().indexOf(queryText.toLowerCase()) !== -1
    );

    if (isNaN(queryText)) {
      newSet = newSet.sort((x, y) =>
        x.text.split("-")[1][0].toLowerCase() === queryText
          ? -1
          : y.text.split("-")[1][0].toLowerCase() === queryText
            ? 1
            : 0
      );
    } else {
      newSet = newSet.sort((x, y) =>
        x.text[0] === queryText ? -1 : y.text[0] === queryText ? 1 : 0
      );
    }

    return [...newSet];
  };

  const customSearchSubAccount = (_options, query) => {
    let queryText = query.toLowerCase();

    let arr = subAccountDropdownList;

    let newSet = arr.filter(
      (val) => val.text.toLowerCase().indexOf(queryText.toLowerCase()) !== -1
    );

    if (isNaN(queryText)) {
      newSet = newSet.sort((x, y) =>
        x.text.split("-")[1][0].toLowerCase() === queryText
          ? -1
          : y.text.split("-")[1][0].toLowerCase() === queryText
            ? 1
            : 0
      );
    } else {
      newSet = newSet.sort((x, y) =>
        x.text[0] === queryText ? -1 : y.text[0] === queryText ? 1 : 0
      );
    }

    return [...newSet];
  };

  const handlePanelChange = (_e, isExpanded) => {
    setPanelExpanded(isExpanded);
  };

  const handlePeriodFromChange = async (e) => {
    let value = e.target.value;
    await setPeriodFrom(value);
  };

  const handlePeriodToChange = async (e) => {
    let value = e.target.value;
    await setPeriodTo(value);
  };

  const changeFiscalYear = (e) => {
    let value = e.target.value;
    setFiscalYear(value);
  };

  const onFilterTermChange = (e) => {
    let value = e.target.value;
    setFilterTerm(value);
  };

  const changeSubAccountCode = async (e, data) => {
    let value = data.value;

    await setSubAccountCode(value);
  };

  const changeAccountCode = async (e, data) => {
    let value = data.value;

    await setAccountCode(value);
    setSubAccountCode("");

    getSubAccountList(value);
  };

  const getSubAccountList = (accountCode) => {
    var rowDetails = [];
    Axios.get(
      "/pos/subLedger/getAllActiveSubLedgerByUnderCode/" + accountCode
    ).then((res) => {
      res.data.forEach((element) => {
        var item = {
          key: element.code,
          value: element.code,
          text: element.code + "-" + element.name,
        };
        rowDetails.push(item);
      });

      setSubAccountDropdownList(rowDetails);
    });
  };

  const applyAccount = () => {
    setLoading(true);
    let obj;

    setPanelExpanded(false);
      
    var format = new Intl.NumberFormat("ne-NP", {
      minimumFractionDigits: 2,
    });

    if (filterTerm === "fiscalYear") {
      obj = {
        fiscalYear: fiscalYear,
        periodFrom: periodFrom,
        periodTo: periodTo,
      };
    } else {
      obj = {
        dateFrom: dateFrom,
        dateTo: dateTo,
      };
    }

    if (subAccountCode !== "") {
      setShowDetails(true);
      let creditAmount = 0;
      let debitAmount = 0;

      Axios.post("/pos/booksOfAccount/getBooksOfAccount/" + accountCode, obj)
        .then(async (res) => {
          setLoading(false);

          let sortedDate = await res.data.booksOfAccountDetails.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(a.transactionDate) - new Date(b.transactionDate);
          });

          res.data.booksOfAccountSubAccounts.forEach((val) => {

            if (
              subAccountCode === val.subAccountCode &&
              val.type === "Closing"
            ) {
              creditAmount = val.amount;
            }

            if (
              subAccountCode === val.subAccountCode &&
              val.type === "Opening"
            ) {
              debitAmount = val.amount;
            }
          });

          console.log({ debitAmount })
          //let initialBalance = res.data.openingDebitAmount;
          let initialBalance = debitAmount;

          let newData = res.data;
          let runningBalance = initialBalance;
          //let totalDebitAmount = res.data.openingDebitAmount;
          //let totalCreditAmount = res.data.closingCreditAmount;

          let totalDebitAmount = debitAmount;
          let totalCreditAmount = creditAmount;
          newData.openingDebitAmount = debitAmount;
          newData.closingCreditAmount = creditAmount;

          //console.log(res.data.closingCreditAmount);

          let filteredData = newData.booksOfAccountDetails.filter(
            (item) => item.subAccountCode === subAccountCode
          );

          newData.accountCode = accountCode;
          newData.accountName = getAccountName(accountCode);
          newData.subAccountCode = subAccountCode;
          newData.subAccountName = getSubAccountName(subAccountCode);
          newData.booksOfAccountDetails = filteredData;

          newData.booksOfAccountDetails.map((val) => {
            val.runningBalance =
              runningBalance + val.debitAmount - val.creditAmount;

            runningBalance = val.runningBalance;
            totalDebitAmount += val.debitAmount;
            totalCreditAmount += val.creditAmount;

            val.transactionDateNepali = getNepaliDate(val.transactionDate);

            return runningBalance;
          });

          newData.totalDebitAmount = totalDebitAmount;
          newData.totalCreditAmount = totalCreditAmount;

          newData.openingDateNepali = getNepaliDate(newData.openingDate);
          newData.closingDateNepali = getNepaliDate(newData.closingDate);

          //------EXCEL DATA--------
          var accountNamePdf = "";
          var subAccountNamePdf = "";

          accountList.forEach((element) => {
            if (element.code === accountCode) {
              accountNamePdf = element.code + "-" + element.name;
            } else {
              return null;
            }
          });

          subAccountList.forEach((item) => {
            if (item.code === subAccountCode) {
              subAccountNamePdf = item.code + "-" + item.name;
            } else {
              return null;
            }
          });

          let excelData = [];
          if (filterTerm === "fiscalYear") {
            excelData.push({
              accountCode: "",
              fiscalYear: "Fiscal Year",
              period: fiscalYear,
              transactionDate: "",
              voucherType: "",
              voucherNumber: "",
              particulars: "",
              debitAmount: "",
              creditAmount: "",
              runningBalance: "",
            });

            excelData.push({
              accountCode: "",
              fiscalYear: "Period From",
              period: periodFrom,
              transactionDate: "",
              voucherType: "",
              voucherNumber: "",
              particulars: "",
              debitAmount: "",
              creditAmount: "",
              runningBalance: "",
            });

            excelData.push({
              accountCode: "",
              fiscalYear: "Period To",
              period: periodTo,
              transactionDate: "",
              voucherType: "",
              voucherNumber: "",
              particulars: "",
              debitAmount: "",
              creditAmount: "",
              runningBalance: "",
            });
          } else if (filterTerm === "date") {
            excelData.push({
              accountCode: "",
              fiscalYear: "Date Range",
              period: "",
              transactionDate: "",
              voucherType: "",
              voucherNumber: "",
              particulars: "",
              debitAmount: "",
              creditAmount: "",
              runningBalance: "",
            });
            excelData.push({
              accountCode: "",
              fiscalYear: "Date From",
              period: dateFrom,
              transactionDate: "",
              voucherType: "",
              voucherNumber: "",
              particulars: "",
              debitAmount: "",
              creditAmount: "",
              runningBalance: "",
            });

            excelData.push({
              accountCode: "",
              fiscalYear: "Date To",
              period: dateTo,
              transactionDate: "",
              voucherType: "",
              voucherNumber: "",
              particulars: "",
              debitAmount: "",
              creditAmount: "",
              runningBalance: "",
            });
          } else {
            return null;
          }

          excelData.push({
            accountCode: "",
            fiscalYear: "Accounts",
            period: accountNamePdf,
            transactionDate: "",
            voucherType: "",
            voucherNumber: "",
            particulars: "",
            debitAmount: "",
            creditAmount: "",
            runningBalance: "",
          });

          excelData.push({
            accountCode: "",
            fiscalYear: "Sub Accounts",
            period: subAccountNamePdf,
            transactionDate: "",
            voucherType: "",
            voucherNumber: "",
            particulars: "",
            debitAmount: "",
            creditAmount: "",
            runningBalance: "",
          });

          excelData.push({
            accountCode: "",
            fiscalYear: "",
            period: "",
            transactionDate: "",
            voucherType: "",
            voucherNumber: "",
            particulars: "",
            debitAmount: "",
            creditAmount: "",
            runningBalance: "",
          });

          excelData.push({
            accountCode: "Account Code",
            fiscalYear: "Fiscal Year",
            period: "Period",
            transactionDate: "Transaction Date",
            voucherType: "Voucher Type",
            voucherNumber: "Voucher Number",
            particulars: "Particulars",
            debitAmount: "Debit Amount",
            creditAmount: "Credit Amount",
            runningBalance: "Running Balance",
          });

          if (res.data.booksOfAccountDetails.length > 0) {
            newData.runningBalance =
              res.data.booksOfAccountDetails[
                res.data.booksOfAccountDetails.length - 1
              ].runningBalance;
            /*  newData.closingCreditAmount = newData.runningBalance;
            console.log({ newData });
            console.log("greater than 0" + newData.runningBalance); */
          } else {
            newData.runningBalance = initialBalance;
            newData.closingCreditAmount = 0;
          }

          setData(newData);
          /* 
                let sortedDate = newData.booksOfAccountDetails.sort(function (a, b) {
                  // Turn your strings into dates, and then subtract them
                  // to get a value that is either negative, positive, or zero.
                  return new Date(a.transactionDate) - new Date(b.transactionDate);
                }); */

          let objOpening = {
            accountCode: "",
            fiscalYear: "",
            period: "",
            transactionDate: "",
            voucherType: "",
            voucherNumber: "",
            particulars: "Opening Balance",
            debitAmount: format.format(newData.openingDebitAmount),
            creditAmount: "",
            runningBalance: newData.openingDebitAmount,
          };
          excelData.push(objOpening);

          newData.booksOfAccountDetails.forEach((val) => {
            let obj = {
              accountCode: newData.accountCode,
              fiscalYear: newData.fiscalYear,
              period: newData.period,
              transactionDate: val.transactionDate,
              voucherType: val.voucherType,
              voucherNumber: val.voucherNumber,
              particulars: val.particulars,
              debitAmount: format.format(val.debitAmount),
              creditAmount: format.format(val.creditAmount),
              runningBalance: format.format(val.runningBalance),
            };

            excelData.push(obj);
          });

          let objClosing = {
            accountCode: "",
            fiscalYear: "",
            period: "",
            transactionDate: "",
            voucherType: "",
            voucherNumber: "",
            particulars: "Closing Balance",
            debitAmount: "",
            creditAmount: format.format(newData.closingCreditAmount),
            runningBalance: format.format(newData.runningBalance),
          };
          excelData.push(objClosing);

          let total = {
            accountCode: "",
            fiscalYear: "",
            period: "",
            transactionDate: "",
            voucherType: "",
            voucherNumber: "",
            particulars: "Total",
            debitAmount: format.format(totalDebitAmount),
            creditAmount: format.format(totalCreditAmount),
          };
          excelData.push(total);

          setExcel(excelData);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      getTableData(obj);
    }
  };

  const styledTableCell = {
    backgroundColor: "#F1F8FC",
    color: "#3F51B5",
    borderRight: "1px solid #EAEDEF",
  };

  const styledTableCellNarration = {
    backgroundColor: "#F1F8FC",
    color: "#3F51B5",
    borderRight: "1px solid #EAEDEF",
    maxWidth: "200px",
    //overflow:"scroll"
  };

  const getCompanyName = () => {
    Axios.get("/hrm/getGeneralCompanyInformation")
      .then((res) => {
        res.data.forEach((element) => {
          setCompanyName(element.name);
          setCompanyAddress(element.street + ", " + element.city);
          setCompanyContact(element.contactNumber);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const generatePdf = () => {
    var accountNamePdf = "";
    var subAccountNamePdf = "";

    accountList.forEach((element) => {
      if (element.code === accountCode) {
        accountNamePdf = element.code + "-" + element.name;
      } else {
        return null;
      }
    });

    subAccountList.forEach((item) => {
      if (item.code === subAccountCode) {
        subAccountNamePdf = item.code + "-" + item.name;
      } else {
        return null;
      }
    });

    let company = companyName;
    let companyAdd = companyAddress;
    let companyContactNumber = companyContact;

    function header() {
      doc.setFont("Helvetica", "bold");
      doc.setFontSize(12);
      doc.text(420, 70, company, {
        align: "center",
      });
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(12);
      doc.text(420, 90, companyAdd, {
        align: "center",
      });
      doc.setFontSize(12);
      doc.text(420, 110, companyContactNumber, {
        align: "center",
      });
    }
    function footer() {
      doc.setFont("Helvetica", "italic");
      doc.setFontSize(9);
      doc.text(40, doc.internal.pageSize.height - 20, window.location.href);
    }

    var doc = new jsPDF("l", "pt", "a4", true);
    //current date
    doc.setFont("Helvetica", "italic");
    doc.setFontSize(9);
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + "/" + mm + "/" + dd;
    doc.text(
      doc.internal.pageSize.width - 150,
      doc.internal.pageSize.height - 20,
      "Printed on: " + today
    );
    header();

    doc.setFont("Helvetica", "bold");
    doc.setFontSize(12);
    doc.text(40, 160, "Books of Account", {
      align: "left",
    });

    var x = 40;
    var y = 190;

    if (filterTerm === "fiscalYear") {
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11);
      doc.text(x + 5, y, "Fiscal Year:");
      doc.setFont("Helvetica", "italic");
      doc.text(120, y, fiscalYear);

      y = y + 25;
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11);
      doc.text(x, y, "Period From:");
      doc.setFont("Helvetica", "italic");
      doc.text(120, y, periodFrom);

      y = y + 25;
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11);
      doc.text(x + 13, y, "Period To:");
      doc.setFont("Helvetica", "italic");
      doc.text(120, y, periodTo);

      x = x + 510;
      y = 190;
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11);
      doc.text(x + 22, y, "Account:");
      doc.setFont("Helvetica", "italic");
      doc.text(630, y, accountNamePdf);

      y = y + 25;
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11);
      doc.text(x, y, "Sub Account:");
      doc.setFont("Helvetica", "italic");
      doc.text(630, y, subAccountNamePdf);
    } else if (filterTerm === "date") {
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(12);
      doc.text(x, y, "Date Range");

      y = y + 25;
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11);
      doc.text(x + 10, y, "Date From:");
      doc.setFont("Helvetica", "italic");
      doc.text(120, y, dateFrom);

      y = y + 25;
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11);
      doc.text(x + 23, y, "Date To:");
      doc.setFont("Helvetica", "italic");
      doc.text(120, y, dateTo);

      y = y + 25;
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11);
      doc.text(x + 23, y, "Account:");
      doc.setFont("Helvetica", "italic");
      doc.text(120, y, accountNamePdf);

      y = y + 25;
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11);
      doc.text(40, y, "Sub Account:");
      doc.setFont("Helvetica", "italic");
      doc.text(120, y, subAccountNamePdf);
    }

    //formatting Amount
    var format = new Intl.NumberFormat("ne-NP", {
      // style: 'currency',
      // currency: 'NPR',
      minimumFractionDigits: 2,
    });

    let booksOfAccount = [];

    booksOfAccount.push([
      data.period,
      data.openingDate,
      data.openingDateNepali,
      "",
      "Opening Balance",
      format.format(data.openingDebitAmount),
      "",
      format.format(data.openingDebitAmount),
    ]);

    data.booksOfAccountDetails.map((value) =>
      booksOfAccount.push([
        value.period ? value.period : "",
        value.transactionDate,
        value.transactionDateNepali,
        value.voucherNumber,
        value.particulars,
        format.format(value.debitAmount),
        format.format(value.creditAmount),
        format.format(value.runningBalance),
      ])
    );

    booksOfAccount.push([
      data.period,
      data.closingDate,
      data.closingDateNepali,
      "",
      "Closing Balance",
      "",
      format.format(data.runningBalance),
      format.format(data.runningBalance),
    ]);

    booksOfAccount.push([
      "",
      "",
      "",
      "",
      "Total",
      format.format(data.totalDebitAmount),
      format.format(data.totalCreditAmount + data.runningBalance),
    ]);

    doc.autoTable({
      startY: y + 60,
      styles: {
        overflow: "linebreak",
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.3,
        halign: "center",
        font: "helvetica",
      },
      didParseCell: function (data) {
        var rows = data.table.body;
        if (data.row.index === rows.length - 1) {
          data.cell.styles.fontStyle = "bold";
        }
      },
      bodyStyles: { valign: "top" },
      theme: "grid",
      columnStyles: {
        0: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          cellWidth: "auto",
          halign: "center",
        },
        1: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          cellWidth: "auto",
          halign: "center",
        },
        2: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          cellWidth: "auto",
          halign: "center",
        },
        3: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          cellWidth: "auto",
          halign: "center",
        },
        4: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          cellWidth: "auto",
          halign: "left",
        },
        5: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          cellWidth: "auto",
          halign: "right",
        },
        6: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          cellWidth: "auto",
          halign: "right",
        },
        7: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          cellWidth: "wrap",
          halign: "right",
        },
      },
      head: [
        [
          "Period",
          "Date",
          "Miti",
          "Voucher No.",
          "Particulars",
          "Debit Amount",
          "Credit Amount",
          "Running Balance",
        ],
      ],
      body: booksOfAccount,
    });
    footer();
    const pages = doc.internal.getNumberOfPages();
    const pageWidth = doc.internal.pageSize.width; //Optional
    const pageHeight = doc.internal.pageSize.height; //Optional
    doc.setFontSize(10); //Optional
    for (let j = 1; j < pages + 1; j++) {
      let horizontalPos = pageWidth / 2; //Can be fixed number
      let verticalPos = pageHeight - 10; //Can be fixed number
      doc.setPage(j);
      doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
        align: "center",
      });
    }
    doc.save("Books Of Account");
  };

  var format = new Intl.NumberFormat("ne-NP", {
    minimumFractionDigits: 2,
  });

  return (
    <>
        <div
          style={{
            backgroundColor: "#EAEDEF",
          }}
        >
      <Grid container justify="center" className={classes.root}>
        <Card className={classes.tabMargin} style={{marginBottom:"100px"}}>
          <CardContent>
            <Grid container style={{ width: "100%" }} justify="space-between">
              <Grid item xs={12} sm={6}>
                <ExpansionPanel
                  expanded={panelExpanded}
                  onChange={handlePanelChange}
                  style={{ maxWidth: "800px" }}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      Report By
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid container>
                      <Grid item xs={12} md={6}>
                        <RadioGroup
                          row
                          value={filterTerm}
                          onChange={onFilterTermChange}
                        >
                          <FormControlLabel
                            value="fiscalYear"
                            control={<Radio color="primary" />}
                            label="Fiscal Year and Period"
                          />
                          <FormControlLabel
                            value="date"
                            control={<Radio color="primary" />}
                            label="Date Range"
                          />
                        </RadioGroup>

                        {filterTerm === "fiscalYear" ? (
                          <>
                            <Grid
                              container
                              justify="flex-start"
                              spacing={2}
                              style={{ marginTop: "20px" }}
                            >
                              <Grid item>
                                <TextField
                                  select
                                  style={{
                                    width: "15vw",
                                    minWidth: "120px",
                                  }}
                                  label="Fiscal Year"
                                  name="fiscalYear"
                                  onChange={changeFiscalYear}
                                  value={fiscalYear}
                                >
                                  {distinctFyList.map((item, i) => (
                                    <MenuItem key={i} value={item}>
                                      {item}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Grid>
                            </Grid>

                            <Grid
                              container
                              justify="flex-start"
                              style={{ marginTop: "20px" }}
                            >
                              <Grid>
                                <TextField
                                  select
                                  style={{
                                    width: "15vw",
                                    minWidth: "120px",
                                  }}
                                  label="Period From"
                                  name="periodFrom"
                                  value={periodFrom}
                                  onChange={handlePeriodFromChange}
                                >
                                  {distinctPeriodList.map((item, i) => (
                                    <MenuItem key={i} value={item}>
                                      {item}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Grid>
                              <Grid item>
                                <TextField
                                  select
                                  style={{
                                    width: "15vw",
                                    minWidth: "120px",
                                  }}
                                  label="Period To"
                                  name="periodTo"
                                  value={periodTo}
                                  onChange={handlePeriodToChange}
                                >
                                  {distinctPeriodList.map((item, i) => (
                                    <MenuItem key={i} value={item}>
                                      {item}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Grid>
                            </Grid>
                          </>
                        ) : null}

                        {filterTerm === "date" ? (
                          <>
                            <Grid container justify="flex-start" spacing={2}>
                            <Grid item style={{marginTop: "8px"}}>
                                  <NepaliDatepicker
                                    id="nepali-date"
                                    onChange={handleFromDate}
                                    label="Date From"
                                    value={dateFrom}
                                    locale= "en"
                                    showDefaultDate="true"
                                    style={{height: "5px", width: "5px"}}
                                  />
                                  </Grid>
                                  <Grid item style={{marginTop: "10px"}}> 
                                  <NepaliDatepicker
                                    id="nepali-date"
                                    onChange={handleToDate}
                                    label="Date To"
                                    value={dateTo}
                                    locale= "en"
                                    showDefaultDate="true"
                                    style={{height: "5px", width: "5px"}}
                                  />
                              </Grid>
                            </Grid>
                          </>
                        ) : null}
                      </Grid>

                      <Hidden smDown>
                        <Grid xs={1}>
                          <Divider
                            orientation="vertical"
                            inset
                            style={{
                              minHeight: "100px",
                              marginBottom: "10px",
                            }}
                          />
                        </Grid>
                      </Hidden>
                      <Hidden mdUp>
                        <Grid xs={12}>
                          <Divider
                            style={{
                              marginTop: "15px",
                              marginBottom: "10px",
                            }}
                          />
                        </Grid>
                      </Hidden>

                      <Grid item md={5} xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Dropdown
                              clearable
                              placeholder="Account "
                              floating
                              fluid
                              fullWidth
                              selectOnBlur={false}
                              search={customSearch}
                              style={{
                                borderTop: "0px",
                                borderRight: 0,
                                borderLeft: 0,
                                borderBottom: "1px solid ",
                                borderRadius: "0px",
                                borderColor: "#8c8a84",
                              }}
                              selection
                              value={accountCode}
                              onChange={changeAccountCode}
                              options={[...dropdownList]}
                            ></Dropdown>
                          </Grid>
                          <Grid item xs={12}>
                            <Dropdown
                              clearable
                              placeholder="Sub Account "
                              floating
                              fluid
                              fullWidth
                              selectOnBlur={false}
                              search={customSearchSubAccount}
                              style={{
                                borderTop: "0px",
                                borderRight: 0,
                                borderLeft: 0,
                                borderBottom: "1px solid ",
                                borderRadius: "0px",
                                borderColor: "#8c8a84",
                              }}
                              selection
                              value={subAccountCode}
                              onChange={changeSubAccountCode}
                              options={[...subAccountDropdownList]}
                            ></Dropdown>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        justify="flex-end"
                        container
                        style={{ marginTop: "27px" }}
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={applyAccount}
                        >
                          Apply
                        </Button>
                      </Grid>
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid
                  container
                  justify="flex-end"
                  style={{ paddingTop: "10px", width: "100%" }}
                >
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{
                        minWidth: "100px",
                        width: "6vw",
                        margin: "3px",
                        height: "38px",
                        fontSize: "16px",
                      }}
                      onClick={generatePdf}
                    >
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        color="#3f51b5"
                        size="30px"
                        style={{
                          marginRight: "10px",
                        }}
                      ></FontAwesomeIcon>
                      Pdf
                    </Button>
                  </Grid>

                  <Grid item>
                    <ReactToPrint
                      trigger={() => (
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{
                            minWidth: "100px",
                            width: "6vw",
                            margin: "3px",
                            height: "38px",
                            fontSize: "16px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPrint}
                            color="#3f51b5"
                            size="30px"
                            style={{
                              marginRight: "10px",
                            }}
                          ></FontAwesomeIcon>
                          Print
                        </Button>
                      )}
                      content={() => componentRef}
                    />
                  </Grid>

                  <Grid item>
                    <ExcelFile
                      filename="BooksOfAccount"
                      element={
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{
                            minWidth: "100px",
                            width: "6vw",
                            margin: "3px",
                            height: "38px",
                            fontSize: "16px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faFileExcel}
                            color="#3f51b5"
                            size="30px"
                            style={{
                              marginRight: "10px",
                            }}
                          ></FontAwesomeIcon>
                          Excel
                        </Button>
                      }
                    >
                      <ExcelSheet data={excel} name="Books Of Account">
                        <ExcelColumn value="accountCode" />
                        <ExcelColumn value="fiscalYear" />
                        <ExcelColumn value="period" />
                        <ExcelColumn value="transactionDate" />
                        <ExcelColumn value="voucherType" />
                        <ExcelColumn value="voucherNumber" />
                        <ExcelColumn value="particulars" />
                        <ExcelColumn value="debitAmount" />
                        <ExcelColumn value="creditAmount" />
                        <ExcelColumn value="runningBalance" />
                      </ExcelSheet>
                    </ExcelFile>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {showDetails ? (
              <>
                {filterTerm === "fiscalYear" ? (
                  <Grid container style={{ margin: "20px", maxWidth: "700px" }}>
                    <Grid item xs={4}>
                      <Grid container>
                        <Grid>
                          <Typography
                            style={{
                              fontSize: "15px",
                              fontWeight: 600,
                              marginRight: "10px",
                              color: "#108DCD",
                            }}
                          >
                            Fiscal Year:
                          </Typography>
                        </Grid>
                        <Grid>
                          <Typography
                            style={{
                              fontSize: "15px",
                              fontWeight: 400,
                              color: "#108DCD",
                            }}
                          >
                            {fiscalYear}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container>
                        <Grid>
                          <Typography
                            style={{
                              fontSize: "15px",
                              fontWeight: 600,
                              marginRight: "10px",
                              color: "#108DCD",
                            }}
                          >
                            Period From:
                          </Typography>
                        </Grid>
                        <Grid>
                          <Typography
                            style={{
                              fontSize: "15px",
                              fontWeight: 400,
                              color: "#108DCD",
                            }}
                          >
                            {periodFrom}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container>
                        <Grid>
                          <Typography
                            style={{
                              fontSize: "15px",
                              fontWeight: 600,
                              marginRight: "10px",
                              color: "#108DCD",
                            }}
                          >
                            Period To:
                          </Typography>
                        </Grid>
                        <Grid>
                          <Typography
                            style={{
                              fontSize: "15px",
                              fontWeight: 400,
                              color: "#108DCD",
                            }}
                          >
                            {periodTo}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <Grid
                      container
                      style={{ margin: "20px", maxWidth: "500px" }}
                    >
                      <Grid item xs={6}>
                        <Grid container>
                          <Grid>
                            <Typography
                              style={{
                                fontSize: "15px",
                                fontWeight: 600,
                                marginRight: "10px",
                                color: "#108DCD",
                              }}
                            >
                              Date From:
                            </Typography>
                          </Grid>
                          <Grid>
                            <Typography
                              style={{
                                fontSize: "15px",
                                fontWeight: 400,
                                color: "#108DCD",
                              }}
                            >
                              {dateFrom}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <Grid container>
                          <Grid>
                            <Typography
                              style={{
                                fontSize: "15px",
                                fontWeight: 600,
                                color: "#108DCD",
                                marginRight: "10px",
                              }}
                            >
                              Date To:
                            </Typography>
                          </Grid>
                          <Grid>
                            <Typography
                              style={{
                                fontSize: "15px",
                                fontWeight: 400,
                                color: "#108DCD",
                              }}
                            >
                              {dateTo}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            ) : null}

            {loading ? (
              <Grid container justify="center" style={{ margin: "50px 0px" }}>
                <Grid item>
                  <CircularProgress thickness="2" size="50px" />
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid container justify="center" style={{ marginTop: "30px" }}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell style={styledTableCell}>Period</TableCell>
                          <TableCell style={styledTableCell}>Date</TableCell>
                          <TableCell style={styledTableCell}>Miti</TableCell>
                          <TableCell style={styledTableCell}>Account</TableCell>
                          <TableCell style={styledTableCell}>
                            Sub Account
                          </TableCell>
                          <TableCell style={styledTableCell}>
                            Voucher No.
                          </TableCell>
                          <TableCell style={styledTableCell} >
                            Particular
                          </TableCell>
                          <TableCell style={styledTableCell}>
                            Debit Amount
                          </TableCell>
                          <TableCell style={styledTableCell}>
                            Credit Amount
                          </TableCell>
                          <TableCell style={styledTableCell}>
                            Running Balance
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <Typography style={{ fontWeight: "bold" }}>
                              {data.period}
                            </Typography>
                          </TableCell>
                          <TableCell style={{ width: "100px" }}>
                            <Typography style={{ fontWeight: "bold" }}>
                              {data.openingDate}
                            </Typography>
                          </TableCell>
                          <TableCell style={{ width: "100px" }}>
                            <Typography style={{ fontWeight: "bold" }}>
                              {data.openingDateNepali}
                            </Typography>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <Typography style={{ fontWeight: "bold" }}>
                              Opening Balance
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              style={{ fontWeight: "bold", textAlign: "right" }}
                            >
                              {format.format(data.openingDebitAmount)}
                            </Typography>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell style={{ textAlign: "right" }}>
                            {format.format(data.openingDebitAmount)}
                          </TableCell>
                        </TableRow>
                        {data.booksOfAccountDetails.map((value) => (
                          <TableRow
                            onDoubleClick={() =>
                              redirectVoucher(
                                value.voucherType,
                                value.voucherNumber
                              )
                            }
                          >
                            <TableCell>{value.period}</TableCell>
                            <TableCell>{value.transactionDate}</TableCell>
                            <TableCell>{value.transactionDateNepali}</TableCell>
                            <TableCell>
                              {data.accountCode + " - " + data.accountName}
                            </TableCell>
                            <TableCell>
                              {value.subAccountCode +
                                " - " +
                                value.subAccountName}
                            </TableCell>
                            <TableCell>{value.voucherNumber}</TableCell>
                            <TableCell style={{ maxWidth: "300px" }}>
                              {value.particulars.slice(0, 80) + "..."}
                            </TableCell>
                            <TableCell style={{ textAlign: "right" }}>
                              {format.format(value.debitAmount)}
                            </TableCell>
                            <TableCell style={{ textAlign: "right" }}>
                              {format.format(value.creditAmount)}
                            </TableCell>
                            <TableCell style={{ textAlign: "right" }}>
                              {format.format(value.runningBalance)}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell>
                            <Typography style={{ fontWeight: "bold" }}>
                              {data.period}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography style={{ fontWeight: "bold" }}>
                              {data.closingDate}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography style={{ fontWeight: "bold" }}>
                              {data.closingDateNepali}
                            </Typography>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <Typography
                              style={{ fontWeight: "bold", textAlign: "left" }}
                            >
                              Closing Balance
                            </Typography>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <Typography
                              style={{ fontWeight: "bold", textAlign: "right" }}
                            >
                              {format.format(data.closingCreditAmount)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              style={{ fontWeight: "bold", textAlign: "right" }}
                            >
                              {format.format(data.runningBalance)}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <Typography
                              style={{ fontWeight: "bold", textAlign: "left" }}
                            >
                              Total
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              style={{ fontWeight: "bold", textAlign: "right" }}
                            >
                              {format.format(data.totalDebitAmount)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              style={{ fontWeight: "bold", textAlign: "right" }}
                            >
                              {format.format(data.totalCreditAmount)}
                            </Typography>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </>
            )}
          </CardContent>
        </Card>
      </Grid>
    </div>
      <div style={{ display: "none" }}>
        <BooksOfAccountPrint
          ref={(el) => (componentRef = el)}
          data={data}
          dateFrom={dateFrom}
          dateTo={dateTo}
          fiscalYear={fiscalYear}
          accountCode={accountCode}
          subAccountCode={subAccountCode}
          periodFrom={periodFrom}
          periodTo={periodTo}
          filterTerm={filterTerm}
        />
      </div>
      </>
  );
}
export default withRouter(withStyles(styles)(BooksOfAccount));
