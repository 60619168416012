import React, { Component } from 'react'
import {
    Grid,
    Table,
    TableContainer,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Typography
} from "@material-ui/core";
import { Label } from "semantic-ui-react";

class TrialBalancePrint extends Component {
    constructor(props) {
        super(props)

        this.state = {};
    }

    getFormattedVal = (val) => {
        let realVal = val;

        if (isNaN(val) && val.startsWith("(")) {
            realVal = val.substring(1, val.length - 1);

            var format = new Intl.NumberFormat("ne-NP", {
                minimumFractionDigits: 2,
            });

            return "(" + format.format(realVal) + ")";
        } else {
            var formatInt = new Intl.NumberFormat("ne-NP", {
                minimumFractionDigits: 2,
            });

            return formatInt.format(realVal);
        }
    };

    render() {
        const styledTableCell = {
            minWidth: "100px",
            fontWeight: "bold"

        };
        let i = 1;

        return (
            <div>
                <Grid
                    container
                    justify="center"
                    style={{ marginTop: 50, fontSize: 18 }}
                >
                    <Label>Trial Balance</Label>
                </Grid>
                {this.props.filterTerm === "fiscalYear" ?
                    <>
                        <Grid container
                            justify="start"
                            style={{ marginTop: 30, fontSize: 18 }}
                        >
                            <Grid item xs={12}>
                                <Grid container >
                                    <Grid item xs={4}>
                                        <Typography style={{ fontWeight: "bold", textAlign: "left", marginLeft: "70px", marginBottom: "10px" }}>
                                            Fiscal Year and Periods
                                     </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={2}>
                                        <Typography style={{ fontWeight: "bold", textAlign: "right", marginRight: "20px" }}>
                                            Fiscal Year:
                                     </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography style={{ fontStyle: "italic" }}>
                                            {this.props.fiscalYear}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={2}>
                                        <Typography style={{ fontWeight: "bold", textAlign: "right", marginRight: "20px" }}>
                                            Period From
                                </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography style={{ fontStyle: "italic" }}>
                                            {this.props.periodFrom}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={2}>
                                        <Typography style={{ fontWeight: "bold", textAlign: "right", marginRight: "20px" }}>
                                            Period To:
                                </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography style={{ fontStyle: "italic" }}>
                                            {this.props.periodTo}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                    :
                    <>
                        <Grid
                            container
                            justify="start"
                            style={{ marginTop: 30, fontSize: 18 }}
                        >
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={2}>
                                        <Typography style={{ fontWeight: "bold", textAlign: "right", marginRight: "20px", marginBottom: "10px" }}>
                                            Date Range
                                     </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={2}>
                                        <Typography style={{ fontWeight: "bold", textAlign: "right", marginRight: "20px" }}>
                                            Date From:
                                     </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography style={{ fontStyle: "italic" }}>
                                            {this.props.dateFrom}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={2}>
                                        <Typography style={{ fontWeight: "bold", textAlign: "right", marginRight: "20px" }}>
                                            Date To:
                                     </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography style={{ fontStyle: "italic" }}>
                                            {this.props.dateTo}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                }

                <Grid
                    container
                    justify="center"
                    style={{ marginTop: 30, fontSize: 18 }}
                >
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead >
                                <TableRow>
                                    <TableCell component="th" align="center">S.No</TableCell>
                                    <TableCell component="th" align="left">Account Code</TableCell>
                                    <TableCell component="th" align="left">Account Name</TableCell>
                                    <TableCell component="th" align="center">Opening Balance</TableCell>
                                    <TableCell component="th" align="left">Debit Current Periods</TableCell>
                                    <TableCell component="th" align="left">Credit Current Periods</TableCell>
                                    <TableCell component="th" align="left">Movement Current Periods</TableCell>
                                    <TableCell component="th" align="left">Balance To Period</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {this.props.data.map(row => (
                                    < TableRow key={row.id} >
                                        <TableCell align="center"> {i++} </TableCell>
                                        <TableCell align="left">{row.accountCode}</TableCell>
                                        <TableCell align="left">{row.accountName}</TableCell>
                                        <TableCell align="center">{this.getFormattedVal(row.openingBalance)}</TableCell>
                                        <TableCell align="right">{this.getFormattedVal(row.debitCurrentPeriod)}</TableCell>
                                        <TableCell align="right">{this.getFormattedVal(row.creditCurrentPeriod)}</TableCell>
                                        <TableCell align="right">{this.getFormattedVal(row.movementCurrentPeriod)}</TableCell>
                                        <TableCell align="right">{this.getFormattedVal(row.balanceToPeriod)}</TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableHead>
                                {this.props.totalData.map((val) => (
                                    <TableRow>
                                        <TableCell style={styledTableCell}>

                                        </TableCell>
                                        <TableCell style={styledTableCell}>
                                            {val.accountCode}
                                        </TableCell>
                                        <TableCell style={styledTableCell}>
                                            {val.accountName}
                                        </TableCell>
                                        <TableCell align="right" style={styledTableCell}>
                                            {this.getFormattedVal(val.openingBalance)}
                                        </TableCell>
                                        <TableCell align="right" style={styledTableCell}>
                                            {this.getFormattedVal(val.debitCurrentPeriod)}
                                        </TableCell>
                                        <TableCell align="right" style={styledTableCell}>
                                            {this.getFormattedVal(
                                                val.creditCurrentPeriod
                                            )}
                                        </TableCell>
                                        <TableCell align="right" style={styledTableCell}>
                                            {this.getFormattedVal(
                                                val.movementCurrentPeriod
                                            )}
                                        </TableCell>
                                        <TableCell align="right" style={styledTableCell}>
                                            {this.getFormattedVal(val.balanceToPeriod)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableHead>

                        </Table>
                    </TableContainer>
                </Grid>
            </div >
        )
    }
}
export default TrialBalancePrint;