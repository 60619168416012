import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import {
  Modal,
  Fade,
  Grid,
  TextField,
  Button,
  Backdrop,
  Divider,
  Typography,
  CardContent,
  withStyles,
  Card,
  IconButton,
} from "@material-ui/core";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import UserContext from "../../utils/UserContext";
import Close from "@material-ui/icons/Close";

const styles = (theme) => ({
  cardHeight: {
    minWidth: "300px",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      maxHeight: "98%",
      height: "auto"
    },
    [theme.breakpoints.up("md")]: {
      width: "80%",
      maxHeight: "98%",
      height: "auto"
    },
    [theme.breakpoints.up("lg")]: {
      width: "70%",
      maxHeight: "98%",
      height: "auto"
    },
  },
});

class ViewGoodsReceivedButton extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    const rowIndex = (params) => params.node.rowIndex + 1;
    this.state = {
      columnDefs: [
        {
          headername: "Sn",
          field: "sn",
          sortable: true,
          resizable: true,
          valueGetter: rowIndex,
          width: 50,
          minWidth: 50,
        },
        {
          headerName: "Item Code",
          field: "itemCode",
          sortable: true,
          resizable: true,
          width: 90,
          minWidth: 90,
        },
        {
          headerName: "Item Description",
          field: "itemName",
          sortable: true,
          resizable: true,
          minWidth: 127,
          width: 127,
        },
        {
          headerName: "unit",
          field: "unit",
          sortable: true,
          resizable: true,
          width: 70,
          minWidth: 70,
        },
        {
          headerName: "Price",
          field: "price",
          width: 70,
          minWidth: 70,
          resizable: true,
          sortable: true,
          type: "numericColumn",
        },
        {
          headerName: "Ordered Quantity",
          field: "orderQuantity",
          sortable: true,
          resizable: true,
          width: 120,
          minWidth: 120,
          type: "numericColumn",
        },
        {
          headerName: "Recieved Quantity",
          field: "receivedQuantity",
          resizable: true,
          sortable: true,
          width: 120,
          minWidth: 120,
          type: "numericColumn",
        },
        {
          headerName: "Line Total",
          field: "lineTotal",
          resizable: true,
          sortable: true,
          width: 120,
          minWidth: 120,
          type: "numericColumn",
        },
        {
          headerName: "Comment",
          field: "comment",
          resizable: true,
          sortable: true,
          minWidth: 127,
          width: 127,
        },
      ],
      rowData: [],
      purchaseDetails: {
        id: "",
        grnDate: "",
        nepaliDate: "",
        grnNumber: "",
        status: "",
        purchaseOrderNumber: "",
        receivedByCompany: "",
        receivedByUser: "",
        receivedFrom: "",
        address: "",
        supplierAddress: "",
        pan: "",
        supplierPan: "",
        warehouse: "",
        chalanNumber: "",
        invoiceNumber: "",
        comment: "",
        transportNumber: "",
      },
      commentError: false,
      overlayLoadingTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
      overlayNoRowsTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
    };
  }

  componentDidMount() {
    Axios.get("pos/grnMaster/findById/" + this.props.node.data.id)
      .then((res) => {

        console.log(res.data)

        this.setState((prevState) => ({
          ...prevState,
          rowData: res.data.grnDetails,

          purchaseDetails: {
            id: res.data.id,
            fiscalYear: res.data.fiscalYear === null ? "" : res.data.fiscalYear,
            period: res.data.period === null ? "" : res.data.period,
            grnDate: res.data.grnDate === null ? "" : res.data.grnDate,
            nepaliDate: res.data.nepaliDate === null ? "" : res.data.nepaliDate,
            status: res.data.status,
            grnNumber: res.data.grnNumber === null ? "" : res.data.grnNumber,
            purchaseOrderNumber:
              res.data.purchaseOrderNumber === null ? "" : res.data.purchaseOrderNumber,
            receivedByCompany:
              res.data.receivedBy === null ? "" : res.data.receivedBy,
            receivedByUser:
              res.data.receivedByName === null ? "" : res.data.receivedByName,
            receivedFrom:
              res.data.receivedFrom === null ? "" : res.data.receivedFrom,
            chalanNumber:
              res.data.chalanNumber === null
                ? ""
                : res.data.chalanNumber,
            address:
              res.data.receivedAddress === null ? "" : res.data.receivedAddress,
            supplierAddress:
              res.data.supplierAddress === null ? "" : res.data.supplierAddress,
            pan: res.data.receivedPan === null ? "" : res.data.receivedPan,
            supplierPan:
              res.data.supplierPan === null ? "" : res.data.supplierPan,
            warehouse: res.data.warehouse === null ? "" : res.data.warehouse,
            invoiceNumber:
              res.data.invoiceNumber === null ? "" : res.data.invoiceNumber,
            transportNumber:
              res.data.transportNumber === null ? "" : res.data.transportNumber,
          },
        }));

        this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>",
        });
        if (this.gridApi !== undefined) {
          this.gridApi.hideOverlay();
          if (res.data.length === 0) this.gridApi.showNoRowsOverlay();
        }
      })
      .catch((err) => {
        console.log(err);

        this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>",
        });
        if (this.gridApi !== undefined) {
          this.gridApi.hideOverlay();
          this.gridApi.showNoRowsOverlay();
        }
      });
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  handleSubmit = (targetName) => {
    console.log(targetName);
    var obj = {
      comment: this.state.purchaseDetails.comment,
      status: targetName,
    };

    if (targetName === "Checked") {
      Axios.put("pos/grnMaster/update/" + this.state.purchaseDetails.id, obj)
        .then((res) => {
          console.log(res);

          var snackbarProps = {
            open: true,
            variant: "success",
            message: res.data,
            autoHideDuration: 2000,
          };
          this.props.showSnackbar(snackbarProps);
          this.props.getGRNData();
          this.props.getListData();
          this.handleClose();
        })
        .catch((err) => {
          console.log(err);
          var message = err.message;
          if (err.response !== undefined) {
            message = err.response.data;
          }
          var snackbarProps = {
            open: true,
            variant: "error",
            message: message,
            autoHideDuration: 2000,
          };
          this.props.showSnackbar(snackbarProps);
        });
    } else if (targetName === "Approved") {
      Axios.put("pos/grnMaster/update/" + this.state.purchaseDetails.id, obj)
        .then((res) => {
          console.log(res);

          var snackbarProps = {
            open: true,
            variant: "success",
            message: res.data,
            autoHideDuration: 2000,
          };
          this.props.showSnackbar(snackbarProps);
          this.props.getGRNData();
          this.handleClose();
          this.props.getListData();
        })
        .catch((err) => {
          console.log(err);
          var message = err.message;
          if (err.response !== undefined) {
            message = err.response.data;
          }
          var snackbarProps = {
            open: true,
            variant: "error",
            message: message,
            autoHideDuration: 2000,
          };
          this.props.showSnackbar(snackbarProps);
        });
    } else if (targetName === "Rejected") {
      if (obj.comment !== "" && obj.comment !== undefined) {
        Axios.put("pos/grnMaster/update/" + this.state.purchaseDetails.id, obj)
          .then((res) => {
            console.log(res);
            var snackbarProps = {
              open: true,
              variant: "success",
              message: res.data,
              autoHideDuration: 2000,
            };
            this.props.showSnackbar(snackbarProps);
            this.props.getGRNData();
            this.props.getListData();
            this.handleClose();
          })
          .catch((err) => {
            var message = err.message;
            if (err.response !== undefined) {
              message = err.response.data;
            }
            var snackbarProps = {
              open: true,
              variant: "error",
              message: message,
              autoHideDuration: 2000,
            };
            this.props.showSnackbar(snackbarProps);
          });
      } else {
        this.setState({ commentError: true });
      }
    }
  };

  handleOpen = () => {
    console.log(this.props.node);
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  onChanged = (e) => {
    e.stopPropagation();
    var targetName = e.target.name;
    var value = e.target.value;

    if (targetName === "comment" && value !== "") {
      this.setState((prevState) => ({
        ...prevState,
        commentError: false,
        purchaseDetails: {
          ...prevState.purchaseDetails,
          [targetName]: value,
        },
      }));
    }
  };

  render() {
    const { classes } = this.props;

    const modal = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };

    const buttonStyle = {
      marginTop: "20px",
      marginBottom: "20px",
      marginRight: "50px",
      paddingTop: "15px",
      paddingBottom: "15px",
      paddingRight: "50px",
      paddingLeft: "50px",
    };

    return (
      <div>
        <span>
          <FontAwesomeIcon
            icon={faSearch}
            color="#3f51b5"
            onClick={() => this.handleOpen()}
          ></FontAwesomeIcon>
        </span>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          open={this.state.open}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            <Card className={classes.cardHeight}>
              <CardContent style={{ padding: "0px" }}>
                <Grid
                  container
                  justify="center"
                  style={{ width: "98%", margin: "auto" }}
                >
                  <Grid container justify="flex-end">
                    <IconButton onClick={this.handleClose}>
                      <Close />
                    </IconButton>
                  </Grid>
                  <Grid container spacing={2} style={{ padding: "20px" }}>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                        margin="dense"
                        label=" GRN Voucher No."
                        fullWidth
                        value={this.state.purchaseDetails.grnNumber}
                        name="voucherNumber"
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        label="Fiscal Year"
                        name="fiscalYear"
                        value={this.state.purchaseDetails.fiscalYear}
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        label="Period"
                        name="period"
                        value={this.state.purchaseDetails.period}
                      >
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        InputProps={{
                          readOnly: true,
                        }}
                        id="outlined-dense"
                        label="GRN Date"
                        name="grnDate"
                        value={this.state.purchaseDetails.grnDate}
                        type="date"
                        fullWidth
                        margin="dense"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        InputProps={{
                          readOnly: true,
                        }}
                        id="outlined-dense"
                        label="Received By - Company"
                        name="receivedBy"
                        value={this.state.purchaseDetails.receivedByCompany}
                        fullWidth
                        margin="dense"
                        variant="outlined"
                      ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        InputProps={{
                          readOnly: true,
                        }}
                        id="outlined-dense"
                        label="Company Address"
                        name="receivedAddress"
                        value={this.state.purchaseDetails.address}
                        fullWidth
                        margin="dense"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        InputProps={{
                          readOnly: true,
                        }}
                        id="outlined-dense"
                        label="Company PAN"
                        name="receivedPan"
                        value={this.state.purchaseDetails.pan}
                        fullWidth
                        margin="dense"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        InputProps={{
                          readOnly: true,
                        }}
                        id="outlined-dense"
                        label="Received From - Supplier"
                        name="receivedFrom"
                        value={this.state.purchaseDetails.receivedFrom}
                        fullWidth
                        margin="dense"
                        variant="outlined"
                      ></TextField>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        InputProps={{
                          readOnly: true,
                        }}
                        id="outlined-dense"
                        label="Supplier Address"
                        name="supplierAddress"
                        value={this.state.purchaseDetails.supplierAddress}
                        fullWidth
                        margin="dense"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        InputProps={{
                          readOnly: true,
                        }}
                        id="outlined-dense"
                        label="Supplier PAN"
                        name="supplierPan"
                        value={this.state.purchaseDetails.supplierPan}
                        fullWidth
                        margin="dense"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        InputProps={{
                          readOnly: true,
                        }}
                        id="outlined-dense"
                        label="Supplier Invoice No."
                        name="invoiceNumber"
                        value={this.state.purchaseDetails.invoiceNumber}
                        fullWidth
                        margin="dense"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        InputProps={{
                          readOnly: true,
                        }}
                        id="outlined-dense"
                        label="Purchase Order Number"
                        name="purchaseOrderNumber"
                        value={this.state.purchaseDetails.purchaseOrderNumber}
                        margin="dense"
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        InputProps={{
                          readOnly: true,
                        }}
                        id="outlined-dense"
                        label="Transport No."
                        name="transportNumber"
                        value={this.state.purchaseDetails.transportNumber}
                        fullWidth
                        margin="dense"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        InputProps={{
                          readOnly: true,
                        }}
                        id="outlined-dense"
                        fullWidth
                        label="Chalan Number"
                        name="chalanNumber"
                        value={this.state.purchaseDetails.chalanNumber}
                        margin="dense"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        InputProps={{
                          readOnly: true,
                        }}
                        id="outlined-dense"
                        label="Warehouse"
                        name="warehouse"
                        value={this.state.purchaseDetails.warehouse}
                        fullWidth
                        margin="dense"
                        variant="outlined"
                      >
                      </TextField>
                    </Grid>


                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        InputProps={{
                          readOnly: true,
                        }}
                        id="outlined-dense"
                        label="Received By - User"
                        onChange={this.handleTextChange}
                        name="receivedByName"
                        value={this.state.purchaseDetails.receivedByUser}
                        fullWidth
                        margin="dense"
                        variant="outlined"
                      />
                    </Grid>

                  </Grid>

                  <Divider style={{ marginTop: "20px" }} />
                  <Grid container style={{ width: "98%", margin: "auto" }}>
                    <Typography
                      style={{
                        marginBottom: "10px",
                        marginTop: "10px",
                        fontSize: "19px",
                      }}
                    >
                      Item List
                    </Typography>

                    <Grid container justify="center">
                      <div
                        className="ag-theme-balham"
                        style={{
                          height: "25vh",
                          width: "100%",
                        }}
                      >
                        <AgGridReact
                          columnDefs={this.state.columnDefs}
                          rowData={this.state.rowData}
                          animateRows={true}
                          rowSelection={this.state.rowSelection}
                          onGridReady={this.onGridReady}
                          overlayLoadingTemplate={
                            this.state.overlayLoadingTemplate
                          }
                          overlayNoRowsTemplate={
                            this.state.overlayNoRowsTemplate
                          }
                        ></AgGridReact>
                      </div>
                      <Grid container justify="center">
                        {this.state.commentError ? (
                          <TextField
                            id="standard-with-placeholder"
                            label="Comment"
                            variant="outlined"
                            name="comment"
                            fullWidth
                            error
                            helperText="Comment field is required"
                            onChange={this.onChanged}
                            value={this.state.purchaseDetails.comment}
                            margin="normal"
                          />
                        ) : (
                            <TextField
                              id="standard-with-placeholder"
                              label="Comment"
                              variant="outlined"
                              name="comment"
                              fullWidth
                              onChange={this.onChanged}
                              value={this.state.purchaseDetails.comment}
                              margin="normal"
                            />
                          )}
                      </Grid>
                    </Grid>

                    <Grid container justify="center">
                      {this.state.purchaseDetails.status === "Pending" ? (
                        <Button
                          variant="outlined"
                          size="large"
                          color="inherit"
                          onClick={() => {
                            this.handleSubmit("Checked");
                          }}
                          style={buttonStyle}
                        >
                          Check
                        </Button>
                      ) : null}

                      {this.state.purchaseDetails.status === "Checked" ? (
                        <Button
                          variant="outlined"
                          size="large"
                          color="primary"
                          onClick={() => {
                            this.handleSubmit("Approved");
                          }}
                          style={buttonStyle}
                        >
                          Approve
                        </Button>
                      ) : null}

                      <Button
                        variant="outlined"
                        size="large"
                        color="secondary"
                        onClick={() => {
                          this.handleSubmit("Rejected");
                        }}
                        style={buttonStyle}
                      >
                        Reject
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Fade>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(ViewGoodsReceivedButton);
