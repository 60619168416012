import React, { Component } from "react";
import {
  Grid,
  Card,
  CardContent,
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Typography,
} from "@material-ui/core";
import { Label } from "semantic-ui-react";

class PurchaseVoucherPrint extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      date,
      voucherNumber,
      invoiceNumber,
      paymentReferenceNumber,
      paymentMode,
      total,
      purchase,
      supplierIdNumber,
      supplierName,
      grnReference,
      transportNumber,
      chalanNumber,
      purchaseVoucherDebit,
      purchaseVoucherCredit,
    } = this.props.data;

    return (
      <Grid style={{ margin: 0, padding: 0 }} container justify="center">
        <Card
          style={{
            width: "100vw",
            marginBottom: "100px",
          }}
        >
          <CardContent>
            <Grid spacing={2} container justify="center">
              <Grid container justify="space-between">
                <Grid item>
                  <Typography>
                    {" "}
                    Supplier Name:{" "}
                    <span style={{ fontStyle: "italic" }}>{supplierName}</span>
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography>
                    {" "}
                    Supplier Id Number:
                    <span style={{ fontStyle: "italic" }}>
                      {supplierIdNumber}
                    </span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container justify="space-between">
                <Grid item>
                  <Typography>
                    Date: <span style={{ fontStyle: "italic" }}>{date}</span>{" "}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography>
                    Voucher Number:
                    <span style={{ fontStyle: "italic" }}>{voucherNumber}</span>{" "}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container justify="space-between">
                <Grid item>
                  <Typography>
                    Invoice Number:
                    <span style={{ fontStyle: "italic" }}>{invoiceNumber}</span>{" "}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography>
                    Invoice Total:
                    <span style={{ fontStyle: "italic" }}> {total}</span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container justify="space-between">
                <Grid item>
                  <Typography>
                    Payment Method:{" "}
                    <span style={{ fontStyle: "italic" }}>{paymentMode}</span>
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography>
                    Payment Reference No.:{" "}
                    <span style={{ fontStyle: "italic" }}>
                      {paymentReferenceNumber}
                    </span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container justify="space-between">
                <Grid item>
                  <Typography>
                    {" "}
                    Transport No.:{" "}
                    <span style={{ fontStyle: "italic" }}>
                      {transportNumber}
                    </span>
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography>
                    Chalan No.:{" "}
                    <span style={{ fontStyle: "italic" }}>{chalanNumber}</span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container justify="space-between">
                <Grid item>
                  <Typography>
                    {" "}
                    GRN Reference.:{" "}
                    <span style={{ fontStyle: "italic" }}>{grnReference}</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              justify="center"
              style={{ marginTop: 50, fontSize: 18 }}
            >
              <Label>Purchase Voucher</Label>
            </Grid>

            <Grid
              container
              justify="center"
              style={{ marginTop: 30, fontSize: 18 }}
            >
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead style={{ borderTop: "0.01em solid #949698" }}>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell align="center">Voucher Type</TableCell>
                      <TableCell align="left">Narration</TableCell>
                      <TableCell align="right">Cost Center</TableCell>
                      <TableCell align="right">Account Code</TableCell>
                      <TableCell align="right">Sub Account</TableCell>
                      <TableCell align="right">Currency</TableCell>
                      <TableCell align="right">Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {purchaseVoucherDebit.map((row, i) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {i + 1}
                        </TableCell>
                        <TableCell align="center">Debit</TableCell>
                        <TableCell align="left">{row.narration}</TableCell>
                        <TableCell align="center">{row.costCenter}</TableCell>
                        <TableCell align="center">{row.accountCode}</TableCell>
                        <TableCell align="center">{row.subAccount}</TableCell>
                        <TableCell align="center">{row.currency}</TableCell>
                        <TableCell align="right">
                          {parseFloat(row.amount).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableBody>
                    {purchaseVoucherCredit.map((row, i) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {i + 1 + purchaseVoucherDebit.length}
                        </TableCell>
                        <TableCell align="center">Credit</TableCell>
                        <TableCell align="left">{row.narration}</TableCell>
                        <TableCell align="center">{row.costCenter}</TableCell>
                        <TableCell align="center">{row.accountCode}</TableCell>
                        <TableCell align="center">{row.subAccount}</TableCell>
                        <TableCell align="center">{row.currency}</TableCell>
                        <TableCell align="right">
                          {parseFloat(row.amount).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

export default PurchaseVoucherPrint;
