import React, { Component } from "react";
import {
  Card,
  CardContent,
  Grid,
  Button,
  Modal,
  Fade,
  Backdrop,
  withStyles,
  IconButton,
  MenuItem,
} from "@material-ui/core";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import Axios from "axios";
import Close from "@material-ui/icons/Close";
import ViewMajorCategoryButton from "../InterfaceElements/ViewMajorCategoryButton";
import UserContext from "../../utils/UserContext";

import ValidatedTextField from "../InterfaceElements/ValidatedTextField";

const styles = (theme) => ({
  root: {
    paddingBottom: "23px",
    paddingTop: "5px",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "170px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
    },
  },
  smButtonPosition: {
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      marginBottom: "10px",
      marginTop: "20px",
    },
    [theme.breakpoints.up("sm")]: {
      marginRight: "0px",
      marginBottom: "10px",
      marginTop: "10px",
    },
  },
  cardHeight: {
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
    [theme.breakpoints.up("md")]: {
      width: "600px",
    },
  },
});

class MajorCategory extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    const rowIndex = (params) => params.node.rowIndex + 1;
    this.state = {
      data: {
        under: "",
        majorCategoryName: "",
        status: true,
        propertyType: {
          id: "",
        },
      },
      errorStatus: {},
      propertyData: [],
      columnDefs: [
        {
          headerName: "Sn",
          field: "sn",
          sortable: true,
          resizable: true,
          valueGetter: rowIndex,
          minWidth: 50,
        },
        {
          headerName: "Name",
          field: "majorCategoryName",
          width: 400,
          sortable: true,
        },
        {
          headerName: "Under",
          field: "propertyType.propertyTypeName",
        },
        {
          headerName: "Status",
          field: "status",
        },
        {
          headerName: "Actions",
          cellRendererFramework: ViewMajorCategoryButton,
          cellRendererParams: {
            //  updateData: this.getTableData,
            // updateData: this.props.getMajorCategoryData,
            setSnackbar: this.setSnackBar,
          },
          minWidth: 50,
        },
      ],
      open: false,
      dataShow: {
        under: "",
        underCode: "",
      },
      totalItemCount: 0,
      overlayLoadingTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
      overlayNoRowsTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
    };
  }

  componentWillUpdate() {
    this.daListener();
  }

  setSnackBar = (snackbarProps) => {
    const token = this.context;

    token.setSnackbarProps({ snackbarProps: snackbarProps });
  };

  daListener = () => {
    if (!this.gridApi) return;
    setTimeout(() => {
      this.gridApi.sizeColumnsToFit();
    }, 100);
  };

  firstDataRendered = (params) => {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    window.addEventListener("resize", this.daListener);
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  handleCloseModal() {
    this.setState({ open: false });
  }

  /* onChangedStatus = async (e) => {
    console.log(e.target.name)
    console.log(e.target.value)
    if (e.target.name === "status") {
      if (e.target.value === "open") {
        this.setState(prevState => ({
          ...prevState,
          data: {
            ...prevState.data,
            status: true
          },
        }));
      } else if (e.target.value === "close") {
        await this.setState(prevState => ({
          ...prevState,
          data: {
            ...prevState.data,
            status: false
          },
        }));
      }
    }
    console.log(this.state.data.status)
  } */

  onChanged = async (e) => {
    e.stopPropagation();
    var targetName = e.target.name;
    var value = e.target.value;
    var underId = "";

    if (targetName === "under") {
      this.props.propertyData.forEach((element) => {
        if (element.propertyTypeName === value) {
          underId = element.id;
        }
      });

      await this.setState((prevState) => ({
        data: {
          ...prevState.data,
          under: underId,
          propertyType: {
            ...prevState.propertyType,
            id: underId,
          },
        },
      }));
    } else {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          [targetName]: value,
        },
      }));
    }
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  validateForm() {
    var toCheck = ["majorCategoryName", "under"];

    var newErrStatus = this.state.errorStatus;
    var status = true;

    toCheck.forEach((element) => {
      let val = this.state.data[element];

      console.log({ val });

      if (val === undefined || val === "") {
        newErrStatus[element] = true;
        status = false;
      } else {
        newErrStatus[element] = false;
      }
    });

    console.log(newErrStatus);

    this.setState((prevState) => ({
      ...prevState,
      errorStatus: {
        ...prevState.errorStatus,
        newErrStatus,
      },
    }));

    return status;
  }

  handleSubmit = () => {
    const token = this.context;

    var status = this.validateForm();

    if (status) {
      Axios.post("pos/majorCategory/addMajorCategory", this.state.data)
        .then((res) => {
          console.log(res);

          var snackbarProps = {
            open: true,
            variant: "success",
            message: res.data,
            autoHideDuration: 2000,
          };

          token.setSnackbarProps({ snackbarProps: snackbarProps });
          console.log(token);
          this.handleClose();
          //this.getTableData();
          this.props.getMajorCategoryData();
          this.setState({
            data: {
              majorCategoryName: "",
              propertyType: {
                id: "",
              },
            },
          });
        })
        .catch((err) => {
          var message = err.message;

          if (err.response !== undefined) {
            message = err.response.data;
          }
          var snackbarProps = {
            open: true,
            variant: "error",
            message: message,
            autoHideDuration: 2000,
          };

          token.setSnackbarProps({ snackbarProps: snackbarProps });
        });
    }
  };

  render(params) {
    const { children, value, index, classes, ...other } = this.props;

    const modal = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };

    const inputStyles = {
      fontSize: 14,
    };

    const textFieldStyle = {
      width: "100%",
    };

    return (
      <div>
        <Card
          className={classes.root}
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
        >
          <CardContent>
            <Grid container justify="center">
              <div
                style={{
                  width: "100%",
                }}
              >
                <Grid
                  container
                  justify="flex-end"
                  style={{
                    paddingRight: "15px",
                    paddingLeft: "15px",
                  }}
                >
                  <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    onClick={this.handleOpen}
                    className={classes.smButtonPosition}
                  >
                    Add Entry
                  </Button>
                </Grid>
                <Grid container justify="flex-end">
                  <div
                    className="ag-theme-balham"
                    style={{
                      height: "51vh",
                      width: "100%",
                      padding: "15px",
                    }}
                  >
                    <h6 style={{ margin: "5px" }}>
                      {" "}
                      Total Records : {this.state.totalItemCount}
                    </h6>
                    <AgGridReact
                      columnDefs={this.state.columnDefs}
                      rowData={this.props.majorCategoryData}
                      onGridReady={this.onGridReady}
                      onFirstDataRendered={this.firstDataRendered}
                      paginationAutoPageSize={true}
                      overlayLoadingTemplate={this.state.overlayLoadingTemplate}
                      overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                    ></AgGridReact>
                  </div>
                </Grid>
              </div>
            </Grid>
          </CardContent>
        </Card>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          open={this.state.open}
          onClose={this.handleClose}
          closeAfterTransition
          errorStatus={this.state.errorStatus}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            <Card className={classes.cardHeight}>
              <CardContent style={{ overflowY: "auto" }}>
                <Grid container justify="flex-end">
                  <IconButton onClick={this.handleClose}>
                    <Close />
                  </IconButton>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ValidatedTextField
                      label="Name"
                      name="majorCategoryName"
                      error={this.state.errorStatus.majorCategoryName}
                      value={this.state.data.name}
                      onChange={this.onChanged}
                      textFieldStyle={textFieldStyle}
                      inputStyles={inputStyles}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <ValidatedTextField
                      name="under"
                      label="Under"
                      fullWidth
                      select
                      error={this.state.errorStatus.under}
                      // value={this.state.data.under}
                      style={textFieldStyle}
                      onChange={this.onChanged}
                      InputProps={{ style: { fontSize: 13 } }}
                      InputLabelProps={{ style: inputStyles }}
                      SelectProps={{
                        MenuProps: {
                          style: { width: 200 },
                        },
                      }}
                    >
                      {this.props.propertyData.map((item, i) => (
                        <MenuItem key={i} value={item.propertyTypeName}>
                          {item.id + " - " + item.propertyTypeName}
                        </MenuItem>
                      ))}
                    </ValidatedTextField>
                    {/* {this.state.errorStatus.under ? (
                      <TextField
                        name="under"
                        label="Under"
                        error
                        select
                        fullWidth
                        value={this.state.data.under}
                        helperText={"Under should be selected"}
                        style={textFieldStyle}
                        onChange={this.onChanged}
                        InputProps={{ style: { fontSize: 13 } }}
                        InputLabelProps={{ style: inputStyles }}
                        SelectProps={{
                          MenuProps: {
                            style: { width: 200 },
                          },
                        }}
                        margin="normal"
                      >
                        {this.props.propertyData.map((item, i) => (
                          <MenuItem key={i} value={item.propertyTypeName}>
                            {item.id + " - " + item.propertyTypeName}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      <TextField
                        name="under"
                        label="Under"
                        select
                        value={this.state.data.under}
                        style={textFieldStyle}
                        onChange={this.onChanged}
                        InputProps={{ style: { fontSize: 13 } }}
                        InputLabelProps={{ style: inputStyles }}
                        SelectProps={{
                          MenuProps: {
                            style: { width: 200 },
                          },
                        }}
                        margin="normal"
                      >
                        {this.props.propertyData.map((item, i) => (
                          <MenuItem key={i} value={item.propertyTypeName}>
                            {item.id + " - " + item.propertyTypeName}
                          </MenuItem>
                        ))}
                      </TextField>
                    )} */}
                  </Grid>
                </Grid>

                <Grid container justify="flex-end">
                  <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    onClick={this.handleSubmit}
                    style={{
                      margin: "20px",
                      marginTop: "25px",
                    }}
                  >
                    Add
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Fade>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(MajorCategory);
