import React, { Component } from "react";
import {
  Grid,
  TextField,
  Divider,
  Button,
  withStyles,
  MenuItem
} from "@material-ui/core";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import DeleteButton from "./InterfaceElements/DeleteButton";
import { Dropdown, Input } from "semantic-ui-react";
import Axios from "axios";
import UserContext from "../utils/UserContext";
import { format } from "date-fns/esm";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import adbs, { bs2ad } from "ad-bs-converter";
import dateFormat from "dateformat";
import "nepali-datepicker-reactjs/dist/index.css";
import NepaliDatepicker from 'nepali-datepicker-and-dateinput';
import VoucherNumberRestriction from "../components/InterfaceElements/VoucherNumberRestriction";

const styles = theme => ({
  containerWidth: {
    marginTop: "8px",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.up("sm")]: {
      width: "70%"
    }
  },
  BottomContainerWidth: {
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.up("sm")]: {
      width: "90%"
    }
  },

  bottonTopMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px"
    },

    [theme.breakpoints.up("sm")]: {
      marginTop: "20px",
      marginBottom: "5px"
    }
  }
});

class OrderForm extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      periodList: [],
      fyList: [],
      currentPeriod: "",
      fiscalYear: "",
      periodDateFrom: "",
      periodDateTo: "",
      periodResData: [],
      rowRefs: [],
      columnDefs: [
        {
          headerName: "SN",
          field: "sn",
          sortable: true,
          resizable: true,
          width: 40
        },
        {
          headerName: "Item Code",
          field: "itemCode",
          sortable: true,
          resizable: true,
          width: 70
        },
        {
          headerName: "Item Name",
          field: "itemName",
          sortable: true,
          resizable: true
        },
        {
          headerName: "Quantity",
          field: "quantity",
          sortable: true,
          resizable: true,
          width: 70
        },
        {
          headerName: "Price",
          field: "price",
          sortable: true,
          width: 70
        },
        {
          headerName: "Discount",
          field: "discount",
          width: 70
        },
        {
          headerName: "Line Total",
          field: "lineTotal",
          sortable: true,
          width: 70
        },
        {
          headerName: "Delete",
          cellRendererFramework: DeleteButton,
          cellRendererParams: {
            deleteRow: this.deleteRow
          },
          width: 50
        }
      ],
      rowData: [],
      tableData: false,
      rowSelection: "multiple",
      value: "cash",
      data: {
        salesOrderNumber: "",
        date: "",
        nepaliDate: "",
        customerPoNumber: "",
        purchaseOrderDate: "",
        preparedBy: "",
        comment: "",

        billTo: "",
        customerAddress: "",
        customerPan: "",
        customerContact: "",
        attention: "",

        salesOrderDetails: []
      },
      customerRowRefs: [],
      quantity: 1,
      itemDetails: {
        itemCode: "",
        itemName: "",
        quantity: "",
        price: "",
        discount: "",
        lineTotal: ""
      },
      subTotal: 0,
      taxes: 0,
      sn: 1,
      discount: 0,
      total: 0,
      salesOrderNoList: [],
      voucherNumberError: false,
      voucherNumber: "",
      emptyComment: false
    };
  }

  deleteRow = data => {
    console.log(data);
    var rowAllData = this.state.rowData;

    var length = rowAllData.length;

    var finalRows = [];

    finalRows = rowAllData;

    for (var i = 0; i < length; i++) {
      console.log("pos: " + i);
      var obj = rowAllData[i];
      if (obj.sn === data.sn) {
        console.log(obj);
        finalRows.splice(i, 1);

        var subTotalNow = this.state.subTotal;
        var taxesNow = this.state.taxes;
        var discountNow = this.state.discount;
        var totalNow = this.state.total;

        var subTotalNew = subTotalNow - obj.price * obj.quantity;

        var taxesNew = taxesNow - obj.taxes;
        var discountNew = discountNow - 0;
        var totalNew = totalNow - obj.price * obj.quantity;

        this.setState({
          subTotal: subTotalNew,
          taxes: taxesNew,
          discount: discountNew,
          total: totalNew
        });

        break;
      }
    }
  };

  handle0Index = (rowAllData, rowsToSave, itemData) => {
    this.setState(prevState => ({ ...prevState, tableData: true }));
    var rowItemData = {
      sn: this.state.sn,
      itemCode: itemData.itemCode,
      itemName: itemData.itemName,
      quantity: this.state.quantity,
      taxes: itemData.taxable,
      price: itemData.sellingPrice,
      discount: 0,
      lineTotal: itemData.sellingPrice * this.state.quantity - 0
    };

    var toSave = true;

    rowAllData.forEach(element => {
      if (element.itemName === itemData.itemName) {
        toSave = false;
      }
    });

    if (toSave) {
      rowAllData.push(rowItemData);
      rowAllData.forEach(element => {
        var itemToAddDiff = {
          discount: element.discount,
          itemCode: element.code,
          itemName: element.name,
          lineTotal: element.lineTotal,
          price: element.price,
          quantity: element.quantity
        };

        rowsToSave.push(itemToAddDiff);
      });
    }
  };

  getAllRows = () => {
    let rowData = [];
    this.gridApi.forEachNode(node => rowData.push(node.data));
    return rowData;
  };

  handleItemAdd = () => {
    var rows = this.getAllRows();

    console.log(rows.length);

    var itemData = this.state.itemDetails;

    if (itemData.itemName === undefined || this.state.quantity === 0) {
      return;
    }

    var rowAllData = this.state.rowData;
    var taxTotal = this.state.taxes + itemData.taxable * this.state.quantity;

    var subTotal =
      this.state.subTotal + itemData.sellingPrice * this.state.quantity;

    var total = taxTotal + subTotal;

    this.setState({
      subTotal: subTotal,
      taxes: taxTotal,
      discount: this.state.discount,
      total: total
    });

    var rowsToSave = [];
    if (rows.length === 0) {
      this.handle0Index(rowAllData, rowsToSave, itemData);
    } else {
      for (let i = 0; i < rows.length; i++) {
        const element = rowAllData[i];
        if (element.itemName === itemData.itemName) {
          var newQuantity = element.quantity + this.state.quantity;
          var newDiscount = element.discount * newQuantity;

          var rowSpecificData = {
            ...element,
            quantity: newQuantity,
            discount: newDiscount,
            lineTotal: itemData.sellingPrice * newQuantity - newDiscount
          };
          rowAllData[i] = rowSpecificData;

          var itemToAdd = {
            discount: rowSpecificData.discount,
            itemCode: rowSpecificData.code,
            itemName: rowSpecificData.name,
            lineTotal: rowSpecificData.lineTotal,
            price: rowSpecificData.sellingPrice,
            quantity: rowSpecificData.quantity
          };
          rowsToSave.push(itemToAdd);
        } else {
          this.handle0Index(rowAllData, rowsToSave, itemData);
        }
      }
    }

    this.setState({ sn: this.state.sn + 1 });
    this.setState({ rowData: rowAllData });

    this.gridApi.setRowData(rowAllData);

    this.setState({ quantity: 1 });
  };

  componentDidMount() {
    this.getAllPeriodListing();

    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    var todayDate = dateFormat(date, "yyyy-mm-dd");

    let transactionDate = (todayDate.split("-"));
    
    let tdf = (
      transactionDate[0] +
        "/" +
        transactionDate[1] +
        "/" +
        transactionDate[2]
    );
    let df= adbs.ad2bs(tdf)

    let finalYear = df.en.year;
    let finalMonth = df.en.month;
    let finalDay = df.en.day;
    if(df.en.month < 10){
      finalMonth = "0" + df.en.month;
    }    
    if(df.en.day < 10){
      finalDay = "0" + df.en.day;
    }

    let finalDate = finalYear + "-" + finalMonth + "-" + finalDay;

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        date: finalDate,
        nepaliDate: finalDate,
      },
    }));

    Axios.get("pos/customer/getAllCustomersList")
      .then(res => {
        console.log(res);
        var rows = [];
        res.data.forEach(element => {
          const customer = {
            key: element.id,
            value: element.id,
            text: element.customerName + "     #" + element.id
          };
          rows.push(customer);
        });
        this.setState({
          customerRowRefs: rows
        });
      })
      .catch(err => {
        console.log(err);
      });

    Axios.get("pos/inventory/getSelectedValues")
      .then(res => {
        console.log(res);
        var rows = [];
        res.data.forEach(element => {
          const item = {
            key: element.itemCode,
            value: element.itemCode + element.itemName,
            text: element.itemName + " #" + element.itemCode,
            label: { content: "#" + element.itemCode, tag: true }
          };
          rows.push(item);
        });

        this.setState({ rowRefs: rows });
      })
      .catch(err => {
        console.log(err);
      });

    Axios.get("pos/salesOrder/getSalesOrder")
      .then(res => {
        console.log(res);

        this.setState(prevState => ({
          data: {
            ...prevState.data,
            //date: res.data.date === null ? "" : res.data.date,
            preparedBy: res.data.preparedBy === null ? "" : res.data.preparedBy,
            purchaseOrderDate: res.data.date === null ? "" : res.data.date
          }
        }));
      })
      .catch(err => {
        console.log(err);
      });
  }

  getAllPeriodListing = () => {
    Axios.get("/fy/getAllActivePeriods")
      .then(async res => {
        var fiscalYearLists = [];
        res.data.forEach(element => {
          var fy = Object.keys(element);

          fiscalYearLists.push(fy[0]);
        });
        await this.setState({
          fyList: fiscalYearLists,
          periodResData: res.data
        });

        this.getCurrentPeriod();
      })
      .catch(err => {
        console.log(err);
      });
  };

  getCurrentPeriod() {
    Axios.get("/fy/getCurrentPeriod")
      .then(async res => {
        await this.setState(prevState => ({
          ...prevState,
          fiscalYear: res.data.fiscalYear,
          currentPeriod: res.data.name
        }));
        this.getPeriodList();
        this.getNumbering(res.data.fiscalYear);
      })
      .catch(err => {
        console.log(err);
      });
  }

  handleFYChange = async e => {
    let Fy = e.target.value;
    await this.setState(prevState => ({
      ...prevState,
      fiscalYear: Fy
    }));
    this.getPeriodList();
    this.getNumbering(Fy);
  };

  getPeriodList() {
    var pLists = [];

    // this.state.resData.map(val => Object.keys(val) === this.state.fiscalYear ? pLists = val : null);

    this.state.periodResData.map(val =>
      Object.keys(val).includes(this.state.fiscalYear)
        ? (pLists = val[this.state.fiscalYear])
        : null
    );

    this.setState(prevState => ({
      ...prevState,
      periodList: pLists,
      currentPeriod: pLists[pLists.length-1],
    }));
    this.getPeriodDateFromTo(this.state.fiscalYear,pLists[pLists.length-1]);
  }

  handlePeriodChange = async e => {
    let Py = e.target.value;

    await this.setState(prevState => ({
      ...prevState,
      currentPeriod: Py
    }));
    this.getPeriodDateFromTo(this.state.fiscalYear, Py);
  };

  getPeriodDateFromTo = (fiscalYear, period) => {
    Axios.post("fy/findByNameAndFiscalYear", { period, fiscalYear }).then(
      (res) =>
        this.setState((prevState)=>({
          ...prevState,
          periodDateFrom: res.data.dateFrom,
          periodDateTo: res.data.dateTo,
        }))
    );
  };

   onChangedDate = (name, dateInMilli, bsDate, adDate) => {

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        date: adDate,
        nepaliDate: bsDate,
      },
    }));
    console.log(adDate);
  };

  onChangeDate = (name, dateInMilli, bsDate, adDate) => {

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        purchaseOrderDate: adDate,
      },
    }));
  };

  handleCustomerSelect = (event, data) => {
    const { value } = data;
    console.log(value);
    Axios.get("pos/customer/getCustomer/" + value)
      .then(res => {
        console.log(res);

        this.setState(prevState => ({
          ...prevState,
          data: {
            ...prevState.data,
            billTo: res.data.fullName === null ? "" : res.data.fullName,
            customerAddress: res.data.city === null ? "" : res.data.city,
            customerPan: res.data.pan === null ? "" : res.data.pan,
            customerContact:
              res.data.mobileNumber === null ? "" : res.data.mobileNumber
          }
        }));
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleChange = e => {
    let val = e.target.value;
    this.setState(prevState => ({
      ...prevState,
      voucherNumber: val
    }));
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  onRemoveSelected = () => {
    const selectedData = this.gridApi.getSelectedRows();
    this.gridApi.updateRowData({ remove: selectedData });
  };

  handleRadioChange = evt => {
    this.setState({ value: evt.target.value });
  };

  handleTextChange = e => {
    e.stopPropagation();
    var targetName = e.target.name;
    var value = e.target.value;
    this.setState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        [targetName]: value
      }
    }));
  };

  handleItemSelect = (event, data) => {
    const { value } = data;
    const { key } = data.options.find(o => o.value === value);

    Axios.get("pos/itemMaster/getItemMasterDetails/" + key)
      .then(res => {
        console.log(res);

        this.setState(prevState => ({
          ...prevState,
          itemDetails: res.data
        }));
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleQuantityChange = evt => {
    var val = evt.target.value;

    if (val < 1) {
      val = 1;
    }
    this.setState({ quantity: Math.round(val * 100) / 100  });
  };

  handleSubmit = () => {
    const token = this.context;

    if (
      this.state.data.comment === "" ||
      this.state.data.comment === undefined
    ) {
      this.setState({
        emptyComment: true
      })
      return;
    }

    if (this.state.tableData === false) {
      var errorSnackbarProps = {
        open: true,
        variant: "error",
        message: "No Item Selected",
        autoHideDuration: 2000
      };
      token.setSnackbarProps({ snackbarProps: errorSnackbarProps });
      return;
    }

    this.setState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        salesOrderDetails: this.state.rowData
      }
    }));

    setTimeout(() => {
      var obj = {
        fiscalYear: this.state.fiscalYear,
        period: this.state.currentPeriod,
        billTo: this.state.data.billTo === null ? "" : this.state.data.billTo,
        salesOrderNumber:
          this.state.data.salesOrderNumber === null
            ? ""
            : this.state.data.salesOrderNumber,
        salesOrderDate:
          this.state.data.date === null ? "" : this.state.data.date,
        nepaliDate:
          this.state.data.nepaliDate === null ? "" : this.state.data.nepaliDate,
        orderAddress:
          this.state.data.customerAddress === null
            ? ""
            : this.state.data.customerAddress,
        customerPoNumber:
          this.state.data.customerPoNumber === null
            ? ""
            : this.state.data.customerPoNumber,
        purchaseOrderDate:
          this.state.data.purchaseOrderDate === null
            ? ""
            : this.state.data.purchaseOrderDate,
        preparedBy:
          this.state.data.preparedBy === null ? "" : this.state.data.preparedBy,
        comment:
          this.state.data.comment === null ? "" : this.state.data.comment,
        pan:
          this.state.data.customerPan === null
            ? ""
            : this.state.data.customerPan,
        contact:
          this.state.data.customerContact === null
            ? ""
            : this.state.data.customerContact,
        attention:
          this.state.data.attention === null ? "" : this.state.data.attention,
        salesOrderDetails: this.state.rowData
      };
      Axios.post("pos/salesOrder/save", obj)
        .then(res => {
          console.log(res);
          var snackbarProps = {
            open: true,
            variant: "success",
            message: res.data,
            autoHideDuration: 2000
          };
          token.setSnackbarProps({ snackbarProps: snackbarProps });
          this.resetFormValues();
        })
        .catch(err => {
          console.log(err);
          var message = err.message;
          if (err.response !== undefined) {
            message = err.response.data;
          }
          var snackbarProps = {
            open: true,
            variant: "error",
            message: message,
            autoHideDuration: 2000
          };
          token.setSnackbarProps({ snackbarProps: snackbarProps });
        });
    }, 100);
  };

  getNumbering(fy) {
    Axios.post("/pos/voucherNumbering/getVoucherNumbering",{fiscalYear: fy, name: "Sales Order"})
      .then(res => {
        if (res.data === "Not Found") {
          this.setState({ voucherNumberError: true });
        } else {
          this.setState(prevState => ({
            ...prevState,
            salesOrderNoList: res.data,
            data: {
              ...prevState.data,
              salesOrderNumber: res.data[0],
            },
          }));
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  resetFormValues() {
    this.setState({
      rowData: [],
      data: {
        customerPoNumber: "",
        comment: "",
        billTo: "",
        customerAddress: "",
        customerPan: "",
        customerContact: "",
        attention: "",
        salesOrderDetails: []
      },
      customerRowRefs: [],
      quantity: 1,
      subTotal: 0,
      taxes: 0,
      sn: 1,
      discount: 0,
      total: 0,
      emptyComment: false
    });
    this.getNumbering(this.state.fiscalYear);
    Axios.get("pos/salesOrder/getSalesOrder")
      .then(res => {
        console.log(res);

        this.setState(prevState => ({
          data: {
            ...prevState.data,
           // date: res.data.date === null ? "" : res.data.date,
            preparedBy: res.data.preparedBy === null ? "" : res.data.preparedBy,
            purchaseOrderDate: res.data.date === null ? "" : res.data.date
          }
        }));
      })
      .catch(err => {
        console.log(err);
      });

    Axios.get("pos/customer/getAllCustomersList")
      .then(res => {
        console.log(res);
        var rows = [];
        res.data.forEach(element => {
          const customer = {
            key: element.id,
            value: element.id,
            text: element.customerName + "     #" + element.id
          };
          rows.push(customer);
        });
        this.setState({
          customerRowRefs: rows
        });
      })
      .catch(err => {
        console.log(err);
      });

  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Grid
          container
          //justify="center"
          className={classes.containerWidth}
          spacing={2}
        >
          <Grid item xs={12}>
            <Dropdown
              placeholder="Search by Customer Name"
              fluid
              search
              ref={search => {
                this.searchInput = search;
              }}
              fullWidth
              selection
              onChange={this.handleCustomerSelect}
              options={this.state.customerRowRefs}
            ></Dropdown>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              variant="outlined"
              select
              margin="dense"
              fullWidth
              label="Fiscal Year"
              name="fiscalYear"
              onChange={this.handleFYChange}
              value={this.state.fiscalYear}
            >
              {this.state.fyList.map(item => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="outlined-dense"
              label="Sales Order No"
              name="salesOrderNumber"
              value={this.state.data.salesOrderNumber}
              //onChange={this.handleChange}
              margin="dense"
              variant="outlined"
              fullWidth
            >
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              variant="outlined"
              select
              margin="dense"
              fullWidth
              label="Period"
              name="period"
              onChange={this.handlePeriodChange}
              value={this.state.currentPeriod}
            >
              {this.state.periodList.map(item => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </TextField>
          </Grid>
          
          <Grid item xs={12} sm={6} md={3} style={{marginTop: "8px"}}>
            
          <NepaliDatepicker
              id="nepali-date"
              onChange={this.onChangedDate}
              label="Date"
              value={this.state.data.date}
              locale= "en"
              showDefaultDate="true"
              style={{height: "5px", width: "5px"}}
            />
            
            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                label="Date"
                value={this.state.data.date}
                inputVariant="outlined"
                autoOk={true}
                format="yyyy-MM-dd"
                margin="dense"
                fullWidth
                minDate={this.state.periodDateFrom}
                maxDate={this.state.periodDateTo}
                onChange={this.onChangedDate}
                shouldDisableDate={this.shouldDisableDate}
                animateYearScrolling
              />
            </MuiPickersUtilsProvider> */}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="outlined-dense"
              label="Prepared By"
              name="preparedBy"
              value={this.state.data.preparedBy}
              onChange={this.handleTextChange}
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="outlined-dense"
              label="Address"
              name="orderAddress"
              value={this.state.data.customerAddress}
              onChange={this.handleTextChange}
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="outlined-dense"
              label="Contact"
              name="contact"
              value={this.state.data.customerContact}
              onChange={this.handleTextChange}
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="outlined-dense"
              label="PAN"
              name="pan"
              value={this.state.data.customerPan}
              onChange={this.handleTextChange}
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} style={{marginTop: "8px"}}>
          <NepaliDatepicker
              id="nepali-date"
              onChange={this.onChangeDate}
              label="Purchase Order Date"
              value={this.state.data.purchaseOrderDate}
              locale= "en"
              showDefaultDate="true"
              style={{height: "5px", width: "5px"}}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="outlined-dense"
              label="Customer PO No"
              name="customerPoNumber"
              value={this.state.data.customerPoNumber}
              onChange={this.handleTextChange}
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="outlined-dense"
              name="attention"
              label="Attention"
              onChange={this.handleTextChange}
              value={this.state.data.attention}
              type="text"
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>

        <Divider style={{ marginTop: "15px", marginBottom: "5px" }} />

        <Grid
          container
          justify="center"
          spacing={2}
          className={classes.BottomContainerWidth}
        >
          <Grid item xs={12} sm={12} md={7}>
            <Dropdown
              placeholder="Search by Item Code or Item Name"
              fluid
              fullWidth
              search
              style={{ marginTop: "15px" }}
              ref={search => {
                this.searchInput = search;
              }}
              selection
              onChange={this.handleItemSelect}
              options={this.state.rowRefs}
            ></Dropdown>
          </Grid>

          <Grid item xs={7} sm={8} md={3}>
            <Input
              placeholder="Quantity"
              type="number"
              style={{ marginTop: "15px", width: "100%" }}
              onChange={this.handleQuantityChange}
              value={this.state.quantity}
            ></Input>
          </Grid>
          <Grid item xs={5} sm={4} md={2}>
            <Button
              variant="outlined"
              fullWidth
              color="primary"
              onClick={this.handleItemAdd}
              style={{
                minWidth: "60px",
                margin: "auto",
                height: "38px",
                marginTop: "15px"
              }}
            >
              +Add
            </Button>
          </Grid>

          <div
            className="ag-theme-balham"
            style={{
              height: "240px",
              width: "100%",
              margin: "10px"
            }}
          >
            <AgGridReact
              columnDefs={this.state.columnDefs}
              rowData={this.state.rowData}
              animateRows={true}
              rowSelection={this.state.rowSelection}
              onGridReady={this.onGridReady}
            ></AgGridReact>
          </div>
          <div></div>
        </Grid>

        <Grid
          container
          justify="center"
          spacing={4}
          style={{ marginBottom: "7px", marginTop: "5px" }}
        >
          <Grid item xs={12} sm={6} md={3}>
            <Grid container justify="center">
              <p>
                Sub Total: <span style={{ display: "inline-block" }}></span>
                <strong>{this.state.subTotal}</strong>
              </p>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid container justify="center">
              <p>
                Taxes: <span style={{ display: "inline-block" }}></span>
                <strong>{this.state.taxes}</strong>
              </p>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid container justify="center">
              <p>
                Discount: <span style={{ display: "inline-block" }}></span>
                <strong>{this.state.discount}</strong>
              </p>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid container justify="center">
              <p>
                Total: <span style={{ display: "inline-block" }}></span>
                <strong>{this.state.total}</strong>
              </p>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          spacing={2}
          className={classes.BottomContainerWidth}
        >
          <Grid item xs={12}>
            {this.state.emptyComment ? (
              <TextField
                id="outlined-dense"
                label="Comment"
                error
                helperText="Comment should not be empty "
                fullWidth
                name="comment"
                value={this.state.data.comment}
                onChange={this.handleTextChange}
                margin="normal"
                variant="outlined"
                style={{ marginTop: "15px" }}
              />
            ) : (
                <TextField
                  id="outlined-dense"
                  label="Comment"
                  fullWidth
                  name="comment"
                  value={this.state.data.comment}
                  onChange={this.handleTextChange}
                  margin="normal"
                  variant="outlined"
                  style={{ marginTop: "15px" }}
                />
              )}

          </Grid>
        </Grid>
        <Grid container justify="center" className={classes.bottonTopMargin}>
          <Button
            variant="outlined"
            color="primary"
            onClick={this.handleSubmit}
            style={{
              minWidth: "100px",
              width: "20vw",
              height: "50px",
              margin: "auto"
            }}
          >
            Save
          </Button>
        </Grid>
        <VoucherNumberRestriction
          open={this.state.voucherNumberError}
        ></VoucherNumberRestriction>
      </div>
    );
  }
}

export default withStyles(styles)(OrderForm);
