import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import Axios from "axios";
import { Card, CardContent, Grid, withStyles } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    padding: 5,
    paddingTop: "5px",
    paddingBottom: "6px",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "170px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
    },
  },
});

class PropertyType extends Component {
  constructor(props) {
    super(props);
    const rowIndex = (params) => params.node.rowIndex + 1;
    this.state = {
      columnDefs: [
        {
          headername: "Sn",
          field: "sn",
          sortable: true,
          resizable: true,
          valueGetter: rowIndex,
          minWidth: 50,
        },
        {
          headerName: "Name",
          field: "propertyTypeName",
          sortable: true,
          resizable: true,
          editable: true,
          minWidth: 200,
        },
      ],
      rowData: [],
      overlayLoadingTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
      overlayNoRowsTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
      open: false,
    };
  }

  componentWillMount() {
    window.removeEventListener("resize", this.daListener);
  }

  componentDidMount() {
    var rowData = [];

    Axios.get("pos/propertyType/getAll")
      .then((res) => {
        rowData = res.data;
        this.setState({ rowData: rowData });
        this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>",
        });
        try {
          this.gridApi.hideOverlay();
          if (res.data.length === 0) this.gridApi.showNoRowsOverlay();
        } catch (error) {
          console.log(error);
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>",
        });
        try {
          this.gridApi.hideOverlay();
          this.gridApi.showNoRowsOverlay();
        } catch (error) {
          console.log(error);
        }
      });
  }

  firstDataRendered = (params) => {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    window.addEventListener("resize", this.daListener);
  };

  daListener = () => {
    if (!this.gridApi) return;
    setTimeout(() => {
      this.gridApi.sizeColumnsToFit();
    }, 100);
  };

  setOpen = (status) => {
    this.setState({ open: status });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  render(params) {
    const { children, value, index, classes, ...other } = this.props;

    return (
      <div>
        <Card
          className={classes.root}
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
        >
          <CardContent>
            <Grid container style={{ marginTop: "10px" }} justify="center">
              <div
                className="ag-theme-balham"
                style={{
                  height: "57vh",
                  width: "100%",
                  margin: "10px",
                }}
              >
                <AgGridReact
                  columnDefs={this.state.columnDefs}
                  rowData={this.state.rowData}
                  onFirstDataRendered={this.firstDataRendered}
                  overlayLoadingTemplate={this.state.overlayLoadingTemplate}
                  overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                  onGridReady={this.onGridReady}
                ></AgGridReact>
              </div>
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(PropertyType);
