import React, { Component } from "react";
import {
  Modal,
  Fade,
  Card,
  Backdrop,
  Grid,
  Typography,
  TextField,
  CardContent,
  Button,
} from "@material-ui/core";

class SalesConfirm extends Component {
  render() {
    const {
      open,
      handleClose,
      billAmount,
      tenderAmount,
      change,
      paymentMethod,
      handleSubmit,
    } = this.props;

    const modal = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };

    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div>
              <Card>
                <CardContent style={{ padding: "50px" }}>
                  <Grid container justify="center">
                    <Typography variant="h6" component="h2">
                      Confirm Sales
                    </Typography>
                  </Grid>

                  <Grid container justify="center">
                    <Grid item>
                      <table style={{ marginTop: "25px", marginLeft: "10px" }}>
                        <tbody>
                          <tr>
                            <td>Payment Mode</td>

                            <td style={{ paddingLeft: "100px" }}>
                              <TextField
                                id="filled-dense-hidden-label"
                                margin="dense"
                                value={paymentMethod}
                                hiddenLabel
                                variant="filled"
                                style={{ marginLeft: "20px" }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Bill Amount</td>

                            <td style={{ paddingLeft: "100px" }}>
                              <TextField
                                id="filled-dense-hidden-label"
                                margin="dense"
                                hiddenLabel
                                type="number"
                                value={billAmount}
                                variant="filled"
                                style={{ marginLeft: "20px" }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Tender Amount</td>

                            <td style={{ paddingLeft: "100px" }}>
                              <TextField
                                id="filled-dense-hidden-label"
                                margin="dense"
                                hiddenLabel
                                type="number"
                                value={tenderAmount}
                                min={0}
                                variant="filled"
                                style={{ marginLeft: "20px" }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Change</td>
                            <td style={{ paddingLeft: "100px" }}>
                              <TextField
                                id="filled-dense-hidden-label"
                                margin="dense"
                                hiddenLabel
                                type="number"
                                value={change}
                                variant="filled"
                                style={{ marginLeft: "20px" }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Grid>
                  </Grid>
                  <Grid container justify="center">
                    <Button
                      variant="outlined"
                      onClick={handleSubmit}
                      size="large"
                      color="primary"
                      style={{
                        margin: "10px",
                        marginTop: "50px",
                        marginRight: "10px",
                        paddingTop: "15px",
                        paddingBottom: "15px",
                        paddingRight: "55px",
                        paddingLeft: "55px",
                      }}
                    >
                      Save & Print
                    </Button>
                  </Grid>
                </CardContent>
              </Card>
            </div>
          </Fade>
        </Modal>
        {/* <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          open={printOpen}
          onClose={handlePrintClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={printOpen}>
            <div>
              <Card>
                <SalesInvoice3mm handlePrintClose={handlePrintClose} />
              </Card>
            </div>
          </Fade>
        </Modal> */}
      </div>
    );
  }
}

export default SalesConfirm;
